function ReportsFilterSharedService() {
	'ngInject';

	const availableMethodNames = Object.freeze({
		METHOD_UPDATE: 'update',
		METHOD_GET_FILTERED_DATE: 'getFilteredData',
	});

	const methodRegistry = {};
	const methodNames = new Set();

	return {
		getAvailableNames: () => availableMethodNames,
		registerMethod: (controllerName, methodName, callBackFn) => {
			if (methodNames.has(methodName)) {
				return;
			}
			methodRegistry[controllerName] = {};
			methodRegistry[controllerName][methodName] = callBackFn;
			methodNames.add(methodName);
		},
		hasMethod: (methodName) => {
			return methodNames.has(methodName);
		},
		callMethod: (methodName, args) => {
			for (const controllerName in methodRegistry) {
				if (methodRegistry[controllerName] && methodRegistry[controllerName][methodName]) {
					return methodRegistry[controllerName][methodName](args);
				}
			}
			return null;
		},
		init: ($scope, controllerName) => {
			$scope.$on('$destroy', () => {
				Object.keys(methodRegistry[controllerName]).forEach((methodName) => methodNames.delete(methodName));
				delete methodRegistry[controllerName];
			});
		},
	};
}

export default ReportsFilterSharedService;
