function PagerFilter(PagerService) {
	'ngInject';

	/**
	 * @param {Array} array
	 * @param {String} pagerId
	 * @returns {Array}
	 */
	return function applyFilter(array, pagerId) {
		var pager = PagerService.get(pagerId);

		if (!angular.isArray(array)) {
			throw new Error('the pager filter can only handle arrays');
		}

		if (pager.items !== array.length) {
			pager.setItems(array.length);
		}

		return array.slice(pager.filterFrom(), pager.to());
	};
}

export default PagerFilter;
