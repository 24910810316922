import angular from 'angular';

import AdslotListFilterComponent from './adslot-list-filter.component.js';
import AdslotListFilterController from './adslot-list-filter.controller.js';

const AdslotListFilterModule = angular
	.module('inventory.adslot-list-filter', [])
	.component('adslotsListFilter', AdslotListFilterComponent)
	.controller('AdslotListFilterController', AdslotListFilterController).name;

export default AdslotListFilterModule;
