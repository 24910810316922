import AdvertiserFilter from '../../../models/AdvertiserFilter.js';

function PartnershipsOpenAuctionsViewFilterController($scope, resolvedConfiguration, uiGridConstants, Advertisers, InfoService, OpenAuctionSettings, toaster) {
	'ngInject';

	const vm = this;

	let initialFilter = resolvedConfiguration.filter;

	vm.filter = new AdvertiserFilter(initialFilter.toObject());

	vm.assignedData = Array.from(initialFilter.items)
		.map((advertiserId) => Advertisers.getById(advertiserId))
		.filter((entry) => entry !== undefined); // eslint-disable-line angular/definedundefined
	vm.availableData = Object.values(Advertisers.getMap()).filter((advertiser) => advertiser.enabled);

	vm.save = () => {
		const currentFilter = AdvertiserFilter.fromIdsAndType(
			vm.assignedData.map(({ id }) => id),
			vm.filter.type,
		);
		const currentFilterDataObject = currentFilter.toObject();

		if (!initialFilter.equals(currentFilter)) {
			InfoService.startRequest();

			resolvedConfiguration.filter = currentFilter;
			OpenAuctionSettings.setGlobalConfig(resolvedConfiguration)
				.then(() => {
					vm.filter.resetTypeChanged();
					initialFilter = new AdvertiserFilter(currentFilterDataObject);

					toaster.successMessage('MESSAGE.SETTINGS_SAVE_SUCCESS');
				})
				.catch((errorObject) => {
					toaster.errorMessage('MESSAGE.SETTINGS_SAVE_ERROR' + JSON.stringify(errorObject)); // eslint-disable-line angular/json-functions
				})
				.finally(() => InfoService.endRequest());
		}
	};
}

export default PartnershipsOpenAuctionsViewFilterController;
