function Account(Api) {
	'ngInject';

	/**
	 * @var {int}
	 */
	this.id = 0;

	/**
	 * @var {String}
	 */
	this.name = '';

	/**
	 * in percent
	 * @var {int}
	 */
	this.share = 0;

	/**
	 * @var {int}
	 */
	this.floorPrice = 0;

	/**
	 * @var {string[]}
	 */
	this.flags = [];

	/**
	 * @var {string[]}
	 */
	this.capabilities = [];

	/**
	 * @var {Array.<int>}
	 */
	this.demandPartnerVisibilityOverwrites = [];

	/**
	 * @return {promise}
	 */
	this.load = function load() {
		var self = this;

		return Api.get('/api/supply/v1/account').then(function success(account) {
			self.id = parseInt(account.id, 10);
			self.name = account.name;
			self.share = parseFloat(account.share);
			self.floorPrice = parseInt(account.floorPrice, 10);
			self.flags = account.flags || [];
			self.capabilities = account.capabilities || [];
			self.demandPartnerVisibilityOverwrites = account.visibleDemands || [];
		});
	};

	/**
	 * @param {Number} floorPrice
	 * @returns {promise}
	 */
	this.update = (floorPrice) => Api.post('/api/supply/v1/account', { floorPrice });

	this.getSupplyPartnerDimensions = () => Api.get('/api/supply/v1/account/dimensions');

	/**
	 * @return {int}
	 */
	this.getId = function getId() {
		return this.id;
	};

	/**
	 * @return {String}
	 */
	this.getName = function getName() {
		return this.name;
	};

	/**
	 * @returns {int}
	 */
	this.getShare = function getShare() {
		return this.share;
	};

	/**
	 * @returns {int}
	 */
	this.getFloorPrice = function getFloorPrice() {
		return this.floorPrice;
	};

	/**
	 * @returns {string[]}
	 */
	this.getFlags = function getFlags() {
		return this.flags;
	};

	this.isAllowedToCreateAdslots = () => this.capabilities.includes('create_adslots');

	this.isAllowedToUpdateDealAdslotConnections = () => this.capabilities.includes('update_deal_adslot_connections');

	this.isAllowedToUpdateDealMargin = () => this.capabilities.includes('update_deal_margin');

	this.isAllowedToUpdateAuctionType = () => this.capabilities.includes('update_auction_type');

	this.mustOnlyCreateDealPartnerships = () => this.capabilities.includes('must_only_create_deals');

	this.mustCopyAdslotsOnDuplicateDeals = () => this.capabilities.includes('must_copy_adslots_on_duplicate_deals');

	this.mustCopyTargetingOnDuplicateDeals = () => this.capabilities.includes('must_copy_targeting_on_duplicate_deals');

	this.mustCopyWhitelistSizesOnDuplicateDeals = () => this.capabilities.includes('must_copy_whitelist_sizes_on_duplicate_deals');

	this.acEnabledDealTargetingDcDeal = () => this.capabilities.includes('ac_enabled_deal_targeting_dc_deal');

	/**
	 * @deprecated
	 * @return {boolean}
	 */
	this.isDirectConnect = () => this.flags.indexOf('dc') !== -1;

	this.shouldIncludeYPRequests = () => this.capabilities.includes('reports_yp_requests');
}

export default Account;
