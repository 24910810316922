function SitesNewController($rootScope, $scope, close, ModalCloser, Sites, InfoService, toaster, $timeout) {
	'ngInject';

	var vm = this,
		unbindAuthListener,
		unbindDestroyListener;

	vm.site = {
		name: '',
	};
	vm.sites = [];

	vm.isFormValid = function isFormValid() {
		return vm.site.name !== '';
	};

	vm.isValidSiteName = function isValidSiteName(modelValue) {
		return !Sites.exists(modelValue);
	};

	vm.afterSaveSuccess = function afterSaveSuccess() {
		$rootScope.$emit(Sites.EVENT.UPDATED);
	};

	vm.afterSaveFinally = function afterSaveFinally() {
		$rootScope.$emit('autofocusId::trigger', 'structure-add-site');
	};

	vm.save = function save(site) {
		InfoService.startRequest();

		Sites.add([site])
			.then(function success() {
				vm.sites.push(angular.copy(site));

				$timeout(vm.afterSaveSuccess, 0);

				toaster.successMessage('MESSAGE.SITE_CREATED');

				vm.site.name = '';
				vm.form.$setPristine();
			})
			.catch(function error(errorObject) {
				if (errorObject.code === Sites.ERROR.DUPLICATE_NAME) {
					vm.form.siteName.$invalid = true;
					vm.form.siteName.$error.existing = true;
				} else {
					toaster.errorMessage(errorObject.errorMessage);
				}
			})
			.finally(function finallyFn() {
				InfoService.endRequest();
				$timeout(vm.afterSaveFinally, 0);
			});
	};

	vm.close = function closeModal() {
		ModalCloser.close(close, undefined, 200);
	};

	unbindAuthListener = $rootScope.$on('event:auth-loginRequired', vm.close);
	unbindDestroyListener = $scope.$on('$destroy', function destroyListener() {
		unbindAuthListener();
		unbindDestroyListener();
	});
}

export default SitesNewController;
