class GoogleProvider {
	/**
	 * @param {Object} data
	 */
	constructor(data) {
		/**
		 * @type {int}
		 */
		this.id = 0;

		/**
		 * @type {string}
		 */
		this.name = '';

		/**
		 * @type {string}
		 */
		this.policyUrl = '';

		for (let key in data) {
			if (Object.prototype.hasOwnProperty.call(data, key) && Object.prototype.hasOwnProperty.call(this, key)) {
				this[key] = data[key];
			}
		}
	}

	/**
	 * @returns {int} id
	 */
	getId() {
		return this.id;
	}

	/**
	 * @returns {string} name
	 */
	getName() {
		return this.name;
	}

	/**
	 *
	 * @returns {string}
	 */
	getPolicyUrl() {
		return this.policyUrl;
	}
}

export default GoogleProvider;
