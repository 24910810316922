import isEmpty from 'lodash/isEmpty.js';
import isEqual from 'lodash/isEqual.js';

import ReportTemplate from '../../models/ReportTemplate.js';
import { getTypeSelectOptions, getScheduleDaySelectOptions } from '../../models/ReportNotificationSettingsSchedule.js';

function ReportsConfigureScheduledReportController(
	$rootScope,
	$scope,
	$timeout,
	$translate,
	close,
	templateId,
	InfoService,
	ModalCloser,
	ReportTemplates,
	toaster,
) {
	'ngInject';

	const vm = this;

	vm.template = new ReportTemplate(ReportTemplates.getById(templateId).toObject());

	vm.title = `TITLE.REPORTS.${vm.template.hasScheduleConfigured() ? 'EDIT_SCHEDULED_REPORT' : 'CONFIGURE_SCHEDULED_REPORT'}`;

	vm.showScheduleDetails = vm.template.hasScheduleConfigured();
	vm.showScheduleDay = vm.template.getSchedule().isScheduledWithDay();

	vm.scheduleTypeOptions = getTypeSelectOptions();
	vm.scheduleDayOptions = getScheduleDaySelectOptions(vm.template.getSchedule().schedule.type, $translate.use());

	vm.scheduleTypeSelected = () => {
		vm.showScheduleDetails = vm.template.getSchedule().isScheduled();
		vm.showScheduleDay = vm.template.getSchedule().isScheduledWithDay();

		if (vm.showScheduleDay) {
			vm.scheduleDayOptions = getScheduleDaySelectOptions(vm.template.getSchedule().schedule.type, $translate.use());
			// reset model to pick the first select box entry
			vm.template.getSchedule().schedule.day = 1;
		}
	};

	vm.recipientsToAdd = undefined;

	/**
	 * @param {String} emails
	 * @return {[String[], String[]]}
	 */
	const getEmailsFromString = (emails) => {
		const candidates = emails
			.trim()
			.split(',')
			.map((recipient) => recipient.trim())
			.filter((recipient) => !isEmpty(recipient));

		let valid = [];
		let invalid = [];
		for (const candidate of candidates) {
			if (candidate.match(/^[^\s]+@[^\s]+\.[^\s]+$/)) {
				valid.push(candidate);
			} else {
				invalid.push(candidate);
			}
		}

		return [valid, invalid];
	};

	vm.addRecipientsEventHandler = ($event) => {
		if ($event.type === 'paste') {
			// prevent paste event to update the model again after we modified it
			$event.originalEvent.preventDefault();

			vm.addRecipients($event.originalEvent.clipboardData.getData('text'));
		} else if ($event.type === 'keypress' && $event.keyCode === 13) {
			// prevent surrounding form submit
			$event.originalEvent.preventDefault();

			vm.addRecipients(vm.recipientsToAdd);
		}
	};

	vm.addRecipients = (recipientsString) => {
		const [recipients, erroneousRecipients] = getEmailsFromString(recipientsString || '');

		if (recipients.length > 0) {
			const withAddedRecipients = vm.template.getSchedule().recipients.concat(recipients);
			// use set for deduplication only
			vm.template.getSchedule().recipients = Array.from(new Set(withAddedRecipients));
		}

		vm.recipientsToAdd = erroneousRecipients.join(', ');

		// defer marking as invalid to land after the current event cycle has ended
		$timeout(() => {
			if (!isEmpty(vm.recipientsToAdd)) {
				vm.configureScheduledReportForm.recipients.$invalid = true;
			}
		});
	};

	vm.removeRecipient = (index) => vm.template.getSchedule().recipients.splice(index, 1);

	vm.isFormValid = () => !vm.template.hasScheduleConfigured() || vm.template.getSchedule().recipients.length > 0;

	vm.submit = () => {
		const previousNotificationSettings = ReportTemplates.getById(templateId).getSchedule().toObject();

		if (isEqual(vm.template.getSchedule().toObject(), previousNotificationSettings)) {
			vm.cancel();
			return;
		}

		InfoService.startRequest();
		ReportTemplates.update(vm.template)
			.then(() => {
				toaster.successMessage('MESSAGE.REPORT_TEMPLATE_SAVE_SUCCESS');
				closeFn(true, 200);
			})
			.catch(() => toaster.errorMessage('MESSAGE.REPORT_TEMPLATE_SAVE_ERROR'))
			.finally(() => InfoService.endRequest());
	};

	const closeFn = (result, delay) => {
		ModalCloser.close(close, result, delay);
	};

	vm.cancel = () => {
		closeFn(undefined, 100);
	};

	const unregisterSessionTimeoutListener = $rootScope.$on('event:auth-loginRequired', vm.cancel);

	$scope.$on('$destroy', unregisterSessionTimeoutListener);
}

export default ReportsConfigureScheduledReportController;
