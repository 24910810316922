function PagerInfo(PagerService) {
	'ngInject';

	var vm = this;

	vm.of = function of() {
		return vm.pager.items;
	};

	vm.isPageSize = function isPageSize(perPage) {
		return vm.pager.perPage === perPage;
	};

	this.$onInit = function $onInit() {
		// eslint-disable-line angular/controller-as-vm
		vm.dropClass = vm.dropup === true ? 'opens-up' : 'opens-down';

		vm.pager = PagerService.get(vm.pagerId);

		vm.setPerPage = vm.pager.setPerPage.bind(vm.pager);
		vm.from = vm.pager.from.bind(vm.pager);
		vm.to = vm.pager.to.bind(vm.pager);
	};
}

export default PagerInfo;
