import PartnershipCSV from '../../models/PartnershipCSV.js';
import REFERRER_TYPE from '../../models/ReferrerType.js';

// Regex pattern for matching dates in report, like "2016-05-18 13:37 +0200"
const PARTNERSHIP_DATE_FORMAT_REGEX = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])\s([0-1]?[0-9]|2[0-3]):[0-5][0-9]\s[+-]\d{4}$/;

function PartnershipsCsvService(ObjectsHelperService, GenericCsvService) {
	'ngInject';

	class ParthershipType {
		constructor(id, name) {
			this.id = id;
			this.name = name;
		}
	}

	const CSV_PARTNERSHIP_TYPE = {
		PRIVATE_AUCTION: new ParthershipType(0, 'private auction'),
		DIRECT_DEAL: new ParthershipType(1, 'direct deal'),
		PROGRAMMATIC_GUARANTEED: new ParthershipType(2, 'programmatic guaranteed'),
		PREFERRED_DEAL: new ParthershipType(3, 'preferred deal'),
	};

	/**
	 * Convert a list of string tokens (in the order of our partnership CSV export / import format) to a Partnership object.
	 * This does only data binding and format conversion, no validation or applying default values.
	 */
	function csvTokensToPartnership(tokens, errorFn, isYDC = false) {
		let obj = {
			id: validateInt(tokens[0], () => errorFn('id')),
			name: tokens[1],
			state: parsePartnershipState(tokens[2], () => errorFn('Status')),
			lastAutomatedDeactivation: tokens[3],
			demandId: validateInt(tokens[4], () => errorFn('Demand Partner ID')),
			demandName: tokens[5],
			type: parsePartnershipType(tokens[6], () => errorFn('Partnership Type')),
			dealId: tokens[7] ? tokens[7] : null,
			dealDates: {
				dealStart: parseDateFormat(tokens[8], () => errorFn('Deal Start')),
				dealEnd: parseDateFormat(tokens[9], () => errorFn('Deal End')),
			},
			priority: validateIntMinMaxValue(tokens[10], 0, 10, () => errorFn('Priority')),
			auctionType: parsePartnershipAuctionType(tokens[11], () => errorFn('Auction Type')),
			floorPrice: validateInt(tokens[12], () => errorFn('Floor Price')),
			fixedPrice: validateInt(tokens[13], () => errorFn('Fixed Price')),
			biasType: parsePartnershipBiasType(tokens[14], () => errorFn('Bid Bias Type')),
			bias: validateInt(tokens[15], () => errorFn('Bid Bias Value')),

			refererType: parseBindOptionsRefererType(tokens[16], () => errorFn('Referrer Type')),
			overrideReferer: tokens[17],
			categoryInfoAllowed: validateYesNoValue(tokens[18], () => errorFn('Category Info')),
			geoLocationAllowed: validateYesNoValue(tokens[19], () => errorFn('Geolocation')),
			billing: validateBilling(tokens[20], () => errorFn('Billing Over')),
		};

		if (isYDC) {
			obj = {
				...obj,
				dealTargetingIds: parseValueToArray(tokens[21]),
				margin: validateIntMinMaxValue(tokens[22], 0, 99, () => errorFn('Margin')),
			};
		} else {
			obj = {
				...obj,
				activeSizes: parseValueToArray(tokens[21]),
				nonEnabledSizesFromAdslots: parseValueToArray(tokens[22]),
				targetingSummary: tokens[23],
			};
		}

		return new PartnershipCSV(obj);
	}

	function parseBindOptionsRefererType(value, errorFn) {
		if (value === null) {
			return null;
		}
		if (value.toLowerCase() === REFERRER_TYPE.TRANSPARENT) {
			return 0;
		} else if (value.toLowerCase().replace('-', '_') === REFERRER_TYPE.SEMI_TRANSPARENT) {
			return 1;
		} else if (value.toLowerCase() === REFERRER_TYPE.BLIND) {
			return 2;
		}
		errorFn();
		return value;
	}

	function parsePartnershipState(value, errorFn) {
		if (value === null || value === '' || value.toLowerCase() === 'enabled') {
			return 0;
		} else if (value.toLowerCase() === 'disabled') {
			return 1;
		} else if (value.toLowerCase() === 'auto-disabled') {
			return 2;
		}
		errorFn();
		return value;
	}

	function parsePartnershipType(value, errorFn) {
		if (value === null) {
			return null;
		}
		let partnershipType = Object.values(CSV_PARTNERSHIP_TYPE).find((entry) => entry.name === value.toLowerCase());
		if (partnershipType) {
			return Number(partnershipType.id);
		}
		errorFn();
		return value;
	}

	function parsePartnershipBiasType(value, errorFn) {
		if (value === null) {
			return null;
		}
		if (value.toLowerCase() === 'empty') {
			return 0;
		} else if (value.toLowerCase() === 'flat') {
			return 1;
		} else if (value.toLowerCase() === 'percentage') {
			return 2;
		}
		errorFn();
		return null;
	}

	function parsePartnershipAuctionType(value, errorFn) {
		if (value === null) {
			return null;
		}

		if (value.toLowerCase() === 'first price') {
			return 1;
		} else if (value.toLowerCase() === 'second price') {
			return 0;
		}
		errorFn();
		return null;
	}

	function validateYesNoValue(value, errorFn) {
		if (value === null) {
			return null;
		}
		if (value.toLowerCase() === 'yes') {
			return true;
		}
		if (value.toLowerCase() === 'no') {
			return false;
		}
		errorFn();
		return null;
	}

	function validateBilling(value, errorFn) {
		if (value === null) {
			return null;
		}
		if (value.toLowerCase() === 'yieldlab') {
			return 0;
		} else if (value.toLowerCase() === 'demand partner') {
			return 1;
		}
		errorFn();
		return null;
	}

	function parseValueToArray(value) {
		if (value) {
			return toStrArray(value);
		}
		return [];
	}

	function validateInt(value, errorFn) {
		if (value === null) {
			return null;
		}
		let result = Number(value);
		if (!isInt(result)) {
			// not an integer
			errorFn();
			return null;
		}
		return result;
	}

	function validateIntMinMaxValue(value, min, max, errorFn) {
		if (value === null) {
			return null;
		}

		let result = Number(value);
		if (!isInt(result)) {
			// not an integer
			errorFn();
			return null;
		}
		if (value < min || value > max) {
			errorFn();
			return null;
		}

		return result;
	}

	function parseDateFormat(value, errorFn) {
		if (value === null) {
			return null;
		}

		if (value.match(PARTNERSHIP_DATE_FORMAT_REGEX) === null) {
			errorFn();
			return null;
		}
		const dateArray = value.split(' ');
		const newDate = dateArray[0] + 'T' + dateArray[1] + dateArray[2];

		return newDate;
	}

	function isInt(n) {
		// works with both string and number
		return !Number.isNaN(n) && n % 1 === 0;
	}

	function toStrArray(str) {
		if (str === null) {
			return null;
		}
		if (str === '') {
			return [];
		}
		return str.split('|');
	}

	return {
		parseCSVString: GenericCsvService.parseCSVString,
		csvTokensToPartnership: csvTokensToPartnership,
	};
}

export default PartnershipsCsvService;
