import Partnership from './../models/Partnership.js';

import PartnershipState from '../models/PartnershipState.js';
import { ascByNameField } from '../utils/sort-utils.js';
import INVOICE_TYPES from './../models/PartnershipInvoiceType.js';
import INVOICE_TYPE_TRANSLATION from './../models/PartnershipInvoiceTypeTranslation.js';
import TYPE from './../models/PartnershipType.js';

function Partnerships(Api, DemandPartners) {
	'ngInject';

	let needReload = false;

	this.ERROR = {
		DUPLICATE_PARTNERSHIP_NO_SEAT_FOR_DSP: 30003,
		DUPLICATE: 40010,
		AUTO_CONFIGURED_ONLY_DEAL: 30018,
		DUPLICATE_PARTNERSHIP_NAME_TOO_LONG: 30022,
		ACTIVE_SIZES_AMOUNT_EXCEEDED: 50018,
		ACTIVE_SIZES_OVERLAP_EXIST: 50019,
		DIMENSION_NOT_ALLOWED_FOR_SUPPLY_PARTNER: 50020,
		ACTIVE_SIZES_CHANGE_NOT_ALLOWED: 50021,
		TARGETING_CONFIGURATION_INVALID: 30017,
	};

	this.EVENTS = {
		PARTNERSHIP_UPDATED: 'Partnerships::partnership-updated',
		PARTNERSHIP_RECREATED: 'Partnerships::partnership-recreated',
	};

	// array index reflects type id
	this.TYPES = [
		{
			id: TYPE.PRIVATE_AUCTION,
			text: 'CAPTION.PRIVATE_AUCTION',
		},
		{
			id: TYPE.DIRECT_DEAL,
			text: 'PLACEHOLDER.DIRECT_DEAL',
		},
		{
			id: TYPE.PROGRAMMATIC_GUARANTEED,
			text: 'LABEL.PROGRAMMATIC_GUARANTEED',
		},
		{
			id: TYPE.PREFERRED_DEAL,
			text: 'PLACEHOLDER.PREFERRED_DEAL',
		},
	];

	this.BIAS_TYPE = {
		NONE: 0,
		FLAT: 1,
		PERCENTAGE: 2,
	};

	this.BIAS_TYPES = ['NONE', 'FLAT', 'PERCENTAGE'];

	/**
	 * @type {Object}
	 */
	this.partnerships = {};

	/**
	 * loads all partnerships data
	 *
	 * @returns {Promise}
	 */
	this.loadAll = () =>
		Api.get('/api/supply/v1/partnerships')
			.then(({ partnerships = [] }) => partnerships)
			.then((partnershipsData) =>
				partnershipsData.reduce((partnerships, partnershipData) => {
					partnerships[partnershipData.id] = new Partnership(partnershipData);
					return partnerships;
				}, {}),
			)
			.then((partnerships) => {
				this.partnerships = partnerships;
				needReload = false;
			});

	const updatePartnershipsAndReturnPartnership = (partnershipData) => {
		this.partnerships[partnershipData.id] = new Partnership(partnershipData);
		return this.partnerships[partnershipData.id];
	};

	/**
	 * @param {Number} id
	 * @param {Object} params
	 *
	 * @returns {Promise}
	 */
	this.update = (id, params) => Api.post(`/api/supply/v1/partnerships/${id}`, params).then(updatePartnershipsAndReturnPartnership);

	/**
	 * @param {String} name
	 * @param {Number} demandId
	 * @param {Number} type
	 *
	 * @returns {Promise}
	 */
	this.add = (name, demandId, type) =>
		Api.post('/api/supply/v1/partnerships/add', {
			name,
			demandId,
			type,
		}).then(updatePartnershipsAndReturnPartnership);

	/**
	 * @param {Array.<Object>|Object} newPartnerships
	 * @param {boolean} csvUpload
	 * @returns {Promise}
	 */
	this.addPartnerships = (newPartnerships, csvUpload = false) =>
		Api.post(`/api/supply/v1/partnerships${csvUpload ? '?csv=1' : ''}`, { partnerships: newPartnerships }).then((partnerships) => {
			partnerships.map((partnershipData) => {
				this.partnerships[partnershipData.id] = new Partnership(partnershipData);
				return this.getById(partnershipData.id);
			});
			return partnerships;
		});

	/**
	 * @param {Number} id
	 * @param {Number} demandId
	 * @param {Number} type
	 * @param {Object} options
	 *
	 * @returns {Promise}
	 */
	this.duplicate = (id, demandId, type, options) =>
		Api.post(`/api/supply/v1/partnerships/${id}/duplicate`, { demandId, type, ...options }, true).then(updatePartnershipsAndReturnPartnership);

	/**
	 * We use this to update multiple partnerships
	 *
	 * @param {Array.<Object>|Object} changedPartnerships
	 * @param {boolean} csvUpload
	 * @return {Promise}
	 */
	this.patchUpdate = (changedPartnerships, csvUpload = false) =>
		Api.patch(`/api/supply/v1/partnerships${csvUpload ? '?csv=1' : ''}`, {
			partnerships: changedPartnerships,
		}).then((response) => {
			response.forEach((partnership) => {
				this.partnerships[partnership.id] = new Partnership(partnership);
			});
			return response;
		});

	/**
	 * Toggle the enabled/disabled state of a partnership.
	 *
	 * @param {Partnership} partnership
	 *
	 * @return {Promise}
	 */
	this.toggle = (partnership) => {
		const toggledState = new PartnershipState(partnership.getState().getStateId()).toggle();
		return this.update(partnership.getId(), {
			state: toggledState.getStateId(),
		});
	};

	this.markForReload = () => {
		needReload = true;
	};

	/**
	 * @param {Number} id
	 * @returns {Partnership}
	 */
	this.getById = (id) => this.partnerships[id];

	/**
	 * @returns {Array.<Partnership>}
	 */
	const getAll = () => Object.values(this.partnerships);

	/**
	 * @returns {DemandPartner}
	 */
	const getDemandPartner = (partnership) => DemandPartners.getById(partnership.getDemandId());

	/**
	 * @returns {boolean}
	 */
	const isVisible = (partnership) => getDemandPartner(partnership).isVisible();
	/**
	 * @returns {boolean}
	 */
	const isRtb = (partnership) => getDemandPartner(partnership).isRtb();
	/**
	 * @returns {boolean}
	 */
	const isDC = (partnership) => getDemandPartner(partnership).isDC();
	/**
	 * @returns {boolean}
	 */
	const isRtbDC = (partnership) => isRtb(partnership) || isDC(partnership);
	/**
	 * @returns {boolean}
	 */
	const isVisibleRtbDC = (partnership) => isVisible(partnership) && isRtbDC(partnership);

	/**
	 * Returns list of all RTB and DC Partnerships.
	 * @returns {Array.<Partnership>}
	 */
	this.getList = () => getAll().filter(isRtbDC);

	/**
	 * Returns list of all RTB and DC Partnerships.
	 * @returns {Array.<Partnership>}
	 */
	this.getListVisibleRtbDC = () => getAll().filter(isVisibleRtbDC);

	/**
	 * @returns {Array.<Partnership>}
	 */
	this.getListPG = () => getAll().filter((partnership) => isRtbDC(partnership) && partnership.isProgrammaticGuaranteed());

	/**
	 * @returns {Array.<{id: Number, name: String}>}
	 */
	this.getDemandPartnersList = () =>
		Array.from(
			this.getList().reduce((uniqueIds, { demandId, demandName }) => {
				uniqueIds.set(demandId, demandName);
				return uniqueIds;
			}, new Map()),
			([id, name]) => ({ id, name }),
		).sort(ascByNameField);

	/**
	 * @returns {Array.<{name: String, value: Number|String}>}
	 */
	this.getInvoiceTypesIdNameSelectOptions = () =>
		Object.values(INVOICE_TYPES)
			.map((invoiceTypeId) => ({
				name: INVOICE_TYPE_TRANSLATION[invoiceTypeId],
				value: invoiceTypeId,
			}))
			.filter((option) => option.value > 0) // remove 'none' option
			.sort(ascByNameField);

	this.getPartnershipTypesIdNameSelectOptions = () =>
		Object.values(TYPE).map((partnershipTypeId) => ({
			name: this.TYPES[partnershipTypeId].text,
			value: partnershipTypeId,
		}));

	/**
	 * @param {Number} id
	 * @returns {String}
	 */
	this.getName = (id) => (this.partnerships[id] ? this.partnerships[id].name : '');

	/**
	 * @returns {boolean}
	 */
	this.isReloadNeeded = () => needReload;

	this.downloadPartnerships = () => {
		return Api.getFile('/api/supply/v1/partnerships/download').then((response) => {
			const file = response.data;
			const fileName = getFilenameFromResponse(response.headers());
			const downloadLink = angular.element('<a/>');
			downloadLink.css({ display: 'none' });
			downloadLink
				.attr({
					href: URL.createObjectURL(file),
					download: fileName,
				})[0]
				.click();
			downloadLink.remove();
			URL.revokeObjectURL(file);
		});
	};

	this.downloadIncoming = () => {
		return Api.getFile('/api/supply/v1/partnerships/incoming-deals/download').then((response) => {
			const file = response.data;
			const fileName = getFilenameFromResponse(response.headers());
			const downloadLink = angular.element('<a/>');
			downloadLink.css({ display: 'none' });
			downloadLink
				.attr({
					href: URL.createObjectURL(file),
					download: fileName,
				})[0]
				.click();
			downloadLink.remove();
			URL.revokeObjectURL(file);
		});
	};

	const getFilenameFromResponse = (headers) => {
		const fileName = headers['content-disposition'].split(';')[1].trim().split('=')[1];
		if (fileName.match("''")) {
			return fileName.split("''")[1];
		}
		return fileName;
	};
}

export default Partnerships;
