function PartnershipDirectConnectOptions(Api) {
	'ngInject';

	/**
	 * @param {int} partnershipId
	 * @returns {promise}
	 */
	this.get = function get(partnershipId) {
		return Api.get(`/api/supply/v1/partnerships/${partnershipId}/directconnect`);
	};

	/**
	 * @param {int} partnershipId
	 * @param {Object} directConnectOptions
	 * @returns {promise}
	 */
	this.update = (partnershipId, directConnectOptions) => Api.put(`/api/supply/v1/partnerships/${partnershipId}/directconnect`, directConnectOptions);
}

export default PartnershipDirectConnectOptions;
