function UniqueFilter($parse) {
	'ngInject';

	/**
	 * @see https://github.com/angular-ui/ui-utils/blob/master/modules/unique/unique.js
	 * Filters out all duplicate items from an array by checking the specified key
	 * @param {Array} items
	 * @param {string} [filterOn] the name of the attribute of each object to compare for uniqueness
	 *	if the filterOn is empty, the entire object will be compared
	 *	if the filterOn === false then no filtering will be performed
	 * @return {Array}
	 */
	return function unique(items, filterOn) {
		var newItems,
			get,
			extractValueToCompare,
			result = items;

		if (filterOn === false) {
			return result;
		}

		if ((filterOn || angular.isUndefined(filterOn)) && angular.isArray(items)) {
			newItems = [];
			get = angular.isString(filterOn)
				? $parse(filterOn)
				: function returnItem(item) {
						return item;
					};
			extractValueToCompare = function extractValueToCompareFn(item) {
				return angular.isObject(item) ? get(item) : item;
			};

			angular.forEach(items, function forEach(item) {
				var i,
					isDuplicate = false,
					length = newItems.length;

				for (i = 0; i < length; i++) {
					if (angular.equals(extractValueToCompare(newItems[i]), extractValueToCompare(item))) {
						isDuplicate = true;
						break;
					}
				}
				if (!isDuplicate) {
					newItems.push(item);
				}
			});

			result = newItems;
		}
		return result;
	};
}

export default UniqueFilter;
