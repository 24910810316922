import listFilterHtml from '../../views/list-filter.html';

const listFilter = {
	transclude: {
		buttonArea: '?buttonArea',
	},
	bindings: {
		filterInstance: '<',
	},
	template: listFilterHtml,
	controller: 'ListFilterController as $ctrl',
};

export default listFilter;
