const asIdFloorPriceMap = (prices, { id, floorPrice }) => {
	prices[id] = { floorPrice };
	return prices;
};

const asIdFloorFixedPriceMap = (prices, { id, floorPrice, fixedPrice }) => {
	prices[id] = { floorPrice, fixedPrice };
	return prices;
};

function AdslotPartnerships(Api) {
	'ngInject';

	/**
	 * @param {int} adslotId
	 * @returns {promise}
	 */
	this.load = (adslotId) => Api.get(`api/supply/v1/adslots/${adslotId}/partnerships`).then((data) => data.adslotPartnerships);

	/**
	 * @param {int} adslotId
	 * @param {Array.<{id: <int>, floorPrice: <int>, fixedPrice: <int>}>} partnerships
	 * @returns {promise}
	 */
	this.updatePrices = (adslotId, partnerships) => Api.post(`api/supply/v1/adslots/${adslotId}/partnerships`, partnerships);

	/**
	 * @param {int} adslotId
	 * @param {Array.<int>} partnershipIds
	 * @returns {promise}
	 */
	this.add = (adslotId, partnershipIds) => Api.post(`api/supply/v1/adslots/${adslotId}/partnerships/add`, { ids: partnershipIds });

	/**
	 * @param {int} adslotId
	 * @param {Array.<int>} partnershipIds
	 * @returns {promise}
	 */
	this.remove = (adslotId, partnershipIds) => Api.post(`api/supply/v1/adslots/${adslotId}/partnerships/remove`, { ids: partnershipIds });

	/**
	 * @param {Array.<Object>} partnershipsList
	 * @returns {Object.<int, {floorPrice: <int>, fixedPrice: <int>}>} priceMap
	 */
	this.getPriceMap = (partnershipsList) => partnershipsList.reduce(asIdFloorFixedPriceMap, {});

	this.toIdFloorPriceMap = (partnershipsList) => partnershipsList.reduce(asIdFloorPriceMap, {});
}

export default AdslotPartnerships;
