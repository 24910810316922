function NoClick() {
	return function postLink(scope, iElement) {
		var unbindDestroyListener;

		function noclick(event) {
			event.stopPropagation();
		}

		iElement.on('click', noclick);

		unbindDestroyListener = scope.$on('$destroy', function $destroyListener() {
			iElement.off('click', noclick);
			unbindDestroyListener();
		});
	};
}

export default NoClick;
