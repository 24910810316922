function MultiSelect($compile, $filter, $parse, $rootScope, $timeout) {
	'ngInject';

	var idCounter = 1,
		MAX_SHOW_SELECTED_COUNT = 3,
		DEFAULT_OPTIONS = {
			allText: '',
			buttonClass: '',
			emptyText: 'PLACEHOLDER.CHOOSE',
			maxItems: MAX_SHOW_SELECTED_COUNT,
		};

	return {
		restrict: 'A',
		priority: 10,
		require: 'ngModel',
		scope: false,
		link: function postLink(scope, iElement, iAttrs, ngModelCtrl) {
			var options,
				selectOptionsArray,
				unbindDestroyListener,
				unbindDisabledWatch,
				unbindTranslateChangeListener,
				container = angular.element('<div class="dropdown"></div>'),
				dropdownContent = angular.element('<div class="dropdown-content" data-noclick></div>'),
				dropdownList = angular.element('<ul class="menu vertical"></ul>'),
				dropdownToggle = angular.element('<button type="button" class="expanded" data-yl-dropdown-toggle></button>');

			if (angular.isDefined(iAttrs.containerStyle)) {
				container.addClass(iAttrs.containerStyle);
			}

			if (angular.isDefined(iAttrs.contentStyle)) {
				dropdownContent.addClass(iAttrs.contentStyle);
			}

			if (angular.isUndefined(iAttrs.multiple)) {
				throw new Error('MultiSelect directive is missing the "multiple" attribute');
			}

			function toggleOption(event) {
				var optionToToggle = angular.element(
						Array.from(iElement.find('option')).find(function getOptionByValue(option) {
							return option.value === event.target.value;
						}),
					),
					checkbox = angular.element(event.target);
				optionToToggle.prop('selected', angular.element(event.target).prop('checked'));
				iElement.triggerHandler('change');

				if (checkbox.prop('checked')) {
					checkbox.parent().parent().addClass('active');
				} else {
					checkbox.parent().parent().removeClass('active');
				}
			}

			function buildDropdownList() {
				clearCheckboxChangeListeners();
				dropdownList.empty();

				selectOptionsArray.forEach(function appendToDropdownList(option) {
					var id = (iAttrs.name || idCounter++) + '-' + option.value,
						checkbox = angular.element('<input id="' + id + '" type="checkbox">'),
						listElement = angular.element('<li></li>'),
						label = angular.element('<label></label>'),
						labelText = angular.element('<label for="' + id + '" data-translate="' + option.text + '"></label>');

					checkbox.attr('value', option.value);
					checkbox.prop('checked', option.selected);
					checkbox.prop('disabled', scope.$eval(iAttrs.ngDisabled));
					checkbox.on('change', toggleOption);

					if (option.selected) {
						listElement.addClass('active');
					}

					label.append(checkbox).append($compile(labelText)(scope));
					dropdownList.append(listElement.append(label));
				});
			}

			function refreshButtonCaption() {
				var selectedOptions = Array.from(iElement.find('option'))
					.filter(function onlySelected(option) {
						return option.selected;
					})
					.map(function getLabel(option) {
						return option.label;
					});

				if (options.allText && selectedOptions.length === selectOptionsArray.length) {
					dropdownToggle.text($filter('translate')(options.allText));
				} else if (selectedOptions.length > options.maxItems) {
					dropdownToggle.text(
						$filter('translate')('CAPTION.N_SELECTED', {
							n: selectedOptions.length,
						}),
					);
				} else if (selectedOptions.length) {
					dropdownToggle.text(selectedOptions.join(', '));
				} else {
					dropdownToggle.text($filter('translate')(options.emptyText));
				}
			}

			function clearCheckboxChangeListeners() {
				Array.from(dropdownList.find('input')).forEach(function removeCheckboxChangeListeners(checkbox) {
					angular.element(checkbox).off('change', toggleOption);
				});
			}

			function init() {
				if (options.buttonClass) {
					dropdownToggle.addClass(options.buttonClass);
				}
				selectOptionsArray = Array.from(iElement.find('option'));
				buildDropdownList();
				refreshButtonCaption();
			}

			// hook into $parsers to not have to use $watch
			ngModelCtrl.$parsers.push(function onModelChange(viewValue) {
				refreshButtonCaption();
				return viewValue;
			});

			unbindDisabledWatch = scope.$watch(
				function isSelectDisabled() {
					return scope.$eval(iAttrs.ngDisabled);
				},
				function toggleDropdownDisabled(isDisabled) {
					Array.from(dropdownList.find('input')).forEach((checkbox) => {
						angular.element(checkbox).attr('disabled', isDisabled);
					});
				},
			);

			unbindTranslateChangeListener = $rootScope.$on('$translateChangeSuccess', init);

			unbindDestroyListener = scope.$on('$destroy', function $destroyListener() {
				clearCheckboxChangeListeners();
				unbindDisabledWatch();
				unbindTranslateChangeListener();
				unbindDestroyListener();
			});

			iElement.after($compile(container.append(dropdownToggle).append(dropdownContent.append(dropdownList)))(scope));
			iElement.addClass('multiselect');

			options = angular.extend({}, DEFAULT_OPTIONS, $parse(iAttrs.ylMultiSelect)(scope));

			// defer to let ngOptions complete first
			$timeout(init);
		},
	};
}

export default MultiSelect;
