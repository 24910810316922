import angular from 'angular';
import angularTranslate from 'angular-translate';

import BooleanFilter from './Boolean.js';
import ClipFilenameFilter from './ClipFilename.js';
import DealDurationInfoFilter from './DealDurationInfo.js';
import FilterMultipleFilter from './FilterMultiple.js';
import NumberIfNumberFilter from './numberIfNumber.js';
import ReplaceFilter from './Replace.js';
import StoreAsFilter from './storeAs.js';
import UniqueFilter from './Unique.js';
import UniqueReportRowsFilter from './UniqueReportRows.js';

const AngularFiltersModule = angular
	.module('app.modules.filters', [angularTranslate])

	.filter('boolean', BooleanFilter)
	.filter('dealDurationInfo', DealDurationInfoFilter)
	.filter('filterMultiple', FilterMultipleFilter)
	.filter('numberIfNumber', NumberIfNumberFilter)
	.filter('replace', ReplaceFilter)
	.filter('storeAs', StoreAsFilter)
	.filter('unique', UniqueFilter)
	.filter('uniqueReportRows', UniqueReportRowsFilter)
	.filter('clipFilename', ClipFilenameFilter).name;

export default AngularFiltersModule;
