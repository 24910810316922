import ROLE from './constants/UserRoles.js';

import loginHtml from './views/login.html';
import resetPasswordHtml from './views/resetPassword.html';
import appHtml from './views/app.html';
import dashboardHtml from './views/dashboard.html';
import navigationHtml from './views/insights/navigation.html';
import analyticsHtml from './views/insights/analytics.html';
import demandOverviewHtml from './views/insights/demand-overview.html';
import yesterdayHtml from './views/insights/yesterday.html';
import dealCheckHtml from './views/insights/deal-check.html';

function AppConfigRouting($locationProvider, $stateProvider, $urlRouterProvider) {
	'ngInject';

	$locationProvider.html5Mode(true).hashPrefix('');

	/* any unmatched state take us to the default page */
	// @see https://github.com/angular-ui/ui-router/issues/600#issuecomment-47228922
	// $urlRouterProvider.otherwise('/dashboard');
	$urlRouterProvider.otherwise(function otherwise($injector) {
		var $state = $injector.get('$state');
		$state.go('dashboard');
	});

	// make trailing spaces for resetPassword state optional
	$urlRouterProvider.rule(function rule($injector, $location) {
		var path = $location.url(),
			resetPasswordKey = 'resetPassword',
			resetPassword = path.indexOf(resetPasswordKey);

		if (resetPassword > -1 && resetPassword === path.length - resetPasswordKey.length) {
			return path + '/';
		}

		return undefined;
	});

	/* top level states for authentication and password reset */
	$stateProvider
		.state('site', {
			abstract: true,
		})
		.state('login', {
			parent: 'site',
			url: '/login',
			views: {
				'body@': {
					controller: 'LoginController',
					template: loginHtml,
				},
			},
			data: {
				onlyUnauthenticated: true,
			},
		})
		.state('logout', {
			parent: 'site',
			url: '/logout',
			views: {
				'body@': {
					controller: 'LogoutController',
				},
			},
		})
		.state('resetPassword', {
			parent: 'site',
			url: '/resetPassword/{code:.*}',
			views: {
				'body@': {
					controller: 'ResetPasswordController',
					controllerAs: 'rpCtrl',
					template: resetPasswordHtml,
				},
			},
			data: {
				onlyUnauthenticated: true,
			},
		})
		.state('app', {
			parent: 'site',
			url: '',
			views: {
				'body@': {
					template: appHtml,
				},
			},
			data: {
				authorizedRoles: ROLE.ALL,
			},
		});

	/* application level states */
	$stateProvider
		.state('dashboard', {
			parent: 'app',
			url: '/dashboard',
			views: {
				main: {
					template: dashboardHtml,
					controller: 'DashboardController',
				},
			},
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER, ROLE.REPORTS],
			},
		})
		.state('insights', {
			parent: 'app',
			abstract: true,
			url: '/insights',
			views: {
				'subMenu@app': {
					template: navigationHtml,
					controller: 'AnalyticsController',
				},
			},
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER, ROLE.REPORTS],
			},
		})
		.state('supplyOverview', {
			parent: 'insights',
			url: '/supply-overview',
			views: {
				'main@app': {
					template: analyticsHtml,
				},
			},
		})
		.state('demandOverview', {
			parent: 'insights',
			url: '/demand-overview',
			views: {
				'main@app': {
					template: demandOverviewHtml,
				},
			},
		})
		.state('yesterday', {
			parent: 'insights',
			url: '/yesterday',
			views: {
				'main@app': {
					template: yesterdayHtml,
				},
			},
		})
		.state('dealCheck', {
			parent: 'insights',
			url: '/deal-check',
			views: {
				'main@app': {
					template: dealCheckHtml,
				},
			},
		});
}

export default AppConfigRouting;
