import adslotsListFilterHtml from '../../../views/adslots/adslots-list-filter.html';

export default {
	transclude: {
		buttonArea: '?buttonArea',
	},
	bindings: {
		filterId: '@',
		filterInstance: '<',
		filterReset: '<?',
	},
	template: adslotsListFilterHtml,
	controller: 'AdslotListFilterController',
};
