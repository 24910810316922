import angular from 'angular';

import AdslotFallback from './AdslotFallback.js';
import AdslotFallbackDefault from './AdslotFallbackDefault.js';
import AdslotFallbackXml from './AdslotFallbackXml.js';
import Advertiser from './Advertiser.js';
import BlackWhiteFilter from './BlackWhiteFilter.js';
import Channel from './Channel.js';
import Format from './Format.js';
import ModalCloser from './ModalCloser.js';
import ReportRow from './ReportRow.js';
import RevenueReport from './RevenueReport.js';
import SummaryRow from './SummaryRow.js';
import TotalRow from './TotalRow.js';

const ModelsModule = angular
	.module('app.models', [])

	.factory('AdslotFallback', AdslotFallback)
	.factory('AdslotFallbackDefault', AdslotFallbackDefault)
	.factory('AdslotFallbackXml', AdslotFallbackXml)
	.factory('Advertiser', Advertiser)
	.factory('BlackWhiteFilter', BlackWhiteFilter)
	.factory('Channel', Channel)
	.factory('Format', Format)
	.factory('ModalCloser', ModalCloser)
	.factory('ReportRow', ReportRow)
	.factory('RevenueReport', RevenueReport)
	.factory('SummaryRow', SummaryRow)
	.factory('TotalRow', TotalRow).name;

export default ModelsModule;
