import { ascByNameField } from '../utils/sort-utils.js';

function toIdNameObject(entry) {
	const [id, name] = entry;
	return {
		id: id * 1, // to int
		name,
	};
}

function AdslotTranslations($filter, Api) {
	'ngInject';

	const PLCMT_IN_FEED = 1;
	const PLCMT_IN_CONTENT = 2;
	const PLCMT_OUTSIDE_CONTENT = 3;
	const PLCMT_RECO_WIDGET = 4;

	const AUCTION_TYPE_SECOND_PRICE = 0;
	const AUCTION_TYPE_FIRST_PRICE = 1;

	const VIDEO_PLACEMENT_TYPE_INSTREAM = 1;
	const VIDEO_PLACEMENT_TYPE_ACCOMPANYING_CONTENT = 2;
	const VIDEO_PLACEMENT_TYPE_INTERSTITIAL = 3;
	const VIDEO_PLACEMENT_TYPE_NO_CONTENT_STANDALONE = 4;

	/**
	 * @type {Object.<string, Object.<int, string>>}
	 */
	this.translations = {};

	/**
	 * @return {promise}
	 */
	this.loadAll = () =>
		Api.get('/api/supply/v1/adslots/translations').then((translationsMap) => {
			this.translations = translationsMap;
		});

	/**
	 * @returns {Object.<int, string>}
	 */
	this.getPlatformTypes = function getPlatformTypes() {
		return this.translations.platformTypes;
	};

	/**
	 * @returns {Array}
	 */
	this.getPlatformTypesAsArraySortAscNameField = () => Object.entries(this.getPlatformTypes()).map(toIdNameObject).sort(ascByNameField);

	/**
	 * @returns {Object.<int, string>}
	 */
	this.getAdTypes = function getAdTypes() {
		return this.translations.adTypes;
	};

	/**
	 * @returns {Array}
	 */
	this.getAdTypesAsArraySortAscNameField = () => Object.entries(this.getAdTypes()).map(toIdNameObject).sort(ascByNameField);

	/**
	 * @returns {Object.<int, string>}
	 */
	this.getApiTypes = function getApiTypes() {
		return this.translations.api;
	};

	/**
	 * @returns {Object.<int, string>}
	 */
	this.getStartDelayTypes = function getStartDelayTypes() {
		return this.translations.startdelay;
	};

	/**
	 * @returns {Object.<int, string>}
	 */
	this.getMimeTypes = function getMimeTypes() {
		return this.translations.mimes;
	};

	/**
	 * @returns {Object.<int, string>}
	 */
	this.getSkippability = function getSkippability() {
		return {
			'LABEL.NON_SKIPPABLE': false,
			'LABEL.SKIPPABLE': true,
		};
	};

	/**
	 * @returns {Object.<int, string>}
	 */
	this.getProtocolTypes = function getProtocolTypes() {
		return this.translations.protocols;
	};

	/**
	 * @returns {Object.<int, string>}
	 */
	this.getMobileOsTypes = function getMobileOsTypes() {
		return this.translations.mobileOS;
	};

	/**
	 * @returns {Object.<int, string>}
	 */
	this.getPositions = function getPositions() {
		return this.translations.position;
	};

	/**
	 * @returns {Object.<int, string>}
	 */
	this.getAuctionTypes = function getAuctionTypes() {
		return {
			[AUCTION_TYPE_SECOND_PRICE]: '2nd Price',
			[AUCTION_TYPE_FIRST_PRICE]: '1st Price',
		};
	};

	/**
	 * @returns {Object.<int, string>}
	 */
	this.getPlacementTypes = function getPlacementTypes() {
		return {
			[PLCMT_IN_FEED]: 'In Feed',
			[PLCMT_IN_CONTENT]: 'In Content',
			[PLCMT_OUTSIDE_CONTENT]: 'LABEL.PLCMT_OUTSIDE_CONTENT',
			[PLCMT_RECO_WIDGET]: 'Recommendation Widget',
		};
	};

	/**
	 * @returns {Object.<int, string>}
	 */
	this.getVideoPlacementTypes = function getVideoPlacementTypes() {
		return {
			[VIDEO_PLACEMENT_TYPE_INSTREAM]: 'Instream',
			[VIDEO_PLACEMENT_TYPE_ACCOMPANYING_CONTENT]: 'Accompanying content',
			[VIDEO_PLACEMENT_TYPE_INTERSTITIAL]: 'Interstitial',
			[VIDEO_PLACEMENT_TYPE_NO_CONTENT_STANDALONE]: 'No content/Standalone',
		};
	};
}

export default AdslotTranslations;
