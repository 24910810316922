function PartnershipsAdvertisersController(
	$scope,
	availableAdvertisers,
	Advertisers,
	ListSearchService,
	Partnerships,
	$timeout,
	PartnershipsAdvertisers,
	toaster,
	InfoService,
	AuthService,
) {
	'ngInject';

	var ALLOW = 'allow',
		BLOCK = 'block';

	/**
	 * @returns {Array}
	 */
	function flattenAdvertisers() {
		var entry,
			entryIdx,
			i,
			newEntry,
			result = [];

		for (entryIdx = 0; entryIdx < availableAdvertisers.length; entryIdx++) {
			entry = availableAdvertisers[entryIdx];

			for (i = 0; i < entry.available.length; i++) {
				newEntry = {
					partnership: Partnerships.getName(entry.partnershipId),
					partnershipId: entry.partnershipId,
					advertiser: Advertisers.getById(entry.available[i]) ? Advertisers.getById(entry.available[i]).getName() : '',
					advertiserId: entry.available[i],
				};

				// if we get an advertiser id which is not present in our advertiser map yet, it's probably quite new and not in cache so far
				if (newEntry.advertiser === '') {
					// check if we already tried to update the cache in the current run of the application
					if (!$scope.cacheAlreadyUpdated) {
						$scope.cacheNeedsUpdate = true;
					}
				} else {
					result.push(newEntry);
				}
			}
		}

		if ($scope.cacheNeedsUpdate) {
			Advertisers.loadAll().then(function success() {
				$scope.cacheAlreadyUpdated = true; // we only wanna update cache once per application run
				$scope.cacheNeedsUpdate = false; // for the loading spinner in the UI
				$scope.availableAdvertisers = flattenAdvertisers();
			});
		}

		return result;
	}

	/**
	 * @param {int} partnershipId
	 * @param {int} advertiserId
	 * @returns {string}
	 */
	function doneIndex(partnershipId, advertiserId) {
		return partnershipId + '-' + advertiserId;
	}

	function markRowAsDone(partnershipId, advertiserId, action) {
		$timeout(function timeout() {
			var idx = doneIndex(partnershipId, advertiserId);
			$scope.done[idx] = action;
			delete $scope.error[idx];
			InfoService.endRequest();
		}, 200);
	}

	function markRowAsError(partnershipId, advertiserId) {
		$timeout(function timeout() {
			$scope.error[doneIndex(partnershipId, advertiserId)] = true;
			toaster.errorMessage('MESSAGE.AVAILABLE_ADVERTISERS_ACTION_FAILED');
			InfoService.endRequest();
		}, 200);
	}

	$scope.done = {};
	$scope.error = {};
	$scope.filter = ListSearchService.get('available-advertisers-search');
	$scope.availableAdvertisers = flattenAdvertisers();
	$scope.cacheNeedsUpdate = false;
	$scope.cacheAlreadyUpdated = false;

	/**
	 * @param {int} partnershipId
	 * @param {int} advertiserId
	 * @returns {boolean}
	 */
	$scope.isDone = function isDone(partnershipId, advertiserId) {
		return !!$scope.done[partnershipId + '-' + advertiserId];
	};

	/**
	 * @param {int} partnershipId
	 * @param {int} advertiserId
	 * @returns {boolean}
	 */
	$scope.hasSuccessClass = function hasSuccessClass(partnershipId, advertiserId) {
		return !$scope.isDone(partnershipId, advertiserId) || $scope.done[doneIndex(partnershipId, advertiserId)] === ALLOW;
	};

	/**
	 * @param {int} partnershipId
	 * @param {int} advertiserId
	 * @returns {boolean}
	 */
	$scope.hasDangerClass = function hasDangerClass(partnershipId, advertiserId) {
		return !$scope.isDone(partnershipId, advertiserId) || $scope.done[doneIndex(partnershipId, advertiserId)] === BLOCK;
	};

	/**
	 * @param {int} partnershipId
	 * @param {int} advertiserId
	 * @returns {boolean}
	 */
	$scope.actionFailed = function actionFailed(partnershipId, advertiserId) {
		return !!$scope.error[doneIndex(partnershipId, advertiserId)];
	};

	/**
	 * @param {int} partnershipId
	 * @param {int} advertiserId
	 * @returns {void}
	 */
	$scope.allow = function allow(partnershipId, advertiserId) {
		if (AuthService.isAdmin() && !InfoService.isRequestInProgress()) {
			toaster.clear();
			InfoService.startRequest();

			PartnershipsAdvertisers.allowAdvertisers(partnershipId, advertiserId)
				.then(function success() {
					markRowAsDone(partnershipId, advertiserId, ALLOW);
				})
				.catch(function error() {
					markRowAsError(partnershipId, advertiserId);
				});
		}
	};

	/**
	 * @param {int} partnershipId
	 * @param {int} advertiserId
	 * @returns {void}
	 */
	$scope.block = function block(partnershipId, advertiserId) {
		if (AuthService.isAdmin() && !InfoService.isRequestInProgress()) {
			toaster.clear();
			InfoService.startRequest();

			PartnershipsAdvertisers.blockAdvertisers(partnershipId, advertiserId)
				.then(function success() {
					markRowAsDone(partnershipId, advertiserId, BLOCK);
				})
				.catch(function error() {
					markRowAsError(partnershipId, advertiserId);
				});
		}
	};
}

export default PartnershipsAdvertisersController;
