function SeasonsService($log) {
	'ngInject';

	// @link view-source:https://stellafane.org/misc/equinox.html

	const SEASONS = {
		SPRING: 'spring',
		SUMMER: 'summer',
		AUTUMN: 'autumn',
		WINTER: 'winter',
	};

	/**
	 * Emulates BASIC's INT Function
	 * @param n
	 * @returns {number}
	 * @constructor
	 */
	function int(n) {
		return Math.floor(n);
	}

	/**
	 * Square a number
	 * @param {number} n
	 * @returns {number}
	 */
	function pow2(n) {
		return n ** 2;
	}

	/**
	 * Cube a number
	 * @param {number} n
	 * @returns {number}
	 */
	function pow3(n) {
		return n ** 3;
	}

	/**
	 * Number to the 4th power
	 * @param {number} n
	 * @returns {number}
	 */
	function pow4(n) {
		return n ** 4;
	}

	/**
	 * Cosine function with degrees as input
	 * @param deg
	 * @returns {number}
	 */
	function cos(deg) {
		return Math.cos((deg * Math.PI) / 180);
	}

	/**
	 * Calculate 24 Periodic Terms
	 * Meeus Astronomical Algorithms Chapter 27
	 * @param t
	 * @returns {number}
	 */
	function periodic24(t) {
		const a = [485, 203, 199, 182, 156, 136, 77, 74, 70, 58, 52, 50, 45, 44, 29, 18, 17, 16, 14, 12, 12, 12, 9, 8];
		const b = [
			324.96, 337.23, 342.08, 27.85, 73.14, 171.52, 222.54, 296.72, 243.58, 119.81, 297.17, 21.02, 247.54, 325.15, 60.93, 155.12, 288.79, 198.04, 199.76, 95.39,
			287.11, 320.81, 227.73, 15.45,
		];
		const c = [
			1934.136, 32964.467, 20.186, 445267.112, 45036.886, 22518.443, 65928.934, 3034.906, 9037.513, 33718.147, 150.678, 2281.226, 29929.562, 31555.956,
			4443.417, 67555.328, 4562.452, 62894.029, 31436.921, 14577.848, 31931.756, 34777.259, 1222.114, 16859.074,
		];

		let s = 0;
		for (let i = 0; i < 24; i++) {
			s += a[i] * cos(b[i] + c[i] * t);
		}

		return s;
	}

	/**
	 * Julian Date to UTC Date Object
	 * Meeus Astronomical Algorithms Chapter 7
	 * @param JD - Julian Date, possible with fractional days
	 * @returns {Date} -  Output is a JavaScript UTC Date Object
	 */
	function fromJDtoUTC(JD) {
		let A;
		let alpha;
		let B;
		let C;
		let D;
		let Day; // Day of Month without decimals for time
		let DT; // Day of Month with decimals for time
		let E;
		let F; // Fractional JD's
		let H; // Hours and fractional hours
		let Hr; // Integer Hours
		let M; // Minutes and fractional minutes
		let Min; // Integer Minutes
		let Mon; // Month Number
		let Sec; // Integer Seconds (Milliseconds discarded)
		let theDate; // Create and set a JavaScript Date Object and return it
		let Yr; // Year
		let Z; // Integer JD's

		Z = int(JD + 0.5);
		F = JD + 0.5 - Z;
		if (Z < 2299161) {
			A = Z;
		} else {
			alpha = int((Z - 1867216.25) / 36524.25);
			A = Z + 1 + alpha - int(alpha / 4);
		}
		B = A + 1524;
		C = int((B - 122.1) / 365.25);
		D = int(365.25 * C);
		E = int((B - D) / 30.6001);
		DT = B - D - int(30.6001 * E) + F;
		Mon = E - (E < 13.5 ? 1 : 13);
		Yr = C - (Mon > 2.5 ? 4716 : 4715);
		Day = int(DT);
		H = 24 * (DT - Day);
		Hr = int(H);
		M = 60 * (H - Hr);
		Min = int(M);
		Sec = int(60 * (M - Min));

		// Create and set a JavaScript Date Object and return it
		theDate = new Date(0);
		theDate.setUTCFullYear(Yr, Mon - 1, Day);
		theDate.setUTCHours(Hr, Min, Sec);

		return theDate;
	}

	/**
	 * Correct TDT to UTC
	 * from Meeus Astronomical Algorithms Chapter 10
	 *
	 * @param tobj
	 * @returns {Date} - JavaScript native time is in milliseconds
	 */
	function fromTDTtoUTC(tobj) {
		// Correction lookup table has entry for every even year between TBLfirst and TBLlast
		var TBLfirst = 1620, // Range of years in lookup table
			TBLlast = 2002, // Range of years in lookup table
			// Corrections in Seconds
			// 2002 last entry
			TBL = [
				//
				121,
				112,
				103,
				95,
				88,
				82,
				77,
				72,
				68,
				63,
				60,
				56,
				53,
				51,
				48,
				46,
				44,
				42,
				40,
				38, // 1620
				35,
				33,
				31,
				29,
				26,
				24,
				22,
				20,
				18,
				16,
				14,
				12,
				11,
				10,
				9,
				8,
				7,
				7,
				7,
				7, // 1660
				7,
				7,
				8,
				8,
				9,
				9,
				9,
				9,
				9,
				10,
				10,
				10,
				10,
				10,
				10,
				10,
				10,
				11,
				11,
				11, // 1700
				11,
				11,
				12,
				12,
				12,
				12,
				13,
				13,
				13,
				14,
				14,
				14,
				14,
				15,
				15,
				15,
				15,
				15,
				16,
				16, // 1740
				16,
				16,
				16,
				16,
				16,
				16,
				15,
				15,
				14,
				13, // 1780
				13.1,
				12.5,
				12.2,
				12.0,
				12.0,
				12.0,
				12.0,
				12.0,
				12.0,
				11.9,
				11.6,
				11.0,
				10.2,
				9.2,
				8.2, // 1800
				7.1,
				6.2,
				5.6,
				5.4,
				5.3,
				5.4,
				5.6,
				5.9,
				6.2,
				6.5,
				6.8,
				7.1,
				7.3,
				7.5,
				7.6, // 1830
				7.7,
				7.3,
				6.2,
				5.2,
				2.7,
				1.4,
				-1.2,
				-2.8,
				-3.8,
				-4.8,
				-5.5,
				-5.3,
				-5.6,
				-5.7,
				-5.9, // 1860
				-6.0,
				-6.3,
				-6.5,
				-6.2,
				-4.7,
				-2.8,
				-0.1,
				2.6,
				5.3,
				7.7,
				10.4,
				13.3,
				16.0,
				18.2,
				20.2, // 1890
				21.1,
				22.4,
				23.5,
				23.8,
				24.3,
				24.0,
				23.9,
				23.9,
				23.7,
				24.0,
				24.3,
				25.3,
				26.2,
				27.3,
				28.2, // 1920
				29.1,
				30.0,
				30.7,
				31.4,
				32.2,
				33.1,
				34.0,
				35.0,
				36.5,
				38.3,
				40.2,
				42.2,
				44.5,
				46.5,
				48.5, // 1950
				50.5,
				52.5,
				53.8,
				54.9,
				55.8,
				56.9,
				58.3,
				60.0,
				61.6,
				63.0,
				63.8,
				64.3, // 1980
			],
			// Values for Delta T for 2000 through 2002 from NASA
			deltaT = 0, // deltaT = TDT - UTC (in Seconds)
			Year = tobj.getUTCFullYear(),
			t = (Year - 2000) / 100; // Centuries from the epoch 2000.0

		if (Year >= TBLfirst && Year <= TBLlast) {
			// Find correction in table
			if (Year % 2) {
				// Odd year - interpolate
				deltaT = (TBL[(Year - TBLfirst - 1) / 2] + TBL[(Year - TBLfirst + 1) / 2]) / 2;
			} else {
				// Even year - direct table lookup
				deltaT = TBL[(Year - TBLfirst) / 2];
			}
		} else if (Year < 948) {
			deltaT = 2177 + 497 * t + 44.1 * pow2(t);
		} else if (Year >= 948) {
			deltaT = 102 + 102 * t + 25.3 * pow2(t);
			if (Year >= 2000 && Year <= 2100) {
				// Special correction to avoid discontinurity in 2000
				deltaT += 0.37 * (Year - 2100);
			}
		} else {
			$log.error('Error: TDT to UTC correction not computed');
		}
		return new Date(tobj.getTime() - deltaT * 1000);
	}

	/**
	 * Calculate the beginning date of a preferred season.
	 *
	 * @param {string} season
	 * @param {number} year
	 * @returns {Date}
	 */
	function calculateBeginningOfSeason(season, year) {
		const JDE0 = calculateInitialDateEstimate(season, year); // Initial estimate of date of event
		const T = (JDE0 - 2451545.0) / 36525;
		const W = 35999.373 * (T - 2.47);
		const dL = 1 + 0.0334 * cos(W) + 0.0007 * cos(2 * W);
		const S = periodic24(T);
		const JDE = JDE0 + (0.00001 * S) / dL; // This is the answer in Julian Emphemeris Days
		const TDT = fromJDtoUTC(JDE); // Convert Julian Days to TDT in a Date Object

		return fromTDTtoUTC(TDT); // Correct TDT to UTC, both as Date Objects
	}

	/**
	 * Initial estimate of date of event
	 *
	 * @param {string} season
	 * @param {number} year
	 * @returns {number}
	 */
	function calculateInitialDateEstimate(season, year) {
		// Valid for years 1000 to 3000
		const Y = (year - 2000) / 1000;

		let result;
		switch (season) {
			case SEASONS.SPRING:
				result = 2451623.80984 + 365242.37404 * Y + 0.05169 * pow2(Y) - 0.00411 * pow3(Y) - 0.00057 * pow4(Y);
				break;
			case SEASONS.SUMMER:
				result = 2451716.56767 + 365241.62603 * Y + 0.00325 * pow2(Y) + 0.00888 * pow3(Y) - 0.0003 * pow4(Y);
				break;
			case SEASONS.AUTUMN:
				result = 2451810.21715 + 365242.01767 * Y - (0.11575 * pow2(Y) + 0.00337 * pow3(Y) + 0.00078 * pow4(Y));
				break;
			case SEASONS.WINTER:
				result = 2451900.05952 + (365242.74049 * Y - 0.06223 * pow2(Y) - 0.00823 * pow3(Y)) + 0.00032 * pow4(Y);
				break;
			default:
				break;
		}

		return result;
	}

	/**
	 * Get the beginning date of a preferred season.
	 *
	 * @param season
	 * @param year
	 * @returns {Date}
	 */
	this.getSeasonStart = function getSeasonStart(season, year) {
		const today = new Date();
		const Y = year || today.getFullYear();

		// Main calculation started here
		return calculateBeginningOfSeason(season, Y);
	};

	this.beginningOfSpring = function beginningOfSpring(year) {
		return this.getSeasonStart(SEASONS.SPRING, year);
	};

	this.beginningOfSummer = function beginningOfSummer(year) {
		return this.getSeasonStart(SEASONS.SUMMER, year);
	};

	this.beginningOfAutumn = function beginningOfAutumn(year) {
		return this.getSeasonStart(SEASONS.AUTUMN, year);
	};

	this.beginningOfWinter = function beginningOfWinter(year) {
		return this.getSeasonStart(SEASONS.WINTER, year);
	};
}

export default SeasonsService;
