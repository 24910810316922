function DropdownToggle($document, $timeout, $window) {
	'ngInject';

	const DROPDOWN_CLASS = 'dropdown';
	const DROPDOWN_ACTIVE_CLASS = 'is-active';
	const DROPDOWN_CONTENT_CLASS = 'dropdown-content';
	const REPOSITION_RIGHT_CLASS = 'js-reposition-right';

	function repositionContainer($container) {
		const $content = $container.find('.' + DROPDOWN_CONTENT_CLASS);

		/*
		 * @todo a general thought:
		 * I think we should find a more generic approach in the future for this. Sometimes
		 * it is indeed desired to explicitly define the open direction of a dropdown and this
		 * directive should then be able to also adjust the positioning from e.g. right to left
		 */

		if ($content.length) {
			// cleanup potentially leftovers to reset consistent UI state
			$container.removeClass(REPOSITION_RIGHT_CLASS);

			const contentRect = $content[0].getBoundingClientRect();
			const contentLeft = contentRect.left + $document[0].body.scrollLeft;
			const contentWidth = $content[0].offsetWidth;
			const windowWidth = $window.innerWidth;

			const openRight = contentLeft > 0 && contentLeft + contentWidth > windowWidth;

			if (openRight) {
				$container.addClass(REPOSITION_RIGHT_CLASS);
			}
		}
	}

	return {
		link(scope, iElement) {
			const container = iElement.parent();

			const open = () => {
				container.addClass(DROPDOWN_ACTIVE_CLASS);
				$timeout(() => $document.on('click', close));
			};

			const close = () => {
				container.removeClass(DROPDOWN_ACTIVE_CLASS);
				$document.off('click', close);
			};

			const closeOthers = () => {
				const dropdowns = $document[0].querySelectorAll('.' + DROPDOWN_CLASS + '.' + DROPDOWN_ACTIVE_CLASS);

				Array.from(dropdowns).forEach((dropdownContainer) => {
					angular.element(dropdownContainer).removeClass(DROPDOWN_ACTIVE_CLASS);
				});
			};

			const onDropdownToggleClick = (event) => {
				event.stopPropagation();
				if (container.hasClass(DROPDOWN_ACTIVE_CLASS)) {
					close(container);
				} else {
					closeOthers();
					open(container);
					repositionContainer(container);
				}
			};

			iElement.on('click', onDropdownToggleClick);

			const unbindDestroyListener = scope.$on('$destroy', () => {
				iElement.off('click', onDropdownToggleClick);
				$document.off('click', close);
				unbindDestroyListener();
			});
		},
	};
}

export default DropdownToggle;
