import isNumber from 'lodash/isNumber.js';

import InventoryType from '../../../../models/InventoryType.js';
import Partnership from '../../../../models/Partnership.js';
import SelectionService from '../../../../modules/utils/SelectionService.js';

function AdslotPartnershipsController(
	$scope,
	$stateParams,
	AdslotPartnerships,
	Partnerships,
	toaster,
	DemandPartners,
	DemandTechnologies,
	ListFilterFactory,
	InfoService,
	ModalFactory,
	PagerService,
	resolvedPrices,
	resolvedPartnerships,
) {
	'ngInject';

	const assignedPartnershipsListId = `adslot-${$stateParams.id}-assigned-partnerships`;
	const COLSPAN = 5;
	const COLSPAN_SELECTMODE = COLSPAN + 1;

	const isRtbDCPartnership = (partnership) => {
		const demandPartner = DemandPartners.getById(Partnerships.getById(partnership.id).getDemandId());
		return demandPartner.isRtb() || demandPartner.isDC();
	};

	function getAvailablePartnerships(assignedPartnerships) {
		const currentlyAssignedPartnerships = new Set(assignedPartnerships.map(({ id }) => +id));

		return (
			Partnerships.getListVisibleRtbDC()
				// not yet assigned
				.filter((partnership) => !currentlyAssignedPartnerships.has(partnership.id))
				// matching inventory type
				.filter((partnership) => {
					const adslot = $scope.localModels.adslot;
					const demandPartner = DemandPartners.getById(partnership.demandId);
					const allowedInventoryTypes = DemandTechnologies.getById(demandPartner.getTechnologyId()).getAllowedInventoryTypes();
					const inventoryType = new InventoryType(adslot.adType, adslot.platformType);

					return allowedInventoryTypes.has(inventoryType);
				})
				// match programmatic guaranteed
				.filter((partnership) => {
					const adslot = $scope.localModels.adslot;

					return (
						(!partnership.isProgrammaticGuaranteed() && !adslot.isProgrammaticGuaranteed()) ||
						(partnership.isProgrammaticGuaranteed() && adslot.isProgrammaticGuaranteed())
					);
				})
		);
	}

	function copyPricesMap(pricesMap) {
		return Object.keys(pricesMap).reduce((prices, adslotId) => {
			prices[adslotId] = { ...pricesMap[adslotId] };
			return prices;
		}, {});
	}

	function toPartnershipsList(pricesMap) {
		return Object.entries(pricesMap)
			.map(([id]) => Partnerships.getById(id))
			.map(Partnership.clone);
	}

	function isValidPriceConfig(partnershipId) {
		const { floorPrice } = $scope.models.prices[partnershipId];
		return isNumber(floorPrice) && floorPrice >= 0;
	}

	const selection = new SelectionService();

	$scope.models = {
		filteredPartnerships: [],

		partnerships: resolvedPartnerships.filter(isRtbDCPartnership),
		prices: resolvedPrices,
		// store copy to be able to compute changes later on
		initialPrices: copyPricesMap(resolvedPrices),
		invalidPrices: {},

		assignedPartnershipsListId,
		assignedPartnershipsListFilter: ListFilterFactory.get(ListFilterFactory.LAYOUTS.PARTNERSHIPS.PRICING).load(
			ListFilterFactory.DEFAULT_SETTINGS.PARTNERSHIPS.LIST,
		),

		colspan: COLSPAN,

		sortKey: 'name',

		isMultisize: $scope.localModels.adslot.isMultisize(),

		isRemoveMode: false,
		toggleRemoveMode: () => {
			$scope.models.isRemoveMode = !$scope.models.isRemoveMode;

			selection.clear();

			$scope.models.colspan = $scope.models.isRemoveMode ? COLSPAN_SELECTMODE : COLSPAN;
		},

		getFilteredPartnerships: () => $scope.models.filteredPartnerships,

		selection,
		toggleOne: (partnershipId) => selection.toggleOne(partnershipId),

		// used in view html
		onPriceChange(partnershipId) {
			if (isValidPriceConfig(partnershipId)) {
				delete $scope.models.invalidPrices[partnershipId];
			} else {
				$scope.models.invalidPrices[partnershipId] = true;
			}
		},

		// used in view html
		addPartnerships() {
			const listFilter = $scope.models.assignedPartnershipsListFilter;
			const listFilterSettings = listFilter.getActiveFilterData();

			ModalFactory.addPartnershipsToAdslotModal(
				{
					adslotId: $stateParams.id,
					partnerships: getAvailablePartnerships($scope.models.partnerships),
				},
				(adslotPartnershipsPricesList) => {
					// list is empty if modal was cancelled
					if (adslotPartnershipsPricesList.length > 0) {
						// @todo why was this implemented in the first place?
						// I don't think it is relevant anymore so we remove it for now and see what happens
						// // reload from api if adslot is not optimized as enabled state might have changed then
						// if (!$scope.localModels.adslot.optimized) {
						// 	return Adslots.load($scope.localModels.adslot.id)
						// 		.then((reloadedAdslot) => {
						// 			$scope.methods.setScopeAdslot(reloadedAdslot);
						// 		});
						// }

						$scope.models.prices = AdslotPartnerships.toIdFloorPriceMap(adslotPartnershipsPricesList);
						$scope.models.initialPrices = AdslotPartnerships.toIdFloorPriceMap(adslotPartnershipsPricesList);
						$scope.models.partnerships = toPartnershipsList($scope.models.prices).filter(isRtbDCPartnership);
					}

					// as the list filter objects are singletons and we only load different data per page we need to restore
					// the filter to it's previous (from before opening the modal) state
					listFilter.load(listFilterSettings);
				},
			);
		},

		// used in view html
		removePartnerships() {
			const partnershipIds = $scope.models.selection.values();

			if (partnershipIds.length) {
				InfoService.startRequest();

				AdslotPartnerships.remove($stateParams.id, partnershipIds)
					.then((adslotPartnershipsPricesList) => {
						toaster.successMessage('MESSAGE.ADSLOT_SAVE_SUCCESS');

						$scope.models.prices = AdslotPartnerships.toIdFloorPriceMap(adslotPartnershipsPricesList);
						$scope.models.initialPrices = AdslotPartnerships.toIdFloorPriceMap(adslotPartnershipsPricesList);
						$scope.models.partnerships = toPartnershipsList($scope.models.prices).filter(isRtbDCPartnership);

						$scope.models.toggleRemoveMode();
					})
					// @todo why was this implemented in the first place?
					// I don't think it is relevant anymore so we remove it for now and see what happens
					// .then(function reloadAdslotIfNeeded() {
					// 	// reload from api if adslot is not optimized as enabled state might have changed then
					// 	if (!$scope.localModels.adslot.optimized) {
					// 		return Adslots.load($scope.localModels.adslot.id);
					// 	}
					// 	return false;
					// })
					// .then(function refreshScopeValuesIfNeeded(reloadedAdslot) {
					// 	if (reloadedAdslot !== false) {
					// 		$scope.methods.setScopeAdslot(reloadedAdslot);
					// 	}
					//
					// 	return true;
					// })
					.catch((errorObject) => {
						if (errorObject.code === 30019) {
							toaster.errorMessage('MESSAGE.ADSLOT_SAVE_ERROR_NON_RTB_PARTNERSHIP');
						} else {
							toaster.errorMessage('MESSAGE.ADSLOT_SAVE_ERROR');
						}
					})
					.finally(() => InfoService.endRequest());
			} else {
				$scope.models.toggleRemoveMode();
			}
		},

		// used in view html
		savePrices() {
			if (Object.keys($scope.models.invalidPrices).length === 0) {
				const updatedPrices = Object.keys($scope.models.prices).reduce((pricesList, elementId) => {
					const { floorPrice } = $scope.models.prices[elementId];

					if (floorPrice !== $scope.models.initialPrices[elementId].floorPrice) {
						pricesList.push({ id: +elementId, floorPrice });
					}
					return pricesList;
				}, []);

				if (updatedPrices.length > 0) {
					InfoService.startRequest();

					AdslotPartnerships.updatePrices($stateParams.id, updatedPrices)
						.then((adslotPartnershipsPricesList) => {
							toaster.successMessage('MESSAGE.ADSLOT_SAVE_SUCCESS');

							$scope.models.prices = AdslotPartnerships.toIdFloorPriceMap(adslotPartnershipsPricesList);
							$scope.models.initialPrices = AdslotPartnerships.toIdFloorPriceMap(adslotPartnershipsPricesList);
							$scope.models.partnerships = toPartnershipsList($scope.models.prices).filter(isRtbDCPartnership);
						})
						.catch(() => {
							toaster.errorMessage('MESSAGE.ADSLOT_SAVE_ERROR');
						})
						.finally(() => InfoService.endRequest());
				}
			}
		},
	};

	$scope.models.assignedPartnershipsListFilter
		.getGroup('LABEL.DEMAND_PARTNER')
		.getEntry('demandPartnerId')
		.setEntryData(Partnerships.getDemandPartnersList().filter(({ id }) => DemandPartners.isVisible(id)));
	$scope.models.assignedPartnershipsListFilterFn = (...args) => $scope.models.assignedPartnershipsListFilter.matchesFilter(...args);

	PagerService.get(assignedPartnershipsListId).setPerPage(20);
}

export default AdslotPartnershipsController;
