function ListFilterController() {
	'ngInject';

	let vm = this;

	vm.groupSearches = {};

	vm.change = function change() {
		vm.filter.updateActive();
	};

	vm.reset = function reset() {
		vm.filter.reset();
	};

	this.$onInit = function $onInit() {
		vm.filter = vm.filterInstance;
	};
}

export default ListFilterController;
