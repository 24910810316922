function GroupsNewController($rootScope, $scope, close, siteId, sitesList, AdslotGroups, InfoService, ModalCloser, Sites, toaster) {
	'ngInject';

	var vm = this,
		unbindAuthListener,
		unbindDestroyListener;

	vm.group = {
		name: '',
	};
	vm.groups = [];
	vm.sites = sitesList;

	vm.fixedSiteId = siteId;
	if (siteId) {
		vm.fixedSiteName = Sites.getById(siteId).name;
	}

	vm.isFormValid = function isFormValid() {
		return (vm.fixedSiteId || vm.siteId) && vm.group.name.length;
	};

	vm.validGroupName = function validGroupName(modelValue) {
		return !AdslotGroups.existsInSite(vm.fixedSiteId || vm.siteId, modelValue);
	};

	vm.save = function save() {
		var selectedSiteId = vm.fixedSiteId || vm.siteId;

		InfoService.startRequest();

		AdslotGroups.add(selectedSiteId, [vm.group])
			.then(function success(addedGroups) {
				var site = Sites.getById(selectedSiteId);

				vm.groups.push(angular.extend({}, vm.group, { site: site.name }));

				$rootScope.$emit(AdslotGroups.EVENT.UPDATED);
				toaster.successMessage('MESSAGE.GROUP_CREATED');

				addedGroups.forEach(function updateSiteGroupsList(group) {
					site.addGroup(group.id);
				});

				vm.group.name = '';
				vm.form.$setPristine();
			})
			.catch(function error(errorObject) {
				if (errorObject.code === AdslotGroups.ERROR.DUPLICATE_NAME) {
					vm.form.groupName.$invalid = true;
					vm.form.groupName.$error.existing = true;
				} else {
					toaster.errorMessage(errorObject.errorMessage);
				}
			})
			.finally(function finallyFn() {
				InfoService.endRequest();
				$timeout(vm.afterSaveFinally, 0);
			});
	};

	vm.afterSaveFinally = function afterSaveFinally() {
		$rootScope.$emit('autofocusId::trigger', 'structure-add-group');
	};

	vm.close = function closeModal() {
		ModalCloser.close(close, undefined, 200);
	};

	unbindAuthListener = $rootScope.$on('event:auth-loginRequired', vm.close);
	unbindDestroyListener = $scope.$on('$destroy', function destroyListener() {
		unbindAuthListener();
		unbindDestroyListener();
	});
}

export default GroupsNewController;
