function Advertisers(Api, Advertiser, SupplyAdvertisers, SupplyFilter) {
	'ngInject';

	/**
	 * @type {Object.<int, Advertiser>}
	 */
	this.advertisers = {};

	/**
	 * @return {promise}
	 */
	this.loadAll = async () => {
		return Api.get('/api/v1/advertisers').then((data) => {
			var i, j, length;

			this.advertisers = {};

			for (i = 0, length = data.advertisers.length; i < length; i++) {
				this.advertisers[data.advertisers[i].id] = new Advertiser(data.advertisers[i]);
			}
			const supplyAdvertisers = Object.assign({}, this.advertisers);
			for (j = 0; j < SupplyFilter.advertiserIds.length; j++) {
				delete supplyAdvertisers[SupplyFilter.advertiserIds[j]];
			}
			SupplyAdvertisers.set(supplyAdvertisers);
		});
	};

	/**
	 * @returns {Object.<int, Advertiser>}
	 */
	this.getMap = function getMap() {
		return this.advertisers;
	};

	/**
	 * @returns {Array.<Advertiser>}
	 */
	this.getList = function getList() {
		var id,
			result = [];

		for (id in this.advertisers) {
			if (Object.prototype.hasOwnProperty.call(this.advertisers, id) && this.advertisers[id].isEnabled()) {
				result.push(this.advertisers[id]);
			}
		}

		return result;
	};

	/**
	 * @param {int} id
	 * @returns {Advertiser}
	 */
	this.getById = function getById(id) {
		return this.advertisers[id];
	};
}

export default Advertisers;
