const includesIgnoringCase = (array, value) => array.map((str) => str.toLowerCase()).includes(value.toLowerCase());

class ReferrerGroup {
	/**
	 * @type {Number}
	 */
	id;

	/**
	 * @type {string}
	 */
	name;

	/**
	 * @type {Array.<String>}
	 */
	includedReferrers;

	/**
	 * @type {Array.<String>}
	 */
	invalidIncludedReferrers;

	/**
	 * @type {Array.<String>}
	 */
	excludedReferrers;

	/**
	 * @type {Array.<String>}
	 */
	invalidExcludedReferrers;

	/**
	 * @param {Number} id
	 * @param {String} name
	 * @param {Array.<String>} includedReferrers
	 * @param {Array.<String>} excludedReferrers
	 */
	constructor({ id = 0, name = '', referers: includedReferrers = [], referrerBlacklist: excludedReferrers = [] } = {}) {
		this.id = id;
		this.name = name;
		this.includedReferrers = includedReferrers;
		this.excludedReferrers = excludedReferrers;
		this.invalidIncludedReferrers = [];
		this.invalidExcludedReferrers = [];
	}

	hasInvalidIncludedReferrers() {
		return this.invalidIncludedReferrers?.length > 0;
	}

	hasInvalidExcludedReferrers() {
		return this.invalidExcludedReferrers?.length > 0;
	}

	getAllReferrersLength() {
		return (
			(this.includedReferrers?.length || 0) +
			(this.invalidIncludedReferrers?.length || 0) +
			(this.excludedReferrers?.length || 0) +
			(this.invalidExcludedReferrers?.length || 0)
		);
	}

	getAllIncludedReferrers() {
		const combination = [...(this.includedReferrers || []), ...(this.invalidIncludedReferrers || [])];
		return [...new Set(combination)];
	}

	getAllExcludedReferrers() {
		const combination = [...(this.excludedReferrers || []), ...(this.invalidExcludedReferrers || [])];
		return [...new Set(combination)];
	}

	hasNoIntersectionBetweenSubgroups() {
		const intersection = this.getAllIncludedReferrers().find((element) => includesIgnoringCase(this.getAllExcludedReferrers(), element));
		return angular.isUndefined(intersection);
	}

	invalidateIncludedReferrers(invalidReferrers) {
		this.invalidIncludedReferrers = invalidReferrers;
		this.includedReferrers = this.includedReferrers.filter((element) => !includesIgnoringCase(invalidReferrers, element));
	}

	invalidateExcludedReferrers(invalidReferrers) {
		this.invalidExcludedReferrers = invalidReferrers;
		this.excludedReferrers = this.excludedReferrers.filter((element) => !includesIgnoringCase(invalidReferrers, element));
	}

	validateAllReferrers() {
		this.includedReferrers = this.getAllIncludedReferrers();
		this.invalidIncludedReferrers = [];

		this.excludedReferrers = this.getAllExcludedReferrers();
		this.invalidExcludedReferrers = [];
	}

	toObject() {
		return {
			name: this.name,
			referers: this.includedReferrers,
			referrerBlacklist: this.excludedReferrers,
		};
	}
}

export default ReferrerGroup;
