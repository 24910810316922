function InputDimension() {
	'ngInject';

	const MAX_DIGITS = 5;

	return {
		require: 'ngModel',
		link: function postLink(scope, iElement, attrs, ngModelCtrl) {
			ngModelCtrl.$parsers.push((viewValue) => {
				const asNumber = parseInt(viewValue, 10);

				if (viewValue && viewValue.length <= MAX_DIGITS && !Number.isNaN(asNumber)) {
					return asNumber;
				}

				return viewValue; // as modelValue
			});

			ngModelCtrl.$validators.isDimension = function isDimension(modelValue, viewValue) {
				return /^[1-9]\d{0,4}$/.test(modelValue || viewValue);
			};
		},
	};
}

export default InputDimension;
