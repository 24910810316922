import uiRouter from '@uirouter/angularjs';
import angular from 'angular';

import preFilter from './reports-pre-filter.component.js';

import ReportsController from './reports.controller.js';
import ReportsGenerateExportController from './reports-generate-export.controller.js';
import ReportsConfigureScheduledReportController from './reports-configure-scheduled-report.controller.js';
import ReportsNewEditController from './reports-new-edit.controller.js';
import ReportsViewEditController from './reports-view-edit.controller.js';
import ReportsAdslotFilterController from './reports-adslots-filter.controller.js';
import ReportsAdvertiserFilterController from './reports-advertisers-filter.controller.js';
import ReportsDemandPartnerFilterController from './reports-demand-partners-filter.controller.js';
import ReportsPartnershipFilterController from './reports-partnerships-filter.controller.js';
import ReportsPreFilterController from './reports-pre-filter.controller.js';
import ReportsOverviewController from './reports-overview.controller.js';
import ReportsViewController from './reports-view.controller.js';
import ReportsConfig from './reports.config.js';
import ReportsFilterSharedService from './reports-filter-shared.service.js';

import reportSettingsHtml from '../../views/reports/includes/report-settings.html';
import ReportSettingsController from './includes/report-settings.js';

const ReportsModule = angular
	.module('reports', [uiRouter])

	.component('preFilter', preFilter)

	.controller('ReportsController', ReportsController)
	.controller('ReportsNewEditController', ReportsNewEditController)
	.controller('ReportsViewEditController', ReportsViewEditController)
	.controller('ReportsGenerateExportController', ReportsGenerateExportController)
	.controller('ReportsConfigureScheduledReportController', ReportsConfigureScheduledReportController)
	.controller('ReportsAdslotFilterController', ReportsAdslotFilterController)
	.controller('ReportsAdvertiserFilterController', ReportsAdvertiserFilterController)
	.controller('ReportsDemandPartnerFilterController', ReportsDemandPartnerFilterController)
	.controller('ReportsPartnershipFilterController', ReportsPartnershipFilterController)
	.controller('ReportsPreFilterController', ReportsPreFilterController)
	.controller('ReportsOverviewController', ReportsOverviewController)
	.controller('ReportsViewController', ReportsViewController)
	.controller('ReportSettingsController', ReportSettingsController)
	.directive('reportSettings', () => ({
		scope: {
			template: '=',
			reportValidity: '=',
		},
		controller: 'ReportSettingsController as $ctrl',
		template: reportSettingsHtml,
	}))
	.factory('ReportsFilterSharedService', ReportsFilterSharedService)
	.config(ReportsConfig).name;

export default ReportsModule;
