import angular from 'angular';

import InventoryAdslotsListModule from './inventory-adslots-list/inventory-adslots-list.module.js';
import InventoryAdslotsViewModule from './inventory-adslots-view/inventory-adslots-view.module.js';

import AdslotMoveController from './inventory-adslots-move.controller.js';
import AdslotNewController from './inventory-adslots-new.controller.js';
import UploadAdslotCSVController from './inventory-adslots-upload-csv.controller.js';

const InventoryAdslotsModule = angular
	.module('inventory.adslots', [InventoryAdslotsListModule, InventoryAdslotsViewModule])
	.controller('AdslotMoveController', AdslotMoveController)
	.controller('AdslotNewController', AdslotNewController)
	.controller('UploadAdslotCSVController', UploadAdslotCSVController).name;

export default InventoryAdslotsModule;
