function AdslotFallbackXmlModel(AdslotFallback) {
	'ngInject';

	/**
	 * @param {Adslot} [adslot]
	 */
	function AdslotFallbackXml(adslot) {
		AdslotFallback.call(this, adslot);

		this.content = (adslot || {}).fallback.xmlContent || '';

		this.urls = [
			{
				key: 'xml',
				value: (adslot || {}).fallback.xml || '',
			},
		];

		this.TEXT.content = 'PLACEHOLDER.XML_CONTENT';
		this.TEXT.xml = 'LABEL.XML_REDIRECT_URL';
	}

	AdslotFallbackXml.prototype = Object.create(AdslotFallback.prototype);
	AdslotFallbackXml.prototype.constructor = AdslotFallbackXml;

	AdslotFallbackXml.prototype.getContent = function getContent() {
		return { xmlContent: this.content };
	};

	return AdslotFallbackXml;
}

export default AdslotFallbackXmlModel;
