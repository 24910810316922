import isEmpty from 'lodash/isEmpty.js';

import ReportTemplate from '../models/ReportTemplate.js';

function ReportTemplates(Api) {
	'ngInject';

	/**
	 * @type {Object.<int, ReportTemplate>}
	 */
	this.templates = {};

	/**
	 * @return {Promise}
	 */
	this.loadAll = () =>
		Api.get('/api/supply/v1/report-templates')
			.then(({ reportTemplates }) => reportTemplates || [])
			.then((templateList) => {
				this.templates = templateList.reduce((templates, templateData) => {
					const template = new ReportTemplate(templateData);
					templates[template.id] = template;
					return templates;
				}, {});
			});

	/**
	 * @return {Promise}
	 */
	this.cachedLoadAll = () => (isEmpty(this.templates) ? this.loadAll() : Promise.resolve());

	/**
	 * @param {ReportTemplate} template
	 *
	 * @return {Promise}
	 */
	this.create = (template) =>
		Api.post('/api/supply/v1/report-templates', { ...template.toObject(), ...{ id: undefined } }).then((createdTemplate) => {
			this.templates[createdTemplate.id] = new ReportTemplate(createdTemplate);
			return createdTemplate.id;
		});

	/**
	 * @param {ReportTemplate} template
	 *
	 * @return {Promise}
	 */
	this.update = (template) =>
		Api.put(`/api/supply/v1/report-templates/${template.id}`, { ...template.toObject(), ...{ id: undefined } }).then((updatedTemplate) => {
			this.templates[updatedTemplate.id] = new ReportTemplate(updatedTemplate);
			return updatedTemplate.id;
		});

	/**
	 * @param {ReportTemplate} template
	 *
	 * @return {Promise}
	 */
	this.delete = (template) =>
		Api.delete(`/api/supply/v1/report-templates/${template.id}`).then(() => {
			delete this.templates[template.id];
		});

	/**
	 * @param {int} id
	 * @returns {ReportTemplate}
	 */
	this.getById = (id) => this.templates[id];

	/**
	 * @returns {Array.<ReportTemplate>}
	 */
	this.getList = () => Object.values(this.templates);
}

export default ReportTemplates;
