/**
 * fork of: ng-currency
 * https://github.com/aguirrel/ng-currency
 */
function Price($filter, $locale, $parse, $rootScope) {
	'ngInject';

	return {
		terminal: true,
		restrict: 'A',
		require: 'ngModel',
		scope: {},
		link: function postLink(scope, iElement, attrs, ngModelCtrl) {
			var unbindMinListener,
				unbindMaxListener,
				unbindCurrencySymbolListener,
				unbindDestroyListener,
				initialViewValue,
				initialModelValue = $parse(attrs.ngModel)(scope.$parent) || 0,
				modelMultiplier = 100,
				isGridCell = iElement.hasClass('price-cell');

			attrs.$observe('min', function minValue(value) {
				scope.min = value;
			});
			attrs.$observe('max', function maxValue(value) {
				scope.max = value;
			});
			attrs.$observe('currencySymbol', function currencySymbol(symbol) {
				scope.currencySymbol = symbol;
			});
			attrs.$observe('ngRequired', function ngRequired(required) {
				scope.ngRequired = required;
			});
			attrs.$observe('fraction', function fraction(digits) {
				scope.fraction = digits;
			});

			assertValidMinMaxValues(scope.min, scope.max);

			if (angular.isDefined(attrs.euroValue)) {
				modelMultiplier = 1;
			}

			function assertValidMinMaxValues(min, max) {
				var minNum = parseInt(min, 10),
					maxNum = parseInt(max, 10);

				if (
					(angular.isDefined(minNum) && !angular.isNumber(minNum)) ||
					(angular.isDefined(maxNum) && !angular.isNumber(maxNum)) ||
					(angular.isDefined(minNum) && angular.isDefined(maxNum) && maxNum < minNum)
				) {
					throw new RangeError('invalid min/max values supplied');
				}
			}

			function getCurrencySymbol() {
				return angular.isDefined(scope.currencySymbol) && !!scope.currencySymbol ? scope.currencySymbol : '\u20ac'; // we go with € for now, $locale.NUMBER_FORMATS.CURRENCY_SYM later
			}

			function getDecimalSeparator() {
				return $locale.NUMBER_FORMATS.DECIMAL_SEP;
			}

			function getFraction() {
				var fraction = parseInt(scope.fraction, 10);

				if (angular.isDefined(scope.fraction) && angular.isNumber(fraction)) {
					return fraction;
				}

				return 2;
			}

			function decimalRex() {
				return RegExp('\\d|\\-|\\' + getDecimalSeparator(), 'g');
			}

			function clearRex() {
				return RegExp('\\-?(\\' + getDecimalSeparator() + '|([0-9]+\\' + getDecimalSeparator() + '?))&?[0-9]{0,' + getFraction() + '}', 'g');
			}

			function handleShortForm(priceString) {
				var resultPriceString = priceString;

				// Check for fast digitation (empty string or - or -,)
				if (RegExp('^\\-?\\' + getDecimalSeparator() + '?$', 'g').test(resultPriceString)) {
					resultPriceString += '0';
				}

				return resultPriceString;
			}

			// according to https://stackoverflow.com/a/7988852 limit input to 13 digits to not loose precision
			function toFloat(floatString) {
				var resultFloatString = floatString,
					separator = resultFloatString.indexOf('.');

				if (separator > -1) {
					resultFloatString = resultFloatString.substr(0, Math.min(separator, 13)) + resultFloatString.substr(separator);
				} else {
					resultFloatString = resultFloatString.substr(0, 13);
				}

				return parseFloat(resultFloatString);
			}

			function clearValue(value) {
				var toBeCleared = String(value).trim(),
					cleared = null;

				toBeCleared = handleShortForm(toBeCleared);

				if (decimalRex().test(toBeCleared)) {
					cleared = toBeCleared.match(decimalRex()).join('').match(clearRex());
					cleared = cleared ? cleared[0].replace(RegExp('\\' + getDecimalSeparator()), '.') : null;
				}

				// handle float short form
				if (cleared === null || cleared === '.' || cleared === '-.') {
					cleared = '0';
				}

				return cleared;
			}

			/**
			 * @param {number} value
			 * @returns {int}
			 */
			function toCentConverter(value) {
				return parseInt(Math.round(value * modelMultiplier), 10);
			}

			ngModelCtrl.$parsers.push(function parserFn(viewValue) {
				return toCentConverter(toFloat(clearValue(viewValue)));
			});

			/**
			 * @param {int} centsValue
			 * @returns {Number}
			 */
			function fromCentConverter(centsValue) {
				var cents = centsValue;

				// anything else than int will be treated as zero (@see https://stackoverflow.com/a/3885844)
				if (!angular.isNumber(cents) || cents % 1 !== 0) {
					cents = 0;
				}
				return cents / parseFloat(modelMultiplier);
			}

			ngModelCtrl.$formatters.push(function formatterFn(value) {
				var viewValue = $filter('currency')(fromCentConverter(value), getCurrencySymbol(), getFraction());

				if (angular.isUndefined(initialViewValue)) {
					initialViewValue = viewValue;
				}

				return viewValue;
			});

			function reformatViewValue(modelValue) {
				var formatters = ngModelCtrl.$formatters,
					idx = formatters.length,
					viewValue = modelValue;

				while (idx--) {
					viewValue = formatters[idx](viewValue);
				}

				ngModelCtrl.$setViewValue(viewValue);
				ngModelCtrl.$render();
			}

			ngModelCtrl.$validators.min = function minValidator(modelValue) {
				if (!scope.ngRequired && isNaN(modelValue)) {
					// eslint-disable-line no-restricted-globals
					return true;
				}

				if (angular.isDefined(scope.min)) {
					return modelValue >= parseFloat(scope.min) * modelMultiplier;
				}

				return true;
			};

			unbindMinListener = scope.$watch('min', function watched(value) {
				assertValidMinMaxValues(value, scope.max);
				ngModelCtrl.$validate();
			});

			ngModelCtrl.$validators.max = function maxValidator(modelValue) {
				if (!scope.ngRequired && isNaN(modelValue)) {
					// eslint-disable-line no-restricted-globals
					return true;
				}

				if (angular.isDefined(scope.max)) {
					return modelValue <= parseFloat(scope.max) * modelMultiplier;
				}

				return true;
			};

			unbindMaxListener = scope.$watch('max', function watched(value) {
				assertValidMinMaxValues(scope.min, value);
				ngModelCtrl.$validate();
			});

			unbindCurrencySymbolListener = scope.$watch('currencySymbol', function watched(newValue, oldValue) {
				if (newValue !== oldValue) {
					reformatViewValue(ngModelCtrl.$modelValue);
				}
			});

			const onBlur = () => {
				// eslint-disable-next-line angular/no-private-call
				if (initialModelValue !== (ngModelCtrl.$modelValue || ngModelCtrl.$$rawModelValue) || initialViewValue !== ngModelCtrl.$viewValue) {
					ngModelCtrl.$commitViewValue();
					reformatViewValue(ngModelCtrl.$modelValue || ngModelCtrl.$$rawModelValue); // eslint-disable-line angular/no-private-call
					initialModelValue = ngModelCtrl.$modelValue || ngModelCtrl.$$rawModelValue; // eslint-disable-line angular/no-private-call
					initialViewValue = ngModelCtrl.$viewValue;
				}
			};
			iElement.on('blur', onBlur);

			const onKeyDownKeyPress = (event) => {
				// submit on ENTER
				if (event.which === 13) {
					iElement[0].blur();
				} else if (event.which === 27) {
					if (isGridCell) {
						// hell no, nggrid input. u ain't doin nothin!
						event.stopImmediatePropagation();
					}

					// reset value on ESC but update only when needed
					// eslint-disable-next-line angular/no-private-call
					if (initialModelValue !== (ngModelCtrl.$modelValue || ngModelCtrl.$$rawModelValue) || initialViewValue !== ngModelCtrl.$viewValue) {
						reformatViewValue(initialModelValue);
						$rootScope.safeApply();
					}
					iElement[0].blur();
				}
			};
			iElement.on('keydown keypress', onKeyDownKeyPress);

			iElement.addClass('price');

			unbindDestroyListener = scope.$on('$destroy', function cleanup() {
				iElement.off('blur', onBlur);
				iElement.off('keydown keypress', onKeyDownKeyPress);
				unbindMinListener();
				unbindMaxListener();
				unbindCurrencySymbolListener();
				unbindDestroyListener();
			});
		},
	};
}

export default Price;
