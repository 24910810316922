function LoadingCircle($location) {
	'ngInject';

	return {
		template: [
			/* eslint-disable */
			'<svg class="loading-circle" width="100%" height="100%" viewBox="0 0 100 100">',
			'<defs>',
			'<path id="circle-path" d="M 50, 50 m -50, 0 a 50,50 0 1,0 100,0 a 50,50 0 1,0 -100,0"/>',
			'<clipPath id="circle-clip-path">',
			'<use xlink:href="{{::circlePath}}"/>',
			'</clipPath>',
			'<linearGradient id="ylGradient">',
			'<stop offset="5%" stop-color="#3bb6b5"/>',
			'<stop offset="95%" stop-color="#40d5a0"/>',
			'</linearGradient>',
			'</defs>',
			'<g>',
			'<use class="circle-element" xlink:href="{{::circlePath}}" stroke="url({{::gradient}})" shape-rendering="auto" fill="transparent" clip-path="url({{::clipPath}})"/>',
			'</g>',
			'</svg>',
			/* eslint-enable */
		].join(''),
		link: function postLink(scope) {
			// Used to fix references to inline SVG elements when the <base> tag is in use.
			// see: https://gist.github.com/leonderijke/c5cf7c5b2e424c0061d2
			var baseUrl = $location.absUrl().replace($location.hash(), '');

			function refUrl(ref) {
				return baseUrl + ref;
			}

			scope.circlePath = refUrl('#circle-path');
			scope.clipPath = refUrl('#circle-clip-path');
			scope.gradient = refUrl('#ylGradient');
		},
	};
}

export default LoadingCircle;
