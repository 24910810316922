import inlineEditHtml from '../views/directives/inline-edit.html';

function InlineEdit($compile, $rootScope, $timeout) {
	'ngInject';

	return {
		scope: true,
		controller: function InlineEditController() {
			'ngInject';

			var vm = this,
				active = false;

			vm.inProgress = false;

			vm.start = function start() {
				active = true;
			};

			vm.stop = function stop() {
				active = false;
			};

			vm.isActive = function isActive() {
				return active;
			};

			vm.save = function save() {
				if (!vm.inProgress) {
					if (vm.input !== vm.input$original) {
						vm.inProgress = true;
						$rootScope.$emit('inlineEdit::save', vm.elementId, vm.input);
					} else {
						vm.cancel();
					}
				}
			};

			vm.cancel = function cancel() {
				$rootScope.$emit('inlineEdit::end', vm.elementId);
			};
		},
		controllerAs: 'ieCtrl',
		link: function postLink(scope, iElement, iAttrs) {
			var elementId = iAttrs.ylInlineEdit,
				unbindSaveSuccessListener,
				unbindSaveErrorListener,
				unbindEditStartListener,
				unbindEditEndListener,
				inlineEditForm = angular.element(inlineEditHtml),
				input = inlineEditForm.find('input'),
				buttons = inlineEditForm.find('button'),
				errorBlock = inlineEditForm.find('span');

			if (!elementId) {
				throw new Error('directive requires trigger id to be set. e.g. yl-inline-edit="my-inline-edit-trigger-id"');
			}

			scope.ieCtrl.elementId = elementId;

			function endEditMode() {
				scope.ieCtrl.stop();

				inlineEditForm.removeClass('success error');
				input.off('keyup');
				errorBlock.hide();
				inlineEditForm.remove();

				iElement.show();
			}

			function onKeyPress(event) {
				if (event.which === 27) {
					endEditMode();
				}
			}

			unbindEditStartListener = $rootScope.$on('inlineEdit::start', function editStartListener(event, triggerId) {
				if (triggerId === elementId && !scope.ieCtrl.isActive()) {
					scope.ieCtrl.input$original = scope.ieCtrl.input = iElement.html(); // eslint-disable-line no-multi-assign
					scope.ieCtrl.start();

					iElement.hide();

					iElement.parent().append($compile(inlineEditForm)(scope));
					buttons.show();
					input.on('keyup', onKeyPress).focus();
					// scope.$digest() not needed, as it's inside angular's cycle
				} else if (scope.ieCtrl.isActive()) {
					input.focus();
				}
			});

			unbindEditEndListener = $rootScope.$on('inlineEdit::end', function editEndListener(event, triggerId) {
				if (triggerId === elementId) {
					endEditMode();
				}
			});

			unbindSaveSuccessListener = $rootScope.$on('inlineEdit::save-success', function saveSuccessListener(event, triggerId) {
				if (triggerId === elementId) {
					scope.ieCtrl.inProgress = false;
					scope.ieCtrl.input$original = scope.ieCtrl.input;

					inlineEditForm.removeClass('error').addClass('success');
					input.blur();
					inlineEditForm.find('button').hide();
					errorBlock.hide();

					$timeout(endEditMode, 1000); // just to give a short success feedback
				}
			});

			unbindSaveErrorListener = $rootScope.$on('inlineEdit::save-error', function saveErrorListener(event, triggerId, errorMsg) {
				if (triggerId === elementId) {
					scope.ieCtrl.inProgress = false;
					scope.ieCtrl.errorMsg = errorMsg;

					inlineEditForm.addClass('error');
					errorBlock.show();
				}
			});

			$rootScope.cleanListeners(scope, [unbindSaveSuccessListener, unbindSaveErrorListener, unbindEditStartListener, unbindEditEndListener]);
		},
	};
}

export default InlineEdit;
