function OnlyDirectConnect(Account) {
	'ngInject';

	var ATTR = 'only-direct-connect';

	return {
		priority: 1000,
		compile: function compile(tElement, tAttrs) {
			const invert = angular.isDefined(tAttrs.invert) || angular.isDefined(tAttrs.dataInvert);
			if ((!invert && !Account.isDirectConnect()) || (invert && Account.isDirectConnect())) {
				tElement.remove();
			} else {
				tElement.removeAttr(ATTR);
				tElement.removeAttr('data-' + ATTR);
				tElement.removeAttr('invert');
				tElement.removeAttr('data-invert');
			}
		},
	};
}

export default OnlyDirectConnect;
