function AdtagsController($filter, Adslots, Adtags, Partnerships) {
	'ngInject';

	const vm = this;

	vm.TYPE_DEFAULT = 1;
	vm.TYPE_PG = 2;

	vm.type = vm.TYPE_DEFAULT;

	/**
	 * @type {object}
	 */
	vm.form = {
		1: {},
		2: {},
	};

	vm.adtags = {
		// TYPE_DEFAULT
		1: {},
		// TYPE_PG
		2: {},
	};

	vm.filter = {
		// TYPE_DEFAULT
		1: '',
		// TYPE_PG
		2: '',
	};

	vm.isChecked = false;

	vm.adslots = Adslots.getListFiltered([]);
	vm.pgPartnerships = Partnerships.getListPG();

	vm.list = vm.adslots;

	vm.boxText = '';

	/**
	 * @returns {boolean}
	 */
	vm.isAnItemChecked = () => {
		const filteredList = vm.filteredList.filter((element) => !!vm.form[vm.type][element.id]);

		return !!Object.keys(vm.form[vm.type]).length && !!filteredList.length;
	};

	/**
	 * @param {int} type
	 */
	vm.switchType = (type) => {
		vm.type = type;

		// Switch list between Adslots and programmatic guaranteed Partnerships
		if (vm.TYPE_DEFAULT === vm.type) {
			vm.list = vm.adslots;
		} else {
			vm.list = vm.pgPartnerships;
		}

		vm.filterList();
	};

	vm.filterList = () => {
		vm.filteredList = $filter('filter')(vm.list, { name: vm.filter[vm.type] });
		vm.isChecked = vm.isAnItemChecked();
	};

	vm.checkAll = () => {
		const ids = vm.filteredList.map(({ id }) => id);

		if (vm.TYPE_DEFAULT === vm.type) {
			Adtags.forAdslot(ids).then((adtag) => {
				Object.keys(adtag)
					.filter((adslotId) => {
						const adslot = Adslots.getById(adslotId);
						return adslot.visible && !adslot.isProgrammaticGuaranteed();
					})
					.forEach((adslotId) => {
						const adslot = Adslots.getById(adslotId);
						vm.adtags[vm.type][adslot.id] = Adtags.generateAdslotAdtagText(adslot, adtag[adslot.id]);
						vm.form[vm.type][adslot.id] = true;
					});
			});
		} else {
			Adtags.forPGPartnership(ids).then(function success(adtags) {
				Object.keys(adtags).forEach((partnershipId) => {
					const partnership = Partnerships.getById(partnershipId);
					vm.adtags[vm.type][partnership.id] = Adtags.generatePGAdtagsText(partnership, adtags[partnership.id]);
					vm.form[vm.type][partnership.id] = true;
				});
			});
		}

		vm.isChecked = true;
	};

	vm.uncheckAll = () => {
		if (!vm.filter[vm.type].length) {
			vm.form[vm.type] = {};
			vm.adtags[vm.type] = {};
		} else {
			vm.filteredList
				.map(({ id }) => id)
				.forEach((itemId) => {
					delete vm.form[vm.type][itemId];
					delete vm.adtags[vm.type][itemId];
				});
		}

		vm.isChecked = false;
	};

	/**
	 * @param {object} entry
	 * @param {boolean} isSelected
	 */
	vm.toggleAdtag = (entry, isSelected) => {
		if (isSelected === true) {
			if (vm.TYPE_DEFAULT === vm.type) {
				Adtags.forAdslot(entry.id).then((adtag) => {
					vm.adtags[vm.type][entry.id] = Adtags.generateAdslotAdtagText(entry, adtag[Object.keys(adtag)[0]]);
				});
			} else {
				Adtags.forPGPartnership(entry.id).then((adtags) => {
					vm.adtags[vm.type][entry.id] = Adtags.generatePGAdtagsText(entry, adtags[Object.keys(adtags)[0]]);
				});
			}
		} else {
			delete vm.adtags[vm.type][entry.id];
			delete vm.form[vm.type][entry.id];
		}

		vm.isChecked = vm.isAnItemChecked();
	};

	vm.addAdslotsTags = () =>
		Object.keys(vm.adtags[vm.type])
			.map((id) => vm.adtags[vm.type][id])
			.join('\n');

	vm.filterList();
}

export default AdtagsController;
