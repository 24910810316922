function AdslotFallbackModel() {
	var TYPE = {
		CONTENT: 'Content',
		REDIRECT: 'Redirect',
	};

	/**
	 * @param {Adslot} [adslot]
	 */
	function AdslotFallback(adslot) {
		/**
		 * @type {boolean}
		 */
		this.isActive = (adslot || {}).overrideFallback || false;

		/**
		 * @type {string}
		 */
		this.type = (adslot || {}).fallback.type || TYPE.CONTENT;

		/**
		 * @type {string}
		 */
		this.content = '';

		/**
		 * @type {Array.<{}>}
		 */
		this.urls = [];

		/**
		 * @type {{}}
		 */
		this.TEXT = {};
	}

	AdslotFallback.prototype.TYPES = TYPE;

	AdslotFallback.prototype.isRedirect = function isRedirect() {
		return this.type === TYPE.REDIRECT;
	};

	AdslotFallback.prototype.getContent = function getContent() {
		throw new Error('abstract. not implemented.');
	};

	AdslotFallback.prototype.toObject = function toObject() {
		if (this.isActive) {
			const redirectFallback = this.urls.reduce((urlMap, url) => {
				urlMap[url.key] = url.value;
				return urlMap;
			}, {});

			const fallbackData = this.isRedirect() ? redirectFallback : this.getContent();

			return {
				overrideFallback: true,
				fallback: {
					type: this.type,
					...fallbackData,
				},
			};
		}

		return { overrideFallback: false };
	};

	return AdslotFallback;
}

export default AdslotFallbackModel;
