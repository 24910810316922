import fileDownload from 'js-file-download';
import ReportColumns from '../models/ReportColumns.js';

function Reports(ReportFormatterService) {
	'ngInject';

	this.EVENT = {
		RELOADED: 'Reports::reloaded',
		SAVED: 'Reports::saved',
		DELETED: 'Reports::deleted',
	};

	/**
	 * get csv report link
	 *
	 * @return {string}
	 */
	this.exportCsv = (reportData, preset, translation) => {
		this.hasValidReportData(reportData, preset, translation);
		const csvString = ReportFormatterService.generateCsv(reportData, preset, translation);
		this.downloadFile(csvString, preset, 'csv');
	};

	/**
	 * get excel report link
	 *
	 * @return {string}
	 */
	this.exportExcel = async (reportData, preset, translation) => {
		this.hasValidReportData(reportData, preset, translation);
		const buffer = await ReportFormatterService.generateExcelFileBuffer(reportData, preset, translation);
		const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
		return this.downloadFile(blob, preset, 'xlsx');
	};

	this.hasValidReportData = (report, preset, translation) => {
		if (!report || !Array.isArray(report) || report.length === 0 || !report[0].type) {
			throw new Error('Missing report data');
		}

		if (!preset || !preset.columns || !preset.startDate || !preset.endDate) {
			throw new Error('Missing preset data');
		}

		if (!translation || typeof translation !== 'object') {
			throw new Error('Missing translation data');
		}
	};

	this.downloadFile = (content, preset, extension) => {
		const fileName = `${preset.name}_${preset.startDate}_${preset.endDate}.${extension}`;
		fileDownload(content, fileName);
	};

	const adslotDimensions = new Set([ReportColumns.ID.ADSLOT, ReportColumns.ID.ADSLOT_ID]);

	const otherDimensions = new Set([
		ReportColumns.ID.DEAL_ID,
		ReportColumns.ID.PARTNERSHIP_ID,
		ReportColumns.ID.PARTNERSHIP,
		ReportColumns.ID.DEMAND_PARTNER,
		ReportColumns.ID.DEMAND_PARTNER_ID,
		ReportColumns.ID.PARENT_SUPPLY_PARTNER,
		ReportColumns.ID.PARENT_DEAL_ID,
		ReportColumns.ID.FORMAT,
	]);

	const hasOnlyAdslotDimensions = (dimensions) => {
		const dimensionIds = dimensions.map(({ id }) => id);
		return dimensionIds.find((id) => adslotDimensions.has(id)) && !dimensionIds.find((id) => otherDimensions.has(id));
	};

	this.isKeyFigureRestricted = (template, columnId) => {
		if (template.isAnalyticsReport()) {
			const selectedDimensions = template.dimensionColumns.filter((dimension) => dimension.visible);
			return columnId === ReportColumns.ID.YIELDPROBE_REQUESTS && !hasOnlyAdslotDimensions(selectedDimensions);
		}

		return false;
	};
}

export default Reports;
