function StructureController($state, toaster, Account, AuthService, ListFilterFactory, ModalFactory, Sites) {
	'ngInject';

	var vm = this;

	function isInSite() {
		return 'siteId' in $state.params;
	}

	function isInGroup() {
		return 'groupId' in $state.params;
	}

	vm.newSite = function newSite() {
		ModalFactory.newSite();
	};

	vm.newGroup = function newGroup(siteId) {
		var sites = Sites.getListAllNoDefault();

		if (sites.length) {
			ModalFactory.newGroup({
				siteId: siteId,
				sitesList: sites,
			});
		} else {
			toaster.warningMessage('MESSAGE.PLEASE_ADD_SITES_FIRST');
			vm.newSite();
		}
	};

	vm.newAdslot = function newAdslot(groupId) {
		ModalFactory.newAdslot(
			{
				groupId: groupId,
			},
			function adslotCreated(adslot) {
				if (angular.isDefined(adslot)) {
					$state.go('inventory.adslots.view.details', { id: adslot.id });
				}
			},
		);
	};

	vm.createItem = function createItem() {
		if (isInGroup()) {
			vm.newAdslot($state.params.groupId);
		} else if (isInSite()) {
			vm.newGroup($state.params.siteId);
		} else {
			vm.newSite();
		}
	};

	vm.getButtonTitle = function getButtonTitle() {
		if (isInGroup()) {
			return 'CAPTION.CREATE_ADSLOT';
		} else if (isInSite()) {
			return 'CAPTION.CREATE_GROUP';
		}
		return 'CAPTION.CREATE_SITE';
	};

	vm.moveAdslots = function moveAdslots() {
		if (isInGroup()) {
			const listFilter = ListFilterFactory.get(ListFilterFactory.LAYOUTS.ADSLOTS.LIST);
			const listFilterSettings = listFilter.getActiveFilterData();

			ModalFactory.moveAdslots(
				{
					groupId: $state.params.groupId,
				},
				() => {
					// as the list filter objects are singletons and we only load different data per page we need to restore
					// the filter to it's previous (from before opening the modal) state
					listFilter.load(listFilterSettings);
				},
			);
		}
	};

	vm.hasMoveButton = function hasMoveButton() {
		return isInGroup();
	};

	vm.hasCreateItemButton = function hasCreateItemButton() {
		return AuthService.isAdmin() && (!Account.isDirectConnect() || !isInGroup());
	};
}

export default StructureController;
