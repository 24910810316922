function UniqueReportRowsFilter() {
	/**
	 * @param {Array.<ReportRow>} reportRows
	 * @param {Function} getRowKeyFn
	 * @return {Array.<ReportRow>}
	 */
	return function uniqueReportRows(reportRows, getRowKeyFn) {
		var rowMap = {},
			getKey =
				getRowKeyFn ||
				function rowToString(row) {
					return row.toString();
				},
			i,
			length = reportRows.length,
			resultRows = [];

		for (i = 0; i < length; i++) {
			rowMap[getKey(reportRows[i])] = reportRows[i];
		}

		for (i in rowMap) {
			if (Object.prototype.hasOwnProperty.call(rowMap, i)) {
				resultRows.push(rowMap[i]);
			}
		}

		return resultRows;
	};
}

export default UniqueReportRowsFilter;
