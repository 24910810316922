function SettingsAccountController($scope, Password, UserInfo, toaster, InfoService) {
	'ngInject';

	var cleanForm = {
		email: UserInfo.getEmail(),
		changeEmail: false,
		changePassword: false,
		newEmail: '',
		newEmailAgain: '',
		newPass: '',
		newPassAgain: '',
		currentPassword: '',
	};

	$scope.form = angular.copy(cleanForm);

	$scope.hasMinLength = function hasMinLength() {
		return !!$scope.form.newPass && $scope.form.newPass.length >= 5;
	};

	$scope.containsNumber = function containsNumber() {
		return !!$scope.form.newPass && $scope.form.newPass.match(/\d/);
	};

	$scope.hasNoSpace = function hasNoSpace() {
		return !!$scope.form.newPass && !$scope.form.newPass.match(/\s/);
	};

	$scope.differsFromCurrentOne = function differsFromCurrentOne() {
		return $scope.form.newPass !== $scope.form.currentPassword;
	};

	$scope.reset = function reset() {
		$scope.form = angular.copy(cleanForm);
		$scope.accountForm.$setPristine();
	};

	$scope.isFormValid = function isFormValid() {
		return (
			($scope.form.changeEmail && $scope.form.newEmail === $scope.form.newEmailAgain) ||
			($scope.form.changePassword &&
				$scope.form.newPass === $scope.form.newPassAgain &&
				$scope.hasMinLength() &&
				$scope.containsNumber() &&
				$scope.hasNoSpace() &&
				$scope.differsFromCurrentOne())
		);
	};

	$scope.saveEmail = function saveEmail() {
		InfoService.startRequest();

		UserInfo.changeAccountEmail($scope.form.newEmail, $scope.form.currentPassword)
			.then(function success() {
				return UserInfo.load();
			})
			// on change success reload user info to correctly display the current email address
			.then(function success() {
				// update to new email
				cleanForm.email = UserInfo.getEmail();

				$scope.reset();

				toaster.successMessage('MESSAGE.EMAIL_CHANGE_SUCCESS');
				InfoService.endRequest();
			})
			.catch(function error(errorObject) {
				var errorMsg = 'MESSAGE.EMAIL_CHANGE_ERROR';

				if (errorObject.code === UserInfo.ERROR.WRONG_PASSWORD) {
					$scope.accountForm.currentPassword.$setValidity('required', false);
					errorMsg = 'MESSAGE.PASSWORD_WRONG';
				} else if (errorObject.code === UserInfo.ERROR.EMAIL_EXISTS) {
					$scope.accountForm.newEmail.$setValidity('required', false);
					errorMsg = 'MESSAGE.EMAIL_EXISTS';
				}

				toaster.errorMessage(errorMsg);
				InfoService.endRequest();
			});
	};

	$scope.savePassword = function savePassword() {
		toaster.clear();

		InfoService.startRequest();

		Password.change($scope.form.currentPassword, $scope.form.newPass)
			.then(function success() {
				$scope.reset();

				toaster.successMessage('MESSAGE.PASSWORD_CHANGE_SUCCESS');
				InfoService.endRequest();
			})
			.catch(function error(errorObject) {
				var errorMsg = 'MESSAGE.PASSWORD_CHANGE_ERROR';

				if (errorObject.code === Password.ERROR.WRONG_PASSWORD) {
					$scope.accountForm.currentPassword.$setValidity('required', false);
					errorMsg = 'MESSAGE.PASSWORD_WRONG';
				} else if (errorObject.code === Password.ERROR.INVALID_PASSWORD) {
					$scope.accountForm.newPass.$setValidity('required', false);
					errorMsg = 'MESSAGE.PASSWORD_INVALID';
				}

				toaster.errorMessage(errorMsg);
				InfoService.endRequest();
			});
	};

	$scope.save = function save() {
		if ($scope.form.changeEmail) {
			$scope.saveEmail();
		} else if ($scope.form.changePassword) {
			$scope.savePassword();
		}
	};
}

export default SettingsAccountController;
