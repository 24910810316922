import de from 'date-fns/locale/de';
import enGB from 'date-fns/locale/en-GB';

const locales = { de, en: enGB };

const DAYS_OF_MONTH = {
	de: Array.from(Array(28)).map((element, index) => `${index + 1}.`),
	en: Array.from(Array(28)).map((element, index) => index + 1),
};

class ReportNotificationSettingsSchedule {
	constructor({ type = ReportNotificationSettingsSchedule.TYPE.INACTIVE, day = 1 } = {}) {
		/**
		 * @type {string}
		 */
		this.type = type;

		/**
		 * @type {number}
		 */
		this.day = day;
	}

	static TYPE = {
		INACTIVE: 'inactive',
		DAILY: 'daily',
		WEEKLY: 'weekly',
		MONTHLY: 'monthly',
	};

	static TYPE_TRANSLATION = {
		[ReportNotificationSettingsSchedule.TYPE.INACTIVE]: 'CAPTION.SCHEDULED_REPORTS_SCHEDULE_INACTIVE',
		[ReportNotificationSettingsSchedule.TYPE.DAILY]: 'CAPTION.SCHEDULED_REPORTS_SCHEDULE_DAILY',
		[ReportNotificationSettingsSchedule.TYPE.WEEKLY]: 'CAPTION.SCHEDULED_REPORTS_SCHEDULE_WEEKLY',
		[ReportNotificationSettingsSchedule.TYPE.MONTHLY]: 'CAPTION.SCHEDULED_REPORTS_SCHEDULE_MONTHLY',
	};

	isActive() {
		return this.type !== ReportNotificationSettingsSchedule.TYPE.INACTIVE;
	}

	isScheduledWeekly() {
		return this.type === ReportNotificationSettingsSchedule.TYPE.WEEKLY;
	}

	isScheduledMonthly() {
		return this.type === ReportNotificationSettingsSchedule.TYPE.MONTHLY;
	}

	isScheduledWithDay() {
		return this.isScheduledWeekly() || this.isScheduledMonthly();
	}

	getTypeTranslation() {
		return ReportNotificationSettingsSchedule.TYPE_TRANSLATION[this.type];
	}

	/**
	 * @param {String} language en|de
	 */
	getDayTranslation(language) {
		if (this.isScheduledWeekly()) {
			// modulo 7 because Sunday is our 7th day but has the index 0
			return locales[language].localize.day(this.day % 7, { width: 'wide' });
		} else if (this.isScheduledMonthly()) {
			return DAYS_OF_MONTH[language][this.day - 1]; // get back from day to zero based array index
		}

		return '';
	}

	toObject() {
		const { type, day } = this;

		if (type === ReportNotificationSettingsSchedule.TYPE.DAILY) {
			return { type: this.type };
		}

		return {
			type,
			day,
		};
	}
}

export const getTypeSelectOptions = () =>
	Object.values(ReportNotificationSettingsSchedule.TYPE).map((value) => ({
		name: ReportNotificationSettingsSchedule.TYPE_TRANSLATION[value],
		value,
	}));

/**
 * @param {String} language en|de
 */
const getScheduleDayWeeklySelectOptions = (language) => {
	const weekdays = [...Array(7).keys()];
	const { localize } = locales[language];
	const { weekStartsOn } = locales[language].options;

	return weekdays
		.splice(weekStartsOn)
		.concat(weekdays)
		.map((dayIndex, arrayIndex) => ({
			name: localize.day(dayIndex, { width: 'short' }),
			value: arrayIndex + 1,
		}));
};

/**
 * @param {String} locale en|de
 */
const getScheduleDayMonthlySelectOptions = (locale) =>
	DAYS_OF_MONTH[locale].map((name, arrayIndex) => ({
		name,
		value: arrayIndex + 1,
	}));

/**
 * @param {String} type keys of ReportNotificationSettingsSchedule.TYPE
 * @param {String} locale en|de
 */
export const getScheduleDaySelectOptions = (type, locale) => {
	if (type === ReportNotificationSettingsSchedule.TYPE.WEEKLY) {
		return getScheduleDayWeeklySelectOptions(locale);
	}

	if (type === ReportNotificationSettingsSchedule.TYPE.MONTHLY) {
		return getScheduleDayMonthlySelectOptions(locale);
	}

	return [];
};

export default ReportNotificationSettingsSchedule;
