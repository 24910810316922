import { format } from 'date-fns';
import de from 'date-fns/locale/de';
import enGB from 'date-fns/locale/en-GB';

const dateFnsLocales = {
	de,
	en: enGB,
};
const formatDate = (date, localeString) => format(date, 'P', { locale: dateFnsLocales[localeString] });

function ReportsOverviewController(
	$state,
	$filter,
	$rootScope,
	$scope,
	$translate,
	ReportExports,
	Reports,
	ReportOrders,
	ReportTemplates,
	toaster,
	InfoService,
	ListSearchService,
	ModalFactory,
) {
	'ngInject';

	const vm = this;
	const MAX_SCHEDULED_REPORTS_COUNT = 7;

	this.STATE_PENDING = ReportExports.STATE_PENDING;
	this.STATE_READY = ReportExports.STATE_READY;
	this.STATE_ERROR = ReportExports.STATE_ERROR;

	vm.filteredReports = vm.reports;
	vm.search = ListSearchService.get('report-search');
	vm.sort = 'name';

	function updateOfflineReports() {
		vm.offlineReports = $filter('orderBy')(ReportExports.getQueuedReportInfo().map(addTypeClassToReportInfo), 'name');

		let activeToasts = ReportExports.getQueuedReportInfo().map((statusEntry) => statusEntry.id);
		toaster.dropUnavailableCloseables(activeToasts);
	}

	const addTypeClassToReportInfo = (reportInfo) => ({
		...reportInfo,
		typeClass: ReportExports.getFileExtensionIconClass(reportInfo.extension),
	});

	const isScheduledReportsLimitReached = () => vm.reports.filter((template) => template.hasScheduleConfigured()).length >= MAX_SCHEDULED_REPORTS_COUNT;

	const updateReportTemplates = () => {
		vm.reports = ReportTemplates.getList();
		vm.isScheduledReportsLimitReached = isScheduledReportsLimitReached();
	};

	updateReportTemplates();
	updateOfflineReports();

	vm.getReportDateRange = (report) => `${formatDate(report.period.start, $translate.use())} - ${formatDate(report.period.end, $translate.use())}`;

	vm.getFileExtensionTitle = (extension) => {
		if (extension === 'csv') {
			return 'LABEL.REPORTS.CSV_FILE';
		}
		if (extension === 'xlsx') {
			return 'LABEL.REPORTS.EXCEL_FILE';
		}
		return '';
	};

	vm.toLocalTime = (time) => (time instanceof Date && !Number.isNaN(time.getTime()) ? format(time, 'dd.MM.yyyy HH:mm') : '');

	vm.isExportPending = (templateId) => ReportExports.isPendingByTemplateId(templateId);

	vm.getReportErrorMessage = (filename) => ReportExports.getReportErrorMessage(filename);

	vm.canQueueAnotherExport = ReportExports.canQueueAnotherExport;

	vm.createExport = (templateId) => {
		ModalFactory.generateReportExport(
			{
				templateId,
			},
			(reportId) => {
				if (reportId) {
					InfoService.endRequest();
					ReportExports.enableStatusCheckLoopForTemplateId(templateId, reportId);
				}
			},
		);
	};

	const onReportModalClose = (templateId) => {
		if (angular.isDefined(templateId)) {
			$state.go('reports.view', { id: templateId });
		}
	};

	vm.editReportTemplate = (templateId) => {
		ModalFactory.newEditReportTemplate(
			{
				templateConfig: {
					templateId: templateId,
					duplicate: false,
				},
			},
			(id) => onReportModalClose(id),
		);
	};

	vm.createReportTemplate = () => {
		ModalFactory.newEditReportTemplate(
			{
				templateConfig: {
					templateId: undefined,
					duplicate: false,
				},
			},
			(id) => onReportModalClose(id),
		);
	};

	vm.duplicateReportTemplate = (templateId) => {
		ModalFactory.newEditReportTemplate(
			{
				templateConfig: {
					templateId: templateId,
					duplicate: true,
				},
			},
			(id) => onReportModalClose(id),
		);
	};

	vm.deleteReportTemplate = (template) => {
		$translate('MESSAGE.DELETE_PRESET_CONFIRMATION').then((confirmationText) => {
			// eslint-disable-next-line no-alert, no-restricted-globals
			if (confirm(confirmationText + '\n' + template.name)) {
				ReportTemplates.delete(template)
					.then(() => {
						toaster.successMessage('MESSAGE.REPORT_TEMPLATE_DELETE_SUCCESS');
						ReportExports.removeTemplateIdFromPending(template.id);
						updateReportTemplates();
					})
					.catch(() => toaster.errorMessage('MESSAGE.REPORT_TEMPLATE_DELETE_ERROR'));
			}
		});
	};

	//  Scheduled reports

	vm.getScheduleTranslationTitleValue = (templateId) => {
		const template = ReportTemplates.getById(templateId);

		if (template.hasScheduleConfigured()) {
			if (template.getSchedule().isScheduledWithDay()) {
				return 'TITLE.REPORTS.EDIT_SCHEDULED_REPORT_WEEKLY_MONTHLY';
			}

			return 'TITLE.REPORTS.EDIT_SCHEDULED_REPORT_DAILY';
		}

		if (vm.isScheduledReportsLimitReached) {
			return 'TITLE.REPORTS.CONFIGURE_SCHEDULED_REPORT_LIMIT_REACHED';
		}

		return 'TITLE.REPORTS.CONFIGURE_SCHEDULED_REPORT';
	};

	vm.getScheduleTranslationValues = (templateId) => {
		const language = $translate.use();
		const { schedule } = ReportTemplates.getById(templateId).getSchedule();

		let cycle = $translate.instant(schedule.getTypeTranslation());

		let day = schedule.getDayTranslation(language);
		if (language === 'en') {
			cycle = cycle.toLowerCase();

			if (schedule.isScheduledWeekly()) {
				day += 's';
			} else if (schedule.isScheduledMonthly()) {
				day = `day ${day}`;
			}
		}

		return { cycle, day };
	};

	vm.handleInvalidLossRate = () => {
		toaster.errorMessage('TEXT.REPORTS_TEMPLATE_DATE_RANGE_INFO_LOSS_RATE');
	};

	vm.canScheduleReport = (templateId) => ReportTemplates.getById(templateId).hasScheduleSupport();

	vm.hasScheduleConfigured = (templateId) => ReportTemplates.getById(templateId).hasScheduleConfigured();

	vm.configureSchedule = (templateId) => {
		ModalFactory.configureScheduledReport(
			{
				templateId,
			},
			(settingsHaveChanged) => {
				if (settingsHaveChanged) {
					updateReportTemplates();
				}
			},
		);
	};

	const removeExportStatusUpdatedListener = $rootScope.$on(ReportExports.EVENT.STATUS_UPDATED, updateOfflineReports);
	const removeCreateTemplateListener = $rootScope.$on(Reports.EVENT.SAVED, () => {
		vm.reports = ReportTemplates.getList();
	});
	const removeDeleteTemplateListener = $rootScope.$on(Reports.EVENT.DELETED, () => {
		vm.reports = ReportTemplates.getList();
	});

	$scope.$on('$destroy', () => {
		removeExportStatusUpdatedListener();
		removeCreateTemplateListener();
		removeDeleteTemplateListener();
	});

	// initialization
	ReportExports.enableStatusCheckLoop();
}

export default ReportsOverviewController;
