import AdvertiserFilter from '../models/AdvertiserFilter.js';
import { ascByNameField } from '../utils/sort-utils.js';
import blackWhiteFilterHtml from '../views/directives/blackWhiteFilter.html';

function BlackWhiteFilter(AuthService) {
	'ngInject';

	return {
		template: blackWhiteFilterHtml,
		scope: {
			filter: '=',
			assignedData: '=',
			availableData: '<',
			blacklistOnly: '=',
		},
		controllerAs: '$ctrl',
		controller: function BlackWhiteFilterController($scope, uiGridConstants) {
			'ngInject';

			const vm = this;

			// initialize the filter type model for the radio buttons
			vm.filter = $scope.filter;
			vm.blacklistOnly = $scope.blacklistOnly;

			if (vm.blacklistOnly) {
				vm.filter.type = AdvertiserFilter.TYPE.BLACKLIST;
			}

			// initialize the filter lists per type
			const filters = {
				[AdvertiserFilter.TYPE.BLACKLIST]: [],
				[AdvertiserFilter.TYPE.WHITELIST]: [],
			};

			// load active filter list from config
			filters[vm.filter.type] = $scope.assignedData;

			vm.assignedAdvertisersFilter = '';
			vm.availableAdvertisersFilter = '';

			vm.assignedAdvertisers = filters[vm.filter.type];
			vm.assignedAdvertisers.sort(ascByNameField);

			const availableAdvertisers = $scope.availableData;
			availableAdvertisers.sort(ascByNameField);
			vm.availableAdvertisers = [...availableAdvertisers]; // use a copy here to not mess up the initial list as we're using splice() when clicking on rows in the grid

			if (vm.assignedAdvertisers.length > 0) {
				vm.availableAdvertisers = availableAdvertisers.filter(
					(available) => vm.assignedAdvertisers.findIndex((assigned) => assigned.id === available.id) === -1,
				);
			}

			vm.isBlacklist = () => vm.filter.type === AdvertiserFilter.TYPE.BLACKLIST;

			vm.filterTypeHasChanged = () => {
				if (vm.filter.hasTypeChanged) {
					return vm.filter.hasTypeChanged();
				}
				return false;
			};

			vm.isSelectionEnabled = () => AuthService.isAdmin();

			vm.onFilterTypeChanged = () => {
				vm.assignedAdvertisers = filters[vm.filter.type];
				$scope.assignedData = vm.assignedAdvertisers; // hack to "export" list changes
				vm.availableAdvertisers = availableAdvertisers.filter(
					(available) => vm.assignedAdvertisers.findIndex((assigned) => assigned.id === available.id) === -1,
				);

				vm.onSelectedFilterChanged();
				vm.onAvailableFilterChanged();
			};

			vm.onSelectedFilterChanged = () => {
				vm.selectedGridOptions.data = vm.assignedAdvertisers.filter(
					(item) => !vm.assignedAdvertisersFilter || item.name.toLowerCase().includes(vm.assignedAdvertisersFilter.toLowerCase()),
				);
			};

			vm.onAvailableFilterChanged = () => {
				vm.availableGridOptions.data = vm.availableAdvertisers.filter(
					(item) => !vm.availableAdvertisersFilter || item.name.toLowerCase().includes(vm.availableAdvertisersFilter.toLowerCase()),
				);
			};

			const commonGridOptions = {
				showHeader: false,

				enableHorizontalScrollbar: uiGridConstants.scrollbars.NEVER,
				enableVerticalScrollbar: uiGridConstants.scrollbars.WHEN_NEEDED,

				enableFiltering: false,
				enableSorting: false,

				enableRowSelection: vm.isSelectionEnabled(),
				enableRowHeaderSelection: false,
				multiSelect: false,

				columnDefs: [
					{
						field: 'name',
					},
				],
				rowHeight: 42,
			};

			vm.selectedGridOptions = {
				...commonGridOptions,
				data: vm.assignedAdvertisers,
				onRegisterApi: (gridApi) => {
					vm.selectedGridApi = gridApi;
					gridApi.selection.on.rowSelectionChanged($scope, (row) => {
						const item = vm.assignedAdvertisers.splice(
							vm.assignedAdvertisers.findIndex((entry) => entry.id === row.entity.id),
							1,
						)[0];
						vm.availableAdvertisers.push(item);
						vm.availableAdvertisers.sort(ascByNameField);

						vm.onAvailableFilterChanged();
						vm.onSelectedFilterChanged();
					});
				},
			};

			vm.availableGridOptions = {
				...commonGridOptions,
				data: vm.availableAdvertisers,
				onRegisterApi: (gridApi) => {
					vm.availabeGridApi = gridApi;
					gridApi.selection.on.rowSelectionChanged($scope, (row) => {
						const item = vm.availableAdvertisers.splice(
							vm.availableAdvertisers.findIndex((entry) => entry.id === row.entity.id),
							1,
						)[0];
						vm.assignedAdvertisers.push(item);
						vm.assignedAdvertisers.sort(ascByNameField);

						vm.onAvailableFilterChanged();
						vm.onSelectedFilterChanged();
					});
				},
			};
		},
	};
}

export default BlackWhiteFilter;
