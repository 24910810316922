class ReportExportSettings {
	constructor(data) {
		this.filetype = data.fileType || ReportExportSettings.FILE_TYPE.EXCEL;
		this.filename = data.fileName || undefined;
	}

	static FILE_TYPE = {
		CSV: 'csv',
		EXCEL: 'xlsx',
	};

	static FILE_TYPE_TRANSLATION = {
		CSV: 'CAPTION.FILE_TYPE_CSV',
		EXCEL: 'CAPTION.FILE_TYPE_XLSX',
	};

	/**
	 * @returns {boolean}
	 */
	isValidFilename() {
		return Boolean(this.filename);
	}

	static getFiletypeSelectOptions() {
		return Object.keys(ReportExportSettings.FILE_TYPE).map((type) => ({
			name: ReportExportSettings.FILE_TYPE_TRANSLATION[type],
			value: ReportExportSettings.FILE_TYPE[type],
		}));
	}

	toObject() {
		const settings = {
			fileType: this.filetype,
			fileName: this.filename,
		};

		return settings;
	}
}

export default ReportExportSettings;
