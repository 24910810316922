import pagerInfoHtml from '../../views/directives/pager-info.html';

const pagerInfo = {
	bindings: {
		pagerId: '@',
		dropup: '<?',
	},
	template: pagerInfoHtml,
	controller: 'PagerInfoController',
};

export default pagerInfo;
