// $delegate refers to the original directive definition.
function ngBlurDecoration($delegate) {
	'ngInject';

	// When decorating a directive, $delegate is an array with the
	// actual directive at the first index.
	var directive = $delegate[0],
		// original directive uses compile function so we have to save that first
		compileOriginal = directive.compile;

	// add requirement of a model controller but make it optional to not break existing blur directives
	directive.require = '?ngModel';

	// hook into compile
	directive.compile = function compile() {
		// run original compile and save returned link function
		var link = compileOriginal.apply(this, arguments);

		/*
		 * add custom ngBlur behavior to the link function
		 * 	- adds ng-blurred class to the corresponding element
		 * 	- sets $blurred property of the corresponding controller element
		 */
		return function postLink($scope, iElement, iAttrs, ngModelCtrl) {
			var BLUR_CLASS = 'ng-blurred',
				$setPristineOriginal;

			// run the original link function
			link.apply(this, arguments);

			if (ngModelCtrl) {
				ngModelCtrl.$blurred = false;

				$setPristineOriginal = ngModelCtrl.$setPristine;
				ngModelCtrl.$setPristine = function $setPristine() {
					// call original method
					$setPristineOriginal.apply(this);

					// and extend behavior
					ngModelCtrl.$blurred = false;
					iElement.removeClass(BLUR_CLASS);
				};

				ngModelCtrl.setUnblurred = function setUnblurred() {
					ngModelCtrl.$blurred = false;
					iElement.removeClass(BLUR_CLASS);
					return true;
				};

				ngModelCtrl.setBlurred = function setBlurred() {
					ngModelCtrl.$blurred = true;
					iElement.addClass(BLUR_CLASS);
					return true;
				};
			}

			const handleKeydown = (event) => {
				// do not remove blur state/class when ESC or ENTER key down
				if (event.which !== 27 && event.which !== 13) {
					iElement.removeClass(BLUR_CLASS);

					if (ngModelCtrl) {
						$scope.$apply(function apply() {
							ngModelCtrl.$blurred = false;
						});
					}
				}
			};
			iElement.on('keydown', handleKeydown);

			const handleBlur = () => {
				iElement.addClass(BLUR_CLASS);

				if (ngModelCtrl) {
					$scope.$apply(function apply() {
						ngModelCtrl.$blurred = true;
					});
				}
			};
			iElement.on('blur', handleBlur);

			const handleFocus = () => {
				iElement.removeClass(BLUR_CLASS);

				if (ngModelCtrl) {
					$scope.$apply(function apply() {
						ngModelCtrl.$blurred = false;
					});
				}
			};
			iElement.on('focus', handleFocus);

			$scope.$on('$destroy', () => {
				iElement.off('keydown', handleKeydown);
				iElement.off('blur', handleBlur);
				iElement.off('focus', handleFocus);
			});
		};
	};

	// Return the decorated original ($delegate).
	return $delegate;
}

export default ngBlurDecoration;
