import angular from 'angular';

import AdslotAdvancedController from './inventory-adslots-view-advanced.controller.js';
import AdslotCategoriesController from './inventory-adslots-view-categories.controller.js';
import AdslotNativeTemplateController from './inventory-adslots-view-native-template.controller.js';
import AdslotDetailsController from './inventory-adslots-view-details.controller.js';
import AdslotCategoryFilterController from './inventory-adslots-view-category-filter.controller.js';
import AdslotFilterController from './inventory-adslots-view-filter.controller.js';
import AdslotPartnershipsController from './inventory-adslots-view-partnerships.controller.js';
import AdslotPartnershipsAddController from './inventory-adslots-view-partnerships-add.controller.js';
import InventoryAdslotsViewController from './inventory-adslots-view.controller.js';

const InventoryAdslotsViewModule = angular
	.module('inventory.adslots.view', [])
	.controller('AdslotAdvancedController', AdslotAdvancedController)
	.controller('AdslotCategoriesController', AdslotCategoriesController)
	.controller('AdslotNativeTemplateController', AdslotNativeTemplateController)
	.controller('AdslotDetailsController', AdslotDetailsController)
	.controller('AdslotCategoryFilterController', AdslotCategoryFilterController)
	.controller('AdslotFilterController', AdslotFilterController)
	.controller('AdslotPartnershipsController', AdslotPartnershipsController)
	.controller('AdslotPartnershipsAddController', AdslotPartnershipsAddController)
	.controller('AdslotsViewController', InventoryAdslotsViewController).name;

export default InventoryAdslotsViewModule;
