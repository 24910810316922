function ModelUpdateOn($rootScope) {
	'ngInject';

	return {
		require: 'ngModel',
		restrict: 'A',
		link: function postLink(scope, iElement, iAttrs, ngModel) {
			var unbindDestroyListener, unbindFormSubmitListener;

			if (angular.isDefined(iAttrs.submit)) {
				// remove default handlers to prevent model updates the old way
				iElement.unbind('input').unbind('keydown').unbind('change');

				unbindFormSubmitListener = $rootScope.$on('yl:FormSubmitted', function ylFormSubmitted() {
					ngModel.$setViewValue(iElement.val());
				});

				unbindDestroyListener = scope.$on('$destroy', function $destroyListener() {
					unbindFormSubmitListener();
					unbindDestroyListener();
				});
			}
		},
	};
}

export default ModelUpdateOn;
