import newPartnershipHtml from '../views/partnerships/partnership-new.html';
import duplicatePartnershipHtml from '../views/partnerships/duplicate.html';
import newSiteHtml from '../views/adslots/sites-new.html';
import newGroupHtml from '../views/adslots/groups-new.html';
import newAdslotHtml from '../views/adslots/adslot-new.html';
import uploadAdslotCSVHtml from '../views/adslots/upload-adslot-csv.html';
import uploadPartnershipCSVHtml from '../views/partnerships/upload-partnership-csv.html';
import moveAdslotsHtml from '../views/adslots/adslot-move.html';
import adslotFilterHtml from '../views/reports/adslot-filter.html';
import advertiserFilterHtml from '../views/reports/advertiser-filter.html';
import demandPartnerFilterHtml from '../views/reports/demand-partner-filter.html';
import partnershipFilterHtml from '../views/reports/partnership-filter.html';
import generateExportHtml from '../views/reports/generate-export.html';
import editReportHtml from '../views/reports/edit-report.html';
import newEditReportTemplateHtml from '../views/reports/new-edit.html';
import configureScheduledReportHtml from '../views/reports/configure-scheduled-report.html';
import simpleModalMessageHtml from '../views/overlays/simple-modal-message.html';
import imageModalMessageHtml from '../views/overlays/image-modal-message.html';
import providerRemovalModalMessageHtml from '../views/settings/provider-whitelist-removal-confirmation.html';
import addAdslotsToPartnershipModalHtml from '../views/partnerships/add-adslots.html';
import addPartnershipsToAdslotModalHtml from '../views/adslots/add-partnerships.html';
import addAdslotsToOpenAuctionConfigModalHtml from '../views/partnerships/open-auction-adslots-add.html';

function ModalFactory(InfoService, ModalCloser, ModalService) {
	'ngInject';

	/**
	 * @param {Object} config
	 * @param {function(*)} [onClose]
	 */
	this.triggerModal = function triggerModal(config, onClose) {
		ModalService.showModal(
			angular.extend({}, config, {
				bodyClass: 'is-modal-open',
				inputs: angular.extend({}, config.inputs, {
					// used to prevent runtime error inside the modal service plugin when calling close more than once
					ModalCloser: new ModalCloser(),
				}),
			}),
		).then(function openModal(modal) {
			InfoService.openModal();

			modal.close
				.then(function modalClosed(result) {
					InfoService.closeModal();
					return result;
				})
				.then(onClose || angular.noop);
		});
	};

	/**
	 * @param {Object} inputs
	 * @param {function(*)} [onClose]
	 */
	this.newPartnership = function newPartnership(inputs, onClose) {
		this.triggerModal(
			{
				template: newPartnershipHtml,
				controller: 'PartnershipNewController',
				controllerAs: '$ctrl',
			},
			onClose,
		);
	};

	/**
	 * @param {Object} inputs
	 * @param {function(*)} [onClose]
	 */
	this.duplicatePartnership = function duplicatePartnership(inputs, onClose) {
		this.triggerModal(
			{
				template: duplicatePartnershipHtml,
				controller: 'PartnershipsDuplicateController',
				controllerAs: '$ctrl',
				inputs: inputs,
			},
			onClose,
		);
	};

	this.newSite = function newSite() {
		this.triggerModal({
			template: newSiteHtml,
			controller: 'SitesNewController',
			controllerAs: '$ctrl',
		});
	};

	/**
	 * @param {Object} inputs
	 */
	this.newGroup = function newGroup(inputs) {
		this.triggerModal({
			template: newGroupHtml,
			controller: 'GroupsNewController',
			controllerAs: '$ctrl',
			inputs: angular.extend(
				{
					siteId: 0, // default -> no site selected
					sitesList: undefined,
				},
				inputs,
			),
		});
	};

	/**
	 * @param {Object} inputs
	 * @param {function(*)} [onClose]
	 */
	this.newAdslot = function newAdslot(inputs, onClose) {
		this.triggerModal(
			{
				template: newAdslotHtml,
				controller: 'AdslotNewController',
				controllerAs: '$ctrl',
				inputs: angular.extend(
					{
						groupId: 0, // default -> no group selected
					},
					inputs,
				),
			},
			onClose,
		);
	};

	/**
	 * @param {Object} inputs
	 * @param {function(*)} [onClose]
	 */
	this.moveAdslots = function moveAdslots(inputs, onClose) {
		this.triggerModal(
			{
				template: moveAdslotsHtml,
				controller: 'AdslotMoveController',
				controllerAs: '$ctrl',
				inputs: angular.extend(
					{
						groupId: 0, // default -> no group selected
					},
					inputs,
				),
			},
			onClose,
		);
	};

	/**
	 * @param {Object} inputs
	 * @param {function(*)} [onClose]
	 */
	this.uploadAdslotCSV = function uploadAdslotCSV(inputs, onClose) {
		this.triggerModal(
			{
				template: uploadAdslotCSVHtml,
				controller: 'UploadAdslotCSVController',
				controllerAs: '$ctrl',
				inputs: inputs,
			},
			onClose,
		);
	};

	/**
	 * @param {Object} inputs
	 * @param {function(*)} [onClose]
	 */
	this.uploadPartnershipCSV = function uploadPartnershipCSV(inputs, onClose) {
		this.triggerModal(
			{
				template: uploadPartnershipCSVHtml,
				controller: 'UploadPartnershipCSVController',
				controllerAs: '$ctrl',
				inputs: inputs,
			},
			onClose,
		);
	};

	/**
	 * @param {Object} inputs
	 * @param {function(*)} [onClose]
	 */
	this.reportAdslotsFilter = (inputs, onClose) => {
		this.triggerModal(
			{
				template: adslotFilterHtml,
				controller: 'ReportsAdslotFilterController',
				controllerAs: '$ctrl',
				inputs: inputs,
			},
			onClose,
		);
	};

	/**
	 * @param {Object} inputs
	 * @param {function(*)} [onClose]
	 */
	this.reportAdvertisersFilter = (inputs, onClose) => {
		this.triggerModal(
			{
				template: advertiserFilterHtml,
				controller: 'ReportsAdvertiserFilterController',
				controllerAs: '$ctrl',
				inputs: inputs,
			},
			onClose,
		);
	};

	/**
	 * @param {Object} inputs
	 * @param {function(*)} [onClose]
	 */
	this.reportDemandPartnersFilter = (inputs, onClose) => {
		this.triggerModal(
			{
				template: demandPartnerFilterHtml,
				controller: 'ReportsDemandPartnerFilterController',
				controllerAs: '$ctrl',
				inputs: inputs,
			},
			onClose,
		);
	};

	/**
	 * @param {Object} inputs
	 * @param {function(*)} [onClose]
	 */
	this.reportPartnershipsFilter = function reportPartnershipsFilter(inputs, onClose) {
		this.triggerModal(
			{
				template: partnershipFilterHtml,
				controller: 'ReportsPartnershipFilterController',
				controllerAs: '$ctrl',
				inputs: inputs,
			},
			onClose,
		);
	};

	/**
	 * @param {Object} inputs
	 * @param {function(*)} [onClose]
	 */
	this.generateReportExport = (inputs, onClose) => {
		this.triggerModal(
			{
				template: generateExportHtml,
				controller: 'ReportsGenerateExportController',
				controllerAs: '$ctrl',
				inputs: inputs,
			},
			onClose,
		);
	};

	/**
	 * @param {Object} inputs
	 * @param {function(*)} [onClose]
	 */
	this.editReport = (inputs, onClose) => {
		this.triggerModal(
			{
				template: editReportHtml,
				controller: 'ReportsViewEditController',
				controllerAs: '$ctrl',
				inputs: inputs,
			},
			onClose,
		);
	};

	/**
	 * @param {Object} inputs
	 * @param {function(*)} [onClose]
	 */
	this.newEditReportTemplate = (inputs, onClose) => {
		this.triggerModal(
			{
				template: newEditReportTemplateHtml,
				controller: 'ReportsNewEditController',
				controllerAs: '$ctrl',
				inputs: inputs,
			},
			onClose,
		);
	};

	/**
	 * @param {Object} inputs
	 * @param {function(*)} [onClose]
	 */
	this.configureScheduledReport = (inputs, onClose) => {
		this.triggerModal(
			{
				template: configureScheduledReportHtml,
				controller: 'ReportsConfigureScheduledReportController',
				controllerAs: '$ctrl',
				inputs: inputs,
			},
			onClose,
		);
	};

	/**
	 * @param {Object} inputs
	 * @param {function(*)} [onClose]
	 */
	this.simpleModalMessage = function simpleModalMessage(inputs, onClose) {
		this.triggerModal(
			{
				template: simpleModalMessageHtml,
				controller: 'SimpleModalMessageController',
				controllerAs: '$ctrl',
				inputs: inputs,
			},
			onClose,
		);
	};

	this.imageModalMessage = function imageModalMessage(inputs, onClose) {
		this.triggerModal(
			{
				template: imageModalMessageHtml,
				controller: 'ImageModalMessageController as $ctrl',
				inputs: inputs,
			},
			onClose,
		);
	};

	/**
	 * @param {Object} uncheckedProviders
	 * @param {function(*)} [onClose]
	 */
	this.confirmProviderRemovalModal = (uncheckedProviders, onClose) => {
		this.triggerModal(
			{
				template: providerRemovalModalMessageHtml,
				controller: 'ProviderWhitelistRemovalConfirmationController as $ctrl',
				inputs: {
					uncheckedProviders: uncheckedProviders,
				},
			},
			onClose,
		);
	};

	/**
	 * @param {Object} inputs
	 * @param {function(*)} [onClose]
	 */
	this.addAdslotsToPartnershipModal = (inputs, onClose) => {
		this.triggerModal(
			{
				template: addAdslotsToPartnershipModalHtml,
				controller: 'PartnershipAdslotsAddController as $ctrl',
				inputs,
			},
			onClose,
		);
	};

	/**
	 * @param {Object} inputs
	 * @param {function(*)} [onClose]
	 */
	this.addPartnershipsToAdslotModal = (inputs, onClose) => {
		this.triggerModal(
			{
				template: addPartnershipsToAdslotModalHtml,
				controller: 'AdslotPartnershipsAddController as $ctrl',
				inputs,
			},
			onClose,
		);
	};

	/**
	 * @param {Object} inputs
	 * @param {function(*)} [onClose]
	 */
	this.addAdslotsToOpenAuctionConfigModal = (inputs, onClose) => {
		this.triggerModal(
			{
				template: addAdslotsToOpenAuctionConfigModalHtml,
				controller: 'PartnershipsOpenAuctionsViewAdslotsAddController as $ctrl',
				inputs,
			},
			onClose,
		);
	};
}

export default ModalFactory;
