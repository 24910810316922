function NoFocus($document) {
	'ngInject';

	return {
		restrict: 'A',
		link: function postLink(scope, iElement, attr) {
			var unbindDestroyListener;

			function interruptFocus(event) {
				var checkbox = angular.element($document[0].getElementById(attr.for));
				event.preventDefault();
				checkbox.prop('checked', !checkbox.prop('checked'));
				checkbox.triggerHandler('change');
			}

			iElement.on('change', interruptFocus);

			unbindDestroyListener = scope.$on('$destroy', function cleanUp() {
				iElement.off('change', interruptFocus);
				unbindDestroyListener();
			});
		},
	};
}

export default NoFocus;
