import SelectionService from '../../../../modules/utils/SelectionService.js';

function AdslotPartnershipsAddController(
	$rootScope,
	$scope,
	Adslots,
	Partnerships,
	AdslotPartnerships,
	InfoService,
	ListFilterFactory,
	ModalCloser,
	toaster,
	close,
	adslotId,
	partnerships,
) {
	'ngInject';

	const vm = this;

	const KNOWN_DUPLICATE_ERROR_CODES = [Partnerships.ERROR.DUPLICATE, Partnerships.ERROR.ACTIVE_SIZES_OVERLAP_EXIST];

	function triggerClose(result, delay) {
		ModalCloser.close(close, result, delay);
	}

	vm.ADD_PARTNERSHIPS_LIST_ID = 'add-partnerships-to-adslots';
	vm.COLSPAN = 5;

	vm.availablePartnershipsListFilter = ListFilterFactory.get(ListFilterFactory.LAYOUTS.PARTNERSHIPS.PRICING).load(
		ListFilterFactory.DEFAULT_SETTINGS.PARTNERSHIPS.LIST,
	);
	vm.availablePartnershipsListFilterFn = (...args) => vm.availablePartnershipsListFilter.matchesFilter(...args);

	vm.adslot = Adslots.getById(adslotId).name;
	vm.partnerships = partnerships;
	vm.filteredPartnerships = [];

	vm.sortKey = 'name';
	vm.search = {};

	vm.selection = new SelectionService();
	vm.selectionErrors = {};

	vm.getFilteredPartnerships = () => vm.filteredPartnerships;

	vm.toggleOne = (partnershipId) => vm.selection.toggleOne(partnershipId);

	const handleDuplicatePrivateAuctionsErrors = (error) => {
		let rejectedPartnershipIds = error.attributes[adslotId];
		vm.selectionErrors = rejectedPartnershipIds.reduce((idMap, id) => {
			idMap[id] = true;
			return idMap;
		}, {});

		if (Partnerships.ERROR.ACTIVE_SIZES_OVERLAP_EXIST === error.code) {
			toaster.errorMessage('MESSAGE.PARTNERSHIPS_ADSLOT_ERROR_ACTIVE_SIZES_OVERLAP_EXIST');
		} else {
			toaster.errorMessage('MESSAGE.PARTNERSHIP_ADSLOT_DUPLICATES');
		}
	};

	vm.submit = () => {
		const partnershipIds = vm.selection.values();

		if (partnershipIds.length) {
			InfoService.startRequest();

			AdslotPartnerships.add(adslotId, partnershipIds)
				.then((adslotPartnerships) => {
					toaster.successMessage('MESSAGE.ADSLOT_SAVE_SUCCESS');
					triggerClose(adslotPartnerships, 0);
				})
				.catch((error) => {
					if (KNOWN_DUPLICATE_ERROR_CODES.includes(error.code)) {
						handleDuplicatePrivateAuctionsErrors(error);
					} else {
						toaster.errorMessage('MESSAGE.ADSLOT_SAVE_ERROR');
					}
				})
				.finally(() => InfoService.endRequest());
		}
	};

	vm.cancel = () => {
		triggerClose([], 200);
	};

	const unregisterSessionTimeoutListener = $rootScope.$on('event:auth-loginRequired', vm.cancel);

	$scope.$on('$destroy', unregisterSessionTimeoutListener);
}

export default AdslotPartnershipsAddController;
