const USER_ROLES = {
	ALL: '*',
	ADMIN: 'admin',
	USER: 'user',
	REPORTS: 'reports',
	YL_ADMIN: 'yl-admin',
	BETA: 'beta',
};

export default USER_ROLES;
