import { hash } from 'immutable';

import PLATFORM_TYPE from './AdslotPlatformType.js';

class AdslotDimension {
	/**
	 * @type {number}
	 */
	width = 0;

	/**
	 * @type {number}
	 */
	height = 0;

	/**
	 * @type {boolean}
	 */
	custom = true;

	static CUSTOM_DIMENSION_STRING = 'LABEL.CUSTOM_SIZE';

	/**
	 * @param {number} [width]
	 * @param {number} [height]
	 * @param {boolean} [custom]
	 */
	constructor(width = 0, height = 0, custom = true) {
		this.width = width;
		this.height = height;
		this.custom = custom;
	}

	static fromString(dimensionString = '', asCustom = undefined) {
		if (dimensionString === '' || dimensionString === this.CUSTOM_DIMENSION_STRING) {
			return new AdslotDimension(0, 0);
		}

		if (!/^\d{1,5}x\d{1,5}$/.test(dimensionString)) {
			throw new Error(`invalid dimension string ${dimensionString}`);
		}

		const [width, height] = dimensionString.split('x');
		return new AdslotDimension(Number.parseInt(width, 10), Number.parseInt(height, 10), asCustom);
	}

	/**
	 * @param {number} platformType
	 */
	static hasPredefinedDimensions(platformType) {
		return Object.prototype.hasOwnProperty.call(PREDEFINED_DIMENSIONS, platformType);
	}

	toString() {
		return `${this.width}x${this.height}`;
	}

	toObject() {
		return {
			width: this.width,
			height: this.height,
		};
	}

	/**
	 * @param other {AdslotDimension}
	 * @return {boolean}
	 */
	equals(other) {
		return other.width === this.width && other.height === this.height;
	}

	/**
	 * @returns {number}
	 */
	hashCode() {
		return hash(this.width + Object.prototype.toString.apply(this.width) + this.height + Object.prototype.toString.apply(this.height));
	}
}

const DIMENSIONS = {
	W_120_H_600: new AdslotDimension(120, 600, false),
	W_160_H_600: new AdslotDimension(160, 600, false),
	W_300_H_250: new AdslotDimension(300, 250, false),
	W_320_H_480: new AdslotDimension(320, 480, false),
	W_300_H_600: new AdslotDimension(300, 600, false),
	W_320_H_50: new AdslotDimension(320, 50, false),
	W_320_H_75: new AdslotDimension(320, 75, false),
	W_420_H_240: new AdslotDimension(420, 240, false),
	W_640_H_480: new AdslotDimension(640, 480, false),
	W_728_H_90: new AdslotDimension(728, 90, false),
	W_728_H_600: new AdslotDimension(728, 600, false),
	W_800_H_250: new AdslotDimension(800, 250, false),
	W_970_H_250: new AdslotDimension(970, 250, false),
	W_1280_H_720: new AdslotDimension(1280, 720, false),
};

export const PREDEFINED_DIMENSIONS = {
	[PLATFORM_TYPE.WEB]: new Set([
		DIMENSIONS.W_120_H_600.toString(),
		DIMENSIONS.W_160_H_600.toString(),
		DIMENSIONS.W_300_H_250.toString(),
		DIMENSIONS.W_300_H_600.toString(),
		DIMENSIONS.W_640_H_480.toString(),
		DIMENSIONS.W_728_H_90.toString(),
		DIMENSIONS.W_728_H_600.toString(),
		DIMENSIONS.W_800_H_250.toString(),
		DIMENSIONS.W_970_H_250.toString(),
	]),
	[PLATFORM_TYPE.MOBILE_WEB]: new Set([
		DIMENSIONS.W_300_H_250.toString(),
		DIMENSIONS.W_320_H_50.toString(),
		DIMENSIONS.W_320_H_75.toString(),
		DIMENSIONS.W_320_H_480.toString(),
	]),
	[PLATFORM_TYPE.MOBILE_APP]: new Set([
		DIMENSIONS.W_300_H_250.toString(),
		DIMENSIONS.W_320_H_50.toString(),
		DIMENSIONS.W_320_H_75.toString(),
		DIMENSIONS.W_320_H_480.toString(),
	]),
	[PLATFORM_TYPE.ADDRESSABLE_TV]: new Set([DIMENSIONS.W_420_H_240.toString(), DIMENSIONS.W_1280_H_720.toString()]),
	[PLATFORM_TYPE.CONNECTED_TV]: new Set([
		DIMENSIONS.W_300_H_250.toString(),
		DIMENSIONS.W_970_H_250.toString(),
		DIMENSIONS.W_728_H_90.toString(),
		DIMENSIONS.W_320_H_50.toString(),
	]),
};

export default AdslotDimension;
