function SortButtons($parse, $rootScope) {
	'ngInject';

	var EVENTS = {
			CLICK: 'click',
		},
		SORT_KEY_CHANGED = 'ylSortButton:sortKeyChanged',
		SORT_BUTTON_ACTIVE_CLASS = 'is-active',
		SORT_BUTTONS_WRAPPER_CLASS = 'sort-buttons',
		SORT_BUTTONS_CONTAINER_CLASS = 'sort-buttons-container';

	return {
		restrict: 'A',
		require: 'ngModel',
		scope: false,
		link: function postLink(scope, iElement, iAttrs, ngModelCtrl) {
			var unbindDestroyListener,
				removeMarkActiveListener,
				sortKeyAsc = iAttrs.ylSortButtons,
				sortKeyDesc = '-' + sortKeyAsc,
				sortKeyInitial = $parse(iAttrs.ngModel)(scope),
				ascSortBtn = angular.element('<i class="sort-button-asc icon-up-dir"></i>'),
				descSortBtn = angular.element('<i class="sort-button-desc icon-down-dir"></i>'),
				wrapper = angular.element('<div></div>'),
				buttonsContainer = angular.element('<div></div>');

			function changeSortKey(key) {
				ngModelCtrl.$setViewValue(key);
				scope.$apply();
				$rootScope.$emit(SORT_KEY_CHANGED, key);
			}

			if (!sortKeyAsc) {
				throw new Error('missing sort key name');
			}

			wrapper.addClass(SORT_BUTTONS_WRAPPER_CLASS);
			iElement.wrap(wrapper);

			// init click state
			ascSortBtn.toggleClass(SORT_BUTTON_ACTIVE_CLASS, sortKeyAsc === sortKeyInitial);
			descSortBtn.toggleClass(SORT_BUTTON_ACTIVE_CLASS, sortKeyDesc === sortKeyInitial);

			ascSortBtn.on(EVENTS.CLICK, function click() {
				if (sortKeyAsc !== ngModelCtrl.$viewValue) {
					changeSortKey(sortKeyAsc);
				}
			});

			descSortBtn.on(EVENTS.CLICK, function click() {
				if (sortKeyDesc !== ngModelCtrl.$viewValue) {
					changeSortKey(sortKeyDesc);
				}
			});

			buttonsContainer.append(ascSortBtn);
			buttonsContainer.append(descSortBtn);
			buttonsContainer.addClass(SORT_BUTTONS_CONTAINER_CLASS);
			iElement.after(buttonsContainer);

			removeMarkActiveListener = $rootScope.$on(SORT_KEY_CHANGED, function sortChanged(event, key) {
				ascSortBtn.toggleClass(SORT_BUTTON_ACTIVE_CLASS, sortKeyAsc === key);
				descSortBtn.toggleClass(SORT_BUTTON_ACTIVE_CLASS, sortKeyDesc === key);
			});

			unbindDestroyListener = scope.$on('$destroy', function cleanUp() {
				ascSortBtn.off(EVENTS.CLICK);
				descSortBtn.off(EVENTS.CLICK);
				removeMarkActiveListener();
				unbindDestroyListener();
			});
		},
	};
}

export default SortButtons;
