import providerListTemplate from '../../../../views/settings/provider-list.html';

export default {
	bindings: {
		providers: '<',
		filter: '<',
		pager: '@',
		searchId: '@',
		selection: '<',
		showSelected: '<?',
		showUnselected: '<?',
	},
	template: providerListTemplate,
	controller: 'ProviderListController as $ctrl',
};
