const AUTH_EVENTS = {
	loginSuccess: 'auth-login-success',
	loginFailed: 'auth-login-failed',
	logoutSuccess: 'auth-logout-success',
	sessionTimeout: 'auth-session-timeout',
	notAuthenticated: 'auth-not-authenticated',
	notAuthorized: 'auth-not-authorized',
	loginRedirected: 'auth-login-redirected',
	userImpersonated: 'auth-user-impersonated',
};

export default AUTH_EVENTS;
