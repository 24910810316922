function ListFilter() {
	/**
	 * @type {Object.<string, ListFilterGroup>}
	 */
	this.groups = {};

	/**
	 * @type {Object.<string, ListFilterGroup>}
	 */
	this.active = {};

	/**
	 * @type {Array.<ListFilterEntryUI>}
	 */
	this.activeUI = [];
}

/**
 * @param {{}} settings
 * @returns {ListFilter}
 */
ListFilter.prototype.load = function load(settings) {
	var self = this;

	Object.keys(self.groups).forEach(function loadGroupSettings(groupKey) {
		if (angular.isObject(settings) && Object.prototype.hasOwnProperty.call(settings, groupKey)) {
			self.groups[groupKey].load(settings[groupKey]);
		} else {
			self.groups[groupKey].reset();
		}
	});
	this.updateActive();

	return this;
};

ListFilter.prototype.getActiveFilterData = function getActiveFilterData() {
	var self = this,
		result = {};

	Object.keys(this.active).forEach(function collectGroups(groupKey) {
		result[groupKey] = Object.keys(self.active[groupKey].entries).reduce(function generateGroupResult(groupResult, entryKey) {
			groupResult[entryKey] = angular.copy(self.active[groupKey].entries[entryKey].model);
			return groupResult;
		}, {});
	});

	return result;
};

/**
 * @param {string} key
 * @param {ListFilterGroup} group
 * @returns {ListFilter}
 */
ListFilter.prototype.addGroup = function addGroup(key, group) {
	this.groups[key] = group;
	return this;
};

/**
 * @param {string} key
 * @returns {ListFilterGroup}
 */
ListFilter.prototype.getGroup = function getGroup(key) {
	return this.groups[key];
};

ListFilter.prototype.updateActive = function updateActive() {
	var groupKey,
		result,
		active = {},
		activeUI = [];

	for (groupKey in this.groups) {
		if (Object.prototype.hasOwnProperty.call(this.groups, groupKey)) {
			result = this.groups[groupKey].getActiveEntries();
			if (!result.group.isEmpty()) {
				active[groupKey] = result.group;
				activeUI = activeUI.concat(result.ui);
			}
		}
	}

	this.active = active;
	this.activeUI = activeUI;
};

ListFilter.prototype.removeActiveElement = function removeActiveElement(index) {
	this.activeUI[index].remove();
	this.activeUI.splice(index, 1);

	this.updateActive();
};

ListFilter.prototype.reset = function reset() {
	var groupKey;

	for (groupKey in this.active) {
		if (Object.prototype.hasOwnProperty.call(this.active, groupKey)) {
			this.active[groupKey].reset();
		}
	}

	this.active = {};
	this.activeUI = [];
};

/**
 * Predicate function to be used by angular filterFilter.
 * @param listElement
 * @returns boolean
 */
ListFilter.prototype.matchesFilter = function matchesFilter(listElement) {
	var groupKey,
		isMatching = true;

	for (groupKey in this.active) {
		if (Object.prototype.hasOwnProperty.call(this.active, groupKey)) {
			isMatching = isMatching && this.active[groupKey].hasGroupMatch(listElement);
		}
	}

	return !Object.keys(this.active).length || isMatching;
};

export default ListFilter;
