import angular from 'angular';

import AdslotListFilterModule from '../../adslot-list-filter/adslot-list-filter.module.js';
import AdslotListModule from '../../adslot-list/adslot-list.module.js';

import InventoryAdslotsListController from './inventory-adslots-list.controller.js';

const InventoryAdslotsListModule = angular
	.module('inventory.adslots.list', [AdslotListFilterModule, AdslotListModule])
	.controller('InventoryAdslotsListController', InventoryAdslotsListController).name;

export default InventoryAdslotsListModule;
