function LoginController($scope, AuthService, Password, toaster, InfoService, $timeout) {
	'ngInject';

	var togglesDefault = {
			login: false,
			forgot: false,
		},
		emptyForm = {
			success: false,
			user: '',
			password: '',
			email: '',
		};

	// show login screen as default
	$scope.toggles = angular.extend({}, togglesDefault, { login: true });

	$scope.form = angular.copy(emptyForm);

	$scope.submit = function submit(form) {
		form.submit();
	};

	$timeout(function () {
		if (AuthService.getLoginError() !== null) {
			AuthService.setLoginError(null);
			toaster.errorMessage('MESSAGE.LOGIN_FAILURE');
		}
	});

	$scope.showForgot = function showForgot() {
		$scope.toggles = angular.extend({}, togglesDefault, { forgot: true });
		$scope.form = angular.copy(emptyForm);
	};

	$scope.showLogin = function showLogin() {
		$scope.toggles = angular.extend({}, togglesDefault, { login: true });
		$scope.form = angular.copy(emptyForm);
	};

	$scope.requestResetLink = function requestResetLink() {
		InfoService.startRequest();
		Password.requestResetLink($scope.form.email)
			.then(function success() {
				InfoService.endRequest();
				$scope.form.success = true;
			})
			.catch(function error() {
				toaster.errorMessage('MESSAGE.PASSWORD_RESET_REQUEST_ERROR');
				InfoService.endRequest();
			});
	};
}

export default LoginController;
