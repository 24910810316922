import ListFilterGroup from './ListFilterGroup.js';

import listFilterGroupElementsHtml from '../../views/list-filter-group-elements.html';

/**
 * @param {string} key
 */
function ListFilterGroupElements(key) {
	ListFilterGroup.call(this, key);

	this.template = listFilterGroupElementsHtml;
}

ListFilterGroupElements.prototype = Object.create(ListFilterGroup.prototype);
ListFilterGroupElements.prototype.constructor = ListFilterGroupElements;

export default ListFilterGroupElements;
