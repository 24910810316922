import ERROR_STATUSES from '../api/api-statuses.constant.js';

/**
 * Handle a collection of parsing related errors
 */
class ErrorCollector {
	constructor() {
		this.errors = {};
	}

	recordError(lineNr, fieldName, errorType, errorStatus = null) {
		if (!this.errorExistsForLine(lineNr)) {
			this.errors[lineNr] = [];
		}
		this.errors[lineNr].push({
			errorType: errorType,
			fieldName: fieldName,
			lineNr: lineNr,
			errorStatus: errorStatus,
		});
	}

	recordLineError(lineNr, errorType) {
		this.recordError(lineNr, null, errorType);
	}

	recordGlobalError(error) {
		if (!error.message && !error.status) {
			return;
		}
		let errorMessage = error.message ? error.message : 'MESSAGE.UNKNOWN_SERVER_ERROR';
		if (error.status === ERROR_STATUSES.REQUEST_TIMEOUT) {
			errorMessage = 'MESSAGE.REQUEST_TIMEOUT_ERROR';
		}
		this.recordError(null, null, errorMessage, error.status);
	}

	recordUniqueFieldError(lineNr, fieldName, errorType) {
		if (!this.errorExistsForLineAndField(lineNr, fieldName)) {
			this.recordError(lineNr, fieldName, errorType);
		}
	}

	isTimeoutError() {
		return this.errors.null?.some((record) => record.errorStatus === ERROR_STATUSES.REQUEST_TIMEOUT);
	}

	errorsExists() {
		return !angular.equals(this.errors, {});
	}

	errorExistsForLine(lineNr) {
		return !!this.errors[lineNr];
	}

	errorExistsForLineAndField(lineNr, fieldName) {
		if (this.errors[lineNr]) {
			return this.errors[lineNr].some((record) => record.fieldName === fieldName);
		}
		return false;
	}

	getLinesWithErrors() {
		return Object.keys(this.errors);
	}

	getErrorsForLine(lineNr) {
		return this.errors[lineNr];
	}

	clearErrors() {
		this.errors = {};
	}
}

export default ErrorCollector;
