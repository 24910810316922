import groupsStructureInfoDe from '../../../images/Infographic_structure_groups_de.png';
import groupsStructureInfoEn from '../../../images/Infographic_structure_groups_en.png';

const infoGraphics = {
	de: groupsStructureInfoDe,
	en: groupsStructureInfoEn,
};

function SiteController($rootScope, $scope, $state, $translate, siteId, Adslots, AdslotGroups, StructureBreadcrumb, ListSearchService, ModalFactory) {
	'ngInject';

	var unbindSaveGroupNameListener,
		unbindGroupsUpdatedListener,
		INLINE_EDIT_SUFFIX = 'group-',
		vm = this;

	vm.groups = AdslotGroups.getListBySiteId(siteId);

	vm.search = ListSearchService.get('structure-group-search' + siteId);
	vm.sort = 'name';

	StructureBreadcrumb.viewSite(siteId);

	vm.trackBy = function trackBy(group) {
		return group.id + '-' + group.adslotIds;
	};

	vm.getAdslotCount = (group) =>
		group.adslotIds.filter((adslotId) => {
			const adslot = Adslots.getById(adslotId);
			return adslot && adslot.visible;
		}).length;

	/**
	 * @param {AdslotGroup} group
	 */
	vm.edit = function edit(group) {
		$rootScope.$emit('inlineEdit::start', INLINE_EDIT_SUFFIX + group.id);
	};

	vm.addGroups = function addGroups() {
		ModalFactory.newGroup({
			siteId: siteId,
		});
	};

	vm.addAdslot = function addAdslot(groupId) {
		ModalFactory.newAdslot(
			{
				groupId: groupId,
			},
			function adslotCreated(adslot) {
				if (angular.isDefined(adslot)) {
					$state.go('inventory.adslots.view.details', { id: adslot.id });
				}
			},
		);
	};

	vm.moveAdslots = function moveAdslots(groupId) {
		ModalFactory.moveAdslots({
			groupId: groupId,
		});
	};

	vm.showHelpModal = function showHelpModal() {
		ModalFactory.imageModalMessage({
			file: infoGraphics[$translate.use()],
		});
	};

	unbindSaveGroupNameListener = $rootScope.$on('inlineEdit::save', function saveGroupNameListener(event, triggerId, inputText) {
		AdslotGroups.update(triggerId.substr(INLINE_EDIT_SUFFIX.length), {
			name: inputText,
		})
			.then(function success() {
				$rootScope.$emit(AdslotGroups.EVENT.UPDATED);
				$rootScope.$emit('inlineEdit::save-success', triggerId);
			})
			.catch(function error(errorObject) {
				var errorMsg;

				switch (errorObject.code) {
					case AdslotGroups.ERROR.DUPLICATE_NAME:
						errorMsg = 'MESSAGE.GROUP_DUPLICATE_NAME';
						break;
					default:
						errorMsg = errorObject.errorMessage;
				}

				$rootScope.$emit('inlineEdit::save-error', triggerId, errorMsg);
			});
	});

	unbindGroupsUpdatedListener = $rootScope.$on(AdslotGroups.EVENT.UPDATED, function groupsUpdatedListener() {
		vm.groups = AdslotGroups.getListBySiteId(siteId);
	});

	$rootScope.cleanListeners($scope, [unbindSaveGroupNameListener, unbindGroupsUpdatedListener]);
}

export default SiteController;
