import SelectionService from '../../../modules/utils/SelectionService.js';

function AdslotListController(AdslotTranslations, ListSearchService) {
	'ngInject';

	const vm = this;

	const platformTypeTextMap = AdslotTranslations.getPlatformTypes();
	const adTypeTextMap = AdslotTranslations.getAdTypes();

	vm.sortKey = 'name';

	vm.indeterminate = -1;

	vm.mapPlatformTypeText = (typeId) => platformTypeTextMap[typeId];

	vm.mapAdTypeText = (typeId) => adTypeTextMap[typeId];

	vm.getFilteredAdslots = () => vm.filteredAdslots;

	vm.toggleOne = (adslotId) => vm.selection.toggleOne(adslotId);

	this.$onInit = function $onInit() {
		vm.search = !vm.searchReset ? ListSearchService.get(vm.searchId) : {};
		vm.selectionMode = vm.selectionMode || false;
		vm.selection = vm.selection || new SelectionService();
		vm.colspan = vm.selectionMode === false ? 4 : 5;
		vm.selectionErrors = vm.selectionErrors || {};
	};
}

export default AdslotListController;
