function PricingPartnershipsController(
	$scope,
	ListFilterFactory,
	ListFilterSettings,
	ListSearchService,
	Partnerships,
	DemandPartners,
	PartnershipsPrices,
	InfoService,
	toaster,
	natvalue,
) {
	'ngInject';

	var removeDestroyListener,
		listFilterId = 'pricing-partnerships-filter';

	$scope.models.demandFilterOptions = Partnerships.getDemandPartnersList().filter(({ id }) => DemandPartners.isVisible(id));

	$scope.models.partnershipFilters = ListFilterFactory.get(ListFilterFactory.LAYOUTS.PARTNERSHIPS.PRICING).load(
		ListFilterSettings.get(listFilterId) || ListFilterFactory.DEFAULT_SETTINGS.PARTNERSHIPS.PRICING,
	);

	// update potentially changed entry data
	$scope.models.partnershipFilters.getGroup('LABEL.DEMAND_PARTNER').getEntry('demandPartnerId').setEntryData($scope.models.demandFilterOptions);

	$scope.matchesFilter = $scope.models.partnershipFilters.matchesFilter.bind($scope.models.partnershipFilters);

	$scope.models.partnerships = Partnerships.getList().reduce(function onlyVisiblePartners(list, partnership) {
		if (DemandPartners.isVisible(partnership.getDemandId())) {
			list.push(partnership);
		}
		return list;
	}, []);

	$scope.models.partnershipsSearch = ListSearchService.get('pricing-partnerships-search');
	$scope.models.partnershipsSortKey = $scope.models.partnershipsSortKey || 'name';

	$scope.models.adslotsSortKey = undefined;

	$scope.models.form = $scope.methods.initializeForm($scope.models.partnerships, PartnershipsPrices.getMap());
	$scope.models.invalidPrices = {};

	$scope.methods.addPricesToList($scope.models.partnerships, $scope.models.form);

	$scope.natvalue = natvalue;

	$scope.save = function save() {
		var prices = $scope.methods.getChangedPrices();

		if (prices.length) {
			InfoService.startRequest();

			PartnershipsPrices.update(prices)
				.then(function success() {
					// set current form as initial state
					// extend initially loaded prices with the currently updated ones to have all prices correct
					$scope.models.form = $scope.methods.initializeForm(
						$scope.models.partnerships,
						prices.reduce(function reduce(priceMap, priceObject) {
							priceMap[priceObject.id] = {
								floorPrice: priceObject.floorPrice,
								fixedPrice: priceObject.fixedPrice,
							};
							return priceMap;
						}, PartnershipsPrices.getMap()),
					);
					$scope.methods.addPricesToList($scope.models.partnerships, $scope.models.form);

					Partnerships.markForReload();

					$scope.methods.postSaveActions();

					InfoService.endRequest();
					toaster.successMessage('MESSAGE.SETTINGS_SAVE_SUCCESS');
				})
				.catch(function error() {
					InfoService.endRequest();
					toaster.errorMessage('MESSAGE.SETTINGS_SAVE_ERROR');
				});
		}
	};

	$scope.methods.initializeModels();

	removeDestroyListener = $scope.$on('$destroy', function $destroyListener() {
		ListFilterSettings.set(listFilterId, $scope.models.partnershipFilters.getActiveFilterData());

		removeDestroyListener();
	});
}

export default PricingPartnershipsController;
