function StringTools() {
	/**
	 * Converts camelCase string into snake_case string.
	 * @param {String} camelCase
	 * @returns {String|*}
	 */
	function snakeCase(camelCase) {
		return angular.isString(camelCase)
			? camelCase.replace(/([A-Z])/g, function replace($1) {
					return '_' + $1.toLowerCase();
				})
			: camelCase;
	}

	/**
	 * Transforms beginning of a string to upper case.
	 * @param {String} string
	 * @returns {String|*}
	 */
	function ucFirst(string) {
		return angular.isString(string) ? string.charAt(0).toUpperCase() + string.slice(1) : string;
	}

	/**
	 * Removes possibly contained protocol from the beginning of a string.
	 * @param stringWithProtocol
	 * @returns {String|*}
	 */
	function stripProtocol(stringWithProtocol) {
		return angular.isString(stringWithProtocol) ? stringWithProtocol.replace(/^(https?)?:?\/{0,2}/, '') : stringWithProtocol;
	}

	return {
		snakeCase: snakeCase,
		ucFirst: ucFirst,
		stripProtocol: stripProtocol,
	};
}

export default StringTools;
