import { endOfDay, format, isAfter, isBefore, isSameDay, isSameMinute, isValid, setMilliseconds, setSeconds, startOfDay } from 'date-fns';
import de from 'date-fns/locale/de';
import enGB from 'date-fns/locale/en-GB';
import isDate from 'lodash/isDate.js';
import isEmpty from 'lodash/isEmpty.js';
import isEqual from 'lodash/isEqual.js';
import { DateTime } from 'luxon';

const locales = { en: enGB, de };

function PartnershipDetailsController(
	$q,
	$rootScope,
	$translate,
	Account,
	AdslotTranslations,
	AuthService,
	Partnerships,
	PartnershipDirectConnectOptions,
	toaster,
	DemandPartners,
	InfoService,
	resolvedPartnership,
	resolvedDirectConnectOptions,
	changed,
) {
	'ngInject';

	const NOW = -1;
	const TWENTY_FOUR_HOURS = Array.from(Array(24), (unused, index) => index);
	const NOW_AND_TWENTY_FOUR_HOURS = [NOW].concat(TWENTY_FOUR_HOURS);

	const hourFormatHelperDate = startOfDay(new Date());

	const vm = this;

	function updatePartnershipDetails() {
		const requestParams = changed(vm.initialPartnership, vm.getValidRequestParams());

		if (!isEmpty(requestParams)) {
			InfoService.startRequest();

			const { dealDates } = { ...requestParams }; // make a copy here as we are going to modify the deal dates

			// convert deal dates back to string
			if (dealDates && !isEmpty(dealDates)) {
				if (isDate(dealDates.dealStart)) {
					dealDates.dealStart = format(dealDates.dealStart, "yyyy-MM-dd'T'HH:mmxx");
				}
				if (isDate(dealDates.dealEnd)) {
					dealDates.dealEnd = format(dealDates.dealEnd, "yyyy-MM-dd'T'HH:mmxx");
				}
			}

			return Partnerships.update(resolvedPartnership.getId(), {
				...requestParams,
				dealDates,
			}).then((partnership) => {
				vm.partnership = angular.copy(partnership);
				vm.initialPartnership = angular.copy(vm.partnership);
				$rootScope.$emit(Partnerships.EVENTS.PARTNERSHIP_UPDATED, partnership);

				// set bias type percentage as default in the ui
				if (Partnerships.BIAS_TYPE.NONE === vm.partnership.biasType) {
					vm.partnership.biasType = Partnerships.BIAS_TYPE.PERCENTAGE;
				}

				vm.duration.edit = false;
			});
		}
		return $q.when(true);
	}

	function updateDirectConnectOptions() {
		// reset to zero if checkbox unchecked
		if (!isEmpty(vm.directConnect) && !vm.hasMargin) {
			vm.directConnect.margin = 0;
		}

		if (!isEqual(vm.directConnect, vm.initialDirectConnect)) {
			InfoService.startRequest();

			return PartnershipDirectConnectOptions.update(resolvedPartnership.getId(), angular.copy(vm.directConnect)).then(() => {
				vm.hasMargin = !!vm.directConnect.margin;
				vm.initialDirectConnect = angular.copy(vm.directConnect);
			});
		}

		return $q.when(true);
	}

	/**
	 * Minimum end date is the deal start date if it is valid and not in the past or the current date as fallback.
	 * @param {Date} dealStart
	 * @returns {Date}
	 */
	function getMinimumEndDate(dealStart) {
		const today = new Date();
		return isValid(dealStart) && dealStart >= today ? startOfDay(dealStart) : startOfDay(today);
	}

	function initDurationConfig(dealDates) {
		const { dealStart, dealEnd } = dealDates;
		const today = startOfDay(new Date());

		return {
			edit: false,

			isContinuous: !dealEnd,

			startDateAdded: false,

			startMinDate: today,
			startHour: !isValid(dealStart) || dealStart.getMinutes() ? NOW : dealStart.getHours(),
			startHours: [...(isValid(dealStart) && isAfter(dealStart, endOfDay(today)) ? TWENTY_FOUR_HOURS : NOW_AND_TWENTY_FOUR_HOURS)],

			isStartTimeEnabled: isValid(dealStart) && (isSameDay(dealStart, today) || isAfter(dealStart, today)),

			endMinDate: getMinimumEndDate(dealStart),
			endHour: !isValid(dealEnd) ? 0 : dealEnd.getHours(),
			endHours: [...TWENTY_FOUR_HOURS],
		};
	}

	vm.partnership = resolvedPartnership;
	vm.initialPartnership = angular.copy(vm.partnership);

	vm.directConnect = resolvedDirectConnectOptions;
	vm.initialDirectConnect = angular.copy(vm.directConnect);

	vm.isActiveModel = resolvedPartnership.isEnabled();
	vm.hasBidBias = resolvedPartnership.biasType !== Partnerships.BIAS_TYPE.NONE;
	vm.hasMargin = !!resolvedDirectConnectOptions.margin;
	vm.showMargin = !isEmpty(resolvedDirectConnectOptions);

	vm.auctionTypes = AdslotTranslations.getAuctionTypes();
	vm.auctionTypeDisabled = !canUpdatePartnershipAuctionType();

	function canUpdatePartnershipAuctionType() {
		let isAllowedToUpdateAuctionType;
		const demandPartner = DemandPartners.getById(vm.partnership.getDemandId());
		const isDcDemandPartner = demandPartner && demandPartner.isDC();
		if (isDcDemandPartner) {
			const isAdmin = AuthService.isAdmin() || AuthService.isYlAdmin();
			isAllowedToUpdateAuctionType = (demandPartner.isInheritParentAuction() && isAdmin) || AuthService.isYlAdmin();
		} else {
			isAllowedToUpdateAuctionType = Account.isAllowedToUpdateAuctionType();
		}
		return isAllowedToUpdateAuctionType;
	}

	// set bias type percentage as default in the ui
	if (Partnerships.BIAS_TYPE.NONE === vm.partnership.biasType) {
		vm.partnership.biasType = Partnerships.BIAS_TYPE.PERCENTAGE;
	}

	vm.priorities = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
	vm.biasTypesById = Partnerships.BIAS_TYPES;
	vm.biasTypes = Partnerships.BIAS_TYPES.map((biasTypeName, index) => ({
		id: index,
		name: biasTypeName,
	})).slice(1);

	vm.toggleInProgress = false;

	vm.isDeal = DemandPartners.getById(resolvedPartnership.getDemandId()).hasDeals() && !!resolvedPartnership.dealId.length;
	vm.hasPriority = !resolvedPartnership.isPreferredDeal();

	vm.duration = initDurationConfig(vm.partnership.dealDates);

	vm.timezone = () => '(' + DateTime.local().setLocale($translate.use()).toFormat('ZZZZ / ZZZZZ') + ')';

	vm.isFormValid = () => {
		return (
			!(vm.partnership.floorPrice && vm.partnership.fixedPrice) &&
			// no duration configuration (partnerships without deals)
			(isEmpty(vm.partnership.dealDates) ||
				// no start date set
				!vm.partnership.dealDates.dealStart ||
				// no end date set
				!vm.partnership.dealDates.dealEnd ||
				// when both dates set, start has to be before end
				vm.partnership.dealDates.dealStart < vm.partnership.dealDates.dealEnd)
		);
	};

	vm.showStartDateInput = () => isValid(vm.initialPartnership.dealDates.dealStart) || vm.duration.startDateAdded;

	vm.addStartDate = () => {
		vm.duration.startDateAdded = true;
		vm.partnership.dealDates.dealStart = setMilliseconds(setSeconds(new Date(), 0), 0);
	};

	vm.formatHour = (hourValue) =>
		hourValue === NOW
			? $translate.instant('CAPTION.NOW')
			: format(hourFormatHelperDate.setHours(hourValue), 'p', {
					locale: locales[$translate.use()],
				});

	/**
	 * Only applies if deal start date set to today.
	 * @param {Number} selectOptionHour
	 * @returns {boolean}
	 */
	vm.startHourHasPassed = (selectOptionHour) => {
		const { dealStart } = vm.partnership.dealDates;
		const today = new Date();

		return (
			isSameDay(dealStart, today) &&
			selectOptionHour !== NOW &&
			// disable all passed hours; current hour only if not in the first minute of this hour
			(selectOptionHour < today.getHours() || (selectOptionHour === today.getHours() && today.getMinutes() > 0))
		);
	};

	vm.onStartDateChange = () => {
		vm.onStartTimeChange();

		const { dealStart, dealEnd } = vm.partnership.dealDates;

		if (isValid(dealStart)) {
			// update global minimum end date
			vm.duration.endMinDate = getMinimumEndDate(dealStart);

			// keep end date in sync if necessary
			if (!vm.duration.isContinuous && isBefore(dealEnd, vm.duration.endMinDate)) {
				dealEnd.setFullYear(vm.duration.endMinDate.getFullYear(), vm.duration.endMinDate.getMonth(), vm.duration.endMinDate.getDate());

				vm.onEndDateChange();
			}

			vm.duration.startHours = [...(isAfter(dealStart, endOfDay(new Date())) ? TWENTY_FOUR_HOURS : NOW_AND_TWENTY_FOUR_HOURS)];
			vm.duration.isStartTimeEnabled = isSameDay(dealStart, vm.duration.startMinDate) || isAfter(dealStart, vm.duration.startMinDate);
		}
	};

	vm.onStartTimeChange = () => {
		const { dealStart } = vm.partnership.dealDates;

		if (isValid(dealStart)) {
			const today = new Date();

			if (vm.duration.startHour === NOW) {
				dealStart.setHours(today.getHours());
				dealStart.setMinutes(today.getMinutes());

				// if time was "now" before and date is not today anymore set time back to zero
				if (!isSameDay(dealStart, today)) {
					vm.duration.startHour = 0;
					dealStart.setHours(vm.duration.startHour);
					dealStart.setMinutes(0);
				}
			} else {
				dealStart.setHours(vm.duration.startHour);
				dealStart.setMinutes(0);
			}

			// when coming back to today and the time was set to an already passed hour automatically select "now"
			if (isSameDay(dealStart, today) && (vm.duration.startHour < today.getHours() || (vm.duration.startHour === today.getHours() && today.getMinutes() > 0))) {
				vm.duration.startHour = NOW;
				dealStart.setHours(today.getHours());
				dealStart.setMinutes(today.getMinutes());
			}
		}
	};

	/**
	 * @param {Number} selectOptionHour
	 * @returns {boolean}
	 */
	vm.endHourHasPassed = (selectOptionHour) => {
		const { dealStart, dealEnd } = vm.partnership.dealDates;
		// disable all passed hours including the same hour
		return isValid(dealStart) && isValid(dealEnd) && isSameDay(dealStart, dealEnd) && selectOptionHour < dealEnd.getHours();
	};

	vm.onEndDateChange = () => {
		const { dealStart, dealEnd } = vm.partnership.dealDates;
		if (isValid(dealEnd)) {
			dealEnd.setHours(vm.duration.endHour);

			if (isValid(dealStart) && isSameDay(dealStart, dealEnd) && vm.duration.endHour <= dealStart.getHours()) {
				vm.duration.endHour = dealStart.getHours() + 1;
				dealEnd.setHours(vm.duration.endHour);
			}

			vm.partnershipForm.dealEnd.$validate();
		}
	};

	vm.onEndTimeChange = () => vm.onEndDateChange();

	vm.toggleBidBias = () => {
		// when switching from 'none' to active select 'percentage' by default
		if (Partnerships.BIAS_TYPE.NONE === vm.partnership.biasType) {
			vm.partnership.biasType = Partnerships.BIAS_TYPE.PERCENTAGE;
		}
	};

	vm.selectBiasType = (biasTypeId) => {
		// reset value on type change
		if (biasTypeId !== vm.partnership.biasType) {
			vm.partnership.bias = 0;
		}
		vm.partnership.biasType = biasTypeId;
	};

	vm.isDealActive = () => {
		const dealDates = vm.initialPartnership.dealDates;
		const isSameOrAfter = (date, dateToCompare) => isSameMinute(date, dateToCompare) || isAfter(date, dateToCompare);
		const isBeforeAndNotSameMinute = (date, dateToCompare) => isBefore(date, dateToCompare) && !isSameMinute(date, dateToCompare);
		const now = new Date();
		if (
			angular.equals(dealDates, {}) ||
			((!dealDates.dealStart || isSameOrAfter(now, dealDates.dealStart)) && (!dealDates.dealEnd || isBeforeAndNotSameMinute(now, dealDates.dealEnd)))
		) {
			return true;
		}
		return false;
	};

	vm.getValidRequestParams = () => {
		const requestParams = {
			name: vm.partnership.getName(),
			priority: vm.partnership.getPriority(),
			floorPrice: vm.partnership.getFloorPrice(),
			fixedPrice: vm.partnership.getFixedPrice(),
			auctionType: vm.partnership.auctionType,
			biasType: vm.partnership.biasType,
			bias: vm.partnership.bias,
		};

		if (vm.isDeal) {
			requestParams.dealId = vm.partnership.getDealId();

			requestParams.dealDates = angular.copy(vm.partnership.dealDates);

			// remove start date if
			if (
				// nothing has changed
				isEqual(requestParams.dealDates.dealStart, vm.initialPartnership.dealDates.dealStart) ||
				// or start date was 'now' and was modified to another 'now value' so we assume it shouldn't be updated
				(isDate(vm.initialPartnership.dealDates.dealStart) &&
					vm.initialPartnership.dealDates.dealStart.getMinutes() !== 0 &&
					requestParams.dealDates.dealStart.getMinutes() !== 0)
			) {
				delete requestParams.dealDates.dealStart;
			}

			// remove end date if
			if (
				// continuous (no end is defined) or
				vm.duration.isContinuous ||
				// no start date is present (otherwise the end date is always needed)
				(!requestParams.dealDates.dealStart &&
					// and nothing has changed
					isEqual(requestParams.dealDates.dealEnd, vm.initialPartnership.dealDates.dealEnd))
			) {
				delete requestParams.dealDates.dealEnd;
			}

			// Explicitly send null (to remove the end date on the backend side) when switching to continuous
			// and the start date was not changed. Otherwise dealEnd is not sent an will be removed automatically.
			if (vm.duration.isContinuous && isDate(vm.initialPartnership.dealDates.dealEnd) && !requestParams.dealDates.dealStart) {
				requestParams.dealDates.dealEnd = null;
			}

			if (isEmpty(requestParams.dealDates)) {
				delete requestParams.dealDates;
			}
		}

		if (!vm.hasBidBias) {
			requestParams.biasType = Partnerships.BIAS_TYPE.NONE;
		}

		return requestParams;
	};

	vm.save = () => {
		updatePartnershipDetails()
			.then(updateDirectConnectOptions)
			.then(() => {
				if (InfoService.isRequestInProgress()) {
					toaster.successMessage('MESSAGE.PARTNERSHIP_SAVE_SUCCESS');
				}
			})
			.catch((errorObject) => {
				if (errorObject.code === 30011) {
					// should only happen when trying to update the settings
					if (vm.duration.edit && vm.partnership.dealDates) {
						const now = Date.now();
						if (
							// tried to update the end date
							!isEqual(vm.partnership.dealDates.dealEnd, vm.initialPartnership.dealDates.dealEnd) &&
							// set to something in the past
							isDate(vm.partnership.dealDates.dealEnd) &&
							vm.partnership.dealDates.dealEnd < now
						) {
							toaster.errorMessage('MESSAGE.PARTNERSHIP_SAVE_ERROR_DEAL_END_IN_THE_PAST');

							if (vm.partnershipForm.dealEnd) {
								vm.partnershipForm.dealEnd.$setValidity('required', false);
							}
						}

						if (
							// tried to update the start date
							!isEqual(vm.partnership.dealDates.dealStart, vm.initialPartnership.dealDates.dealStart) &&
							// set to something in the past
							isDate(vm.partnership.dealDates.dealStart) &&
							vm.partnership.dealDates.dealStart < now
						) {
							toaster.errorMessage('MESSAGE.PARTNERSHIP_SAVE_ERROR_DEAL_START_IN_THE_PAST');

							if (vm.partnershipForm.dealStart) {
								vm.partnershipForm.dealStart.$setValidity('required', false);
							}
						}
					}
				} else if (errorObject.code === 30012) {
					vm.partnershipForm.dealId.$setValidity('pattern', false);
					toaster.errorMessage('MESSAGE.PARTNERSHIP_SAVE_ERROR_DUPLICATE_DEAL_ID');
				} else if (errorObject.code === 10008) {
					vm.partnershipForm.name.$setValidity('required', false);
					toaster.errorMessage('MESSAGE.PARTNERSHIP_SAVE_ERROR_NAME_TOO_LONG');
				} else {
					toaster.errorMessage('MESSAGE.PARTNERSHIP_SAVE_ERROR');
				}
			})
			.finally(() => {
				InfoService.endRequest();
			});
	};

	vm.toggle = () => {
		InfoService.startRequest();
		vm.toggleInProgress = true;

		Partnerships.toggle(vm.partnership)
			.then((partnership) => {
				vm.partnership.state = partnership.state;
				vm.isActiveModel = partnership.state.isEnabled();
				$rootScope.$emit(Partnerships.EVENTS.PARTNERSHIP_UPDATED);

				toaster.successMessage('MESSAGE.PARTNERSHIP_SAVE_SUCCESS');
			})
			.catch((errorObject) => {
				vm.isActiveModel = vm.partnership.state.isEnabled();
				switch (errorObject.code) {
					case 40010:
						{
							const { attributes } = errorObject;

							const adslotId = Object.keys(attributes)[0];
							const partnershipId = attributes[adslotId].filter((pid) => pid !== vm.partnership.id)[0];

							toaster.errorMessage(
								$translate.instant('MESSAGE.PARTNERSHIP_ACTIVATE_ERROR_DUPLICATE_PRIVATE_AUCTION', {
									adslotId,
									partnershipId,
								}),
							);
						}
						break;
					case Partnerships.ERROR.ACTIVE_SIZES_OVERLAP_EXIST:
						toaster.errorMessage('MESSAGE.PARTNERSHIP_ACTIVATE_ERROR_ALLOWLIST_SIZES');
						break;
					case Partnerships.ERROR.TARGETING_CONFIGURATION_INVALID:
						toaster.errorMessage('MESSAGE.PARTNERSHIP_SAVE_ERROR_TARGETING_CONFIGURATION_INVALID');
						break;
					default:
						toaster.errorMessage('MESSAGE.PARTNERSHIP_SAVE_ERROR');
				}
			})
			.finally(() => {
				InfoService.endRequest();
				vm.toggleInProgress = false;
			});
	};
}

export default PartnershipDetailsController;
