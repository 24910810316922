/**
 * add some convenience to the location service
 */
function Location($delegate) {
	'ngInject';

	/**
	 * check if current location is the one you are looking for.
	 * @param {string} location
	 * @return {boolean}
	 */
	$delegate.isCurrent = function isCurrent(location) {
		var pathParts = $delegate.path().split('/');
		return pathParts.length > 0 && pathParts[1] === location;
	};

	return $delegate;
}

export default Location;
