import Partnership from '../../models/Partnership.js';

function PricingAdslotPartnershipsController(
	$scope,
	$filter,
	AdslotPartnerships,
	DemandPartners,
	ListFilterFactory,
	ListFilterSettings,
	ListSearchService,
	Partnerships,
	InfoService,
	toaster,
	resolvedPartnerships,
	currentAdslot,
) {
	'ngInject';

	$scope.models.adslotPartnerships = resolvedPartnerships;

	const listFilterId = `pricing-adslot-partnerships-filter-${currentAdslot.id}`;

	$scope.models.demandFilterOptions = Partnerships.getDemandPartnersList().filter(({ id }) => DemandPartners.isVisible(id));

	$scope.models.adslotPartnershipsFilter = ListFilterFactory.get(ListFilterFactory.LAYOUTS.PARTNERSHIPS.PRICING).load(
		ListFilterSettings.get(listFilterId) || ListFilterFactory.DEFAULT_SETTINGS.PARTNERSHIPS.PRICING,
	);

	// update potentially changed entry data
	$scope.models.adslotPartnershipsFilter.getGroup('LABEL.DEMAND_PARTNER').getEntry('demandPartnerId').setEntryData($scope.models.demandFilterOptions);

	$scope.models.matchesFilter = $scope.models.adslotPartnershipsFilter.matchesFilter.bind($scope.models.adslotPartnershipsFilter);

	$scope.models.adslotPartnershipsSearch = ListSearchService.get('pricing-adslot-partnerships-search-' + currentAdslot.id);
	$scope.models.subListSortKey = 'name';
	$scope.currentAdslot = currentAdslot;

	$scope.models.isMultisize = currentAdslot.isMultisize();

	$scope.models.form = $scope.methods.initializeForm($scope.models.adslotPartnerships, AdslotPartnerships.getPriceMap(resolvedPartnerships));
	$scope.models.invalidPrices = {};

	$scope.methods.addPricesToList($scope.models.adslotPartnerships, $scope.models.form);

	$scope.save = function save() {
		var prices = $scope.methods.getChangedPrices();

		if (prices.length) {
			InfoService.startRequest();

			AdslotPartnerships.updatePrices(currentAdslot.id, prices)
				.then((updatedPartnerships) => {
					$scope.models.adslotPartnerships = updatedPartnerships
						.map(({ id, floorPrice, fixedPrice }) => Partnership.clone(Partnerships.getById(id)).setFloorPrice(floorPrice).setFixedPrice(fixedPrice))
						.filter((partnership) => {
							const demandPartner = DemandPartners.getById(partnership.getDemandId());
							return demandPartner.isRtb() || demandPartner.isDC();
						});

					// set current form as initial state
					// extend initially loaded prices with the currently updated ones to have all prices correct
					$scope.models.form = $scope.methods.initializeForm(
						$scope.models.adslotPartnerships,
						prices.reduce(function reduce(priceMap, priceObject) {
							priceMap[priceObject.id] = {
								floorPrice: priceObject.floorPrice,
								fixedPrice: priceObject.fixedPrice,
							};
							return priceMap;
						}, AdslotPartnerships.getPriceMap($scope.models.adslotPartnerships)),
					);
					$scope.methods.addPricesToList($scope.models.adslotPartnerships, $scope.models.form);

					$scope.methods.postSaveActions();

					InfoService.endRequest();
					toaster.successMessage('MESSAGE.SETTINGS_SAVE_SUCCESS');
				})
				.catch(function error() {
					InfoService.endRequest();
					toaster.errorMessage('MESSAGE.SETTINGS_SAVE_ERROR');
				});
		}
	};

	$scope.methods.initializeModels();

	const removeDestroyListener = $scope.$on('$destroy', function $destroyListener() {
		ListFilterSettings.set(listFilterId, $scope.models.adslotPartnershipsFilter.getActiveFilterData());

		removeDestroyListener();
	});
}

export default PricingAdslotPartnershipsController;
