import isEmpty from 'lodash/isEmpty.js';

const toNumber = (numberValue) => +numberValue;

class SelectionService {
	/**
	 * @param elements
	 */
	constructor(elements = {}) {
		this.elements = { ...elements };
		this.count = Object.keys(this.elements).length;
	}

	values() {
		return Object.keys(this.elements).map(toNumber);
	}

	isEmpty() {
		return isEmpty(this.elements);
	}

	toggleOne = (elementId) => {
		if (this.elements[elementId]) {
			this.count += 1;
		} else {
			delete this.elements[elementId];
			this.count = Math.max(this.count - 1, 0);
		}
	};

	check = (filteredList) => {
		filteredList.forEach((element) => {
			if (!this.elements[element.id]) {
				this.elements[element.id] = true;
				this.count += 1;
			}
		});
	};

	clear = () => {
		Object.keys(this.elements).forEach((elementId) => {
			delete this.elements[elementId];
		});
		this.count = 0;
	};
}

export default SelectionService;
