function StateResolveLoadingIndicator() {
	return {
		restrict: 'A',
		scope: {},
		template: [
			/* eslint-disable */
			'<div data-ng-if="$ctrl.show" class="yl-animate fade-in-out state-resolve-loading-indicator-container">',
			'<span class="state-resolve-loading-dots" data-translate="TEXT.LOADING"></span>',
			'</div>',
			/* eslint-enable */
		].join(''),
		controller($rootScope, $scope, $timeout) {
			'ngInject';

			const vm = this;

			vm.show = false;

			let stateResolveLoadingTimer = null;

			const unregisterIsLoadingListener = $rootScope.$on('state-resolve-is-loading', (event, { isLoading }) => {
				if (isLoading) {
					if (stateResolveLoadingTimer === null) {
						stateResolveLoadingTimer = $timeout(() => {
							vm.show = true;
						}, 1500);
					}
				} else {
					if (stateResolveLoadingTimer != null) {
						$timeout.cancel(stateResolveLoadingTimer);
						stateResolveLoadingTimer = null;
					}
					vm.show = false;
				}
			});

			// do not forget to clean up as we are listening on rootScope
			$scope.$on('$destroy', () => unregisterIsLoadingListener);
		},
		controllerAs: '$ctrl',
	};
}

export default StateResolveLoadingIndicator;
