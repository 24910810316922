import pagerControlsHtml from '../../views/directives/pager-controls.html';

const pagerControls = {
	bindings: {
		pagerId: '@',
		pagerReset: '<',
	},
	template: pagerControlsHtml,
	controller: 'PagerControlsController',
};

export default pagerControls;
