class User {
	/**
	 * @type {number}
	 */
	id;

	/**
	 * @type {string}
	 */
	name;

	/**
	 * @type {Set.<string>}
	 */
	roles;

	/**
	 * @type {boolean}
	 */
	supplyPartner;

	/**
	 * @type {boolean}
	 */
	demandPartner;

	/**
	 * @type {boolean}
	 */
	allChannel;

	/**
	 * @param {number} id
	 * @param {string} user
	 * @param {string[]} roles
	 * @param {boolean} supplyPartner
	 * @param {boolean} demandPartner
	 * @param {boolean} allChannel
	 */
	constructor({ id = 0, user = '', roles = [], supplyPartner = false, demandPartner = false, allChannel = false } = {}) {
		this.id = id;
		this.name = user;
		this.roles = new Set(roles);
		this.supplyPartner = supplyPartner;
		this.demandPartner = demandPartner;
		this.allChannel = allChannel;
	}

	isSupplyPartner() {
		return this.supplyPartner === true;
	}
}

export default User;
