import DemandTechnology from '../models/DemandTechnology.js';

function DemandTechnologies(Api) {
	'ngInject';

	/**
	 * @type {Map<String, DemandTechnology>}
	 */
	this.demandTechnologies = new Map();

	this.loadAll = () => {
		return Api.get('/api/v1/accounts/technology').then((result) => {
			const technologies = result.technologies || [];
			technologies.forEach((data) => {
				this.demandTechnologies.set(data.id, new DemandTechnology(data));
			});
		});
	};

	/**
	 * @param {String} id
	 * @returns {DemandTechnology}
	 */
	this.getById = (id) => {
		return this.demandTechnologies.get(id);
	};
}

export default DemandTechnologies;
