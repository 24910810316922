import SelectionService from '../../../modules/utils/SelectionService.js';

function AdslotMoveController($rootScope, $scope, close, groupId, AdslotGroups, Adslots, InfoService, ModalCloser, Sites, toaster) {
	'ngInject';

	var unbindAuthListener,
		unbindDestroyListener,
		vm = this;

	vm.listFilter = {};
	vm.adslots = Adslots.getList();

	vm.selection = new SelectionService();

	vm.selectGroup = function selectGroup(newGroupId) {
		var groupAdslots = AdslotGroups.getById(newGroupId).adslotIds.reduce(function adslotIdMap(map, adslotId) {
			map[adslotId] = true;
			return map;
		}, {});

		vm.adslots = vm.adslots.filter(function excludeAdslotsAlreadyInGroup(adslot) {
			return !(adslot.id in groupAdslots);
		});
	};

	vm.save = function save() {
		if (!vm.selection.isEmpty()) {
			InfoService.startRequest();

			Adslots.move(vm.selection.values(), groupId)
				.then(function adslotsMoved() {
					$rootScope.$emit(Adslots.EVENT.UPDATED);
					return AdslotGroups.loadAll();
				})
				.then(function adslotGroupsRefreshed() {
					$rootScope.$emit(AdslotGroups.EVENT.UPDATED);
					toaster.successMessage('MESSAGE.ADSLOT_MOVE_SUCCESS');
					vm.close();
				})
				.finally(InfoService.endRequest.bind(InfoService));
		}
	};

	vm.close = function closeFn() {
		ModalCloser.close(close, undefined, 200);
	};

	// init

	if (groupId) {
		vm.fixedGroupName = Sites.getById(AdslotGroups.getById(groupId).siteId).name + ' - ' + AdslotGroups.getById(groupId).name;
		vm.selectGroup(groupId);
	}

	unbindAuthListener = $rootScope.$on('event:auth-loginRequired', vm.close);
	unbindDestroyListener = $scope.$on('$destroy', function destroyListener() {
		unbindAuthListener();
		unbindDestroyListener();
	});
}

export default AdslotMoveController;
