class NativeAsset {
	static TYPE = {
		IMAGE_ICON: 'image_icon',
		IMAGE_MAIN: 'image_main',
		TITLE: 'title',
		DATA_DESCRIPTION: 'data_description',
	};

	/**
	 * @type {int}
	 */
	id = 0;

	/**
	 * @type {boolean}
	 */
	required = false;

	/**
	 * Internally used field to conveniently handle asset types in the UI.
	 * Should be set by the concrete asset.
	 * @type {string|undefined}
	 */
	uiType = undefined;

	/**
	 * @param {Object} [data]
	 */
	constructor({ id = 0, required = 0 } = {}) {
		this.id = id;
		this.required = !!required; // convert from API data object number value to boolean
	}

	/**
	 * @param {int} id
	 * @returns {NativeAsset}
	 */
	setId(id) {
		this.id = id;
		return this;
	}

	/**
	 * @returns {boolean}
	 */
	isImage() {
		// eslint-disable-line class-methods-use-this
		return false;
	}

	/**
	 * @returns {boolean}
	 */
	isData() {
		// eslint-disable-line class-methods-use-this
		return false;
	}

	/**
	 * @returns {boolean}
	 */
	isTitle() {
		// eslint-disable-line class-methods-use-this
		return false;
	}

	/**
	 * @returns {object}
	 */
	toObject() {
		return {
			id: this.id,
			required: this.required ? 1 : 0,
		};
	}
}

export const assetTypeSelectOptions = [
	{
		id: NativeAsset.TYPE.IMAGE_ICON,
		text: 'CAPTION.NATIVE_TEMPLATE.ASSET.IMAGE_ICON',
	},
	{
		id: NativeAsset.TYPE.IMAGE_MAIN,
		text: 'CAPTION.NATIVE_TEMPLATE.ASSET.IMAGE_MAIN',
	},
	{
		id: NativeAsset.TYPE.TITLE,
		text: 'CAPTION.NATIVE_TEMPLATE.ASSET.TITLE',
	},
	{
		id: NativeAsset.TYPE.DATA_DESCRIPTION,
		text: 'CAPTION.NATIVE_TEMPLATE.ASSET.DATA_DESCRIPTION',
	},
];

export default NativeAsset;
