function PartnershipTargeting(Api) {
	'ngInject';

	var MATCHING = {
		MODE: {
			AND: 0,
			OR: 1,
			NAND: 2,
			NOR: 3,
		},
		EVALUATOR: {
			EQUALS: 1,
			LESS_THAN: 2,
			GREATER_THAN: 3,
			CONTAINS: 4,
		},
	};

	// list in order to appear in the UI
	MATCHING.OPTIONS = [
		{
			key: 'IS',
			config: {
				mode: MATCHING.MODE.OR,
				evaluator: MATCHING.EVALUATOR.EQUALS,
			},
			numeric: false,
		},
		{
			key: 'IS_NOT',
			config: {
				mode: MATCHING.MODE.NOR,
				evaluator: MATCHING.EVALUATOR.EQUALS,
			},
			numeric: false,
		},
		{
			key: 'CONTAINS',
			config: {
				mode: MATCHING.MODE.OR,
				evaluator: MATCHING.EVALUATOR.CONTAINS,
			},
			numeric: false,
		},
		{
			key: 'NOT_CONTAINS',
			config: {
				mode: MATCHING.MODE.NOR,
				evaluator: MATCHING.EVALUATOR.CONTAINS,
			},
			numeric: false,
		},
		{
			key: 'GREATER_THAN',
			config: {
				mode: MATCHING.MODE.OR,
				evaluator: MATCHING.EVALUATOR.GREATER_THAN,
			},
			numeric: true,
		},
		{
			key: 'LESS_THAN',
			config: {
				mode: MATCHING.MODE.OR,
				evaluator: MATCHING.EVALUATOR.LESS_THAN,
			},
			numeric: true,
		},
	];

	MATCHING.UI_KEYS = MATCHING.OPTIONS.map(function map(option) {
		return option.key;
	});

	MATCHING.optionIndex = function optionIndex(config) {
		var i,
			l = MATCHING.OPTIONS.length;

		for (i = 0; i < l; i++) {
			if (angular.equals(config, MATCHING.OPTIONS[i].config)) {
				return i;
			}
		}

		return 0;
	};

	this.MATCHING = MATCHING;

	this.ERROR = {
		MISSING_DC_DEAL: 30017,
	};

	/**
	 * @param {int} partnershipId
	 * @returns {promise}
	 */
	this.get = function getFn(partnershipId) {
		return Api.get(`/api/supply/v3/partnerships/${partnershipId}/targeting`).then(function success(targetingConfiguration) {
			return targetingConfiguration;
		});
	};

	/**
	 * @param {int} partnershipId
	 * @param {Object} targetingConfiguration
	 * @returns {promise}
	 */
	this.update = (partnershipId, targetingConfiguration) => Api.put(`/api/supply/v3/partnerships/${partnershipId}/targeting`, targetingConfiguration);
}

export default PartnershipTargeting;
