function bsDropdownEvents($document, $parse) {
	'ngInject';

	return function postLink(scope, iElement, iAttrs) {
		// grab registered open/close handlers
		var open = $parse(iAttrs.open)(scope),
			close = $parse(iAttrs.close)(scope),
			// set to local "scope"
			onOpen = angular.isFunction(open) ? open : angular.noop,
			onClose = angular.isFunction(close) ? close : angular.noop;

		function documentClickHandler() {
			if (iElement.data('open')) {
				iElement.data('open', false);
				onClose();
			}
		}

		// initialized dropdown is closed
		iElement.data('open', false);

		// take care of state when clicking on dropdown toggle
		iElement.bind('click', function click() {
			// close listener for toggle button
			if (iElement.data('open')) {
				iElement.data('open', false);
				onClose();
			} else {
				// open listener
				iElement.data('open', true);
				onOpen();
			}
		});

		// register document wide close listener
		$document.on('click', documentClickHandler);

		// remove document wide listener
		scope.$on('$destroy', function $destroy() {
			$document.off('click', documentClickHandler);
		});
	};
}

export default bsDropdownEvents;
