import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

import PricingController from './pricing.controller.js';
import PricingAdslotPartnershipsController from './pricing-adslot-partnerships.controller.js';
import PricingAdslotsController from './pricing-adslots.controller.js';
import PricingAdvertisersController from './pricing-advertisers.controller.js';
import PricingGlobalController from './pricing-global.controller.js';
import PricingPartnershipAdslotsController from './pricing-partnership-adslots.controller.js';
import PricingPartnershipsController from './pricing-partnerships.controller.js';

import PricingConfig from './pricing.config.js';

const PricingModule = angular
	.module('pricing', [uiRouter])
	.controller('AbstractPricingController', PricingController)
	.controller('PricingAdslotPartnershipsController', PricingAdslotPartnershipsController)
	.controller('PricingAdslotsController', PricingAdslotsController)
	.controller('PricingAdvertisersController', PricingAdvertisersController)
	.controller('PricingGlobalController', PricingGlobalController)
	.controller('PricingPartnershipAdslotsController', PricingPartnershipAdslotsController)
	.controller('PricingPartnershipsController', PricingPartnershipsController)
	.config(['$stateProvider', PricingConfig]).name;

export default PricingModule;
