import AdvertiserCategoryFilter from '../../../../models/AdvertiserCategoryFilter.js';

function AdslotCategoryFilterController($scope, Adslots, resolvedCategoryFilter, AuthService, toaster, InfoService, AdvertiserCategories) {
	'ngInject';

	let initialFilter = resolvedCategoryFilter;

	$scope.filter = new AdvertiserCategoryFilter(initialFilter.toObject());

	$scope.assignedData = Array.from(initialFilter.items)
		.map((advertiserCategoryId) => AdvertiserCategories.getById(advertiserCategoryId))
		.filter((entry) => entry !== undefined); // eslint-disable-line angular/definedundefined
	$scope.availableData = Object.values(AdvertiserCategories.getMap());

	$scope.save = function save() {
		const currentFilter = AdvertiserCategoryFilter.fromIds($scope.assignedData.map(({ id }) => id));
		const currentFilterDataObject = currentFilter.toObject();

		if (!initialFilter.equals(currentFilter)) {
			InfoService.startRequest();

			Adslots.update($scope.localModels.adslot.id, {
				filter: currentFilterDataObject,
			})
				.then(() => {
					initialFilter = new AdvertiserCategoryFilter(currentFilterDataObject);

					toaster.successMessage('MESSAGE.ADSLOT_SAVE_SUCCESS');
				})
				.catch(() => toaster.errorMessage('MESSAGE.ADSLOT_SAVE_ERROR'))
				.finally(() => InfoService.endRequest());
		}
	};
}

export default AdslotCategoryFilterController;
