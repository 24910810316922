// $delegate refers to the original directive definition.
function ngFocusDecoration($delegate) {
	'ngInject';

	// When decorating a directive, $delegate is an array with the
	// actual directive at the first index.
	var directive = $delegate[0],
		// original directive uses compile function so we have to save that first
		compileOriginal = directive.compile;

	// add requirement of a model controller but make it optional to not break existing focus directives
	directive.require = '?ngModel';

	// hook into compile
	directive.compile = function compile() {
		// run original compile and save returned link function
		var link = compileOriginal.apply(this, arguments);

		/*
		 * add custom ngFocus behavior to the link function
		 * 	- adds ng-focused class to the corresponding element
		 * 	- sets $focused property of the corresponding controller element
		 */
		return function postLink($scope, iElement, iAttrs, ngModelCtrl) {
			var FOCUS_CLASS = 'ng-focused',
				$setPristineOriginal;

			// run the original link function
			link.apply(this, arguments);

			if (ngModelCtrl) {
				ngModelCtrl.$focused = false;

				$setPristineOriginal = ngModelCtrl.$setPristine;
				ngModelCtrl.$setPristine = function $setPristine() {
					// call original method
					$setPristineOriginal.apply(this);

					// and extend behavior
					ngModelCtrl.$focused = false;
					iElement.removeClass(FOCUS_CLASS);
				};

				ngModelCtrl.setUnfocused = function setUnfocused() {
					ngModelCtrl.$focused = false;
					iElement.removeClass(FOCUS_CLASS);
					return true;
				};

				ngModelCtrl.setFocused = function setFocused() {
					ngModelCtrl.$focused = true;
					iElement.addClass(FOCUS_CLASS);
					return true;
				};
			}

			iElement
				.bind('blur', function blur() {
					iElement.removeClass(FOCUS_CLASS);

					if (ngModelCtrl) {
						$scope.$apply(function apply() {
							ngModelCtrl.$focused = false;
						});
					}
				})
				.bind('focus', function focus() {
					iElement.addClass(FOCUS_CLASS);

					if (ngModelCtrl) {
						$scope.$apply(function apply() {
							ngModelCtrl.$focused = true;
						});
					}
				});
		};
	};

	// Return the decorated original ($delegate).
	return $delegate;
}

export default ngFocusDecoration;
