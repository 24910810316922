class SeparationByHourUtils {
	static getDifferenceInDays(endDate, startDate) {
		const timeDifference = endDate.getTime() - startDate.getTime();

		return Math.round(timeDifference / (1000 * 3600 * 24));
	}

	static getFormattedHourOption(hour) {
		return hour > 9 ? '' + hour : '0' + hour;
	}
}

export default SeparationByHourUtils;
