import ReportColumns from '../../../models/ReportColumns.js';
import ReportColumnUiGroup from '../../../models/ReportColumnUiGroup.js';

function ReportSettingsController($scope, Reports, ModalFactory, AdslotGroups, Sites, toaster) {
	'ngInject';

	const vm = this;
	vm.UI_GROUP = ReportColumnUiGroup;
	vm.template = $scope.template;
	vm.reportValidity = $scope.reportValidity;
	vm.COLUMN_HINTS = {
		[ReportColumns.ID.YIELDPROBE_REQUESTS]: 'TITLE.REPORTS.COLUMN_RESTRICTION.YIELDPROBE_REQUESTS',
	};

	$scope.$watch(
		() => $scope.template,
		(newTemplate) => {
			vm.template = newTemplate;
		},
		true,
	);

	vm.isAnyKeyFigureRestricted = () =>
		vm.template.isAnalyticsReport() &&
		vm.template.keyFigureColumns // eslint-disable-line angular/definedundefined
			.filter((keyFigure) => keyFigure.visible)
			.map((keyFigure) => keyFigure.id)
			.find(vm.isKeyFigureRestricted) !== undefined;

	vm.addKeyFigure = () => {
		vm.reportValidity.keyFiguresValid = true;
	};

	vm.addDimension = () => {
		vm.reportValidity.dimensionsValid = true;
	};

	vm.isRequiredByFilter = (column) => {
		if (
			column.filter.model.length > 0 &&
			(column.id === ReportColumns.ID.ADSLOT_ID ||
				column.id === ReportColumns.ID.ADVERTISER_ID ||
				column.id === ReportColumns.ID.DEMAND_PARTNER_ID ||
				column.id === ReportColumns.ID.PARTNERSHIP_ID)
		) {
			return true;
		}
		return false;
	};

	vm.removeDimension = (column) => {
		if (
			vm.isRequiredByFilter(column) ||
			(column.filter.model.length > 0 &&
				(column.id === ReportColumns.ID.ADSLOT ||
					column.id === ReportColumns.ID.DEMAND_PARTNER ||
					column.id === ReportColumns.ID.PARTNERSHIP ||
					(vm.template.isRevenueReport() && column.id === ReportColumns.ID.ADVERTISER)))
		) {
			column.toggle(true);
		} else {
			column.toggle();
		}
		if (vm.template.dimensionColumns.filter((dimension) => dimension.visible).length === 0) {
			vm.reportValidity.dimensionsValid = false;
		}
	};

	vm.removeKeyFigure = (column) => {
		column.toggle();
		if (vm.template.keyFigureColumns.filter((keyFigure) => keyFigure.visible).length === 0) {
			vm.reportValidity.keyFiguresValid = false;
		}
	};

	vm.isKeyFigureRestricted = (columnId) => {
		return Reports.isKeyFigureRestricted(vm.template, columnId);
	};

	vm.allDimensionsSelected = () => {
		if (vm.template.isRevenueReport()) {
			// variables are set implicitly by view
			return (
				!vm.availableFilteredPartnershipColumns.length &&
				!vm.availableFilteredAdslotColumns.length &&
				!vm.availableFilteredYdcColumns.length &&
				!vm.availableFilteredBillingColumns.length
			);
		}
		if (vm.template.isAnalyticsReport()) {
			// variables are set implicitly by view
			return !vm.availableFilteredPartnershipColumns.length && !vm.availableFilteredAdslotColumns.length && !vm.availableFilteredYdcColumns.length;
		}
		return false;
	};

	vm.allKeyFiguresSelected = () => {
		if (vm.template.isRevenueReport()) {
			// variables are set implicitly by view
			return !vm.availableFilteredGeneralColumns.length && !vm.availableFilteredVideoColumns.length;
		}
		if (vm.template.isAnalyticsReport()) {
			// variables are set implicitly by view
			return (
				!vm.availableFilteredGeneralColumns.length &&
				!vm.availableFilteredInvalidBidColumns.length &&
				!vm.availableFilteredBidPriceColumns.length &&
				!vm.availableFilteredAdServingColumns.length
			);
		}
		return false;
	};

	vm.editAdslotFilter = () => {
		Promise.all([AdslotGroups.loadAll(), Sites.loadAll()])
			.then(() => {
				ModalFactory.reportAdslotsFilter({ filterData: vm.template.getColumn(ReportColumns.ID.ADSLOT_ID).filter.model }, (adslotIds) => {
					vm.template.getColumn(ReportColumns.ID.ADSLOT_ID).filter.model = adslotIds;

					// we have to enforce the adslot column otherwise the configured filter would have no effect.
					if (adslotIds.length > 0) {
						vm.template.getColumn(ReportColumns.ID.ADSLOT_ID).toggle(true);
						vm.reportValidity.dimensionsValid = true;
					}
				});
			})
			.catch(() => toaster.errorMessage('MESSAGE.REPORT_TEMPLATE_ADVANCED_FILTER_ERROR'));
	};

	vm.editAdvertiserFilter = () => {
		ModalFactory.reportAdvertisersFilter(
			{
				filterData: vm.template.getColumn(ReportColumns.ID.ADVERTISER_ID).filter.model,
			},
			(advertiserIds) => {
				vm.template.getColumn(ReportColumns.ID.ADVERTISER_ID).filter.model = advertiserIds;

				// we have to enforce the advertiser column otherwise the configured filter would have no effect.
				if (advertiserIds.length > 0) {
					vm.template.getColumn(ReportColumns.ID.ADVERTISER_ID).toggle(true);
					vm.reportValidity.dimensionsValid = true;
				}
			},
		);
	};

	vm.editDemandPartnerFilter = () => {
		ModalFactory.reportDemandPartnersFilter(
			{
				filterData: vm.template.getColumn(ReportColumns.ID.DEMAND_PARTNER_ID).filter.model,
			},
			(demandPartnerIds) => {
				vm.template.getColumn(ReportColumns.ID.DEMAND_PARTNER_ID).filter.model = demandPartnerIds;

				// we have to enforce the demandPartner column otherwise the configured filter would have no effect.
				if (demandPartnerIds.length > 0) {
					vm.template.getColumn(ReportColumns.ID.DEMAND_PARTNER_ID).toggle(true);
					vm.reportValidity.dimensionsValid = true;
				}
			},
		);
	};

	vm.editPartnershipFilter = () => {
		ModalFactory.reportPartnershipsFilter(
			{
				filterData: vm.template.getColumn(ReportColumns.ID.PARTNERSHIP_ID).filter.model,
			},
			(partnershipIds) => {
				vm.template.getColumn(ReportColumns.ID.PARTNERSHIP_ID).filter.model = partnershipIds;

				// we have to enforce the partnerships column otherwise the configured filter would have no effect.
				if (partnershipIds.length > 0) {
					vm.template.getColumn(ReportColumns.ID.PARTNERSHIP_ID).toggle(true);
					vm.reportValidity.dimensionsValid = true;
				}
			},
		);
	};
}

export default ReportSettingsController;
