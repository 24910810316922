function DashboardController($rootScope, $scope) {
	'ngInject';

	var unbindDestroyListener;

	$rootScope.noFrame = true;

	unbindDestroyListener = $scope.$on('$destroy', function destroyListener() {
		$rootScope.noFrame = false;
		unbindDestroyListener();
	});
}

export default DashboardController;
