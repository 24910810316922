import { startOfDay, subDays } from 'date-fns';
import SeparationByHourUtils from '../utils/separation-by-hour-utils.js';
import Dimension from './Dimension.js';
import KeyFigure from './KeyFigure.js';
import ReportColumns from './ReportColumns.js';
import SORT from './ReportColumnSortDirection.js';
import ReportExportSettings from './ReportExportSettings.js';
import ReportNotificationSettings from './ReportNotificationSettings.js';
import ReportPeriod from './ReportPeriod.js';
import ReportSeparateBy from './ReportSeparateBy.js';

function diff(a1, a2) {
	return a1.concat(a2).filter(function filter(value, index, array) {
		return array.indexOf(value) === array.lastIndexOf(value);
	});
}

const MAXIMUM_LOSS_RATE_REPORT_RANGE_DAYS = 30; // It needs to be 30 and not 31 because we don't take hours into account

const TEMPLATE_TYPE = {
	REVENUE: 'revenue',
	ANALYTICS: 'analytics',
	LOSS_RATE: 'loss_rate',
};

const DIMENSIONS = {
	[TEMPLATE_TYPE.REVENUE]: [
		ReportColumns.ID.SITE,
		ReportColumns.ID.GROUP,
		ReportColumns.ID.ADSLOT,
		ReportColumns.ID.ADSLOT_ID,
		ReportColumns.ID.ADTYPE,
		ReportColumns.ID.PLATFORM_TYPE,
		ReportColumns.ID.CHANNELS,
		ReportColumns.ID.EXTERNAL_ID,
		ReportColumns.ID.FORMAT,
		ReportColumns.ID.URL,
		ReportColumns.ID.DEAL_ID,
		ReportColumns.ID.PARTNERSHIP,
		ReportColumns.ID.PARTNERSHIP_ID,
		ReportColumns.ID.PARTNERSHIP_TYPE,
		ReportColumns.ID.DEMAND_PARTNER,
		ReportColumns.ID.DEMAND_PARTNER_ID,
		ReportColumns.ID.PARENT_SUPPLY_PARTNER,
		ReportColumns.ID.PARENT_DEAL_ID,
		ReportColumns.ID.ADVERTISER,
		ReportColumns.ID.ADVERTISER_ID,
		ReportColumns.ID.INVOICE,
	],
	[TEMPLATE_TYPE.ANALYTICS]: [
		ReportColumns.ID.ADSLOT,
		ReportColumns.ID.ADSLOT_ID,
		ReportColumns.ID.DEAL_ID,
		ReportColumns.ID.PARTNERSHIP_ID,
		ReportColumns.ID.PARTNERSHIP,
		ReportColumns.ID.DEMAND_PARTNER,
		ReportColumns.ID.DEMAND_PARTNER_ID,
		ReportColumns.ID.PARENT_SUPPLY_PARTNER,
		ReportColumns.ID.PARENT_DEAL_ID,
		ReportColumns.ID.FORMAT,
	],
	[TEMPLATE_TYPE.LOSS_RATE]: [
		ReportColumns.ID.ADVERTISER,
		ReportColumns.ID.ADVERTISER_ID,
		ReportColumns.ID.PARTNERSHIP,
		ReportColumns.ID.PARTNERSHIP_ID,
		ReportColumns.ID.DEAL_ID,
		ReportColumns.ID.DEMAND_PARTNER,
		ReportColumns.ID.DEMAND_PARTNER_ID,
	],
};

const KEY_FIGURES = {
	[TEMPLATE_TYPE.REVENUE]: [
		ReportColumns.ID.IMPRESSIONS,
		ReportColumns.ID.FALLBACKS,
		ReportColumns.ID.REVENUE,
		ReportColumns.ID.NET_REVENUE,
		ReportColumns.ID.PARENT_REVENUE,
		ReportColumns.ID.ECPM,
		ReportColumns.ID.NET_ECPM,
		ReportColumns.ID.VIDEO_STARTS,
		ReportColumns.ID.VIDEO_FIRST_QUARTILE,
		ReportColumns.ID.VIDEO_MIDPOINT,
		ReportColumns.ID.VIDEO_THIRD_QUARTILE,
		ReportColumns.ID.VIDEO_COMPLETE,
		ReportColumns.ID.VIDEO_CLICK,
	],
	[TEMPLATE_TYPE.ANALYTICS]: [
		ReportColumns.ID.YIELDPROBE_REQUESTS,
		ReportColumns.ID.BID_REQUESTS,
		ReportColumns.ID.TOTAL_BIDS,
		ReportColumns.ID.NO_BIDS,
		ReportColumns.ID.WON_BIDS,
		ReportColumns.ID.YIELDPROBE_IGNORED_RECOMMENDATIONS,
		ReportColumns.ID.LOST_BIDS,
		ReportColumns.ID.BIDS_BELOW_FLOOR,
		ReportColumns.ID.BIDS_BY_BLOCKED_ADVERTISERS,
		ReportColumns.ID.AVERAGE_BID_PRICE,
		ReportColumns.ID.AVERAGE_BIDS_BELOW_FLOOR,
		ReportColumns.ID.AVERAGE_BIDS_BY_BLOCKED_ADVERTISER,
		ReportColumns.ID.DELIVERIES,
		ReportColumns.ID.IMPRESSIONS_RTB,
		ReportColumns.ID.LOSS_RATE,
	],
	[TEMPLATE_TYPE.LOSS_RATE]: [ReportColumns.ID.IMPRESSIONS, ReportColumns.ID.DELIVERIES, ReportColumns.ID.LOSS_RATE],
};

const TIME_INFO_COLUMNS = {
	[TEMPLATE_TYPE.REVENUE]: [ReportColumns.ID.HOUR],
	[TEMPLATE_TYPE.ANALYTICS]: [ReportColumns.ID.HOUR],
};

const LOADED_KEY_FIGURES_ID_MAPPER = {
	[TEMPLATE_TYPE.ANALYTICS]: {
		[ReportColumns.ID.IMPRESSIONS_RTB]: ReportColumns.ID.IMPRESSIONS,
	},
};

function convertListToMap(sourceList) {
	return (sourceList || []).reduce((resultMap, item) => {
		const id = item.name;
		resultMap[id] = deepCopyByJSON(item);
		delete resultMap[id].name;
		return resultMap;
	}, {});
}

const deepCopyByJSON = (source) => angular.fromJson(angular.toJson(source));

class ReportTemplate {
	/**
	 * @param {Object} [data]
	 */
	constructor(data = {}) {
		/**
		 * @type {int}
		 */
		this.id = parseInt(data.id, 10) || 0;

		/**
		 * @type {string}
		 */
		this.name = data.name || '';

		/**
		 * @type {ReportSeparateBy}
		 */
		this.separateBy = new ReportSeparateBy(data.separateBy || ReportSeparateBy.SEPARATE_BY.TOTAL);

		/**
		 * @type {ReportPeriod}
		 */
		this.period = new ReportPeriod(
			data.period
				? {
						period: data.period,
						separatedByHour: this.separateBy.isSeparateByHour(),
					}
				: { period: {}, separatedByHour: false },
		);

		/**
		 * @type {string}
		 */
		this.type = data.type || TEMPLATE_TYPE.REVENUE;

		if (!(this.type.toUpperCase() in TEMPLATE_TYPE)) {
			throw new Error(`invalid template type ${this.type} given`);
		}

		const dimensionMap = convertListToMap(data.dimensions);

		/**
		 * @type {Array<ReportColumn>}
		 */
		this.dimensionColumns = DIMENSIONS[this.type].map(
			(columnId) =>
				new Dimension({
					...ReportColumns.DATA[columnId],
					...(dimensionMap[columnId] || {}),
					visible: Object.entries(dimensionMap).length > 0 ? columnId in dimensionMap : this.isDimensionPreselectedAccordingToType(columnId),
				}),
		);
		this.separateByHourColumns = this.separateBy.isSeparateByHour()
			? TIME_INFO_COLUMNS[this.type].map((columnId) => new KeyFigure({ ...ReportColumns.TIME_INFO_COLUMNS[columnId] }))
			: [];

		const keyFiguresMap = convertListToMap(data.keyFigures);

		/**
		 * @type {Array<ReportColumn>}
		 */
		this.keyFigureColumns = KEY_FIGURES[this.type].map(
			(columnId) =>
				new KeyFigure({
					...ReportColumns.DATA[columnId],
					...(keyFiguresMap[(LOADED_KEY_FIGURES_ID_MAPPER[this.type] || {})[columnId] || columnId] || {}),
					...{
						visible:
							Object.entries(keyFiguresMap).length > 0
								? ((LOADED_KEY_FIGURES_ID_MAPPER[this.type] || {})[columnId] || columnId) in keyFiguresMap
								: this.isKeyFigurePreselectedAccordingToType(columnId),
					},
				}),
		);

		// build shortcut column map for easy filter/sort/search access
		this.getAllColumns().forEach((column) => {
			this.COLUMN_VIEW[column.id] = column;
		});

		/**
		 * @type {ReportExportSettings}
		 */
		this.exportSettings = new ReportExportSettings(data.exportSettings || {});

		/**
		 * @type {ReportNotificationSettings}
		 */
		this.notificationSettings = new ReportNotificationSettings(data.notificationSettings);

		// init sort state
		this.activeSortColumnId = this.getAllColumns()
			.filter(({ sortDirection }) => sortDirection !== SORT.NONE)
			.reduce((noSortingSet, { id }) => id, '');

		this.datePresetOptions = this.getDatePresetOptions();
		this.endDateLocked = false;

		if (this.separateBy.isSeparateByHour()) {
			this.setHourSelectionOptions();
		}
		this.separateByOptions = this.getSeparatedByOptions();
	}

	static TYPE = TEMPLATE_TYPE;

	/**
	 * @type {{string: ReportColumn}}
	 */
	COLUMN_VIEW = {};

	ADSLOT_COLUMN_IDS = [
		ReportColumns.ID.ADSLOT,
		ReportColumns.ID.ADSLOT_ID,
		ReportColumns.ID.ADTYPE,
		ReportColumns.ID.PLATFORM_TYPE,
		ReportColumns.ID.SITE,
		ReportColumns.ID.GROUP,
		ReportColumns.ID.CHANNELS,
		ReportColumns.ID.FORMAT,
		ReportColumns.ID.URL,
	];

	VIDEO_COLUMN_IDS = [
		ReportColumns.ID.VIDEO_STARTS,
		ReportColumns.ID.VIDEO_FIRST_QUARTILE,
		ReportColumns.ID.VIDEO_MIDPOINT,
		ReportColumns.ID.VIDEO_THIRD_QUARTILE,
		ReportColumns.ID.VIDEO_COMPLETE,
		ReportColumns.ID.VIDEO_CLICK,
	];

	/**
	 * @param {int} id
	 * @return {ReportTemplate}
	 */
	setId(id) {
		this.id = id;
		return this;
	}

	/**
	 * @param {string} name
	 * @return {ReportTemplate}
	 */
	setName(name) {
		this.name = name;
		return this;
	}

	/**
	 * @param {string} filename
	 * @returns {ReportTemplate}
	 */
	setFilename(filename) {
		this.exportSettings.filename = filename;
		return this;
	}

	/**
	 * @returns {string}
	 */
	getFilename() {
		return this.exportSettings.filename;
	}

	/**
	 * @returns {string}
	 */
	getFiletype() {
		return this.exportSettings.filetype;
	}

	/**
	 * @returns {string}
	 */
	getFullFilename() {
		return `${this.getFilename()}.${this.getFiletype()}`;
	}

	getSearches() {
		const collectedSearches = {};

		this.dimensionColumns.reduce((searches, { id, search }) => {
			if (search !== '') {
				searches[id] = search;
			}
			return searches;
		}, collectedSearches);

		this.keyFigureColumns.reduce((searches, { id, search }) => {
			if (search !== '') {
				searches[id] = search;
			}
			return searches;
		}, collectedSearches);

		return collectedSearches;
	}

	getFilters() {
		return this.dimensionColumns.reduce((filters, { id, filter }) => {
			if (!filter.isEmpty()) {
				filters[id] = [...filter.model]; // @todo this probably needs more work to be more flexible with model types here
			}
			return filters;
		}, {});
	}

	/**
	 * @returns {Array<String>}
	 */
	getVisibleColumnIds() {
		return this.getVisibleDimensionIds().concat(this.getVisibleKeyFigureIds());
	}

	getVisibleExportColumns() {
		const optionalColumns = this.separateBy.isSeparateByHour() ? this.separateByHourColumns.map((column) => column.id) : [];

		return optionalColumns.concat(this.getVisibleDimensionIds()).concat(this.getVisibleKeyFigureIds());
	}

	/**
	 * @returns {Array<String>}
	 */
	getVisibleDimensionIds() {
		return this.getVisibleDimensionColumns().map((column) => column.id);
	}

	/**
	 * @returns {Array<String>}
	 */
	getVisibleKeyFigureIds() {
		return this.getVisibleKeyFigureColumns().map((column) => column.id);
	}

	/**
	 * @returns {Array<Object>}
	 */
	getVisibleDimensions(additionalColumnIds = []) {
		return this.getVisibleDimensionColumns(additionalColumnIds).map((column) => column.toObject());
	}

	/**
	 * @returns {Array<Object>}
	 */
	getVisibleKeyFigures() {
		return this.getVisibleKeyFigureColumns().map((column) => column.toObject());
	}

	/**
	 * @returns {Array<ReportColumn>}
	 */
	getVisibleDimensionColumns(additionalColumnIds = []) {
		return this.dimensionColumns.filter((column) => column.isVisible() || additionalColumnIds.includes(column.id));
	}

	/**
	 * @returns {Array<ReportColumn>}
	 */
	getVisibleKeyFigureColumns() {
		return this.keyFigureColumns.filter((column) => column.isVisible());
	}

	/**
	 * @returns {Array<ReportColumn>}
	 */
	getAllColumns() {
		return [...this.separateByHourColumns].concat([...this.dimensionColumns]).concat([...this.keyFigureColumns]);
	}

	/**
	 * @param {string} id
	 * @return {ReportColumn}
	 */
	getColumn(id) {
		return this.COLUMN_VIEW[id];
	}

	/**
	 * @returns {boolean}
	 */
	isRevenueReport() {
		return this.type === TEMPLATE_TYPE.REVENUE;
	}

	/**
	 * @returns {boolean}
	 */
	isAnalyticsReport() {
		return this.type === TEMPLATE_TYPE.ANALYTICS;
	}

	/**
	 * @returns {boolean}
	 */
	isLossRateReport() {
		return this.type === TEMPLATE_TYPE.LOSS_RATE;
	}

	validLossRateReport() {
		const minDate = startOfDay(subDays(new Date(), MAXIMUM_LOSS_RATE_REPORT_RANGE_DAYS));
		return this.period.start >= minDate;
	}

	isValidLossRateReport() {
		return this.isLossRateReport() && this.validLossRateReport();
	}
	isInvalidLossRateReport() {
		return this.isLossRateReport() && !this.validLossRateReport();
	}

	/**
	 * @returns {boolean}
	 */
	hasScheduleConfigured() {
		return this.getSchedule().isScheduled();
	}

	/**
	 * @returns {boolean}
	 */
	hasScheduleSupport() {
		return ReportPeriod.SCHEDULED_SUPPORT_PERIOD_TYPES.has(this.period.type);
	}

	/**
	 * @returns {ReportNotificationSettings}
	 */
	getSchedule() {
		return this.notificationSettings;
	}

	/**
	 * @returns {boolean}
	 */
	isAnyAdslotColumnVisible() {
		const currentlyVisibleColumns = this.getVisibleColumnIds();
		return diff(this.ADSLOT_COLUMN_IDS, currentlyVisibleColumns).length !== this.ADSLOT_COLUMN_IDS.length + currentlyVisibleColumns.length;
	}

	/**
	 * @returns {boolean}
	 */
	isAnyVideoColumnVisible() {
		const currentlyVisibleColumns = this.getVisibleColumnIds();
		return diff(this.VIDEO_COLUMN_IDS, currentlyVisibleColumns).length !== this.VIDEO_COLUMN_IDS.length + currentlyVisibleColumns.length;
	}

	/**
	 * @param {ReportColumn} column
	 * @returns {ReportTemplate}
	 */
	sort(column) {
		if (this.activeSortColumnId !== column.id && this.activeSortColumnId !== '') {
			this.COLUMN_VIEW[this.activeSortColumnId].sort(SORT.NONE);
		}

		column.sort();
		this.activeSortColumnId = column.isSorted() ? column.id : '';

		return this;
	}

	/**
	 * @returns {boolean}
	 */
	// remove one column
	isKeyFigurePreselectedAccordingToType(columnId) {
		if (this.isLossRateReport()) {
			return ReportColumns.ID.IMPRESSIONS === columnId || ReportColumns.ID.LOSS_RATE === columnId || ReportColumns.ID.DELIVERIES === columnId;
		}
		return ReportColumns.DATA[columnId].visible;
	}

	/**
	 * @returns {boolean}
	 */
	// remove one column
	isDimensionPreselectedAccordingToType(columnId) {
		if (this.isLossRateReport()) {
			return ReportColumns.ID.ADVERTISER === columnId || ReportColumns.ID.ADVERTISER_ID === columnId;
		}
		return ReportColumns.DATA[columnId].visible;
	}

	/**
	 * @returns {ReportTemplate}
	 */
	removeParentRevenueColumn() {
		this.keyFigureColumns = this.keyFigureColumns.filter((column) => column.id !== ReportColumns.ID.PARENT_REVENUE);

		return this;
	}

	/**
	 * @returns {ReportTemplate}
	 */
	removeParentSupplyPartnerColumn() {
		this.dimensionColumns = this.dimensionColumns.filter((column) => column.id !== ReportColumns.ID.PARENT_SUPPLY_PARTNER);

		return this;
	}

	/**
	 * @returns {ReportTemplate}
	 */
	removeParentDealIdColumn() {
		this.dimensionColumns = this.dimensionColumns.filter((column) => column.id !== ReportColumns.ID.PARENT_DEAL_ID);

		return this;
	}

	/**
	 * @returns {ReportTemplate}
	 */
	removeYPRequestsColumn() {
		this.keyFigureColumns = this.keyFigureColumns.filter((column) => column.id !== ReportColumns.ID.YIELDPROBE_REQUESTS);

		return this;
	}

	changeStartDate() {
		if (this.separateBy.isSeparateByHour()) {
			this.resetHourSelection();
		} else {
			this.separateByOptions = this.getSeparatedByOptions();
		}
	}

	changeEndDate() {
		if (!this.separateBy.isSeparateByHour()) {
			this.separateByOptions = this.getSeparatedByOptions();
			return;
		}
		// only cover when end date goes in advance for one day, otherwise reset hour selection
		if (SeparationByHourUtils.getDifferenceInDays(this.period.end, this.period.start) !== 1) {
			this.period.alignStartDateWithEndDate();
			this.resetHourSelection();
			return;
		}
		this.refreshEndHourOptions();
	}

	changeStartTime() {
		if (this.period.startHour === 0) {
			this.endDateLocked = true;
			this.period.alignEndDateWithStartDate();
		} else {
			this.endDateLocked = false;
		}
		this.refreshEndHourOptions();
	}

	refreshEndHourOptions() {
		this.endHourOptions = this.period.getValidEndHourOptions();

		if (!this.endHourOptions.includes(this.period.endHour)) {
			this.period.endHour = this.endHourOptions[this.endHourOptions.length - 1];
		}
	}

	refreshSeparateByOptions() {
		this.separateByOptions = this.getSeparatedByOptions();
	}

	getSeparatedByOptions() {
		if (this.isLossRateReport()) {
			return ReportSeparateBy.withoutSeparationByHour().filter((option) => ![ReportSeparateBy.SEPARATE_BY.MONTH].includes(option.value));
		} else if (this.period.isLongerThanOneDay()) {
			return ReportSeparateBy.withoutSeparationByHour();
		}
		return ReportSeparateBy.getSeparateBySelectOptions();
	}

	setHourSelectionOptions() {
		this.startHourOptions = this.period.getValidStartHourOptions();
		this.endHourOptions = this.period.getValidEndHourOptions();

		if (this.period.startHour === 0) {
			this.endDateLocked = true;
		}
	}

	changeSeparateBy() {
		if (!this.separateBy.isSeparateByHour()) {
			this.endDateLocked = false;
			if (this.isLossRateReport()) {
				this.datePresetOptions = ReportPeriod.getTimePresetSelectOptionsLossRateReport();
			} else {
				this.datePresetOptions = ReportPeriod.getTimePresetSelectOptions();
			}
			this.period.setStartAndEndAccordingToType();
			this.period.separatedByHour = false;
			return;
		}
		this.period.separatedByHour = true;
		this.period.clampDates(this.period.getMaxDate());
		this.period.setStartAndEndAccordingToType();
		this.datePresetOptions = ReportPeriod.getTimePresetSelectSeparatedByHourOptions();
		this.resetHourSelection();
	}

	resetHourSelection() {
		this.period.alignEndDateWithStartDate();
		this.startHourOptions = this.period.getValidStartHourOptions();
		this.period.startHour = this.startHourOptions[0];

		this.endHourOptions = this.period.getValidEndHourOptions();
		this.period.endHour = this.endHourOptions[this.endHourOptions.length - 1];
		this.endDateLocked = true;
	}

	refreshDatePresetOptions() {
		this.datePresetOptions = this.getDatePresetOptions();
	}

	getDatePresetOptions() {
		if (this.separateBy.isSeparateByHour()) {
			return ReportPeriod.getTimePresetSelectSeparatedByHourOptions();
		}
		if (this.isLossRateReport()) {
			return ReportPeriod.getTimePresetSelectOptionsLossRateReport();
		}
		return ReportPeriod.getTimePresetSelectOptions();
	}

	/**
	 * @return {Object}
	 */
	toObject() {
		return {
			id: this.id,
			name: this.name,
			period: this.period.toObject(),
			separateBy: this.separateBy.separateBy,
			type: this.type,
			dimensions: this.getVisibleDimensions(),
			keyFigures: this.getVisibleKeyFigures(),
			exportSettings: this.exportSettings.toObject(),
			notificationSettings: this.getSchedule().toObject(),
		};
	}
}

export default ReportTemplate;
