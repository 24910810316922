const VIDEO = {
	X_FLV: 1,
	MP4: 2,
	X_SHOCKWAVE_FLASH: 3,
	APPLICATION_JAVASCRIPT: 8,
};

const AUDIO = {
	MPEG: 4,
	OGG: 5,
	ACC: 6,
	WAV: 7,
};

export default {
	VIDEO,
	AUDIO,
};
