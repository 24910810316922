import AdslotFormat from '../models/AdslotFormat.js';

function AdslotsPrices(Api) {
	'ngInject';

	/**
	 * @returns {Promise.<{Number: Array.<AdslotFormat>}>}
	 */
	this.get = () =>
		Api.get('/api/supply/v1/adslots/prices').then((prices = []) =>
			prices.reduce((adslotIdMap, priceData) => {
				const { id: adslotId } = priceData;
				// note: we always create custom formats here as that info is not relevant for pricing related processing
				const adslotFormat = new AdslotFormat(priceData);

				if (!(adslotId in adslotIdMap)) {
					adslotIdMap[adslotId] = {};
				}
				adslotIdMap[adslotId][adslotFormat.dimension.toString()] = adslotFormat.floorPrice;
				return adslotIdMap;
			}, {}),
		);

	/**
	 * @param {Array.<{id: Number, width: Number, height: Number, floorPrice: Number}>} prices
	 * @returns {promise}
	 */
	this.update = (prices) => Api.post('/api/supply/v1/adslots/prices', prices);
}

export default AdslotsPrices;
