/**
 * @see http://stackoverflow.com/questions/15868248/how-to-filter-multiple-values-or-operation-in-angularjs/21169596#21169596
 */
function FilterMultipleFilter($filter) {
	'ngInject';

	return function filterMultiple(items, keyObj) {
		var keyObjKey,
			filterObj = {
				data: items,
				filteredData: [],
				applyFilter: function applyFilter(filterValue, key) {
					var fData, fObj, i, l;

					if (angular.isDefined(filterValue) && filterValue !== null) {
						fObj = {};
						if (!angular.isArray(filterValue)) {
							fObj[key] = filterValue;
							fData = angular.isDefined(fData) ? fData.concat($filter('filter')(this.filteredData, fObj)) : $filter('filter')(this.filteredData, fObj);
						} else if (angular.isArray(filterValue)) {
							if (filterValue.length > 0) {
								l = filterValue.length;
								for (i = 0; i < l; i++) {
									if (angular.isDefined(filterValue[i])) {
										fObj[key] = filterValue[i];
										fData = angular.isDefined(fData) ? fData.concat($filter('filter')(this.filteredData, fObj)) : $filter('filter')(this.filteredData, fObj);
									}
								}
							}
						}
						if (angular.isDefined(fData)) {
							this.filteredData = fData;
						}
					}
				},
			};

		// first filter run
		if (filterObj.filteredData.length === 0) {
			filterObj.filteredData = filterObj.data;
		}

		if (angular.isObject(keyObj) && !angular.isArray(keyObj)) {
			for (keyObjKey in keyObj) {
				if (Object.prototype.hasOwnProperty.call(keyObj, keyObjKey)) {
					filterObj.applyFilter(keyObj[keyObjKey], keyObjKey);
				}
			}
		}

		return filterObj.filteredData;
	};
}

export default FilterMultipleFilter;
