function OnlyAdmin(AuthService) {
	'ngInject';

	return {
		priority: 1000,
		compile: function compile(tElement) {
			if (!AuthService.isAdmin()) {
				tElement.remove();
			} else {
				tElement.removeAttr('only-admin');
				tElement.removeAttr('data-only-admin');
			}
		},
	};
}

export default OnlyAdmin;
