import NativeAsset from './NativeAsset.js';

class NativeAssetTitle extends NativeAsset {
	len = 0;

	constructor(data = {}) {
		super(data);

		const { len = 0 } = data.title || {};

		this.len = len;
		this.uiType = NativeAsset.TYPE.TITLE;
	}

	/**
	 * @returns {boolean}
	 */
	isTitle() {
		// eslint-disable-line class-methods-use-this
		return true;
	}

	/**
	 * @returns {{}}
	 */
	toObject() {
		const title = {
			len: Number(this.len),
		};

		return {
			...super.toObject(),
			title,
		};
	}
}

export default NativeAssetTitle;
