import PartnershipAdvertiserFilter from '../../../models/PartnershipAdvertiserFilter.js';
import { ascByNameField } from '../../../utils/sort-utils.js';

function PartnershipFilterController(
	$scope,
	$filter,
	uiGridConstants,
	Adslots,
	Advertisers,
	AuthService,
	toaster,
	PartnershipFilter,
	InfoService,
	partnershipId,
	resolvedFilter,
	resolvedBlockedAdvertisers,
) {
	'ngInject';

	const vm = this;

	let advertisersRefreshed = false;

	/**
	 * @param {Array.<int>} advertiserIds
	 * @returns {Array.<Object.<int, String>>}
	 */
	function addAdvertiserNames(advertiserIds) {
		var i,
			result = [];

		for (i = 0; i < advertiserIds.length; i++) {
			// ignore advertisers without known name (trigger cache update instead)
			if (Advertisers.getById(advertiserIds[i])) {
				result.push({
					id: advertiserIds[i],
					name: Advertisers.getById(advertiserIds[i]).getName(),
				});
				// if advertisers have not been refreshed during the existence of this controller so far
			} else if (!advertisersRefreshed) {
				advertisersRefreshed = true;
				Advertisers.loadAll();
			}
		}

		return result;
	}

	const byId = (matchId) => (entry) => entry.id === matchId;
	const pickId = (entry) => entry.id;

	const indexFound = (idx) => idx >= 0;

	// blacklist actions

	// left box
	vm.removeSelectedBlacklistItem = (id) => {
		const item = vm.blackList.splice(vm.blackList.findIndex(byId(id)), 1)[0];
		return removeRevenuePotentialItem(item.id);
	};

	// center box
	vm.blockActiveItem = (id) => {
		const item = vm.active.splice(vm.active.findIndex(byId(id)), 1)[0];
		vm.blackList.push(item);
		return item;
	};

	// right box
	const removeRevenuePotentialItem = (id) => {
		const idx = vm.revenuePotential.findIndex((entry) => entry.id === id && entry.type === 'p');
		return indexFound(idx) ? vm.revenuePotential.splice(idx, 1)[0] : idx;
	};

	vm.unblockRevenuePotentialItem = (id) => vm.removeSelectedBlacklistItem(id);

	// whitelist actions

	// left box
	vm.blockSelectedWhitelistItem = (id) => {
		const item = vm.removeSelectedWhitelistItem(id);
		vm.whiteListBlocked.push(item);
		return item;
	};

	vm.removeSelectedWhitelistItem = (id) => {
		const item = vm.whiteList.splice(vm.whiteList.findIndex(byId(id)), 1)[0];
		return item;
	};

	// center box
	vm.allowBlockedWhitelistItem = (id) => {
		const item = vm.removeBlockedWhitelistItem(id);
		vm.whiteList.push(item);
		return item;
	};

	vm.removeBlockedWhitelistItem = (id) => {
		const item = vm.whiteListBlocked.splice(vm.whiteListBlocked.findIndex(byId(id)), 1)[0];
		return item;
	};

	// right box
	vm.allowAvailableWhitelistItem = (id) => {
		const item = vm.available.splice(vm.available.findIndex(byId(id)), 1)[0];
		vm.whiteList.push(item);
		return item;
	};
	vm.blockAvailableWhitelistItem = (id) => {
		const item = vm.available.splice(vm.available.findIndex(byId(id)), 1)[0];
		vm.whiteListBlocked.push(item);
		return item;
	};

	/**
	 * Add advertiser from suggest box to left box if not contained elsewhere so far.
	 * @param item
	 */
	function addAdvertiser(item) {
		const NOT_FOUND = undefined;
		const { id } = item;

		if (vm.isBlacklistSelected()) {
			// in selected blacklist?
			if (
				vm.blackList.find(({ id: entryId }) => entryId === id) === NOT_FOUND &&
				// active blacklist?
				vm.active.find(({ id: entryId }) => entryId === id) === NOT_FOUND
			) {
				vm.blackList.push(item);
			} else {
				toaster.warningMessage('MESSAGE.ADVERTISER_ALREADY_IN_LIST');
			}
		} else if (
			vm.whiteList.find(({ id: entryId }) => entryId === id) === NOT_FOUND &&
			// blocked whitelist?
			vm.whiteListBlocked.find(({ id: entryId }) => entryId === id) === NOT_FOUND &&
			// available whitelist?
			vm.available.find(({ id: entryId }) => entryId === id) === NOT_FOUND
		) {
			vm.whiteList.push(item);
		} else {
			toaster.warningMessage('MESSAGE.ADVERTISER_ALREADY_IN_LIST');
		}
	}

	let initialFilter = resolvedFilter.filter;
	vm.filter = new PartnershipAdvertiserFilter(initialFilter.toObject());

	vm.isBlacklistSelected = () => vm.filter.type === PartnershipAdvertiserFilter.TYPE.BLACKLIST;

	vm.addAdvertiser = {
		input: '',
		config: {
			suggest: function suggest(term) {
				var advId,
					advName,
					advertisers = Advertisers.getMap(),
					result = [];

				for (advId in advertisers) {
					if (Object.prototype.hasOwnProperty.call(advertisers, advId)) {
						// check if searched term is found in advertisers names
						if (advertisers[advId].getName().toLowerCase().indexOf(term.toLowerCase()) !== -1) {
							advName = advertisers[advId].getName();
							result.push({
								label: advName,
								value: advName,
								data: {
									id: parseInt(advId, 10),
									name: advName,
								},
							});
						}
					}
				}

				return $filter('limitTo')(result, 10);
			},
			on_select: function onSelect(item) {
				addAdvertiser(item.data);
				// and clear the box
				$scope.safeApply(function safeApply() {
					vm.addAdvertiser.input = '';
				});
			},
		},
	};

	// initialize the filter lists per type
	const filters = {
		[PartnershipAdvertiserFilter.TYPE.BLACKLIST]: [],
		[PartnershipAdvertiserFilter.TYPE.WHITELIST]: [],
	};

	// load active filter list from config
	filters[vm.filter.type] = Array.from(vm.filter.items);

	vm.blackList = addAdvertiserNames(filters[PartnershipAdvertiserFilter.TYPE.BLACKLIST]);
	vm.whiteList = addAdvertiserNames(filters[PartnershipAdvertiserFilter.TYPE.WHITELIST]);

	vm.revenuePotential = resolvedBlockedAdvertisers;
	vm.whiteListBlocked = addAdvertiserNames(Array.from(vm.filter.blocked));

	vm.active = addAdvertiserNames(resolvedFilter.active);
	vm.available = addAdvertiserNames(resolvedFilter.available);

	const generateCellTemplate = (button1Title, button1Click, button2Title, button2Click) => {
		let cellTemplate = '';
		cellTemplate += '<div class="ui-grid-cell-contents flex align-justify">';
		cellTemplate += '<span title="TOOLTIP" data-ng-bind="row.entity.name"></span>';
		if (AuthService.isAdmin()) {
			if (button1Title) {
				cellTemplate += '<span>';
				cellTemplate += `<a class="button grid outlined no-margin" data-ng-click="grid.appScope.$ctrl['${button1Click}'](row.entity.id)" data-translate="${button1Title}"></a>`;

				if (button2Title) {
					cellTemplate += ' ';
					cellTemplate += `<a class="button grid outlined no-margin" data-ng-click="grid.appScope.$ctrl['${button2Click}'](row.entity.id)" data-translate="${button2Title}"></a>`;
				}
				cellTemplate += '</span>';
			}
		}
		cellTemplate += '</div>';
		return cellTemplate;
	};

	const commonGridOptions = {
		showHeader: true, // this needs to be hacked because somehow filtering does not work when the header is hidden
		headerRowHeight: 0,

		enableHorizontalScrollbar: uiGridConstants.scrollbars.NEVER,
		enableVerticalScrollbar: uiGridConstants.scrollbars.WHEN_NEEDED,

		enableFiltering: true,
		enableSorting: true,
		suppressRemoveSort: true,

		enableRowSelection: false,
		enableRowHeaderSelection: false,
		multiSelect: false,

		rowHeight: 42,
		enableColumnResizing: true,
	};

	const buildGridOptions = (data, cellTemplate, onRegisterApi) => ({
		...commonGridOptions,
		data,
		columnDefs: [
			{
				field: 'name',
				headerCellTemplate: '<div></div>', // second part of the filter-does-only-work-with-a-header hack
				cellTemplate,
				sort: {
					direction: uiGridConstants.ASC,
				},
				sortingAlgorithm(a, b, rowA, rowB) {
					const { entity: entityA } = rowA;
					const { entity: entityB } = rowB;

					const aIsSupply = entityA.type === 's';
					const aIsPartnership = entityA.type === 'p';
					const aIsAdslot = entityA.type === 'a';

					const bIsSupply = entityB.type === 's';
					const bIsPartnership = entityB.type === 'p';
					const bIsAdslot = entityB.type === 'a';

					// We want to sort list entries by type and name.
					// Supply should be at the top, followed by Partnership, followed by Adslot.
					// If they're the same name comparison kicks in. Also for equal names, we
					// additionally sort by entityId in ascending order.

					if ((aIsSupply && bIsSupply) || (aIsPartnership && bIsPartnership) || (aIsAdslot && bIsAdslot)) {
						const comparedByName = ascByNameField(entityA, entityB);

						if (comparedByName === 0) {
							if (entityA.entityId === undefined || entityB.entityId === undefined) {
								// eslint-disable-line angular/definedundefined
								return 0;
							}

							if (entityA.entityId < entityB.entityId) {
								return -1;
							}

							if (entityA.entityId > entityB.entityId) {
								return 1;
							}

							return 0;
						}

						return comparedByName;
					}

					if (aIsSupply || (aIsPartnership && bIsAdslot)) {
						return -1;
					}

					if (aIsAdslot || (aIsPartnership && bIsSupply)) {
						return 1;
					}

					return 0;
				},
			},
		],
		onRegisterApi,
	});

	vm.selectedBlacklistItemsGrid = undefined;
	vm.selectedBlacklistItemsGridFilter = '';
	vm.selectedBlacklistItemsGridOptions = buildGridOptions(vm.blackList, generateCellTemplate('CAPTION.REMOVE', 'removeSelectedBlacklistItem'), (gridApi) => {
		vm.selectedBlacklistItemsGrid = gridApi.grid;
	});
	vm.onSelectedBlacklistItemsGridFilterChange = (value) => {
		vm.selectedBlacklistItemsGrid.columns[0].filters[0].term = value;
	};

	vm.selectedWhitelistItemsGrid = undefined;
	vm.selectedWhitelistItemsGridFilter = '';
	vm.selectedWhitelistItemsGridOptions = buildGridOptions(
		vm.whiteList,
		generateCellTemplate('CAPTION.BLOCK', 'blockSelectedWhitelistItem', 'CAPTION.REMOVE', 'removeSelectedWhitelistItem'),
		(gridApi) => {
			vm.selectedWhitelistItemsGrid = gridApi.grid;
		},
	);
	vm.onSelectedWhitelistItemsGridFilterChange = (value) => {
		vm.selectedWhitelistItemsGrid.columns[0].filters[0].term = value;
	};

	vm.activeBlacklistItemsGrid = undefined;
	vm.activeBlacklistItemsGridFilter = '';
	vm.activeBlacklistItemsGridOptions = buildGridOptions(vm.active, generateCellTemplate('CAPTION.BLOCK', 'blockActiveItem'), (gridApi) => {
		vm.activeBlacklistItemsGrid = gridApi.grid;
	});
	vm.onActiveBlacklistItemsGridFilterChange = (value) => {
		vm.activeBlacklistItemsGrid.columns[0].filters[0].term = value;
	};

	vm.blockedWhitelistItemsGrid = undefined;
	vm.blockedWhitelistItemsGridFilter = '';
	vm.blockedWhitelistItemsGridOptions = buildGridOptions(
		vm.whiteListBlocked,
		generateCellTemplate('CAPTION.ALLOW', 'allowBlockedWhitelistItem', 'CAPTION.REMOVE', 'removeBlockedWhitelistItem'),
		(gridApi) => {
			vm.blockedWhitelistItemsGrid = gridApi.grid;
		},
	);
	vm.onBlockedWhitelistItemsGridFilterChange = (value) => {
		vm.blockedWhitelistItemsGrid.columns[0].filters[0].term = value;
	};

	vm.getAdslotName = (id) => Adslots.getById(id).name;
	vm.revenuePotentialBlacklistItemsGrid = undefined;
	vm.revenuePotentialBlacklistItemsGridFilter = '';
	vm.revenuePotentialBlacklistItemsGridOptions = buildGridOptions(
		vm.revenuePotential,
		`<div class="ui-grid-cell-contents flex align-justify">
			<span>
				<span data-ng-if="row.entity.type === 's'" class="label default-light small" data-translate-attr="{title: 'TEXT.ADVERTISER_BLOCKED_ON_SUPPLY_ACCOUNT_LEVEL'}">S</span>
				<span data-ng-if="row.entity.type === 'p'" class="label default-light small" data-translate-attr="{title: 'TEXT.ADVERTISER_BLOCKED_ON_PARTNERSHIP_LEVEL'}">P</span>
				<span data-ng-if="row.entity.type === 'a'" class="label default-light small" data-translate-attr="{title: 'TEXT.ADVERTISER_BLOCKED_ON_ADSLOT_LEVEL'}">A</span>
				<span title="TOOLTIP" data-ng-bind="row.entity.name"></span>
			</span>
			<span>				
				${AuthService.isAdmin() ? '<a data-ng-if="row.entity.type === \'p\'" class="button grid outlined no-margin" data-ng-click="grid.appScope.$ctrl.unblockRevenuePotentialItem(row.entity.id)" data-translate="CAPTION.UNBLOCK"></a>' : ''}
				<span data-ng-if="row.entity.type === 'a'" class="label default-light small" title="{{grid.appScope.$ctrl.getAdslotName(row.entity.entityId)}}" data-ng-bind="row.entity.entityId"></span>
			</span>
		</div>`,
		(gridApi) => {
			vm.revenuePotentialBlacklistItemsGrid = gridApi.grid;
		},
	);
	vm.onRevenuePotentialBlacklistItemsGridFilterChange = (value) => {
		vm.revenuePotentialBlacklistItemsGrid.columns[0].filters[0].term = value;
	};

	vm.availableWhitelistItemsGrid = undefined;
	vm.availableWhitelistItemsGridFilter = '';
	vm.availableWhitelistItemsGridOptions = buildGridOptions(
		vm.available,
		generateCellTemplate('CAPTION.ALLOW', 'allowAvailableWhitelistItem', 'CAPTION.BLOCK', 'blockAvailableWhitelistItem'),
		(gridApi) => {
			vm.availableWhitelistItemsGrid = gridApi.grid;
		},
	);
	vm.onAvailableWhitelistItemsGridFilterChange = (value) => {
		vm.availableWhitelistItemsGrid.columns[0].filters[0].term = value;
	};

	vm.save = () => {
		const advertisers = vm.isBlacklistSelected() ? vm.blackList : vm.whiteList;
		const blocked = vm.whiteListBlocked;

		const currentFilter = PartnershipAdvertiserFilter.fromIdsAndType(advertisers.map(pickId), vm.filter.type, blocked.map(pickId));

		if (!initialFilter.equals(currentFilter)) {
			InfoService.startRequest();

			PartnershipFilter.update(partnershipId, currentFilter)
				.then(() => {
					// clean/reset filter state
					vm.filter.resetTypeChanged();
					initialFilter = new PartnershipAdvertiserFilter(currentFilter.toObject());

					toaster.successMessage('MESSAGE.PARTNERSHIP_SAVE_SUCCESS');
				})
				.catch(() => toaster.errorMessage('MESSAGE.PARTNERSHIP_SAVE_ERROR'))
				.finally(() => InfoService.endRequest());
		}
	};
}

export default PartnershipFilterController;
