import angular from 'angular';

import providerList from './provider-list.component.js';
import ProviderListController from './provider-list.controller.js';

const ProviderListModule = angular
	.module('providerList', [])
	.component('providerList', providerList)
	.controller('ProviderListController', ProviderListController).name;

export default ProviderListModule;
