import pick from 'lodash/pick.js';

import Adslot from './../../../models/Adslot.js';
import AD_TYPE from './../../../models/AdslotAdType.js';
import PLATFORM_TYPE from './../../../models/AdslotPlatformType.js';
import AdslotFormat from '../../../models/AdslotFormat.js';
import AdslotDimension, { PREDEFINED_DIMENSIONS } from '../../../models/AdslotDimension.js';

function AdslotNewController(
	$rootScope,
	$scope,
	$timeout,
	groupId,
	AdslotGroups,
	Adslots,
	AdslotTranslations,
	InfoService,
	ModalCloser,
	Sites,
	close,
	toaster,
) {
	'ngInject';

	const vm = this;

	const MULTISIZE_ADSLOT_SIZE_DUPLICATED = 50015;
	const MIN_FORMAT_COUNT = 1;
	const MAX_FORMAT_COUNT = 10;

	if (groupId) {
		vm.fixedGroupName = Sites.getById(AdslotGroups.getById(groupId).siteId).name + ' - ' + AdslotGroups.getById(groupId).name;
	}

	vm.platformTypes = AdslotTranslations.getPlatformTypesAsArraySortAscNameField();
	vm.adTypes = AdslotTranslations.getAdTypesAsArraySortAscNameField();
	vm.placementTypes = AdslotTranslations.getPlacementTypes();
	vm.groups = Sites.getListAll().reduce(function groupBySite(result, site) {
		return result.concat(
			AdslotGroups.getListBySiteId(site.id).map(function joinSiteAndGroupName(group) {
				return {
					id: group.id,
					name: site.name + ' - ' + group.name,
				};
			}),
		);
	}, []);
	vm.groupId = groupId || vm.groups[0].id;
	vm.mobileOsTypes = AdslotTranslations.getMobileOsTypes();
	vm.startDelayTypes = AdslotTranslations.getStartDelayTypes();

	vm.success = false;

	vm.onChangePlatformType = () => {
		delete vm.adslot.adType;

		if (angular.isDefined(vm.adslot.platformType)) {
			vm.adTypes = AdslotTranslations.getAdTypesAsArraySortAscNameField().filter((adTypeEntry) =>
				Adslot.isPlatformAdTypeCombinationSupported(vm.adslot.platformType, adTypeEntry.id),
			);

			const isAdTypeSelected = angular.isDefined(vm.adslot.adType);
			const selectedAdTypeIsNotAvailable = vm.adTypes.filter((adTypeEntry) => adTypeEntry.id === vm.adslot.adType).length === 0;
			if (isAdTypeSelected && selectedAdTypeIsNotAvailable) {
				vm.adslot.adType = vm.adTypes[0] ? vm.adTypes[0].id : undefined; // fall back to first available option
			}
		}

		vm.onChangeType();
	};

	vm.onChangeType = function onChangeType() {
		if (vm.adslotTypeSelected()) {
			const dimension = AdslotDimension.fromString(vm.getDimensionOptions()[0], false);
			vm.adslot.formats = [AdslotFormat.fromDimension(dimension)];
		}
	};

	vm.platformTypeSelected = () => angular.isDefined(vm.adslot.platformType);

	vm.adslotTypeSelected = function adslotTypeSelected() {
		return angular.isDefined(vm.adslot.platformType) && angular.isDefined(vm.adslot.adType);
	};

	vm.showReferrerSettings = () => !vm.adslot.isMobileApp() && !vm.adslot.isConnectedTv();

	vm.sortableFormatsConfig = {
		animation: 350,
		handle: '.format-draggable-handle',
	};

	vm.hasMultisize = () => vm.adslot.isMultisizeEnabled();

	vm.getDimensionOptions = () => {
		const { adType, platformType } = vm.adslot;

		let formatOptions = [];

		// Predefined formats only for banner ad types
		if (adType === AD_TYPE.BANNER && AdslotDimension.hasPredefinedDimensions(platformType)) {
			formatOptions = formatOptions.concat(Array.from(PREDEFINED_DIMENSIONS[platformType]));
		}

		return formatOptions.concat([AdslotDimension.CUSTOM_DIMENSION_STRING]);
	};

	/**
	 * @param {AdslotDimension} dimension
	 * @returns {boolean}
	 */
	vm.isCustomDimension = (dimension) =>
		!(vm.adslot.platformType in PREDEFINED_DIMENSIONS) || !PREDEFINED_DIMENSIONS[vm.adslot.platformType].has(dimension.toString());

	vm.onCustomDimensionChange = (format) => {
		if (!vm.isCustomDimension(format.dimension)) {
			// if custom dimension input is shown and a predefined dimension is entered
			// re-set the dimension to the model in order to re-trigger the input formatter
			// and show the predefined entry as selected
			format.dimension = AdslotDimension.fromString(format.dimension.toString(), false);
		}
	};

	vm.canAddFormats = () => vm.hasMultisize() && vm.adslot.formats.length < MAX_FORMAT_COUNT;

	vm.addFormat = () => {
		if (vm.adslot.formats.length < MAX_FORMAT_COUNT) {
			const dimension = AdslotDimension.fromString(vm.getDimensionOptions()[0], false);
			vm.adslot.formats = vm.adslot.formats.concat(AdslotFormat.fromDimension(dimension));

			$timeout(() => $rootScope.$emit('autofocusId::trigger', `dimension-selection-${vm.adslot.formats.length - 1}`));
		}
	};

	vm.canRemoveFormats = () => vm.hasMultisize() && vm.adslot.formats.length > MIN_FORMAT_COUNT;

	vm.removeFormat = (idx) => vm.adslot.formats.splice(idx, 1);

	// form model and defaults
	vm.adslot = new Adslot({
		formats: [{ width: 0, height: 0, floorPrice: 0 }],
	});
	// set some value to undefined to have the "please select" option selected
	vm.adslot.platformType = undefined;
	vm.adslot.adType = undefined;
	vm.adslot.mobileOs = undefined;
	vm.adslot.startDelay = undefined;
	vm.adslot.placementType = undefined;
	vm.adslot.videoPlacementType = undefined;

	vm.save = () => {
		InfoService.startRequest();
		Adslots.add(vm.groupId, vm.getRequestData(vm.adslot))
			.then((adslots) => {
				const group = AdslotGroups.getById(vm.groupId);

				$rootScope.$emit(Adslots.EVENT.UPDATED);
				vm.success = true;

				adslots.forEach((adslot) => {
					group.addAdslot(adslot.id);
				});

				toaster.successMessage('MESSAGE.ADSLOT_CREATED', {
					onHideCallback() {
						vm.close(adslots[0]);
					},
					timeout: 2500,
				});
			})
			.catch((error) => {
				if (error && error.code === MULTISIZE_ADSLOT_SIZE_DUPLICATED) {
					toaster.errorMessage('MESSAGE.ADSLOT_SAVE_ERROR_DIMENSIONS_NOT_UNIQUE');
				} else {
					toaster.errorMessage('MESSAGE.ADSLOT_SAVE_ERROR');
				}
			})
			.finally(InfoService.endRequest.bind(InfoService));
	};

	vm.getRequestData = function getRequestData(adslot) {
		// TODO: Consider using Adslot.getRequestBody()
		const adslotData = pick(adslot, 'name', 'platformType', 'adType', 'formats', 'floorPrice', 'url', 'overrideReferer');

		switch (adslot.platformType) {
			case PLATFORM_TYPE.MOBILE_APP:
				delete adslotData.url;
				delete adslotData.overrideReferer;
				angular.extend(adslotData, pick(adslot, 'mobileAppName', 'mobileBundleName', 'mobileOs', 'storeUrl'));
				break;
			case PLATFORM_TYPE.CONNECTED_TV:
				delete adslotData.url;
				delete adslotData.overrideReferer;
				delete adslot.mobileOs;
				angular.extend(adslotData, pick(adslot, 'mobileAppName', 'mobileBundleName', 'storeUrl'));
				break;
			case PLATFORM_TYPE.DIGITAL_OUT_OF_HOME:
				delete adslot.startDelay;
				break;
			default:
			// no further handling needed
		}

		switch (adslot.adType) {
			case AD_TYPE.AUDIO: {
				// Audio Adslots do not need dimension information at all because there is only one default format valid.
				// Since the introduction of the multisize feature Middleware is not able to properly handle this correctly
				// anymore as one would not be able to configure a floor price if no dimension is provided.
				// In order to fix audio Adslot creation send the default format explicitly (and be able to also send
				// a floor price).
				const [format] = adslotData.formats;
				const { dimension } = format;
				dimension.width = 7;
				dimension.height = 1;
				break;
			}
			case AD_TYPE.VIDEO:
				angular.extend(adslotData, pick(adslot, 'startDelay'));
				break;
			case AD_TYPE.NATIVE:
				angular.extend(adslotData, pick(adslot, 'placementType'));
				break;
			default:
			// no further handling needed
		}

		adslotData.newFormats = adslotData.formats.map((format) => format.toObject());
		delete adslotData.formats;

		return adslotData;
	};

	vm.close = function closeModal(adslot) {
		ModalCloser.close(close, adslot, 200);
	};

	const unbindAuthListener = $rootScope.$on('event:auth-loginRequired', function onAuthRequired() {
		vm.close();
	});
	const unbindDestroyListener = $scope.$on('$destroy', function destroyListener() {
		unbindAuthListener();
		unbindDestroyListener();
	});
}

export default AdslotNewController;
