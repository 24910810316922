import adslotsListHtml from '../../../views/adslots/adslots-list.html';

export default {
	transclude: {
		list: '?listAppend',
	},
	bindings: {
		adslots: '<',
		filter: '<',
		pager: '@',
		searchId: '@',
		searchReset: '<?',
		selection: '<?',
		selectionMode: '<?',
		selectionErrors: '<?',
	},
	template: adslotsListHtml,
	controller: 'AdslotListController',
};
