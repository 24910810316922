function OnlyBeta(AuthService) {
	'ngInject';

	return {
		priority: 1000,
		compile: function compile(tElement) {
			if (!AuthService.hasBetaAccess()) {
				tElement.remove();
			} else {
				tElement.removeAttr('only-beta');
				tElement.removeAttr('data-only-beta');
			}
		},
	};
}

export default OnlyBeta;
