// @see https://bitbucket.org/OverZealous/angularjs-naturalsort/src/f6e7ea30bf71324125549bd40a7cf9043abc2449/src/

function natvalue() {
	'ngInject';

	var natCache = {},
		// amount of extra zeros to padd for sorting
		padding = function padding(value) {
			return '00000000000000000000'.slice(value.length);
		},
		// Converts a value to a string.  Null and undefined are converted to ''
		toString = function toString(value) {
			if (value === null || angular.isUndefined(value)) {
				return '';
			}
			return '' + value;
		},
		// Fix numbers to be correctly padded
		fixNumbers = function fixNumbers(value) {
			// First, look for anything in the form of d.d or d.d.d...
			return toString(value).replace(/(\d+)((\.\d+)+)?/g, function replace($0, integer, decimal, $3) {
				// If there's more than 2 sets of numbers...
				if (decimal !== $3) {
					// treat as a series of integers, like versioning,
					// rather than a decimal
					return $0.replace(/(\d+)/g, function replaceFn($d) {
						return padding($d) + $d;
					});
				}
				// add a decimal if necessary to ensure decimal sorting
				decimal = decimal || '.0'; // eslint-disable-line no-param-reassign
				return padding(integer) + integer + decimal + padding(decimal);
			});
		},
		// Finally, this function puts it all together.
		natValue = function natValue(value) {
			if (natCache[value]) {
				return natCache[value];
			}
			natCache[value] = fixNumbers(value);
			return natCache[value];
		};

	// 'factory' function to specify the desired field name
	return function factory(field) {
		// actual function that is used by orderBy filter and receives the current item
		return function natValueFn(item) {
			return angular.isDefined(field) ? natValue(item[field.replace(/^-/, '')]) : item;
		};
	};
}

export default natvalue;
