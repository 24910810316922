/**
 * @param {string} text to be displayed in UI
 * @param {Function} removeFn to unset the original filter model
 */
function ListFilterEntryUI(text, removeFn) {
	/**
	 * @type {string}
	 */
	this.text = text;

	/**
	 * @type {Function}
	 */
	this.remove = removeFn;
}

export default ListFilterEntryUI;
