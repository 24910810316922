import isNumber from 'lodash/isNumber.js';

import SelectionService from '../../../modules/utils/SelectionService.js';

function PartnershipAdslotsController(
	$scope,
	$rootScope,
	$stateParams,
	toaster,
	PartnershipAdslots,
	Adslots,
	DemandPartners,
	DemandTechnologies,
	InfoService,
	ModalFactory,
	PagerService,
	Partnerships,
	resolvedPrices,
	resolvedAdslots,
	resolvedGuaranteedAdslots,
) {
	'ngInject';

	const assignedAdslotsListId = `partnership-${$stateParams.id}-assigned-adslots`;

	/**
	 * @param {Number} id
	 * @returns {{adType: *, groupId: *, name: *, platformType: *, formats: *, id: *, auctionType: *, channelId: *, isMultisize: *}}
	 */
	function getAdslotListEntry(id) {
		const { groupId, name, platformType, adType, auctionType, channelId, formats, isMultisize } = Adslots.getById(id);

		return {
			id,
			groupId,
			name,
			platformType,
			adType,
			auctionType,
			channelId,
			formats,
			isMultisize,
		};
	}

	function getAvailableAdslots(assignedAdslots) {
		const currentlyAssignedAdslotIds = assignedAdslots.map(({ id }) => +id);
		const partnership = Partnerships.getById($stateParams.id);
		const demandPartner = DemandPartners.getById(partnership.demandId);
		const allowedInventoryTypes = DemandTechnologies.getById(demandPartner.getTechnologyId()).getAllowedInventoryTypes();

		let availableAdslots = [];
		if (resolvedGuaranteedAdslots.length > 0) {
			availableAdslots = resolvedGuaranteedAdslots
				.filter((adslot) => currentlyAssignedAdslotIds.indexOf(adslot.id) === -1)
				.filter((adslot) => allowedInventoryTypes.has(adslot.getInventoryType()));
		} else {
			availableAdslots = Adslots.getListFiltered(currentlyAssignedAdslotIds).filter((adslot) => allowedInventoryTypes.has(adslot.getInventoryType()));
		}

		return availableAdslots.map(({ id }) => getAdslotListEntry(id));
	}

	/**
	 * @param {Array.<{id: Number, floorPrice: Number, fixedPrice: Number}>} partnershipAdslotsPricesList
	 * @returns {{Number: {floorPrice: Number, fixedPrice: Number}}}
	 */
	function toPricesMap(partnershipAdslotsPricesList) {
		return partnershipAdslotsPricesList.reduce((prices, { id, floorPrice, fixedPrice }) => {
			prices[id] = { floorPrice, fixedPrice };
			return prices;
		}, {});
	}

	function copyPricesMap(pricesMap) {
		return Object.keys(pricesMap).reduce((prices, adslotId) => {
			prices[adslotId] = { ...pricesMap[adslotId] };
			return prices;
		}, {});
	}

	function toAdslotsList(pricesMap) {
		return Object.entries(pricesMap).map(([id]) => getAdslotListEntry(id));
	}

	function isValidPriceConfig(adslotId) {
		const { floorPrice, fixedPrice } = $scope.models.prices[adslotId];
		return isNumber(floorPrice) && isNumber(fixedPrice) && ((floorPrice >= 0 && fixedPrice === 0) || (floorPrice === 0 && fixedPrice >= 0));
	}

	const selection = new SelectionService();

	$scope.models = {
		filteredAdslots: [],

		adslots: resolvedAdslots,
		prices: resolvedPrices,
		// store copy to be able to compute changes later on
		initialPrices: copyPricesMap(resolvedPrices),
		invalidPrices: {},

		assignedAdslotsListId,
		assignedAdslotsListFilter: {},

		colspan: 4,

		sortKey: 'name',

		isAssignable: !DemandPartners.getById(Partnerships.getById($stateParams.id).getDemandId()).isInternal(),
		isRemoveMode: false,
		toggleRemoveMode: () => {
			$scope.models.isRemoveMode = !$scope.models.isRemoveMode;

			selection.clear();

			$scope.models.colspan = $scope.models.isRemoveMode ? 5 : 4;
		},

		getFilteredAdslots: () => $scope.models.filteredAdslots,

		selection,
		toggleOne: (adslotId) => selection.toggleOne(adslotId),

		// used in view html
		onPriceChange(adslotId) {
			if (isValidPriceConfig(adslotId)) {
				delete $scope.models.invalidPrices[adslotId];
			} else {
				$scope.models.invalidPrices[adslotId] = true;
			}
		},

		// used in view html
		addAdslots() {
			const listFilter = $scope.models.assignedAdslotsListFilter.instance;
			const listFilterSettings = listFilter.getActiveFilterData();

			ModalFactory.addAdslotsToPartnershipModal(
				{
					partnershipId: $stateParams.id,
					adslots: getAvailableAdslots($scope.models.adslots),
				},
				(partnershipAdslotsPricesList) => {
					// list is empty if modal was cancelled
					if (partnershipAdslotsPricesList.length > 0) {
						$scope.models.prices = toPricesMap(partnershipAdslotsPricesList);
						$scope.models.initialPrices = toPricesMap(partnershipAdslotsPricesList);
						$scope.models.adslots = toAdslotsList($scope.models.prices);

						$rootScope.$emit(PartnershipAdslots.EVENTS.PARTNERSHIP_ADSLOTS_SIZE_UPDATE, $scope.models.adslots.length);
					}

					// as the list filter objects are singletons and we only load different data per page we need to restore
					// the filter to it's previous (from before opening the modal) state
					listFilter.load(listFilterSettings);
				},
			);
		},

		// used in view html
		removeAdslots() {
			const adslotIds = $scope.models.selection.values();

			if (adslotIds.length) {
				InfoService.startRequest();

				PartnershipAdslots.remove($stateParams.id, adslotIds)
					// handle invisible Adslots first
					.then((partnershipAdslotsPricesList) => partnershipAdslotsPricesList.filter(({ id: adslotId }) => Adslots.getById(adslotId).visible))
					// then proceed easily
					.then((partnershipAdslotsPricesList) => {
						toaster.successMessage('MESSAGE.PARTNERSHIP_SAVE_SUCCESS');

						$scope.models.prices = toPricesMap(partnershipAdslotsPricesList);
						$scope.models.initialPrices = toPricesMap(partnershipAdslotsPricesList);
						$scope.models.adslots = toAdslotsList($scope.models.prices);

						$rootScope.$emit(PartnershipAdslots.EVENTS.PARTNERSHIP_ADSLOTS_SIZE_UPDATE, $scope.models.adslots.length);

						$scope.models.toggleRemoveMode();
					})
					.catch(function error() {
						toaster.errorMessage('MESSAGE.PARTNERSHIP_SAVE_ERROR');
					})
					.finally(() => InfoService.endRequest());
			} else {
				$scope.models.toggleRemoveMode();
			}
		},

		// used in view html
		savePrices() {
			if (Object.keys($scope.models.invalidPrices).length === 0) {
				const updatedPrices = Object.keys($scope.models.prices).reduce((pricesList, elementId) => {
					const { floorPrice, fixedPrice } = $scope.models.prices[elementId];

					if (floorPrice !== $scope.models.initialPrices[elementId].floorPrice || fixedPrice !== $scope.models.initialPrices[elementId].fixedPrice) {
						pricesList.push({ id: +elementId, floorPrice, fixedPrice });
					}
					return pricesList;
				}, []);

				if (updatedPrices.length > 0) {
					InfoService.startRequest();

					PartnershipAdslots.updatePrices($stateParams.id, updatedPrices)
						// handle invisible Adslots first
						.then((partnershipAdslotsPricesList) => partnershipAdslotsPricesList.filter(({ id: adslotId }) => Adslots.getById(adslotId).visible))
						// then proceed easily
						.then((partnershipAdslotsPricesList) => {
							toaster.successMessage('MESSAGE.PARTNERSHIP_SAVE_SUCCESS');

							$scope.models.prices = toPricesMap(partnershipAdslotsPricesList);
							$scope.models.initialPrices = toPricesMap(partnershipAdslotsPricesList);
							$scope.models.adslots = toAdslotsList($scope.models.prices);
						})
						.catch(() => {
							toaster.errorMessage('MESSAGE.PARTNERSHIP_SAVE_ERROR');
						})
						.finally(() => InfoService.endRequest());
				}
			}
		},
	};

	$scope.noAdslotsOnPartnership = () => !$scope.models.adslots.length;

	PagerService.get(assignedAdslotsListId).setPerPage(20);
}

export default PartnershipAdslotsController;
