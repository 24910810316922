function ListFilterSettings() {
	this.settings = {};

	/**
	 * @param {string} id
	 * @returns {{}}
	 */
	this.get = function get(id) {
		return this.settings[id];
	};

	/**
	 * @param {string} id
	 * @param {{}} data
	 */
	this.set = function set(id, data) {
		this.settings[id] = data;
	};

	this.reset = function reset() {
		this.settings = {};
	};
}

export default ListFilterSettings;
