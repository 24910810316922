function FormatModel() {
	function Format(data) {
		var key;

		/**
		 * @type {string}
		 */
		this.name = '';

		/**
		 * @type {number}
		 */
		this.width = 0;

		/**
		 * @type {number}
		 */
		this.height = 0;

		for (key in data) {
			if (Object.prototype.hasOwnProperty.call(data, key) && Object.prototype.hasOwnProperty.call(this, key)) {
				this[key] = data[key];
			}
		}
	}

	return Format;
}

export default FormatModel;
