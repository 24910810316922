import adslotsStructureInfoDe from '../../../images/Infographic_structure_adslots_de.png';
import adslotsStructureInfoEn from '../../../images/Infographic_structure_adslots_en.png';

const infoGraphics = {
	de: adslotsStructureInfoDe,
	en: adslotsStructureInfoEn,
};

function GroupController($rootScope, $scope, $state, $translate, groupId, resolvedAdslots, Adslots, AdslotGroups, ModalFactory) {
	'ngInject';

	var vm = this,
		unbindAdslotGroupsUpdatedListener,
		unbindDestroyListener;

	vm.groupId = groupId;
	vm.adslots = resolvedAdslots;

	vm.listFilter = {};
	vm.listFilterId = 'structure-group-adslots-filter-' + groupId;
	vm.listSearchId = 'structure-group-adslots-search-' + groupId;

	vm.addAdslot = function newAdslot() {
		ModalFactory.newAdslot(
			{
				groupId: groupId,
			},
			function adslotCreated(adslot) {
				if (angular.isDefined(adslot)) {
					$state.go('inventory.adslots.view.details', { id: adslot.id });
				}
			},
		);
	};

	vm.moveAdslots = function moveAdslots() {
		const listFilter = vm.listFilter.instance;
		const listFilterSettings = listFilter.getActiveFilterData();

		ModalFactory.moveAdslots(
			{
				groupId: groupId,
			},
			() => {
				// as the list filter objects are singletons and we only load different data per page we need to restore
				// the filter to it's previous (from before opening the modal) state
				listFilter.load(listFilterSettings);
			},
		);
	};

	vm.showHelpModal = function showHelpModal() {
		ModalFactory.imageModalMessage({
			file: infoGraphics[$translate.use()],
		});
	};

	unbindAdslotGroupsUpdatedListener = $rootScope.$on(AdslotGroups.EVENT.UPDATED, function adslotsUpdated() {
		vm.adslots = AdslotGroups.getById(groupId)
			.adslotIds.map(function toAdslotsList(adslotId) {
				return Adslots.getById(adslotId);
			})
			.filter(function onlyVisible(adslot) {
				return adslot.visible;
			});
	});

	unbindDestroyListener = $scope.$on('$destroy', function destroyListener() {
		unbindAdslotGroupsUpdatedListener();
		unbindDestroyListener();
	});
}

export default GroupController;
