function ArrayToString() {
	/**
	 * @param {String} input
	 * @returns {Array.<String>}
	 */
	function stringToArray(input) {
		return input
			? input
					.split(/[\n,]+/)
					.map(function trimRows(row) {
						return row.trim();
					})
					.filter(function removeEmptyRows(row) {
						return !!row.length;
					})
			: [];
	}

	/**
	 * @param {Array.<String>} list
	 * @returns {String}
	 */
	function arrayToString(list) {
		return (list || []).join('\n');
	}

	return {
		restrict: 'A',
		require: 'ngModel',
		scope: false,
		link: function postLink(scope, iElement, attrs, ngModelCtrl) {
			ngModelCtrl.$parsers.push(stringToArray);
			ngModelCtrl.$formatters.push(arrayToString);

			function reformatViewValue(modelValue) {
				var formatters = ngModelCtrl.$formatters,
					idx = formatters.length,
					viewValue = modelValue;

				while (idx--) {
					viewValue = formatters[idx](viewValue);
				}

				ngModelCtrl.$setViewValue(viewValue);
				ngModelCtrl.$render();
			}

			iElement.on('blur.arrayToString', function blur() {
				ngModelCtrl.$commitViewValue();
				reformatViewValue(ngModelCtrl.$modelValue);
			});

			scope.$on('$destroy', function cleanup() {
				iElement.off('blur.arrayToString');
			});
		},
	};
}

export default ArrayToString;
