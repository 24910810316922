function AppUserIndicator($rootScope, AUTH_EVENTS, UserInfo) {
	'ngInject';

	const APP_USER_INDICATOR_HAS_CHANGED_CLASS = 'has-changed';

	return {
		compile: function compile(tElement) {
			return function postLink(scope, iElement) {
				var unbindBootstrapDoneListener, unbindLogoutListener, unbindDestroyListener;

				unbindBootstrapDoneListener = $rootScope.$on('event:bootstrap-done', function bootstrapDoneListener() {
					if (UserInfo.isImpersonatedUser()) {
						if (!tElement.hasClass(APP_USER_INDICATOR_HAS_CHANGED_CLASS)) {
							iElement.addClass(APP_USER_INDICATOR_HAS_CHANGED_CLASS);
						}
					} else {
						iElement.removeClass(APP_USER_INDICATOR_HAS_CHANGED_CLASS);
					}
				});

				unbindLogoutListener = $rootScope.$on(AUTH_EVENTS.logoutSuccess, function logoutListener() {
					iElement.removeClass(APP_USER_INDICATOR_HAS_CHANGED_CLASS);
				});

				unbindDestroyListener = scope.$on('$destroy', function $destroyListener() {
					unbindBootstrapDoneListener();
					unbindLogoutListener();
					unbindDestroyListener();
				});
			};
		},
	};
}

export default AppUserIndicator;
