import angular from 'angular';

import ImageModalMessageController from './modals-image-message.controller.js';
import SimpleModalMessageController from './modals-simple-message.controller.js';

const ModalsModule = angular
	.module('modals', [])
	.controller('ImageModalMessageController', ImageModalMessageController)
	.controller('SimpleModalMessageController', SimpleModalMessageController).name;

export default ModalsModule;
