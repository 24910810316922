function SubmitAttempt($parse) {
	'ngInject';

	var ATTEMPTED_CLASS = 'ng-attempted',
		SUBMITTED_CLASS = 'ng-submitted';

	function returnsTrue() {
		return true;
	}

	return {
		restrict: 'A',
		require: 'form',
		link: function postLink($scope, iElement, iAttrs, formCtrl) {
			var onSubmit = $parse(iAttrs.ylSubmit),
				furtherValidated = $parse(iAttrs.additionalValidation)($scope) || returnsTrue,
				$setPristineOriginal;

			formCtrl.$attempted = false;
			formCtrl.$submitted = false;

			iElement.bind('submit', function submit($event) {
				formCtrl.$attempted = true;
				iElement.addClass(ATTEMPTED_CLASS);

				$scope.safeApply();

				if (!(formCtrl.$valid && furtherValidated())) {
					return false;
				}

				$scope.safeApply(function safeApply() {
					onSubmit($scope, { $event: $event });
				});

				formCtrl.$submitted = true;
				iElement.addClass(SUBMITTED_CLASS);

				formCtrl.$attempted = false;
				iElement.removeClass(ATTEMPTED_CLASS);

				return true;
			});

			// hook into form controllers set pristine method to clear submitted and blurred state
			$setPristineOriginal = formCtrl.$setPristine;
			formCtrl.$setPristine = function $setPristine() {
				$setPristineOriginal.apply(this);
				formCtrl.$attempted = false;
				formCtrl.$submitted = false;
				iElement.removeClass(ATTEMPTED_CLASS);
				iElement.removeClass(SUBMITTED_CLASS);
			};
		},
	};
}

export default SubmitAttempt;
