import PartnershipState from './PartnershipState.js';
import Partnership from './Partnership.js';

const PARTNERSHIP_TYPES = {
	PRIVATE_AUCTION: 0,
	DIRECT_DEAL: 1,
	PROGRAMMATIC_GUARANTEED: 2,
	PREFERRED_DEAL: 3,
};

// Fields that are mandatory when creating NEW partnerships
const PARTNERSHIP_DEFAULT_MANDATORY_FIELDS = ['name', 'demandId', 'type'];

const COMMON_IGNORE_PARTNERSHIP_FIELDS = ['lastAutomatedDeactivation', 'demandName', 'billingOver', 'nonEnabledSizesFromAdslots', 'targetingSummary'];

const IGNORE_FIELDS = {
	DEFAULT: [...COMMON_IGNORE_PARTNERSHIP_FIELDS],
	PRIVATE_AUCTION_TYPE: [...COMMON_IGNORE_PARTNERSHIP_FIELDS, 'dealId', 'dealDates'],
};

const SEMI_TRANSPARENT_REFERER_TYPE = 1;

class PartnershipCSV {
	/**
	 * @type {Number}
	 */
	id = 0;

	/**
	 * @type {String}
	 */
	name = '';

	/**
	 * @type {PartnershipState}
	 */
	state = new PartnershipState();

	/**
	 * 0 - Billing over Yieldlab, 1 - Direct Billing (Demand Partner)
	 * @type {Number}
	 */
	billing = 0;

	/**
	 * @type {Number}
	 */
	demandId = 0;

	/**
	 * @type {String}
	 */
	demandName = '';

	/**
	 * @type {String}
	 */
	dealId = '';

	/**
	 * @type {{dealStart: Date, dealEnd: Date}}
	 */
	dealDates = {};

	/**
	 * @type {Number}
	 */
	type = 0;

	/**
	 * @type {Number}
	 */
	priority = 0;

	/**
	 * in cents
	 * @type {Number}
	 */
	floorPrice = 0;

	/**
	 * in cents
	 * @type {Number}
	 */
	fixedPrice = 0;

	/**
	 * @type {Number}
	 */
	auctionType = 0;

	/**
	 * @type {Number}
	 */
	biasType = 0;

	/**
	 * @type {Number}
	 */
	bias = 0;

	/**
	 * @type {Array<String>}
	 */
	activeSizes = [];

	/**
	 * @type {String}
	 */
	lastAutomatedDeactivation = '';

	/**
	 * @type {Number}
	 */
	refererType = 0;

	/**
	 * @type {String}
	 */
	overrideReferer = '';

	/**
	 * @type {String}
	 */
	categoryInfoAllowed = '';

	/**
	 * @type {String}
	 */
	geoLocationAllowed = '';

	/**
	 * @type {Array<String>}
	 */
	nonEnabledSizesFromAdslots = [];

	/**
	 * @type {Array<String>}
	 */
	dealTargetingIds = [];

	/**
	 * @type {Number}
	 */
	margin = 0;

	constructor(data) {
		this.id = data.id;
		this.name = data.name;
		this.state = data.state !== null ? new PartnershipState(data.state) : null;
		this.billing = data.billing;
		this.demandId = data.demandId;
		this.demandName = data.demandName;
		this.dealId = data.dealId;
		this.dealDates = Partnership.convertDealDatesStringsToObjects(data.dealDates);
		this.type = data.type;
		this.priority = data.priority;
		this.floorPrice = data.floorPrice;
		this.fixedPrice = data.fixedPrice;
		this.auctionType = data.auctionType;
		this.biasType = data.biasType;
		this.bias = data.bias;
		this.activeSizes = data.activeSizes;

		this.lastAutomatedDeactivation = data.lastAutomatedDeactivation;
		this.refererType = data.refererType;
		this.overrideReferer = data.overrideReferer;
		this.categoryInfoAllowed = data.categoryInfoAllowed;
		this.geoLocationAllowed = data.geoLocationAllowed;
		this.nonEnabledSizesFromAdslots = data.nonEnabledSizesFromAdslots;
		this.targetingSummary = data.targetingSummary;
		this.dealTargetingIds = data.dealTargetingIds;
		this.margin = data.margin;
	}

	/**
	 * @return {Number}
	 */
	getId() {
		return this.id;
	}

	/**
	 * @return {Number}
	 */
	getBilling() {
		return this.billing;
	}

	/**
	 * @returns {Number}
	 */
	getDemandId() {
		return this.demandId;
	}

	/**
	 * @returns {String}
	 */
	getDealId() {
		return this.dealId;
	}

	/**
	 * @return {{dealStart: Date, dealEnd: Date}}
	 */
	getDealDates() {
		return this.dealDates;
	}

	/**
	 * @return {Number}
	 */
	getType() {
		return this.type;
	}

	/**
	 * @return {String}
	 */
	getOverrideReferer() {
		return this.overrideReferer;
	}

	/**
	 * @return {boolean}
	 */
	isPrivateAuction() {
		return this.type === PARTNERSHIP_TYPES.PRIVATE_AUCTION;
	}

	/**
	 * @return {boolean}
	 */
	isSemiTransparentRefererType() {
		return this.refererType === SEMI_TRANSPARENT_REFERER_TYPE;
	}

	/**
	 * @returns {boolean}
	 */
	isDisabledAutomatically() {
		return this.state.isDisabledAutomatically();
	}

	/**
	 * Verify whether the field is required for the partnership
	 */
	// eslint-disable-next-line class-methods-use-this
	isFieldMandatory(fieldName) {
		return PARTNERSHIP_DEFAULT_MANDATORY_FIELDS.includes(fieldName);
	}

	clearUnavailableFields(fieldsToCheck) {
		let partnershipType = IGNORE_FIELDS.DEFAULT;

		// Private Auction
		if (this.type === 0) {
			partnershipType = IGNORE_FIELDS.PRIVATE_AUCTION_TYPE;
		}

		fieldsToCheck.forEach((fieldName) => {
			if (partnershipType.includes(fieldName)) {
				this[fieldName] = null;
			}
		});
	}

	getRequestBody(forUpdate = false) {
		let result = {};
		if (!forUpdate) {
			result.id = this.id;
		}
		Object.getOwnPropertyNames(this).forEach((field) => {
			if (field === 'state') {
				result.state = this.state.getStateId();
				return;
			}
			if (field === 'overrideReferer') {
				result.overrideRefererUrl = this.overrideReferer;
				return;
			}
			if (field === 'dealDates') {
				if (this.dealDates === null) {
					result.dealDates = null;
					return;
				}
				const dealStart = this.dealDates.dealStart;
				const dealEnd = this.dealDates.dealEnd;
				result.dealDates = {
					dealStart: dealStart ? dealStart.toISOString().replace(/.\d+.\d+Z$/g, '+0000') : null,
					dealEnd: dealEnd ? dealEnd.toISOString().replace(/.\d+.\d+Z$/g, '+0000') : null,
				};
				return;
			}
			result[field] = this[field];
		});
		return result;
	}
}

export default PartnershipCSV;
