function OnlyBetaAndAdmin(AuthService) {
	'ngInject';

	return {
		priority: 1000,
		compile: function compile(tElement) {
			if (!(AuthService.hasBetaAccess() && AuthService.isAdmin())) {
				tElement.remove();
			} else {
				tElement.removeAttr('only-beta-and-admin');
				tElement.removeAttr('data-only-beta-and-admin');
			}
		},
	};
}

export default OnlyBetaAndAdmin;
