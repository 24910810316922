function FilterBypass($delegate) {
	'ngInject';

	/**
	 * add ability to bypass filter behavior
	 */
	return function filterWithBypassOption(/* array, expression, comparator, bypass */) {
		return arguments[3] === true ? arguments[0] : $delegate.apply(this, arguments);
	};
}

export default FilterBypass;
