import { format } from 'date-fns';
import de from 'date-fns/locale/de';
import enGB from 'date-fns/locale/en-GB';

const locales = { de, en: enGB };

const getDateFormatter = (locale) => (date) => format(date, `P p${locale === de ? " 'Uhr'" : ''}`, { locale });

function DealDurationInfoFilter($filter, $translate) {
	'ngInject';

	return function dealDurationInfo(dealDates) {
		const formatDate = getDateFormatter(locales[$translate.use()]);

		if (dealDates.dealEnd) {
			if (dealDates.dealStart) {
				return $filter('translate')('TEXT.LIMITED_WITH_START_AND_END_DATE', {
					start: formatDate(dealDates.dealStart),
					end: formatDate(dealDates.dealEnd),
				});
			}
			return $filter('translate')('TEXT.LIMITED_SINCE_DEAL_CREATION_WITH_END_DATE', {
				end: formatDate(dealDates.dealEnd),
			});
		}

		if (dealDates.dealStart) {
			return $filter('translate')('TEXT.UNLIMITED_WITH_START_DATE', {
				start: formatDate(dealDates.dealStart),
			});
		}

		return $filter('translate')('TEXT.UNLIMITED_SINCE_DEAL_CREATION');
	};
}

export default DealDurationInfoFilter;
