import isEqual from 'lodash/isEqual.js';

class AdvertiserFilter {
	/**
	 * @type {Set.<Number>}
	 */
	items;

	/**
	 * @type {String}
	 */
	type;

	/**
	 * @type {String}
	 */
	initialType;

	static TYPE = {
		BLACKLIST: 'blacklist',
		WHITELIST: 'whitelist',
	};

	constructor({ domains = [], type = AdvertiserFilter.TYPE.BLACKLIST } = {}) {
		this.items = new Set(domains);
		this.type = type;
		this.initialType = type;
	}

	/**
	 * @param {Number[]} domains
	 * @param {string} type
	 * @return {AdvertiserFilter}
	 */
	static fromIdsAndType(domains, type) {
		return new AdvertiserFilter({ domains, type });
	}

	resetTypeChanged() {
		this.initialType = this.type;
	}

	/**
	 * @return {boolean}
	 */
	hasTypeChanged() {
		return this.type !== this.initialType;
	}

	/**
	 * @param {AdvertiserFilter} other
	 * @return {boolean}
	 */
	equals(other) {
		return this.type === other.type && isEqual(this.items, other.items);
	}

	/**
	 * @return {{domains: <Number[]>, type: <string>}}
	 */
	toObject() {
		return {
			domains: Array.from(this.items),
			type: this.type,
		};
	}

	/**
	 * To add backwards compatibility to endpoints receiving old field names
	 * @return {{advertisers: <Number[]>, advertiserFilter: <string>}}
	 */
	toOldObject() {
		const { domains: advertisers, type: advertiserFilter } = this.toObject();
		return { advertisers, advertiserFilter };
	}
}

export default AdvertiserFilter;
