import AdvertiserCategoryFilter from '../models/AdvertiserCategoryFilter.js';

function PartnershipCategoryFilter(Api) {
	'ngInject';

	/**
	 * @param {Number} id of the partnership to load the filter for
	 * @returns {Promise.<AdvertiserCategoryFilter>}
	 */
	this.get = (id) =>
		Api.get(`/api/supply/v1/partnerships/${id}/filters/categories`).then(({ advertiserCategories }) => AdvertiserCategoryFilter.fromIds(advertiserCategories));

	/**
	 * @param {Number} id of the partnership to save the filter for
	 * @param {AdvertiserCategoryFilter} advertiserCategoryFilter
	 * @returns {Promise.<AdvertiserCategoryFilter>}
	 */
	this.save = (id, advertiserCategoryFilter) =>
		Api.put(`/api/supply/v1/partnerships/${id}/filters/categories`, advertiserCategoryFilter.toObject()).then(() => advertiserCategoryFilter);
}

export default PartnershipCategoryFilter;
