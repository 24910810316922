/**
 * @param {string} key
 */
function ListFilterGroup(key) {
	/**
	 * @type {string}
	 */
	this.key = key;

	/**
	 * @type {Object.<string, ListFilterEntry>}
	 */
	this.entries = {};
}

ListFilterGroup.prototype.load = function load(groupSettings) {
	var self = this;

	if (angular.isObject(groupSettings)) {
		Object.keys(self.entries).forEach(function loadEntrySettings(entryKey) {
			if (Object.prototype.hasOwnProperty.call(groupSettings, entryKey)) {
				self.entries[entryKey].set(groupSettings[entryKey]);
			} else {
				self.entries[entryKey].unset();
			}
		});
	}
};

/**
 * @param {string} key
 * @param {ListFilterEntry} entry
 * @returns {ListFilterGroup}
 */
ListFilterGroup.prototype.addEntry = function addEntry(key, entry) {
	this.entries[key] = entry;
	return this;
};

/**
 * @param {string} key
 * @returns {ListFilterEntry}
 */
ListFilterGroup.prototype.getEntry = function getEntry(key) {
	return this.entries[key];
};

/**
 * @returns {Object.<string, ListFilterEntry>}
 */
ListFilterGroup.prototype.getEntries = function getEntries() {
	return this.entries;
};

/**
 * as@returns {ListFilterGroup}
 * @returns {{group: <ListFilterGroup>, ui: Array.<ListFilterEntryUI>}}
 */
ListFilterGroup.prototype.getActiveEntries = function getEntries() {
	var entryKey,
		result = {
			group: new ListFilterGroup(this.key),
			ui: [],
		};

	for (entryKey in this.entries) {
		if (Object.prototype.hasOwnProperty.call(this.entries, entryKey) && this.entries[entryKey].isActive()) {
			result.group.addEntry(entryKey, this.entries[entryKey]);
			result.ui = result.ui.concat(this.entries[entryKey].asUIElements());
		}
	}

	return result;
};

ListFilterGroup.prototype.reset = function reset() {
	var entryKey;

	for (entryKey in this.entries) {
		if (Object.prototype.hasOwnProperty.call(this.entries, entryKey)) {
			this.entries[entryKey].unset();
		}
	}
};

/**
 * @returns {boolean}
 */
ListFilterGroup.prototype.isEmpty = function isEmpty() {
	return angular.equals(this.entries, {});
};

/**
 * @param listElement
 * @returns boolean
 */
ListFilterGroup.prototype.hasGroupMatch = function hasGroupMatch(listElement) {
	var entryKey,
		isMatching = false;

	for (entryKey in this.entries) {
		if (Object.prototype.hasOwnProperty.call(this.entries, entryKey) && !isMatching) {
			isMatching = this.entries[entryKey].isMatching(listElement);
		}
	}

	return isMatching;
};

export default ListFilterGroup;
