import GoogleProvider from '../../models/GoogleProvider.js';

function GoogleProviders(Api) {
	'ngInject';

	/**
	 * @type {Object.<int, GoogleProvider>}
	 */
	this.providers = {};

	/**
	 * @return {promise}
	 */
	this.loadAll = () => {
		return Api.get('/api/v1/providers').then((data) => {
			this.providers = {};

			for (let i = 0; i < data.items.length; i++) {
				this.providers[data.items[i].id] = new GoogleProvider(data.items[i]);
			}
		});
	};

	/**
	 * @returns {Object.<int, >}
	 */
	this.getMap = function getMap() {
		return this.providers;
	};

	/**
	 * @returns {Array.<GoogleProvider>}
	 */
	this.getList = function getList() {
		let id,
			result = [];

		for (id in this.providers) {
			if (Object.prototype.hasOwnProperty.call(this.providers, id)) {
				result.push(this.providers[id]);
			}
		}

		return result;
	};

	/**
	 * @param {int} id
	 * @returns {GoogleProvider}
	 */
	this.getById = function getById(id) {
		return this.providers[id];
	};
}

export default GoogleProviders;
