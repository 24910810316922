import isEmpty from 'lodash/isEmpty.js';

import INVOICE_TYPE from './PartnershipInvoiceType.js';
import ReportColumns from './ReportColumns.js';
import SORT_DIRECTION from './ReportColumnSortDirection.js';

function RevenueReportModel($filter, Adslots, Partnerships, PGAdslots, ReportRow, SummaryRow, TotalRow) {
	'ngInject';

	const UPDATE_STEP = {
		ALL: 'all',
		GROUP: 'group',
		FILTER: 'filter',
		ORDER: 'order',
	};

	/**
	 * @param {Array} rawReport
	 * @param {ReportTemplate} template
	 */
	function RevenueReport(rawReport, template) {
		/**
		 * @type {Array<ReportRow>}
		 */
		this.data = rawReport.map((rowData) => {
			const reportRow = new ReportRow();

			// divide revenue values through 100 000 to get euro instead of millicent values
			rowData.pr /= 100000;
			rowData.pnr /= 100000;
			rowData.yr /= 100000;
			rowData.parentRevenue /= 100000;

			const partnership = Partnerships.getById(rowData.pid);
			if (partnership) {
				rowData.partnership = partnership.getName();
				rowData.partnershipId = partnership.getId();
				rowData.demandPartner = partnership.getDemandName();
				rowData.demandPartnerId = partnership.getDemandId();
				rowData.dealId = partnership.getDealId();
				rowData.partnership_type = partnership.getType();
			} else {
				// possibly open auction
				if (rowData.partnershipName) {
					rowData.partnership = rowData.partnershipName;
				}
				if (rowData.demandName) {
					rowData.demandPartner = rowData.demandName;
				}
				if (rowData.did) {
					rowData.demandPartnerId = rowData.did;
				}
			}

			// billing
			rowData.invoice = angular.isDefined(rowData.shareType) ? rowData.shareType + 1 : INVOICE_TYPE.NONE; // it starts with -1 for N/A so normalize it here

			const adslot = Adslots.getById(rowData.adid) || PGAdslots.getById(rowData.adid);
			if (adslot) {
				rowData.adslot = adslot.name;
				rowData.channels = adslot.channelId;
				rowData.format = rowData.size;
				rowData.url = adslot.url;
				rowData.group = rowData.groupName;
				rowData.site = rowData.siteName;
				rowData.ad_type = adslot.adType;
				rowData.platform_type = adslot.platformType;
			}

			if (rowData.pi > 0) {
				rowData.partnerEcpm = reportRow.calculateEcpm(rowData.pr, rowData.pi);
				rowData.partnerNetEcpm = reportRow.calculateEcpm(rowData.pnr, rowData.pi);
			}

			reportRow.setData(rowData);
			return reportRow;
		});

		/**
		 * @type {ReportTemplate}
		 */
		this.template = template;

		/**
		 * @type {Array<ReportRow>}
		 */
		this.grouped = [];

		/**
		 * @type {Array<ReportRow>}
		 */
		this.filtered = [];

		/**
		 * @type {Array<ReportRow>}
		 */
		this.ordered = [];
	}

	RevenueReport.prototype.update = function update(updateStep = UPDATE_STEP.ALL) {
		switch (updateStep) {
			default: // eslint-disable-line default-case-last
			case UPDATE_STEP.ALL:
			case UPDATE_STEP.GROUP:
				this.grouped = groupReport(this.data, this.template);
			// we do explicitly run all the steps
			case UPDATE_STEP.FILTER: // eslint-disable-line no-fallthrough
				this.filtered = filterReport(this.grouped, this.template);
			// we do explicitly run all the steps
			case UPDATE_STEP.ORDER: // eslint-disable-line no-fallthrough
				this.ordered = orderReport(this.filtered, this.template);
		}
	};

	/**
	 * @param {ReportRow} row
	 * @param {ReportTemplate} template
	 * @returns {string}
	 */
	function getGroupingRowKey(row, template) {
		const key = template
			.getVisibleDimensionIds()
			.map((columnId) => row[columnId])
			.join('#/#');

		return template.separateBy.isSeparateByHour() ? key + row[ReportColumns.ID.HOUR] : key;
	}

	/**
	 * Grouping rows in this case means collapsing all rows with the same values in their dimension columns into
	 * a single row.
	 *
	 * @param {Array<ReportRow>} reportRows
	 * @param {ReportTemplate} template
	 * @returns {Array<ReportRow>} groupedReportRows
	 */
	function groupReport(reportRows, template) {
		const rowsWithSameKey = {};

		return reportRows
			.reduce((groupedReport, reportRow) => {
				// @todo I don't get this step!
				if (!template.getColumn(ReportColumns.ID.FALLBACKS)?.visible && reportRow[ReportColumns.ID.FALLBACKS] > 0) {
					return groupedReport;
				}

				const rowKey = getGroupingRowKey(reportRow, template);
				// merge all rows with the same key
				if (rowsWithSameKey[rowKey] instanceof ReportRow) {
					rowsWithSameKey[rowKey].mergeAggregateColumns(reportRow);
					return groupedReport;
				}

				// don't edit the original row
				const row = reportRow.copy();
				// new row key detected
				groupedReport.push(row);
				rowsWithSameKey[rowKey] = row;

				return groupedReport;
			}, [])
			.map((groupedReportRow) => groupedReportRow.calculateMergedColumns());
	}

	/**
	 * @param {Array<ReportRow>} groupedReportRows
	 * @param {ReportTemplate} template
	 * @returns {Array<ReportRow>} filteredReportRows
	 */
	function filterReport(groupedReportRows, template) {
		const searchesAndFilters = Object.assign(template.getSearches(), template.getFilters());

		if (isEmpty(searchesAndFilters)) {
			return groupedReportRows;
		}

		return $filter('uniqueReportRows')($filter('filterMultiple')(groupedReportRows, searchesAndFilters), (row) => getGroupingRowKey(row, template));
	}

	RevenueReport.prototype.updateFilter = function updateFilter() {
		this.update(UPDATE_STEP.FILTER);
	};

	/**
	 * Ordering the report in this case means yes, sure, sorting it but also adding intermediate summary rows similar
	 * to a groupBy feature. This only applies if the sorted column is a dimension and has no effect for key figures.
	 *
	 * @param {Array<ReportRow>} filteredReportRows
	 * @param {ReportTemplate} template
	 * @returns {Array<ReportRow>} orderedReportRows
	 */
	function orderReport(filteredReportRows, template) {
		const sortColumnId = template.activeSortColumnId;

		if (sortColumnId === '') {
			return filteredReportRows;
		}

		const sortColumn = template.getColumn(sortColumnId);
		return $filter('orderBy')(filteredReportRows, sortColumnId, sortColumn.sortDirection === SORT_DIRECTION.DESC);
	}

	RevenueReport.prototype.updateOrder = function updateOrder() {
		this.update(UPDATE_STEP.ORDER);
	};

	RevenueReport.prototype.setTemplate = function setTemplate(template) {
		this.template = template;
	};

	/**
	 * get report row count
	 *
	 * @return {number}
	 */
	RevenueReport.prototype.rowCount = function rowCount() {
		return this.grouped.length;
	};

	/**
	 * @param {Array<ReportRow>} orderedReportRows
	 * @param {string} sortColumnId
	 * @returns {Array<ReportRow>} orderedReportRowsWithAddedSummaries
	 */
	RevenueReport.addSummaryRows = (orderedReportRows, sortColumnId) => {
		const summary = orderedReportRows.reduce((summaryMap, orderedRow) => {
			if (!(orderedRow[sortColumnId] in summaryMap)) {
				summaryMap[orderedRow[sortColumnId]] = new SummaryRow();
				summaryMap[orderedRow[sortColumnId]][sortColumnId] = orderedRow[sortColumnId];
			}
			summaryMap[orderedRow[sortColumnId]].mergeAggregateColumns(orderedRow);

			return summaryMap;
		}, {});

		if (isEmpty(summary)) {
			return orderedReportRows;
		}

		Object.values(summary).forEach((summaryRow) => summaryRow.calculateMergedColumns());

		let previousRow = {};
		const orderedReportRowsWithSummaries = orderedReportRows.reduce((rowsWithSummary, orderedReportRow) => {
			// check if column value differs from previous row and insert summary row then
			if (previousRow.type === 'default' && previousRow[sortColumnId] !== orderedReportRow[sortColumnId]) {
				rowsWithSummary.push(summary[previousRow[sortColumnId]]);
			}

			previousRow = orderedReportRow;
			rowsWithSummary.push(orderedReportRow);

			return rowsWithSummary;
		}, []);

		// add (possible) summary for last row in report
		if (previousRow.type === 'default') {
			orderedReportRowsWithSummaries.push(summary[previousRow[sortColumnId]]);
		}

		return orderedReportRowsWithSummaries;
	};

	/**
	 * create total summary row
	 *
	 * @param {Array} rowToSummarize
	 * @param {boolean} isTotalSummary
	 *
	 * @return {TotalRow|SummaryRow}
	 */
	RevenueReport.summarizeRows = function summarizeRows(rowToSummarize, isTotalSummary) {
		var summaryRow = isTotalSummary ? new TotalRow() : new SummaryRow();

		angular.forEach(rowToSummarize, function foreach(reportRow) {
			summaryRow.mergeAggregateColumns(reportRow);
		});

		summaryRow.calculateEcpmColumns();

		return summaryRow;
	};

	return RevenueReport;
}

export default RevenueReportModel;
