import ROLE from '../../constants/UserRoles.js';

import Partnership from '../../models/Partnership.js';

import navigationHtml from '../../views/adslots/navigation.html';
import structureHtml from '../../views/adslots/structure.html';
import sitesHtml from '../../views/adslots/sites.html';
import siteHtml from '../../views/adslots/site.html';
import groupHtml from '../../views/adslots/group.html';
import listHtml from '../../views/adslots/list.html';
import viewHtml from '../../views/adslots/view.html';
import detailsHtml from '../../views/adslots/details.html';
import categoryFilterHtml from '../../views/adslots/category-filter.html';
import filterHtml from '../../views/adslots/filter.html';
import partnershipsHtml from '../../views/adslots/partnerships.html';
import categoriesHtml from '../../views/adslots/categories.html';
import nativeTemplateHtml from '../../views/adslots/native-template.html';
import advancedHtml from '../../views/adslots/advanced.html';
import channelsHtml from '../../views/adslots/channels.html';
import channelsNewEditHtml from '../../views/adslots/channels-new-edit.html';
import adtagsHtml from '../../views/adslots/adtags.html';
import AdvertiserFilter from '../../models/AdvertiserFilter.js';
import AdvertiserCategoryFilter from '../../models/AdvertiserCategoryFilter.js';

function AppConfigRoutingAdslots($stateProvider) {
	'ngInject';

	// controllerAs will be refactored step by step
	$stateProvider // eslint-disable-line angular/controller-as-route
		.state('inventory', {
			parent: 'app',
			abstract: true,
			url: '/inventory',
			views: {
				'subMenu@app': {
					template: navigationHtml,
				},
				'main@app': {
					template: '<div data-ui-view></div>',
					controller: 'AbstractInventoryController',
					controllerAs: 'absInvCtrl',
				},
			},
			resolve: {
				loadSites: function loadSites(Sites) {
					'ngInject';

					return Sites.loadAll();
				},
				loadAdslotGroups: function loadAdslotGroups(AdslotGroups) {
					'ngInject';

					return AdslotGroups.loadAll(); // initialize global map of adslot groups
				},
				loadCategories: function loadCategories(Categories) {
					'ngInject';

					return Categories.load();
				},
				loadNativeTemplates: function loadNativeTemplates(NativeTemplates) {
					'ngInject';

					return NativeTemplates.loadAll();
				},
			},
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER],
			},
		})
		.state('structure', {
			abstract: true,
			parent: 'inventory',
			url: '/structure',
			template: structureHtml,
			controller: 'StructureController as $ctrl',
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER],
			},
		})
		.state('structure.sites', {
			url: '/sites',
			template: sitesHtml,
			controller: 'SitesController',
			controllerAs: 'sitesCtrl',
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER],
			},
		})
		.state('structure.sites.groups', {
			url: '/{siteId:[0-9]+}',
			views: {
				'@structure': {
					template: siteHtml,
					controller: 'SiteController',
					controllerAs: 'siteCtrl',
				},
			},
			resolve: {
				siteExists: function siteExists($q, $stateParams, loadSites, Sites) {
					'ngInject';

					return (
						!!Sites.getById($stateParams.siteId) ||
						$q.reject({
							error: 'TEXT.SITE_NOT_EXISTS',
							data: $stateParams.siteId,
						})
					);
				},
				siteId: function currentSiteId($stateParams) {
					'ngInject';

					return $stateParams.siteId;
				},
			},
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER],
			},
		})
		.state('structure.sites.groups.adslots', {
			url: '/group/{groupId:[0-9]+}',
			views: {
				'@structure': {
					template: groupHtml,
					controller: 'GroupController',
					controllerAs: 'grpCtrl',
				},
			},
			resolve: {
				resolvedAdslots: function resolvedAdslots($stateParams, loadAdslotGroups, Adslots, AdslotGroups) {
					'ngInject';

					return AdslotGroups.load($stateParams.groupId)
						.then((groups) => ((groups[0] || {}).adslotIds || []).map((adslotId) => Adslots.getById(adslotId)).filter((adslot) => adslot && adslot.visible))
						.catch(() => []);
				},
				groupId: function currentGroupId($q, $stateParams, resolvedAdslots, AdslotGroups, StructureBreadcrumb) {
					'ngInject';

					if (AdslotGroups.getById($stateParams.groupId)) {
						StructureBreadcrumb.viewGroup($stateParams.groupId);
						return $stateParams.groupId;
					}

					return $q.reject({
						error: 'TEXT.GROUP_NOT_EXISTS',
						data: $stateParams.groupId,
					});
				},
			},
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER],
			},
		})
		.state('inventory.adslots', {
			url: '/adslots',
			template: listHtml,
			controller: 'InventoryAdslotsListController',
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER],
			},
		})
		.state('inventory.adslots.view', {
			abstract: true,
			url: '/{id:[0-9]+}',
			views: {
				'@inventory': {
					template: viewHtml,
					controller: 'AdslotsViewController',
				},
			},
			resolve: {
				resolvedAdslot: function resolvedAdslot($q, $stateParams, loadAdslotGroups, loadSites, toaster, Adslots) {
					'ngInject';

					return Adslots.load($stateParams.id).catch(function error() {
						return $q.reject({
							error: 'TEXT.ADSLOT_NOT_EXISTS',
							data: $stateParams.id,
						});
					});
				},
				breadcrumbViewAdslot: function breadcrumbViewAdslot($stateParams, resolvedAdslot, StructureBreadcrumb) {
					'ngInject';

					StructureBreadcrumb.viewAdslot($stateParams.id);
				},
			},
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER],
			},
		})
		.state('inventory.adslots.view.details', {
			url: '/details',
			template: detailsHtml,
			controller: 'AdslotDetailsController as $ctrl',
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER],
			},
		})
		.state('inventory.adslots.view.category-filter', {
			url: '/category-filter',
			template: categoryFilterHtml,
			controller: 'AdslotCategoryFilterController',
			resolve: {
				resolvedCategoryFilter($stateParams, Adslots) {
					'ngInject';

					return Adslots.load($stateParams.id).then((adslot) => AdvertiserCategoryFilter.fromIds(adslot.filter.advertiserCategories));
				},
			},
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER],
			},
		})
		.state('inventory.adslots.view.filter', {
			url: '/filter',
			template: filterHtml,
			controller: 'AdslotFilterController',
			resolve: {
				resolvedAdvertiserFilter($stateParams, Adslots) {
					'ngInject';

					return Adslots.load($stateParams.id).then((adslot) => AdvertiserFilter.fromIdsAndType(adslot.filter.advertisers, adslot.filter.advertiserFilter));
				},
			},
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER],
			},
		})
		.state('inventory.adslots.view.partnerships', {
			url: '/partnerships',
			template: partnershipsHtml,
			controller: 'AdslotPartnershipsController',
			resolve: {
				resolvedPrices(AdslotPartnerships, $stateParams) {
					'ngInject';

					return AdslotPartnerships.load($stateParams.id).then(AdslotPartnerships.toIdFloorPriceMap);
				},
				resolvedPartnerships(resolvedPrices, Partnerships) {
					'ngInject';

					return Object.entries(resolvedPrices)
						.map(([id]) => Partnerships.getById(id))
						.map(Partnership.clone);
				},
			},
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER],
			},
		})
		.state('inventory.adslots.view.categories', {
			url: '/categories',
			template: categoriesHtml,
			controller: 'AdslotCategoriesController',
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER],
			},
		})
		.state('inventory.adslots.view.native-template', {
			url: '/native-template',
			template: nativeTemplateHtml,
			controller: 'AdslotNativeTemplateController',
			controllerAs: '$ctrl',
			redirectTo(transition) {
				return transition
					.injector()
					.getAsync('resolvedAdslot')
					.then((adslot) => {
						if (adslot && !adslot.isNative()) {
							return {
								state: 'inventory.adslots.view.details',
								params: { id: adslot.id },
							};
						}
						// when precondition is met, return undefined to not transition anywhere
						return undefined;
					});
			},
			resolve: {
				resolveTemplates($q, NativeTemplates) {
					'ngInject';

					return NativeTemplates.loadAll();
				},
				assignedTemplate(resolvedAdslot, resolveTemplates, Adslots, NativeTemplates) {
					'ngInject';

					// As the resolvedAdslot is only updated when entering the inventory state tree,
					// assigning a template to an Adslot is not properly reflected in the UI when switching
					// between substates. Getting the Adslot from the global service everytime ensures
					// the latest changes to be always reflected.
					const { nativeTemplateId } = Adslots.getById(resolvedAdslot.id) || {};

					return nativeTemplateId && NativeTemplates.getById(nativeTemplateId) ? NativeTemplates.getById(nativeTemplateId).clone() : undefined;
				},
			},
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER],
			},
		})
		.state('inventory.adslots.view.advanced', {
			url: '/advanced',
			template: advancedHtml,
			controller: 'AdslotAdvancedController',
			controllerAs: '$ctrl',
		})
		.state('inventory.channels', {
			url: '/channels',
			views: {
				'@inventory': {
					template: channelsHtml,
					controller: 'AdslotsChannelsController',
				},
			},
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER],
			},
		})
		.state('inventory.channels.edit', {
			url: '/{id:[0-9]+}',
			views: {
				'@inventory': {
					template: channelsNewEditHtml,
					controller: 'AdslotsChannelsEditController',
				},
			},
			resolve: {
				resolvedChannel: function resolvedChannel($stateParams, Channels, $q) {
					'ngInject';

					return (
						Channels.getById($stateParams.id) ||
						$q.reject({
							error: 'not a valid channel id',
							data: $stateParams.id,
						})
					);
				},
			},
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER],
			},
		})
		.state('inventory.channels.new', {
			url: '/new',
			views: {
				'@inventory': {
					template: channelsNewEditHtml,
					controller: 'AdslotsChannelsNewController',
				},
			},
			// workaround to further restrict access (which is not covered by the current basic authorization check)
			resolve: {
				resolvedChannel: function resolvedChannel(UserInfo, $q) {
					'ngInject';

					return UserInfo.hasAllChannels() || $q.reject('MESSAGE.CHANNEL_CREATE_NOT_ALLOWED');
				},
			},
			data: {
				authorizedRoles: [ROLE.ADMIN],
			},
		})
		.state('inventory.adtags', {
			url: '/adtags',
			views: {
				'@inventory': {
					template: adtagsHtml,
					controller: 'AdtagsController',
					controllerAs: 'adtagsCtrl',
				},
			},
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER],
			},
		});
}

export default AppConfigRoutingAdslots;
