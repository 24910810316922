function PricingGlobalController($scope, Account, InfoService, toaster) {
	'ngInject';

	$scope.models.globalPrices = [{ id: 'global' }];

	$scope.models.partnershipsFilter = undefined;
	$scope.models.adslotsFilter = undefined;

	$scope.models.form = $scope.methods.initializeForm($scope.models.globalPrices, { global: { floorPrice: Account.getFloorPrice() } });
	$scope.models.rowPriceForms = {};

	$scope.save = function save() {
		var prices = $scope.methods.getChangedPrices().map(function map(priceObject) {
			delete priceObject.fixedPrice;
			return priceObject;
		});

		if (prices.length) {
			InfoService.startRequest();
			Account.update(prices[0].floorPrice) // there is only one element (if any)
				.then(function success() {
					$scope.models.form = $scope.methods.initializeForm($scope.models.globalPrices, { global: { floorPrice: prices[0].floorPrice } });

					$scope.methods.postSaveActions();

					InfoService.endRequest();
					toaster.successMessage('MESSAGE.SETTINGS_SAVE_SUCCESS');
				})
				.catch(function error() {
					InfoService.endRequest();
					toaster.errorMessage('MESSAGE.SETTINGS_SAVE_ERROR');
				});
		}
	};
}

export default PricingGlobalController;
