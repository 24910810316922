var idx = 0;

function MiniTip($filter, $rootScope) {
	'ngInject';

	return {
		restrict: 'A',
		scope: false,
		link: function postLink(scope, iElement, iAttrs) {
			var removeTranslateListener,
				removeDestroyListener,
				minitipElement,
				currentIdx = ++idx;

			iElement.attr('title', $filter('translate')(iAttrs.ylMiniTip));

			iElement.miniTip({
				event: 'hover',
				showArrow: false,
				className: 'mt-' + currentIdx + ' yieldlab',
			});

			minitipElement = angular.element('.mt-' + currentIdx + '.yieldlab');

			// clean tooltip divs from DOM
			removeDestroyListener = scope.$on('$destroy', function $destroy() {
				minitipElement.remove();
				removeTranslateListener();
				removeDestroyListener();
			});

			// when switching language on a page where minitip is present
			removeTranslateListener = $rootScope.$on('$translateChangeSuccess', function $translateChangeSuccess() {
				// update current minitip content to make sure content is right on the current page
				minitipElement.children().html($filter('translate')(iAttrs.ylMiniTip));
			});
		},
	};
}

export default MiniTip;
