function Checklist($parse) {
	'ngInject';

	return {
		restrict: 'A',
		scope: {
			list: '=ylChecklist',
			value: '@',
			label: '@',
			onChange: '&',
		},
		link: function postLink(scope, iElement) {
			var unbindWatchList,
				unbindDestroy,
				value = $parse(scope.value)(scope.$parent),
				label = $parse(scope.label)(scope.$parent);

			iElement.bind('change', function changeEvent() {
				if (iElement[0].checked) {
					scope.list[value] = label;
				} else {
					delete scope.list[value];
				}
				scope.$apply(scope.onChange);
			});

			unbindWatchList = scope.$watch(
				'list',
				function watched(newValue) {
					iElement[0].checked = !!newValue[value];
				},
				true,
			);

			unbindDestroy = scope.$on('$destroy', function cleanUp() {
				unbindWatchList();
				unbindDestroy();
			});
		},
	};
}

export default Checklist;
