import ListFilterEntryBoolean from './ListFilterEntryBoolean.js';
import ListFilterEntryObject from './ListFilterEntryObject.js';
import ListFilterGroupData from './ListFilterGroupData.js';
import ListFilterGroupElements from './ListFilterGroupElements.js';
import { ascByNameField } from '../../utils/sort-utils.js';

function ListFilterComponents(AdslotGroups, Adslots, AdslotTranslations, Channels, DemandPartners, Partnerships, Sites) {
	'ngInject';

	const demandFilterOptions = Partnerships.getDemandPartnersList() // returns sorted list already
		.filter(({ id }) => DemandPartners.isVisible(id));

	const formatFilterOptions = Adslots.getFormatList() // returns sorted list already
		.map((formatString) => ({
			id: formatString,
			name: formatString,
		}));

	const auctionTypesArray = (function auctionTypeToArrayHelper() {
		const auctionTypeMap = AdslotTranslations.getAuctionTypes();
		return Object.keys(auctionTypeMap)
			.map(function toObjectArray(auctionTypeId) {
				return {
					id: auctionTypeId * 1,
					name: auctionTypeMap[auctionTypeId],
				};
			})
			.sort(ascByNameField);
	})();

	function isAuctionTypeId(model, item) {
		return !!model[item.auctionType];
	}

	this.ADSLOT = {
		SITE: new ListFilterGroupData('LABEL.SITE')
			.addEntry(
				'siteId',
				new ListFilterEntryObject((model, adslot) => !!model[AdslotGroups.getById(adslot.groupId).siteId]).setEntryData(Sites.getAsIdNameObjectsList()),
			)
			.setHasEntrySearch(),
		GROUP: new ListFilterGroupData('LABEL.GROUP')
			.addEntry('groupId', new ListFilterEntryObject((model, adslot) => !!model[adslot.groupId]).setEntryData(AdslotGroups.getAsIdNameObjectsList()))
			.setHasEntrySearch(),
		CHANNEL: new ListFilterGroupData('LABEL.ADSLOT_CHANNEL')
			.addEntry(
				'channelId',
				new ListFilterEntryObject(function isChannelId(model, adslot) {
					// 'no-channel' option is treated as any other selected channel and can therefore be combined.
					// 'no-channel' and other options do not exclude each other
					for (const selectedChannelId of Object.keys(model)) {
						if (selectedChannelId === Channels.WITHOUT_CHANNELS_ID && adslot.channelId.length === 0) {
							return true;
						}
						if (adslot.channelId.indexOf(selectedChannelId * 1) !== -1) {
							return true;
						}
					}

					return false;
				}).setEntryData([Channels.WITOUT_CHANNELS_FILTER_ENTRY].concat(Channels.getList().sort(ascByNameField))),
			)
			.setHasEntrySearch(),
		FORMAT: new ListFilterGroupData('LABEL.ADSLOT_FORMAT')
			.addEntry(
				'formatId',
				new ListFilterEntryObject(function isFormatId(model, adslot) {
					for (const dimension of adslot.formats.map((format) => format.dimension)) {
						if (dimension.toString() in model) {
							return true;
						}
					}

					// pricing manager exception
					if (adslot.format) {
						return adslot.format.dimension.toString() in model;
					}

					return false;
				}).setEntryData(formatFilterOptions),
			)
			.setHasEntrySearch(),
		PLATFORM_TYPE: new ListFilterGroupData('LABEL.ADSLOT_PLATFORM_TYPE').addEntry(
			'platformId',
			new ListFilterEntryObject(function isPlatformTypeId(model, adslot) {
				return !!model[adslot.platformType];
			}).setEntryData(AdslotTranslations.getPlatformTypesAsArraySortAscNameField()),
		),
		AD_TYPE: new ListFilterGroupData('LABEL.ADSLOT_AD_TYPE').addEntry(
			'adId',
			new ListFilterEntryObject(function isAdTypeId(model, adslot) {
				return !!model[adslot.adType];
			}).setEntryData(AdslotTranslations.getAdTypesAsArraySortAscNameField()),
		),
		AUCTION_TYPE: new ListFilterGroupData('LABEL.AUCTION_TYPE').addEntry(
			'auctionTypeId',
			new ListFilterEntryObject(isAuctionTypeId).setEntryData(auctionTypesArray),
		),
	};

	this.PARTNERSHIP = {
		BILLING: new ListFilterGroupElements('TITLE.REPORTS.COLUMN.INVOICE')
			.addEntry(
				'direct',
				new ListFilterEntryBoolean(function isDirectBilling(model, partnership) {
					return partnership.isDirectBilling();
				}).setEntryData('LABEL.BILLING_DIRECT_SHORT'),
			)
			.addEntry(
				'yieldlab',
				new ListFilterEntryBoolean(function isBillingOverYieldlab(model, partnership) {
					return !partnership.isDirectBilling();
				}).setEntryData('TITLE.YIELDLAB'),
			),
		DEMAND_PARTNER: new ListFilterGroupData('LABEL.DEMAND_PARTNER')
			.addEntry(
				'demandPartnerId',
				new ListFilterEntryObject(function isDemandPartnerId(model, partnership) {
					return !!model[partnership.getDemandId()];
				}).setEntryData(demandFilterOptions),
			)
			.setHasEntrySearch(),
		STATUS: new ListFilterGroupElements('LABEL.STATUS')
			.addEntry(
				'active',
				new ListFilterEntryBoolean(function isActive(model, partnership) {
					return partnership.isEnabled();
				})
					.setEntryData('CAPTION.PARTNERSHIP_STATUS_ACTIVE')
					.set(true),
			)
			.addEntry(
				'autoInactive',
				new ListFilterEntryBoolean((model, partnership) => partnership.isDisabledAutomatically()).setEntryData('CAPTION.PARTNERSHIP_STATUS_AUTO_INACTIVE'),
			)
			.addEntry('inactive', new ListFilterEntryBoolean((model, partnership) => partnership.isDisabled()).setEntryData('CAPTION.PARTNERSHIP_STATUS_INACTIVE')),
		TYPE: new ListFilterGroupElements('LABEL.TYPE')
			.addEntry(
				'directDeal',
				new ListFilterEntryBoolean(function isDirectDeal(model, partnership) {
					return partnership.isDirectDeal();
				}).setEntryData('PLACEHOLDER.DIRECT_DEAL'),
			)
			.addEntry(
				'preferredDeal',
				new ListFilterEntryBoolean(function isPreferredDeal(model, partnership) {
					return partnership.isPreferredDeal();
				}).setEntryData('PLACEHOLDER.PREFERRED_DEAL'),
			)
			.addEntry(
				'partnership',
				new ListFilterEntryBoolean(function isPrivateAuction(model, partnership) {
					return partnership.isPrivateAuction();
				}).setEntryData('CAPTION.PRIVATE_AUCTION'),
			)
			.addEntry(
				'programmatic',
				new ListFilterEntryBoolean(function isProgrammatic(model, partnership) {
					return partnership.isProgrammaticGuaranteed();
				}).setEntryData('LABEL.PROGRAMMATIC_GUARANTEED'),
			),
		AUCTION_TYPE: new ListFilterGroupData('LABEL.AUCTION_TYPE').addEntry(
			'auctionTypeId',
			new ListFilterEntryObject(isAuctionTypeId).setEntryData(auctionTypesArray),
		),
	};

	this.ENTRY = {
		IS_CHECKED: new ListFilterGroupElements('LABEL.PROVIDER')
			.addEntry(
				'isChecked',
				new ListFilterEntryBoolean(() => {
					// we do not need this function as we just use this entry for the UI and filter directly in the controller
				}).setEntryData('CAPTION.IS_SELECTED'),
			)
			.addEntry(
				'isNotChecked',
				new ListFilterEntryBoolean(() => {
					// we do not need this function as we just use this entry for the UI and filter directly in the controller
				}).setEntryData('CAPTION.IS_NOT_SELECTED'),
			),
	};
}

export default ListFilterComponents;
