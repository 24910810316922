function AbstractInventoryController($rootScope, $scope, AdslotTranslations, Adslots) {
	'ngInject';

	var unbindChannelsUpdatedListener, unbindAdslotsUpdatedListener, unbindAdslotsRecreatedListener;

	$scope.models = {
		channelsSortKey: 'name',
		adslots: Adslots.getList(),
	};

	$scope.methods = {};

	$scope.methods.rebuildAdslots = function rebuildAdslots() {
		$scope.models.adslots = Adslots.getList();
	};

	unbindChannelsUpdatedListener = $rootScope.$on('Channels::updated', function channelsUpdated() {
		// reload adslots to have possible channel assignment changes updated in the list
		Adslots.loadAll().then(function success() {
			$scope.methods.rebuildAdslots();
		});
	});

	unbindAdslotsUpdatedListener = $rootScope.$on(Adslots.EVENT.UPDATED, function adslotsUpdated() {
		$scope.methods.rebuildAdslots();
	});

	unbindAdslotsRecreatedListener = $rootScope.$on(Adslots.EVENT.RECREATED, function adslotsUpdated() {
		// After submitting Adlost CSV Angular doesn't detect updated (only can detect new ones) adslots.
		// Workaround, in order to force a parent UI to be updated, reset adslots array and bind changes
		$scope.models.adslots = [];
		$scope.$apply();

		$scope.models.adslots = Adslots.getList();
	});

	$rootScope.cleanListeners($scope, [unbindChannelsUpdatedListener, unbindAdslotsUpdatedListener, unbindAdslotsRecreatedListener]);
}

export default AbstractInventoryController;
