import isEmpty from 'lodash/isEmpty.js';
import isUndefined from 'lodash/isUndefined.js';

import ReportColumnFilter from './ReportColumnFilter.js';
import SORT_DIRECTION from './ReportColumnSortDirection.js';
import TYPE from './ReportColumnUiType.js';

class ReportColumn {
	/**
	 * @type {ReportColumn}
	 * @param columnDefinition
	 */
	constructor(columnDefinition) {
		if (isUndefined(columnDefinition) || isEmpty(columnDefinition)) {
			throw new Error('Cannot create ReportColumn with no column definition given.');
		}

		this.id = columnDefinition.id;
		this.name = columnDefinition.name;

		this.filter = new ReportColumnFilter(columnDefinition.filter);

		this.visible = columnDefinition.visible;
		this.search = columnDefinition.search;
		this.sortDirection = columnDefinition.sort;

		this.type = columnDefinition.type;
		this.group = columnDefinition.group;
	}

	/**
	 * @param {boolean} [state]
	 * @returns {ReportColumn}
	 */
	toggle(state) {
		this.visible = isUndefined(state) ? !this.visible : !!state;
		return this;
	}

	/**
	 * @returns {ReportColumn}
	 */
	show() {
		return this.toggle(true);
	}

	/**
	 * @returns {ReportColumn}
	 */
	hide() {
		return this.toggle(false);
	}

	/**
	 * @param {string} [direction]
	 * @returns {ReportColumn}
	 */
	sort(direction = undefined) {
		if (isUndefined(direction)) {
			if (this.sortDirection === SORT_DIRECTION.NONE) {
				this.sortDirection = SORT_DIRECTION.ASC;
			} else if (this.sortDirection === SORT_DIRECTION.ASC) {
				this.sortDirection = SORT_DIRECTION.DESC;
			} else if (this.sortDirection === SORT_DIRECTION.DESC) {
				this.sortDirection = SORT_DIRECTION.NONE;
			}
		} else {
			this.sortDirection = direction;
		}

		return this;
	}

	/**
	 * @returns {boolean}
	 */
	isDimension() {
		// eslint-disable-line class-methods-use-this
		throw new Error('abstract method not implemented');
	}

	/**
	 * @returns {boolean}
	 */
	isKeyFigure() {
		// eslint-disable-line class-methods-use-this
		throw new Error('abstract method not implemented');
	}

	/**
	 * @returns {boolean}
	 */
	isVisible() {
		return this.visible === true;
	}

	/**
	 * @returns {boolean}
	 */
	isSorted() {
		return this.sortDirection !== SORT_DIRECTION.NONE;
	}

	/**
	 * used in template html
	 *
	 * @returns {boolean}
	 */
	isTypeSelect() {
		return this.type === TYPE.SELECT;
	}

	/**
	 * used in template html
	 *
	 * @returns {string}
	 */
	getClassname() {
		return this.type === TYPE.NUMBER || this.type === TYPE.CURRENCY ? 'number' : '';
	}

	toObject() {
		// eslint-disable-line class-methods-use-this
		throw new Error('abstract method not implemented');
	}
}

export default ReportColumn;
