function Indeterminate() {
	return {
		require: 'ngModel',
		link(scope, iElement, iAttrs, ngModelCtrl) {
			const unbindModelWatch = scope.$watch(
				() => ngModelCtrl.$modelValue,
				(newValue) => {
					const checkbox = iElement[0];

					if (newValue === true || newValue === 1) {
						checkbox.checked = true;
						checkbox.indeterminate = false;
					} else if (newValue === false || newValue === -1) {
						checkbox.checked = false;
						checkbox.indeterminate = false;
					} else {
						checkbox.indeterminate = true;
					}
				},
			);

			scope.$on('$destroy', () => {
				unbindModelWatch();
			});
		},
	};
}

export default Indeterminate;
