import { Set } from 'immutable';
import isString from 'lodash/isString.js';

import Adslot from './../models/Adslot.js';

function Adslots(Api, $q) {
	'ngInject';

	const LOAD_PER_IDS_BATCHSIZE = 500;

	this.EVENT = {
		UPDATED: 'Adslots::updated',
		RECREATED: 'AdslotGroups::recreate',
	};

	/**
	 * @type {Map.<number, Adslot>}
	 */
	this.adslots = new Map();

	/**
	 * load all adslots list data from api.
	 *
	 * @return {Promise}
	 */
	this.loadAll = () =>
		Api.get('/api/supply/v1/adslots/list').then(({ adslots = [] }) => {
			this.adslots.clear();

			adslots.forEach((adslot) => this.adslots.set(adslot.id, new Adslot(adslot)));
		});

	/**
	 * load and return specific adslot data from api.
	 *
	 * @param {number} adslotId
	 * @return {Promise<Adslot>}
	 */
	this.load = (adslotId) =>
		Api.get(`api/supply/v1/adslots/${adslotId}`).then(({ adslots = [] }) => {
			if (adslots.length > 0) {
				return new Adslot(adslots[0]);
			}

			// no generic error object available here...
			return $q.reject();
		});

	/**
	 * Load adslot details for one or more given adslot IDs. Make a series of single calls, to that the
	 * individual request URL length stays under 8kb.
	 *
	 * @param {Array[number]} adslotIds
	 * @return {Promise}
	 */
	this.loadDetails = (adslotIds) => {
		let uniqueIds = Array.from(new Set(adslotIds));
		let adslotIdChunks = chunk(uniqueIds, LOAD_PER_IDS_BATCHSIZE);

		let promise = Promise.resolve();
		let i = 0;
		while (i < adslotIdChunks.length) {
			let currentChunk = adslotIdChunks[i];
			promise = promise.then(() => this.loadDetailsSingleCall(currentChunk));
			i++;
		}

		return promise;
	};

	function chunk(sourceArray, chunkSize) {
		let chunks = [];
		for (let i = 0; i < sourceArray.length; i += chunkSize) {
			chunks.push(sourceArray.slice(i, i + chunkSize));
		}
		return chunks;
	}

	/**
	 * Load adslot details for one or more given adslot IDs.
	 *
	 * @param {Array[number]} adslotIds
	 * @return {Promise}
	 */
	this.loadDetailsSingleCall = (adslotIds) =>
		Api.get(`api/supply/v1/adslots/${adslotIds.join(',')}`).then(({ adslots = [] }) => {
			if (adslots.length > 0) {
				adslots.filter((adslot) => isString(adslot.name) && adslot.name.trim().length > 0).forEach((adslot) => this.adslots.set(adslot.id, new Adslot(adslot)));
			}
		});

	/**
	 * We use this to update an adslot.
	 *
	 * @param {number} adslotId
	 * @param {Object} postParams
	 * @returns {Promise<Adslot>}
	 */
	this.update = (adslotId, postParams) =>
		Api.post(`api/supply/v1/adslots/${adslotId}`, postParams).then((adslot) => {
			this.adslots.set(adslot.id, new Adslot(adslot));
			return this.getById(adslot.id);
		});

	/**
	 * We use this to update multiple adslots.
	 *
	 * @param {Array.<Object>|Object} changedAdslots
	 * @param {boolean} csvUpload
	 * @returns {Promise}
	 */
	this.patchUpdate = (changedAdslots, csvUpload = false) => {
		let url = `api/supply/v1/adslots`;

		if (csvUpload) {
			url += '?csv=1';
		}

		return Api.patch(url, { adslots: changedAdslots }).then((response) => {
			response.forEach((adslot) => {
				this.adslots.set(adslot.id, new Adslot(adslot));
			});
		});
	};

	/**
	 * We use this to send a new adslot to API for create.
	 *
	 * @param {number} groupId
	 * @param {Array.<Object>|Object} data
	 * @param {boolean} csvUpload
	 * @returns {Promise<Adslot>}
	 */
	this.add = (groupId, data, csvUpload = false) => {
		let url = `api/supply/v1/adslotgroups/${groupId}/adslots/add`;

		if (csvUpload) {
			url += '?csv=1';
		}

		return Api.post(url, angular.isArray(data) ? data : [data]).then((adslots) =>
			adslots.map((adslotData) => {
				this.adslots.set(adslotData.id, new Adslot(adslotData));
				return this.getById(adslotData.id);
			}),
		);
	};

	/**
	 * @param {Array.<number>} adslots
	 * @param {number} groupId
	 * @returns {Promise}
	 */
	this.move = (adslots, groupId) =>
		Api.post(`api/supply/v1/adslotgroups/${groupId}/adslots/move`, angular.isArray(adslots) ? adslots : [adslots]).then(() => {
			// on success update the groupId for all given adslotIds
			adslots.forEach((adslotId) => {
				this.adslots.get(adslotId).groupId = groupId;
			});
		});

	this.disableAdslot = (adslotId) => Api.put('/api/supply/v1/admin/adslots/disable', { ids: [adslotId] });

	/**
	 * @returns {Map.<number, Adslot>}
	 */
	this.getMap = () => this.adslots;

	/**
	 * @param {number} id
	 * @returns {Adslot}
	 */
	this.getById = (id) => this.adslots.get(Number(id)); // always convert to number here to be on the safe side in case of string inputs

	/**
	 * @param {number} id
	 * @returns {Boolean}
	 */
	this.exists = (id) => this.adslots.has(id);

	/**
	 * @returns {Array.<Adslot>}
	 */
	this.getList = () => Array.from(this.adslots.values()).filter((adslot) => adslot.visible);

	/**
	 * @param {Array.<number>} filter list of adslot ids to exclude in result
	 * @returns {Array.<Adslot>}
	 */
	this.getListFiltered = (filter) =>
		Array.from(this.adslots.values()).filter((adslot) => adslot.visible && !adslot.guaranteed && filter.indexOf(adslot.id) === -1);

	/**
	 * @returns {Array.<string>}
	 */
	this.getFormatList = () => {
		const allDimensions = Array.from(this.adslots.values()).flatMap((adslot) => (adslot.visible ? adslot.formats.map((format) => format.dimension) : []));

		const uniqueDimensions = Set(allDimensions);

		const sortedUniqueDimensions = this.sortByWidthAndHeight(Array.from(uniqueDimensions), (element) => [element.width, element.height]);

		return sortedUniqueDimensions.map((dimension) => dimension.toString());
	};

	/**
	 * Return sorted dimension by width and height.
	 * @param list Collection of elements to be sorted
	 * @param extractFunction function that extracts sorting fields
	 * @returns Collection of sorted elements
	 */
	this.sortByWidthAndHeight = (list, extractFunction) =>
		list.sort((one, another) => {
			const [width1, height1] = extractFunction(one);
			const [width2, height2] = extractFunction(another);

			if (width1 < width2 || (width1 === width2 && height1 < height2)) {
				return -1;
			}

			if (width1 > width2 || (width1 === width2 && height1 > height2)) {
				return 1;
			}

			return 0;
		});

	this.downloadAdslots = () => {
		return Api.getFile('/api/supply/v1/adslots/download').then((response) => {
			const file = response.data;
			const fileName = getFilenameFromResponse(response.headers());
			const downloadLink = angular.element('<a/>');
			downloadLink.css({ display: 'none' });
			downloadLink
				.attr({
					href: URL.createObjectURL(file),
					download: fileName,
				})[0]
				.click();
			downloadLink.remove();
			URL.revokeObjectURL(file);
		});
	};

	const getFilenameFromResponse = (headers) => {
		const fileName = headers['content-disposition'].split(';')[1].trim().split('=')[1];
		if (fileName.match("''")) {
			return fileName.split("''")[1];
		}
		return fileName;
	};
}

export default Adslots;
