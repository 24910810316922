import ListFilterEntry from './ListFilterEntry.js';
import ListFilterEntryUI from './ListFilterEntryUI.js';

/**
 * @param {function(model, *)} matchingFn
 */
function ListFilterEntryBoolean(matchingFn) {
	ListFilterEntry.call(this, matchingFn);

	/**
	 * @type {boolean}
	 */
	this.model = false;
}

ListFilterEntryBoolean.prototype = Object.create(ListFilterEntry.prototype);
ListFilterEntryBoolean.prototype.constructor = ListFilterEntryBoolean;

/**
 * @param {*} value
 * @returns {ListFilterEntry}
 */
ListFilterEntryBoolean.prototype.set = function setModel(value) {
	this.model = value;
	return this;
};

/**
 * @returns {ListFilterEntry}
 */
ListFilterEntryBoolean.prototype.unset = function unset() {
	this.model = false;
	return this;
};

/**
 * @returns {boolean}
 */
ListFilterEntryBoolean.prototype.isActive = function isActive() {
	return this.model === true;
};

/**
 * @returns Array.<ListFilterEntryUI>
 */
ListFilterEntryBoolean.prototype.asUIElements = function asUIElements() {
	return [new ListFilterEntryUI(this.entryData, this.unset.bind(this))];
};

export default ListFilterEntryBoolean;
