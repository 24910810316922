import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

import SettingsAccountModule from './settings-account/settings-account.module.js';
import SettingsSupplyModule from './settings-supply/settings-supply.module.js';
import SettingsSupplyCategoryFilterModule from './settings-supply/settings-supply-category-filter.module.js';
import SettingsInventoryModule from './settings-inventory/settings-inventory.module.js';
import SettingsProviderWhitelistModule from './settings-provider-whitelist/settings-provider-whitelist.module.js';

import SettingsController from './settings.controller.js';

import SettingsConfig from './settings.config.js';

const SettingsModule = angular
	.module('settings', [
		uiRouter,

		SettingsAccountModule,
		SettingsSupplyModule,
		SettingsSupplyCategoryFilterModule,
		SettingsInventoryModule,
		SettingsProviderWhitelistModule,
	])
	.controller('SettingsController', SettingsController)
	.config(SettingsConfig).name;

export default SettingsModule;
