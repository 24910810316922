function Categories(Api) {
	'ngInject';

	/**
	 * Map of categories.
	 * @type {Object}
	 */
	this.categories = {};

	/**
	 * @param {Array} [categoryCodes]
	 * @returns {promise}
	 */
	this.load = function load(categoryCodes) {
		var self = this;
		const ids = angular.isArray(categoryCodes) ? categoryCodes : [categoryCodes];
		const url = `api/v1/accounts/categories/${ids?.join(',') || ''}`;
		return Api.get(url).then(function success(result) {
			var categories = result.categories,
				i;

			for (i = 0; i < categories.length; i++) {
				self.categories[categories[i].code] = {
					code: categories[i].code,
					description: categories[i].description,
				};
			}
		});
	};

	/**
	 * @returns {Object}
	 */
	this.get = function getFn() {
		return this.categories;
	};

	/**
	 * @param {String} categoryCode
	 * @returns {Object}
	 */
	this.getDescription = function getDescription(categoryCode) {
		return this.categories[categoryCode] ? this.categories[categoryCode].description : '';
	};

	/**
	 * Return list of categories.
	 * @param {Array.<String>} [filter] list of category codes that should not be returned with
	 * @returns {Array.<{code: <String>, description: <String>}>}
	 */
	this.getAsList = function getAsList(filter) {
		var categoryCode,
			result = [];

		for (categoryCode in this.categories) {
			if (Object.prototype.hasOwnProperty.call(this.categories, categoryCode)) {
				if (
					// filter items based on given filter ids
					!filter ||
					(angular.isArray(filter) && filter.length === 0) ||
					filter.indexOf(categoryCode) === -1
				) {
					result.push({
						code: categoryCode,
						description: this.categories[categoryCode].description,
					});
				}
			}
		}

		return result;
	};
}

export default Categories;
