import preFilterHtml from '../../views/reports/pre-filter.html';

const preFilter = {
	bindings: {
		elements: '<',
		selected: '<',
		onSelectionChange: '&',
	},
	template: preFilterHtml,
	controller: 'ReportsPreFilterController as $ctrl',
};

export default preFilter;
