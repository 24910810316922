function ReplaceFilter() {
	return function replace(value, replacements) {
		var key,
			result = value;
		for (key in replacements) {
			if (Object.prototype.hasOwnProperty.call(replacements, key)) {
				result = result.replace(new RegExp('%' + key + '%', 'g'), replacements[key]);
			}
		}
		return result;
	};
}

export default ReplaceFilter;
