function InventoryAdslotsListController($scope, $state, Adslots, ModalFactory) {
	'ngInject';

	$scope.listFilter = {};

	$scope.uploadAdslots = () => ModalFactory.uploadAdslotCSV({});

	$scope.downloadAdslots = () => Adslots.downloadAdslots();

	$scope.newAdslot = function newAdslot() {
		ModalFactory.newAdslot({}, function adslotCreated(adslot) {
			if (angular.isDefined(adslot)) {
				$state.go('inventory.adslots.view.details', { id: adslot.id });
			}
		});
	};
}

export default InventoryAdslotsListController;
