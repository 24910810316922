function ReportOrders(Api, $q, $window) {
	'ngInject';

	const REPORT_ORDERS_CONTROLLER = '/api/supply/v1/reports';

	this.createReportOrderFromTemplate = (template, requestExport, additionalDimensions = []) => {
		let postParams = {
			type: template.type,
			period: template.period.toObject(),
			separateBy: template.separateBy.separateBy,
			dimensions: template.getVisibleDimensions(additionalDimensions),
			keyFigures: template.getVisibleKeyFigures(),
		};
		if (requestExport) {
			postParams.exportSettings = {
				fileName: template.getFilename(),
				fileType: template.getFiletype(),
			};
		}
		return Api.post(`${REPORT_ORDERS_CONTROLLER}/orders`, postParams);
	};

	this.getStatus = () => Api.get(`${REPORT_ORDERS_CONTROLLER}/orders`);

	this.getStatusById = (reportUUID) => Api.get(`${REPORT_ORDERS_CONTROLLER}/orders/${reportUUID}`);

	this.getJsonReport = (reportUUID) => {
		return Api.get(`${REPORT_ORDERS_CONTROLLER}/${reportUUID}`).then(({ report }) => report);
	};
}

export default ReportOrders;
