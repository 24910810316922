function FormDebug($parse, $compile) {
	'ngInject';

	return {
		restrict: 'A',
		link: function postLink(scope, iElement, iAttrs) {
			var formName = iAttrs.ylFormDebug || '';

			function generateDebugInfoList($scope, formCtrl) {
				var field,
					list = angular.element($compile('<ul></ul>')($scope));

				list.append(angular.element($compile('<li>' + formName + '.$error: {{' + formName + '.$error}}</li>')($scope)));
				list.append(angular.element($compile('<li>' + formName + '.$pristine: {{' + formName + '.$pristine}}</li>')($scope)));
				list.append(angular.element($compile('<li>' + formName + '.$dirty: {{' + formName + '.$dirty}}</li>')($scope)));
				list.append(angular.element($compile('<li>' + formName + '.$valid: {{' + formName + '.$valid}}</li>')($scope)));
				list.append(angular.element($compile('<li>' + formName + '.$invalid: {{' + formName + '.$invalid}}</li>')($scope)));
				list.append(angular.element($compile('<li>' + formName + '.$attempted: {{' + formName + '.$attempted}}</li>')($scope)));
				list.append(angular.element($compile('<li>' + formName + '.$submitted: {{' + formName + '.$submitted}}</li>')($scope)));

				for (field in formCtrl) {
					if (Object.prototype.hasOwnProperty.call(formCtrl, field) && field.indexOf('$') === -1) {
						list.append(angular.element($compile('<li>&nbsp;</li>')($scope)));
						list.append(angular.element($compile('<li>' + formName + '.' + field + '.$error: {{' + formName + '.' + field + '.$error}}</li>')($scope)));
						list.append(angular.element($compile('<li>' + formName + '.' + field + '.$pristine: {{' + formName + '.' + field + '.$pristine}}</li>')($scope)));
						list.append(angular.element($compile('<li>' + formName + '.' + field + '.$dirty: {{' + formName + '.' + field + '.$dirty}}</li>')($scope)));
						list.append(angular.element($compile('<li>' + formName + '.' + field + '.$blurred: {{' + formName + '.' + field + '.$blurred}}</li>')($scope)));
						list.append(angular.element($compile('<li>' + formName + '.' + field + '.$valid: {{' + formName + '.' + field + '.$valid}}</li>')($scope)));
						list.append(angular.element($compile('<li>' + formName + '.' + field + '.$invalid: {{' + formName + '.' + field + '.$invalid}}</li>')($scope)));
					}
				}

				return list;
			}

			iElement.append(generateDebugInfoList(scope, $parse(formName)(scope) || {}));
		},
	};
}

export default FormDebug;
