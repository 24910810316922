import ReportNotificationSettingsSchedule from './ReportNotificationSettingsSchedule.js';

class ReportNotificationSettings {
	constructor(notificationSettings) {
		let { schedule = {}, recipients = [] } = notificationSettings || {};
		/**
		 * @type {ReportNotificationSettingsSchedule}
		 */
		this.schedule = new ReportNotificationSettingsSchedule(schedule);

		/**
		 * @type {string[]}
		 */
		this.recipients = recipients;
	}

	/**
	 * @returns {boolean}
	 */
	isEmpty() {
		return !this.isScheduled();
	}

	/**
	 * @returns {boolean}
	 */
	isScheduled() {
		return this.schedule.isActive();
	}

	/**
	 * @returns {boolean}
	 */
	isScheduledWithDay() {
		return this.schedule.isScheduledWithDay();
	}

	toObject() {
		if (this.isEmpty()) {
			return undefined;
		}

		return {
			schedule: this.schedule.toObject(),
			recipients: [...this.recipients],
		};
	}
}

export default ReportNotificationSettings;
