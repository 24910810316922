import angular from 'angular';

import InventoryStructureBreadcrumbModule from './inventory-structure-breadcrumb/inventory-structure-breadcrumb.module.js';

import InventoryStructureController from './inventory-structure.controller.js';
import InventoryStructureGroupController from './inventory-structure-group.controller.js';
import InventoryStructureGroupsNewController from './inventory-structure-groups-new.controller.js';
import InventoryStructureSiteController from './inventory-structure-site.controller.js';
import InventoryStructureSitesController from './inventory-structure-sites.controller.js';
import InventoryStructureSitesNewController from './inventory-structure-sites-new.controller.js';

import InventoryStructureGroupsService from './inventory-structure-groups.service.js';
import InventoryStructureSitesService from './inventory-structure-sites.service.js';

const InventoryStructureModule = angular // eslint-disable-line angular/no-service-method
	.module('inventory.structure', [InventoryStructureBreadcrumbModule])
	.controller('StructureController', InventoryStructureController)
	.controller('GroupController', InventoryStructureGroupController)
	.controller('GroupsNewController', InventoryStructureGroupsNewController)
	.controller('SiteController', InventoryStructureSiteController)
	.controller('SitesController', InventoryStructureSitesController)
	.controller('SitesNewController', InventoryStructureSitesNewController)
	.service('AdslotGroups', InventoryStructureGroupsService)
	.service('Sites', InventoryStructureSitesService).name;

export default InventoryStructureModule;
