function ToggleScrolltable() {
	'ngInject';

	return {
		restrict: 'A',
		scope: false,
		link: function postLink(scope, iElement, iAttrs) {
			var unbindToggleStateWatch,
				unbindDestroyListener,
				dummyThead,
				headTable,
				doesScroll = false,
				scrolltableWrapper,
				scrolltableWrapperTemplate = '<div class="scroll-content"></div>',
				thead = iElement.find('thead');

			function toggleScrolltable(shouldScroll) {
				if (shouldScroll) {
					doesScroll = true;

					headTable = angular.element('<table></table>');
					headTable.attr('class', iElement.attr('class'));
					headTable.append(thead);

					iElement.before(headTable);

					dummyThead = thead.clone();
					dummyThead.addClass('dummy-thead');
					Array.prototype.slice.call(dummyThead.find('th')).forEach(function emptyHeaderCells(headerCell) {
						angular.element(headerCell).empty();
					});

					scrolltableWrapper = angular.element(scrolltableWrapperTemplate);
					iElement.find('tbody').before(dummyThead);
					iElement.wrap(scrolltableWrapper);
				} else if (doesScroll) {
					doesScroll = false;

					iElement.find('tbody').before(thead);
					headTable.remove();

					scrolltableWrapper.before(iElement);
					scrolltableWrapper.remove();
				}
			}

			unbindToggleStateWatch = scope.$watch(function watchToggleState() {
				return scope.$eval(iAttrs.toggleScrolltable);
			}, toggleScrolltable);

			unbindDestroyListener = scope.$on('$destroy', function $destroyListener() {
				unbindToggleStateWatch();
				unbindDestroyListener();
			});
		},
	};
}

export default ToggleScrolltable;
