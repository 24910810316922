import isEqual from 'lodash/isEqual.js';

class PartnershipAdvertiserFilter {
	/**
	 * @type {Set.<Number>}
	 */
	items;

	/**
	 * @type {String}
	 */
	type;

	/**
	 * @type {String}
	 */
	initialType;

	/**
	 * @type {Set.<Number>}
	 */
	blocked;

	static TYPE = {
		BLACKLIST: 'blacklist',
		WHITELIST: 'whitelist',
	};

	constructor({ advertisers = [], advertiserFilter = PartnershipAdvertiserFilter.TYPE.BLACKLIST, blocked = [] } = {}) {
		this.items = new Set(advertisers);
		this.type = advertiserFilter;
		this.initialType = advertiserFilter;
		this.blocked = new Set(blocked);
	}

	/**
	 * @param {Number[]} advertisers
	 * @param {string} advertiserFilter
	 * @param {Number[]} blocked
	 * @return {PartnershipAdvertiserFilter}
	 */
	static fromIdsAndType(advertisers, advertiserFilter, blocked) {
		return new PartnershipAdvertiserFilter({
			advertisers,
			advertiserFilter,
			blocked,
		});
	}

	resetTypeChanged() {
		this.initialType = this.type;
	}

	/**
	 * @return {boolean}
	 */
	isBlacklist() {
		return this.type === PartnershipAdvertiserFilter.TYPE.BLACKLIST;
	}

	/**
	 * @return {boolean}
	 */
	hasTypeChanged() {
		return this.type !== this.initialType;
	}

	/**
	 * @param {PartnershipAdvertiserFilter} other
	 * @return {boolean}
	 */
	equals(other) {
		return this.type === other.type && isEqual(this.items, other.items) && (this.isBlacklist() || isEqual(this.blocked, other.blocked));
	}

	/**
	 * @return {{advertisers: <Number[]>, advertiserFilter: <string>, blocked: <Number[]>}}
	 */
	toObject() {
		return {
			advertisers: Array.from(this.items),
			advertiserFilter: this.type,
			blocked: this.isBlacklist() ? [] : Array.from(this.blocked),
		};
	}
}

export default PartnershipAdvertiserFilter;
