import ReportColumn from './ReportColumn.js';

class Dimension extends ReportColumn {
	/**
	 * @type {ReportColumn}
	 * @param columnData
	 */
	constructor(columnData) {
		super(columnData);
	}

	/**
	 * @returns {boolean}
	 */
	isDimension() {
		// eslint-disable-line class-methods-use-this
		return true;
	}

	/**
	 * @returns {boolean}
	 */
	isKeyFigure() {
		// eslint-disable-line class-methods-use-this
		return false;
	}

	toObject() {
		const column = {
			name: this.id,
			search: this.search,
		};

		if (!this.filter.isEmpty()) {
			column.filter = this.filter.toObject();
		}

		if (this.isSorted()) {
			column.sort = this.sortDirection;
		}

		return column;
	}
}

export default Dimension;
