function NoSubmit() {
	return function postLink(scope, element) {
		element.bind('keydown keypress', function keydown(event) {
			if (event.which === 13) {
				event.preventDefault();
			}
		});
	};
}

export default NoSubmit;
