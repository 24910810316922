function ClickPreventDefault() {
	return function postLink(scope, iElement) {
		function preventDefault(event) {
			event.preventDefault();
		}

		iElement.on('click', preventDefault);

		const unbindDestroyListener = scope.$on('$destroy', function $destroyListener() {
			iElement.off('click', preventDefault);
			unbindDestroyListener();
		});
	};
}

export default ClickPreventDefault;
