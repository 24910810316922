import NativeTemplate from '../models/NativeTemplate.js';

function NativeTemplates(Api) {
	'ngInject';

	this.ERROR = {
		DUPLICATE_NAME: 90002,
	};

	/**
	 * @type {Map<Number, NativeTemplate>}
	 */
	this.nativeTemplates = new Map();

	/**
	 * @returns {Promise<Map<Number, NativeTemplate>>}
	 */
	this.loadAll = () =>
		Api.get('/api/supply/v1/native-templates').then(({ items = [] }) => {
			this.nativeTemplates = items.reduce((templates, template) => {
				templates.set(template.id, new NativeTemplate(template));
				return templates;
			}, new Map());
		});

	/**
	 * @param {NativeTemplate} template
	 *
	 * @return {Promise<NativeTemplate>}
	 */
	this.create = (template) =>
		Api.post('/api/supply/v1/native-templates', template.toObject()).then((createdTemplate) => {
			this.nativeTemplates.set(createdTemplate.id, new NativeTemplate(createdTemplate));
			return this.nativeTemplates.get(createdTemplate.id);
		});

	/**
	 * @param {NativeTemplate} template
	 *
	 * @return {Promise<NativeTemplate>}
	 */
	this.update = (template) =>
		Api.put(`/api/supply/v1/native-templates/${template.id}`, { ...template.toObject(), ...{ id: undefined } }).then((updatedTemplate) => {
			this.nativeTemplates.set(updatedTemplate.id, new NativeTemplate(updatedTemplate));
			return this.nativeTemplates.get(updatedTemplate.id);
		});

	/**
	 * @param {NativeTemplate} template
	 *
	 * @return {Promise}
	 */
	this.delete = (template) =>
		Api.delete(`/api/supply/v1/native-templates/${template.id}`).then(() => {
			this.nativeTemplates.delete(template.id);
		});

	/**
	 * @param {Number} id
	 * @returns {NativeTemplate|undefined}
	 */
	this.getById = (id) => this.nativeTemplates.get(id);

	/**
	 * @returns {{ id: Number, name: string }[]}
	 */
	this.getAsSelectOptionsList = () =>
		Array.from(this.nativeTemplates.values()).map(({ id, name }) => ({
			id,
			name: `${name} [${id}]`,
		}));
}

export default NativeTemplates;
