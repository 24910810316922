import AdslotDimension from '../models/AdslotDimension.js';

function SelectDimension() {
	'ngInject';

	return {
		require: 'ngModel',
		link: function postLink(scope, iElement, attrs, ngModelCtrl) {
			/**
			 * @type {AdslotDimension} modelValue
			 */
			ngModelCtrl.$formatters.push((modelValue) => {
				return modelValue.custom ? AdslotDimension.CUSTOM_DIMENSION_STRING : modelValue.toString(); // as viewValue
			});

			/**
			 * @type {string} viewValue
			 */
			ngModelCtrl.$parsers.push((viewValue) => {
				return AdslotDimension.fromString(viewValue, false); // as modelValue
			});
		},
	};
}

export default SelectDimension;
