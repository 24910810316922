import ReferrerGroup from '../models/ReferrerGroup.js';

function InventoryFilter(Api) {
	'ngInject';

	this.error = {
		MISSING_GROUP_NAME: 50001,
		MISSING_REFERERS: 50002,
		DUPLICATE_GROUP_NAME: 50003,
		INVALID_EXCLUDED_REFERRERS: 50008,
		INVALID_INCLUDED_REFERRERS: 50009,
	};

	/**
	 * @returns {Promise.<{String: ReferrerGroup}>}
	 */
	this.getReferrerGroups = () =>
		Api.get('/api/supply/v1/referergroups').then((groupsList) =>
			groupsList.reduce((groupsMap, groupData) => {
				const group = new ReferrerGroup(groupData);
				groupsMap[group.id] = group;
				return groupsMap;
			}, {}),
		);

	/**
	 * @param {ReferrerGroup} group the group to be added
	 * @returns {Promise.<ReferrerGroup>}
	 */
	this.addReferrerGroup = (group) => Api.post('/api/supply/v1/referergroups/add', group.toObject()).then((addedGroup) => new ReferrerGroup(addedGroup));

	/**
	 * @param {ReferrerGroup} group the updated referrer group
	 * @returns {Promise.<ReferrerGroup>}
	 */
	this.updateReferrerGroup = (group) =>
		Api.post(`/api/supply/v1/referergroups/${group.id}`, group.toObject()).then((updatedGroup) => new ReferrerGroup(updatedGroup));

	/**
	 * @returns {Promise}
	 */
	this.getActiveReferrerGroups = () => Api.get('/api/supply/v1/referergroups/active');
}

export default InventoryFilter;
