import PartnershipAdvertiserFilter from '../models/PartnershipAdvertiserFilter.js';

function PartnershipFilter(Api) {
	'ngInject';

	/**
	 * @param {Number} id of the partnership to load the filter for
	 * @returns {Promise.<{ filter: <PartnershipAdvertiserFilter>, active: <Array.<Number>>, available: <Array.<Number>>, blocked: <Array.<Number>> }>}
	 */
	this.load = (id) =>
		Api.get(`/api/supply/v1/partnerships/${id}/filters`).then((filterData) => ({
			filter: new PartnershipAdvertiserFilter(filterData),
			active: filterData.active,
			available: filterData.available,
			blocked: filterData.blocked,
		}));

	/**
	 * @param {Number} partnershipId
	 * @param {PartnershipAdvertiserFilter} filter
	 * @returns {Promise.<{ filter: <PartnershipAdvertiserFilter>, active: <Array.<Number>>, available: <Array.<Number>>, blocked: <Array.<Number>> }>}
	 */
	this.update = (partnershipId, filter) =>
		Api.post(`/api/supply/v1/partnerships/${partnershipId}/filters`, filter.toObject()).then((filterData) => ({
			filter: new PartnershipAdvertiserFilter(filterData),
			active: filterData.active,
			available: filterData.available,
			blocked: filterData.blocked,
		}));

	/**
	 * @param {Number} id
	 * @return {Promise.<Array.<{id: Number, name: String, blockedBySupply: Number[], blockedByPartnership: Number[], blockedByAdslot: Number[]}>>}
	 */
	this.getBlockedAdvertisers = (id) => Api.get(`/api/supply/v1/partnerships/${id}/blocked-advertisers`).then(({ items = [] } = {}) => items);
}

export default PartnershipFilter;
