function LogoutController($rootScope, AUTH_EVENTS, AuthService, $state, InfoService, Matomo, toaster) {
	'ngInject';

	AuthService.logout().then(function success() {
		$rootScope.$emit(AUTH_EVENTS.logoutSuccess);

		AuthService.dlo();

		Matomo.resetUserId();

		InfoService.setLoggedIn(false);
		InfoService.setImpersonated(false);

		toaster.clear();
		toaster.clear('sticky');

		// tell auth service about the expired session to make sure we can go to the login page
		AuthService.setSessionExpired();
		$state.go('login', {}, { location: 'replace' });
	});
}

export default LogoutController;
