import AD_TYPE from './AdslotAdType.js';
import PLATFORM_TYPE from './AdslotPlatformType.js';

import AdslotDimension, { PREDEFINED_DIMENSIONS } from '../models/AdslotDimension.js';
import AdslotFormat from './AdslotFormat.js';
import InventoryType from './InventoryType.js';

// Has to contain all valid combinations.
const INVENTORY_TYPES = {
	WEB_BANNER: new InventoryType(AD_TYPE.BANNER, PLATFORM_TYPE.WEB),
	MOBILE_WEB_BANNER: new InventoryType(AD_TYPE.BANNER, PLATFORM_TYPE.MOBILE_WEB),
	MOBILE_APP_BANNER: new InventoryType(AD_TYPE.BANNER, PLATFORM_TYPE.MOBILE_APP),
	ADDRESSABLE_TV_BANNER: new InventoryType(AD_TYPE.BANNER, PLATFORM_TYPE.ADDRESSABLE_TV),
	CONNECTED_TV_BANNER: new InventoryType(AD_TYPE.BANNER, PLATFORM_TYPE.CONNECTED_TV),

	WEB_VIDEO: new InventoryType(AD_TYPE.VIDEO, PLATFORM_TYPE.WEB),
	MOBILE_WEB_VIDEO: new InventoryType(AD_TYPE.VIDEO, PLATFORM_TYPE.MOBILE_WEB),
	MOBILE_APP_VIDEO: new InventoryType(AD_TYPE.VIDEO, PLATFORM_TYPE.MOBILE_APP),
	ADDRESSABLE_TV_VIDEO: new InventoryType(AD_TYPE.VIDEO, PLATFORM_TYPE.ADDRESSABLE_TV),
	CONNECTED_TV_VIDEO: new InventoryType(AD_TYPE.VIDEO, PLATFORM_TYPE.CONNECTED_TV),
	DOOH_VIDEO: new InventoryType(AD_TYPE.VIDEO, PLATFORM_TYPE.DIGITAL_OUT_OF_HOME),

	WEB_AUDIO: new InventoryType(AD_TYPE.AUDIO, PLATFORM_TYPE.WEB),
	MOBILE_WEB_AUDIO: new InventoryType(AD_TYPE.AUDIO, PLATFORM_TYPE.MOBILE_WEB),
	MOBILE_APP_AUDIO: new InventoryType(AD_TYPE.AUDIO, PLATFORM_TYPE.MOBILE_APP),

	WEB_NATIVE: new InventoryType(AD_TYPE.NATIVE, PLATFORM_TYPE.WEB),
	MOBILE_WEB_NATIVE: new InventoryType(AD_TYPE.NATIVE, PLATFORM_TYPE.MOBILE_WEB),
	MOBILE_APP_NATIVE: new InventoryType(AD_TYPE.NATIVE, PLATFORM_TYPE.MOBILE_APP),
};

const UNKNOWN_INVENTORY_TYPE = 'UNKNOWN';

const MANDATORY_COMMON = ['adType', 'platformType', 'name'];
const MANDATORY_MOBILE_APP = ['mobileAppName', 'mobileBundleName', 'mobileOs', 'storeUrl'];
const MANDATORY_CONNECTED_TV = ['mobileAppName', 'mobileBundleName', 'storeUrl'];
const MANDATORY_MEDIA = ['mimes', 'protocols'];
const MANDATORY_NATIVE = ['placementType'];

// Fields that are mandatory (for the given inventory type) when creating NEW adslots
const MANDATORY_FIELDS = {
	WEB_BANNER: [...MANDATORY_COMMON, 'formats', 'url'],
	MOBILE_WEB_BANNER: [...MANDATORY_COMMON, 'formats', 'url'],
	MOBILE_APP_BANNER: [...MANDATORY_COMMON, 'formats', ...MANDATORY_MOBILE_APP],
	ADDRESSABLE_TV_BANNER: [...MANDATORY_COMMON, 'formats', 'url'],
	CONNECTED_TV_BANNER: [...MANDATORY_COMMON, 'formats', ...MANDATORY_CONNECTED_TV],

	WEB_VIDEO: [...MANDATORY_COMMON, 'formats', 'url', 'startDelay', ...MANDATORY_MEDIA],
	MOBILE_WEB_VIDEO: [...MANDATORY_COMMON, 'formats', 'url', 'startDelay', ...MANDATORY_MEDIA],
	MOBILE_APP_VIDEO: [...MANDATORY_COMMON, 'formats', 'startDelay', ...MANDATORY_MOBILE_APP, ...MANDATORY_MEDIA],
	ADDRESSABLE_TV_VIDEO: [...MANDATORY_COMMON, 'formats', 'url', ...MANDATORY_MEDIA],
	CONNECTED_TV_VIDEO: [...MANDATORY_COMMON, 'formats', 'startDelay', ...MANDATORY_CONNECTED_TV, ...MANDATORY_MEDIA],
	DOOH_VIDEO: [...MANDATORY_COMMON, 'formats', 'url', ...MANDATORY_MEDIA],

	WEB_AUDIO: [...MANDATORY_COMMON, 'url', ...MANDATORY_MEDIA],
	MOBILE_WEB_AUDIO: [...MANDATORY_COMMON, 'url', ...MANDATORY_MEDIA],
	MOBILE_APP_AUDIO: [...MANDATORY_COMMON, ...MANDATORY_MOBILE_APP, ...MANDATORY_MEDIA],

	WEB_NATIVE: ['name', 'formats', 'url', ...MANDATORY_NATIVE],
	MOBILE_WEB_NATIVE: ['name', 'formats', 'url', ...MANDATORY_NATIVE],
	MOBILE_APP_NATIVE: ['name', 'formats', ...MANDATORY_MOBILE_APP, ...MANDATORY_NATIVE],

	UNKNOWN: [...MANDATORY_COMMON],
};

const ALLOWED_COMMON = ['adType', 'platformType', 'name', 'categories', 'visible', 'auctionType', 'formats'];
const ALLOWED_MEDIA = ['minDuration', 'maxDuration', 'minBitrate', 'maxBitrate', ...MANDATORY_MEDIA];

// Fields that are allowed (for the given inventory type) - applies for both creation & update.
const ALLOWED_FIELDS = {
	WEB_BANNER: [...ALLOWED_COMMON, 'url', 'overrideReferer', 'position'],
	MOBILE_WEB_BANNER: [...ALLOWED_COMMON, 'url', 'overrideReferer', 'position'],
	MOBILE_APP_BANNER: [...ALLOWED_COMMON, 'position', ...MANDATORY_MOBILE_APP, 'api'],
	ADDRESSABLE_TV_BANNER: [...ALLOWED_COMMON, 'url', 'overrideReferer'],
	CONNECTED_TV_BANNER: [...ALLOWED_COMMON, 'position', ...MANDATORY_CONNECTED_TV, 'api'],

	WEB_VIDEO: [
		...ALLOWED_COMMON,
		'url',
		'overrideReferer',
		'position',
		'startDelay',
		'skippable',
		...ALLOWED_MEDIA,
		'api',
		'playbackMethod',
		'videoPlacementType',
	],
	MOBILE_WEB_VIDEO: [
		...ALLOWED_COMMON,
		'url',
		'overrideReferer',
		'position',
		'startDelay',
		'skippable',
		...ALLOWED_MEDIA,
		'api',
		'playbackMethod',
		'videoPlacementType',
	],
	MOBILE_APP_VIDEO: [
		...ALLOWED_COMMON,
		'position',
		...MANDATORY_MOBILE_APP,
		'startDelay',
		'skippable',
		...ALLOWED_MEDIA,
		'api',
		'playbackMethod',
		'videoPlacementType',
	],
	ADDRESSABLE_TV_VIDEO: [
		...ALLOWED_COMMON,
		'url',
		'overrideReferer',
		'startDelay',
		'skippable',
		...ALLOWED_MEDIA,
		'api',
		'playbackMethod',
		'videoPlacementType',
	],
	CONNECTED_TV_VIDEO: [...ALLOWED_COMMON, ...MANDATORY_CONNECTED_TV, ...ALLOWED_MEDIA, 'api', 'playbackMethod', 'videoPlacementType'],
	DOOH_VIDEO: [...ALLOWED_COMMON, 'url', 'overrideReferer', ...ALLOWED_MEDIA, 'api', 'playbackMethod', 'videoPlacementType'],

	WEB_AUDIO: [...ALLOWED_COMMON, 'url', 'overrideReferer', 'position', ...ALLOWED_MEDIA],
	MOBILE_WEB_AUDIO: [...ALLOWED_COMMON, 'url', 'overrideReferer', 'position', ...ALLOWED_MEDIA],
	MOBILE_APP_AUDIO: [...ALLOWED_COMMON, 'position', ...MANDATORY_MOBILE_APP, ...ALLOWED_MEDIA],

	WEB_NATIVE: [...ALLOWED_COMMON, 'url', 'overrideReferer', 'position', 'nativeTemplateId', ...MANDATORY_NATIVE],
	MOBILE_WEB_NATIVE: [...ALLOWED_COMMON, 'url', 'overrideReferer', 'position', 'nativeTemplateId', ...MANDATORY_NATIVE],
	MOBILE_APP_NATIVE: [...ALLOWED_COMMON, 'position', ...MANDATORY_MOBILE_APP, 'nativeTemplateId', ...MANDATORY_NATIVE],

	UNKNOWN: [],
};

class Adslot {
	/**
	 * @type {number}
	 */
	id = 0;

	/**
	 * @type {string}
	 */
	name = '';

	/**
	 * @type {number}
	 */
	groupId = 0;

	/**
	 * @type {boolean}
	 */
	optimized = false;

	/**
	 * @type {boolean}
	 */
	guaranteed = false;

	/**
	 * @type {Array.<number>}
	 */
	channelId = [];

	/**
	 * @type {Object}
	 */
	filter = {};

	/**
	 * @type {string}
	 */
	url = '';

	/**
	 * Note: This field is an extra addition solely for the pricing manager to be able to display several rows per
	 * format per Adslot. Therefore, it will not be handled in the constructor. See pricing-adslots.controller.js
	 * for usage info.
	 *
	 * @type {undefined|AdslotFormat}
	 */
	format = undefined;

	/**
	 * @type {Array.<AdslotFormat>}
	 */
	formats = [];

	/**
	 * @type {boolean}
	 */
	overrideReferer = false;

	/**
	 * @type {number}
	 */
	platformType = 0;

	/**
	 * @type {number}
	 */
	adType = 0;

	/**
	 * @type {Array.<string>}
	 */
	categories = [];

	/**
	 * @type {Object}
	 */
	fallback = {};

	/**
	 * @type {boolean}
	 */
	overrideFallback = false;

	/**
	 * @type {boolean}
	 */
	visible = false;

	/**
	 * @type {number}
	 */
	minDuration = 0;

	/**
	 * @type {number}
	 */
	maxDuration = 0;

	/**
	 * @type {number|null}
	 */
	minBitrate = null;

	/**
	 * @type {number|null}
	 */
	maxBitrate = null;

	/**
	 * @type {number|null}
	 */
	playbackMethod = null;

	/**
	 * @type {Array.<number>}
	 */
	mimes = [];

	/**
	 * @type {Array.<number>}
	 */
	protocols = [];

	/**
	 * @type {boolean}
	 */
	skippable = false;

	/**
	 * @type {number}
	 */
	startDelay = 0;

	/**
	 * @type {Array.<number>}
	 */
	api = [];

	/**
	 * @type {string}
	 */
	mobileAppName = '';

	/**
	 * @type {string}
	 */
	mobileBundleName = '';

	/**
	 * @type {number}
	 */
	mobileOs = 0;

	/**
	 * @type {string}
	 */
	storeUrl = '';

	/**
	 * @type {number}
	 */
	position = 0;

	/**
	 * @type {number|null}
	 */
	nativeTemplateId = null;

	/**
	 * @type {number}
	 */
	placementType = 0;

	/**
	 * @type {number}
	 */
	auctionType = 0;

	/**
	 * @type InventoryType|undefined
	 */
	inventoryType = undefined;

	/**
	 * @type {number|null}
	 */
	videoPlacementType = null;

	/**
	 * @param {Object} [data]
	 */
	constructor({
		id = 0,
		name = '',
		groupId = 0,
		optimized = false,
		guaranteed = false,
		channelId = [],
		filter = {},
		url = '',
		formats = [],
		overrideReferer = false,
		platformType = 0,
		adType = 0,
		categories = [],
		fallback = {},
		overrideFallback = false,
		visible = false,
		minDuration = 0,
		maxDuration = 0,
		minBitrate = null,
		maxBitrate = null,
		playbackMethod = null,
		mimes = [],
		protocols = [],
		skippable = false,
		startDelay = 0,
		api = [],
		mobileAppName = '',
		mobileBundleName = '',
		mobileOs = 0,
		storeUrl = '',
		position = 0,
		nativeTemplateId = null,
		placementType = 0,
		auctionType = 0,
		videoPlacementType = null,
	}) {
		this.id = id;
		this.name = name;
		this.groupId = groupId;
		this.optimized = optimized;
		this.guaranteed = guaranteed;
		this.channelId = channelId;
		this.filter = filter;
		this.url = url;
		if (formats === null) {
			this.formats = null;
		} else {
			this.formats = formats.map((formatData) => {
				const predefined =
					adType === AD_TYPE.BANNER &&
					AdslotDimension.hasPredefinedDimensions(platformType) &&
					PREDEFINED_DIMENSIONS[platformType].has(`${formatData.width}x${formatData.height}`);
				const dimension = new AdslotDimension(formatData.width, formatData.height, !predefined);

				return AdslotFormat.fromDimension(dimension, formatData.floorPrice);
			});
		}
		this.overrideReferer = overrideReferer;
		this.platformType = platformType;
		this.adType = adType;
		this.categories = categories;
		this.fallback = fallback;
		this.overrideFallback = overrideFallback;
		this.visible = visible;
		this.minDuration = minDuration;
		this.maxDuration = maxDuration;
		this.minBitrate = minBitrate;
		this.maxBitrate = maxBitrate;
		this.playbackMethod = playbackMethod;
		this.mimes = mimes;
		this.protocols = protocols;
		this.skippable = skippable;
		this.startDelay = startDelay;
		this.api = api;
		this.mobileAppName = mobileAppName;
		this.mobileBundleName = mobileBundleName;
		this.mobileOs = mobileOs;
		this.storeUrl = storeUrl;
		this.position = position;
		this.nativeTemplateId = nativeTemplateId;
		this.placementType = placementType;
		this.auctionType = auctionType;
		this.inventoryType = new InventoryType(adType, platformType);
		this.videoPlacementType = videoPlacementType;
	}

	static isPlatformAdTypeCombinationSupported(platformType, adType) {
		return Object.values(INVENTORY_TYPES).some((inventoryType) => inventoryType.getAdType() === adType && inventoryType.getPlatformType() === platformType);
	}

	/**
	 * @returns {boolean}
	 */
	isMultisizeEnabled() {
		return (
			!this.isProgrammaticGuaranteed() &&
			this.isBanner() &&
			new Set([PLATFORM_TYPE.WEB, PLATFORM_TYPE.MOBILE_WEB, PLATFORM_TYPE.MOBILE_APP, PLATFORM_TYPE.CONNECTED_TV]).has(this.platformType)
		);
	}

	/**
	 * @returns {boolean}
	 */
	isMultisize() {
		return this.formats.length > 1;
	}

	/**
	 * @returns {boolean}
	 */
	isProgrammaticGuaranteed() {
		return this.guaranteed;
	}

	/**
	 * @returns {boolean}
	 */
	isBanner() {
		return this.adType === AD_TYPE.BANNER;
	}

	/**
	 * @returns {boolean}
	 */
	isVideo() {
		return this.adType === AD_TYPE.VIDEO;
	}

	/**
	 * @returns {boolean}
	 */
	isAudio() {
		return this.adType === AD_TYPE.AUDIO;
	}

	/**
	 * @returns {boolean}
	 */
	isMobileApp() {
		return this.platformType === PLATFORM_TYPE.MOBILE_APP;
	}

	/**
	 * @returns {boolean}
	 */
	isNative() {
		// used in template html
		return this.adType === AD_TYPE.NATIVE;
	}

	/**
	 * @returns {boolean}
	 */
	isDooh() {
		return this.platformType === PLATFORM_TYPE.DIGITAL_OUT_OF_HOME;
	}

	/**
	 * @returns {boolean}
	 */
	isConnectedTv() {
		return this.platformType === PLATFORM_TYPE.CONNECTED_TV;
	}

	/**
	 * @returns {boolean}
	 */
	isAddressableTv() {
		return this.platformType === PLATFORM_TYPE.ADDRESSABLE_TV;
	}

	/**
	 * @return {InventoryType}
	 */
	getInventoryType() {
		return this.inventoryType;
	}

	/**
	 * Lookup a name (e.g. WEB_BANNER) for the given inventory type
	 */
	getInventoryTypeName() {
		let inventoryTypeName = UNKNOWN_INVENTORY_TYPE;
		Object.entries(INVENTORY_TYPES).forEach(([key, value]) => {
			if (this.inventoryType.equals(value)) {
				inventoryTypeName = key;
			}
		});
		return inventoryTypeName;
	}

	/**
	 * Is a given field mandatory for the inventory type of the adslot?
	 */
	isFieldMandatory(fieldName) {
		let inventoryType = this.getInventoryTypeName();
		return MANDATORY_FIELDS[inventoryType].includes(fieldName);
	}

	isFieldAllowed(fieldName) {
		let inventoryType = this.getInventoryTypeName();
		return ALLOWED_FIELDS[inventoryType].includes(fieldName);
	}

	/**
	 * Check a list of possible fields, if each field is available / valid for the inventory type of the adslot.
	 * If not, set it to null.
	 */
	clearUnavailableFields(fieldsToCheck) {
		let inventoryType = this.getInventoryTypeName();
		if (inventoryType === UNKNOWN_INVENTORY_TYPE) {
			return;
		}
		fieldsToCheck.forEach((fieldName) => {
			if (!ALLOWED_FIELDS[inventoryType].includes(fieldName)) {
				this[fieldName] = null;
			}
		});
	}

	/**
	 * Return an object that can be used for a create / update call.
	 *
	 * @param {Array.<string>} possibleFields
	 * @param {boolean} forUpdate
	 */
	getRequestBody(possibleFields, forUpdate = false) {
		let inventoryType = this.getInventoryTypeName();
		if (inventoryType === UNKNOWN_INVENTORY_TYPE) {
			return {};
		}

		let result = {};
		possibleFields.forEach((fieldName) => {
			if (ALLOWED_FIELDS[inventoryType].includes(fieldName)) {
				if (forUpdate && (fieldName === 'adType' || fieldName === 'platformType')) {
					return; // forEach
				}
				if (fieldName === 'formats') {
					if (!this.formats) {
						result.newFormats = {};
						return; // forEach
					}
					result.newFormats = this.formats.map((format) => format.toObject());
				} else {
					result[fieldName] = this[fieldName];
				}
			}
		});
		if (this.id) {
			result.id = this.id;
		}
		return result;
	}

	/**
	 * @param {AdslotFormat} format
	 * @returns {Adslot}
	 */
	setFormat(format) {
		this.format = format;
		return this;
	}
}

export default Adslot;
