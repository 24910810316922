function DropdownMenu($timeout) {
	'ngInject';

	function anchorAddClass() {
		angular.element(this).parent().addClass('is-active');
	}

	function anchorRemoveClass() {
		angular.element(this).parent().removeClass('is-active');
	}

	return {
		restrict: 'A',
		link: function postLink(scope, iElement) {
			var anchor,
				submenu,
				promise,
				dropdownMenus = iElement[0].querySelectorAll('.menu');

			angular.forEach(dropdownMenus, function each(dropdownMenu) {
				anchor = angular.element(dropdownMenu).parent().find('a');
				submenu = angular.element(dropdownMenu);

				anchor
					.on('click', function addClass() {
						anchorAddClass.apply(this);
					})
					.on('mouseleave', function removeClass() {
						promise = $timeout(anchorRemoveClass.bind(this), 500);
					});

				submenu
					.on('mouseenter', function cancelTimeout() {
						$timeout.cancel(promise);
					})
					.on('mouseleave', function remoceClass() {
						anchorRemoveClass.apply(this);
					});
			});
		},
	};
}

export default DropdownMenu;
