import isUndefined from 'lodash/isUndefined.js';

const ENABLED = 0;
const DISABLED = 1;
const DISABLED_AUTOMATICALLY = 2;

const VALID_STATES = new Set([ENABLED, DISABLED, DISABLED_AUTOMATICALLY]);

class PartnershipState {
	constructor(stateId) {
		this.state = VALID_STATES.has(stateId) ? stateId : DISABLED;
	}

	/**
	 * @returns {Number}
	 */
	getStateId() {
		return this.state;
	}

	/**
	 * @returns {boolean}
	 */
	isEnabled() {
		return this.state === ENABLED;
	}

	/**
	 * @returns {boolean}
	 */
	isDisabled() {
		return this.state === DISABLED;
	}

	/**
	 * @returns {boolean}
	 */
	isDisabledAutomatically() {
		return this.state === DISABLED_AUTOMATICALLY;
	}

	/**
	 * @param {boolean} [enable]
	 * @returns {PartnershipState}
	 */
	toggle(enable = undefined) {
		if (isUndefined(enable)) {
			this.state = this.isEnabled() ? DISABLED : ENABLED;
		} else {
			this.state = enable === true ? ENABLED : DISABLED;
		}

		return this;
	}
}

export default PartnershipState;
