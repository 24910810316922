/**
 * @param {function(model, *)} matchingFn
 */
function ListFilterEntry(matchingFn) {
	/**
	 * @type {*}
	 */
	this.model = undefined;

	/**
	 * @type {function(model, *)}
	 */
	this.matchingFn = matchingFn;

	/**
	 * E.g. entry text for boolean entries or array data for object entries.
	 * @type {*}
	 */
	this.entryData = null;
}

/**
 * @param {*} value
 * @returns {ListFilterEntry}
 */
ListFilterEntry.prototype.set = function setModel(value) {
	// eslint-disable-line no-unused-vars
	throw new Error('set() has to be implemented by child class');
};

/**
 * @returns {ListFilterEntry}
 */
ListFilterEntry.prototype.unset = function unset() {
	throw new Error('unset() has to be implemented by child class');
};

/**
 * @returns {boolean}
 */
ListFilterEntry.prototype.isActive = function isActive() {
	throw new Error('isActive() has to be implemented by child class');
};

/**
 * @param {*} entryData
 * @returns {ListFilterEntry}
 */
ListFilterEntry.prototype.setEntryData = function setEntryData(entryData) {
	this.entryData = entryData;
	return this;
};

/**
 * @param {*} listElement
 * @returns {boolean}
 */
ListFilterEntry.prototype.isMatching = function isMatching(listElement) {
	return this.matchingFn.call(null, this.model, listElement);
};

/**
 * @returns Array.<ListFilterEntryUI>
 */
ListFilterEntry.prototype.asUIElements = function asUIElements() {
	throw new Error('asUIElement() has to be implemented by child class');
};

export default ListFilterEntry;
