function ChannelModel() {
	/**
	 * @param {Object} data

	 */
	function Channel(data) {
		/**
		 *
		 * @type {Number}
		 */
		this.id = data.id || -1;

		/**
		 *
		 * @type {String}
		 */
		this.name = data.name || '';

		/**
		 *
		 * @type {Array<Number>|*}
		 */
		this.websites = data.websites || [];
	}

	return Channel;
}

export default ChannelModel;
