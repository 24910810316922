function StoreAsFilter() {
	/**
	 * @param {Array} array
	 * @param {Array} reference
	 * @param {String} fieldName
	 * @returns {Array}
	 */
	return function applyFilter(array, reference, fieldName) {
		reference[fieldName] = array; // eslint-disable-line no-param-reassign

		return array;
	};
}

export default StoreAsFilter;
