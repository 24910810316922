const PROGRESS_TYPE = {
	SAVE: 'save',
	DELETE: 'delete',
};

class InfoService {
	constructor() {
		this.requestInProgress = false;
		this.applicationDataLoadingInProgress = false;
		this.impersonated = false;
		this.loggedIn = false;
		this.modalOpen = false;
	}

	/**
	 * @return {void}
	 */
	startRequest() {
		this.requestInProgress = true;
	}

	/**
	 * @return {void}
	 */
	startSaveRequest() {
		this.requestInProgress = PROGRESS_TYPE.SAVE;
	}

	/**
	 * @return {void}
	 */
	startDeleteRequest() {
		this.requestInProgress = PROGRESS_TYPE.DELETE;
	}

	/**
	 * @return {void}
	 */
	endRequest() {
		this.requestInProgress = false;
	}

	/**
	 * @return {boolean}
	 */
	isRequestInProgress() {
		return !!this.requestInProgress;
	}

	/**
	 * @return {boolean}
	 */
	isSaveInProgress() {
		return this.requestInProgress === PROGRESS_TYPE.SAVE;
	}

	/**
	 * @return {boolean}
	 */
	isDeleteInProgress() {
		return this.requestInProgress === PROGRESS_TYPE.DELETE;
	}

	/**
	 * @return {void}
	 */
	startLoadingAppData() {
		this.applicationDataLoadingInProgress = true;
	}

	/**
	 * @return {void}
	 */
	endLoadingAppData() {
		this.applicationDataLoadingInProgress = false;
	}

	/**
	 * @return {boolean}
	 */
	isAppDataLoading() {
		return !!this.applicationDataLoadingInProgress;
	}

	/**
	 * @returns {void}
	 */
	openModal() {
		this.modalOpen = true;
	}

	/**
	 * @returns {void}
	 */
	closeModal() {
		this.modalOpen = false;
	}

	/**
	 * @returns {boolean}
	 */
	isModalOpen() {
		return this.modalOpen;
	}

	/**
	 * @param {boolean} isImpersonated
	 * @returns {boolean}
	 */
	setImpersonated(isImpersonated) {
		this.impersonated = isImpersonated;
		return isImpersonated;
	}

	/**
	 * @returns {boolean}
	 */
	isImpersonated() {
		return !!this.impersonated;
	}

	/**
	 * @param {boolean} isLoggedIn
	 */
	setLoggedIn(isLoggedIn) {
		this.loggedIn = isLoggedIn;
	}

	/**
	 * @returns {boolean}
	 */
	isLoggedIn() {
		return this.loggedIn;
	}
}

export default InfoService;
