import { startOfDay, subDays } from 'date-fns';

import ReportTemplate from '../../models/ReportTemplate.js';
import ReportPeriod from '../../models/ReportPeriod.js';
import ReportExportSettings from '../../models/ReportExportSettings.js';
import SeparationByHourUtils from '../../utils/separation-by-hour-utils.js';

function ReportsGenerateExportController($rootScope, $scope, API_ERRORS, close, templateId, InfoService, ModalCloser, ReportExports, ReportTemplates, toaster) {
	'ngInject';

	const MAXIMUM_ANALYTICS_REPORT_RANGE_DAYS = 90;
	const MAXIMUM_LOSS_RATE_REPORT_RANGE_DAYS = 30; // It needs to be 30 and not 31 because we don't take hours into account

	const vm = this;

	vm.template = new ReportTemplate(ReportTemplates.getById(templateId).toObject());

	vm.maxDate = vm.template.period.getMaxDate();
	vm.minDate = null;
	if (vm.template.isAnalyticsReport()) {
		vm.minDate = startOfDay(subDays(new Date(), MAXIMUM_ANALYTICS_REPORT_RANGE_DAYS));
	}
	if (vm.template.isLossRateReport()) {
		vm.minDate = startOfDay(subDays(new Date(), MAXIMUM_LOSS_RATE_REPORT_RANGE_DAYS));
	}
	vm.template.period.clampDates(vm.maxDate, vm.minDate);
	vm.filenameAutofill = !vm.template.getFilename();
	if (vm.filenameAutofill) {
		vm.template.setFilename(vm.template.getFilename() || vm.template.name);
	}

	vm.datePresetOptions = vm.template.separateBy.isSeparateByHour()
		? ReportPeriod.getTimePresetSelectSeparatedByHourOptions()
		: ReportPeriod.getTimePresetSelectOptions();
	vm.filetypeOptions = ReportExportSettings.getFiletypeSelectOptions();
	vm.separateByOptions = vm.template.getSeparatedByOptions();
	vm.startHourOptions = vm.template.period.getValidStartHourOptions();
	vm.endHourOptions = vm.template.period.getValidEndHourOptions();

	vm.selectTimePreset = () => {
		vm.maxDate = vm.template.period.getMaxDate();
		vm.template.period.setStartAndEndAccordingToType();
		vm.template.period.clampDates(vm.maxDate);
		vm.template.refreshSeparateByOptions();

		if (vm.template.separateBy.isSeparateByHour() && vm.template.period.isCustom()) {
			vm.template.resetHourSelection();
		}
	};

	vm.onSeparateByChange = () => {
		vm.template.changeSeparateBy();
	};

	vm.onStartDateUpdate = () => {
		vm.template.changeStartDate();
	};

	vm.onEndDateUpdate = () => {
		vm.template.changeEndDate();
	};

	vm.onStartTimeUpdate = () => {
		vm.template.changeStartTime();
	};

	vm.getFormattedHourOption = (hour) => {
		return SeparationByHourUtils.getFormattedHourOption(hour);
	};

	vm.isValidExportName = () => vm.template.exportSettings.isValidFilename();

	vm.isFormValid = () => vm.isValidExportName() && vm.template.period.isValid();

	vm.submit = () => {
		InfoService.startRequest();
		ReportExports.addTemplateToQueue(vm.template)
			// .then() InfoService.endRequest() is called in reports overview controller
			.then((reportId) => closeFn(reportId, 200))
			.catch((error) => {
				InfoService.endRequest();

				if (error.code === API_ERRORS.INVALID_REPORT_DATE) {
					if (vm.template.isAnalyticsReport()) {
						toaster.errorMessage('TEXT.REPORTS_TEMPLATE_DATE_RANGE_INFO_ANALYTICS');
					} else if (vm.template.isLossRateReport()) {
						toaster.errorMessage('TEXT.REPORTS_TEMPLATE_DATE_RANGE_INFO_LOSS_RATE');
					}
				} else {
					toaster.errorMessage('MESSAGE.GENERATE_EXPORT_ERROR');
				}
			});
	};

	const closeFn = (result, delay) => {
		ModalCloser.close(close, result, delay);
	};

	vm.cancel = () => {
		closeFn(undefined, 200);
	};

	const unregisterSessionTimeoutListener = $rootScope.$on('event:auth-loginRequired', vm.cancel);

	$scope.$on('$destroy', unregisterSessionTimeoutListener);
}

export default ReportsGenerateExportController;
