class AdvertiserCategory {
	constructor(data = {}) {
		/**
		 *
		 * @type {number}
		 */
		this.id = 0;

		/**
		 *
		 * @type {string}
		 */
		this.name = '';

		if (data) {
			this.id = parseInt(data.id, 10) || 0;
			this.name = data.name || '';
		}
	}
}

export default AdvertiserCategory;
