function ProviderWhitelistRemovalConfirmationController($rootScope, $scope, $log, GoogleProviders, ModalCloser, uncheckedProviders, close) {
	'ngInject';

	let unregisterSessionTimeoutListener,
		vm = this;

	vm.providers = uncheckedProviders;

	vm.close = (result, delay) => {
		ModalCloser.close(close, result, delay);
	};

	vm.ok = () => {
		vm.close([], 100);
	};

	vm.cancel = () => {
		vm.close(undefined, 50);
	};

	unregisterSessionTimeoutListener = $rootScope.$on('event:auth-loginRequired', vm.cancel);

	$scope.$on('$destroy', unregisterSessionTimeoutListener);
}

export default ProviderWhitelistRemovalConfirmationController;
