function Ellipsis() {
	return {
		restrict: 'A',
		link: function postLink(scope, element, attrs) {
			var maxCharLength = attrs.charLimit || 80,
				watcher = scope.$watch(function watch() {
					var text = element.text();
					if (text.length <= maxCharLength) {
						watcher();
						return;
					}
					element.tooltip({
						title: text,
						trigger: 'hover',
						container: element,
					});
					element.text(text.substring(0, maxCharLength) + '...');
					watcher();
				});
		},
	};
}

export default Ellipsis;
