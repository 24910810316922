import { ascByNameField } from '../../../utils/sort-utils.js';

function AdslotListFilterController(AdslotGroups, Adslots, AdslotTranslations, Channels, ListFilterFactory, ListFilterSettings, Sites) {
	'ngInject';

	const vm = this;

	this.$onInit = function $onInit() {
		vm.filter = ListFilterFactory.get(ListFilterFactory.LAYOUTS.ADSLOTS.LIST).load(!vm.filterReset ? ListFilterSettings.get(vm.filterId) : {});

		// update potentially changed entry data
		vm.filter.getGroup('LABEL.SITE').getEntry('siteId').setEntryData(Sites.getAsIdNameObjectsList());
		vm.filter.getGroup('LABEL.GROUP').getEntry('groupId').setEntryData(AdslotGroups.getAsIdNameObjectsList());
		vm.filter.getGroup('LABEL.ADSLOT_PLATFORM_TYPE').getEntry('platformId').setEntryData(AdslotTranslations.getPlatformTypesAsArraySortAscNameField());
		vm.filter.getGroup('LABEL.ADSLOT_AD_TYPE').getEntry('adId').setEntryData(AdslotTranslations.getAdTypesAsArraySortAscNameField());
		vm.filter
			.getGroup('LABEL.ADSLOT_CHANNEL')
			.getEntry('channelId')
			.setEntryData([Channels.WITOUT_CHANNELS_FILTER_ENTRY].concat(Channels.getList().sort(ascByNameField)));
		vm.filter
			.getGroup('LABEL.ADSLOT_FORMAT')
			.getEntry('formatId')
			.setEntryData(
				Adslots.getFormatList().map((format) => ({
					id: format,
					name: format,
				})),
			);

		// export hack
		vm.filterInstance.instance = vm.filter;
		vm.filterInstance.fn = vm.filter.matchesFilter.bind(vm.filter);
	};

	this.$onDestroy = function $onDestroy() {
		ListFilterSettings.set(vm.filterId, vm.filter.getActiveFilterData());
	};
}

export default AdslotListFilterController;
