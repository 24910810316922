import angular from 'angular';

function AdslotCategoriesController($scope, Adslots, Categories, AuthService, toaster, InfoService) {
	'ngInject';

	/**
	 * @param {Array.<String>} categories
	 * @returns {Array.<Object>}
	 */
	function toGridList(categories) {
		var i,
			result = [];

		for (i = 0; i < categories.length; i++) {
			result.push({
				code: categories[i],
				description: Categories.getDescription(categories[i]),
			});
		}

		return result;
	}

	function updateGrids() {
		$scope.selectedData = toGridList($scope.categories);
		$scope.availableData = Categories.getAsList($scope.categories);
	}

	$scope.categories = angular.copy($scope.localModels.adslot.categories);
	$scope.categories$original = angular.copy($scope.categories);

	updateGrids();

	$scope.selectedGridOptions = {
		enableRowSelection: AuthService.isAdmin(),
		data: 'selectedData',
		columnDefs: [
			{
				field: 'description',
				displayName: 'LABEL.CONNECTED',
				headerCellTemplate: '<h2><span data-translate="{{col.displayName}}"></span> <small>{{selectedData.length}}</small></h2>',
				cellTemplate: '<div class="ngCellText selectable" data-ng-class="col.colIndex()"><span>{{row.getProperty(col.field)}}</span></div>',
			},
		],
		headerRowHeight: 42,
		headerRowTemplate:
			'<div data-ng-style="{ height: col.headerRowHeight }" data-ng-repeat="col in renderedColumns" data-ng-class="col.colIndex()" class="ngHeaderCell {{col.headerClass}}" data-ng-header-cell></div>',
		rowHeight: 42,
		rowTemplate: '<div data-ng-repeat="col in renderedColumns" data-ng-class="col.colIndex()" class="ngCell {{col.cellClass}}"><div data-ng-cell></div>',
		multiSelect: false,
		keepLastSelected: false,
		sortInfo: { fields: ['description'], directions: ['asc'] },
		enableSorting: false,
		showFilter: false,
		filterOptions: {
			filterText: '',
			useExternalFilter: false,
		},
		beforeSelectionChange: function beforeSelectionChange(selectedItem) {
			// remove from categories
			var index = $scope.categories.indexOf(selectedItem.entity.code);
			if (index !== -1) {
				$scope.categories.splice(index, 1);
				updateGrids();
			}
		},
	};
	$scope.availableGridOptions = {
		enableRowSelection: AuthService.isAdmin(),
		data: 'availableData',
		columnDefs: [
			{
				field: 'description',
				displayName: 'LABEL.AVAILABLE',
				headerCellTemplate: '<h2><span data-translate="{{col.displayName}}"></span> <small>{{availableData.length}}</small></h2>',
				cellTemplate: '<div class="ngCellText selectable" data-ng-class="col.colIndex()"><span>{{row.getProperty(col.field)}}</span></div>',
			},
		],
		headerRowHeight: 42,
		headerRowTemplate:
			'<div data-ng-style="{ height: col.headerRowHeight }" data-ng-repeat="col in renderedColumns" data-ng-class="col.colIndex()" class="ngHeaderCell {{col.headerClass}}" data-ng-header-cell></div>',
		rowHeight: 42,
		rowTemplate: '<div data-ng-repeat="col in renderedColumns" data-ng-class="col.colIndex()" class="ngCell {{col.cellClass}}"><div data-ng-cell></div>',
		multiSelect: false,
		keepLastSelected: false,
		sortInfo: { fields: ['description'], directions: ['asc'] },
		enableSorting: false,
		showFilter: false,
		filterOptions: {
			filterText: '',
			useExternalFilter: false,
		},
		beforeSelectionChange: function beforeSelectionChange(selectedItem) {
			// add to categories
			if ($scope.categories.indexOf(selectedItem.entity.code) === -1) {
				$scope.categories.push(selectedItem.entity.code);
				updateGrids();
			}
		},
	};

	$scope.save = function save() {
		if (!angular.equals($scope.categories, $scope.categories$original)) {
			InfoService.startRequest();

			Adslots.update($scope.localModels.adslot.id, {
				categories: $scope.categories,
			})
				.then(function success() {
					// update adslots model
					$scope.localModels.adslot.categories = angular.copy($scope.categories);
					$scope.categories$original = angular.copy($scope.categories);

					toaster.successMessage('MESSAGE.ADSLOT_SAVE_SUCCESS');
				})
				.catch(function error() {
					toaster.errorMessage('MESSAGE.ADSLOT_SAVE_ERROR');
				})
				.finally(InfoService.endRequest.bind(InfoService));
		}
	};
}

export default AdslotCategoriesController;
