import { ascByNameField } from '../../utils/sort-utils.js';

function ImpersonateController($scope, $rootScope, Impersonate, UserInfo, Users, InfoService, AUTH_EVENTS, toaster) {
	'ngInject';

	$scope.models = {
		user: UserInfo.getName(),
		usersList: [],
		isImpersonated: InfoService.setImpersonated(UserInfo.isImpersonatedUser()),
		currentlySwitching: false,
	};

	/**
	 * Load list of available users and put them to the scope.
	 */
	$scope.loadUsers = () =>
		Users.supply().then((users) => {
			$scope.models.usersList = users.sort(ascByNameField);
			$rootScope.$broadcast('impersonate-ready');
		});

	/**
	 * Clear input on close to provide a fresh input on every open.
	 */
	$scope.clearInput = () =>
		$scope.safeApply(() => {
			$scope.models.desiredUser = '';
		});

	/**
	 * configure autocomplete to search and suggest users by name.
	 */
	$scope.suggestUsersConfig = {
		suggest(term) {
			return $scope.models.usersList
				.filter(({ name }) => name.toLowerCase().includes(term.toLowerCase()))
				.slice(0, 10)
				.map(({ name }) => ({ label: name, value: name }));
		},
		on_select(item) {
			$scope.switchUser(item.label);
		},
	};

	$scope.switchUser = (name) => {
		$scope.models.currentlySwitching = true;

		Impersonate.as(name)
			.then(() => {
				$rootScope.$broadcast(AUTH_EVENTS.userImpersonated, true);
				$scope.models.currentlySwitching = false;
			})
			.catch((errorObject) => {
				let errorMsg = 'MESSAGE.IMPERSONATE_USER_UNKNOWN';
				if (errorObject.code === Impersonate.ERROR.SAME_AS_ORIGINAL_USER) {
					errorMsg = 'MESSAGE.IMPERSONATE_USER_SAME_AS_ORIGINAL';
				}
				toaster.errorMessage(errorMsg);
				$scope.models.currentlySwitching = false;
			});
	};

	$scope.resetUser = () => {
		$scope.models.currentlySwitching = true;

		Impersonate.stop()
			.then(() => {
				$rootScope.$broadcast(AUTH_EVENTS.userImpersonated, false);
				$scope.models.currentlySwitching = false;
			})
			.catch(() => {
				$scope.models.currentlySwitching = false;
			});
	};
}

export default ImpersonateController;
