function PartnershipBidOptionsController(
	$q,
	$scope,
	API_ERRORS,
	resolvedPartnershipBidOptions,
	resolvedYieldprobeParameters,
	PartnershipBidOptions,
	PartnershipYieldprobeParameters,
	globalGroups,
	globallyActiveGroups,
	locallyActiveGroups,
	PartnershipReferrerGroups,
	StringTools,
	toaster,
	InfoService,
) {
	'ngInject';

	var vm = this,
		globallyActiveGroupIds = [],
		partnershipBidOptions = resolvedPartnershipBidOptions;

	function saveBidOptions() {
		var updatedBidOptions = vm.getUpdatedBidOptions(partnershipBidOptions, vm.form);

		if (!angular.equals(updatedBidOptions, {})) {
			InfoService.startRequest();
			return PartnershipBidOptions.update($scope.localModels.partnership.getId(), updatedBidOptions).then(function bidOptionsSuccess() {
				// set currently set bid options as initial to have change detection working for further save requests as well
				partnershipBidOptions = angular.copy(vm.form);
			});
		}

		return $q.when(true);
	}

	function saveYpParameters() {
		var currentYpParameters = vm.yieldprobeParameters.filter(function filter(entry) {
			return entry.name;
		});

		if (!angular.equals(vm.yieldprobeParameters$original, currentYpParameters)) {
			InfoService.startRequest();
			return PartnershipYieldprobeParameters.update($scope.localModels.partnership.getId(), PartnershipYieldprobeParameters.asObject(currentYpParameters)).then(
				function ypParametersSuccess() {
					vm.yieldprobeParameters$original = angular.copy(currentYpParameters);
				},
			);
		}

		return $q.when(true);
	}

	vm.referrerTypes = {
		TRANSPARENT: 'CAPTION.REFERRER_VISIBLE',
		SEMI_TRANSPARENT: 'CAPTION.REFERRER_OVERWRITTEN',
		BLIND: 'CAPTION.REFERRER_HIDDEN',
	};

	vm.partnershipId = $scope.localModels.partnership.getId();
	vm.form = angular.copy(partnershipBidOptions);

	vm.globallyActiveGroups = globallyActiveGroups
		.map(function map(group) {
			// shortcut to remove from locally available groups later
			var groupId = Object.keys(group)[0];
			globallyActiveGroupIds.push(parseInt(groupId, 10));
			return group[groupId];
		})
		.join(', ');

	vm.localGroups = globalGroups.filter(function filter(group) {
		return globallyActiveGroupIds.indexOf(group.id) === -1;
	});

	vm.groupsForm = locallyActiveGroups.reduce(function reduce(groupsForm, group) {
		groupsForm[group.id] = true;
		return groupsForm;
	}, {});

	vm.toggleGroupInProgress = undefined;

	vm.yieldprobeParameters = resolvedYieldprobeParameters;
	vm.yieldprobeParameters$original = angular.copy(vm.yieldprobeParameters).filter(function filter(entry) {
		return entry.name;
	});

	vm.hasReferrerGroups = function hasReferrerGroups() {
		return !!globalGroups.length;
	};

	vm.cleanUrl = function cleanUrl() {
		vm.form.overrideReferer = StringTools.stripProtocol(vm.form.overrideReferer);
	};

	vm.save = function save() {
		saveBidOptions()
			.then(function saveBidOptionsSuccess() {
				return saveYpParameters();
			})
			.then(function saveYpParametersSuccess() {
				if (InfoService.isRequestInProgress()) {
					toaster.successMessage('MESSAGE.PARTNERSHIP_SAVE_SUCCESS');
				}
			})
			.catch(function error() {
				toaster.errorMessage('MESSAGE.PARTNERSHIP_SAVE_ERROR');
			})
			.finally(function eventually() {
				InfoService.endRequest();
			});
	};

	/**
	 * Return bid options that have been changed regarding to the current backend state.
	 *
	 * @param {Object} initialOptions
	 * @param {Object} currentOptions
	 * @returns {Object}
	 */
	vm.getUpdatedBidOptions = function getUpdatedBidOptions(initialOptions, currentOptions) {
		var optionName,
			updatedOptions = {};

		// see what's changed
		for (optionName in currentOptions) {
			if (Object.prototype.hasOwnProperty.call(currentOptions, optionName) && currentOptions[optionName] !== initialOptions[optionName]) {
				updatedOptions[optionName] = currentOptions[optionName];
			}
		}

		// overrideRefer is mandatory in case of SEMI_TRANSPARENT referrer type
		if (updatedOptions.refererType === 'SEMI_TRANSPARENT' && angular.isUndefined(updatedOptions.overrideReferer)) {
			updatedOptions.overrideReferer = currentOptions.overrideReferer || '';
		}

		// overrideRefer remains untouched in case of any other referrer type than SEMI_TRANSPARENT
		if (currentOptions.refererType !== 'SEMI_TRANSPARENT') {
			delete updatedOptions.overrideReferer;
		}

		return updatedOptions;
	};

	/**
	 * Add/remove Groups to the current partnership
	 * @param id
	 */
	vm.toggleGroup = function toggleGroup(id) {
		var action;

		// only allow to add/remove one group at the same time
		if (!vm.toggleGroupInProgress) {
			vm.toggleGroupInProgress = id;
			InfoService.startRequest();

			// if new group state is true -> we just added it
			if (vm.groupsForm[id]) {
				action = 'add';
			} else {
				// the other way around, if new state is false
				action = 'remove';
			}

			PartnershipReferrerGroups[action]($scope.localModels.partnership.getId(), id)
				.then(function success() {
					vm.toggleGroupInProgress = undefined;
					toaster.successMessage('MESSAGE.PARTNERSHIP_SAVE_SUCCESS');
				})
				.catch(function error(errorObject) {
					// if something went wrong, revert model state to update ui to match backend state
					vm.groupsForm[id] = !vm.groupsForm[id];

					if (errorObject.code && errorObject.code === API_ERRORS.INVALID_ID) {
						toaster.errorMessage('MESSAGE.INVENTORY_GROUP_INVALID_ID');
					} else {
						toaster.errorMessage('MESSAGE.GENERIC_ERROR_MESSAGE');
					}

					vm.toggleGroupInProgress = undefined;
				})
				.finally(InfoService.endRequest.bind(InfoService));
		}
	};
}

export default PartnershipBidOptionsController;
