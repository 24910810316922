import NativeAsset from './NativeAsset.js';

class NativeAssetData extends NativeAsset {
	static TYPE_ID = {
		DESCRIPTION: 2,
	};

	dataType = 0;

	len = 0;

	constructor(data = {}) {
		super(data);

		const { type, len = 0 } = data.data || {};

		if (!new Set(Object.values(NativeAssetData.TYPE_ID)).has(type)) {
			throw new Error('Not supported. Invalid data asset type provided.');
		}

		this.dataType = type;

		this.len = len;

		this.uiType = NativeAsset.TYPE.DATA_DESCRIPTION;
	}

	/**
	 * @returns {boolean}
	 */
	isData() {
		// eslint-disable-line class-methods-use-this
		return true;
	}

	/**
	 * @returns {{}}
	 */
	toObject() {
		const data = {
			type: this.dataType,
			len: Number(this.len),
		};

		return {
			...super.toObject(),
			data,
		};
	}
}

export default NativeAssetData;
