import Adslot from '../../models/Adslot.js';

function AdslotCsvService(AdslotTranslations, ObjectsHelperService, GenericCsvService) {
	'ngInject';

	let mimesByID = AdslotTranslations.getMimeTypes();
	let mimesByName = ObjectsHelperService.reverseObject(mimesByID);

	/**
	 * Convert a list of string tokens (in the order of our adslot CSV export / import format) to an Adslot object.
	 * This does only data binding and format conversion, no validation or applying default values.
	 */
	function csvTokensToAdslot(tokens, errorFn) {
		let obj = {
			groupId: null,
			id: validateInt(tokens[0], () => errorFn('id')),
			adType: validateInt(tokens[1], () => errorFn('adType')),
			platformType: validateInt(tokens[2], () => errorFn('platformType')),
			name: tokens[3],
			formats: parseFormatStrings(
				tokens[4],
				tokens[5],
				() => errorFn('sizes'),
				() => errorFn('floorPrices'),
			),
			url: tokens[6],
			categories: toStrArray(tokens[7]),
			position: validateInt(tokens[8], () => errorFn('position')),
			visible: validateBool(tokens[9], () => errorFn('visible')),
			mobileAppName: tokens[10],
			mobileBundleName: tokens[11],
			mobileOs: validateInt(tokens[12], () => errorFn('mobileOS')),
			storeUrl: tokens[13],
			startDelay: validateInt(tokens[14], () => errorFn('videoPosition')),
			videoPlacementType: validateInt(tokens[15], () => errorFn('videoPlacementType')),
			playbackMethod: validateInt(tokens[16], () => errorFn('playbackMethod')),
			minDuration: validateInt(tokens[17], () => errorFn('minDuration')),
			maxDuration: validateInt(tokens[18], () => errorFn('maxDuration')),
			mimes: validateMimeStrings(toStrArray(tokens[19]), () => errorFn('mimeTypes')),
			protocols: validateEncodedIntArray(tokens[20], () => errorFn('protocols')),
			skippable: intToBool(validateInt(tokens[21], () => errorFn('skippability'))),
			minBitrate: validateInt(tokens[22], () => errorFn('minBitrate')),
			maxBitrate: validateInt(tokens[23], () => errorFn('maxBitrate')),
			placementType: validateInt(tokens[24], () => errorFn('placementType')),
			nativeTemplateId: validateInt(tokens[25], () => errorFn('nativeTemplateId')),
			api: validateEncodedIntArray(tokens[26], () => errorFn('apiFramework')),
			auctionType: boolToInt(validateBool(tokens[27], () => errorFn('firstprice'))),
			overrideReferer: validateBool(tokens[28], () => errorFn('overrideReferrer')),
			optimized: null,
			guaranteed: null,
			channelId: null,
			filter: null,
			fallback: null,
			overrideFallback: null,
		};
		return new Adslot(obj);
	}

	function validateInt(value, errorFn) {
		if (value === null) {
			return null;
		}
		let result = Number(value);
		if (!isInt(result)) {
			// not an integer
			errorFn();
			return null;
		}
		return result;
	}

	function validateBool(value, errorFn) {
		if (value === null) {
			return null;
		}
		if (value.toLowerCase() === 'true') {
			return true;
		}
		if (value.toLowerCase() === 'false') {
			return false;
		}
		errorFn();
		return null;
	}

	/**
	 * Parse sizes string ('10x10|20x20') and floor price string (either '10|20' or a single price) into a list
	 * of size objects ({width, height, floorPrice}).
	 */
	function parseFormatStrings(sizeString, floorPriceString, sizesErrorFn, floorPricesErrorFn) {
		let sizes = toStrArray(sizeString);
		if (sizes === null || sizes.length === 0) {
			return null; // Count as missing
		}
		let parsedSizes = parseSizes(sizes);
		if (parsedSizes === null) {
			sizesErrorFn();
			return [];
		}

		let floorPrices;
		if (floorPriceString === null) {
			floorPrices = [0]; // Floor prices do have a default value
		} else {
			floorPrices = validateEncodedIntArray(floorPriceString, floorPricesErrorFn);
			if (floorPrices === null) {
				return [];
			}
		}

		if (sizes.length !== floorPrices.length) {
			if (floorPrices.length !== 1) {
				// if number of sizes and floorPrices differ, we consider it an error for the prices
				floorPricesErrorFn();
				return [];
			}
			// if only a single floorPrice is given, this price counts for all sizes
			floorPrices = Array(sizes.length).fill(floorPrices[0]);
		}
		return combineFormats(parsedSizes, floorPrices);
	}

	function parseSizes(sizes) {
		let width;
		let height;
		let hasErrors = false;
		let result = sizes.map((size) => {
			[width, height] = size.split('x', 2);
			if (!isInt(width) || !isInt(height)) {
				hasErrors = true;
				return [];
			}
			return [width, height];
		});
		if (hasErrors) {
			return null;
		}
		return result;
	}

	function combineFormats(sizes, floorPrices) {
		return sizes.map((size, index) => {
			return {
				width: Number(size[0]),
				height: Number(size[1]),
				floorPrice: floorPrices[index],
			};
		});
	}

	/**
	 * ["video/x-flv", "video/mp4"] -> [1, 2]
	 */
	function validateMimeStrings(mimes, errorFn) {
		if (mimes === null) {
			return null;
		}
		let mimeStrings = mimes.map((item) => mimesByName[item]);
		return validateIntArray(mimeStrings, errorFn);
	}

	/**
	 * "1|2|3" -> [1, 2, 3]
	 */
	function validateEncodedIntArray(str, errorFn) {
		if (str === null) {
			return null;
		}
		let strArray = toStrArray(str);
		return validateIntArray(strArray, errorFn);
	}

	/**
	 * ["1", "2", "3"] -> [1, 2, 3]
	 */
	function validateIntArray(intArray, errorFn) {
		let result = intArray.map((value) => (isInt(value) ? Number(value) : null));

		if (result.some((el) => el === null)) {
			errorFn();
			return null;
		}
		return result;
	}

	function toStrArray(str) {
		if (str === null || str === '') {
			return [];
		}
		return str.split('|');
	}

	function intToBool(boolStr) {
		if (boolStr == null) {
			return null;
		}
		return Number(boolStr) !== 0;
	}

	function boolToInt(value) {
		if (value == null) {
			return null;
		}
		return value ? 1 : 0;
	}

	function isInt(n) {
		// works with both string and number
		return !Number.isNaN(n) && n % 1 === 0;
	}

	return {
		parseCSVString: GenericCsvService.parseCSVString,
		csvTokensToAdslot: csvTokensToAdslot,
	};
}

export default AdslotCsvService;
