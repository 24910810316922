import Site from '../../../models/Site.js';
import { ascByNameField } from '../../../utils/sort-utils.js';

function Sites(Api) {
	'ngInject';

	this.ERROR = {
		DUPLICATE_NAME: 10007,
	};

	this.EVENT = {
		UPDATED: 'Sites::updated',
	};

	/**
	 * @type {Object.<int, Site>}
	 */
	this.sites = {};

	/**
	 * @return {promise}
	 */
	this.loadAll = function loadAll() {
		var self = this;
		return Api.get('/api/supply/v1/sites/').then(function success(sites) {
			self.sites = {};

			sites.forEach(function updateSites(site) {
				self.sites[site.id] = new Site(site);
			});
		});
	};

	/**
	 * @param {int} siteId
	 * @param {{}} siteData
	 * @returns {promise}
	 */
	this.update = function update(siteId, siteData) {
		var self = this;
		return Api.post(`api/supply/v1/sites/${siteId}`, siteData).then(function success() {
			angular.extend(self.sites[siteId], siteData);
			return self.sites[siteId];
		});
	};

	/**
	 * @param {Array.<{name: <string>}>} sites
	 */
	this.add = function add(sites) {
		var self = this;
		return Api.post('/api/supply/v1/sites/add', sites).then(function success(addedSites) {
			addedSites.forEach(function updateSites(site) {
				self.sites[site.id] = new Site(site);
			});
		});
	};

	/**
	 * @returns {Object.<int, Site>}
	 */
	this.getMap = function getMap() {
		return this.sites;
	};

	/**
	 * @param {int} id
	 * @returns {Site}
	 */
	this.getById = function getById(id) {
		return this.sites[id];
	};

	/**
	 * @returns {{id: number, name: string}[]}
	 */
	this.getAsIdNameObjectsList = () =>
		Object.values(this.getMap())
			.map(({ id, name }) => ({ id, name }))
			.sort(ascByNameField);

	/**
	 * @returns {Array.<Site>}
	 */
	this.getListAll = function getListAll() {
		var self = this;
		return Object.keys(this.sites).map(function asList(siteId) {
			return self.sites[siteId];
		});
	};

	this.getListAllNoDefault = function getListAllNoDefault() {
		return this.getListAll().filter(function omitDefault(site) {
			return !site.readOnly;
		});
	};

	this.getDefault = () => this.getListAll().filter((site) => site.readOnly)[0];

	/**
	 * @param {string} siteName
	 * @returns {boolean}
	 */
	this.exists = function exists(siteName) {
		var i,
			self = this,
			siteIds = Object.keys(self.sites),
			l = siteIds.length,
			siteNameLowerCase = siteName.toLowerCase();

		for (i = 0; i < l; i++) {
			if (self.sites[siteIds[i]].name.toLowerCase() === siteNameLowerCase) {
				return true;
			}
		}

		return false;
	};
}

export default Sites;
