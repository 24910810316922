import User from '../../models/User.js';

function Users(Api, $q) {
	'ngInject';

	/**
	 * list of all users retrieved by api
	 * @type {User[]}
	 */
	this.users = [];

	/**
	 * Used to store the promise that is loading the users in order to be able to
	 * call load() multiple times and return the promise in case of a request is
	 * currently running.
	 * @type {Promise|null}
	 */
	let usersPromise = null;

	/**
	 * list of all users supply partner users
	 * @type {User[]}
	 */
	this.supplyUsers = [];

	/**
	 * Fetch list of users.
	 *
	 * @return {Promise.<User[]>}
	 */
	this.load = () => {
		if (this.users.length) {
			return $q.when(this.users);
		}

		if (usersPromise == null) {
			usersPromise = Api.get('/api/v1/users').then(({ users = [] }) => {
				this.users = users.map((user) => new User(user));
				return this.users;
			});
		}

		return usersPromise;
	};

	/**
	 * Fetch list of all supply users.
	 * @returns {Promise.<User[]>}
	 */
	this.supply = () => {
		if (this.supplyUsers.length) {
			return $q.when(this.supplyUsers);
		}

		return this.load().then((users) => {
			this.supplyUsers = users.filter((user) => user.isSupplyPartner());
			return this.supplyUsers;
		});
	};
}

export default Users;
