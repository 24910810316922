class AdslotGroup {
	/**
	 * @type {int}
	 */
	id = 0;

	/**
	 * @type {string}
	 */
	name = '';

	/**
	 * @type {int}
	 */
	siteId = 0;

	/**
	 * @type {Array.<int>}
	 */
	adslotIds = [];

	/**
	 * @type {boolean}
	 */
	readOnly = false;

	/**
	 * @type {boolean}
	 */
	isDefault = false;

	/**
	 * @param {Object} [data]
	 */
	constructor(data = {}) {
		// init
		Object.keys(data).forEach((key) => {
			if (key in this) {
				this[key] = data[key];
			}
		});

		this.isDefault = data.default;

		/**
		 * mark default site as read only
		 * @type {boolean}
		 */
		this.readOnly = this.name === 'Default';
	}

	/**
	 * @returns {int}
	 */
	getAdslotCount() {
		return this.adslotIds.length;
	}

	/**
	 * @param {int} adslotId
	 */
	removeAdslot(adslotId) {
		const idx = this.adslotIds.indexOf(adslotId);

		if (idx !== -1) {
			this.adslotIds.splice(idx, 1);
		}
	}

	/**
	 * @param {int} adslotId
	 */
	addAdslot(adslotId) {
		this.adslotIds.push(adslotId);
	}
}

export default AdslotGroup;
