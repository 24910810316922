import ReportColumns from './ReportColumns.js';

function SummaryRowModel(ReportRow) {
	'ngInject';

	/**
	 * SummaryRow
	 */
	function SummaryRow() {
		this.type = 'summary';

		this[ReportColumns.ID.ADSLOT_ID] = '';
		this[ReportColumns.ID.EXTERNAL_ID] = '';
	}

	SummaryRow.prototype = new ReportRow();
	SummaryRow.prototype.constructor = SummaryRow;

	return SummaryRow;
}

export default SummaryRowModel;
