function MatomoService($document, $location, $window, trackingConfig) {
	'ngInject';

	$window._mtm = $window._mtm || []; // eslint-disable-line no-underscore-dangle
	$window._paq = $window._paq || []; // eslint-disable-line no-underscore-dangle

	let isNewVisit = false;

	const getMatomoContainer = () => $window._mtm; // eslint-disable-line no-underscore-dangle

	const getTracker = () => $window._paq; // eslint-disable-line no-underscore-dangle

	this.validateTrackingConfig = (config) => {
		if (config.source === '') {
			// eslint-disable-next-line angular/json-functions
			throw new Error(`Invalid matomo config provided. Please make sure all values are properly set. ${JSON.stringify(config)}`);
		}
	};

	this.init = () => {
		getMatomoContainer().push({
			'mtm.startTime': new Date().getTime(),
			event: 'mtm.Start',
		});
		const g = $document[0].createElement('script');
		const s = $document[0].getElementsByTagName('script')[0];
		g.async = true;
		g.src = trackingConfig.source;
		s.parentNode.insertBefore(g, s);
	};

	function getUrl() {
		// eslint-disable-next-line no-restricted-globals
		let url = '';
		let inId = false;

		// eslint-disable-next-line no-restricted-globals
		for (const char of $location.path()) {
			if (/^([0-9])$/.test(char)) {
				if (!inId) {
					inId = true;
					url += 'id';
				}
			} else {
				inId = false;
				url += char;
			}
		}
		return url;
	}

	this.setUserId = (userId) => getTracker().push(['setUserId', userId]);
	this.resetUserId = () => getTracker().push(['resetUserId']);

	this.setNewVisit = () => {
		getTracker().push(['appendToTrackingUrl', 'new_visit=1']); // forces a new visit
		isNewVisit = true;
	};

	this.trackPageImpression = () => {
		const tracker = getTracker();
		/* tracker methods like "setCustomDimension" should be called before "trackPageView" */
		tracker.push(['setCustomUrl', getUrl()]);
		tracker.push(['trackPageView']);
		tracker.push(['enableLinkTracking']);

		if (isNewVisit) {
			tracker.push(['appendToTrackingUrl', 'new_visit=0']);
			isNewVisit = false;
		}
	};

	return this;
}

export default MatomoService;
