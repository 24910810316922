import DemandPartner from '../models/DemandPartner.js';

function DemandPartners(Api, Account) {
	'ngInject';

	/**
	 * @type {Object.<Number, DemandPartner>}
	 */
	this.demandPartners = {};

	/**
	 * @returns {promise}
	 */
	this.loadAll = () => {
		return Api.get('/api/v1/accounts/demand').then(({ demandPartners = [] }) => {
			this.demandPartners = demandPartners.reduce((demandPartnersMap, demandPartnerData) => {
				const { id } = demandPartnerData;
				const visibilityOverwrite = {};

				if (Account.demandPartnerVisibilityOverwrites.indexOf(id) !== -1) {
					visibilityOverwrite.visible = true;
				}

				demandPartnersMap[id] = new DemandPartner({
					...demandPartnerData,
					...visibilityOverwrite,
				});

				return demandPartnersMap;
			}, {});
		});
	};

	/**
	 * @param {int} id
	 * @returns {DemandPartner}
	 */
	this.getById = function getById(id) {
		return this.demandPartners[id];
	};

	/**
	 * @param {function} [include]
	 * @returns {Array.<DemandPartner>}
	 */
	this.getListAll = function getListAll(include) {
		var self = this,
			list = Object.keys(this.demandPartners).map(function asList(demandId) {
				return self.demandPartners[demandId];
			});

		return angular.isFunction(include) ? list.filter(include) : list;
	};

	/**
	 * @returns {Array.<DemandPartner>}
	 */
	this.getListRtbDC = function getList() {
		return this.getListAll(function visibleEnabledRtbDC(demandPartner) {
			return demandPartner.isVisible() && demandPartner.isEnabled() && (demandPartner.isRtb() || demandPartner.isDC());
		});
	};

	/**
	 * @returns {Array.<{id: <int>, name: <String>, seatId: <String>}>}
	 */
	this.getAsSelectOptionsList = () =>
		Object.values(this.demandPartners)
			.filter(
				(demandPartner) =>
					demandPartner.isVisible() &&
					demandPartner.isEnabled() &&
					!demandPartner.isInternal() &&
					// special: exclude some demand partners from the list, as they are only allowed in some cases
					// Google DBM [G]
					demandPartner.id !== 8899,
			)
			.map(({ id, name, seatId }) => ({ id, name, seatId }));

	/**
	 * @param {int} demandId
	 * @returns {boolean}
	 */
	this.isVisible = function isVisible(demandId) {
		return !!this.demandPartners[demandId] && this.demandPartners[demandId].isVisible();
	};

	this.isDirectConnect = function isDirectConnect(demandId) {
		return !!this.demandPartners[demandId] && this.demandPartners[demandId].isDC();
	};
}

export default DemandPartners;
