import isUndefined from 'lodash/isUndefined.js';
import SelectionService from '../../../modules/utils/SelectionService.js';

function SettingsProviderWhitelistController(
	$q,
	$scope,
	GoogleProviders,
	ListFilterFactory,
	ListFilterSettings,
	InfoService,
	ModalFactory,
	SupplyProviderWhitelist,
	toaster,
	resolvedProviderWhitelist,
) {
	'ngInject';

	const vm = this,
		listFilterId = 'supply-settings-provider-whitelist';

	const toSelectionMap = (list) =>
		list.reduce((selection, providerId) => {
			selection[providerId] = true;
			return selection;
		}, {});

	vm.listFilter = ListFilterFactory.get(ListFilterFactory.LAYOUTS.SETTINGS.PROVIDER_WHITELIST).load(ListFilterSettings.get(listFilterId) || {});

	vm.providers = GoogleProviders.getList();

	vm.initialSelection = resolvedProviderWhitelist;
	vm.selection = new SelectionService(toSelectionMap(resolvedProviderWhitelist));

	vm.showSelected = () => {
		return vm.listFilter.getGroup('LABEL.PROVIDER').getEntry('isChecked').isActive();
	};

	vm.showUnselected = () => {
		return vm.listFilter.getGroup('LABEL.PROVIDER').getEntry('isNotChecked').isActive();
	};

	vm.save = function save() {
		const confirmation = $q.defer();
		const uncheckedVendors = [];

		const providerIds = vm.selection.values();

		vm.initialSelection.forEach((providerId) => {
			const googleProvider = vm.providers.filter((provider) => provider.id === providerId)[0];

			if (!isUndefined(googleProvider) && providerIds.indexOf(providerId) === -1) {
				uncheckedVendors.push(googleProvider.name);
			}
		});

		if (uncheckedVendors.length > 0) {
			ModalFactory.confirmProviderRemovalModal(uncheckedVendors, (result) => {
				if (result) {
					confirmation.resolve(providerIds.concat(result));
				} else {
					InfoService.endRequest();
				}
			});
		} else {
			confirmation.resolve(providerIds);
		}

		InfoService.startRequest();
		confirmation.promise.then((providers) => {
			SupplyProviderWhitelist.update(providers)
				.then(() => {
					toaster.successMessage('MESSAGE.SETTINGS_SAVE_SUCCESS');
					vm.selection = new SelectionService(toSelectionMap(providers));
					vm.initialSelection = providers;
				})
				.catch(() => {
					toaster.errorMessage('MESSAGE.SETTINGS_SAVE_ERROR');
				})
				.finally(InfoService.endRequest.bind(InfoService));
		});
	};

	$scope.$on('$destroy', function $destroyListener() {
		ListFilterSettings.set(listFilterId, vm.listFilter.getActiveFilterData());
	});
}

export default SettingsProviderWhitelistController;
