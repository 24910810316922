import ErrorCollector from '../../modules/utils/ErrorCollector.js';
import jschardet from 'jschardet';
import PartnershipType from '../../models/PartnershipType.js';

class PartnershipField {
	constructor(fieldName, columnName, includeInChecks, getDefaultValue = () => {}) {
		this.fieldName = fieldName;
		this.columnName = columnName;
		this.includeInChecks = includeInChecks;
		this.getDefaultValue = getDefaultValue;
	}
}

const PREFERRED_DEAL_PRIORITY = 10;
const BIAS_FLAT_MAX_VALUE = 500;
const BIAS_PERCENTAGE_MAX_VALUE = 100;
const MARGIN_PERCENTAGE_MIN_VALUE = 0;
const MARGIN_PERCENTAGE_MAX_VALUE = 99;

const CSV_FIELDS_BASE = {
	id: new PartnershipField('id', 'Partnership ID', false),
	name: new PartnershipField('name', 'Partnership Name', true),
	state: new PartnershipField('state', 'Status', true, (DemandPartners, partnership) => {
		if (DemandPartners.isDirectConnect(partnership.getDemandId())) {
			return 1;
		}
		return 0;
	}),
	lastAutomatedDeactivation: new PartnershipField('lastAutomatedDeactivation', 'Last Automated Deactivation', true),
	demandId: new PartnershipField('demandId', 'Demand Partner ID', true),
	demandName: new PartnershipField('demandName', 'Demand Partner Name', false),
	type: new PartnershipField('type', 'Partnership Type', true),
	dealId: new PartnershipField('dealId', 'Deal ID', true),
	dealStart: new PartnershipField('dealDates', 'Deal Start', true),
	dealEnd: new PartnershipField('dealDates', 'Deal End', true),
	priority: new PartnershipField('priority', 'Priority', true, (DemandPartners, partnership) => {
		if (partnership.type === PartnershipType.PREFERRED_DEAL) {
			return PREFERRED_DEAL_PRIORITY;
		}
		return 0;
	}),
	auctionType: new PartnershipField('auctionType', 'Auction Type', true, () => 1),
	floorPrice: new PartnershipField('floorPrice', 'Floor Price', true, () => 0),
	fixedPrice: new PartnershipField('fixedPrice', 'Fixed Price', true, () => 0),
	biasType: new PartnershipField('biasType', 'Bid Bias Type', true, () => 0),
	bias: new PartnershipField('bias', 'Bid Bias Value', false),
	refererType: new PartnershipField('refererType', 'Referrer Type', true, () => 0),
	overrideReferer: new PartnershipField('overrideReferer', 'Overwrite Referrer', false),
	categoryInfoAllowed: new PartnershipField('categoryInfoAllowed', 'Category Info', true, () => true),
	geoLocationAllowed: new PartnershipField('geoLocationAllowed', 'Geolocation', true, () => true),
	billingOver: new PartnershipField('billing', 'Billing Over', true, () => 0), // Default '0' ( Yieldlab)
};

const CSV_FIELDS_NON_YDC = {
	...CSV_FIELDS_BASE,
	activeSizes: new PartnershipField('activeSizes', 'Enabled Sizes', true),
	nonEnabledSizesFromAdslots: new PartnershipField('nonEnabledSizesFromAdslots', 'Non-enabled Sizes from Adslots', true),
	targetingSummary: new PartnershipField('targetingSummary', 'Targeting Summary', false),
};

const CSV_FIELDS_YDC = {
	...CSV_FIELDS_BASE,
	dealTargetingIds: new PartnershipField('dealTargetingIds', 'Deal Targeting', true),
	margin: new PartnershipField('margin', 'Margin', true, () => 0),
};

// Partnership CSV is expected to have these headers in this exact order.
const EXPECTED_HEADERS_NON_YDC = Object.values(CSV_FIELDS_NON_YDC).map((entry) => entry.columnName);
const EXPECTED_HEADERS_YDC = Object.values(CSV_FIELDS_YDC).map((entry) => entry.columnName);

const PARTNERSHIP_FIELDS_TO_CHECK_NON_YDC = Object.values(CSV_FIELDS_NON_YDC)
	.filter((field) => field.includeInChecks)
	.map((field) => field.fieldName);
const PARTNERSHIP_FIELDS_TO_CHECK_YDC = Object.values(CSV_FIELDS_YDC)
	.filter((field) => field.includeInChecks)
	.map((field) => field.fieldName);

const EXCEPTIONS_LOOKUP = {
	4: (errors, rowIndex, errorRecord) => {
		return handleFieldError(errors, rowIndex, errorRecord);
	},
	10005: (errors, rowIndex) => {
		errors.recordLineError(rowIndex, 'MESSAGE.PARTNERSHIP_UPLOAD_ERROR_INVALID_PRICES');
	},
	10021: (errors, rowIndex) => {
		errors.recordLineError(rowIndex, 'MESSAGE.PARTNERSHIP_UPLOAD_ERROR_INVALID_REFERRER_URL');
	},
	30005: (errors, rowIndex, errorRecord) => {
		return handleFieldError(errors, rowIndex, errorRecord);
	},
	30012: (errors, rowIndex) => {
		errors.recordLineError(rowIndex, 'MESSAGE.PARTNERSHIP_UPLOAD_ERROR_DUPLICATE_DEAL_ID');
	},
	30015: (errors, rowIndex) => {
		errors.recordLineError(rowIndex, 'MESSAGE.MARGIN_VALUE_NOT_IN_RANGE');
	},
	30017: (errors, rowIndex) => {
		errors.recordLineError(rowIndex, 'MESSAGE.AUTOCONFIGURED_DC_PARTNERSHIP_WITHOUT_VALID_DEAL_TARGETING');
	},
	30020: (errors, rowIndex) => {
		errors.recordLineError(rowIndex, 'MESSAGE.CANT_SET_PARTNERSHIP_PRIORITY_FOR_PREFERRED_DEALS');
	},
	30037: (errors, rowIndex) => {
		errors.recordLineError(rowIndex, 'MESSAGE.DEMANDPARTNER_ID_NOT_EDITABLE');
	},
	30038: (errors, rowIndex) => {
		errors.recordLineError(rowIndex, 'MESSAGE.NOT_ALLOWED_TO_CHANGE_AUCTION_TYPE');
	},
	30039: (errors, rowIndex) => {
		errors.recordLineError(rowIndex, 'MESSAGE.INVALID_PARTNERSHIP_DEAL_TARGETING_ID');
	},
	30040: (errors, rowIndex) => {
		errors.recordLineError(rowIndex, 'MESSAGE.CANT_SET_MARGIN');
	},
	30041: (errors, rowIndex) => {
		errors.recordLineError(rowIndex, 'MESSAGE.CANT_SET_MARGIN_FOR_PROGRAMMATIC_TYPE');
	},
	30042: (errors, rowIndex) => {
		errors.recordLineError(rowIndex, 'MESSAGE.DISABLED_PARTNERSHIP_DEAL_TARGETING');
	},
	30043: (errors, rowIndex) => {
		errors.recordLineError(rowIndex, 'MESSAGE.EXISTING_DEAL_TARGETING_TOO_COMPLEX');
	},
	50018: (errors, rowIndex) => {
		errors.recordLineError(rowIndex, 'MESSAGE.PARTNERSHIP_ACTIVE_SIZES_AMOUNT_EXCEEDED');
	},
	50020: (errors, rowIndex) => {
		errors.recordLineError(rowIndex, 'MESSAGE.PARTNERSHIP_UPLOAD_ERROR_INVALID_DIMENSION');
	},
	50021: (errors, rowIndex) => {
		errors.recordLineError(rowIndex, 'MESSAGE.PARTNERSHIP_ACTIVE_SIZES_CHANGE_NOT_ALLOWED');
	},
	50022: (errors, rowIndex) => {
		errors.recordLineError(rowIndex, 'MESSAGE.PARTNERSHIP_ACTIVE_SIZES_CONTAIN_NULL');
	},
	50024: (errors, rowIndex) => {
		errors.recordLineError(rowIndex, 'MESSAGE.PARTNERSHIP_ACTIVE_SIZES_INVALID');
	},
};

const handleFieldError = (errors, rowIndex, errorRecord) => {
	if (Object.prototype.hasOwnProperty.call(errorRecord, 'field')) {
		let fieldName = errorRecord.field.split('.').pop();

		errors.recordError(rowIndex, fieldName, 'MESSAGE.PARTNERSHIP_UPLOAD_ERROR_INVALID');
	} else {
		errors.recordError(rowIndex, null, errorRecord.errorMessage);
	}
};

function UploadPartnershipCSVController(
	$rootScope,
	$scope,
	$filter,
	$q,
	$timeout,
	close,
	ModalCloser,
	Partnerships,
	changed,
	DemandPartners,
	PartnershipsCsvService,
	PartnershipBidOptions,
	InfoService,
	ObjectsHelperService,
	Account,
) {
	'ngInject';

	let vm = this;
	let unbindAuthListener;
	let unbindDestroyListener;

	let errors = new ErrorCollector();
	let fileHasBeenParsed = false;

	let csvRowsByActionMeta = [];
	let updatePartnershipFailed = false;
	let changedPartnerships = [];
	let newPartnerships = [];
	let partnershipSubmitted = false;
	let rejectedDealIds = new Map();

	// ydc / non-ydc hybrid accounts i.e. yl@smartstream (2172217) use non-ydc files
	let isYDCFile = Account.isDirectConnect() && !Account.isAllowedToCreateAdslots();
	const [EXPECTED_HEADERS, PARTNERSHIP_FIELDS_TO_CHECK, CSV_FIELDS] = isYDCFile
		? [EXPECTED_HEADERS_YDC, PARTNERSHIP_FIELDS_TO_CHECK_YDC, CSV_FIELDS_YDC]
		: [EXPECTED_HEADERS_NON_YDC, PARTNERSHIP_FIELDS_TO_CHECK_NON_YDC, CSV_FIELDS_NON_YDC];

	vm.chosenFile = null; // bound to the file picker
	vm.save = save;
	vm.clearFileSelection = clearFileSelection;
	vm.close = debounceClose;
	vm.onFileChosen = () => {
		fileHasBeenParsed = false;
		partnershipSubmitted = false;
		csvRowsByActionMeta = [];
		vm.totalCSVChangedPartnerships = 0;
		vm.totalCSVNewPartnerships = 0;
		updatePartnershipFailed = false;

		clearErrors();
	};
	vm.isFileChosen = () => vm.chosenFile !== null;
	vm.shouldShowSummary = () => fileHasBeenParsed && !InfoService.isRequestInProgress();
	vm.canSubmit = () => vm.isFileChosen() && !partnershipSubmitted;

	vm.isUpdatePartnershipFailed = () => updatePartnershipFailed;

	vm.totalCSVChangedPartnerships = 0;
	vm.totalCSVNewPartnerships = 0;

	vm.errorText = '';
	vm.warningText = '';
	vm.errorsExists = () => errors.errorsExists();
	vm.canDisplaySummary = () => errors.isTimeoutError() === false;
	vm.warningsExists = () => rejectedDealIds.size > 0;

	vm.uploadWarningMessageTimer = null;
	vm.showUploadWarningMessage = false;
	vm.shouldShowUploadWarningMessage = () => vm.showUploadWarningMessage && InfoService.isRequestInProgress();

	function save() {
		partnershipSubmitted = true;
		InfoService.startRequest();

		vm.uploadTimerWarningMessage = $timeout(() => {
			vm.showUploadWarningMessage = true;
		}, 5000); // If the upload takes longer than 5 sec show the warning message

		readAndParseFile()
			.then(() => sendUpdatedPartnerships())
			.then(() => sendNewPartnerships())
			.catch(() => {})
			.finally(() => {
				if (vm.uploadTimerWarningMessage != null) {
					$timeout.cancel(vm.uploadTimerWarningMessage);
					vm.uploadTimerWarningMessage = null;
					vm.showUploadWarningMessage = false;
				}

				createSummaryData();
				setWarningsText();

				InfoService.endRequest();

				if (changedPartnerships.length || newPartnerships.length) {
					$rootScope.$emit(Partnerships.EVENTS.PARTNERSHIP_RECREATED);
				}
				// After all, promises are resolved the summary might be hidden, to force display trigger "update bindings"
				$scope.$apply();
			});
	}

	async function sendUpdatedPartnerships() {
		if (InfoService.isRequestInProgress() && !vm.errorsExists()) {
			let requestBody = changedPartnerships.map((x) => x.partnership.getRequestBody(true));

			if (requestBody.length) {
				await Partnerships.patchUpdate(requestBody, true)
					.then((responseObject) => {
						handleApiRequestResponseSuccess(responseObject, false);
					})
					.catch((errorObject) => {
						handleApiRequestResponseError(errorObject, requestBody, false);
						setErrorText();
						return Promise.reject();
					});
			}
		}
	}

	async function sendNewPartnerships() {
		if (InfoService.isRequestInProgress() && !vm.errorsExists()) {
			let requestBody = newPartnerships.map((x) => x.partnership.getRequestBody());

			if (requestBody.length) {
				await Partnerships.addPartnerships(requestBody, true)
					.then((responseObject) => {
						handleApiRequestResponseSuccess(responseObject, true);
					})
					.then(() => InfoService.endRequest())
					.catch((errorObject) => {
						handleApiRequestResponseError(errorObject, requestBody, true);
						setErrorText();
						return Promise.reject();
					});
			}
		}
	}

	function handleApiRequestResponseSuccess(responseObject, isCreate) {
		let i = 0;
		responseObject.forEach((p) => {
			let lineNumber;
			if (isCreate) {
				lineNumber = newPartnerships[i++].line;
			} else {
				lineNumber = changedPartnerships.find((obj) => obj.partnership.id === p.id).line;
			}
			if (p.rejectedDealIds) {
				rejectedDealIds.set(lineNumber, p.rejectedDealIds.split(','));
			}
		});
	}

	function handleApiRequestResponseError(errorObject, partnershipsFromRequest, isCreate) {
		if (!Object.prototype.hasOwnProperty.call(errorObject, 'errors')) {
			errors.recordGlobalError(errorObject);
			return;
		}

		let errorsList;
		if (errorObject.attributes && Object.keys(errorObject.attributes).length > 0) {
			errorsList = errorObject.attributes;
		} else {
			errorsList = errorObject.errors;
		}

		for (const errorItemIndex in errorsList) {
			if (Object.prototype.hasOwnProperty.call(errorsList, errorItemIndex)) {
				const rowIndex = isCreate ? newPartnerships[errorItemIndex].line : changedPartnerships[errorItemIndex].line;
				const errorItems = errorObject.attributes ? errorsList[errorItemIndex].errors : errorsList;

				recordApiRequestResponseError(partnershipsFromRequest, errorItemIndex, rowIndex, errorItems);
			}
		}
	}

	function recordApiRequestResponseError(partnershipsFromRequest, errorAttributeIndex, rowIndex, errorItems) {
		Object.values(errorItems).forEach((errorItem) => {
			const errorCallBack = EXCEPTIONS_LOOKUP[errorItem.code];
			if (errorCallBack) {
				errorCallBack(errors, rowIndex, errorItem, partnershipsFromRequest, errorAttributeIndex);
			} else {
				errors.recordError(rowIndex, errorItem.errorMessage, 'MESSAGE.PARTNERSHIP_UPLOAD_ERROR_DEFAULT');
			}
		});
	}

	async function readAndParseFile() {
		clearErrors();

		await readFile(vm.chosenFile)
			.catch(() => Promise.reject(Error('MESSAGE.PARTNERSHIP_UPLOAD_ERROR_READFILE')))
			// string -> array[array[string]]
			.then((content) =>
				PartnershipsCsvService.parseCSVString(content, EXPECTED_HEADERS).catch(() => Promise.reject(Error('MESSAGE.PARTNERSHIP_UPLOAD_ERROR_INVALID_HEADER'))),
			)
			// array[array[string]] -> array[row{line,tokens}]
			.then(tokensToRows)
			// array[row{line,tokens}] empty?
			.then(rejectIfEmpty)
			// array[row{line,tokens}] -> array[row{line,partnership}]
			.then(addPartnershipsToRows)
			.then(collectPartnershipsMetaByActionForSummary)
			.then(removeNonExistingPartnershipIDs)
			.then(rejectIfAnyDuplicates)
			.then((rows) =>
				rows.map((row) => {
					fillWithDefaults(row.partnership, recordFieldError(row.line));
					return row;
				}),
			)
			.then((rows) =>
				rows.map((row) => {
					validateNewPartnership(row.partnership, recordFieldError(row.line));
					return row;
				}),
			)
			.then((rows) =>
				rows.map((row) => {
					validateUpdatedPartnership(row.partnership, recordFieldError(row.line));
					return row;
				}),
			)
			.then(removeInvalidRows)
			.then((rows) =>
				rows.map((row) => {
					row.partnership.clearUnavailableFields(PARTNERSHIP_FIELDS_TO_CHECK);
					return row;
				}),
			)
			.then(async (rows) => {
				const asyncFilter = async (arr, predicate) => Promise.all(arr.map(predicate)).then((results) => arr.filter((_v, index) => results[index]));

				changedPartnerships = await asyncFilter(rows, async (row) => {
					if (row.partnership.getId() === null) {
						return false;
					}
					return partnershipHasChanged(row);
				});

				newPartnerships = rows.filter((row) => row.partnership.getId() === null);

				fileHasBeenParsed = true;
				setErrorText();
			})
			.catch((error) => {
				errors.recordGlobalError(error);

				setWarningsText();

				changedPartnerships = [];
				newPartnerships = [];
				rejectedDealIds.clear();

				setErrorText();
				InfoService.endRequest();
			});
	}

	function clearErrors() {
		errors.clearErrors();
		vm.errorText = '';
		vm.warningText = '';
	}

	/**
	 * Translate all collected error codes into proper errors messages and concatenate them to a single string, sorted
	 * by line number.
	 */
	function setErrorText() {
		vm.errorText = errors
			.getLinesWithErrors()
			.sort((a, b) => a - b) // sort by value, not alphabetically
			.flatMap((lineNr) => errors.getErrorsForLine(lineNr))
			.map((error) =>
				$filter('translate')(error.errorType, {
					attribute: error.fieldName,
					row: error.lineNr,
				}),
			)
			.join('\n');
	}

	function setWarningsText() {
		vm.warningText = Array.from(rejectedDealIds.entries())
			.sort((a, b) => a - b)
			.map(([line, ids]) => $filter('translate')('MESSAGE.PARTNERSHIPS_UPLOAD_WARNING_IGNORED_TARGETING_IDS', { row: line, ids: ids.join(', ') }))
			.join('\n');
	}

	/**
	 * Reading a file
	 * @param {Blob} file
	 * @returns {Promise} promise
	 */
	function readFile(file) {
		return file.arrayBuffer().then((buffer) => {
			const byteBuffer = new Uint8Array(buffer);
			const rawString = Uint8ArrayToString(byteBuffer);
			const encoding = jschardet.detect(rawString, {
				detectEncodings: ['windows-1252', 'UTF-8'],
			}).encoding;
			const decoder = new TextDecoder(encoding);
			return decoder.decode(byteBuffer);
		});
	}

	function Uint8ArrayToString(uint8array) {
		// String.fromCharCode(...buffer) will crush the stack
		let rawString = '';
		for (let x of uint8array) {
			rawString += String.fromCharCode(x);
		}
		return rawString;
	}

	/**
	 * Wrap an array of CSV lines into an array of row objects (in order to preserve line number information)
	 */
	function tokensToRows(tokenLines) {
		return (
			tokenLines
				.map((tokens, index) => {
					return {
						line: index + 2, // +1 for header, +1 for line number starting at 1
						tokens: tokens,
					};
				})
				// filter empty lines
				.filter((row) => !isEmptyRow(row))
		);
	}

	function rejectIfEmpty(array) {
		if (array.length === 0) {
			return Promise.reject(Error('MESSAGE.PARTNERSHIP_UPLOAD_ERROR_NO_PARTNERSHIPS'));
		}
		return Promise.resolve(array);
	}

	function isEmptyRow(row) {
		// shouldn't be fully empty row
		let nullValuesInRow = row.tokens.filter((csvCell) => !csvCell || !csvCell.trim());
		let allColumnsNull = nullValuesInRow.length === row.tokens.length;

		return !row || !row.tokens || row.tokens.length === 1 || allColumnsNull;
	}

	function rejectIfAnyDuplicates(rows) {
		let duplicates = false;
		let partnershipIds = [];
		rows
			.filter((row) => row.partnership.getId())
			.forEach((row) => {
				if (partnershipIds.includes(row.partnership.getId())) {
					errors.recordLineError(row.line, 'MESSAGE.PARTNERSHIP_UPLOAD_ERROR_DUPLICATE_PARTNERSHIP_ID');
					duplicates = true;
				} else {
					partnershipIds.push(row.partnership.getId());
				}
			});
		if (duplicates) {
			setErrorText();
			return Promise.reject();
		}
		return Promise.resolve(rows);
	}

	function collectPartnershipsMetaByActionForSummary(rows) {
		rows.forEach((row) => {
			csvRowsByActionMeta.push({
				line: row.line,
				action: row.partnership.id != null ? 'update' : 'create',
			});
		});
		return rows;
	}

	/**
	 * Convert array of CSV tokens into partnership and add the partnership object to the wrapper object
	 */
	function addPartnershipsToRows(rows) {
		return (
			rows
				.map((row) => {
					let parsedPartnership = null;
					if (row.tokens.length !== EXPECTED_HEADERS.length) {
						errors.recordError(row.line, null, 'MESSAGE.PARTNERSHIP_UPLOAD_ERROR_INVALID_NUMBER_OF_FIELDS');
					} else {
						parsedPartnership = PartnershipsCsvService.csvTokensToPartnership(
							row.tokens,
							(columnName) => errors.recordError(row.line, columnName, 'MESSAGE.PARTNERSHIP_UPLOAD_ERROR_INVALID'),
							isYDCFile,
						);
					}
					return {
						line: row.line,
						partnership: parsedPartnership,
					};
				})
				// Not parsable rows result in null, so remove them
				.filter((row) => row.partnership !== null)
		);
	}

	function createSummaryData() {
		vm.totalCSVNewPartnerships = newPartnerships.length;
		vm.totalCSVChangedPartnerships = changedPartnerships.length;
		csvRowsByActionMeta.forEach((item) => {
			if (errors.errorExistsForLine(item.line)) {
				if (item.action !== 'create') {
					updatePartnershipFailed = true;
				}
			}
		});
	}

	function removeInvalidRows(rows) {
		return rows.filter((row) => !errors.errorExistsForLine(row.line));
	}

	function removeNonExistingPartnershipIDs(rows) {
		return rows.map(recordErrorForNonExistingID).filter((row) => row.partnership.getId() === null || Partnerships.getById(row.partnership.getId()));
	}

	function recordErrorForNonExistingID(row) {
		if (row.partnership.getId() !== null && !Partnerships.getById(row.partnership.getId())) {
			errors.recordError(row.line, 'id', 'MESSAGE.PARTNERSHIP_UPLOAD_ERROR_INVALID_ID');
		}
		return row;
	}

	/**
	 * Check, if a partnership has changed. Only consider provided properties that are not null.
	 */
	async function partnershipHasChanged(row) {
		let existingPartnership = Partnerships.getById(row.partnership.getId());
		return PARTNERSHIP_FIELDS_TO_CHECK.some((propertyName) => propertyHasChanged(propertyName, row.partnership, existingPartnership));
	}

	function propertyHasChanged(propertyName, updatedPartnership, existingPartnership) {
		if (propertyName === 'dealDates' && dealDatesNullAndUnchanged(existingPartnership.dealDates, updatedPartnership.dealDates)) {
			// dealDates might not be equal but mean the same
			return false;
		}

		if (valuesAreEmpty(updatedPartnership[propertyName], existingPartnership[propertyName])) {
			// some falsy values mean the same
			return false;
		}

		return !ObjectsHelperService.objectsAreEqual(updatedPartnership[propertyName], existingPartnership[propertyName]);
	}

	function dealDatesNullAndUnchanged() {
		// treat those two as equal:
		// dealDates = null
		// dealDates = {dealStart: null, dealEnd: null}
		// i.e. the existing partnership object for private auctions has dealDates = null
		// updated partnerships should always contain a dealDates object
		return [...arguments].every((dealDates) => !dealDates || !Object.values(dealDates).some((value) => value));
	}

	function valuesAreEmpty() {
		// treat emptyValues as the same value
		let emptyValues = [undefined, null, ''];
		return [...arguments].every((value) => emptyValues.includes(value));
	}

	function fillWithDefaults(partnership, errorFn) {
		function checkProperty(propertyName, isRequired, csvColumnName, fallback) {
			if (partnership[propertyName] === null) {
				if (isRequired) {
					errorFn(csvColumnName, 'MESSAGE.PARTNERSHIP_UPLOAD_ERROR_REQUIRED');
				} else {
					partnership[propertyName] = fallback;
				}
			}
		}

		Object.entries(CSV_FIELDS).forEach(([, field]) => {
			if (field.includeInChecks) {
				checkProperty(field.fieldName, partnership.isFieldMandatory(field.fieldName), field.columnName, field.getDefaultValue(DemandPartners, partnership));
			}
		});
	}

	function validateNewPartnership(partnership, errorFn) {
		if (partnership.getId() !== null) {
			return;
		}

		// mutual (non-specific) YDC and NON_YDC validations
		checkDemand(partnership, errorFn);
		checkOverwriteReferrer(partnership, errorFn);
		checkBilling(partnership, errorFn);
		checkDealDates(partnership, errorFn, true);
		checkDealId(partnership, errorFn);
		checkPrices(partnership, errorFn);
		checkPriority(partnership, errorFn);
		checkState(partnership, errorFn, true);
		checkBias(partnership, errorFn);

		// specific YDC and NON_YDC validations
		specificYdcAndNonYdcValidations(partnership, errorFn, true);
	}

	function validateUpdatedPartnership(partnership, errorFn) {
		if (partnership.getId() === null) {
			return;
		}

		// mutual (non-specific) YDC and NON_YDC validations
		checkCannotEditFieldsInUpdatedPartnership(partnership, errorFn);
		checkName(partnership, errorFn);
		checkDemand(partnership, errorFn);
		checkPrivateAuctionProperties(partnership, errorFn);
		checkOverwriteReferrer(partnership, errorFn);
		checkDealDates(partnership, errorFn);
		checkPrices(partnership, errorFn);
		checkPriority(partnership, errorFn);
		checkState(partnership, errorFn);
		checkBias(partnership, errorFn);

		// specific YDC and NON_YDC validations
		specificYdcAndNonYdcValidations(partnership, errorFn);
	}

	function specificYdcAndNonYdcValidations(partnership, errorFn, isNewPartnership = false) {
		if (isYDCFile) {
			checkPartnershipType(partnership, errorFn);
			checkDealTargetingIds(partnership, errorFn);
			checkMargin(partnership, errorFn, isNewPartnership);
		} else {
			checkPrivateAuctionProperties(partnership, errorFn);
		}
	}

	function checkCannotEditFieldsInUpdatedPartnership(partnership, errorFn) {
		const existingPartnership = Partnerships.getById(partnership.getId());
		if (!ObjectsHelperService.objectsAreEqual(existingPartnership.getType(), partnership.getType())) {
			errorFn('Partnership Type', 'MESSAGE.PARTNERSHIP_UPLOAD_ERROR_CANNOT_EDIT');
		}

		if (!ObjectsHelperService.objectsAreEqual(existingPartnership.getBilling(), partnership.getBilling())) {
			errorFn('Billing Over', 'MESSAGE.PARTNERSHIP_UPLOAD_ERROR_CANNOT_EDIT');
		}

		if (!ObjectsHelperService.objectsAreEqual(existingPartnership.getDemandId(), partnership.getDemandId())) {
			errorFn('Demand Partner ID', 'MESSAGE.PARTNERSHIP_UPLOAD_ERROR_CANNOT_EDIT');
		}
	}

	function checkPrivateAuctionProperties(partnership, errorFn) {
		if (!partnership.isPrivateAuction() || partnership.getId()) {
			return;
		}
		if (
			!partnership.getDealId() &&
			angular.equals(partnership.getDealDates(), {
				dealStart: null,
				dealEnd: null,
			})
		) {
			return;
		}
		errorFn('', 'MESSAGE.PARTNERSHIP_UPLOAD_ERROR_INVALID_DEAL_ID_RUNTIME');
	}

	function checkOverwriteReferrer(partnership, errorFn) {
		if (partnership.isSemiTransparentRefererType()) {
			if (!partnership.getOverrideReferer()) {
				errorFn('Overwrite Referrer', 'MESSAGE.PARTNERSHIP_UPLOAD_ERROR_REQUIRED');
			}
		} else if (partnership.getOverrideReferer()) {
			errorFn('', 'MESSAGE.PARTNERSHIP_UPLOAD_ERROR_REFERRER_URL_NOT_ALLOWED');
		}
	}

	function checkBilling(partnership, errorFn) {
		if (partnership.getBilling() !== 0) {
			errorFn('Billing Over', 'MESSAGE.PARTNERSHIP_UPLOAD_ERROR_INVALID');
		}
	}

	function checkDealId(partnership, errorFn) {
		// dealId should support only alphanumeric chars
		if (!isAlphanumericValue(partnership.dealId)) {
			errorFn('', 'MESSAGE.PARTNERSHIP_UPLOAD_ERROR_NON_ALPHANUMERIC_DEAL_ID');
		}
	}

	function isAlphanumericValue(value) {
		let alphaNumericRegex = /^[a-zA-Z0-9]*$/;
		return value === null || value === '' || alphaNumericRegex.test(value);
	}

	function checkPrices(partnership, errorFn) {
		if (!partnership.fixedPrice && !partnership.floorPrice) {
			return;
		}
		if (!!partnership.fixedPrice && !!partnership.floorPrice) {
			errorFn('', 'MESSAGE.PARTNERSHIP_UPLOAD_ERROR_ONLY_ONE_PRICE_ALLOWED');
			return;
		}
		if (partnership.fixedPrice < 0 || partnership.floorPrice < 0) {
			errorFn('', 'MESSAGE.PARTNERSHIP_UPLOAD_ERROR_NO_NEGATIVE_PRICES');
		}
	}

	function checkPriority(partnership, errorFn) {
		if (partnership.type === PartnershipType.PREFERRED_DEAL && partnership.priority !== PREFERRED_DEAL_PRIORITY) {
			errorFn('', 'MESSAGE.CANT_SET_PARTNERSHIP_PRIORITY_FOR_PREFERRED_DEALS');
		}
	}

	function checkState(partnership, errorFn, isNewPartnership = false) {
		if (isNewPartnership) {
			if (partnership.isDisabledAutomatically()) {
				errorFn('', 'MESSAGE.CANT_SET_DISABLED_AUTOMATICALLY_STATE');
			}
			return;
		}
		const existingPartnership = Partnerships.getById(partnership.getId());
		if (partnership.isDisabledAutomatically() && !existingPartnership.isDisabledAutomatically()) {
			errorFn('', 'MESSAGE.CANT_SET_DISABLED_AUTOMATICALLY_STATE');
		}
	}

	function checkBias(partnership, errorFn) {
		if (
			partnership.biasType === Partnerships.BIAS_TYPE.FLAT &&
			(partnership.bias === null || partnership.bias === '' || partnership.bias < -BIAS_FLAT_MAX_VALUE || partnership.bias > BIAS_FLAT_MAX_VALUE)
		) {
			errorFn('', 'MESSAGE.BIAS_FLAT_VALUE_NOT_IN_RANGE');
		}
		if (
			partnership.biasType === Partnerships.BIAS_TYPE.PERCENTAGE &&
			(partnership.bias === null || partnership.bias === '' || partnership.bias < -BIAS_PERCENTAGE_MAX_VALUE || partnership.bias > BIAS_PERCENTAGE_MAX_VALUE)
		) {
			errorFn('', 'MESSAGE.BIAS_PERCENTAGE_VALUE_NOT_IN_RANGE');
		}
	}

	function checkDealDates(partnership, errorFn, isNewPartnership = false) {
		// Note: some records might not have `dealStart` and `dealEnd`
		// dealDates don't have to be in the future if not changed
		if (isDealEndBeforeDealStart(partnership.getDealDates().dealStart, partnership.getDealDates().dealEnd)) {
			errorFn('', 'MESSAGE.PARTNERSHIP_UPLOAD_ERROR_INVALID_DEAL_START_DATE');
			return;
		}

		if (isNewPartnership) {
			if (isDateInThePast(partnership.getDealDates().dealStart)) {
				errorFn('Deal Start', 'MESSAGE.PARTNERSHIP_UPLOAD_ERROR_INVALID_DEAL_DATE');
			}

			if (isDateInThePast(partnership.getDealDates().dealEnd)) {
				errorFn('Deal End', 'MESSAGE.PARTNERSHIP_UPLOAD_ERROR_INVALID_DEAL_DATE');
			}
			return;
		}

		const existingPartnership = Partnerships.getById(partnership.getId());

		if (!isDateValid(partnership.getDealDates().dealStart, existingPartnership.dealDates.dealStart)) {
			errorFn('Deal Start', 'MESSAGE.PARTNERSHIP_UPLOAD_ERROR_INVALID_DEAL_DATE');
		}

		if (!isDateValid(partnership.getDealDates().dealEnd, existingPartnership.dealDates.dealEnd)) {
			errorFn('Deal End', 'MESSAGE.PARTNERSHIP_UPLOAD_ERROR_INVALID_DEAL_DATE');
		}
	}

	function isDealEndBeforeDealStart(dealStart, dealEnd) {
		if (dealStart === null || dealEnd === null) {
			return false;
		}
		return dealEnd < dealStart;
	}

	function isDateValid(updatedDate, existingDate) {
		if (!isDateInThePast(updatedDate)) {
			return true;
		}
		if (existingDate === null) {
			return false;
		}
		return updatedDate.getTime() === existingDate.getTime();
	}

	function isDateInThePast(date) {
		if (date === null) {
			return false;
		}
		return date < new Date();
	}

	function checkName(partnership, errorFn) {
		if (!partnership.name) {
			errorFn('Partnership Name', 'MESSAGE.PARTNERSHIP_UPLOAD_ERROR_REQUIRED');
		}
	}

	function checkDemand(partnership, errorFn) {
		if (partnership.getDemandId() === null) {
			return;
		}
		const demandPartner = DemandPartners.getById(partnership.getDemandId());
		if (!demandPartner) {
			errorFn('Demand Partner ID', 'MESSAGE.PARTNERSHIP_UPLOAD_ERROR_INVALID');
		}
	}

	function checkPartnershipType(partnership, errorFn) {
		if (partnership.isPrivateAuction()) {
			errorFn('Partnership Type', 'MESSAGE.PARTNERSHIP_UPLOAD_ERROR_INVALID');
		}
	}

	function checkDealTargetingIds(partnership, errorFn) {
		if (partnership.dealTargetingIds === null) {
			return;
		}
		partnership.dealTargetingIds.forEach((dealTargetingId) => {
			if (!isAlphanumericValue(dealTargetingId)) {
				errorFn('', 'MESSAGE.PARTNERSHIP_UPLOAD_ERROR_NON_ALPHANUMERIC_DEAL_TARGETING');
			}
		});
	}

	function checkMargin(partnership, errorFn, isNewPartnership = false) {
		if (isNewPartnership && partnership.margin === MARGIN_PERCENTAGE_MIN_VALUE) {
			return;
		}

		if (!isNewPartnership && partnership.margin === Partnerships.getById(partnership.getId()).margin) {
			return;
		}

		if (!Account.isAllowedToUpdateDealMargin()) {
			errorFn('', 'MESSAGE.CANT_SET_MARGIN');
			return;
		}

		if (partnership.type === PartnershipType.PROGRAMMATIC_GUARANTEED) {
			errorFn('', 'MESSAGE.CANT_SET_MARGIN_FOR_PROGRAMMATIC_TYPE');
			return;
		}

		if (partnership.margin < MARGIN_PERCENTAGE_MIN_VALUE || partnership.margin > MARGIN_PERCENTAGE_MAX_VALUE) {
			errorFn('Margin', 'MESSAGE.MARGIN_VALUE_NOT_IN_RANGE');
		}
	}

	function recordFieldError(line) {
		return (columnName, errorType) => {
			if (!errors.errorExistsForLineAndField(line, columnName)) {
				errors.recordError(line, columnName, errorType);
			}
		};
	}

	function clearFileSelection() {
		vm.chosenFile = null;
		changedPartnerships = [];
		newPartnerships = [];
		clearErrors();
		rejectedDealIds.clear();
	}

	function debounceClose() {
		ModalCloser.close(close, undefined, 0);
	}

	unbindAuthListener = $rootScope.$on('event:auth-loginRequired', vm.close);
	unbindDestroyListener = $scope.$on('$destroy', function destroyListener() {
		unbindAuthListener();
		unbindDestroyListener();
	});
}

export default UploadPartnershipCSVController;
