import Adslot from './../models/Adslot.js';

function PGAdslots(Api) {
	'ngInject';

	/**
	 * @type {Object.<int, Adslot>}
	 */
	this.adslots = {};

	/**
	 * load all adslots list data from api.
	 *
	 * @return {promise}
	 */
	this.loadAll = () => {
		return Api.get('/api/supply/v1/adslots/guaranteed').then((data) => {
			this.adslots = {};

			// store all loaded adslots in local map
			angular.forEach(data.adslots, (value) => {
				this.adslots[value.id] = new Adslot(value);
			});
		});
	};

	/**
	 * @returns {Object.<int, Adslot>}
	 */
	this.getMap = function getMap() {
		return this.adslots;
	};

	/**
	 * @param {int} id
	 * @returns {Adslot}
	 */
	this.getById = function getById(id) {
		return this.adslots[id];
	};

	/**
	 * @returns {Array.<Adslot>}
	 */
	this.getList = function getList() {
		var id,
			list = [];

		for (id in this.adslots) {
			if (Object.prototype.hasOwnProperty.call(this.adslots, id) && this.adslots[id].visible) {
				list.push(this.adslots[id]);
			}
		}

		return list;
	};
}

export default PGAdslots;
