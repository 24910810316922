import toasterTranslateHtml from '../views/toasterTranslate.html';

function ToasterTranslateBody() {
	'ngInject';

	return {
		restrict: 'A',
		template: toasterTranslateHtml,
	};
}

export default ToasterTranslateBody;
