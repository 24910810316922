function BooleanFilter($filter) {
	'ngInject';

	return function boolean(value) {
		if (Object.prototype.toString.call(value) !== '[object Boolean]') {
			return value;
		}
		if (value) {
			return $filter('translate')('LABEL.BOOLEAN_TRUE');
		}
		return $filter('translate')('LABEL.BOOLEAN_FALSE');
	};
}

export default BooleanFilter;
