import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

import DashboardController from './dashboard.controller.js';

const DashboardModule = angular // eslint-disable-line angular/no-service-method
	.module('dashboard', [uiRouter])
	.controller('DashboardController', DashboardController).name;

export default DashboardModule;
