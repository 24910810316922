function TriggerModelUpdateOn($rootScope) {
	'ngInject';

	return function postLink(scope, iElement) {
		var unbindDestroyListener;

		function emitEvent() {
			$rootScope.$emit('yl:FormSubmitted');
		}

		iElement.on('click', emitEvent);

		unbindDestroyListener = scope.$on('$destroy', function $destroyListener() {
			iElement.off('click', emitEvent);
			unbindDestroyListener();
		});
	};
}

export default TriggerModelUpdateOn;
