import ListFilter from './ListFilter.js';

function ListFilterFactory(ListFilterComponents) {
	'ngInject';

	this.LAYOUTS = {
		ADSLOTS: {
			LIST: 'ADSLOTS_LIST',
		},
		PARTNERSHIPS: {
			LIST: 'PARTNERSHIPS_LIST',
			PRICING: 'PARTNERSHIPS_PRICING_LIST',
		},
		SETTINGS: {
			PROVIDER_WHITELIST: 'SETTINGS_PROVIDER_WHITELIST',
		},
	};

	this.LAYOUT = {
		ADSLOTS_LIST: new ListFilter()
			.addGroup(ListFilterComponents.ADSLOT.SITE.key, ListFilterComponents.ADSLOT.SITE)
			.addGroup(ListFilterComponents.ADSLOT.GROUP.key, ListFilterComponents.ADSLOT.GROUP)
			.addGroup(ListFilterComponents.ADSLOT.PLATFORM_TYPE.key, ListFilterComponents.ADSLOT.PLATFORM_TYPE)
			.addGroup(ListFilterComponents.ADSLOT.AD_TYPE.key, ListFilterComponents.ADSLOT.AD_TYPE)
			.addGroup(ListFilterComponents.ADSLOT.AUCTION_TYPE.key, ListFilterComponents.ADSLOT.AUCTION_TYPE)
			.addGroup(ListFilterComponents.ADSLOT.CHANNEL.key, ListFilterComponents.ADSLOT.CHANNEL)
			.addGroup(ListFilterComponents.ADSLOT.FORMAT.key, ListFilterComponents.ADSLOT.FORMAT),
		PARTNERSHIPS_LIST: new ListFilter()
			.addGroup(ListFilterComponents.PARTNERSHIP.STATUS.key, ListFilterComponents.PARTNERSHIP.STATUS)
			.addGroup(ListFilterComponents.PARTNERSHIP.TYPE.key, ListFilterComponents.PARTNERSHIP.TYPE)
			.addGroup(ListFilterComponents.PARTNERSHIP.AUCTION_TYPE.key, ListFilterComponents.PARTNERSHIP.AUCTION_TYPE)
			.addGroup(ListFilterComponents.PARTNERSHIP.BILLING.key, ListFilterComponents.PARTNERSHIP.BILLING),
		PARTNERSHIPS_PRICING_LIST: new ListFilter()
			.addGroup(ListFilterComponents.PARTNERSHIP.STATUS.key, ListFilterComponents.PARTNERSHIP.STATUS)
			.addGroup(ListFilterComponents.PARTNERSHIP.TYPE.key, ListFilterComponents.PARTNERSHIP.TYPE)
			.addGroup(ListFilterComponents.PARTNERSHIP.AUCTION_TYPE.key, ListFilterComponents.PARTNERSHIP.AUCTION_TYPE)
			.addGroup(ListFilterComponents.PARTNERSHIP.DEMAND_PARTNER.key, ListFilterComponents.PARTNERSHIP.DEMAND_PARTNER),
		SETTINGS_PROVIDER_WHITELIST: new ListFilter().addGroup(ListFilterComponents.ENTRY.IS_CHECKED.key, ListFilterComponents.ENTRY.IS_CHECKED),
	};

	// @todo: this should be done better
	this.LAYOUT[this.LAYOUTS.PARTNERSHIPS.LIST].updateActive();
	this.LAYOUT[this.LAYOUTS.PARTNERSHIPS.PRICING].updateActive();
	this.DEFAULT_SETTINGS = {
		PARTNERSHIPS: {
			LIST: this.LAYOUT[this.LAYOUTS.PARTNERSHIPS.LIST].getActiveFilterData(),
			PRICING: this.LAYOUT[this.LAYOUTS.PARTNERSHIPS.PRICING].getActiveFilterData(),
		},
	};

	this.get = function get(layout) {
		return this.LAYOUT[layout];
	};
}

export default ListFilterFactory;
