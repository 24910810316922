function BlackWhiteFilterModel() {
	/**
	 * BlackWhiteFilter
	 * @param {{type: <String>, list: <Array.<int>>}} [config]
	 */
	function BlackWhiteFilter(config) {
		/** @type {String} */
		this.FILTER_TYPE_BLACK_LIST = 'blacklist';

		/** @type {String} */
		this.FILTER_TYPE_WHITE_LIST = 'whitelist';

		/**
		 * @type {boolean}
		 */
		this.isWhitelistStatus = false;

		/**
		 * @type {boolean}
		 */
		this.initialState = this.isWhitelistStatus;

		/**
		 * @type {Array}
		 */
		this.blackListIds = [];

		/**
		 * @type {Array}
		 */
		this.whiteListIds = [];

		if (config) {
			this.setType(config.type).setList(config.list).setAsInitialState();
		}
	}

	/**
	 * @param {Array} filterIds List of filter ids to set
	 *
	 * @return {BlackWhiteFilter}
	 */
	BlackWhiteFilter.prototype.setList = function setList(filterIds) {
		if (this.isWhitelistStatus) {
			this.whiteListIds = filterIds || [];
		} else {
			this.blackListIds = filterIds || [];
		}
		return this;
	};

	/**
	 * @return {Array}
	 */
	BlackWhiteFilter.prototype.getList = function getList() {
		return this.isWhitelistStatus ? this.whiteListIds : this.blackListIds;
	};

	/**
	 * @return {boolean} true, if filter id was found in list - otherwise false
	 */
	BlackWhiteFilter.prototype.contains = function contains(filterId) {
		return this.isWhitelistStatus ? this.whiteListIds.indexOf(+filterId) !== -1 : this.blackListIds.indexOf(+filterId) !== -1;
	};

	/**
	 * @param {int} id filter id to be added
	 *
	 * @return {BlackWhiteFilter}
	 */
	BlackWhiteFilter.prototype.addId = function addId(id) {
		if (this.isWhitelistStatus) {
			this.whiteListIds.push(+id);
		} else {
			this.blackListIds.push(+id);
		}
		return this;
	};

	/**
	 * @param {int} id filter id to be removed
	 *
	 * @return {BlackWhiteFilter}
	 */
	BlackWhiteFilter.prototype.removeId = function removeId(id) {
		var index = this.isWhitelistStatus ? this.whiteListIds.indexOf(+id) : this.blackListIds.indexOf(+id);

		if (index !== -1) {
			if (this.isWhitelistStatus) {
				this.whiteListIds.splice(index, 1);
			} else {
				this.blackListIds.splice(index, 1);
			}
		}
		return this;
	};

	/**
	 * @param {string} type filter type as string. Any other input than 'whitelist' will set the filter type to blacklist.
	 *
	 * @return {BlackWhiteFilter}
	 */
	BlackWhiteFilter.prototype.setType = function setType(type) {
		this.isWhitelistStatus = this.FILTER_TYPE_WHITE_LIST === type;

		return this;
	};

	/**
	 * @return {String} filter type as string
	 */
	BlackWhiteFilter.prototype.getType = function getType() {
		return this.isWhitelistStatus ? this.FILTER_TYPE_WHITE_LIST : this.FILTER_TYPE_BLACK_LIST;
	};

	/**
	 * @return {boolean}
	 */
	BlackWhiteFilter.prototype.isWhiteList = function isWhiteList() {
		return this.isWhitelistStatus;
	};

	/**
	 * @return {boolean}
	 */
	BlackWhiteFilter.prototype.isBlackList = function isBlackList() {
		return !this.isWhitelistStatus;
	};

	/**
	 * Define the current filter type as initial state to detect changes later on.
	 * Also clean the filter list that is not currently in use.
	 *
	 * @return {BlackWhiteFilter}
	 */
	BlackWhiteFilter.prototype.setAsInitialState = function setAsInitialState() {
		this.initialState = this.isWhitelistStatus;

		if (this.isWhitelistStatus) {
			this.blackListIds = [];
		} else {
			this.whiteListIds = [];
		}

		return this;
	};

	/**
	 * @return {boolean}
	 */
	BlackWhiteFilter.prototype.hasTypeChanged = function hasTypeChanged() {
		return this.isWhitelistStatus !== this.initialState;
	};

	return BlackWhiteFilter;
}

export default BlackWhiteFilterModel;
