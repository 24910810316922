import AdvertiserFilter from '../../../../models/AdvertiserFilter.js';

function AdslotFilterController($scope, Adslots, resolvedAdvertiserFilter, AuthService, toaster, InfoService, SupplyAdvertisers) {
	'ngInject';

	let initialFilter = resolvedAdvertiserFilter;

	$scope.filter = new AdvertiserFilter(initialFilter.toObject());

	$scope.assignedData = Array.from(initialFilter.items)
		.map((advertiserId) => SupplyAdvertisers.getById(advertiserId))
		.filter((entry) => entry !== undefined); // eslint-disable-line angular/definedundefined
	$scope.availableData = Object.values(SupplyAdvertisers.getMap()).filter((advertiser) => advertiser.enabled);

	$scope.save = function save() {
		const currentFilter = AdvertiserFilter.fromIdsAndType(
			$scope.assignedData.map(({ id }) => id),
			$scope.filter.type,
		);
		const currentFilterDataObject = currentFilter.toObject();

		if (!initialFilter.equals(currentFilter)) {
			InfoService.startRequest();

			Adslots.update($scope.localModels.adslot.id, {
				filter: currentFilter.toOldObject(),
			})
				.then(() => {
					// promote changes to adslots model
					$scope.localModels.adslot.filter = currentFilterDataObject;

					$scope.filter.resetTypeChanged();
					initialFilter = new AdvertiserFilter(currentFilterDataObject);

					toaster.successMessage('MESSAGE.ADSLOT_SAVE_SUCCESS');
				})
				.catch(() => toaster.errorMessage('MESSAGE.ADSLOT_SAVE_ERROR'))
				.finally(() => InfoService.endRequest());
		}
	};
}

export default AdslotFilterController;
