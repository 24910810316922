function ListSearchService() {
	this.searches = {};

	this.get = function get(id) {
		return this.searches[id] || (this.searches[id] = {}); // eslint-disable-line no-return-assign
	};

	this.reset = function reset() {
		this.searches = {};
	};
}

export default ListSearchService;
