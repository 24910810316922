function AppDataLoadingIndicator() {
	return {
		restrict: 'A',
		scope: true,
		replace: true,
		template: [
			'<div class="app-data-loading-container yl-animate fade-in-out delay-in fast-in" data-ng-if="InfoService.isAppDataLoading()">',
			'<div class="loading-circle-container" data-yl-loading-circle></div>',
			'<p class="text-center margin-small-top no-margin-bottom" data-translate="TEXT.LOADING_APP_DATA"></p>',
			'</div>',
		].join(''),
	};
}

export default AppDataLoadingIndicator;
