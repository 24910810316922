import USER_ROLES from './constants/UserRoles.js';

function AppRun(
	$state,
	$transitions,
	$location,
	$rootScope,
	$window,
	Account,
	Matomo,
	ModalFactory,
	UserInfo,
	toaster,
	$translate,
	InfoService,
	API_ERRORS,
	AUTH_EVENTS,
	AuthService,
	isAlreadyAuthenticated,
	Bootstrapper,
) {
	'ngInject';

	const APP_NEEDS_DATA_EVENT = 'APP_NEEDS_DATA';

	$rootScope.year = new Date().getFullYear();
	$rootScope.loginRedirectInProgress = false;
	$rootScope.noFrame = false;
	// for usage in templates only
	$rootScope.InfoService = InfoService;
	$rootScope.AS = AuthService;
	$rootScope.UR = USER_ROLES;
	$rootScope.AC = Account;

	if (isAlreadyAuthenticated) {
		AuthService.setAlreadyLoggedIn();
		$rootScope.$broadcast(AUTH_EVENTS.loginSuccess);
		$rootScope.needsAppDataToBeLoaded = true;
	} else {
		toaster.errorMessage('MESSAGE.LOGIN_FAILURE');
	}

	$rootScope.checkLocation = $location.isCurrent;

	Matomo.init();

	// eslint-disable-next-line angular/on-watch
	$rootScope.$on('$locationChangeStart', function $locationChangeStart(event) {
		// no state changes when modal windows are open
		// also use $location cause ui-router (the currently used version) does not prevent url/location changes correctly
		if (InfoService.isModalOpen()) {
			event.preventDefault();
		}
	});

	$transitions.onStart({}, function onStartTransition(transition) {
		var to = transition.to(),
			data = to.data || {},
			params = transition.params();

		// no state changes when another request is currently in progress (also no need to notify anyone)
		if (InfoService.isRequestInProgress()) {
			return false;
		}

		// state 'app' is only needed for the page template app.html. we don't really wanna go there directly. so,
		// whenever this happens, just go to the dashboard instead
		if (to.name === 'app') {
			return transition.router.stateService.target('dashboard', {}, { location: 'replace' });
		}

		// when site refresh, but still with valid backend session, we gotta stop here, then load the app data first and reload the state afterwards
		// exclude logout state as a special case here, to make it possible to logout at any time (e.g. if errors during bootstrap occur)
		if (AuthService.isAuthenticated() && $rootScope.needsAppDataToBeLoaded && to.name !== 'logout') {
			$rootScope.$emit(APP_NEEDS_DATA_EVENT);

			// remember where to go after logging in
			AuthService.setRedirectState(to.name);
			AuthService.setRedirectParams({ ...params });

			return false;
		}

		// when authenticated but trying to access pages where no authentication is needed, just silently stop state change
		if (AuthService.isAuthenticated() && data.onlyUnauthenticated === true) {
			return transition.from().name === '' ? transition.router.stateService.target('dashboard', {}, { location: 'replace' }) : false;
		}

		// authorization is needed but user is not authorized -> handle situation
		if (AuthService.authorizationNeeded(data.authorizedRoles) && !AuthService.isAuthorized(data.authorizedRoles)) {
			// user does not have required permissions to view that page
			if (AuthService.isAuthenticated()) {
				// user is not allowed
				$rootScope.$broadcast(AUTH_EVENTS.notAuthorized);

				// check if performing redirect after login, then redirect to default (dashboard) instead
				if ($rootScope.loginRedirectInProgress) {
					return transition.router.stateService.target('dashboard', {}, { location: 'replace' });
				}

				// otherwise show a message and just remain in the current state
				toaster.warningMessage('MESSAGE.NOT_AUTHORIZED');

				return false;
			}

			// user is not logged in, so bring him to login page to authenticate himself
			$rootScope.$broadcast(AUTH_EVENTS.notAuthenticated);

			// do not redirect to logout page cause user would be immediately logged out then again
			if (to.name !== 'logout') {
				AuthService.setRedirectState(to.name);
				AuthService.setRedirectParams({ ...params });
			}
			const urlParams = new URLSearchParams(window.location.search);
			AuthService.setLoginError(urlParams.get('error'));
			return transition.router.stateService.target('login');
		}

		$rootScope.$emit('state-resolve-is-loading', { isLoading: true });

		return true;
	});

	$transitions.onFinish({}, () => {
		$rootScope.$emit('state-resolve-is-loading', { isLoading: false });
	});

	$transitions.onSuccess({}, function onSuccessTransition(transition) {
		// always stay on top
		$window.scrollTo(0, 0);
		// clear possible messages
		toaster.clear();
		// We don't need to track users that are not logged in yet. Doing so would also complicate
		// the separate visits detection.
		if (transition.to().name !== 'login') {
			Matomo.trackPageImpression();
		}
		InfoService.endLoadingAppData();
	});

	// eslint-disable-next-line angular/on-watch
	$rootScope.$on(AUTH_EVENTS.loginSuccess, function loginSuccess() {
		AuthService.setSessionValid();

		Bootstrapper.bootstrap().then(function bootstrapped() {
			InfoService.setLoggedIn(true);

			Matomo.setUserId(UserInfo.getId());
			Matomo.setNewVisit();

			if (AuthService.hasRedirect()) {
				$state.transitionTo(AuthService.getRedirectState(), AuthService.getRedirectParams()).then(function transitioned() {
					$rootScope.$emit(AUTH_EVENTS.loginRedirected);
				});
			} else {
				$state.go('dashboard');
			}
		});
	});

	$transitions.onError({}, function onErrorTransition(transition) {
		var to = transition.to(),
			error = transition.error();

		$rootScope.$emit('state-resolve-is-loading', { isLoading: false });

		// @todo: I couldn't find a way how to access the RejectionType from outside the library
		if (error.type === 6 && to && !angular.equals(to.resolve, {})) {
			console.log(error);
			if (!error.detail || (error.detail && error.detail.code !== API_ERRORS.APP_OUTDATED)) {
				toaster.errorMessage(error.detail || 'MESSAGE.GENERIC_ERROR_MESSAGE');
			}
		}
	});

	$transitions.onError({ to: (state) => state.name === 'reports.view' }, (transition) => {
		const error = transition.error();

		// @todo: I couldn't find a way how to access the RejectionType from outside the library
		if (error.type === 6) {
			// RejectType.ERROR (6) - generally due to a rejected promise during resolve
			$state.go('reports.overview');
		}
	});

	// eslint-disable-next-line angular/on-watch
	$rootScope.$on(APP_NEEDS_DATA_EVENT, function appNeedsData() {
		Bootstrapper.bootstrap()
			.then(function bootstrapped() {
				$rootScope.needsAppDataToBeLoaded = false;
				$rootScope.loginRedirectInProgress = true;

				Matomo.setUserId(UserInfo.getId());

				$state.transitionTo(AuthService.getRedirectState(), AuthService.getRedirectParams()).then(function transitioned() {
					$rootScope.$emit(AUTH_EVENTS.loginRedirected);
				});
			})
			.catch(function error() {
				// if anything goes wrong, reset flag to be able to logout and login again
				$rootScope.needsAppDataToBeLoaded = false;
			});
	});

	// eslint-disable-next-line angular/on-watch
	$rootScope.$on('event:bootstrap-load-data-error', function bootstrapLoadDataError() {
		ModalFactory.simpleModalMessage(
			{
				text: 'MESSAGE.BOOTSTRAP_LOAD_DATA_ERROR',
				buttonText: 'CAPTION.LOGOUT_AND_LOGIN',
			},
			function onMessageClose() {
				$state.go('logout');
			},
		);
	});

	// eslint-disable-next-line angular/on-watch
	$rootScope.$on(AUTH_EVENTS.loginRedirected, function loginRedirected() {
		AuthService.clearRedirect();
		$rootScope.loginRedirectInProgress = false;
		InfoService.setLoggedIn(true);
	});

	// eslint-disable-next-line angular/on-watch
	$rootScope.$on('event:auth-loginRequired', function loginRequired() {
		// trigger session expired message
		ModalFactory.simpleModalMessage(
			{
				text: 'MESSAGE.NO_SESSION',
				buttonText: 'TITLE.LOGIN_HEADER',
			},
			function onMessageClose() {
				$state.go('logout');
			},
		);

		// remember where to go after re-authenticating (if not an impersonated user)
		if (!InfoService.isImpersonated()) {
			AuthService.setRedirectState($state.current.name);
			AuthService.setRedirectParams({ ...$state.params });
		}

		// end all requests to allow state transition
		InfoService.endRequest();
	});

	// eslint-disable-next-line angular/on-watch
	$rootScope.$on('event:app-version-outdated', function appVersionOutdated() {
		// trigger new app version message
		ModalFactory.simpleModalMessage(
			{
				text: 'MESSAGE.APP_UPDATED',
				buttonText: 'CAPTION.OK_RELOAD',
			},
			function onMessageClose() {
				$window.location.reload();
			},
		);

		// clear possible active toaster messages and end all requests (cause apparently none is in progress anymore)
		toaster.clear();
		toaster.clear('sticky');
		InfoService.endRequest();
	});

	// eslint-disable-next-line angular/on-watch
	$rootScope.$on(AUTH_EVENTS.userImpersonated, function userImpersonated(isImpersonated) {
		toaster.clear('sticky');

		$rootScope.needsAppDataToBeLoaded = true;

		Bootstrapper.bootstrap()
			.then(function bootstrapped() {
				$rootScope.needsAppDataToBeLoaded = false;

				InfoService.setImpersonated(isImpersonated);
				Matomo.setUserId(UserInfo.getId());
				// We don't wanna count a new visit when switching back to the original user.
				if (isImpersonated) {
					Matomo.setNewVisit();
				}

				$state
					.transitionTo(
						'dashboard',
						{},
						{
							reload: true,
							inherit: false,
							notify: true,
						},
					)
					.finally(function eventually() {
						AuthService.dlo();
					});
			})
			.catch(function error() {
				// if anything goes wrong, reset flag to be able to logout and login again
				$rootScope.needsAppDataToBeLoaded = false;
			});
	});

	$rootScope.switchLanguage = function switchLanguage(languageKey) {
		// only switch if necessary
		if ($translate.use() !== languageKey) {
			$translate.use(languageKey);
		}
	};
}

export default AppRun;
