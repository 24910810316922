const defaultLocaleCompare = new Intl.Collator(undefined, {
	sensitivity: 'base',
}).compare;

const ascByNameFieldWith =
	(compareFn) =>
	({ name: nameA = '' }, { name: nameB = '' }) =>
		compareFn(nameA.trim(), nameB.trim());

const ascByEntryNameFieldWith = (compareFn) => (a, b) => {
	const leftValue = a.entry.name || '';
	const rightValue = b.entry.name || '';
	return compareFn(leftValue.trim(), rightValue.trim());
};

export const ascByEntryName = ascByEntryNameFieldWith(defaultLocaleCompare);
export const ascByNameField = ascByNameFieldWith(defaultLocaleCompare);
