import isEmpty from 'lodash/isEmpty.js';

class ReportColumnFilter {
	type = null;

	// used in UI as model for multi select
	model = [];

	// used in UI for multi select options
	options = [];

	constructor(rawFilterData) {
		if (!isEmpty(rawFilterData.ids)) {
			this.type = ReportColumnFilter.TYPE.IDS;
			this.model = rawFilterData.ids;
		}

		if (!isEmpty(rawFilterData.value)) {
			if (this.type === ReportColumnFilter.TYPE.IDS) {
				throw new Error('got invalid column filter configuration! either ids or value only is allowed.');
			}
			this.type = ReportColumnFilter.TYPE.VALUE;
			this.model = rawFilterData.value;
		}

		if (this.type === null) {
			this.type = ReportColumnFilter.TYPE.IDS;
		}
	}

	static TYPE = {
		IDS: 'ids',
		VALUE: 'value',
	};

	setOptions(options) {
		this.options = options;
		return this;
	}

	/**
	 * @returns {boolean}
	 */
	isEmpty() {
		return isEmpty(this.model);
	}

	toObject() {
		return {
			[this.type]: [...this.model],
		};
	}
}

export default ReportColumnFilter;
