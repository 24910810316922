import stickyToasterTranslateHtml from '../../views/stickyToasterTranslate.html';

/**
 * Add some convenience to the toaster service to call messages with translated resources easily.
 */
function ToasterDecoration($delegate, $templateCache, $log) {
	'ngInject';

	const toaster = $delegate;
	const ERROR_TIMEOUT = 20000; // 20 sec
	const WARNING_TIMEOUT = 10000; // 10 sec
	const STICKY_TOASTER_TEMPLATE_NAME = 'stickyToasterTemplate.html';

	/**
	 * Holds toast instances that can be closed by {@link toaster#dropCloseable} or
	 * {@link toaster#dropUnavailableCloseables}.
	 * @type {Map<string, {toastId, toasterId}>}
	 */
	const closeableToasts = new Map();

	function popToast(toasterId, title, icon, message, messageData, toastConfig, onClick) {
		return toaster.pop(
			angular.extend(
				{
					timeout: 0,
					toasterId,
					showCloseButton: true,
					closeHtml: '<button class="link primary no-margin toast-close-button icon-cancel" type="button"></button>',
					body: angular.toJson({
						template: STICKY_TOASTER_TEMPLATE_NAME,
						data: {
							title,
							icon,
							message,
							messageData,
						},
					}),
					bodyOutputType: 'templateWithData',
				},
				toastConfig || {
					clickHandler: (instance, shouldClose = false) => {
						if (shouldClose) {
							toaster.clear(toasterId, instance.toastId);
						} else if (angular.isFunction(onClick)) {
							onClick(instance);
							toaster.clear(toasterId, instance.toastId);
						}
					},
				},
			),
		);
	}

	toaster.infoMessage = function infoMessage(message, config) {
		toaster.info(
			angular.extend({}, config || {}, {
				body: 'toaster-translate-body',
				directiveData: { message },
				bodyOutputType: 'directive',
			}),
		);
	};

	toaster.errorMessage = function errorMessage(message, config) {
		toaster.error(
			angular.extend({}, config || {}, {
				body: 'toaster-translate-body',
				directiveData: { message },
				bodyOutputType: 'directive',
				timeout: ERROR_TIMEOUT,
			}),
		);
	};

	toaster.successMessage = function successMessage(message, config) {
		toaster.success(
			angular.extend({}, config || {}, {
				body: 'toaster-translate-body',
				directiveData: { message },
				bodyOutputType: 'directive',
			}),
		);
	};

	toaster.warningMessage = function warningMessage(message, config) {
		toaster.warning(
			angular.extend({}, config || {}, {
				body: 'toaster-translate-body',
				directiveData: { message },
				bodyOutputType: 'directive',
				timeout: WARNING_TIMEOUT,
			}),
		);
	};

	// Creates a message/toast that can only be closed by the user via close button.
	toaster.popStickyMessage = (messageConfig, toastConfig) => {
		const toasterId = 'sticky';
		const { message, messageData, onClick, title, icon } = messageConfig;

		popToast(toasterId, title, icon, message, messageData, toastConfig, onClick);
	};

	// Creates a message/toast that can be closed via {@link toaster#dropCloseable} and
	// {@link toaster#dropUnavailableCloseables} or the close button.
	toaster.popCloseableMessage = (messageConfig, toastConfig) => {
		const toasterId = 'sticky';
		const { message, messageData, onClick, title, icon, toastId } = messageConfig;

		const toast = popToast(toasterId, title, icon, message, messageData, toastConfig, onClick);
		closeableToasts.set(toastId, toast);
		$log.debug('Closeable toast ' + angular.toJson(toastId) + ' has been added.');
	};

	toaster.doesCloseableExist = (toastId) => closeableToasts.has(toastId);

	// Does close closeable messages that have been created with {@link toaster#popCloseableMessage}.
	// Does not throw an exception if toastId does not exist.
	toaster.dropCloseable = (toastId) => {
		const instanceToClose = closeableToasts.get(toastId);
		if (instanceToClose) {
			toaster.clear(instanceToClose.toasterId, instanceToClose.toastId);
			closeableToasts.delete(toastId);
			$log.debug('Closeable toast ' + angular.toJson(toastId) + ' has been closed.');
		}
	};

	// Closes all toasts that are currently active but not in activeToasts.
	toaster.dropUnavailableCloseables = (activeToasts) => {
		$log.debug('Current closeable toasts state: ' + angular.toJson([...closeableToasts.keys()]));
		$log.debug('Toast update: ' + angular.toJson(activeToasts));
		[...closeableToasts.keys()]
			.filter((toastId) => !activeToasts.includes(toastId))
			.forEach((key) => {
				if (toaster.doesCloseableExist(key)) {
					toaster.dropCloseable(key);
				} else {
					$log.warn('Closeable toast ' + key + ' does not exist.');
				}
			});
	};

	$templateCache.put(STICKY_TOASTER_TEMPLATE_NAME, stickyToasterTranslateHtml);

	return toaster;
}

export default ToasterDecoration;
