import ROLE from './../constants/UserRoles.js';

function UserInfo(Api) {
	'ngInject';

	var /**
		 * @type {int}
		 */
		apiId,
		/**
		 * @type {String}
		 */
		apiAccount,
		/**
		 * @type {boolean}
		 */
		isImpersonated,
		/**
		 * @type {String}
		 */
		email,
		/**
		 * @type {Array.<String>}
		 */
		roles = [],
		/**
		 * @type {boolean}
		 */
		hasAllChannels,
		/**
		 * @type {boolean}
		 */
		hasRoleAdmin,
		/**
		 * @type {boolean}
		 */
		hasRoleUser,
		/**
		 * @type {boolean}
		 */
		hasRoleReports,
		/**
		 * @type {Object.<String, String>}
		 */
		apiToUserRole = {
			ROLE_ADMIN: ROLE.ADMIN,
			ROLE_REPORTING: ROLE.REPORTS,
			ROLE_USER: ROLE.USER,
			ROLE_YL_ADMIN: ROLE.YL_ADMIN,
			ROLE_BETA: ROLE.BETA,
		};

	/**
	 * @type {{WRONG_PASSWORD: number, INVALID_PASSWORD: number, INVALID_RESET_CODE: number}}
	 */
	this.ERROR = {
		WRONG_PASSWORD: 20001,
		EMAIL_EXISTS: 20005,
	};

	/**
	 * @param {Array.<String>} apiRoles
	 * @returns {Array.<String>}
	 */
	function mapUserRoles(apiRoles) {
		var i,
			res = [];

		for (i = 0; i < apiRoles.length; i++) {
			res.push(apiToUserRole[apiRoles[i]]);
		}

		return res;
	}

	/**
	 * loads user info data
	 *
	 * @return {promise}
	 */
	this.load = function load() {
		return Api.get('/api/v1/users/info').then(function success(userInfo) {
			apiId = userInfo.id;
			apiAccount = userInfo.user;
			isImpersonated = userInfo.isImpersonated;
			email = userInfo.email;
			roles = mapUserRoles(userInfo.roles || []);
			hasAllChannels = userInfo.allChannel;
			hasRoleAdmin = roles.indexOf(ROLE.ADMIN) !== -1;
			hasRoleUser = hasRoleAdmin || roles.indexOf(ROLE.USER) !== -1;
			hasRoleReports = hasRoleAdmin || hasRoleUser || roles.indexOf(ROLE.REPORTS) !== -1;
		});
	};

	/**
	 * @param {String} mail
	 * @param {String} password
	 * @returns {Promise}
	 */
	this.changeAccountEmail = (mail, password) =>
		Api.post('/api/v1/users/info', {
			email: mail,
			password,
		});

	/**
	 * @returns {int}
	 */
	this.getId = function getId() {
		return apiId;
	};

	/**
	 * @returns {String}
	 */
	this.getName = function getName() {
		return apiAccount;
	};

	/**
	 * @returns {boolean}
	 */
	this.isImpersonatedUser = function isImpersonatedUser() {
		return isImpersonated;
	};

	/**
	 * @returns {String}
	 */
	this.getEmail = function getEmail() {
		return email;
	};

	/**
	 * @returns {Array.<String>}
	 */
	this.getRoles = function getRoles() {
		return roles;
	};

	/**
	 * @returns {boolean}
	 */
	this.hasAllChannels = function hasAllChannelsFn() {
		return hasAllChannels;
	};

	/**
	 * @returns {String}
	 */
	this.getHighestRole = function getHighestRole() {
		if (hasRoleAdmin) {
			return ROLE.ADMIN;
		}

		if (hasRoleUser) {
			return ROLE.USER;
		}

		if (hasRoleReports) {
			return ROLE.REPORTS;
		}

		// no hierarchical role found
		return '';
	};

	/**
	 * @param {String} requiredRole
	 * @returns {boolean}
	 */
	this.hasAtLeastRole = function hasAtLeastRole(requiredRole) {
		switch (requiredRole) {
			case ROLE.ADMIN:
				return hasRoleAdmin;

			case ROLE.USER:
				return hasRoleUser;

			case ROLE.REPORTS:
				return hasRoleReports;

			default:
				return false;
		}
	};
}

export default UserInfo;
