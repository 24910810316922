import AdvertiserFilter from '../../../models/AdvertiserFilter.js';

function SettingsSupplyController($scope, resolvedSupplyFilter, Advertisers, SupplyFilter, toaster, InfoService, SupplyAdvertisers) {
	'ngInject';

	const vm = this;

	let initialFilter = resolvedSupplyFilter;

	vm.filter = new AdvertiserFilter(initialFilter.toObject());

	vm.assignedData = Array.from(initialFilter.items)
		.map((advertiserId) => Advertisers.getById(advertiserId))
		.filter((entry) => entry !== undefined); // eslint-disable-line angular/definedundefined
	// we do not use blocked advertisers for supply filter -> choose from all available advertisers instead
	vm.availableData = Object.values(Advertisers.getMap()).filter((advertiser) => advertiser.enabled);

	vm.saveAdvertiserFilter = () => {
		const currentFilter = AdvertiserFilter.fromIdsAndType(
			vm.assignedData.map(({ id }) => id),
			vm.filter.type,
		);
		const currentFilterDataObject = currentFilter.toObject();

		if (!initialFilter.equals(currentFilter)) {
			InfoService.startRequest();

			SupplyFilter.save(currentFilter)
				.then(() => {
					vm.filter.resetTypeChanged();
					initialFilter = new AdvertiserFilter(currentFilterDataObject);

					toaster.successMessage('MESSAGE.SETTINGS_SAVE_SUCCESS');
				})
				.catch(() => toaster.errorMessage('MESSAGE.SETTINGS_SAVE_ERROR'))
				.finally(() => InfoService.endRequest());
		}
	};

	$scope.$on('$destroy', () => {
		if (!resolvedSupplyFilter.equals(initialFilter)) {
			SupplyAdvertisers.loadAll();
		}
	});
}

export default SettingsSupplyController;
