function ButtonGroupToggle() {
	return {
		link: function postLink(scope, iElement) {
			var $buttons = angular.element(iElement[0].querySelectorAll('.button, button'));

			Array.prototype.slice.call($buttons).forEach(function each(button) {
				var $button = angular.element(button);
				$button.on('click', function addClass() {
					$buttons.removeClass('active');
					$button.addClass('active');
				});
			});
		},
	};
}

export default ButtonGroupToggle;
