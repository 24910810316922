import ExcelJS from 'exceljs';

function ReportFormatterService() {
	'ngInject';

	const excelRowTypes = {
		DEFAULT_ROW: 0,
		SUMMARY_ROW: 1,
		TOTAL_ROW: 2,
	};

	const excelColumnIdentifiers = {
		COLUMN_ID_ADSLOT: 'adslot',
		COLUMN_ID_ADSLOT_ID: 'adslot_id',
		COLUMN_ID_DEAL_ID: 'deal_id',
		COLUMN_ID_DEMAND_PARTNER: 'demand_partner',
		COLUMN_ID_PARTNERSHIP: 'partnership',
		COLUMN_ID_PARTNERSHIP_ID: 'partnership_id',
		COLUMN_ID_ADVERTISER: 'advertiser',
		COLUMN_ID_CHANNELS: 'channels',
		COLUMN_ID_EXTERNAL_ID: 'external_id',
		COLUMN_ID_FORMAT: 'format',
		COLUMN_ID_GROUP: 'group',
		COLUMN_ID_INVOICE: 'invoice',
		COLUMN_ID_SITE: 'site',
		COLUMN_ID_URL: 'url',
		COLUMN_ID_ECPM: 'ecpm',
		COLUMN_ID_FALLBACKS: 'fallbacks',
		COLUMN_ID_IMPRESSIONS: 'impressions',
		COLUMN_ID_NET_ECPM: 'net_ecpm',
		COLUMN_ID_NET_REVENUE: 'net_revenue',
		COLUMN_ID_PARENT_REVENUE: 'parent_revenue',
		COLUMN_ID_REVENUE: 'revenue',
		COLUMN_ID_VIDEO_CLICK: 'video_click',
		COLUMN_ID_VIDEO_COMPLETE: 'video_complete',
		COLUMN_ID_VIDEO_FIRST_QUARTILE: 'video_first_quartile',
		COLUMN_ID_VIDEO_MIDPOINT: 'video_midpoint',
		COLUMN_ID_VIDEO_STARTS: 'video_starts',
		COLUMN_ID_VIDEO_THIRD_QUARTILE: 'video_third_quartile',
		COLUMN_ID_ADTYPE: 'ad_type',
		COLUMN_ID_PLATFORM_TYPE: 'platform_type',
		COLUMN_ID_PARTNERSHIP_TYPE: 'partnership_type',
	};

	const defaultFont = { name: 'Arial', size: 10 };
	const excelCellFormat = {
		formatBold: {
			font: { bold: true, ...defaultFont },
		},
		currency: {
			numFmt: '#,##0.00 €',
		},
		number: {
			numFmt: '#,##0',
		},
		summaryRow: {
			font: { bold: true, ...defaultFont },
			fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFE4E4E4' } }, // light gray
		},
		summaryRowCurrency: {
			font: { bold: true, ...defaultFont },
			numFmt: '#,##0.00 €',
			fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFE4E4E4' } }, // light gray
		},
		summaryRowNumber: {
			font: { bold: true, ...defaultFont },
			numFmt: '#,##0',
			fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFE4E4E4' } }, // light gray
		},
		totalRow: {
			font: { color: { argb: 'FFFFFFFF' }, ...defaultFont }, // white
			fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF2D9A9F' } }, // dark teal
		},
		totalRowCurrency: {
			font: { color: { argb: 'FFFFFFFF' }, ...defaultFont }, // white
			numFmt: '#,##0.00 €',
			fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF2D9A9F' } }, // dark teal
		},
		totalRowNumber: {
			font: { color: { argb: 'FFFFFFFF' }, ...defaultFont }, // white
			numFmt: '#,##0',
			fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF2D9A9F' } }, // dark teal
		},
	};

	function generateCsv(reportData, preset, translation) {
		// Exclude type (legacy code) from the report
		const modifiedReport = reportData.map(({ type, ...rest }) => rest);

		// Round floating-point numbers
		modifiedReport.forEach((row) => {
			for (const key in row) {
				const value = row[key];

				if (typeof value === 'number') {
					row[key] = parseFloat(value.toFixed(5));
				}
			}
		});

		const csvString = convertToCSV(translation, modifiedReport);
		return csvString;
	}

	function convertToCSV(headerTranslation, data) {
		const sepSeparator = 'sep=,\n';

		const header =
			Object.values(headerTranslation)
				.map((field) => `"${field}"`)
				.join(',') + '\n';

		const rows = data
			.map((row) =>
				Object.values(row)
					.map((value) => {
						if (value === null) {
							return value;
						}

						if (typeof value === 'string') {
							const strippedValue = value.trim();

							if (strippedValue === '') {
								return value;
							}

							if (!isNaN(Number(strippedValue))) {
								return strippedValue;
							} else {
								return `"${strippedValue.replace(/"/g, '""')}"`;
							}
						}
						return value;
					})
					.join(','),
			)
			.join('\n');
		return sepSeparator + header + rows;
	}

	async function generateExcelFileBuffer(reportData, preset, translation) {
		const workbook = new ExcelJS.Workbook();
		const worksheet = workbook.addWorksheet('Yieldlab Report');
		worksheet.properties.defaultRowHeight = 13;

		// Start, end date rows
		worksheet.addRow(['Start', formatDate(preset.startDate)]).font = excelCellFormat.formatBold.font;
		worksheet.addRow(['End', formatDate(preset.endDate)]).font = excelCellFormat.formatBold.font;

		// Empty Row
		worksheet.addRow([]);

		// Report column headers
		const headerRow = worksheet.addRow(preset.columns.map((key) => translation[key]));
		headerRow.eachCell((cell, colNumber) => {
			const columnKey = preset.columns[colNumber - 1];
			const columnWidth = getColumnWidth(columnKey);

			cell.font = excelCellFormat.formatBold.font;
			worksheet.getColumn(colNumber).width = columnWidth !== -1 ? columnWidth : worksheet.getColumn(colNumber).width;
		});

		// Report data
		reportData.forEach((rowData) => {
			const row = worksheet.addRow(
				preset.columns
					.filter((columnKey) => columnKey !== 'type') // skip 'type' column, legacy code
					.map((columnKey) => {
						const columnValue = rowData[columnKey];
						if (columnKey === excelColumnIdentifiers.COLUMN_ID_ADSLOT_ID && columnValue <= 0) {
							return '';
						} else if (columnKey === excelColumnIdentifiers.COLUMN_ID_DEAL_ID) {
							if (columnValue <= 0) {
								return '';
							} else {
								return isNaN(Number(columnValue)) ? columnValue : Number(columnValue); // without converting the `deal_id` value we will have a quote at the beginning, (ex: '14324631)
							}
						} else {
							return columnValue;
						}
					}),
			);

			row.font = defaultFont;
			row.eachCell((cell, colNumber) => {
				const columnKey = preset.columns[colNumber - 1];
				const rowType = getExcelRowType(rowData['type']);

				const format = getCellFormat(columnKey, rowType);

				if (format) {
					cell.numFmt = format.numFmt;
					cell.font = format.font || cell.font;
					cell.fill = format.fill || cell.fill;
				}
			});
		});

		return workbook.xlsx.writeBuffer();
	}

	function getExcelRowType(type) {
		if (type === 'total') {
			return excelRowTypes.TOTAL_ROW;
		} else if (type === 'summary') {
			return excelRowTypes.SUMMARY_ROW;
		} else {
			return excelRowTypes.DEFAULT_ROW;
		}
	}

	function formatDate(date) {
		return new Date(date).toLocaleDateString('de-DE');
	}

	function getColumnWidth(columnKey) {
		switch (columnKey) {
			case excelColumnIdentifiers.COLUMN_ID_SITE:
			case excelColumnIdentifiers.COLUMN_ID_GROUP:
			case excelColumnIdentifiers.COLUMN_ID_ADSLOT:
			case excelColumnIdentifiers.COLUMN_ID_PARTNERSHIP:
				return 33.7;
			case excelColumnIdentifiers.COLUMN_ID_ADSLOT_ID:
			case excelColumnIdentifiers.COLUMN_ID_DEAL_ID:
			case excelColumnIdentifiers.COLUMN_ID_FALLBACKS:
			case excelColumnIdentifiers.COLUMN_ID_INVOICE:
			case excelColumnIdentifiers.COLUMN_ID_EXTERNAL_ID:
			case excelColumnIdentifiers.COLUMN_ID_ADTYPE:
				return 9.7;
			case excelColumnIdentifiers.COLUMN_ID_DEMAND_PARTNER:
				return 18.7;
			case excelColumnIdentifiers.COLUMN_ID_ADVERTISER:
				return 25.7;
			case excelColumnIdentifiers.COLUMN_ID_REVENUE:
			case excelColumnIdentifiers.COLUMN_ID_PARENT_REVENUE:
			case excelColumnIdentifiers.COLUMN_ID_ECPM:
				return 16.7;
			case excelColumnIdentifiers.COLUMN_ID_IMPRESSIONS:
			case excelColumnIdentifiers.COLUMN_ID_NET_REVENUE:
			case excelColumnIdentifiers.COLUMN_ID_NET_ECPM:
			case excelColumnIdentifiers.COLUMN_ID_VIDEO_STARTS:
			case excelColumnIdentifiers.COLUMN_ID_VIDEO_FIRST_QUARTILE:
			case excelColumnIdentifiers.COLUMN_ID_VIDEO_MIDPOINT:
			case excelColumnIdentifiers.COLUMN_ID_VIDEO_THIRD_QUARTILE:
			case excelColumnIdentifiers.COLUMN_ID_VIDEO_COMPLETE:
			case excelColumnIdentifiers.COLUMN_ID_VIDEO_CLICK:
			case excelColumnIdentifiers.COLUMN_ID_PARTNERSHIP_TYPE:
			case excelColumnIdentifiers.COLUMN_ID_PLATFORM_TYPE:
				return 19.7;
			default:
				return -1;
		}
	}

	function getCellFormat(columnKey, rowType) {
		let format = null;

		if (rowType === excelRowTypes.SUMMARY_ROW) {
			format = excelCellFormat.summaryRow;
		} else if (rowType === excelRowTypes.TOTAL_ROW) {
			format = excelCellFormat.totalRow;
		}

		// The format can be overwritten by column key
		switch (columnKey) {
			case excelColumnIdentifiers.COLUMN_ID_NET_ECPM:
			case excelColumnIdentifiers.COLUMN_ID_ECPM:
			case excelColumnIdentifiers.COLUMN_ID_REVENUE:
			case excelColumnIdentifiers.COLUMN_ID_NET_REVENUE:
			case excelColumnIdentifiers.COLUMN_ID_PARENT_REVENUE:
				switch (rowType) {
					case excelRowTypes.DEFAULT_ROW:
						format = excelCellFormat.currency;
						break;
					case excelRowTypes.SUMMARY_ROW:
						format = excelCellFormat.summaryRowCurrency;
						break;
					case excelRowTypes.TOTAL_ROW:
						format = excelCellFormat.totalRowCurrency;
						break;
				}
				break;
			case excelColumnIdentifiers.COLUMN_ID_IMPRESSIONS:
			case excelColumnIdentifiers.COLUMN_ID_FALLBACKS:
			case excelColumnIdentifiers.COLUMN_ID_VIDEO_STARTS:
			case excelColumnIdentifiers.COLUMN_ID_VIDEO_FIRST_QUARTILE:
			case excelColumnIdentifiers.COLUMN_ID_VIDEO_MIDPOINT:
			case excelColumnIdentifiers.COLUMN_ID_VIDEO_THIRD_QUARTILE:
			case excelColumnIdentifiers.COLUMN_ID_VIDEO_COMPLETE:
			case excelColumnIdentifiers.COLUMN_ID_VIDEO_CLICK:
				switch (rowType) {
					case excelRowTypes.DEFAULT_ROW:
						format = excelCellFormat.number;
						break;
					case excelRowTypes.SUMMARY_ROW:
						format = excelCellFormat.summaryRowNumber;
						break;
					case excelRowTypes.TOTAL_ROW:
						format = excelCellFormat.totalRowNumber;
						break;
				}
		}

		return format;
	}

	return {
		generateCsv: generateCsv,
		generateExcelFileBuffer: generateExcelFileBuffer,
	};
}

export default ReportFormatterService;
