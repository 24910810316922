import isEqual from 'lodash/isEqual.js';

class AdvertiserCategoryFilter {
	/**
	 * @type {Set.<Number>}
	 */
	items;

	constructor({ advertiserCategories = [] } = {}) {
		this.items = new Set(advertiserCategories);
	}

	/**
	 * @param {Number[]} advertiserCategories
	 * @return {AdvertiserCategoryFilter}
	 */
	static fromIds(advertiserCategories) {
		return new AdvertiserCategoryFilter({ advertiserCategories });
	}

	/**
	 * @param {AdvertiserCategoryFilter} other
	 * @return {boolean}
	 */
	equals(other) {
		return isEqual(this.items, other.items);
	}

	/**
	 * @return {{advertiserCategories: number[]}}
	 */
	toObject() {
		return {
			advertiserCategories: Array.from(this.items),
		};
	}
}

export default AdvertiserCategoryFilter;
