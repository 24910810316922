function RootScope($delegate) {
	'ngInject';

	/**
	 * helper to manually call $apply in a safe way
	 * from: https://coderwall.com/p/ngisma (2014/01/21)
	 */
	$delegate.safeApply = function safeApply(fn) {
		var phase = $delegate.$$phase; // eslint-disable-line angular/no-private-call
		if (phase === '$apply' || phase === '$digest') {
			if (fn && angular.isFunction(fn)) {
				$delegate.$eval(fn);
			}
		} else if (fn && angular.isFunction(fn)) {
			$delegate.$apply(fn);
		} else {
			$delegate.$apply();
		}
	};

	/**
	 * @param {$scope} scope
	 * @param {Array.<Function>} listeners
	 */
	$delegate.cleanListeners = function cleanListeners(scope, listeners) {
		var unbindDestroyListener = scope.$on('$destroy', function destroyListener() {
			listeners.forEach(function removeListener(listener) {
				listener();
			});
			unbindDestroyListener();
		});
	};

	return $delegate;
}

export default RootScope;
