function AppConfig($animateProvider, $httpProvider, $translateProvider, $qProvider, MATOMO_CONFIG, MatomoProvider) {
	'ngInject';

	MatomoProvider.setSource(MATOMO_CONFIG.source);

	$animateProvider.classNameFilter(/yl-animate|toast|app-user-indicator/);
	$qProvider.errorOnUnhandledRejections(false);

	$translateProvider
		.useUrlLoader('/language?env=' + process.env.LANGUAGE_CACHE_BUSTER)
		.useCookieStorage()
		.useLocalStorage()
		.registerAvailableLanguageKeys(['en', 'de'], {
			en_US: 'en',
			en_UK: 'en',
			de_AT: 'de',
			de_BE: 'de',
			de_DE: 'de',
			de_CH: 'de',
			de_LI: 'de',
			de_LU: 'de',
		})
		.determinePreferredLanguage()
		.fallbackLanguage('en')
		.useSanitizeValueStrategy('escaped');

	/**
	 * $http interceptor.
	 * On 409 response discards the request and broadcasts 'event:app-version-outdated'.
	 */
	$httpProvider.interceptors.push(function appVersionOutdatedInterceptor($rootScope, $q, API_ERRORS) {
		'ngInject';

		return {
			responseError: function responseError(rejection) {
				switch (rejection.status) {
					case 409:
						if (rejection.data && rejection.data.code === API_ERRORS.APP_OUTDATED) {
							$rootScope.$broadcast('event:app-version-outdated', rejection);
						}
						break;
					default:
					// no further handling needed, will be rejected anyway
				}
				// otherwise, default behaviour
				return $q.reject(rejection);
			},
		};
	});
}

export default AppConfig;
