import ReferrerGroup from '../../../../models/ReferrerGroup.js';

function SettingsInventoryEditController($state, $stateParams, $timeout, InfoService, InventoryFilter, toaster, resolvedReferrerGroupsMap) {
	'ngInject';

	const vm = this;

	const isNewGroup = $stateParams.refGrpId === 'new';
	vm.heading = isNewGroup ? 'CAPTION.NEW_INVENTORY_GROUP' : 'TITLE.EDIT_REFERRER_GROUP';

	vm.isCurrentlyCorrecting = false;
	vm.includedErrorsExist = false; // should be updated only on save
	vm.excludedErrorsExist = false; // same

	vm.form = null; // used in template
	vm.group = isNewGroup ? new ReferrerGroup() : angular.copy(resolvedReferrerGroupsMap[$stateParams.refGrpId]);
	const group$original = angular.copy(vm.group);

	vm.submit = () => {
		if (vm.group.hasNoIntersectionBetweenSubgroups()) {
			vm.group.validateAllReferrers();
			(isNewGroup ? createGroup : saveGroup)();
		} else {
			toaster.errorMessage('MESSAGE.REFERRER_DUPLICATE');
		}
	};

	const createGroup = () => {
		saveOrUpdateGroup(InventoryFilter.addReferrerGroup);
	};

	const saveGroup = () => {
		if (!angular.equals(vm.group, group$original)) {
			saveOrUpdateGroup(InventoryFilter.updateReferrerGroup);
		}
	};

	const saveOrUpdateGroup = (upsertFn) => {
		InfoService.startRequest();

		vm.isCurrentlyCorrecting = false;
		upsertFn(vm.group).then(handleSuccess).then(backToListViewDelayed).catch(handleApiError).finally(InfoService.endRequest.bind(InfoService));
	};

	const handleSuccess = (group) => {
		resolvedReferrerGroupsMap[group.id] = group;
		toaster.successMessage('MESSAGE.SETTINGS_SAVE_SUCCESS');
	};

	/**
	 * @param {{code: <int>, errorMessage: <String>}} error
	 */
	const handleApiError = (error) => {
		if (angular.isObject(error)) {
			switch (error.code) {
				case InventoryFilter.error.MISSING_GROUP_NAME:
					toaster.errorMessage('MESSAGE.INVENTORY_GROUP_NAME_MISSING');
					vm.form.groupName.$setValidity('required', false);
					break;

				case InventoryFilter.error.MISSING_REFERERS:
					toaster.errorMessage('MESSAGE.INVENTORY_GROUP_REFERRERS_MISSING');
					vm.form.groupReferrers.$setValidity('required', false);
					break;

				case InventoryFilter.error.DUPLICATE_GROUP_NAME:
					toaster.errorMessage('MESSAGE.INVENTORY_GROUP_NAME_EXISTS');
					vm.form.groupName.$setValidity('required', false);
					break;

				case InventoryFilter.error.INVALID_EXCLUDED_REFERRERS:
				case InventoryFilter.error.INVALID_INCLUDED_REFERRERS:
					handleAllInvalidReferrers(error);
					break;

				default:
					toaster.errorMessage('MESSAGE.GENERIC_ERROR_MESSAGE');
			}
		} else {
			toaster.errorMessage('MESSAGE.GENERIC_ERROR_MESSAGE');
		}
	};

	const handleAllInvalidReferrers = (result) => {
		vm.isCurrentlyCorrecting = true;

		handleInvalidReferrers(result, InventoryFilter.error.INVALID_INCLUDED_REFERRERS, vm.group.invalidateIncludedReferrers.bind(vm.group));
		handleInvalidReferrers(result, InventoryFilter.error.INVALID_EXCLUDED_REFERRERS, vm.group.invalidateExcludedReferrers.bind(vm.group));

		vm.includedErrorsExist = vm.group.hasInvalidIncludedReferrers();
		vm.excludedErrorsExist = vm.group.hasInvalidExcludedReferrers();
	};

	const handleInvalidReferrers = (result, code, handleFn) => {
		const referrersError = pickErrorFromList(result.errors, code);
		if (angular.isDefined(referrersError)) {
			const wrongReferrers = parseInvalidEntries(referrersError.errorMessage);
			handleFn(wrongReferrers);
		}
	};

	const pickErrorFromList = (list, code) => list.find((element) => element.code === code);

	// Invalid entries are given in a string like this:
	//    "Referrer whitelist contains invalid referrers: [testö, contains space]"
	const parseInvalidEntries = (message) =>
		message
			.substring(message.indexOf('[') + 1, message.lastIndexOf(']'))
			.split(',')
			.map((element) => element.trim());

	const backToListViewDelayed = () => {
		$timeout(backToListView, 500);
	};

	const backToListView = () => {
		$state.go('settings.inventory.view');
	};

	vm.cancel = backToListView;
}

export default SettingsInventoryEditController;
