import AuctionType, { SECOND_PRICE } from './AuctionType.js';
import AdvertiserFilter from './AdvertiserFilter.js';
import REFERRER_TYPE from './ReferrerType.js';

class OpenAuctionConfig {
	/**
	 * @type {Number}
	 */
	floorPrice;

	/**
	 * @type {String}
	 */
	referrerType;

	/**
	 * @type {String}
	 */
	referrerUrl;

	/**
	 * @type {AuctionType}
	 */
	auctionTypeModel;

	/**
	 * @return {number}
	 */
	get auctionType() {
		return this.auctionTypeModel.id;
	}

	/**
	 * @param {number} id
	 */
	set auctionType(id) {
		this.auctionTypeModel = new AuctionType(id);
	}

	/**
	 * @type {AdvertiserFilter}
	 */
	filter;

	constructor({ floorPrice = 0, referrerType = REFERRER_TYPE.TRANSPARENT, referrerUrl = '', auctionType = SECOND_PRICE.toString(), filter = {} } = {}) {
		this.floorPrice = floorPrice;
		this.referrerType = referrerType;
		this.referrerUrl = referrerUrl;
		this.auctionTypeModel = AuctionType.fromString(auctionType);
		this.filter = new AdvertiserFilter(filter);
	}

	toObject() {
		const resultObject = {
			floorPrice: this.floorPrice,
			referrerType: this.referrerType,
			auctionType: this.auctionTypeModel.toString(),
			filter: this.filter.toObject(),
		};

		if (this.referrerType === REFERRER_TYPE.SEMI_TRANSPARENT) {
			resultObject.referrerUrl = this.referrerUrl;
		}

		return resultObject;
	}
}

export default OpenAuctionConfig;
