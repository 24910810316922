import Adslot from '../../models/Adslot.js';
import { ascByNameField } from '../../utils/sort-utils.js';

function PricingAdslotsController(
	$scope,
	resolvedPrices,
	AdslotGroups,
	ListFilterFactory,
	ListFilterSettings,
	ListSearchService,
	AdslotTranslations,
	Adslots,
	Channels,
	AdslotsPrices,
	InfoService,
	Sites,
	toaster,
) {
	'ngInject';

	const listFilterId = 'pricing-adslots-filter';

	$scope.models.formatFilterOptions = Adslots.getFormatList().map(function formatStringToObject(format) {
		return {
			id: format,
			name: format,
		};
	});

	$scope.models.adslotsFilters = ListFilterFactory.get(ListFilterFactory.LAYOUTS.ADSLOTS.LIST).load(ListFilterSettings.get(listFilterId));

	// update potentially changed entry data
	$scope.models.adslotsFilters.getGroup('LABEL.SITE').getEntry('siteId').setEntryData(Sites.getAsIdNameObjectsList());
	$scope.models.adslotsFilters.getGroup('LABEL.GROUP').getEntry('groupId').setEntryData(AdslotGroups.getAsIdNameObjectsList());
	$scope.models.adslotsFilters
		.getGroup('LABEL.ADSLOT_CHANNEL')
		.getEntry('channelId')
		.setEntryData([Channels.WITOUT_CHANNELS_FILTER_ENTRY].concat(Channels.getList().sort(ascByNameField)));
	$scope.models.adslotsFilters.getGroup('LABEL.ADSLOT_FORMAT').getEntry('formatId').setEntryData($scope.models.formatFilterOptions);

	$scope.matchesFilter = $scope.models.adslotsFilters.matchesFilter.bind($scope.models.adslotsFilters);

	$scope.models.adslots = Adslots.getList();
	$scope.models.adslotsSearch = ListSearchService.get('pricing-adslots-search');
	$scope.models.adslotsSortKey = $scope.models.adslotsSortKey || 'name';

	$scope.models.partnershipsSortKey = undefined;

	/**
	 * Initialize form model in a way to keep track of changes. This is a locally adjusted version of the method
	 * in the pricing controller to be able to handle multisize Adslots. The resulting map will contain
	 *
	 * @param {Array.<Adslot>} itemList
	 * @param {{Number: {String: Number}}} priceMap
	 * @returns {{Number: {String: Number}}}
	 */
	function initializeForm(itemList, priceMap) {
		const form = {};

		for (const adslot of itemList) {
			if (!(adslot.id in form)) {
				form[adslot.id] = {};
			}

			form[adslot.id] = adslot.formats.reduce((formats, format) => {
				const dimension = format.dimension.toString();
				formats[dimension] = {
					floorPrice: priceMap[adslot.id] ? priceMap[adslot.id][dimension] || 0 : 0,
				};
				formats[dimension].$initial = formats[dimension].floorPrice;
				formats[dimension].$changed = false;
				formats[dimension].$invalid = false;
				return formats;
			}, {});
		}

		return form;
	}

	$scope.models.form = initializeForm($scope.models.adslots, resolvedPrices);
	// We are extending the list by adding an extra entry per size per Adslot.
	// This is necessary to be able to sort the result by price.
	// Yes, this is hacky but we will hopefully get a completely new pricing manager one day.
	$scope.models.adslots = $scope.models.adslots.map((adslot) => adslot.formats.map((format) => new Adslot(adslot).setFormat(format))).flat();

	$scope.onlyChangedFilter = function onlyChangedFilter(listItem) {
		return (
			!$scope.models.onlyChanged || // not checked? -> show all
			($scope.models.form[listItem.id] && $scope.models.form[listItem.id][listItem.format.dimension.toString()].$changed)
		);
	};

	/**
	 * @param {Number} id
	 * @param {String} dimension
	 * @returns {boolean} true, if price has changed state has changed
	 */
	function detectChangeAndValidate(id, dimension) {
		$scope.models.form[id][dimension].$changed = $scope.models.form[id][dimension].floorPrice !== $scope.models.form[id][dimension].$initial;
		$scope.models.form[id][dimension].$invalid = $scope.models.form[id][dimension].floorPrice === undefined; // eslint-disable-line angular/definedundefined

		return $scope.models.form[id][dimension].$changed;
	}

	function calculateChangedCount() {
		$scope.models.changedPricesCount = Object.values($scope.models.form).reduce(
			(changed, dimensions) =>
				changed + Object.values(dimensions).reduce((changedDimensions, dimension) => changedDimensions + (dimension.$changed === true ? 1 : 0), 0),
			0,
		);
	}

	$scope.checkChanged = function checkChanged(id, dimension) {
		if (detectChangeAndValidate(id, dimension)) {
			calculateChangedCount();
		}
	};

	$scope.isFormValid = function isFormValid() {
		for (const adslot of Object.values($scope.models.form)) {
			for (const dimension of Object.values(adslot)) {
				if (dimension.$invalid === true) {
					return false;
				}
			}
		}

		return true;
	};

	$scope.applyToAll = function applyToAll(id, dimension) {
		const filteredList = $scope.models.filteredListItems;

		// apply prices to the whole, currently visible list
		for (const adslot of filteredList) {
			const { id: adslotId } = adslot;
			const adslotDimension = adslot.format.dimension.toString();

			const priceToApply = $scope.models.form[id][dimension].floorPrice;

			const hasChangedAlready = $scope.models.form[adslotId][adslotDimension].$changed === true;
			const hasNotChangedYet = $scope.models.form[adslotId][adslotDimension].$changed === false;

			$scope.models.form[adslotId][adslotDimension].floorPrice = priceToApply;
			// detect change compared to initially loaded price
			$scope.models.form[adslotId][adslotDimension].$changed =
				$scope.models.form[adslotId][adslotDimension].floorPrice !== $scope.models.form[adslotId][adslotDimension].$initial;

			if (hasNotChangedYet && $scope.models.form[adslotId][adslotDimension].$changed === true) {
				$scope.models.changedPricesCount += 1;
			} else if (hasChangedAlready && $scope.models.form[adslotId][adslotDimension].$changed === false) {
				$scope.models.changedPricesCount -= 1;
			}
		}
	};

	$scope.discardChange = function discardChange(id, dimension) {
		if ($scope.models.form[id][dimension].floorPrice !== $scope.models.form[id][dimension].$initial) {
			$scope.models.form[id][dimension].floorPrice = $scope.models.form[id][dimension].$initial;

			$scope.models.form[id][dimension].$changed = false;
			$scope.models.form[id][dimension].$invalid = false;

			$scope.models.changedPricesCount = Math.max($scope.models.changedPricesCount - 1, 0);
		}
	};

	$scope.discardChanges = function discardChanges() {
		for (const adslot of $scope.models.adslots) {
			$scope.discardChange(adslot.id, adslot.format.dimension.toString());
		}
	};

	$scope.save = function save() {
		const prices = $scope.models.adslots.reduce((changed, adslot) => {
			const { id, format } = adslot;
			const { $changed, floorPrice } = $scope.models.form[id][format.dimension.toString()];
			if ($changed) {
				changed.push({ id, ...format.dimension.toObject(), floorPrice });
			}
			return changed;
		}, []);

		if (prices.length) {
			InfoService.startRequest();

			AdslotsPrices.update(prices)
				.then(function success() {
					// set current form state as initial state
					for (const formEntry of Object.values($scope.models.form)) {
						for (const dimensionEntry of Object.values(formEntry)) {
							dimensionEntry.$initial = dimensionEntry.floorPrice;
							dimensionEntry.$changed = false;
							dimensionEntry.$invalid = false;
						}
					}

					// update prices in the list to update price values used for sorting
					for (const adslot of $scope.models.adslots) {
						adslot.format.floorPrice = $scope.models.form[adslot.id][adslot.format.dimension.toString()].floorPrice;
					}

					$scope.methods.postSaveActions();

					InfoService.endRequest();
					toaster.successMessage('MESSAGE.SETTINGS_SAVE_SUCCESS');
				})
				.catch(function error() {
					InfoService.endRequest();
					toaster.errorMessage('MESSAGE.SETTINGS_SAVE_ERROR');
				});
		}
	};

	$scope.methods.initializeModels();

	const removeDestroyListener = $scope.$on('$destroy', function $destroyListener() {
		ListFilterSettings.set(listFilterId, $scope.models.adslotsFilters.getActiveFilterData());

		removeDestroyListener();
	});
}

export default PricingAdslotsController;
