import angular from 'angular';

import ConstantsModule from '../constants/constants.module.js';
import ModelsModule from '../models/models.module.js';
import ApiModule from '../modules/api/api.module.js';

import Account from './Account.js';
import Adslots from './Adslots.js';
import AdslotsPrices from './AdslotsPrices.js';
import AdslotTranslations from './AdslotTranslations.js';
import AdvertiserCategories from './AdvertiserCategories.js';
import Advertisers from './Advertisers.js';
import AdvertisersPrices from './AdvertisersPrices.js';
import AuthService from './AuthService.js';
import Bootstrapper from './Bootstrapper.js';
import Categories from './Categories.js';
import DemandPartners from './DemandPartners.js';
import DemandTechnologies from './DemandTechnologies.js';
import InfoService from './InfoService.js';
import InventoryFilter from './InventoryFilter.js';
import ListSearchService from './ListSearchService.js';
import ModalFactory from './ModalFactory.js';
import NativeTemplates from './NativeTemplates.js';
import PartnershipCategoryFilter from './PartnershipCategoryFilter.js';
import PartnershipFilter from './PartnershipFilter.js';
import Partnerships from './Partnerships.js';
import PartnershipsPrices from './PartnershipsPrices.js';
import PGAdslots from './PGAdslots.js';
import ReportExports from './ReportExports.js';
import ReportOrders from './ReportOrders.js';
import Reports from './Reports.js';
import ReportTemplates from './ReportTemplates.js';
import SupplyAdvertisers from './SupplyAdvertisers.js';
import SupplyCategoryFilter from './SupplyCategoryFilter.js';
import SupplyFilter from './SupplyFilter.js';
import UserInfo from './UserInfo.js';

const StateModule = angular // eslint-disable-line angular/no-service-method
	.module('app.state', [ConstantsModule, ApiModule, ModelsModule])

	.service('Account', Account)
	.service('Adslots', Adslots)
	.service('AdslotsPrices', AdslotsPrices)
	.service('AdslotTranslations', AdslotTranslations)
	.service('Advertisers', Advertisers)
	.service('AdvertiserCategories', AdvertiserCategories)
	.service('AdvertisersPrices', AdvertisersPrices)
	.service('AuthService', AuthService)
	.service('Bootstrapper', Bootstrapper)
	.service('Categories', Categories)
	.service('DemandPartners', DemandPartners)
	.service('DemandTechnologies', DemandTechnologies)
	.service('InfoService', InfoService)
	.service('InventoryFilter', InventoryFilter)
	.service('ListSearchService', ListSearchService)
	.service('ModalFactory', ModalFactory)
	.service('NativeTemplates', NativeTemplates)
	.service('PartnershipCategoryFilter', PartnershipCategoryFilter)
	.service('PartnershipFilter', PartnershipFilter)
	.service('Partnerships', Partnerships)
	.service('PartnershipsPrices', PartnershipsPrices)
	.service('PGAdslots', PGAdslots)
	.service('Reports', Reports)
	.service('ReportExports', ReportExports)
	.service('ReportTemplates', ReportTemplates)
	.service('ReportOrders', ReportOrders)
	.service('SupplyAdvertisers', SupplyAdvertisers)
	.service('SupplyCategoryFilter', SupplyCategoryFilter)
	.service('SupplyFilter', SupplyFilter)
	.service('UserInfo', UserInfo).name;

export default StateModule;
