import uiRouter from '@uirouter/angularjs';
import angular from 'angular';
import ngAnimate from 'angular-animate';
import ngCookies from 'angular-cookies';
import 'angular-http-auth';
import 'angular-i18n/angular-locale_de-de.js';
import 'angular-include-replace';
import 'angular-legacy-sortablejs-maintained';
import 'angular-mass-autocomplete';
import 'angular-modal-service';
import ngSanitize from 'angular-sanitize';
import NgShimsPlaceholder from 'angular-shims-placeholder';
import angularTranslate from 'angular-translate';
import 'angular-translate-loader-url';
import 'angular-translate-storage-cookie';
import 'angular-translate-storage-local';
import 'angular-ui-grid/ui-grid.core.js';
import 'angular-ui-grid/ui-grid.resize-columns.js';
import 'angular-ui-grid/ui-grid.selection.js';
import 'ng-grid/build/ng-grid.js';
import AppConfigRouting from './app-config-routing.js';
import AppConfig from './app-config.js';
import AppRun from './app-run.js';
import ComponentsModule from './components/components.module.js';
import MatomoModule from './modules/matomo/matomo.module.js';
import AngularDecorationsModule from './modules/ng-decorations/ng-decorations.module.js';
import AngularFiltersModule from './modules/ng-filters/ng-filters.module.js';
import ScreensModule from './screens/screens.module.js';

const modules = [
	ngAnimate,
	ngCookies,
	ngSanitize,

	MatomoModule,

	ComponentsModule,
	ScreensModule,

	AngularDecorationsModule,
	AngularFiltersModule,

	uiRouter,

	'ngGrid',
	'ui.grid',
	'ui.grid.selection',
	'ng-sortable',

	'MassAutoComplete',
	'angularModalService',
	NgShimsPlaceholder,
	angularTranslate,
	'http-auth-interceptor',
	'angular-include-replace',
];

if (process.env.NODE_ENV === 'production') {
	modules.push('ngSentry');
}

const AppModule = angular.module('app', modules).config(AppConfig).config(AppConfigRouting).run(AppRun).name;

export default AppModule;
