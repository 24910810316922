function CustomValidate($parse) {
	'ngInject';

	return {
		restrict: 'A',
		scope: false,
		require: ['ngModel', '^form'],
		link: function postLink(scope, iElement, iAttrs, ctrls) {
			var submitAttempted,
				domForm,
				modelCtrl = ctrls[0],
				validators = null;

			domForm = iElement.parent();
			while (domForm.get(0).tagName.toLowerCase() !== 'form') {
				domForm = domForm.parent();
			}

			function registerAdditionalValidators() {
				Object.keys(validators).forEach(function addValidators(validatorKey) {
					modelCtrl.$validators[validatorKey] = function validatorFn() {
						// validation is only executed on submit
						if (submitAttempted) {
							submitAttempted = false;
							return validators[validatorKey](modelCtrl.$viewValue.trim());
						}

						return true;
					};
				});
			}

			// hook into form submit
			function submitListener() {
				if (validators === null) {
					validators = $parse(iAttrs.ylCustomValidate)(scope);
					registerAdditionalValidators();
				}
				submitAttempted = true;
				modelCtrl.$validate();
				scope.$apply();
			}
			domForm.on('submit', submitListener);

			scope.cleanListeners(scope, [
				function cleanDomListener() {
					domForm.off('submit', submitListener);
				},
			]);
		},
	};
}

export default CustomValidate;
