export default {
	VPAID_1_0: 1,
	VPAID_2_0: 2,
	MRAID_1: 3,
	MRAID_2: 5,
	MRAID_3: 6,
	OMID_1_0: 7,
	SIMID_1_0: 8,
	SIMID_1_1: 9,
};
