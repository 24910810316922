import angular from 'angular';

import PartnershipsOpenAuctionsController from './partnerships-open-auctions.controller.js';
import PartnershipsOpenAuctionsViewDetailsController from './partnerships-open-auctions-view-details.controller.js';
import PartnershipsOpenAuctionsViewAdslotsController from './partnerships-open-auctions-view-adslots.controller.js';
import PartnershipsOpenAuctionsViewAdslotsAddController from './partnerships-open-auctions-view-adslots-add.controller.js';
import PartnershipsOpenAuctionsViewFilterController from './partnerships-open-auctions-view-filter.controller.js';

const PartnershipsOpenAuctionsModule = angular
	.module('partnerships.open-auctions', [])
	.controller('PartnershipsOpenAuctionsController', PartnershipsOpenAuctionsController)
	.controller('PartnershipsOpenAuctionsViewDetailsController', PartnershipsOpenAuctionsViewDetailsController)
	.controller('PartnershipsOpenAuctionsViewAdslotsController', PartnershipsOpenAuctionsViewAdslotsController)
	.controller('PartnershipsOpenAuctionsViewAdslotsAddController', PartnershipsOpenAuctionsViewAdslotsAddController)
	.controller('PartnershipsOpenAuctionsViewFilterController', PartnershipsOpenAuctionsViewFilterController).name;

export default PartnershipsOpenAuctionsModule;
