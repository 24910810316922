import ErrorCollector from '../../../modules/utils/ErrorCollector.js';
import jschardet from 'jschardet';

function UploadAdslotCSVController(
	$rootScope,
	$scope,
	$filter,
	$timeout,
	close,
	ModalCloser,
	Adslots,
	AdslotGroups,
	AdslotCsvService,
	InfoService,
	ObjectsHelperService,
	AuthService,
	NativeTemplates,
) {
	'ngInject';

	let vm = this;
	let unbindAuthListener;
	let unbindDestroyListener;

	let errors = new ErrorCollector();
	let fileHasBeenParsed = false;
	let adslotSubmitted = false;

	let csvRowsByActionMeta = [];
	let updateAdslotFailed = false;
	let changedAdslots = [];
	let newAdslots = [];

	// Interface to the template
	vm.chosenFile = null; // bound to the file picker
	vm.onFileChosen = () => {
		fileHasBeenParsed = false;
		adslotSubmitted = false;
		csvRowsByActionMeta = [];
		vm.totalChangedAdslots = 0;
		vm.totalNewAdslots = 0;
		updateAdslotFailed = false;

		clearErrors();
	};
	vm.clearFileSelection = clearFileSelection;
	vm.isFileChosen = () => vm.chosenFile !== null;

	vm.errorsExists = () => errors.errorsExists();
	vm.errorText = '';
	vm.canDisplaySummary = () => errors.isTimeoutError() === false;

	vm.shouldShowSummary = () => fileHasBeenParsed && !InfoService.isRequestInProgress();
	vm.isUpdateAdslotFailed = () => updateAdslotFailed;
	vm.totalChangedAdslots = 0;
	vm.totalNewAdslots = 0;

	vm.save = save;
	vm.canSubmit = () => vm.chosenFile !== null && !adslotSubmitted;
	vm.close = debounceClose;

	vm.uploadWarningMessageTimer = null;
	vm.showUploadWarningMessage = false;
	vm.shouldShowUploadWarningMessage = () => vm.showUploadWarningMessage && InfoService.isRequestInProgress();

	function save() {
		adslotSubmitted = true;
		InfoService.startRequest();

		vm.uploadTimerWarningMessage = $timeout(() => {
			vm.showUploadWarningMessage = true;
		}, 5000); // If the upload takes longer than 5 sec show the warning message

		readAndParseFile()
			.then(() => sendUpdatedAdslots())
			.then(() => sendNewAdslots())
			.catch(() => {
				/* Don't need any error handling here */
			})
			.finally(() => {
				if (vm.uploadTimerWarningMessage != null) {
					$timeout.cancel(vm.uploadTimerWarningMessage);
					vm.uploadTimerWarningMessage = null;
					vm.showUploadWarningMessage = false;
				}

				createSummaryData();
				InfoService.endRequest();

				if (changedAdslots.length || newAdslots.length) {
					$rootScope.$emit(Adslots.EVENT.RECREATED);
				}
				// After all, promises are resolved the summary might be hidden, to force display trigger "update bindings"
				$scope.$apply();
			});
	}

	async function sendUpdatedAdslots() {
		if (InfoService.isRequestInProgress() && !vm.errorsExists()) {
			let changedRecords = changedAdslots.map((x) => x.adslot);

			if (changedRecords.length) {
				vm.totalChangedAdslots = changedRecords.length;

				let requestBody = changedRecords.map((adslot) => adslot.getRequestBody(ADSLOT_FIELDS_TO_CHECK, true));
				await Adslots.patchUpdate(requestBody, true).catch((errorObject) => {
					handleApiRequestResponseError(errorObject, changedRecords, false);
					setErrorText();
					return Promise.reject();
				});
			}
		}
	}

	async function sendNewAdslots() {
		if (InfoService.isRequestInProgress() && !vm.errorsExists()) {
			let defaultAdslotGroup = getDefaultAdslotGroup();
			if (defaultAdslotGroup === null) {
				return;
			}

			let localNewAdslots = newAdslots.map((x) => x.adslot);

			if (localNewAdslots.length) {
				let requestBody = localNewAdslots.map((adslot) => adslot.getRequestBody(ADSLOT_FIELDS_TO_CHECK));

				await Adslots.add(defaultAdslotGroup.id, requestBody, true)
					.then(() => InfoService.endRequest())
					.catch((errorObject) => {
						handleApiRequestResponseError(errorObject, localNewAdslots, true);
						setErrorText();
						return Promise.reject();
					});
			}
		}
	}

	function getDefaultAdslotGroup() {
		let allAdslotGroups = AdslotGroups.getAsIdNameObjectsList();
		let defaultAdslotGroup = allAdslotGroups.filter((row) => row.isDefault);
		if (angular.isArray(defaultAdslotGroup) && defaultAdslotGroup.length) {
			return defaultAdslotGroup[0];
		}
		return null;
	}

	function handleApiRequestResponseError(errorObject, adslotsFromRequest, isCreate) {
		if (!Object.prototype.hasOwnProperty.call(errorObject, 'attributes')) {
			errors.recordGlobalError(errorObject);
		} else {
			for (const errorAttribute in errorObject.attributes) {
				if (Object.prototype.hasOwnProperty.call(errorObject.attributes, errorAttribute)) {
					const rowIndex = isCreate ? newAdslots[errorAttribute].line : changedAdslots[errorAttribute].line;
					const attributeErrors = errorObject.attributes[errorAttribute].errors;

					recordApiRequestResponseError(adslotsFromRequest, errorAttribute, rowIndex, attributeErrors);
				}
			}
		}
	}

	function recordApiRequestResponseError(adslotsFromRequest, errorAttributeIndex, rowIndex, attributeErrors) {
		Object.values(attributeErrors).forEach((errorRecord) => {
			if (errorRecord.code === 8) {
				// 'Empty Request
				// An empty request occurs when an API request does not have more than one valid property.
				return;
			}

			const errorCallBack = EXCEPTIONS_LOOKUP[errorRecord.code];
			if (errorCallBack) {
				errorCallBack(errors, rowIndex, errorRecord, adslotsFromRequest, errorAttributeIndex);
			} else {
				errors.recordError(rowIndex, errorRecord.errorMessage, 'MESSAGE.ADSLOT_UPLOAD_ERROR_DEFAULT');
			}
		});
	}

	function createSummaryData() {
		vm.totalNewAdslots = newAdslots.length;
		vm.totalChangedAdslots = changedAdslots.length;
		csvRowsByActionMeta.forEach((item) => {
			if (errors.errorExistsForLine(item.line)) {
				if (item.action !== 'create') {
					updateAdslotFailed = true;
				}
			}
		});
	}

	async function readAndParseFile() {
		clearErrors();

		await readFile(vm.chosenFile)
			.catch(() => Promise.reject(Error('MESSAGE.ADSLOT_UPLOAD_ERROR_READFILE')))
			// string -> array[array[string]]
			.then((content) =>
				AdslotCsvService.parseCSVString(content, EXPECTED_HEADERS).catch(() => Promise.reject(Error('MESSAGE.ADSLOT_UPLOAD_ERROR_INVALID_HEADER'))),
			)
			// array[array[string]] -> array[row{line,tokens}]
			.then(tokensToRows)
			// array[row{line,tokens}] empty?
			.then(rejectIfEmpty)
			// array[row{line,tokens}] -> array[row{line,adslot}]
			.then(addAdslotsToRows)
			.then(checkForDuplicateIds)
			.then(collectAdslotsMetaByActionForSummary)
			.then((rows) => applyFieldValidationFn(rows, validateMandatoryAndFillWithDefaults))
			.then((rows) => applyFieldValidationFn(rows, validateUpdatedAdslots))
			.then((rows) => applyFieldValidationFn(rows, validateNativeTemplateId))
			.then(removeInvalidRows)
			.then(removeNonExistingAdslotIDs)
			.then((rows) =>
				rows.map((row) => {
					row.adslot.clearUnavailableFields(ADSLOT_FIELDS_TO_CHECK);
					return row;
				}),
			)
			.then((rows) => {
				// load details for all adslots to be changed in order to determine changes in attributes not
				// loaded before (categories)
				let adslotIds = rows.filter((row) => row.adslot.id !== null).map((row) => row.adslot.id);

				let detailsLoaded = Adslots.loadDetails(adslotIds);
				// We need to wait here, until the promise from "loadDetails" resolves as well, so we can access
				// the details in the next step
				return Promise.all([detailsLoaded, rows]);
			})
			.then((data) => {
				let rows = data[1]; // Ignore (undefined) result from first promise
				changedAdslots = rows.filter((row) => row.adslot.id !== null).filter(adslotHasChanged);

				newAdslots = rows.filter((row) => row.adslot.id === null);

				fileHasBeenParsed = true;
				setErrorText();
			})
			.catch((error) => {
				errors.recordGlobalError(error);

				changedAdslots = [];
				newAdslots = [];

				setErrorText();
				InfoService.endRequest();
			});
	}

	function clearErrors() {
		errors.clearErrors();
		vm.errorText = '';
	}

	/**
	 * Translate all collected error codes into proper errors messages and concatenate them to a single string, sorted
	 * by line number.
	 */
	function setErrorText() {
		vm.errorText = errors
			.getLinesWithErrors()
			.sort((a, b) => a - b) // sort by value, not alphabetically
			.flatMap((lineNr) => errors.getErrorsForLine(lineNr))
			.map((error) =>
				$filter('translate')(error.errorType, {
					attribute: error.fieldName,
					row: error.lineNr,
				}),
			)
			.join('\n');
	}

	/**
	 * Reading a file
	 * @param {Blob} file
	 * @returns {Promise} promise
	 */
	function readFile(file) {
		return file.arrayBuffer().then((buffer) => {
			const byteBuffer = new Uint8Array(buffer);
			const rawString = Uint8ArrayToString(byteBuffer);
			const encoding = jschardet.detect(rawString, {
				detectEncodings: ['windows-1252', 'UTF-8'],
			}).encoding;
			const decoder = new TextDecoder(encoding);
			return decoder.decode(byteBuffer);
		});
	}

	function Uint8ArrayToString(uint8array) {
		// String.fromCharCode(...buffer) will crush the stack
		let rawString = '';
		for (let x of uint8array) {
			rawString += String.fromCharCode(x);
		}
		return rawString;
	}

	/**
	 * Wrap an array of CSV lines into an array of row objects (in order to preserve line number information)
	 */
	function tokensToRows(tokenLines) {
		return (
			tokenLines
				.map((tokens, index) => {
					return {
						line: index + 2, // +1 for header, +1 for line number starting at 1
						tokens: tokens,
					};
				})
				// remove empty lines
				.filter((row) => row.tokens.length !== 1 || row.tokens[0] !== null)
		);
	}

	function rejectIfEmpty(array) {
		if (array.length === 0) {
			return Promise.reject(Error('MESSAGE.ADSLOT_UPLOAD_ERROR_NO_ADSLOTS'));
		}
		return Promise.resolve(array);
	}

	/**
	 * Convert array of CSV tokens into adslot and add the adslot object to the wrapper object
	 */
	function addAdslotsToRows(rows) {
		return (
			rows
				.map((row) => {
					let parsedAdslot = null;
					if (row.tokens.length !== EXPECTED_HEADERS.length) {
						errors.recordLineError(row.line, 'MESSAGE.ADSLOT_UPLOAD_ERROR_INVALID_NUMBER_OF_FIELDS');
					} else {
						parsedAdslot = AdslotCsvService.csvTokensToAdslot(row.tokens, (fieldName) =>
							errors.recordError(row.line, fieldName, 'MESSAGE.ADSLOT_UPLOAD_ERROR_INVALID'),
						);
					}
					return {
						line: row.line,
						adslot: parsedAdslot,
					};
				})
				// Not parsable rows result in null, so remove them
				.filter((row) => row.adslot !== null)
		);
	}

	function checkForDuplicateIds(rows) {
		const containedIds = new Set();
		rows
			.filter((row) => row.adslot.id != null)
			.forEach((row) => {
				if (containedIds.has(row.adslot.id)) {
					errors.recordLineError(row.line, 'MESSAGE.ADSLOT_UPLOAD_ERROR_DUPLICATE_ID');
				} else {
					containedIds.add(row.adslot.id);
				}
			});
		return rows;
	}

	function collectAdslotsMetaByActionForSummary(rows) {
		rows.forEach((row) => {
			csvRowsByActionMeta.push({
				line: row.line,
				action: row.adslot.id != null ? 'update' : 'create',
			});
		});
		return rows;
	}

	function applyFieldValidationFn(rows, validateFn) {
		function createErrorFn(row) {
			return (columnName, errorType) => errors.recordUniqueFieldError(row.line, columnName, errorType);
		}
		return rows.map((row) => {
			validateFn(row.adslot, createErrorFn(row));
			return row;
		});
	}

	/**
	 * Validate adslot for required properties. If not required, set a default value.
	 */
	function validateMandatoryAndFillWithDefaults(adslot, errorFn) {
		function checkProperty(propertyName, isRequired, csvColumnName, fallback) {
			if (adslot[propertyName] === null) {
				if (isRequired) {
					errorFn(csvColumnName, 'MESSAGE.ADSLOT_UPLOAD_ERROR_REQUIRED');
				} else {
					adslot[propertyName] = fallback;
				}
			}
		}

		Object.entries(CSV_FIELDS).forEach(([csvName, field]) => {
			if (field.includeInChecks) {
				checkProperty(field.fieldName, adslot.isFieldMandatory(field.fieldName), csvName, field.getDefaultValue(adslot));
			}
		});

		// Only `YL_ADMIN` users can provide the 'visible` property
		if (!AuthService.isYlAdmin()) {
			adslot.visible = null;
		}
	}

	function validateUpdatedAdslots(adslot, errorFn) {
		if (adslot.id === null) {
			return;
		}

		const existingAdslot = Adslots.getById(adslot.id);
		if (!existingAdslot) {
			errorFn('id', 'MESSAGE.ADSLOT_UPLOAD_ERROR_INVALID_ID');
			return;
		}

		if (!ObjectsHelperService.objectsAreEqual(existingAdslot.adType, adslot.adType)) {
			errorFn('adType', 'MESSAGE.ADSLOT_UPLOAD_ERROR_PLATFORM_ADTYPE_CANNOT_EDIT');
		} else if (!ObjectsHelperService.objectsAreEqual(existingAdslot.platformType, adslot.platformType)) {
			errorFn('platformType', 'MESSAGE.ADSLOT_UPLOAD_ERROR_PLATFORM_ADTYPE_CANNOT_EDIT');
		}

		if (!adslot.name) {
			errorFn('name', 'MESSAGE.ADSLOT_UPLOAD_ERROR_REQUIRED');
		}

		if (adslot.isFieldMandatory('url') && !adslot.url) {
			errorFn('url', 'MESSAGE.ADSLOT_UPLOAD_ERROR_REQUIRED');
		}

		if (adslot.isFieldMandatory('formats') && !adslot.formats) {
			errorFn('sizes', 'MESSAGE.ADSLOT_UPLOAD_ERROR_REQUIRED');
		}
	}

	function validateNativeTemplateId(adslot, errorFn) {
		if (adslot.nativeTemplateId === null) {
			return;
		}

		if (adslot.isFieldAllowed('nativeTemplateId')) {
			const existingNativeTemplate = NativeTemplates.getById(adslot.nativeTemplateId);
			if (!existingNativeTemplate) {
				errorFn(adslot.nativeTemplateId, 'MESSAGE.ADSLOT_UPLOAD_ERROR_NATIVE_TEMPLATE_ID');
			}
		}
	}

	function removeInvalidRows(rows) {
		return rows.filter((row) => !errors.errorExistsForLine(row.line));
	}

	function removeNonExistingAdslotIDs(rows) {
		return rows.map(recordErrorForNonExistingID).filter((row) => row.adslot.id === null || Adslots.exists(row.adslot.id));
	}

	function recordErrorForNonExistingID(row) {
		if (row.adslot.id !== null && !Adslots.exists(row.adslot.id)) {
			errors.recordError(row.line, 'id', 'MESSAGE.ADSLOT_UPLOAD_ERROR_INVALID_ID');
		}
		return row;
	}

	/**
	 * Check, if an adslot has changed. Only consider provided properties that are not null.
	 */
	function adslotHasChanged(row) {
		let existingAdslot = Adslots.getById(row.adslot.id);

		let fieldShouldBeCompared = (propertyName) => ADSLOT_DELETABLE_FIELDS.includes(propertyName) || row.adslot[propertyName] !== null;
		let adslotPropertyHasChanged = (propertyName) =>
			fieldShouldBeCompared(propertyName) && !ObjectsHelperService.objectsAreEqual(row.adslot[propertyName], existingAdslot[propertyName]);

		return ADSLOT_FIELDS_TO_CHECK.some(adslotPropertyHasChanged);
	}

	function clearFileSelection() {
		vm.chosenFile = null;
		changedAdslots = [];
		newAdslots = [];
		adslotSubmitted = false;
		clearErrors();
	}

	function debounceClose() {
		ModalCloser.close(close, undefined, 0);
	}

	unbindAuthListener = $rootScope.$on('event:auth-loginRequired', vm.close);
	unbindDestroyListener = $scope.$on('$destroy', function destroyListener() {
		unbindAuthListener();
		unbindDestroyListener();
	});
}

//
//  Additional helper classes, constants and functions
//

class AdslotField {
	// constructor(options) {
	constructor(
		fieldName,
		includeInChecks = true,
		getDefaultValue = () => {
			/* No default value */
		},
		deletable = false,
	) {
		this.fieldName = fieldName;
		this.includeInChecks = includeInChecks;
		this.getDefaultValue = getDefaultValue;
		this.deletable = deletable;
	}
}

// key = name in CSV; AdslotField.fieldName = name in Adslot object/API
const CSV_FIELDS = {
	id: new AdslotField('id', false),
	adType: new AdslotField('adType'),
	platformType: new AdslotField('platformType'),
	name: new AdslotField('name'),
	sizes: new AdslotField('formats'),
	floorPrices: new AdslotField('-', false), // sizes & floorPrices are combined to 'formats'
	url: new AdslotField('url'),
	categories: new AdslotField('categories', true, () => []),
	position: new AdslotField('position', true, () => 0),
	visible: new AdslotField('visible', true, () => null),
	appName: new AdslotField('mobileAppName'),
	bundleName: new AdslotField('mobileBundleName'),
	mobileOS: new AdslotField('mobileOs'),
	storeUrl: new AdslotField('storeUrl'),
	videoPosition: new AdslotField('startDelay'),
	videoPlacementType: new AdslotField('videoPlacementType'),
	playbackMethod: new AdslotField('playbackMethod', true, () => null),
	minDuration: new AdslotField('minDuration', true, () => 0),
	maxDuration: new AdslotField('maxDuration', true, () => 600),
	mimeTypes: new AdslotField('mimes'),
	protocols: new AdslotField('protocols'),
	skippability: new AdslotField('skippable', true, (adslot) => {
		if (['WEB_VIDEO', 'MOBILE_WEB_VIDEO', 'MOBILE_APP_VIDEO', 'CONNECTED_TV_VIDEO'].includes(adslot.getInventoryTypeName())) {
			return false;
		}
		return null;
	}),
	minBitrate: new AdslotField('minBitrate', true, () => null, true),
	maxBitrate: new AdslotField('maxBitrate', true, () => null, true),
	placementType: new AdslotField('placementType'),
	nativeTemplateId: new AdslotField('nativeTemplateId', true, () => null, true),
	apiFramework: new AdslotField('api', true, () => []),
	firstprice: new AdslotField('auctionType', true, () => 0),
	overrideReferrer: new AdslotField('overrideReferer', true, () => false),
};

// Adslot CSV is expected to have these headers in this exact order.
const EXPECTED_HEADERS = Object.keys(CSV_FIELDS);

const ADSLOT_FIELDS_TO_CHECK = Object.values(CSV_FIELDS)
	.filter((field) => field.includeInChecks)
	.map((field) => field.fieldName);

// These fields can be deleted by providing a null value (empty CSV field)
const ADSLOT_DELETABLE_FIELDS = Object.values(CSV_FIELDS)
	.filter((field) => field.deletable)
	.map((field) => field.fieldName);

const EXCEPTIONS_LOOKUP = {
	4: (errors, rowIndex, errorRecord) => {
		return handleFieldError(errors, rowIndex, errorRecord);
	},
	10008: (errors, rowIndex) => {
		errors.recordLineError(rowIndex, 'MESSAGE.ADSLOT_UPLOAD_ERROR_NAME_LENGTH');
	},
	10011: (errors, rowIndex, errorRecord, newAdslots, errorAttributeIndex) => {
		if (newAdslots[errorAttributeIndex] && Object.prototype.hasOwnProperty.call(newAdslots[errorAttributeIndex], 'categories')) {
			errors.recordError(rowIndex, newAdslots[errorAttributeIndex].categories.toString(), 'MESSAGE.ADSLOT_UPLOAD_ERROR_CATEGORY');
		} else {
			errors.recordLineError(rowIndex, errorRecord.errorMessage);
		}
	},
	10014: (errors, rowIndex) => {
		errors.recordLineError(rowIndex, 'MESSAGE.ADSLOT_UPLOAD_ERROR_PROTOCOLS');
	},
	10015: (errors, rowIndex) => {
		errors.recordLineError(rowIndex, 'MESSAGE.ADSLOT_UPLOAD_ERROR_API_FRAMEWORK');
	},
	10016: (errors, rowIndex) => {
		errors.recordError(rowIndex, 'videoPosition', 'MESSAGE.ADSLOT_UPLOAD_ERROR_INVALID');
	},
	10017: (errors, rowIndex) => {
		errors.recordError(rowIndex, 'videoPlacementType', 'MESSAGE.ADSLOT_UPLOAD_ERROR_INVALID');
	},
	10020: (errors, rowIndex) => {
		errors.recordLineError(rowIndex, 'MESSAGE.ADSLOT_UPLOAD_ERROR_MOBILE_OS');
	},
	10021: (errors, rowIndex) => {
		errors.recordLineError(rowIndex, 'MESSAGE.ADSLOT_UPLOAD_ERROR_URL');
	},
	10022: (errors, rowIndex) => {
		errors.recordLineError(rowIndex, 'MESSAGE.ADSLOT_UPLOAD_ERROR_PLATFORM_ADTYPE_COMBINATION');
	},
	30005: (errors, rowIndex, errorRecord) => {
		return handleFieldError(errors, rowIndex, errorRecord);
	},
};

const handleFieldError = (errors, rowIndex, errorRecord) => {
	if (Object.prototype.hasOwnProperty.call(errorRecord, 'field')) {
		let fieldName = errorRecord.field.split('.').pop();

		// In CSV file the `startDelay` is called `videoPosition`
		if (fieldName === 'startDelay') {
			fieldName = 'videoPosition';
		}

		errors.recordError(rowIndex, fieldName, 'MESSAGE.ADSLOT_UPLOAD_ERROR_INVALID');
	} else {
		errors.recordLineError(rowIndex, errorRecord.errorMessage);
	}
};

export default UploadAdslotCSVController;
