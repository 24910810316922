function Bootstrapper(
	$q,
	$rootScope,
	Account,
	Adslots,
	AdslotTranslations,
	AdvertiserCategories,
	Advertisers,
	AuthService,
	Channels,
	httpBuffer,
	DemandPartners,
	DemandTechnologies,
	InfoService,
	ListFilterSettings,
	ListSearchService,
	PagerService,
	Partnerships,
	PGAdslots,
	SupplyFilter,
	UserInfo,
	Users,
) {
	'ngInject';

	// maximum value throttling-filter.concurrent.per-user from middleware
	const concurrencyLimit = 5;
	// do slow requests first
	const requests = [
		Advertisers.loadAll,
		Partnerships.loadAll,
		Adslots.loadAll,
		DemandTechnologies.loadAll,
		Channels.loadAll,
		AdslotTranslations.loadAll,
		PGAdslots.loadAll,
		DemandPartners.loadAll,
		AdvertiserCategories.loadAll,
	];

	function runRequestsConcurrently() {
		const results = [];
		let index = 0;

		function runNextRequest() {
			if (index >= requests.length) {
				// no more requests from here
				return Promise.resolve();
			}

			const currentRequestIndex = index;
			const currentRequest = requests[index];
			index++;

			return currentRequest()
				.then((result) => {
					results[currentRequestIndex] = result;
				})
				.then(runNextRequest);
		}

		// start the initial requests up to the concurrency limit
		const promises = [];
		for (let i = 0; i < concurrencyLimit && i < requests.length; i++) {
			promises.push(runNextRequest());
		}

		return Promise.all(promises).then(() => results);
	}

	/**
	 * @returns {promise}
	 */
	this.bootstrap = function bootstrap() {
		InfoService.startLoadingAppData();

		return $q
			.all([UserInfo.load(), Account.load(), SupplyFilter.get()])
			.then(() => runRequestsConcurrently())
			.then(function generalInitialization() {
				PagerService.reset();
				ListFilterSettings.reset();
				ListSearchService.reset();
				return true;
			})
			.then(function success() {
				// remove all (potentially) buffered http requests in http-auth-interceptor
				httpBuffer.rejectAll();

				// async prefetch user list for faster access
				if (AuthService.isYlAdmin()) {
					Users.supply();
				}
				return true;
			})
			.catch(function error() {
				InfoService.endLoadingAppData();
				$rootScope.$broadcast('event:bootstrap-load-data-error');
				return $q.reject(false);
			})
			.finally(function eventually() {
				$rootScope.$emit('event:bootstrap-done');
			});
	};
}

export default Bootstrapper;
