function AdvertiserModel() {
	/**
	 * @param {Object} data
	 */
	function Advertiser(data) {
		var key;

		/**
		 * @type {int}
		 */
		this.id = 0;

		/**
		 * @type {string}
		 */
		this.name = '';

		/**
		 * @type {boolean}
		 */
		this.enabled = false;

		for (key in data) {
			if (Object.prototype.hasOwnProperty.call(data, key) && Object.prototype.hasOwnProperty.call(this, key)) {
				this[key] = data[key];
			}
		}
	}

	/**
	 * @returns {int} id
	 */
	Advertiser.prototype.getId = function getId() {
		return this.id;
	};

	/**
	 * @returns {string} name
	 */
	Advertiser.prototype.getName = function getName() {
		return this.name;
	};

	/**
	 * @returns {boolean} enabled
	 */
	Advertiser.prototype.isEnabled = function isEnabled() {
		return this.enabled;
	};

	return Advertiser;
}

export default AdvertiserModel;
