import angular from 'angular';

import ImpersonateController from './authentication-impersonate.controller.js';
import LoginController from './authentication-login.controller.js';
import LogoutController from './authentication-logout.controller.js';
import ResetPasswordController from './authentication-reset-password.controller.js';

const AuthenticationModule = angular
	.module('authentication', [])
	.controller('ImpersonateController', ImpersonateController)
	.controller('LoginController', LoginController)
	.controller('LogoutController', LogoutController)
	.controller('ResetPasswordController', ResetPasswordController).name;

export default AuthenticationModule;
