import { addDays, endOfDay, endOfMonth, startOfDay, startOfWeek as startOfWeekRaw } from 'date-fns';

/**
 * @param {Date} date
 * @returns {Date}
 */
const startOfWeek = (date) => startOfWeekRaw(date, { weekStartsOn: 1 });

/**
 * taken from: https://stackoverflow.com/a/44480326
 * @returns {Date}
 */
function getEasterSunday() {
	// Instantiate the date object.
	const date = new Date();
	const year = date.getFullYear();

	// Set the timestamp to midnight.
	date.setHours(0, 0, 0, 0);

	date.setFullYear(year);

	// Find the golden number.
	const golden = year % 19;

	// Choose which version of the algorithm to use based on the given year.
	const b = year >= 2200 && year <= 2299 ? (11 * golden + 4) % 30 : (11 * golden + 5) % 30;

	// Determine whether or not to compensate for the previous step.
	const c = b === 0 || (b === 1 && golden > 10) ? b + 1 : b;

	// Use c first to find the month: April or March.
	const month = c >= 1 && c <= 19 ? 3 : 2;

	// Then use c to find the full moon after the northward equinox.
	const day = (50 - c) % 31;

	// Mark the date of that full moon—the "Paschal" full moon.
	date.setMonth(month, day);

	// Count forward the number of days until the following Sunday (Easter).
	date.setMonth(month, day + (7 - date.getDay()));

	// Gregorian Western Easter Sunday
	return date;
}

function LoginBackground(Seasons) {
	'ngInject';

	const HAS_BACKGROUND_CLASS = 'has-background';

	const BACKGROUND_TOPICS = [
		{
			/*
			 * Easter time is considered as the week (starting on monday) before easter sunday (which marks the end of that week)
			 * until easter monday.
			 */
			isActive: function isEasterTime(now) {
				const easterSunday = getEasterSunday();
				return now >= startOfDay(startOfWeek(easterSunday)) && now <= addDays(endOfDay(easterSunday), 1);
			},
			topicClass: 'easter',
		},
		{
			isActive: function isValentinesDay(now) {
				return now >= startOfDay(new Date().setMonth(1, 14)) && now <= endOfDay(new Date().setMonth(1, 14));
			},
			topicClass: 'valentine',
		},
		{
			isActive: function isBeginningOfSummer(now) {
				const beginningOfSummer = Seasons.beginningOfSummer();
				return now >= startOfDay(beginningOfSummer) && now <= endOfDay(endOfMonth(beginningOfSummer));
			},
			topicClass: 'beginning-summer',
		},
		{
			isActive: function isWinter(now) {
				return (
					(now >= startOfDay(new Date().setMonth(11, 1)) && now <= endOfDay(new Date().setMonth(11, 31))) ||
					(now >= startOfDay(new Date().setMonth(0, 1)) && now <= endOfDay(new Date().setMonth(0, 6)))
				);
			},
			topicClass: 'winter',
		},
	];

	/**
	 * @param {Date} now
	 * @returns {string|undefined}
	 */
	function getBackgroundTopic(now) {
		let i;
		let topicClass;
		let topicCount = BACKGROUND_TOPICS.length;

		for (i = 0; !topicClass && i < topicCount; i++) {
			if (BACKGROUND_TOPICS[i].isActive(now)) {
				topicClass = BACKGROUND_TOPICS[i].topicClass;
			}
		}

		return topicClass;
	}

	return {
		restrict: 'A',
		link: function postLink(scope, iElement) {
			const backgroundTopicClass = getBackgroundTopic(new Date());

			// We recently applied the new Virtual Minds rebranding to YRD and especially changed the login screen
			// a lot. As we currently have several other topics of higher priority pending, we want to disable the
			// login background functionality until we find time to properly adjust the background to the new look.
			// eslint-disable-next-line no-constant-condition
			if (backgroundTopicClass) {
				iElement.addClass(HAS_BACKGROUND_CLASS);
				iElement.addClass(backgroundTopicClass);
			}
		},
	};
}

export default LoginBackground;
