function Adtags(Api) {
	'ngInject';

	/**
	 * @param {Number|Number[]} adslotIds
	 * @returns {promise}
	 */
	this.forAdslot = (adslotIds) => {
		const ids = angular.isArray(adslotIds) ? adslotIds : [adslotIds];

		return Api.get(`api/supply/v1/adslots/${ids.join(',')}/tags`);
	};

	/**
	 * @param {Number|Number[]} partnershipIds
	 * @returns {promise}
	 */
	this.forPGPartnership = function forPGPartnership(partnershipIds) {
		const ids = angular.isArray(partnershipIds) ? partnershipIds : [partnershipIds];

		return Api.get(`api/supply/v1/partnerships/${ids.join(',')}/tags`);
	};

	/**
	 * @param {{name: String, id: Number}} adslot
	 * @param {string} adtag
	 * @returns {string}
	 */
	this.generateAdslotAdtagText = ({ name, id }, adtag) => '<!-- ' + name + ' (id ' + id + ') -->\n' + adtag;

	/**
	 * @param {{name: String}} partnership
	 * @param {{adTag: String, key: String}[]} adtagsList
	 * @returns {string}
	 */
	this.generatePGAdtagsText = ({ name }, adtagsList) => {
		const result = adtagsList.map(({ adTag, key }) => {
			return '\t<!-- ' + key + ' -->\n\t' + adTag;
		});

		return '<!-- ' + name + ' -->\n' + (result.length ? result.join('') : '\tno tags\n') + '<!-- end of ' + name + ' -->\n';
	};
}

export default Adtags;
