import { ascByNameField } from '../../utils/sort-utils.js';

function ReportsDemandPartnerFilterController($rootScope, $scope, DemandPartners, ModalCloser, close, filterData) {
	'ngInject';

	const vm = this;

	vm.demandPartners = DemandPartners.getListRtbDC().sort(ascByNameField);
	vm.filterData = filterData;

	let selection = [];

	vm.onSelectionChange = ($event) => {
		selection = $event.selection;
	};

	vm.close = (result, delay) => {
		ModalCloser.close(close, result, delay);
	};

	vm.cancel = () => {
		vm.close(filterData, 200);
	};

	vm.submit = () => {
		vm.close(
			selection.map((entry) => entry.id),
			200,
		);
	};

	$scope.$on('$destroy', $rootScope.$on('event:auth-loginRequired', vm.cancel));
}

export default ReportsDemandPartnerFilterController;
