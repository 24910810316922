function DirectConnectAccountSettings(Api) {
	'ngInject';

	/**
	 * @return {promise}
	 */
	this.load = () => Api.get('/api/supply/v1/settings/direct-connect');
}

export default DirectConnectAccountSettings;
