const DEFAULTS = {
	page: 1,
	perPage: 100,
	items: 0,
	maxPage: 1,
};

const noop = () => undefined;

/**
 * @param {int} id
 */
function Pager(id) {
	this.id = id;

	this.onPageChange = noop;

	this.reset();
}

Pager.prototype.reset = function reset() {
	return Object.assign(this, DEFAULTS);
};

/**
 * @param {int} perPage
 * @returns Pager
 */
Pager.prototype.setPerPage = function setPerPage(perPage) {
	this.perPage = Math.max(perPage, 1);
	this.updateMaxPage();

	// always reset to first page when per page count has changed
	return this.goTo(1);
};

/**
 * @param {int} items
 * @returns Pager
 */
Pager.prototype.setItems = function setItems(items) {
	this.items = Math.max(items, 0);
	this.updateMaxPage();

	// always reset to first page when item count has changed
	return this.goTo(1);
};

/**
 * @param {Function} onPageChange
 * @returns {Pager}
 */
Pager.prototype.setOnPageChange = function setOnPageChange(onPageChange) {
	this.onPageChange = onPageChange;
	return this;
};

Pager.prototype.updateMaxPage = function updateMaxPage() {
	if (this.perPage === 1) {
		this.maxPage = this.items;
	} else if (this.items === this.perPage || this.items === 0) {
		this.maxPage = 1;
	} else if (this.items % this.perPage === 0) {
		this.maxPage = this.items / this.perPage;
	} else {
		this.maxPage = Math.floor(this.items / this.perPage) + 1;
	}
};

/**
 * @param {Number} pageNo
 * @returns {Pager}
 */
Pager.prototype.goTo = function goTo(pageNo) {
	const pageHasChanged = this.page !== pageNo;

	this.page = pageNo;

	if (pageHasChanged) {
		this.onPageChange(this.page);
	}

	return this;
};

/**
 * @returns {Pager}
 */
Pager.prototype.prev = function prev() {
	return this.goTo(Math.max(this.page - 1, 1));
};

/**
 * @returns {Pager}
 */
Pager.prototype.next = function next() {
	return this.goTo(Math.min(this.page + 1, this.maxPage));
};

/**
 * To be used by paging filter.
 * @returns int array index to start slice
 */
Pager.prototype.filterFrom = function filterFrom() {
	return (this.page - 1) * this.perPage;
};

/**
 * To be used by pager info.
 * @returns int
 */
Pager.prototype.from = function from() {
	return this.items ? this.filterFrom() + 1 : 0;
};

/**
 * @returns int
 */
Pager.prototype.to = function to() {
	return Math.min(this.filterFrom() + this.perPage, this.items);
};

/**
 * @returns {boolean}
 */
Pager.prototype.isFirst = function isFirst() {
	return this.page === 1;
};

/**
 * @returns {boolean}
 */
Pager.prototype.isLast = function isLast() {
	return this.page === this.maxPage;
};

export default Pager;
