import AdslotDimension from './AdslotDimension.js';

class AdslotFormat {
	/**
	 * @type {AdslotDimension}
	 */
	dimension = new AdslotDimension(0, 0);

	/**
	 * @type {number}
	 */
	floorPrice = 0;

	/**
	 * @param {{
	 *     width: <number>,
	 *     height: <number>,
	 *     floorPrice: <number>
	 * }} [data]
	 */
	constructor({ width = 0, height = 0, floorPrice = 0 } = {}) {
		this.dimension = new AdslotDimension(width, height);
		this.floorPrice = floorPrice;
	}

	/**
	 *
	 * @param {AdslotDimension} dimension
	 * @param {number} [floorPrice]
	 * @return {AdslotFormat}
	 */
	static fromDimension(dimension, floorPrice = 0) {
		const adslotFormat = new AdslotFormat({ floorPrice });
		adslotFormat.dimension = dimension;
		return adslotFormat;
	}

	toObject() {
		return {
			...this.dimension.toObject(),
			floorPrice: this.floorPrice,
		};
	}
}

export default AdslotFormat;
