function ResizeAnalyticsFrame($document, $window) {
	'ngInject';

	return function postLink(scope, iElement) {
		var unbindDestroyListener,
			mainContainer = angular.element($document[0].querySelector('main.main'));

		function resizeFrame() {
			var headerHeight = angular.element('.header').height(),
				footerHeight = angular.element('.footer-bar').outerHeight();

			iElement.height(angular.element($window).height() - headerHeight - (footerHeight || 40) - 1);
		}

		mainContainer.addClass('no-frame');
		angular.element($window).on('resize', resizeFrame);
		angular.element($window).trigger('resize');

		unbindDestroyListener = scope.$on('$destroy', function destroyListener() {
			angular.element($window).off('resize', resizeFrame);
			mainContainer.removeClass('no-frame');
			unbindDestroyListener();
		});
	};
}

export default ResizeAnalyticsFrame;
