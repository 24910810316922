function HasAdslotCreation(Account, AuthService) {
	'ngInject';

	return {
		priority: 1000,
		compile: function compile(tElement) {
			if (!(AuthService.isAdmin() && Account.isAllowedToCreateAdslots())) {
				tElement.remove();
			} else {
				tElement.removeAttr('has-adslot-creation');
				tElement.removeAttr('data-has-adslot-creation');
			}
		},
	};
}

export default HasAdslotCreation;
