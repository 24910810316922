import ROLE from './../../constants/UserRoles.js';

import overviewHtml from '../../views/reports/overview.html';
import viewHtml from '../../views/reports/view.html';
import ReportTemplate from '../../models/ReportTemplate.js';

function AppConfigRoutingReports($stateProvider, $urlRouterProvider) {
	'ngInject';

	$urlRouterProvider.when('/reports', '/reports/overview');
	// controllerAs will be refactored step by step
	$stateProvider // eslint-disable-line angular/controller-as-route
		.state('reports', {
			parent: 'app',
			abstract: true,
			url: '/reports',
			views: {
				'main@app': {
					template: '<div data-ui-view></div>',
					controller: 'ReportsController',
				},
			},
			resolve: {
				/**
				 * @param {ReportTemplates} ReportTemplates
				 * @return {Promise}
				 */
				loadTemplates(ReportTemplates) {
					'ngInject';

					return ReportTemplates.loadAll();
				},
				loadExports(ReportExports) {
					'ngInject';

					return ReportExports.refreshStatus();
				},
			},
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER, ROLE.REPORTS],
			},
		})
		.state('reports.overview', {
			url: '/overview',
			template: overviewHtml,
			controller: 'ReportsOverviewController',
			controllerAs: '$ctrl',
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER, ROLE.REPORTS],
			},
		})
		.state('reports.view', {
			url: '/view/{id:[0-9]+}',
			template: viewHtml,
			controller: 'ReportsViewController',
			resolve: {
				/**
				 * @param $q
				 * @param $stateParams
				 * @param Account
				 * @param {ReportTemplates} ReportTemplates
				 * @return {Promise}
				 */
				resolvedTemplate($q, $stateParams, Account, ReportTemplates) {
					'ngInject';

					return ReportTemplates.cachedLoadAll().then(() => {
						if (ReportTemplates.getById($stateParams.id)) {
							const template = new ReportTemplate(ReportTemplates.getById($stateParams.id).toObject());

							if (!Account.isDirectConnect()) {
								template.removeParentRevenueColumn();
								template.removeParentSupplyPartnerColumn();
								template.removeParentDealIdColumn();
							}

							if (Account.isDirectConnect() && !Account.acEnabledDealTargetingDcDeal()) {
								template.removeParentSupplyPartnerColumn();
								template.removeParentDealIdColumn();
							}

							return template;
						}

						return $q.reject({
							error: 'could not load requested template',
							data: $stateParams.id,
						});
					});
				},
			},
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER, ROLE.REPORTS],
			},
		});
}

export default AppConfigRoutingReports;
