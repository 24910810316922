import angular from 'angular';

import SettingsProviderWhitelistController from './settings-provider-whitelist.controller.js';
import ProviderListModule from './provider-list/provider-list.module.js';
import ProviderWhitelistRemovalConfirmationModule from './provider-whitelist-removal-confirmation.module.js';

const SettingsProviderWhitelistModule = angular
	.module('settingsProviderWhitelist', [ProviderListModule, ProviderWhitelistRemovalConfirmationModule])
	.controller('SettingsProviderWhitelistController', SettingsProviderWhitelistController).name;

export default SettingsProviderWhitelistModule;
