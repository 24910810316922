import ROLE from './../../constants/UserRoles.js';

import accountHtml from '../../views/settings/account.html';
import providerWhitelistHtml from '../../views/settings/supply-provider-whitelist.html';
import supplyFilterHtml from '../../views/settings/supply-filter.html';
import supplyCategoryFilterHtml from '../../views/settings/supply-category-filter.html';
import supplyInventoryHtml from '../../views/settings/supply-inventory.html';
import supplyInventoryViewHtml from '../../views/settings/supply-inventory-view.html';
import supplyInventoryEditHtml from '../../views/settings/supply-inventory-edit.html';
import helpCenterHtml from '../../views/settings/help-center.html';

function SettingsConfig($stateProvider) {
	'ngInject';

	// controllerAs will be refactored step by step
	$stateProvider // eslint-disable-line angular/controller-as-route
		.state('settings', {
			parent: 'app',
			abstract: true,
			url: '/settings',
			views: {
				'main@app': {
					template: '<div class="container" data-ui-view></div>',
					controller: 'SettingsController',
				},
			},
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER, ROLE.REPORTS],
			},
		})
		.state('settings.account', {
			url: '/account',
			template: accountHtml,
			controller: 'SettingsAccountController',
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER, ROLE.REPORTS],
			},
		})
		.state('settings.provider-whitelist', {
			url: '/provider-whitelist',
			template: providerWhitelistHtml,
			controller: 'SettingsProviderWhitelistController as $ctrl',
			resolve: {
				resolveProviderList: (GoogleProviders) => {
					return GoogleProviders.loadAll();
				},
				resolvedProviderWhitelist: (SupplyProviderWhitelist) => {
					return SupplyProviderWhitelist.load();
				},
			},
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER],
			},
		})
		.state('settings.filter', {
			url: '/filter',
			template: supplyFilterHtml,
			controller: 'SettingsSupplyController',
			controllerAs: '$ctrl',
			resolve: {
				resolvedSupplyFilter(SupplyFilter) {
					'ngInject';

					return SupplyFilter.get();
				},
			},
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER],
			},
		})
		.state('settings.category-filter', {
			url: '/categoryfilter',
			template: supplyCategoryFilterHtml,
			controller: 'SettingsSupplyCategoryFilterController',
			controllerAs: '$ctrl',
			resolve: {
				resolvedSupplyCategoryFilter(SupplyCategoryFilter) {
					'ngInject';

					return SupplyCategoryFilter.get();
				},
			},
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER],
			},
		})
		.state('settings.inventory', {
			abstract: true,
			url: '/inventory',
			template: supplyInventoryHtml,
			resolve: {
				resolvedReferrerGroupsMap(InventoryFilter) {
					'ngInject';

					return InventoryFilter.getReferrerGroups();
				},
			},
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER],
			},
		})
		.state('settings.inventory.view', {
			url: '',
			template: supplyInventoryViewHtml,
			controller: 'SettingsInventoryController as $ctrl',
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER],
			},
		})
		.state('settings.inventory.edit', {
			url: '/{refGrpId:[0-9]+|new}',
			template: supplyInventoryEditHtml,
			controller: 'SettingsInventoryEditController as $ctrl',
			data: {
				authorizedRoles: [ROLE.ADMIN],
			},
		})
		.state('settings.help-center', {
			url: '/help-center',
			template: helpCenterHtml,
			data: {
				authorizedRoles: ROLE.ALL,
			},
		});
}

export default SettingsConfig;
