import NativeAsset from './NativeAsset.js';
import NativeAssetImage from './NativeAssetImage.js';
import NativeAssetData from './NativeAssetData.js';
import NativeAssetTitle from './NativeAssetTitle.js';

class NativeAssetFactory {
	/**
	 * @param {object} data
	 * @returns {NativeAsset}
	 */
	static createFromData(data) {
		if (data.img) {
			return new NativeAssetImage(data);
		}
		if (data.data) {
			return new NativeAssetData(data);
		}
		if (data.title) {
			return new NativeAssetTitle(data);
		}

		throw new Error('Not supported. Invalid Native Asset data object found.');
	}

	/**
	 * @param {string} uiType
	 * @returns {NativeAsset}
	 */
	static createFromUiType(uiType) {
		switch (uiType) {
			case NativeAsset.TYPE.IMAGE_ICON:
				return new NativeAssetImage({
					img: { type: NativeAssetImage.TYPE_ID.ICON },
				});
			case NativeAsset.TYPE.IMAGE_MAIN:
				return new NativeAssetImage({
					img: { type: NativeAssetImage.TYPE_ID.MAIN },
				});
			case NativeAsset.TYPE.DATA_DESCRIPTION:
				return new NativeAssetData({
					data: { type: NativeAssetData.TYPE_ID.DESCRIPTION },
				});
			case NativeAsset.TYPE.TITLE:
				return new NativeAssetTitle();
			default:
				throw new Error('Not supported. Invalid Native Asset type string found.');
		}
	}

	// shorthand convenience methods

	/**
	 * @returns {NativeAsset}
	 */
	static iconImage() {
		return NativeAssetFactory.createFromUiType(NativeAsset.TYPE.IMAGE_ICON);
	}
}

export default NativeAssetFactory;
