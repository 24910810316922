import angular from 'angular';

import API_ERRORS from './api-errors.constant.js';
import APP_VERSION from './app-version.constant.js';

import Api from './api.service.js';

import Adtags from './Adtags.js';
import AdslotPartnerships from './AdslotPartnerships.js';
import DirectConnectAccountSettings from './DirectConnectAccountSettings.js';
import GoogleProviders from './GoogleProviders.js';
import Impersonate from './Impersonate.js';
import OpenAuctionSettings from './OpenAuctionSettings.js';
import PartnershipAdslots from './PartnershipAdslots.js';
import PartnershipBidOptions from './PartnershipBidOptions.js';
import PartnershipDirectConnectOptions from './PartnershipDirectConnectOptions.js';
import PartnershipReferrerGroups from './PartnershipReferrerGroups.js';
import PartnershipsAdvertisers from './PartnershipsAdvertisers.js';
import PartnershipTargeting from './PartnershipTargeting.js';
import PartnershipYieldprobeParameters from './PartnershipYieldprobeParameters.js';
import Password from './Password.js';
import SupplyProviderWhitelist from './SupplyProviderWhitelist.js';
import Users from './Users.js';

const ApiModule = angular // eslint-disable-line angular/no-service-method
	.module('app.modules.api', [])

	.constant('API_ERRORS', API_ERRORS)
	.constant('APP_VERSION', APP_VERSION)

	.service('Api', Api)

	.service('Adtags', Adtags)
	.service('AdslotPartnerships', AdslotPartnerships)
	.service('DirectConnectAccountSettings', DirectConnectAccountSettings)
	.service('GoogleProviders', GoogleProviders)
	.service('Impersonate', Impersonate)
	.service('OpenAuctionSettings', OpenAuctionSettings)
	.service('PartnershipAdslots', PartnershipAdslots)
	.service('PartnershipBidOptions', PartnershipBidOptions)
	.service('PartnershipDirectConnectOptions', PartnershipDirectConnectOptions)
	.service('PartnershipReferrerGroups', PartnershipReferrerGroups)
	.service('PartnershipsAdvertisers', PartnershipsAdvertisers)
	.service('PartnershipTargeting', PartnershipTargeting)
	.service('PartnershipYieldprobeParameters', PartnershipYieldprobeParameters)
	.service('Password', Password)
	.service('SupplyProviderWhitelist', SupplyProviderWhitelist)
	.service('Users', Users).name;

export default ApiModule;
