import Pager from './pager.js';

function PagerService() {
	this.instances = {};

	this.get = function getPager(id) {
		return this.instances[id] || (this.instances[id] = new Pager(id)); // eslint-disable-line no-return-assign
	};

	this.reset = function reset() {
		this.instances = {};
	};
}

export default PagerService;
