import isNumber from 'lodash/isNumber.js';

import SelectionService from '../../../modules/utils/SelectionService.js';

function PartnershipAdslotsController(
	$scope,
	$stateParams,
	toaster,
	Adslots,
	InfoService,
	ModalFactory,
	OpenAuctionSettings,
	PagerService,
	resolvedPrices,
	resolvedAdslots,
) {
	'ngInject';

	const assignedAdslotsListId = `open-auction-config-${$stateParams.id}-assigned-adslots`;

	/**
	 * @param {String} id
	 * @return {{adType: *, formats: *, groupId: *, name: *, platformType: *, isMultisize: *, id: *, auctionType: *, channelId: *}}
	 */
	function getAdslotListEntry(id) {
		const { groupId, name, platformType, adType, auctionType, channelId, formats, isMultisize } = Adslots.getById(id);

		return {
			id: id * 1,
			groupId,
			name,
			platformType,
			adType,
			auctionType,
			channelId,
			formats,
			isMultisize,
		};
	}

	function getAvailableAdslots(assignedAdslots) {
		const currentlyAssignedAdslotIds = assignedAdslots.map(({ id }) => +id);

		return Adslots.getListFiltered(currentlyAssignedAdslotIds).map(({ id }) => getAdslotListEntry(id));
	}

	/**
	 * @param {Array.<{adslot: Number, floorPrice: Number}>} adslots
	 * @returns {{String: {floorPrice: Number}}}
	 */
	function toPricesMap(adslots) {
		return adslots.reduce((prices, { adslot, floorPrice }) => {
			prices[adslot] = { floorPrice };
			return prices;
		}, {});
	}

	function copyPricesMap(pricesMap) {
		return Object.keys(pricesMap).reduce((prices, adslotId) => {
			prices[adslotId] = { ...pricesMap[adslotId] };
			return prices;
		}, {});
	}

	function toAdslotsList(pricesMap) {
		return Object.entries(pricesMap).map(([id]) => getAdslotListEntry(id));
	}

	function isValidPriceConfig(adslotId) {
		const { floorPrice } = $scope.models.prices[adslotId];
		return isNumber(floorPrice) && floorPrice >= 0;
	}

	const selection = new SelectionService();

	$scope.models = {
		filteredAdslots: [],

		adslots: resolvedAdslots,
		prices: resolvedPrices,
		// store copy to be able to compute changes later on
		initialPrices: copyPricesMap(resolvedPrices),
		invalidPrices: {},

		assignedAdslotsListId,
		assignedAdslotsListFilter: {},

		colspan: 3,

		sortKey: 'name',

		isRemoveMode: false,
		toggleRemoveMode: () => {
			$scope.models.isRemoveMode = !$scope.models.isRemoveMode;

			selection.clear();

			$scope.models.colspan = $scope.models.isRemoveMode ? 4 : 3;
		},

		getFilteredAdslots: () => $scope.models.filteredAdslots,

		selection,
		toggleOne: (adslotId) => selection.toggleOne(adslotId),

		// used in view html
		onPriceChange(adslotId) {
			if (isValidPriceConfig(adslotId)) {
				delete $scope.models.invalidPrices[adslotId];
			} else {
				$scope.models.invalidPrices[adslotId] = true;
			}
		},

		// used in view html
		addAdslots() {
			const listFilter = $scope.models.assignedAdslotsListFilter.instance;
			const listFilterSettings = listFilter.getActiveFilterData();

			ModalFactory.addAdslotsToOpenAuctionConfigModal(
				{
					assignedAdslots: $scope.models.adslots,
					availableAdslots: getAvailableAdslots($scope.models.adslots),
				},
				(addedPricesList) => {
					// list is empty if modal was cancelled
					if (addedPricesList.length > 0) {
						// as we only get the list of added ids back, we need to build the whole list first
						// before updating the scope price map and filterable list
						const currentPricesList = $scope.models.adslots.map(({ id: adslot }) => ({
							adslot,
							floorPrice: $scope.models.prices[adslot].floorPrice,
						}));
						const wholePricesList = currentPricesList.concat(addedPricesList);

						$scope.models.prices = toPricesMap(wholePricesList);
						$scope.models.initialPrices = toPricesMap(wholePricesList);
						$scope.models.adslots = toAdslotsList($scope.models.prices);
					}

					// as the list filter objects are singletons and we only load different data per page we need to restore
					// the filter to it's previous (from before opening the modal) state
					listFilter.load(listFilterSettings);
				},
			);
		},

		// used in view html
		removeAdslots() {
			const adslotIds = $scope.models.selection.values();

			if (adslotIds.length) {
				InfoService.startRequest();

				OpenAuctionSettings.removeGlobalAdslotConfigs(adslotIds)
					.then(() => {
						toaster.successMessage('MESSAGE.SETTINGS_SAVE_SUCCESS');

						// as we only have the list of removed ids, we also need to build an updated prices list first
						const removedIds = new Set(adslotIds);
						const updatedPricesList = $scope.models.adslots
							.filter(({ id }) => !removedIds.has(id))
							.map(({ id: adslot }) => ({
								adslot,
								floorPrice: $scope.models.prices[adslot].floorPrice,
							}));

						$scope.models.prices = toPricesMap(updatedPricesList);
						$scope.models.initialPrices = toPricesMap(updatedPricesList);
						$scope.models.adslots = toAdslotsList($scope.models.prices);

						$scope.models.toggleRemoveMode();
					})
					.catch(() => {
						toaster.errorMessage('MESSAGE.SETTINGS_SAVE_ERROR');
					})
					.finally(() => InfoService.endRequest());
			} else {
				$scope.models.toggleRemoveMode();
			}
		},

		// used in view html
		savePrices() {
			if (Object.keys($scope.models.invalidPrices).length === 0) {
				const updatedPrices = Object.keys($scope.models.prices).reduce((pricesList, elementId) => {
					const { floorPrice } = $scope.models.prices[elementId];

					if (floorPrice !== $scope.models.initialPrices[elementId].floorPrice) {
						pricesList.push({ adslot: +elementId, floorPrice });
					}
					return pricesList;
				}, []);

				if (updatedPrices.length > 0) {
					InfoService.startRequest();

					OpenAuctionSettings.setGlobalAdslotConfigs(updatedPrices)
						.then(() => {
							toaster.successMessage('MESSAGE.SETTINGS_SAVE_SUCCESS');

							// as only the prices have changed we just need to set the current prices as initial
							$scope.models.initialPrices = copyPricesMap($scope.models.prices);
						})
						.catch(() => {
							toaster.errorMessage('MESSAGE.SETTINGS_SAVE_ERROR');
						})
						.finally(() => InfoService.endRequest());
				}
			}
		},
	};

	PagerService.get(assignedAdslotsListId).setPerPage(20);
}

export default PartnershipAdslotsController;
