function AdslotsChannelsEditController($scope, $filter, Adslots, Channels, InfoService, toaster, resolvedChannel) {
	'ngInject';

	$scope.models = {
		assignedNameFilter: '',
		availableNameFilter: '',
		assignedList: [], // list of assigned adslots to channel
		availableList: [], // list of available unassigned adslots to channel
		adslotsList: Adslots.getList().map(function map(channel) {
			// extend channel to allow list to be easily handled in two lists
			channel.checked = !!(resolvedChannel.websites.length && resolvedChannel.websites.indexOf(channel.id) !== -1);
			return channel;
		}), // list of all adslots
	};

	$scope.form = {
		name: resolvedChannel.name,
		websites: resolvedChannel.websites,
	};
	$scope.initialForm = angular.copy($scope.form);

	$scope.toggleSelection = function toggleSelection(list) {
		var i;

		for (i = 0; i < list.length; i++) {
			list[i].checked = !list[i].checked;
		}
	};

	$scope.save = function save() {
		var data = {};

		// get adslot ids out of the list of assigned adslots
		$scope.form.websites = $filter('filter')($scope.models.adslotsList, {
			checked: true,
		}).reduce(function reduce(adslotIds, adslot) {
			adslotIds.push(adslot.id);
			return adslotIds;
		}, []);

		// check which data to include for submit
		if (!angular.equals($scope.form.name, $scope.initialForm.name)) {
			data.name = $scope.form.name;
		}
		if (!angular.equals($scope.form.websites, $scope.initialForm.websites)) {
			data.websites = $scope.form.websites;
		}

		// save if necessary
		if (!angular.equals(data, {})) {
			InfoService.startRequest();

			Channels.update(resolvedChannel.id, data.name, data.websites)
				.then(function success() {
					$scope.initialForm = angular.copy($scope.form);
					InfoService.endRequest();
					toaster.successMessage('MESSAGE.SETTINGS_SAVE_SUCCESS');
				})
				.catch(function error(errorObject) {
					if (errorObject.code === Channels.ERROR.NAME_EXISTS) {
						$scope.channelEditForm.name.$setValidity('required', false);
						toaster.errorMessage('MESSAGE.CHANNEL_NAME_EXISTS');
					} else if (errorObject.code === Channels.ERROR.NAME_INVALID) {
						$scope.channelEditForm.name.$setValidity('required', false);
						toaster.errorMessage('MESSAGE.CHANNEL_NAME_INVALID');
					} else {
						toaster.errorMessage('MESSAGE.SETTINGS_SAVE_ERROR');
					}
					InfoService.endRequest();
				});
		}
	};
}

export default AdslotsChannelsEditController;
