function ReportExports($filter, $log, $q, $rootScope, $timeout, ReportOrders, AUTH_EVENTS, toaster) {
	'ngInject';

	const EXPORT_STATUS_CHECK_TIMEOUT = 30000;
	const EXPORT_MAX_QUEUE_SIZE = 5;

	this.STATE_PENDING = 'pending';
	this.STATE_READY = 'ready';
	this.STATE_ERROR = 'error';

	const API_ORDER_STATE_PENDING = 'queued';
	const API_ORDER_STATE_READY = 'processed';
	const API_ORDER_STATE_ERROR = 'failed';

	const stateTable = {
		[API_ORDER_STATE_PENDING]: this.STATE_PENDING,
		[API_ORDER_STATE_READY]: this.STATE_READY,
		[API_ORDER_STATE_ERROR]: this.STATE_ERROR,
	};

	let statusCheckTimer = null;
	let isProcessingDownloadNotifiers = false;
	let downloadNotifiers = {};

	this.EVENT = {
		STATUS_UPDATED: 'report-export-status-updated',
	};

	this.queuedReports = {};
	this.numPendingReports = 0;

	// we need this, because the API status doesn't know about templates
	this.pendingTemplateIds = new Set();

	this.enableStatusCheckLoop = () => scheduleStatusCheck();

	this.enableStatusCheckLoopForTemplateId = (templateId, reportId) => {
		const filename = this.queuedReports[reportId].filenameFull;

		// closes duplicate toasts before they get processed again
		if (toaster.doesCloseableExist(reportId)) {
			toaster.dropCloseable(reportId);
		}
		scheduleStatusCheckAndAddNotifier(reportId)
			.then(() => {
				createDownloadToastMessage(filename, reportId);
				this.removeTemplateIdFromPending(templateId);
			})
			.catch(() => this.removeTemplateIdFromPending(templateId));
	};

	const createDownloadToastMessage = (filename, reportUUID) =>
		toaster.popCloseableMessage({
			message: 'TEXT.REPORTS_CENTER_CLICK_HERE_TO_DOWNLOAD_EXPORT',
			messageData: { filename },
			onClick: () => {
				window.open(`/api/supply/v1/reports/${reportUUID}`, '_blank');
			},
			title: 'TITLE.REPORTS.REPORTS_CENTER_EXPORT_GENERATED',
			icon: this.getFileExtensionIconClass(getFilenameExtension(filename)),
			toastId: reportUUID,
		});

	this.removeTemplateIdFromPending = (templateId) => {
		this.pendingTemplateIds.delete(templateId);
	};

	const scheduleStatusCheckAndAddNotifier = (reportId) => {
		scheduleStatusCheck();
		return $q((resolve, reject) => {
			downloadNotifiers[reportId] = { resolve, reject };
		});
	};

	const scheduleStatusCheck = () => {
		if (statusCheckTimer !== null) {
			return;
		}
		if (this.hasPendingReports()) {
			statusCheckTimer = $timeout(EXPORT_STATUS_CHECK_TIMEOUT);
			statusCheckTimer
				.then(() => {
					statusCheckTimer = null;
				})
				.then(refreshStatusAndSendEvent)
				.then(processDownloadNotifiers)
				.then(scheduleStatusCheck)
				.catch((error) => $log.info(error));
		} else {
			processDownloadNotifiers();
		}
	};

	const processDownloadNotifiers = () => {
		if (!isProcessingDownloadNotifiers) {
			isProcessingDownloadNotifiers = true;

			downloadNotifiers = Object.entries(downloadNotifiers).reduce((notifiers, notifierEntry) => {
				const [reportId, notifier] = notifierEntry;

				if (this.isReady(reportId)) {
					notifier.resolve();
				} else if (this.isPending(reportId)) {
					// re-add the pending ones
					notifiers[reportId] = notifier;
				} else {
					// reject failed and not existing ones
					notifier.reject();
				}

				return notifiers;
			}, {});

			isProcessingDownloadNotifiers = false;
		}
	};

	const killStatusCheckTimer = () => {
		if (statusCheckTimer !== null) {
			$timeout.cancel(statusCheckTimer);
			statusCheckTimer = null;
		}
	};

	this.addTemplateToQueue = (template) =>
		ReportOrders.createReportOrderFromTemplate(template, true).then((result) => {
			const status = getStateFromApiState(result.status.toLowerCase());
			const filename = template.getFullFilename();
			addQueueEntry(filename, result.id, status, null, null);
			this.numPendingReports++;
			this.pendingTemplateIds.add(template.id);

			$rootScope.$emit(this.EVENT.STATUS_UPDATED);
			return result.id;
		});

	const refreshStatusAndSendEvent = () => {
		return this.refreshStatus().then(() => $rootScope.$emit(this.EVENT.STATUS_UPDATED));
	};

	this.refreshStatus = () =>
		ReportOrders.getStatus().then((status) => {
			this.queuedReports = {};

			const validKeys = Object.keys(status)
				// response may contain '_links' or other fields
				.filter((key) => key in stateTable);

			validKeys.forEach((apiStatus) =>
				status[apiStatus]
					// Only show queued reports with a filename (=offline reports) in the export list
					.filter((item) => !!item.filename)
					.forEach((orderStatus) =>
						addQueueEntry(orderStatus.filename, orderStatus.id, getStateFromApiState(apiStatus), orderStatus.detail, new Date(orderStatus.lastModified)),
					),
			);

			this.numPendingReports = Object.values(this.queuedReports).filter((item) => item.state === this.STATE_PENDING).length;
		});

	const addQueueEntry = (filename, id, state, errorDetail, lastModified) => {
		let newEntry = {
			mappingId: id,
			name: getFilenameStem(filename),
			extension: getFilenameExtension(filename),
			filenameFull: filename,
			state: state,
			lastModified: lastModified,
		};
		if (state === this.STATE_ERROR) {
			newEntry.errorDetail = errorDetail;
		}
		this.queuedReports[id] = newEntry;
	};

	const getFilenameStem = (filename) => {
		const separatorIndex = filename.lastIndexOf('.');
		return separatorIndex < 0 ? filename : filename.substr(0, separatorIndex);
	};

	const getFilenameExtension = (filename) => {
		const separatorIndex = filename.lastIndexOf('.');
		return separatorIndex < 0 ? '' : filename.substr(separatorIndex + 1);
	};

	const getStateFromApiState = (apiState) => {
		return stateTable[apiState] || apiState;
	};

	this.hasPendingReports = () => this.numPendingReports !== 0;

	this.isPendingByTemplateId = (templateId) => this.pendingTemplateIds.has(templateId);

	this.isPending = (reportId) => reportId in this.queuedReports && this.queuedReports[reportId].state === this.STATE_PENDING;

	this.isReady = (reportId) => reportId in this.queuedReports && this.queuedReports[reportId].state === this.STATE_READY;

	this.isError = (reportId) => reportId in this.queuedReports && this.queuedReports[reportId].state === this.STATE_ERROR;

	this.getQueuedReportInfo = () => Object.values(this.queuedReports);

	this.canQueueAnotherExport = () => this.numPendingReports < EXPORT_MAX_QUEUE_SIZE;

	this.getReportErrorMessage = (reportId) => this.queuedReports[reportId].errorDetail.message;

	this.getFileExtensionIconClass = (extension) => {
		let iconClass = 'icon-';

		switch (extension) {
			case 'csv':
				iconClass += 'doc-text-1';
				break;

			case 'xls':
			case 'xlsx':
				iconClass += 'file-excel';
				break;
			default:
				iconClass = '';
		}

		return iconClass;
	};

	// eslint-disable-next-line angular/on-watch
	$rootScope.$on(AUTH_EVENTS.userImpersonated, killStatusCheckTimer);
	// eslint-disable-next-line angular/on-watch
	$rootScope.$on(AUTH_EVENTS.logoutSuccess, killStatusCheckTimer);
}

export default ReportExports;
