import Partnership from '../../models/Partnership.js';
import ROLE from './../../constants/UserRoles.js';

import adslotPartnershipsHtml from '../../views/pricing/adslot-partnerships.html';
import adslotsHtml from '../../views/pricing/adslots.html';
import advertisersHtml from '../../views/pricing/advertisers.html';
import globalHtml from '../../views/pricing/global.html';
import navigationHtml from '../../views/pricing/navigation.html';
import partnershipAdslotsHtml from '../../views/pricing/partnership-adslots.html';
import partnershipsHtml from '../../views/pricing/partnerships.html';
import { loadAdslotsWithPrices, loadPartnershipAdslotPrices } from '../resolver-util.js';

function AppConfigRoutingPricing($stateProvider) {
	'ngInject';

	// controllerAs will be refactored step by step
	$stateProvider // eslint-disable-line angular/controller-as-route
		.state('pricing', {
			parent: 'app',
			abstract: true,
			url: '/pricing',
			views: {
				'subMenu@app': {
					template: navigationHtml,
				},
				'main@app': {
					template: '<div data-ui-view></div>',
					controller: 'AbstractPricingController',
				},
			},
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER],
			},
		})
		.state('pricing.global', {
			url: '/global',
			template: globalHtml,
			controller: 'PricingGlobalController',
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER],
			},
		})
		.state('pricing.advertisers', {
			url: '/advertisers',
			template: advertisersHtml,
			controller: 'PricingAdvertisersController',
			resolve: {
				resolvePrices: function resolvePrices(AdvertisersPrices) {
					'ngInject';

					return AdvertisersPrices.loadAll();
				},
			},
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER],
			},
		})
		.state('pricing.adslots', {
			url: '/adslots',
			template: adslotsHtml,
			controller: 'PricingAdslotsController',
			resolve: {
				loadSites: function loadSites(Sites) {
					'ngInject';

					return Sites.loadAll();
				},
				loadAdslotGroups: function loadAdslotGroups(AdslotGroups) {
					'ngInject';

					return AdslotGroups.loadAll();
				},
				resolvedPrices(AdslotsPrices) {
					'ngInject';

					return AdslotsPrices.get();
				},
			},
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER],
			},
		})
		.state('pricing.adslots.partnerships', {
			url: '/{id:[0-9]+}',
			views: {
				'@pricing': {
					template: adslotPartnershipsHtml,
					controller: 'PricingAdslotPartnershipsController',
				},
			},
			resolve: {
				currentAdslot: function currentAdslot($stateParams, Adslots, toaster, $q) {
					'ngInject';

					const requestedAdslot = Adslots.getById($stateParams.id);
					if (!requestedAdslot) {
						toaster.errorMessage('TEXT.ADSLOT_NOT_EXISTS');
						return $q.reject({
							error: 'could not load requested Adslot',
							data: $stateParams.id,
						});
					}
					return requestedAdslot;
				},
				resolvedPartnerships: function resolvedPartnerships(AdslotPartnerships, $stateParams, DemandPartners, Partnerships) {
					'ngInject';

					return AdslotPartnerships.load($stateParams.id).then((partnerships) =>
						partnerships
							.map(({ id, floorPrice, fixedPrice }) => Partnership.clone(Partnerships.getById(id)).setFloorPrice(floorPrice).setFixedPrice(fixedPrice))
							.filter((partnership) => {
								const demandPartner = DemandPartners.getById(partnership.getDemandId());
								return demandPartner.isRtb() || demandPartner.isDC();
							}),
					);
				},
			},
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER],
			},
		})
		.state('pricing.partnerships', {
			url: '/partnerships',
			template: partnershipsHtml,
			controller: 'PricingPartnershipsController',
			resolve: {
				resolvePrices: function resolvePrices(PartnershipsPrices) {
					'ngInject';

					return PartnershipsPrices.loadAll();
				},
			},
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER],
			},
		})
		.state('pricing.partnerships.adslots', {
			url: '/{id:[0-9]+}',
			views: {
				'@pricing': {
					template: partnershipAdslotsHtml,
					controller: 'PricingPartnershipAdslotsController',
				},
			},
			resolve: {
				loadSites: function loadSites(Sites) {
					'ngInject';

					return Sites.loadAll();
				},
				loadAdslotGroups: function loadAdslotGroups(AdslotGroups) {
					'ngInject';

					return AdslotGroups.loadAll();
				},
				currentPartnership: function currentPartnership($stateParams, Partnerships, toaster, $q) {
					'ngInject';

					const requestedPartnership = Partnerships.getById($stateParams.id);
					if (!requestedPartnership) {
						toaster.errorMessage('TEXT.PARTNERSHIP_NOT_EXISTS');
						return $q.reject({
							error: 'could not load requested Partnership',
							data: $stateParams.id,
						});
					}
					return requestedPartnership;
				},
				resolvedPrices: function resolvedPrices(PartnershipAdslots, $stateParams) {
					'ngInject';

					return loadPartnershipAdslotPrices(PartnershipAdslots, $stateParams.id);
				},
				resolvedAdslots: function resolveAdslots(resolvedPrices, Adslots) {
					'ngInject';

					return loadAdslotsWithPrices(resolvedPrices, Adslots);
				},
			},
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER],
			},
		});
}

export default AppConfigRoutingPricing;
