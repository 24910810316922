function SplitInput($parse, $rootScope, $timeout) {
	'ngInject';

	return {
		restrict: 'A',
		require: 'ngModel',
		link: function postLink(scope, iElement, attrs, ngModelCtrl) {
			var initialViewValue,
				initialModelValue = $parse(attrs.ngModel)(scope) || [],
				isNumeric = false;

			function calculateIsNumeric() {
				isNumeric = attrs.isNumeric ? $parse(attrs.isNumeric)(scope) : false;
			}

			ngModelCtrl.$parsers.push(function parserFn(viewValue) {
				var parsedValue = viewValue || '';

				// trim leading or trailing empty parts
				parsedValue = parsedValue.replace(/^(\s*,?)*|(,?\s*)*$/g, '');

				// trim empty parts in between
				parsedValue = parsedValue.replace(/(\s*,\s*)+/g, ',');

				// split
				parsedValue = parsedValue.length ? parsedValue.split(/\s*,\s*/) : [];

				// as model value
				return parsedValue;
			});

			ngModelCtrl.$formatters.push(function formatterFn(modelValue) {
				// as view value
				return !modelValue ? '' : modelValue.join(', ');
			});

			function validateNumeric(viewValue) {
				if (!isNumeric) {
					return true;
				}
				if (!/^-?[0-9]{1,16}(\s*,\s*-?[0-9]{1,16})*$/.test(viewValue)) {
					return false;
				}
				return !viewValue.split(/\s*,\s*/).some((string) => Number(string) > Number.MAX_SAFE_INTEGER);
			}

			function validateAllowedCharacters(viewValue) {
				ngModelCtrl.$setValidity('numeric', validateNumeric(viewValue));
				ngModelCtrl.$setValidity('allowedCharacters', !viewValue || /^[0-9a-zA-Z$!_'-.+*()]+(, [0-9a-zA-Z$!_'-.+*()]+)*$/.test(viewValue));
				ngModelCtrl.$setValidity('required', (viewValue + '').length);
			}

			function reformatViewValue(modelValue, modelValueWasChanged) {
				var formatters = ngModelCtrl.$formatters,
					idx = formatters.length,
					viewValue = modelValue;

				while (idx--) {
					viewValue = formatters[idx](viewValue);
				}

				if (modelValueWasChanged === true) {
					ngModelCtrl.$setViewValue(viewValue);
				} else {
					ngModelCtrl.$viewValue = viewValue;
				}

				ngModelCtrl.$render();

				validateAllowedCharacters(viewValue);
			}

			iElement.on('blur.splitInput', function blur() {
				// update view only when needed
				if (initialModelValue !== ngModelCtrl.$modelValue || initialViewValue !== ngModelCtrl.$viewValue) {
					reformatViewValue(ngModelCtrl.$modelValue);
					initialModelValue = ngModelCtrl.$modelValue;
					initialViewValue = ngModelCtrl.$viewValue;
				}
			});

			iElement.on('keydown.splitInput keypress.splitInput', function keydown(event) {
				if (event.which === 27) {
					// reset value on ESC
					reformatViewValue(initialModelValue, true);
					$rootScope.safeApply();
					iElement.blur();
				}
			});

			scope.$watch(
				function checkIsNumeric() {
					calculateIsNumeric();
					return isNumeric;
				},
				function watched(newValue, oldValue) {
					// prevent on initial run
					if (newValue !== oldValue) {
						if (newValue) {
							reformatViewValue(ngModelCtrl.$modelValue, true);
						}

						validateAllowedCharacters(ngModelCtrl.$viewValue);
					}
				},
			);

			scope.$on('$destroy', function cleanup() {
				iElement.off('blur.splitInput keydown.splitInput keypress.splitInput');
			});

			// trigger validation on load
			if (initialModelValue && initialModelValue.length) {
				$timeout(function timeout() {
					iElement.blur();
				}, 0);
			}
		},
	};
}

export default SplitInput;
