class ReportSeparateBy {
	constructor(separateBy) {
		this.separateBy = separateBy;
	}

	static SEPARATE_BY = {
		TOTAL: 'total',
		HOUR: 'hour',
		DAY: 'day',
		WEEK: 'week',
		MONTH: 'month',
	};

	static SEPARATE_BY_TRANSLATION = {
		TOTAL: 'CAPTION.SEPARATE_BY_TOTAL',
		HOUR: 'CAPTION.SEPARATE_BY_HOUR',
		DAY: 'CAPTION.SEPARATE_BY_DAY',
		WEEK: 'CAPTION.SEPARATE_BY_WEEK',
		MONTH: 'CAPTION.SEPARATE_BY_MONTH',
	};

	static getSeparateBySelectOptions() {
		return Object.keys(ReportSeparateBy.SEPARATE_BY).map((type) => ({
			name: this.SEPARATE_BY_TRANSLATION[type],
			value: ReportSeparateBy.SEPARATE_BY[type],
		}));
	}

	static withoutSeparationByHour() {
		return ReportSeparateBy.getSeparateBySelectOptions().filter((option) => option.value !== ReportSeparateBy.SEPARATE_BY.HOUR);
	}

	/**
	 * @returns {boolean}
	 */
	isSeparateByWeek() {
		return this.separateBy === ReportSeparateBy.SEPARATE_BY.WEEK;
	}

	/**
	 * @returns {boolean}
	 */
	isSeparateByMonth() {
		return this.separateBy === ReportSeparateBy.SEPARATE_BY.MONTH;
	}

	isSeparateByHour() {
		return this.separateBy === ReportSeparateBy.SEPARATE_BY.HOUR;
	}
}

export default ReportSeparateBy;
