function PagerControls(PagerService) {
	'ngInject';

	var vm = this;

	this.$onInit = function $onInit() {
		// eslint-disable-line angular/controller-as-vm
		vm.pager = PagerService.get(vm.pagerId);

		vm.prev = vm.pager.prev.bind(vm.pager);
		vm.next = vm.pager.next.bind(vm.pager);

		vm.first = vm.pager.isFirst.bind(vm.pager);
		vm.last = vm.pager.isLast.bind(vm.pager);

		if (vm.pagerReset === true) {
			vm.pager.reset();
		}
	};
}

export default PagerControls;
