import angular from 'angular';

import AngularDecorationsModule from '../modules/ng-decorations/ng-decorations.module.js';

import ConstantsModule from '../constants/constants.module.js';
import ModelsModule from '../models/models.module.js';
import ApiModule from '../modules/api/api.module.js';
import StateModule from '../state/state.module.js';
import UtilsModule from '../modules/utils/utils.module.js';

import AuthenticationModule from './authentication/authentication.module.js';
import DashboardModule from './dashboard/dashboard.module.js';
import InsightsModule from './insights/insights.module.js';
import InventoryModule from './inventory/inventory.module.js';
import ModalsModule from './modals/modals.module.js';
import PartnershipsModule from './partnerships/partnerships.module.js';
import PricingModule from './pricing/pricing.module.js';
import ReportsModule from './reports/reports.module.js';
import SettingsModule from './settings/settings.module.js';

const ScreensModule = angular.module('app.screens', [
	AngularDecorationsModule,

	ConstantsModule,
	ModelsModule,
	ApiModule,
	StateModule,
	UtilsModule,

	AuthenticationModule,
	DashboardModule,
	InsightsModule,
	InventoryModule,
	ModalsModule,
	PartnershipsModule,
	PricingModule,
	ReportsModule,
	SettingsModule,
]).name;

export default ScreensModule;
