/**
 * Enable two-way-binding (ngModel) and change event (ngChange) for <input type="file"> as this is missing
 * in AngularJS.
 */
function FileInputModel() {
	'ngInject';

	function isCorrectElement(element, type) {
		return element[0].tagName.toLowerCase() === 'input' && type && type.toLowerCase() === 'file';
	}

	return {
		restrict: 'A',
		scope: {
			type: '@',
		},
		require: 'ngModel',
		link: ($scope, $element, $attr, ngModelCtrl) => {
			if (!isCorrectElement($element, $scope.type)) {
				return;
			}

			/**
			 * If model change, change the element. File input is only allowed to set to an empty string
			 * programmatically, so we only clear the input (if the model gets a falsy value).
			 */
			ngModelCtrl.$render = () => {
				if (!ngModelCtrl.$viewValue) {
					$element.val('');
				}
			};

			/**
			 * Change the model, if the element value changes.
			 */
			$element.on('change', () => {
				$scope.$apply(() => {
					// in some edge cases, $element[0].files[0] can be undefined
					if ($element[0].files[0]) {
						ngModelCtrl.$setViewValue($element[0].files[0]);
					}
				});
			});

			/**
			 * Reset the selected file to trigger 'onChange' event if re-selected the same file.
			 */
			$element.on('click', () => {
				$scope.$apply(() => {
					if ($element[0].files[0]) {
						$element[0].files = new DataTransfer().files;
					}
				});
			});

			let clearChangeListener = $scope.$on('$destroy', () => {
				$element.off('change');
				$element.off('click');
				clearChangeListener();
			});
		},
	};
}

export default FileInputModel;
