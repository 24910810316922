function Api($document, $http, $q, APP_VERSION) {
	'ngInject';

	var NO_CACHE_HEADER = {
			// for IE ajax request caching
			'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
			// extra
			'Cache-Control': 'no-store, no-cache, must-revalidate', // HTTP/1.1
			Pragma: 'no-cache', // HTTP/1.0
		},
		APP_VERSION_HEADER = {
			'X-APP-VERSION': APP_VERSION,
		};

	/**
	 * @param {Object} response
	 */
	function unwrapData(response) {
		return response.data;
	}

	/**
	 * @param {Object} response
	 */
	function handleError(response) {
		const { data, status } = response;

		// in case the api error response is not what we expect
		if (!angular.isObject(data)) {
			return $q.reject({
				message: 'An unknown error occurred.',
				status: status,
			});
		}

		// otherwise return error details submitted by api
		return $q.reject({ ...data, status });
	}

	/**
	 * @param {string}   path     	request url
	 *
	 * @return {Promise}
	 */
	this.get = function getRequest(path) {
		return $http
			.get(path, {
				headers: angular.extend(angular.copy(NO_CACHE_HEADER), angular.copy(APP_VERSION_HEADER)),
			})
			.then(unwrapData, handleError);
	};

	/**
	 * @param {string}   controller controller to be called
	 * @param {string}   action     action to be called
	 * @param {object}   params     additional params
	 * @returns {Promise} returns promise containing the binary data stream
	 */
	this.getFile = function getFileRequest(path) {
		return $http
			.get(path, {
				headers: angular.extend(angular.copy(NO_CACHE_HEADER), angular.copy(APP_VERSION_HEADER)),
				responseType: 'blob',
			})
			.then(angular.identity, handleError);
	};

	/**
	 * @param {string} path request url
	 * @param {object} postParams
	 *
	 * @return {Promise}
	 */
	this.post = function post(path, postParams) {
		return $http
			.post(path, postParams, {
				headers: angular.extend(
					{
						'Content-Type': 'application/json; charset=utf-8',
					},
					angular.copy(NO_CACHE_HEADER),
					angular.copy(APP_VERSION_HEADER),
				),
			})
			.then(unwrapData, handleError);
	};

	this.put = (path, putParams) => {
		return $http
			.put(path, putParams, {
				headers: angular.extend(
					{
						'Content-Type': 'application/json; charset=utf-8',
					},
					angular.copy(NO_CACHE_HEADER),
					angular.copy(APP_VERSION_HEADER),
				),
			})
			.then(unwrapData, handleError);
	};

	this.patch = (path, patchParams) => {
		return $http
			.patch(path, patchParams, {
				headers: angular.extend(
					{
						'Content-Type': 'application/json; charset=utf-8',
					},
					angular.copy(NO_CACHE_HEADER),
					angular.copy(APP_VERSION_HEADER),
				),
			})
			.then(unwrapData, handleError);
	};

	this.delete = (path) => {
		return $http
			.delete(path, {
				headers: angular.extend(angular.copy(NO_CACHE_HEADER), angular.copy(APP_VERSION_HEADER)),
			})
			.then(unwrapData, handleError);
	};

	this.postForm = function postForm(url, data) {
		var input,
			form = $document[0].createElement('form');

		form.method = 'POST';
		form.action = url;

		angular.forEach(data, function addInputFields(value, key) {
			input = $document[0].createElement('input');

			input.type = 'hidden';
			input.name = key;
			input.value = value;

			form.appendChild(input);
		});

		// firefox fix
		form.style.display = 'none';
		$document[0].body.appendChild(form);
		form.submit();
		$document[0].body.removeChild(form);
	};
}

export default Api;
