import ReportColumns from './ReportColumns.js';

function ReportRowModel() {
	/**
	 * @var ReportRow
	 */
	function ReportRow() {
		this.type = 'default';

		this[ReportColumns.ID.HOUR] = '';
		this[ReportColumns.ID.SITE] = '';
		this[ReportColumns.ID.GROUP] = '';
		this[ReportColumns.ID.ADSLOT] = '';
		this[ReportColumns.ID.ADSLOT_ID] = 0;
		this[ReportColumns.ID.ADTYPE] = '';
		this[ReportColumns.ID.PLATFORM_TYPE] = '';
		this[ReportColumns.ID.CHANNELS] = [];
		this[ReportColumns.ID.EXTERNAL_ID] = '';
		this[ReportColumns.ID.FORMAT] = '';
		this[ReportColumns.ID.URL] = '';

		this[ReportColumns.ID.PARTNERSHIP_ID] = 0;
		this[ReportColumns.ID.PARTNERSHIP_TYPE] = '';
		this[ReportColumns.ID.DEAL_ID] = '';
		this[ReportColumns.ID.PARTNERSHIP] = '';
		this[ReportColumns.ID.DEMAND_PARTNER] = '';
		this[ReportColumns.ID.DEMAND_PARTNER_ID] = 0;
		this[ReportColumns.ID.PARENT_SUPPLY_PARTNER] = '';
		this[ReportColumns.ID.PARENT_DEAL_ID] = '';
		this[ReportColumns.ID.ADVERTISER] = '';
		this[ReportColumns.ID.ADVERTISER_ID] = 0;

		this[ReportColumns.ID.IMPRESSIONS] = 0;
		this[ReportColumns.ID.DELIVERIES] = 0;
		this[ReportColumns.ID.LOSS_RATE] = 0.0;
		this[ReportColumns.ID.FALLBACKS] = 0;

		this[ReportColumns.ID.REVENUE] = 0.0;
		this[ReportColumns.ID.NET_REVENUE] = 0.0;
		this[ReportColumns.ID.PARENT_REVENUE] = 0.0;

		this[ReportColumns.ID.ECPM] = 0.0;
		this[ReportColumns.ID.NET_ECPM] = 0.0;

		// empty string to distinguish between empty and zero
		this[ReportColumns.ID.VIDEO_STARTS] = '';
		this[ReportColumns.ID.VIDEO_FIRST_QUARTILE] = '';
		this[ReportColumns.ID.VIDEO_MIDPOINT] = '';
		this[ReportColumns.ID.VIDEO_THIRD_QUARTILE] = '';
		this[ReportColumns.ID.VIDEO_COMPLETE] = '';
		this[ReportColumns.ID.VIDEO_CLICK] = '';

		this[ReportColumns.ID.INVOICE] = '';
	}

	/**
	 * set report data
	 *
	 * @param {Object} data
	 *
	 * @return {void}
	 */
	ReportRow.prototype.setData = function setData(data) {
		this[ReportColumns.ID.HOUR] = data.hour || this[ReportColumns.ID.HOUR];
		this[ReportColumns.ID.ADSLOT] = data.adslot || this[ReportColumns.ID.ADSLOT];
		this[ReportColumns.ID.ADSLOT_ID] = parseInt(data.adid, 10) || this[ReportColumns.ID.ADSLOT_ID];
		this[ReportColumns.ID.ADTYPE] = data.ad_type || this[ReportColumns.ID.ADTYPE];
		this[ReportColumns.ID.PLATFORM_TYPE] = data.platform_type || this[ReportColumns.ID.PLATFORM_TYPE];
		this[ReportColumns.ID.SITE] = data.site || this[ReportColumns.ID.SITE];
		this[ReportColumns.ID.GROUP] = data.group || this[ReportColumns.ID.GROUP];
		this[ReportColumns.ID.CHANNELS] = data.channels || [];
		this[ReportColumns.ID.EXTERNAL_ID] = data.extid || this[ReportColumns.ID.EXTERNAL_ID];
		this[ReportColumns.ID.FORMAT] = data.format || this[ReportColumns.ID.FORMAT];
		this[ReportColumns.ID.URL] = data.url || this[ReportColumns.ID.URL];

		this[ReportColumns.ID.PARTNERSHIP_ID] = parseInt(data.pid, 10) || this[ReportColumns.ID.PARTNERSHIP_ID];
		// partnership_type starts with value 0
		this[ReportColumns.ID.PARTNERSHIP_TYPE] = angular.isDefined(data.partnership_type) ? data.partnership_type : this[ReportColumns.ID.PARTNERSHIP_TYPE];
		this[ReportColumns.ID.DEAL_ID] = data.dealId || this[ReportColumns.ID.DEAL_ID];
		this[ReportColumns.ID.PARTNERSHIP] = data.partnership || this[ReportColumns.ID.PARTNERSHIP];
		this[ReportColumns.ID.DEMAND_PARTNER] = data.demandPartner || this[ReportColumns.ID.DEMAND_PARTNER];
		this[ReportColumns.ID.DEMAND_PARTNER_ID] = data.demandPartnerId || this[ReportColumns.ID.DEMAND_PARTNER_ID];
		this[ReportColumns.ID.PARENT_SUPPLY_PARTNER] = data.parentSupplyPartner || this[ReportColumns.ID.PARENT_SUPPLY_PARTNER];
		this[ReportColumns.ID.PARENT_DEAL_ID] = data.parentDealId || this[ReportColumns.ID.PARENT_DEAL_ID];
		this[ReportColumns.ID.ADVERTISER] = data.adv || this[ReportColumns.ID.ADVERTISER];
		this[ReportColumns.ID.ADVERTISER_ID] = parseInt(data.advid, 10) || this[ReportColumns.ID.ADVERTISER_ID];

		this[ReportColumns.ID.IMPRESSIONS] = parseInt(data.pi, 10) || this[ReportColumns.ID.IMPRESSIONS];
		this[ReportColumns.ID.DELIVERIES] = parseInt(data.deliveries, 10) || this[ReportColumns.ID.DELIVERIES];
		this[ReportColumns.ID.LOSS_RATE] = parseFloat(data.lossRate, 10) || this[ReportColumns.ID.LOSS_RATE];
		this[ReportColumns.ID.FALLBACKS] = parseInt(data.fb, 10) || this[ReportColumns.ID.FALLBACKS];

		this[ReportColumns.ID.REVENUE] = parseFloat(data.pr) || this[ReportColumns.ID.REVENUE];
		this[ReportColumns.ID.NET_REVENUE] = parseFloat(data.pnr) || this[ReportColumns.ID.NET_REVENUE];
		this[ReportColumns.ID.PARENT_REVENUE] = parseFloat(data.parentRevenue) || this[ReportColumns.ID.PARENT_REVENUE];

		this[ReportColumns.ID.ECPM] = parseFloat(data.partnerEcpm) || this[ReportColumns.ID.ECPM];
		this[ReportColumns.ID.NET_ECPM] = parseFloat(data.partnerNetEcpm) || this[ReportColumns.ID.NET_ECPM];

		this[ReportColumns.ID.VIDEO_STARTS] = angular.isNumber(data.videoStarts) ? data.videoStarts : this[ReportColumns.ID.VIDEO_STARTS];
		this[ReportColumns.ID.VIDEO_FIRST_QUARTILE] = angular.isNumber(data.videoFirstQuartile)
			? data.videoFirstQuartile
			: this[ReportColumns.ID.VIDEO_FIRST_QUARTILE];
		this[ReportColumns.ID.VIDEO_MIDPOINT] = angular.isNumber(data.videoMidpoint) ? data.videoMidpoint : this[ReportColumns.ID.VIDEO_MIDPOINT];
		this[ReportColumns.ID.VIDEO_THIRD_QUARTILE] = angular.isNumber(data.videoThirdQuartile)
			? data.videoThirdQuartile
			: this[ReportColumns.ID.VIDEO_THIRD_QUARTILE];
		this[ReportColumns.ID.VIDEO_COMPLETE] = angular.isNumber(data.videoComplete) ? data.videoComplete : this[ReportColumns.ID.VIDEO_COMPLETE];
		this[ReportColumns.ID.VIDEO_CLICK] = angular.isNumber(data.videoClick) ? data.videoClick : this[ReportColumns.ID.VIDEO_CLICK];

		this[ReportColumns.ID.INVOICE] = data.invoice || this[ReportColumns.ID.INVOICE];
	};

	/**
	 * merge/add parameter ReportRow into this ReportRow
	 *
	 * @param {ReportRow} row
	 * @return {void}
	 */
	ReportRow.prototype.mergeAggregateColumns = function mergeAggregateColumns(row) {
		// @todo integrate reporting columns service to have access to the global valid model
		// @todo and be to know which columns are aggregate-able

		var columns = [
				ReportColumns.ID.IMPRESSIONS,
				ReportColumns.ID.DELIVERIES,
				ReportColumns.ID.FALLBACKS,

				ReportColumns.ID.REVENUE,
				ReportColumns.ID.NET_REVENUE,
				ReportColumns.ID.PARENT_REVENUE,

				ReportColumns.ID.VIDEO_STARTS,
				ReportColumns.ID.VIDEO_FIRST_QUARTILE,
				ReportColumns.ID.VIDEO_MIDPOINT,
				ReportColumns.ID.VIDEO_THIRD_QUARTILE,
				ReportColumns.ID.VIDEO_COMPLETE,
				ReportColumns.ID.VIDEO_CLICK,
			],
			i,
			length = columns.length;

		for (i = 0; i < length; i++) {
			// check if incoming value is a number then convert current value to number as well
			if (angular.isNumber(row[columns[i]]) && angular.isNumber(this[columns[i]] * 1)) {
				this[columns[i]] = this[columns[i]] * 1 + row[columns[i]];
			}
		}
	};

	/**
	 * calculate eCPM according revenue and impressions
	 *
	 * @param {Number} revenue
	 * @param {int} impressions
	 *
	 * @return {Number}
	 */
	ReportRow.prototype.calculateEcpm = function calculateEcpm(revenue, impressions) {
		if (!revenue || !impressions || impressions === 0) {
			return 0.0;
		}
		return parseFloat(((revenue / impressions) * 1000).toFixed(2));
	};

	/**
	 * calculate eCPM columns
	 *
	 * @returns {ReportRow}
	 */
	ReportRow.prototype.calculateEcpmColumns = function calculateEcpmColumns() {
		this[ReportColumns.ID.ECPM] = this.calculateEcpm(this[ReportColumns.ID.REVENUE], this[ReportColumns.ID.IMPRESSIONS]);
		this[ReportColumns.ID.NET_ECPM] = this.calculateEcpm(this[ReportColumns.ID.NET_REVENUE], this[ReportColumns.ID.IMPRESSIONS]);

		return this;
	};

	/**
	 * calculates average value for loss rate
	 *
	 * @param {int} impressions
	 * @param {int} deliveries
	 *
	 * @return {Number}
	 */
	ReportRow.prototype.calculateLossRateAverage = function calculateLossRateAverage(impressions, deliveries) {
		if (impressions === null || angular.isUndefined(impressions) || !deliveries) {
			return 0;
		}
		return parseFloat(((1 - impressions / deliveries) * 100).toFixed(2));
	};

	/**
	 * calculate loss rate average
	 *
	 * @returns {ReportRow}
	 */
	ReportRow.prototype.calculateLossRateMergedColumn = function calculateLossRateMergedColumn() {
		this[ReportColumns.ID.LOSS_RATE] = this.calculateLossRateAverage(this[ReportColumns.ID.IMPRESSIONS], this[ReportColumns.ID.DELIVERIES]);

		return this;
	};

	ReportRow.prototype.calculateMergedColumns = function calculateMergedColumns() {
		this.calculateEcpmColumns();
		this.calculateLossRateMergedColumn();
		return this;
	};

	/**
	 * Used for exports.
	 * Return row object with only the columnIds specified by the columnIds parameter. Row type will always be added.
	 *
	 * @param {Array<string>} columnIds
	 * @returns {Object}
	 */
	ReportRow.prototype.getByColumnIds = function getByColumnIds(columnIds) {
		const result = {};

		columnIds.forEach((columId) => {
			result[columId] = this[columId];
		});

		result.type = this.type;

		return result;
	};

	/**
	 * @returns {boolean}
	 */
	ReportRow.prototype.isSummary = function isSummary() {
		return this.type === 'summary';
	};

	/**
	 * used to copy the current report row
	 *
	 * @return {ReportRow}
	 */
	ReportRow.prototype.copy = function copy() {
		return angular.copy(this);
	};

	return ReportRow;
}

export default ReportRowModel;
