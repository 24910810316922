const template = `<div class="dropdown has-badge">
	<span data-ng-if="$ctrl.selection.count" class="badge primary top-left" data-ng-bind="$ctrl.selection.count"></span>
	<a href data-yl-dropdown-toggle><i class="icon icon-list"></i> <i class="icon icon-down-dir"></i></a>
	<div class="dropdown-content">
		<ul class="vertical menu text-left">
			<li><a class="btn link primary" href data-ng-click="::$ctrl.selectAll()" data-translate="CAPTION.SELECT_ALL"></a></li>
			<li><a class="btn link primary" href data-ng-click="::$ctrl.clearSelection()" data-translate="CAPTION.CLEAR_SELECTION"></a></li>
		</ul>
	</div>
</div>
`;

const listSelector = {
	bindings: {
		selection: '<',
		getSelectables: '&',
	},
	template,
	controller() {
		this.selectAll = () => {
			this.selection.clear();
			const selectables = this.getSelectables();
			this.selection.check(selectables);
		};

		this.clearSelection = () => {
			this.selection.clear();
		};
	},
	controllerAs: '$ctrl',
};

export default {
	name: 'listSelector',
	config: listSelector,
};
