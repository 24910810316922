import angular from 'angular';

import ConstantsModule from '../constants/constants.module.js';
import ModelsModule from '../models/models.module.js';
import ApiModule from '../modules/api/api.module.js';
import StateModule from '../state/state.module.js';

import ListFilterModule from './list-filter/list-filter.module.js';
import PagerModule from './pager/pager.module.js';

import listSelector from './list-selector.js';

import AdslotCsvService from '../modules/services/Adslot.Csv.service.js';
import GenericCsvService from '../modules/services/Generic.Csv.service.js';
import ObjectsHelperService from '../modules/services/ObjectsHelper.service.js';
import PartnershipsCsvService from '../modules/services/Partnership.Csv.service.js';
import ReportFormatterService from '../modules/services/ReportFormatter.service.js';
import AppDataLoadingIndicator from './AppDataLoadingIndicator.js';
import AppUserIndicator from './AppUserIndicator.js';
import ArrayToString from './ArrayToString.js';
import AutofocusId from './AutofocusId.js';
import BlackWhiteFilter from './BlackWhiteFilter.js';
import bsDropdownEvents from './bsDropdownEvents.js';
import ButtonGroupToggle from './ButtonGroupToggle.js';
import jQueryChartDirective from './chart.js';
import Checklist from './Checklist.js';
import ClearInput from './ClearInput.js';
import ClickPreventDefault from './ClickPreventDefault.js';
import CustomValidate from './CustomValidate.js';
import Datepicker from './Datepicker.js';
import DropdownMenu from './DropdownMenu.js';
import DropdownToggle from './DropdownToggle.js';
import Ellipsis from './ellipsis.js';
import FileInputModel from './FileInputModel.js';
import FocusOn from './focusOn.js';
import FormDebug from './FormDebug.js';
import HasAdslotCreation from './HasAdslotCreation.js';
import Indeterminate from './Indeterminate.js';
import InlineEdit from './InlineEdit.js';
import InputDimension from './InputDimension.js';
import IntegerOnly from './IntegerOnly.js';
import LoadingCircle from './LoadingCircle.js';
import LoginBackground from './LoginBackground.js';
import LowercaseInput from './lowercase.js';
import MiniTip from './MiniTip.js';
import ModalClose from './ModalClose.js';
import ModelUpdateOn from './ModelUpdateOn.js';
import MultiSelect from './MultiSelect.js';
import NoClick from './NoClick.js';
import NoDrag from './NoDrag.js';
import NoFocus from './NoFocusCheckbox.js';
import NoSubmit from './NoSubmit.js';
import OnlyAdmin from './OnlyAdmin.js';
import OnlyAdminNotBeta from './OnlyAdminNotBeta.js';
import OnlyBeta from './OnlyBeta.js';
import OnlyBetaAndAdmin from './OnlyBetaAndAdmin.js';
import OnlyDirectConnect from './OnlyDirectConnect.js';
import Price from './Price.js';
import ResizeAnalyticsFrame from './ResizeAnalyticsFrame.js';
import SearchableSelect from './SearchableSelect.js';
import SelectDimension from './SelectDimension.js';
import SortButtons from './SortButtons.js';
import SplitInput from './SplitInput.js';
import StateResolveLoadingIndicator from './StateResolveLoadingIndicator.js';
import SubmitAttempt from './SubmitAttempt.js';
import ToasterTranslateBody from './ToasterTranslateBody.js';
import ToggleScrolltable from './ToggleScrolltable.js';
import TriggerModelUpdateOn from './TriggerModelUpdateOn.js';

const ComponentsModule = angular
	.module('app.components', [ConstantsModule, ModelsModule, ApiModule, StateModule, ListFilterModule, PagerModule])

	.factory('GenericCsvService', GenericCsvService)
	.factory('AdslotCsvService', AdslotCsvService)
	.factory('PartnershipsCsvService', PartnershipsCsvService)
	.factory('ObjectsHelperService', ObjectsHelperService)
	.factory('ReportFormatterService', ReportFormatterService)

	.component(listSelector.name, listSelector.config)

	.directive('ylAppDataLoadingIndicator', AppDataLoadingIndicator)
	.directive('appUserIndicator', AppUserIndicator)
	.directive('ylArrayToString', ArrayToString)
	.directive('autofocusId', AutofocusId)
	.directive('ylBlackWhiteFilter', BlackWhiteFilter)
	.directive('bsDropdownEvents', bsDropdownEvents)
	.directive('ylButtonGroupToggle', ButtonGroupToggle)
	.directive('chart', jQueryChartDirective)
	.directive('ylChecklist', Checklist)
	.directive('ylClearInput', ClearInput)
	.directive('clickPreventDefault', ClickPreventDefault)
	.directive('ylCustomValidate', CustomValidate)
	.directive('ylDatepicker', Datepicker)
	.directive('ylDropdownMenu', DropdownMenu)
	.directive('ylDropdownToggle', DropdownToggle)
	.directive('ellipsis', Ellipsis)
	.directive('ylFileInputModel', FileInputModel)
	.directive('focusOn', FocusOn)
	.directive('ylFormDebug', FormDebug)
	.directive('hasAdslotCreation', HasAdslotCreation)
	.directive('ylIndeterminate', Indeterminate)
	.directive('ylInlineEdit', InlineEdit)
	.directive('ylInputDimension', InputDimension)
	.directive('integerOnly', IntegerOnly)
	.directive('ylLoadingCircle', LoadingCircle)
	.directive('ylLoginBackground', LoginBackground)
	.directive('ylLowercase', LowercaseInput)
	.directive('ylMiniTip', MiniTip)
	.directive('ylModalClose', ModalClose)
	.directive('ylModelUpdateOn', ModelUpdateOn)
	.directive('ylMultiSelect', MultiSelect)
	.directive('noclick', NoClick)
	.directive('noDrag', NoDrag)
	.directive('noFocusCheckbox', NoFocus)
	.directive('nosubmit', NoSubmit)
	.directive('onlyAdmin', OnlyAdmin)
	.directive('onlyAdminNotBeta', OnlyAdminNotBeta)
	.directive('onlyBeta', OnlyBeta)
	.directive('onlyBetaAndAdmin', OnlyBetaAndAdmin)
	.directive('onlyDirectConnect', OnlyDirectConnect)
	.directive('ylPrice', Price)
	.directive('resizeAnalyticsFrame', ResizeAnalyticsFrame)
	.directive('searchableSelect', SearchableSelect)
	.directive('ylSelectDimension', SelectDimension)
	.directive('ylSortButtons', SortButtons)
	.directive('ylSplitInput', SplitInput)
	.directive('ylStateResolveLoadingIndicator', StateResolveLoadingIndicator)
	.directive('ylSubmit', SubmitAttempt)
	.directive('toasterTranslateBody', ToasterTranslateBody)
	.directive('toggleScrolltable', ToggleScrolltable)
	.directive('ylTriggerUpdateModelOn', TriggerModelUpdateOn)
	.directive('renderTemplate', ($compile, $parse) => {
		'ngInject';

		return {
			scope: false,
			link(scope, iElement, iAttrs) {
				const templateString = ($parse(iAttrs.renderTemplate)(scope) || '').toString();

				if (templateString) {
					iElement.append($compile(templateString)(scope));
				}
			},
		};
	}).name;

export default ComponentsModule;
