import { ascByNameField } from '../../utils/sort-utils.js';

function ReportsAdslotFilterController($rootScope, $scope, Adslots, ModalCloser, close, filterData, ReportsFilterSharedService) {
	'ngInject';

	const vm = this;

	vm.adslots = Adslots.getList().sort(ascByNameField);
	vm.filterData = filterData;

	this.assignedAdslotsListFilter = {};

	ReportsFilterSharedService.init($scope, ReportsAdslotFilterController.name);
	ReportsFilterSharedService.registerMethod(
		ReportsAdslotFilterController.name,
		ReportsFilterSharedService.getAvailableNames().METHOD_GET_FILTERED_DATE,
		(adslotList) => {
			return adslotList.filter((el) => this.assignedAdslotsListFilter.fn(el.element));
		},
	);

	$scope.$watch(
		() => this.assignedAdslotsListFilter,
		(newVal, oldVal) => {
			if (newVal !== oldVal) {
				ReportsFilterSharedService.callMethod(ReportsFilterSharedService.getAvailableNames().METHOD_UPDATE);
			}
		},
		true,
	);

	let selection = [];

	vm.onSelectionChange = ($event) => {
		selection = $event.selection;
	};

	vm.close = (result, delay) => {
		ModalCloser.close(close, result, delay);
	};

	vm.cancel = () => {
		vm.close(filterData, 200);
	};

	vm.submit = () => {
		vm.close(
			selection.map((entry) => entry.id),
			200,
		);
	};

	$scope.$on('$destroy', $rootScope.$on('event:auth-loginRequired', vm.cancel));
}

export default ReportsAdslotFilterController;
