import AdvertiserCategory from '../models/AdvertiserCategory.js';

function AdvertiserCategories(Api) {
	'ngInject';

	this.advertiserCategories = new Map();

	/**
	 * @return {promise}
	 */
	this.loadAll = () => {
		return Api.get('/api/supply/v1/advertisercategories').then((response) => {
			this.advertiserCategories.clear();
			const advertiserCategories = response.advertiserCategories;
			for (let i = 0; i < advertiserCategories.length; i++) {
				const data = advertiserCategories[i];
				this.advertiserCategories[data.id] = new AdvertiserCategory(data);
			}
		});
	};

	/**
	 * @returns {Object.<int, Advertiser>}
	 */
	this.getMap = function getMap() {
		return this.advertiserCategories;
	};

	/**
	 * @param {int} id
	 * @returns {Advertiser}
	 */
	this.getById = function getById(id) {
		return this.advertiserCategories[id];
	};
}

export default AdvertiserCategories;
