import angular from 'angular';

import SettingsInventoryEditModule from './settings-inventory-edit/settings-inventory-edit.module.js';

import SettingsInventoryController from './settings-inventory.controller.js';

const SettingsInventoryModule = angular
	.module('settingsInventory', [SettingsInventoryEditModule])
	.controller('SettingsInventoryController', SettingsInventoryController).name;

export default SettingsInventoryModule;
