function LowercaseInput($document) {
	'ngInject';

	function getCaretPosition(inputElement) {
		// get the element
		var $elem = angular.element(inputElement),
			// default caret position
			caretPosition = 0,
			selection;

		// ie way of doing things
		if ($document.selection) {
			// get empty selection range
			selection = $document.selection.createRange();

			// move selection start to 0 position
			selection.moveStart('character', -$elem.val().length);

			// the caret position is selection length
			caretPosition = selection.text.length;
			// proper way of doing things
		} else if (inputElement.selectionStart || inputElement.selectionStart === 0) {
			// the caret position is a lot easier to determine
			caretPosition = inputElement.selectionStart;
		}

		// return the caret position
		return caretPosition;
	}

	function setCaretPosition(inputElement, position) {
		var range;

		if (inputElement.setSelectionRange) {
			inputElement.setSelectionRange(position, position);
		} else if (inputElement.createTextRange) {
			range = inputElement.createTextRange();
			range.move('character', position);
			range.select();
		}
	}

	return {
		restrict: 'A',
		require: 'ngModel',
		scope: false,
		link: function postLink($scope, iElement, attrs, ngModel) {
			ngModel.$parsers.push(function inputToLowercase(input) {
				var lowercase = (input || '').toLowerCase(),
					caretPos = getCaretPosition(iElement[0]);

				if (angular.isDefined(input) && input !== lowercase) {
					ngModel.$setViewValue(lowercase);
					ngModel.$render();
					setCaretPosition(iElement[0], caretPos);
				}

				return lowercase;
			});
		},
	};
}

export default LowercaseInput;
