import NativeAsset from './NativeAsset.js';

class NativeAssetImage extends NativeAsset {
	static TYPE_ID = {
		ICON: 1,
		MAIN: 3,
	};

	imageType = 0;

	width = 0;

	height = 0;

	isAbsoluteDimension = false;

	constructor(data) {
		super(data);

		const { type, w = 0, wmin = 0, h = 0, hmin = 0 } = data.img || {};

		if (!new Set(Object.values(NativeAssetImage.TYPE_ID)).has(type)) {
			throw new Error('Not supported. Invalid image asset type provided.');
		}

		this.imageType = type;

		this.width = w || wmin;

		this.height = h || hmin;

		this.isAbsoluteDimension = w > 0 || h > 0;

		// UI convenience asset type "id"
		switch (type) {
			case NativeAssetImage.TYPE_ID.ICON:
				this.uiType = NativeAsset.TYPE.IMAGE_ICON;
				break;
			case NativeAssetImage.TYPE_ID.MAIN:
				this.uiType = NativeAsset.TYPE.IMAGE_MAIN;
				break;
			default: // not needed
		}
	}

	/**
	 * @returns {boolean}
	 */
	isImage() {
		// eslint-disable-line class-methods-use-this
		return true;
	}

	/**
	 * @returns {boolean}
	 */
	isMainImage() {
		// eslint-disable-line class-methods-use-this
		return this.imageType === NativeAssetImage.TYPE_ID.MAIN;
	}

	/**
	 * @returns {{}}
	 */
	toObject() {
		const img = {
			type: this.imageType,
			[this.isAbsoluteDimension ? 'w' : 'wmin']: this.width,
			[this.isAbsoluteDimension ? 'h' : 'hmin']: this.height,
		};

		return {
			...super.toObject(),
			img,
		};
	}
}

export const imageFormatSelectOptions = [
	{
		value: false,
		text: 'CAPTION.NATIVE_TEMPLATE.ASSET.IMAGE_FORMAT_RELATIVE',
	},
	{
		value: true,
		text: 'CAPTION.NATIVE_TEMPLATE.ASSET.IMAGE_FORMAT_ABSOLUTE',
	},
];

export default NativeAssetImage;
