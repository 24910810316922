function PartnershipsPrices(Api) {
	'ngInject';

	/**
	 * @type {Object.<int, {floorPrice: <int>, fixedPrice: <int>}>}
	 */
	this.prices = {};

	/**
	 * @returns {promise}
	 */
	this.loadAll = function loadAll() {
		var self = this;

		return Api.get('/api/supply/v1/partnerships/prices').then(function success(data) {
			var i;

			self.prices = {};

			for (i = 0; i < data.length; i++) {
				self.prices[data[i].id] = data[i];
			}
		});
	};

	/**
	 * @param {Array.<{id: <int>, floorPrice: <int>, fixedPrice: <int>}>} prices
	 * @returns {promise}
	 */
	this.update = (prices) => Api.post('/api/supply/v1/partnerships/prices', prices);

	/**
	 * @returns {Object.<int, {floorPrice: <int>, fixedPrice: <int>}>}
	 */
	this.getMap = function getMap() {
		return this.prices;
	};
}

export default PartnershipsPrices;
