import sitesStructureInfoDe from '../../../images/Infographic_structure_sites_de.png';
import sitesStructureInfoEn from '../../../images/Infographic_structure_sites_en.png';

const infoGraphics = {
	de: sitesStructureInfoDe,
	en: sitesStructureInfoEn,
};

function SitesController($rootScope, $translate, $scope, StructureBreadcrumb, ListSearchService, Sites, ModalFactory) {
	'ngInject';

	var unbindSaveSiteNameListener,
		unbindSitesUpdatedListener,
		INLINE_EDIT_PREFIX = 'site-',
		vm = this;

	vm.sites = Sites.getListAll();

	vm.search = ListSearchService.get('structure-sites-search');
	vm.sort = 'name';

	StructureBreadcrumb.reset();

	/**
	 * @param {Site} site
	 */
	vm.edit = function edit(site) {
		$rootScope.$emit('inlineEdit::start', INLINE_EDIT_PREFIX + site.id);
	};

	vm.addGroup = function addGroup(site) {
		ModalFactory.newGroup({
			siteId: site.id,
		});
	};

	vm.showHelpModal = function showHelpModal() {
		ModalFactory.imageModalMessage({
			file: infoGraphics[$translate.use()],
		});
	};

	unbindSaveSiteNameListener = $rootScope.$on('inlineEdit::save', function saveSiteNameListener(event, triggerId, inputText) {
		Sites.update(triggerId.substr(INLINE_EDIT_PREFIX.length), {
			name: inputText,
		})
			.then(function success() {
				$rootScope.$emit('inlineEdit::save-success', triggerId);
			})
			.catch(function error(errorObject) {
				var errorMsg;

				switch (errorObject.code) {
					case Sites.ERROR.DUPLICATE_NAME:
						errorMsg = 'MESSAGE.SITE_DUPLICATE_NAME';
						break;
					default:
						errorMsg = errorObject.errorMessage;
				}

				$rootScope.$emit('inlineEdit::save-error', triggerId, errorMsg);
			});
	});

	unbindSitesUpdatedListener = $rootScope.$on(Sites.EVENT.UPDATED, function sitesUpdatedListener() {
		vm.sites = Sites.getListAll();
	});

	$rootScope.cleanListeners($scope, [unbindSaveSiteNameListener, unbindSitesUpdatedListener]);
}

export default SitesController;
