import angular from 'angular';

import InventoryStructureBreadcrumbComponent from './inventory-structure-breadcrumb.component.js';

import InventoryStructureBreadcrumbController from './inventory-structure-breadcrumb.controller.js';

import InventoryStructureBreadcrumbService from './inventory-structure-breadcrumb.service.js';

const InventoryStructureBreadcrumbModule = angular // eslint-disable-line angular/no-service-method
	.module('inventory.structure.breadcrumb', [])
	.component('structureBreadcrumb', InventoryStructureBreadcrumbComponent)
	.controller('StructureBreadcrumbController', InventoryStructureBreadcrumbController)
	.service('StructureBreadcrumb', InventoryStructureBreadcrumbService).name;

export default InventoryStructureBreadcrumbModule;
