import OpenAuctionConfig from '../../../models/OpenAuctionConfig.js';
import SelectionService from '../../../modules/utils/SelectionService.js';

function PartnershipsOpenAuctionsViewAdslotsAddController(
	$q,
	$rootScope,
	$scope,
	InfoService,
	ModalCloser,
	OpenAuctionSettings,
	toaster,
	close,
	assignedAdslots,
	availableAdslots,
) {
	'ngInject';

	const vm = this;

	function triggerClose(result, delay) {
		ModalCloser.close(close, result, delay);
	}

	vm.ADD_ADSLOTS_LIST_ID = 'add-adslots-to-open-auction-configuration';

	vm.listFilter = {};

	vm.adslots = availableAdslots;

	vm.selection = new SelectionService();
	vm.errorIds = {};

	vm.submit = () => {
		const adslotIds = vm.selection.values();

		if (adslotIds.length) {
			const adslots = adslotIds.map((adslot) => ({ adslot, floorPrice: 0 }));

			InfoService.startRequest();

			OpenAuctionSettings.setGlobalAdslotConfigs(adslots)
				.then(() => {
					toaster.successMessage('MESSAGE.SETTINGS_SAVE_SUCCESS');
					InfoService.endRequest();

					triggerClose(adslots, 0);
				})
				.catch((error) => {
					// if no global config is set yet, create a default one and try again
					if (error && error.status === 404 && error.code === 6) {
						return OpenAuctionSettings.setGlobalConfig(new OpenAuctionConfig())
							.then(() => OpenAuctionSettings.setGlobalAdslotConfigs(adslots))
							.then(() => {
								toaster.successMessage('MESSAGE.SETTINGS_SAVE_SUCCESS');
								InfoService.endRequest();

								triggerClose(adslots, 0);
							});
					}

					return $q.reject(error);
				})
				.catch(() => {
					toaster.errorMessage('MESSAGE.SETTINGS_SAVE_ERROR');
					InfoService.endRequest();
				});
		}
	};

	vm.cancel = () => {
		triggerClose([], 200);
	};

	const unregisterSessionTimeoutListener = $rootScope.$on('event:auth-loginRequired', vm.cancel);

	$scope.$on('$destroy', unregisterSessionTimeoutListener);
}

export default PartnershipsOpenAuctionsViewAdslotsAddController;
