function PartnershipsOpenAuctionsController(natvalue, InfoService, OpenAuctionSettings, isOpenAuctionEnabled) {
	'ngInject';

	const vm = this;

	vm.natvalue = natvalue;

	vm.isEnabled = isOpenAuctionEnabled;

	vm.toggle = () => {
		if (!InfoService.isRequestInProgress()) {
			InfoService.startRequest();
			OpenAuctionSettings.setOpenAuctionEnabled(vm.isEnabled)
				.catch(() => {
					// reset toggle to previous state
					vm.isEnabled = !vm.isEnabled;
				})
				.finally(() => InfoService.endRequest());
		}
	};
}

export default PartnershipsOpenAuctionsController;
