function AdslotsViewController($scope, $translate, $state, Channels, resolvedAdslot, Adslots, toaster) {
	'ngInject';

	$scope.localModels = {
		adslot: resolvedAdslot,
	};

	$scope.localModels.channels = Channels.getNamesForIds($scope.localModels.adslot.channelId);

	$scope.methods.setScopeAdslot = function setScopeAdslot(adslot) {
		$scope.localModels.adslot = adslot;
	};

	$scope.disableAdslot = () => {
		// eslint-disable-next-line no-restricted-globals,no-alert
		if (confirm($translate.instant('MESSAGE.ADSLOT_DISABLE_CONFIRMATION'))) {
			Adslots.disableAdslot($scope.localModels.adslot.id)
				.then(() => {
					Adslots.getById($scope.localModels.adslot.id).visible = false;
					$scope.methods.rebuildAdslots();
					$state.go('inventory.adslots').finally(() => toaster.successMessage('MESSAGE.ADSLOT_DISABLE_SUCCESS'));
				})
				.catch(() => toaster.errorMessage('MESSAGE.ADSLOT_DISABLE_ERROR'));
		}
	};
}

export default AdslotsViewController;
