import ListFilterEntry from './ListFilterEntry.js';
import ListFilterEntryUI from './ListFilterEntryUI.js';

/**
 * @param {function(model, *)} matchingFn
 */
function ListFilterEntryObject(matchingFn) {
	ListFilterEntry.call(this, matchingFn);

	/**
	 * @type {Object.<string, boolean>}
	 */
	this.model = {};
}

ListFilterEntryObject.prototype = Object.create(ListFilterEntry.prototype);
ListFilterEntryObject.prototype.constructor = ListFilterEntryObject;

/**
 * @param {*} value
 * @returns {ListFilterEntry}
 */
ListFilterEntryObject.prototype.set = function setModel(value) {
	this.model = value;
	return this;
};

/**
 * @returns {ListFilterEntry}
 */
ListFilterEntryObject.prototype.unset = function unset() {
	this.model = {};
	return this;
};

/**
 * @returns {ListFilterEntry}
 */
ListFilterEntryObject.prototype.unsetOne = function unsetOne(modelKey) {
	delete this.model[modelKey];
	return this;
};

/**
 * @returns {boolean}
 */
ListFilterEntryObject.prototype.isActive = function isActive() {
	return !angular.equals(this.model, {});
};

/**
 * @returns Array.<ListFilterEntryUI>
 */
ListFilterEntryObject.prototype.asUIElements = function asUIElements() {
	var self = this;

	return Object.keys(this.model).map(function asUIEntry(key) {
		return new ListFilterEntryUI(self.model[key], self.unsetOne.bind(self, key));
	});
};

export default ListFilterEntryObject;
