function jQueryChartDirective() {
	return {
		restrict: 'A',
		compile: function compile() {
			return function postLink(scope, element, attributes) {
				var chart;

				scope.$watch(
					function watch() {
						return {
							data: scope.$eval(attributes.chartData || []),
							options: scope.$eval(attributes.chartOptions || {}),
						};
					},
					function watchChanged(chartData) {
						if (!angular.isArray(chartData.data)) {
							return;
						}
						if (!angular.isObject(chartData.options)) {
							return;
						}
						if (!chart) {
							element.show();
							chart = $.plot(element, chartData.data, chartData.options);
						} else {
							chart.setData(chartData.data);
							chart.setupGrid();
							chart.draw();
						}
					},
					true,
				);
			};
		},
	};
}

export default jQueryChartDirective;
