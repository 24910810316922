function PartnershipAdslots(Api, Adslots) {
	'ngInject';

	this.EVENTS = {
		PARTNERSHIP_ADSLOTS_SIZE_UPDATE: 'PartnershipAdslots::partnership-adslots-size-updated',
	};

	/**
	 * @param {number} partnershipId
	 * @returns {Promise}
	 */
	this.load = (partnershipId) => Api.get(`api/supply/v1/partnerships/${partnershipId}/adslots`).then(({ partnershipWebsites = [] }) => partnershipWebsites);

	/**
	 * @param {number} partnershipId
	 * @return {Array.<Adslot>}
	 */
	this.loadDetailed = async function loadDetailed(partnershipId) {
		const adslotIds = (await this.load(partnershipId)).map((adslot) => adslot.id);

		return adslotIds.map((adslotId) => Adslots.getById(adslotId));
	};

	/**
	 * @param {number} partnershipId
	 * @param {Array.<{id: <number>, floorPrice: <number>, fixedPrice: <number>}>} adslots
	 * @returns {Promise}
	 */
	this.updatePrices = (partnershipId, adslots) => Api.post(`/api/supply/v1/partnerships/${partnershipId}/adslots`, adslots);

	/**
	 * @param {number} partnershipId
	 * @param {Array.<number>} adslotIds
	 * @returns {Promise}
	 */
	this.add = (partnershipId, adslotIds) => Api.post(`/api/supply/v1/partnerships/${partnershipId}/adslots/add`, { ids: adslotIds });

	/**
	 * @param {number} partnershipId
	 * @param {Array.<number>} adslotIds
	 * @returns {Promise}
	 */
	this.remove = (partnershipId, adslotIds) => Api.post(`/api/supply/v1/partnerships/${partnershipId}/adslots/remove`, { ids: adslotIds });

	/**
	 * @param {Array.<{id: <number>, floorPrice: <number>, fixedPrice: <number>}>} adslotsList
	 * @returns {Object.<number, {floorPrice: <number>, fixedPrice: <number>}>} priceMap
	 */
	this.getPriceMap = (adslotsList) =>
		adslotsList.reduce((priceMap, entry) => {
			priceMap[entry.id] = {
				floorPrice: entry.floorPrice,
				fixedPrice: entry.fixedPrice,
			};
			return priceMap;
		}, {});
}

export default PartnershipAdslots;
