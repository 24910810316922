function AbstractPartnershipsController($rootScope, $scope, Partnerships, DemandPartners, ListFilterFactory, ListFilterSettings, ListSearchService) {
	'ngInject';

	var removeDestroyListener,
		removePartnershipUpdatedListener,
		removePartnershipRecreatedListener,
		listFilterId = 'partnerships-list-filter',
		listSearchId = 'partnerships-list-search';

	$scope.models = {
		partnerships: Partnerships.getList().reduce(function onlyVisiblePartners(list, partnership) {
			if (DemandPartners.isVisible(partnership.getDemandId())) {
				list.push(partnership);
			}
			return list;
		}, []),
		filteredPartnerships: [],

		patnershipsFilters: ListFilterFactory.get(ListFilterFactory.LAYOUTS.PARTNERSHIPS.LIST).load(
			ListFilterSettings.get(listFilterId) || ListFilterFactory.DEFAULT_SETTINGS.PARTNERSHIPS.LIST,
		),

		search: ListSearchService.get(listSearchId),

		listSortKey: 'demandName',
		advertisersSortKey: 'partnership',
	};

	$scope.methods = {};

	$scope.matchesFilter = $scope.models.patnershipsFilters.matchesFilter.bind($scope.models.patnershipsFilters);

	$scope.methods.rebuildPartnerships = function rebuildPartnerships() {
		$scope.models.partnerships = Partnerships.getList().reduce(function onlyVisiblePartners(list, partnership) {
			if (DemandPartners.isVisible(partnership.getDemandId())) {
				list.push(partnership);
			}
			return list;
		}, []);
	};

	removePartnershipUpdatedListener = $rootScope.$on(Partnerships.EVENTS.PARTNERSHIP_UPDATED, function partnershipUpdated() {
		$scope.methods.rebuildPartnerships();
	});

	removePartnershipRecreatedListener = $rootScope.$on(Partnerships.EVENTS.PARTNERSHIP_RECREATED, function partnershipsUpdated() {
		// After submitting Partnerships CSV Angular doesn't detect updated (only can detect new ones) partnerships.
		// Workaround, in order to force a parent UI to be updated, reset partnerships array and bind changes
		$scope.models.partnerships = [];
		$scope.$apply();

		$scope.models.partnerships = Partnerships.getList();
	});

	removeDestroyListener = $scope.$on('$destroy', function $destroyListener() {
		ListFilterSettings.set(listFilterId, $scope.models.patnershipsFilters.getActiveFilterData());

		removePartnershipUpdatedListener();
		removeDestroyListener();
		removePartnershipRecreatedListener();
	});
}

export default AbstractPartnershipsController;
