import ReportTemplate from '../../models/ReportTemplate.js';

function ReportsViewEditController($rootScope, template, Reports, ModalCloser, close) {
	'ngInject';

	const vm = this;
	vm.template = template;
	vm.isLossRate = template.type === ReportTemplate.TYPE.LOSS_RATE;
	vm.isRevenue = template.type === ReportTemplate.TYPE.REVENUE;
	vm.reportValidity = {
		dimensionsValid: true,
		keyFiguresValid: true,
	};

	vm.isReportValid = () => {
		return vm.reportValidity.dimensionsValid && vm.reportValidity.keyFiguresValid;
	};

	vm.dateOptionsInvalid = () => {
		if (vm.template.hasScheduleConfigured()) {
			return !vm.template.hasScheduleSupport();
		}
		return false;
	};

	vm.save = () => {
		if (vm.isReportValid()) {
			$rootScope.$emit(Reports.EVENT.SAVED, vm.template);
			vm.close();
		}
	};

	vm.reloadReport = () => {
		if (vm.isReportValid()) {
			$rootScope.$emit(Reports.EVENT.RELOADED, vm.template);
			vm.close();
		}
	};

	vm.close = () => {
		ModalCloser.close(close, undefined, 200);
	};
}

export default ReportsViewEditController;
