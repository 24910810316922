function ObjectsHelperService() {
	'ngInject';

	/**
	 * Swap keys + values of an object.
	 */
	function reverseObject(obj) {
		return Object.entries(obj).reduce((result, entry) => {
			result[entry[1]] = entry[0];
			return result;
		}, {});
	}

	/**
	 * Check, if two objects have the same values.
	 * If the objects are arrays, order of the items does not matter (this does not hold for deeper arrays).
	 */
	function objectsAreEqual(obj1, obj2) {
		if (obj1 instanceof Array && obj2 instanceof Array) {
			return angular.equals([...obj1].sort(), [...obj2].sort());
		}
		return angular.equals(obj1, obj2);
	}

	return {
		reverseObject: reverseObject,
		objectsAreEqual: objectsAreEqual,
	};
}

export default ObjectsHelperService;
