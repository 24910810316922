function SupplyProviderWhitelist(Api) {
	'ngInject';

	/**
	 * @return {promise}
	 */
	this.load = () => Api.get('/api/supply/v1/settings/providers').then((data) => data.items || []);

	/**
	 * @param {Array.<int>} whitelist
	 * @return {promise}
	 */
	this.update = (whitelist) =>
		Api.put('/api/supply/v1/settings/providers', {
			items: whitelist,
		});
}

export default SupplyProviderWhitelist;
