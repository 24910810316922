function SimpleModalMessageController(buttonText, close, text, ModalCloser) {
	'ngInject';

	var vm = this;

	vm.text = text;
	vm.buttonText = buttonText;

	vm.close = function closeFn() {
		ModalCloser.close(close, undefined, 300);
	};
}

export default SimpleModalMessageController;
