function PartnershipsViewController(
	$rootScope,
	$scope,
	$state,
	$stateParams,
	Adslots,
	ModalFactory,
	Partnerships,
	DemandPartners,
	PartnershipAdslots,
	Account,
) {
	'ngInject';

	var removePartnershipUpdatedListener;
	var removePartnershipAdslotsSizeUpdatedListener;

	this.$onInit = () => {
		PartnershipAdslots.load($stateParams.id)
			// handle invisible Adslots first
			.then((partnershipAdslots) => partnershipAdslots.filter(({ id: adslotId }) => Adslots.getById(adslotId).visible))
			// then proceed easily
			.then((result) => {
				$scope.numberOfConnectedAdslots = result.length;
			});
	};

	$scope.localModels = {
		partnership: angular.copy(Partnerships.getById($stateParams.id)),
	};

	$scope.localModels.fromAdvertisers = $stateParams.adv === '';

	$scope.showTargeting = !$scope.localModels.partnership.isProgrammaticGuaranteed();

	$scope.showSizes = !Account.mustOnlyCreateDealPartnerships();

	$scope.showNoSizesWarning = () => !$scope.localModels.partnership.getActiveSizes().length;

	$scope.showNoAdslotsWarning = () => $scope.numberOfConnectedAdslots === 0;

	$scope.duplicatePartnership = function duplicatePartnership() {
		ModalFactory.duplicatePartnership(
			{
				partnership: Partnerships.getById($scope.localModels.partnership.getId()),
			},
			function modalClosed(id) {
				if (id) {
					$scope.methods.rebuildPartnerships();
					$state.go('partnerships.list.view.details', { id: id });
				}
			},
		);
	};

	$scope.setScopePartnership = function setScopePartnership(partnership) {
		$scope.localModels.partnership = partnership;
	};

	$scope.localModels.getSeatId = () => {
		let partner = Partnerships.getById($stateParams.id);
		if (!partner || !partner.getDemandId() || !DemandPartners.getById(partner.getDemandId()) || !DemandPartners.getById(partner.getDemandId()).getSeatId()) {
			return '';
		}

		return DemandPartners.getById(partner.getDemandId()).getSeatId();
	};

	removePartnershipUpdatedListener = $rootScope.$on(Partnerships.EVENTS.PARTNERSHIP_UPDATED, (event, partnership) => {
		if (angular.isDefined(partnership)) {
			$scope.setScopePartnership(partnership);
		}
	});

	removePartnershipAdslotsSizeUpdatedListener = $rootScope.$on(PartnershipAdslots.EVENTS.PARTNERSHIP_ADSLOTS_SIZE_UPDATE, (event, numberOfConnectedAdslots) => {
		$scope.numberOfConnectedAdslots = numberOfConnectedAdslots;
	});

	$scope.$on('$destroy', () => {
		removePartnershipUpdatedListener();
		removePartnershipAdslotsSizeUpdatedListener();
	});
}

export default PartnershipsViewController;
