function AutofocusId($rootScope, $timeout) {
	'ngInject';

	return {
		restrict: 'A',
		link: function postLink(scope, iElement, iAttrs) {
			var unbindeDestroyListener, unbindAutofocsIdListener;

			if (angular.isDefined(iAttrs.onLoad)) {
				$timeout(function defer() {
					iElement.focus();
				});
			}

			unbindAutofocsIdListener = $rootScope.$on('autofocusId::trigger', function autofocusIdListener(event, autofocusId) {
				if (iAttrs.autofocusId === autofocusId) {
					iElement.focus();
				}
			});

			unbindeDestroyListener = scope.$on('$destroy', function $destroyListener() {
				unbindAutofocsIdListener();
				unbindeDestroyListener();
			});
		},
	};
}

export default AutofocusId;
