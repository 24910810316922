import AdvertiserFilter from '../models/AdvertiserFilter.js';

function SupplyFilter(Api) {
	'ngInject';

	this.advertiserIds = [];

	/**
	 * @returns {Promise.<AdvertiserFilter>}
	 */
	this.get = () =>
		Api.get('/api/supply/v1/filters').then(({ advertisers, advertiserFilter }) => {
			this.advertiserIds = advertisers;
			return AdvertiserFilter.fromIdsAndType(advertisers, advertiserFilter);
		});

	/**
	 * @param {AdvertiserFilter} advertiserFilter
	 * @returns {Promise.<AdvertiserFilter>}
	 */
	this.save = (advertiserFilter) =>
		Api.post('/api/supply/v1/filters', advertiserFilter.toOldObject()).then(() => new AdvertiserFilter(advertiserFilter.toObject()));
}

export default SupplyFilter;
