function SettingsInventoryController(resolvedReferrerGroupsMap) {
	'ngInject';

	const vm = this;

	vm.referrerGroups = Object.values(resolvedReferrerGroupsMap);

	vm.search = {};
	vm.listSortKey = 'name';
}

export default SettingsInventoryController;
