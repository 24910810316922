import {
	addHours,
	endOfDay,
	endOfISOWeek,
	endOfMonth,
	format,
	isValid,
	parseISO,
	startOfDay,
	startOfISOWeek,
	startOfMonth,
	subDays,
	subMonths,
	subWeeks,
} from 'date-fns';
import isEmpty from 'lodash/isEmpty.js';
import isUndefined from 'lodash/isUndefined.js';

const HOURS_OF_THE_DAY = [...Array(24).keys()];

class ReportPeriod {
	constructor(data) {
		this.start = isUndefined(data.period) || isUndefined(data.period.start) ? startOfDay(new Date()) : parseISO(data.period.start);
		this.end = isUndefined(data.period) || isUndefined(data.period.end) ? endOfDay(new Date()) : parseISO(data.period.end);
		this.type = isUndefined(data.period) || isUndefined(data.period.type) ? ReportPeriod.TYPE.TODAY : data.period.type;

		this.startHour = this.start.getHours();
		this.endHour = this.end.getHours();

		this.separatedByHour = data.separatedByHour || false;

		this.setStartAndEndAccordingToType();
	}

	static API_REPORT_DATE_FORMAT = "yyyy-MM-dd'T'HH:mmXX";

	static REQUEST_DATE_FORMAT = 'yyyy-MM-dd';

	static TYPE = {
		CUSTOM: 'custom',
		TODAY: 'today',
		CURRENT_WEEK: 'current_week',
		CURRENT_MONTH: 'current_month',
		YESTERDAY: 'yesterday',
		LAST_WEEK: 'last_week',
		LAST_MONTH: 'last_month',
		LAST_THIRTY_DAYS: 'last_thirty_days',
	};

	static TYPE_TRANSLATION = {
		CUSTOM: 'CAPTION.TIME_PRESET_DEFAULT',
		TODAY: 'CAPTION.TIME_PRESET_TODAY',
		CURRENT_WEEK: 'CAPTION.TIME_PRESET_CURRENT_WEEK',
		CURRENT_MONTH: 'CAPTION.TIME_PRESET_CURRENT_MONTH',
		YESTERDAY: 'CAPTION.TIME_PRESET_YESTERDAY',
		LAST_WEEK: 'CAPTION.TIME_PRESET_LAST_WEEK',
		LAST_MONTH: 'CAPTION.TIME_PRESET_LAST_MONTH',
		LAST_THIRTY_DAYS: 'CAPTION.TIME_PRESET_LAST_THIRTY_DAYS',
	};

	static SCHEDULED_SUPPORT_PERIOD_TYPES = new Set([
		ReportPeriod.TYPE.YESTERDAY,
		ReportPeriod.TYPE.LAST_WEEK,
		ReportPeriod.TYPE.LAST_MONTH,
		ReportPeriod.TYPE.LAST_THIRTY_DAYS,
	]);

	static SEPARABLE_BY_HOUR = new Set([ReportPeriod.TYPE.CUSTOM, ReportPeriod.TYPE.TODAY, ReportPeriod.TYPE.YESTERDAY]);

	static getTimePresetSelectOptions() {
		return Object.keys(ReportPeriod.TYPE).map((type) => ({
			name: ReportPeriod.TYPE_TRANSLATION[type],
			value: ReportPeriod.TYPE[type],
		}));
	}

	static getTimePresetSelectOptionsDashboard() {
		return ReportPeriod.getTimePresetSelectOptions().filter(
			(option) => option.value !== ReportPeriod.TYPE.TODAY && option.value !== ReportPeriod.TYPE.YESTERDAY,
		);
	}

	static getTimePresetSelectOptionsLossRateReport() {
		return ReportPeriod.getTimePresetSelectOptions().filter((option) => option.value !== ReportPeriod.TYPE.LAST_MONTH);
	}

	static getTimePresetSelectSeparatedByHourOptions() {
		return ReportPeriod.getTimePresetSelectOptions().filter((option) => ReportPeriod.SEPARABLE_BY_HOUR.has(option.value));
	}

	/**
	 * @deprecated
	 */
	static TYPE_ID = {
		[ReportPeriod.TYPE.CUSTOM]: 0,
		[ReportPeriod.TYPE.TODAY]: 1,
		[ReportPeriod.TYPE.CURRENT_WEEK]: 2,
		[ReportPeriod.TYPE.CURRENT_MONTH]: 3,
		[ReportPeriod.TYPE.YESTERDAY]: 4,
		[ReportPeriod.TYPE.LAST_WEEK]: 5,
		[ReportPeriod.TYPE.LAST_MONTH]: 6,
		[ReportPeriod.TYPE.LAST_THIRTY_DAYS]: 7,
	};

	/**
	 * @returns {boolean}
	 */
	isValid() {
		return isValid(this.start) && isValid(this.end) && this.start <= this.end && this.start.getTime() > 0;
	}

	/**
	 * Used in templates.
	 * @returns {boolean}
	 */
	isTypeCustom() {
		return this.type === ReportPeriod.TYPE.CUSTOM;
	}

	isLongerThanOneDay() {
		if (!ReportPeriod.SEPARABLE_BY_HOUR.has(this.type)) {
			return true;
		}

		if (ReportPeriod.TYPE.YESTERDAY === this.type || ReportPeriod.TYPE.TODAY === this.type) {
			return false;
		}

		const diffTime = Math.abs(this.end - this.start);
		const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));

		return diffHours > 24;
	}

	/**
	 * @param {string} type
	 * @returns {ReportPeriod}
	 */
	setType(type) {
		if (!((type || '').toUpperCase() in ReportPeriod.TYPE)) {
			throw new Error(`invalid type ${type} given`);
		}

		this.type = type;
		return this;
	}

	/**
	 * @param {Date} start
	 * @param {Date} end
	 * @returns {ReportPeriod}
	 */
	setTypeToCustom(start, end) {
		this.type = ReportPeriod.TYPE.CUSTOM;
		this.start = start;
		this.end = end;
		return this;
	}

	/**
	 * @returns {ReportPeriod}
	 */
	setStartAndEndAccordingToType() {
		const today = new Date();

		switch (this.type) {
			case ReportPeriod.TYPE.TODAY:
				this.start = startOfDay(today);
				this.end = endOfDay(today);
				break;

			case ReportPeriod.TYPE.CURRENT_WEEK:
				this.start = startOfISOWeek(today);
				this.end = endOfISOWeek(today);
				break;

			case ReportPeriod.TYPE.LAST_WEEK:
				this.start = startOfISOWeek(subWeeks(today, 1));
				this.end = endOfISOWeek(subWeeks(today, 1));
				break;

			case ReportPeriod.TYPE.CURRENT_MONTH:
				this.start = startOfMonth(today);
				this.end = endOfMonth(today);
				break;

			case ReportPeriod.TYPE.LAST_MONTH:
				this.start = startOfMonth(subMonths(today, 1));
				this.end = endOfMonth(subMonths(today, 1));
				break;

			case ReportPeriod.TYPE.YESTERDAY:
				this.start = startOfDay(subDays(today, 1));
				this.end = endOfDay(subDays(today, 1));
				break;

			case ReportPeriod.TYPE.LAST_THIRTY_DAYS:
				this.start = startOfDay(subDays(today, 30));
				this.end = endOfDay(subDays(today, 1));
				break;

			case ReportPeriod.TYPE.CUSTOM:
			default:
				// nothing to do
				break;
		}

		if (this.type !== ReportPeriod.TYPE.CUSTOM) {
			this.startHour = this.start.getHours();
			this.endHour = this.end.getHours();
		}

		return this;
	}

	/**
	 * @returns {Date}
	 */
	getMaxDate() {
		const now = new Date();

		switch (this.type) {
			case ReportPeriod.TYPE.CUSTOM:
			case ReportPeriod.TYPE.TODAY:
				return endOfDay(now);
			case ReportPeriod.TYPE.CURRENT_WEEK:
				return endOfISOWeek(now);
			case ReportPeriod.TYPE.CURRENT_MONTH:
				return endOfMonth(now);
			case ReportPeriod.TYPE.YESTERDAY:
				return endOfDay(subDays(now, 1));
			case ReportPeriod.TYPE.LAST_WEEK:
				return endOfISOWeek(subWeeks(now, 1));
			case ReportPeriod.TYPE.LAST_MONTH:
				return endOfMonth(subMonths(now, 1));
			case ReportPeriod.TYPE.LAST_THIRTY_DAYS:
				return endOfDay(subDays(now, 1));

			default:
				return endOfMonth(now);
		}
	}

	alignEndDateWithStartDate() {
		if (!isValid(this.start)) {
			return;
		}

		this.start = startOfDay(this.start);
		this.end = ReportPeriod.getUpdatedDate(this.start);
	}

	alignStartDateWithEndDate() {
		if (!isValid(this.end)) {
			return;
		}
		this.start = ReportPeriod.getUpdatedDate(this.end);
		this.end = startOfDay(this.end);
	}

	static getUpdatedDate(date) {
		const updatedDate = startOfDay(new Date());
		updatedDate.setDate(date.getDate());

		return updatedDate;
	}

	getValidStartHourOptions() {
		if (!isValid(this.start)) {
			return [];
		}

		return ReportPeriod.getValidHourOptions(this.start);
	}

	getValidEndHourOptions() {
		if (!this.isValid()) {
			return [];
		}

		const options = ReportPeriod.getValidHourOptions(this.end);

		return this.start.getDate() === this.end.getDate() ? options.filter((value) => value >= this.startHour) : options.filter((value) => value < this.startHour);
	}

	static getValidHourOptions(date) {
		const currentTime = new Date();

		if (currentTime.getDate() > date.getDate()) {
			return HOURS_OF_THE_DAY;
		}

		const start = startOfDay(date);

		return HOURS_OF_THE_DAY.filter((hour) => addHours(start, hour) < currentTime);
	}

	/**
	 * @param {Date} maxDate
	 * @param {Date|null} [minDate]
	 * @returns {ReportPeriod}
	 */
	clampDates = (maxDate, minDate = null) => {
		if (!isEmpty(minDate) && this.start < minDate) {
			this.start = new Date(minDate.valueOf());
		}

		if (!isEmpty(minDate) && this.end < minDate) {
			this.end = endOfDay(new Date(minDate.valueOf()));
		}

		// adjust start date to maxDate if later than allowed
		if (this.start > maxDate) {
			this.start = startOfDay(new Date(maxDate.valueOf()));
		}

		// adjust end date to maxDate if later than allowed
		if (this.end > maxDate) {
			this.end = new Date(maxDate.valueOf());
		}

		return this;
	};

	/**
	 * @deprecated
	 * @returns {number}
	 */
	getDeprecatedTypeId() {
		return ReportPeriod.TYPE_ID[this.type];
	}

	/**
	 * @param {number} id
	 * @returns {ReportPeriod}
	 */
	setTypeFromDeprecatedTypeId(id) {
		const [type] = Object.entries(ReportPeriod.TYPE_ID).find(([, typeId]) => typeId === id);

		if (isUndefined(type)) {
			throw new Error(`could not set report period type from invalid deprecated type id ${id}`);
		}

		this.type = type;
		return this;
	}

	isCustom() {
		return this.type === ReportPeriod.TYPE.CUSTOM;
	}

	/**
	 * Used in template html.
	 * @returns {string}
	 */
	getTranslation() {
		return ReportPeriod.TYPE_TRANSLATION[this.type.toUpperCase()];
	}

	toObject() {
		if (this.type !== ReportPeriod.TYPE.CUSTOM) {
			return {
				type: this.type,
			};
		}
		if (this.separatedByHour) {
			const startTime = startOfDay(this.start);
			startTime.setHours(this.startHour);
			this.start = startTime;

			const endTime = endOfDay(this.end);
			endTime.setHours(this.endHour);
			this.end = endTime;
		} else {
			this.start = startOfDay(this.start);
			this.end = endOfDay(this.end);
		}

		return {
			type: this.type,
			start: format(this.start, ReportPeriod.API_REPORT_DATE_FORMAT),
			end: format(this.end, ReportPeriod.API_REPORT_DATE_FORMAT),
		};
	}
}

export default ReportPeriod;
