import isNumber from 'lodash/isNumber.js';
import pick from 'lodash/pick.js';

import API_TYPE from './../../../../models/AdslotApiType.js';
import MIME from './../../../../models/AdslotMimeType.js';

function AdslotAdvancedController(
	$scope,
	AdslotFallbackDefault,
	AdslotFallbackXml,
	Account,
	Adslots,
	AdslotTranslations,
	AuthService,
	InfoService,
	toaster,
	changed,
) {
	'ngInject';

	const vm = this;

	vm.BITRATE_MAX_VALUE = 2147483647; // the maximum of the signed int used on the backend side

	vm.apiTypes = AdslotTranslations.getApiTypes();
	vm.mimeTypes = AdslotTranslations.getMimeTypes();
	vm.skippable = AdslotTranslations.getSkippability();
	vm.protocols = AdslotTranslations.getProtocolTypes();
	vm.positions = AdslotTranslations.getPositions();
	vm.auctionTypes = AdslotTranslations.getAuctionTypes();
	vm.placementTypes = AdslotTranslations.getPlacementTypes();

	vm.adslot = angular.copy($scope.localModels.adslot);

	vm.auctionTypeDisabled = !Account.isAllowedToUpdateAuctionType();

	if (vm.adslot.isBanner()) {
		if (vm.adslot.isMobileApp() || vm.adslot.isConnectedTv()) {
			vm.api = pick(vm.apiTypes, [API_TYPE.MRAID_1, API_TYPE.MRAID_2, API_TYPE.MRAID_3]);
		}
	} else if (vm.adslot.isAudio()) {
		vm.mimes = pick(vm.mimeTypes, [MIME.AUDIO.MPEG, MIME.AUDIO.OGG, MIME.AUDIO.ACC, MIME.AUDIO.WAV]);
	} else if (vm.adslot.isVideo()) {
		vm.mimes = pick(vm.mimeTypes, [MIME.VIDEO.X_FLV, MIME.VIDEO.MP4, MIME.VIDEO.X_SHOCKWAVE_FLASH, MIME.VIDEO.APPLICATION_JAVASCRIPT]);

		if (vm.adslot.isMobileApp() || vm.adslot.isConnectedTv()) {
			// vpaid and mraid
			vm.api = vm.apiTypes;
		} else {
			// everything except for mraid
			vm.api = pick(vm.apiTypes, [API_TYPE.VPAID_1_0, API_TYPE.VPAID_2_0, API_TYPE.OMID_1_0, API_TYPE.SIMID_1_0, API_TYPE.SIMID_1_1]);
		}
	}

	vm.showAudioVideo = vm.adslot.isAudio() || vm.adslot.isVideo();

	vm.showApi = vm.adslot.isVideo() || (vm.adslot.isMobileApp() && vm.adslot.isBanner()) || (vm.adslot.isConnectedTv() && vm.adslot.isBanner());

	vm.showPosition = !(vm.adslot.isConnectedTv() && vm.adslot.isVideo()) && !vm.adslot.isAddressableTv() && !vm.adslot.isDooh();

	vm.showSkippability = vm.adslot.isVideo() && !vm.adslot.isDooh() && !vm.adslot.isAddressableTv();

	const hasXmlFallback = vm.showAudioVideo;
	vm.fallback = hasXmlFallback ? new AdslotFallbackXml(vm.adslot) : new AdslotFallbackDefault(vm.adslot);

	const hasDurationSettings = vm.showAudioVideo;
	const hasValidDurationSettings = () => !hasDurationSettings || vm.adslot.maxDuration >= vm.adslot.minDuration;

	vm.isNumber = isNumber;
	const hasBitrateSettings = vm.showAudioVideo;
	const hasValidBitrateSettings = () =>
		!hasBitrateSettings ||
		(!isNumber(vm.adslot.minBitrate) && vm.adslot.maxBitrate) ||
		(!isNumber(vm.adslot.maxBitrate) && vm.adslot.minBitrate) ||
		vm.adslot.maxBitrate >= vm.adslot.minBitrate;

	vm.isFormValid = () => hasValidDurationSettings() && hasValidBitrateSettings();

	vm.save = function save() {
		const adslotData = changed($scope.localModels.adslot, Object.assign(vm.adslot, vm.fallback.toObject()));

		if (adslotData.fallback) {
			adslotData.overrideFallback = true;
		}

		if (!angular.equals({}, adslotData)) {
			InfoService.startRequest();
			Adslots.update(vm.adslot.id, adslotData)
				.then(function success(updatedAdslot) {
					// update scope with updated adslot (to display correct name and stuff)
					$scope.methods.setScopeAdslot(updatedAdslot);
					$scope.methods.rebuildAdslots();
					vm.adslot = angular.copy(updatedAdslot);
					vm.form.$setPristine();

					toaster.successMessage('MESSAGE.ADSLOT_SAVE_SUCCESS');
				})
				.catch(function updateFailed(errorObject) {
					if (errorObject && errorObject.code === 60004) {
						toaster.errorMessage('MESSAGE.ADSLOT_SAVE_ERROR_FALLBACK_URL_ILLEGAL_CHARACTER');
					} else {
						toaster.errorMessage('MESSAGE.ADSLOT_SAVE_ERROR');
					}
				})
				.finally(InfoService.endRequest.bind(InfoService));
		}
	};
}

export default AdslotAdvancedController;
