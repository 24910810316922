function loadPartnershipAdslotPrices(PartnershipAdslots, partnershipId) {
	return PartnershipAdslots.load(partnershipId).then((partnershipAdslotsPricesList) =>
		partnershipAdslotsPricesList.reduce((prices, { id, floorPrice, fixedPrice }) => {
			prices[id] = { floorPrice, fixedPrice };
			return prices;
		}, {}),
	);
}

function loadAdslotsWithPrices(prices, Adslots) {
	return Object.entries(prices)
		.filter(([adslotId]) => Adslots.getById(adslotId).visible)
		.map(([adslotId]) => {
			const { id, groupId, name, platformType, adType, auctionType, channelId, formats, isMultisize } = Adslots.getById(adslotId);

			return {
				id,
				groupId,
				name,
				platformType,
				adType,
				auctionType,
				channelId,
				formats,
				isMultisize,
			};
		});
}

export { loadPartnershipAdslotPrices, loadAdslotsWithPrices };
