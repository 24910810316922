import NativeAssetFactory from './NativeAssetFactory.js';

const ASSET_ID_OFFSET = 3;

const FIXED_TITLE_ASSET_ID = 1;
const FIXED_MAIN_IMAGE_ASSET_ID = 2;
const FIXED_DATA_DESCRIPTION_ASSET_ID = 3;

class NativeTemplate {
	/**
	 * @type {int|undefined}
	 */
	id = undefined;

	/**
	 * @type {boolean}
	 */
	ratioCheckEnabled = false;

	/**
	 * @type {string}
	 */
	name = '';

	/**
	 * @type {NativeAsset[]}
	 */
	assets = [];

	/**
	 * @param {Object} [data]
	 */
	constructor({ id = undefined, name = '', ratioCheckEnabled = false, assets = [] } = {}) {
		this.id = id;
		this.ratioCheckEnabled = ratioCheckEnabled;
		this.name = name;
		this.assets = assets.map(NativeAssetFactory.createFromData);
	}

	/**
	 * @param {NativeAsset} asset
	 * @returns {NativeTemplate}
	 */
	addAsset(asset) {
		this.assets.push(asset.setId(this.#getValidatedAssetId(asset, this.assets.length + 1)));
		return this;
	}

	/**
	 * @param {number} setAt (array index -> 0 based)
	 * @param {NativeAsset} asset
	 * @returns {NativeTemplate}
	 */
	setAsset(setAt, asset) {
		if (setAt < this.assets.length) {
			this.assets[setAt] = asset.setId(this.#getValidatedAssetId(asset, setAt + 1, this.assets[setAt].id));
			this.#updateFixedAssetIds();
		}
		return this;
	}

	/**
	 * @param {number} removeAt index of the asset to remove
	 * @returns {NativeTemplate}
	 */
	removeAsset(removeAt) {
		if (removeAt < this.assets.length) {
			this.assets.splice(removeAt, 1);
			this.#updateFixedAssetIds();
		}

		return this;
	}

	/**
	 * @returns {Object}
	 */
	toObject() {
		return {
			name: this.name,
			ratioCheckEnabled: this.ratioCheckEnabled,
			assets: this.assets.map((asset) => asset.toObject()),
		};
	}

	/**
	 * @returns {NativeTemplate}
	 */
	clone() {
		return new NativeTemplate({
			id: this.id,
			...this.toObject(),
		});
	}

	/**
	 *
	 * @param {NativeAsset} desiredAsset
	 * @param {number} desiredId
	 * @param {number} toBeReplacedId
	 *
	 * @returns {number} validatedAssetId
	 */
	#getValidatedAssetId(desiredAsset, desiredId, toBeReplacedId = 0) {
		let hasDescriptionAsset = false;
		let hasMainImageAsset = false;
		let hasTitleAsset = false;

		const takenIds = new Set();

		// prepare
		this.assets.forEach((asset) => {
			hasDescriptionAsset = hasDescriptionAsset || asset.isData();
			hasMainImageAsset = hasMainImageAsset || (asset.isImage() && asset.isMainImage());
			hasTitleAsset = hasTitleAsset || asset.isTitle();

			takenIds.add(asset.id);
		});

		// When setting rather than adding an asset, the previous ID can be freed and re-used.
		if (toBeReplacedId) {
			takenIds.delete(toBeReplacedId);
		}

		// and decide
		let validatedId;

		switch (true) {
			case desiredAsset.isData() && !hasDescriptionAsset:
				validatedId = FIXED_DATA_DESCRIPTION_ASSET_ID;
				break;

			case desiredAsset.isImage() && desiredAsset.isMainImage() && !hasMainImageAsset:
				validatedId = FIXED_MAIN_IMAGE_ASSET_ID;
				break;

			case desiredAsset.isTitle() && !hasTitleAsset:
				validatedId = FIXED_TITLE_ASSET_ID;
				break;

			case desiredId > ASSET_ID_OFFSET && !takenIds.has(desiredId):
				validatedId = desiredId;
				break;

			default: {
				let nextAvailableId = ASSET_ID_OFFSET + 1;

				while (takenIds.has(nextAvailableId)) {
					nextAvailableId += 1;
				}

				validatedId = nextAvailableId;
			}
		}

		return validatedId;
	}

	#updateFixedAssetIds() {
		let firstDataAsset;
		let firstMainImageAsset;
		let firstTitleAsset;

		let dataAssetIdFound = false;
		let mainImageAssetIdFound = false;
		let titleAssetIdFound = false;

		this.assets.forEach((asset) => {
			switch (true) {
				case asset.isData() && !dataAssetIdFound:
					if (!firstDataAsset) {
						firstDataAsset = asset;
					}
					dataAssetIdFound = asset.id === FIXED_DATA_DESCRIPTION_ASSET_ID;
					break;

				case asset.isImage() && asset.isMainImage() && !mainImageAssetIdFound:
					if (!firstMainImageAsset) {
						firstMainImageAsset = asset;
					}
					mainImageAssetIdFound = asset.id === FIXED_MAIN_IMAGE_ASSET_ID;
					break;

				case asset.isTitle() && !titleAssetIdFound:
					if (!firstTitleAsset) {
						firstTitleAsset = asset;
					}
					titleAssetIdFound = asset.id === FIXED_TITLE_ASSET_ID;
					break;

				default:
				// not needed
			}
		});

		if (firstDataAsset && !dataAssetIdFound) {
			firstDataAsset.id = FIXED_DATA_DESCRIPTION_ASSET_ID;
		}
		if (firstMainImageAsset && !mainImageAssetIdFound) {
			firstMainImageAsset.id = FIXED_MAIN_IMAGE_ASSET_ID;
		}
		if (firstTitleAsset && !titleAssetIdFound) {
			firstTitleAsset.id = FIXED_TITLE_ASSET_ID;
		}
	}
}

export default NativeTemplate;
