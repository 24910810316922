// @todo: TEMPORY controller. to be removed after inventory section is not restricted with beta access anymore.
function PartnershipsNavigationController($state, DemandPartners, ModalFactory) {
	'ngInject';

	var vm = this;

	vm.newPartnership = function newPartnership() {
		// try to refresh demand partners list first, then show modal anyway
		DemandPartners.loadAll().finally(function demandPartnersLoaded() {
			ModalFactory.newPartnership({}, function partnershipCreated(partnership) {
				if (angular.isDefined(partnership)) {
					$state.go('partnerships.list.view.details', {
						demandId: partnership.demandId,
						id: partnership.id,
					});
				}
			});
		});
	};
}

export default PartnershipsNavigationController;
