function AdslotFallbackDefaultModel(AdslotFallback) {
	'ngInject';

	/**
	 * @param {Adslot} [adslot]
	 */
	function AdslotFallbackDefault(adslot) {
		AdslotFallback.call(this, adslot);

		this.content = (adslot || {}).fallback.content || '';

		this.urls = [
			{
				key: 'html',
				value: (adslot || {}).fallback.html || '',
			},
			{
				key: 'javascript',
				value: (adslot || {}).fallback.javascript || '',
			},
		];

		this.TEXT.content = 'PLACEHOLDER.HTML_CONTENT';
		this.TEXT.html = 'LABEL.HTML_REDIRECT_URL';
		this.TEXT.javascript = 'LABEL.JAVASCRIPT_REDIRECT_URL';
	}

	AdslotFallbackDefault.prototype = Object.create(AdslotFallback.prototype);
	AdslotFallbackDefault.prototype.constructor = AdslotFallbackDefault;

	AdslotFallbackDefault.prototype.getContent = function getContent() {
		return { content: this.content };
	};

	return AdslotFallbackDefault;
}

export default AdslotFallbackDefaultModel;
