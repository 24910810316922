const INTEGER_REGEXP = /^-?\d+$/;

function IntegerOnly() {
	'ngInject';

	return {
		restrict: 'A',
		require: 'ngModel',
		link(scope, iElement, iAttrs, ctrl) {
			// consider empty to be valid as well
			ctrl.$validators.integerOnly = (modelValue, viewValue) => ctrl.$isEmpty(viewValue) || INTEGER_REGEXP.test(viewValue);
		},
	};
}

export default IntegerOnly;
