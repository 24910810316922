// globals
import 'jquery';

// external js modules
import 'jquery-flot/jquery.flot.js';
import './vendor/jquery.flot.resize.fix.js';
import 'jquery-flot/jquery.flot.time.js';
import 'jquery.flot.tooltip/js/jquery.flot.tooltip.js';

import './vendor/miniTip.js';

// app
import angular from 'angular';
import * as Sentry from '@sentry/browser';
import { Angular as AngularIntegration } from '@sentry/integrations';
import { bootstrap } from 'angular-deferred-bootstrap';

import AppModule from './app.module.js';
import StateModule from './state/state.module.js';

import './styles/main.scss';
import './styles/main.styl';

if (process.env.NODE_ENV === 'production') {
	Sentry.init({
		dsn: 'https://7719d972aaf04ea09ef17012c0fc83d9@sentry2.rz.adition.net/15',
		integrations: [new AngularIntegration()],
	});
}

// intl polyfill (needed for luxon)
if (!window.Intl) {
	// eslint-disable-line angular/window-service
	// line below allows for code splitting
	require.ensure(['intl', 'intl/locale-data/jsonp/de.js', 'intl/locale-data/jsonp/en.js'], (require) => {
		// requires like this will just initialize them, and the modules set the globals
		require('intl');
		require('intl/locale-data/jsonp/de.js');
		require('intl/locale-data/jsonp/en.js');
	});
}

// mount on window for testing
window.app = angular.module(AppModule); // eslint-disable-line angular/window-service

// const hardcodedMatomoConfig = {
// 	source: 'https://analytics.y6b.de/js/container_PWePeiOP_dev_9d68fb1b80237ff99da6fb8c.js'
// };

bootstrap({
	element: document,
	module: AppModule,
	bootstrapConfig: {
		strictDi: true,
	},
	injectorModules: [StateModule],
	resolve: {
		isAlreadyAuthenticated: /* @ngInject */ (AuthService) => AuthService.verifyAuthenticationStatus(),
		MATOMO_CONFIG: /* @ngInject */ ($http) =>
			$http
				.get('/matomo-config')
				// Silently catching errors here to let the application load. Validation is done later on.
				.catch(() => ({})),
	},
});
