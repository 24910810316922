function changed(original, current) {
	var key,
		changedProperties = {};

	for (key in original) {
		if (
			Object.prototype.hasOwnProperty.call(original, key) &&
			key[0] !== '$' &&
			Object.prototype.hasOwnProperty.call(current, key) &&
			!angular.equals(original[key], current[key])
		) {
			changedProperties[key] = current[key];
		}
	}

	return changedProperties;
}

export default changed;
