import AdvertiserCategoryFilter from '../../../models/AdvertiserCategoryFilter.js';

function SettingsSupplyCategoryFilterController($scope, resolvedSupplyCategoryFilter, AdvertiserCategories, SupplyCategoryFilter, toaster, InfoService) {
	'ngInject';

	const vm = this;

	let initialFilter = resolvedSupplyCategoryFilter;

	vm.filter = new AdvertiserCategoryFilter(initialFilter.toObject());

	vm.assignedData = Array.from(initialFilter.items)
		.map((advertiserCategoryId) => AdvertiserCategories.getById(advertiserCategoryId))
		.filter((entry) => entry !== undefined); // eslint-disable-line angular/definedundefined
	vm.availableData = Object.values(AdvertiserCategories.getMap());
	vm.saveAdvertiserCategoryFilter = () => {
		const currentFilter = AdvertiserCategoryFilter.fromIds(vm.assignedData.map(({ id }) => id));
		const currentFilterDataObject = currentFilter.toObject();

		if (!initialFilter.equals(currentFilter)) {
			InfoService.startRequest();

			SupplyCategoryFilter.save(currentFilter)
				.then(() => {
					initialFilter = new AdvertiserCategoryFilter(currentFilterDataObject);

					toaster.successMessage('MESSAGE.SETTINGS_SAVE_SUCCESS');
				})
				.catch(() => toaster.errorMessage('MESSAGE.SETTINGS_SAVE_ERROR'))
				.finally(() => InfoService.endRequest());
		}
	};
}

export default SettingsSupplyCategoryFilterController;
