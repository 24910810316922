import angular from 'angular';

import listFilter from './list-filter.component.js';

import ListFilterController from './list-filter.controller.js';

import ListFilterComponents from './list-filter-components.service.js';
import ListFilterFactory from './list-filter-factory.service.js';
import ListFilterSettings from './list-filter-settings.service.js';

const ListFilterModule = angular // eslint-disable-line angular/no-service-method
	.module('ListFilterModule', [])

	.component('listFilter', listFilter)

	.controller('ListFilterController', ListFilterController)

	.service('ListFilterComponents', ListFilterComponents)
	.service('ListFilterFactory', ListFilterFactory)
	.service('ListFilterSettings', ListFilterSettings).name;

export default ListFilterModule;
