function PartnershipsAdvertisers(Api, $q) {
	'ngInject';

	/**
	 * @returns {promise}
	 */
	this.get = function getFn() {
		return Api.get('/api/supply/v1/partnerships/filters/advertisers/available').then(
			function success(result) {
				return result.advertisers;
			},
			function error() {
				return $q.reject([]);
			},
		);
	};

	/**
	 * @param {int} partnershipId
	 * @param {Array|int} advertisers
	 * @returns {promise}
	 */
	this.allowAdvertisers = (partnershipId, advertisers) =>
		Api.post(`/api/supply/v1/partnerships/${partnershipId}/filters/allow`, {
			advertisers: !angular.isArray(advertisers) ? [advertisers] : advertisers,
		});

	/**
	 * @param {int} partnershipId
	 * @param {Array|int} advertisers
	 * @returns {promise}
	 */
	this.blockAdvertisers = (partnershipId, advertisers) =>
		Api.post(`/api/supply/v1/partnerships/${partnershipId}/filters/block`, {
			advertisers: !angular.isArray(advertisers) ? [advertisers] : advertisers,
		});
}

export default PartnershipsAdvertisers;
