function StructureBreadcrumbController(Account, StructureBreadcrumb) {
	'ngInject';

	var vm = this;

	vm.account = Account.getName();
	vm.bc = StructureBreadcrumb;
}

export default StructureBreadcrumbController;
