import SelectionService from '../../../modules/utils/SelectionService.js';

function PartnershipAdslotsAddController(
	$rootScope,
	$scope,
	$filter,
	Adslots,
	InfoService,
	ModalCloser,
	PartnershipAdslots,
	Partnerships,
	toaster,
	close,
	partnershipId,
	adslots,
) {
	'ngInject';

	const vm = this;

	const KNOWN_DUPLICATE_ERROR_CODES = [Partnerships.ERROR.DUPLICATE, Partnerships.ERROR.ACTIVE_SIZES_OVERLAP_EXIST];

	function triggerClose(result, delay) {
		ModalCloser.close(close, result, delay);
	}

	vm.ADD_ADSLOTS_LIST_ID = 'add-adslots-to-partnerships';

	vm.listFilter = {};

	vm.partnership = Partnerships.getById(partnershipId).name;
	vm.adslots = adslots;

	vm.selection = new SelectionService();
	vm.errorIds = {};

	const uniqueArray = (array) => Array.from(new Set(array));

	const getRejectedAdslots = (error) => uniqueArray(Object.values(error.attributes).flat().map(Number));

	const handleDuplicatePrivateAuctionsErrors = (error) => {
		const selectedAdslotIds = vm.selection.values();
		const rejectedAdslotIds = getRejectedAdslots(error);
		const allowedAdslotIds = selectedAdslotIds.filter((adslotId) => !rejectedAdslotIds.includes(adslotId));

		if (allowedAdslotIds.length === 0) {
			vm.errorIds = rejectedAdslotIds.reduce((idMap, id) => {
				idMap[id] = true;
				return idMap;
			}, {});

			if (Partnerships.ERROR.ACTIVE_SIZES_OVERLAP_EXIST === error.code) {
				toaster.errorMessage('MESSAGE.PARTNERSHIP_ADSLOTS_ERROR_ACTIVE_SIZES_OVERLAP_EXIST');
			} else {
				toaster.errorMessage('MESSAGE.PARTNERSHIP_ADSLOT_DUPLICATES');
			}
			InfoService.endRequest();
		} else {
			PartnershipAdslots.add(partnershipId, allowedAdslotIds)
				// handle invisible Adslots first
				.then((partnershipAdslots) => partnershipAdslots.filter(({ id: adslotId }) => Adslots.getById(adslotId).visible))
				// then proceed easily
				.then((partnershipAdslots) => {
					triggerClose(partnershipAdslots, 0);

					if (Partnerships.ERROR.ACTIVE_SIZES_OVERLAP_EXIST === error.code) {
						const numberOfInvalidAdslots = rejectedAdslotIds.length;
						if (numberOfInvalidAdslots === 1) {
							toaster.warningMessage('MESSAGE.PARTNERSHIP_ADSLOTS_PARTIAL_SUCCESS_ONE_ADSLOT_SIZES_OVERLAP_EXIST');
						} else {
							toaster.warningMessage(
								$filter('translate')('MESSAGE.PARTNERSHIP_ADSLOTS_PARTIAL_SUCCESS_SOME_ADSLOTS_SIZES_OVERLAP_EXIST', { value: numberOfInvalidAdslots }),
							);
						}
					} else {
						toaster.warningMessage('MESSAGE.PARTNERSHIP_ADSLOT_DUPLICATES_WITH_SUCCESS');
					}
				})
				.catch(() => toaster.errorMessage('MESSAGE.PARTNERSHIP_SAVE_ERROR'))
				.finally(() => InfoService.endRequest());
		}
	};

	vm.submit = () => {
		const adslotIds = vm.selection.values();

		if (adslotIds.length) {
			InfoService.startRequest();

			PartnershipAdslots.add(partnershipId, adslotIds)
				// handle invisible Adslots first
				.then((partnershipAdslots) => partnershipAdslots.filter(({ id: adslotId }) => Adslots.getById(adslotId).visible))
				// then proceed easily
				.then((partnershipAdslots) => {
					toaster.successMessage('MESSAGE.PARTNERSHIP_SAVE_SUCCESS');
					InfoService.endRequest();

					triggerClose(partnershipAdslots, 0);
				})
				.catch((error) => {
					if (KNOWN_DUPLICATE_ERROR_CODES.includes(error.code)) {
						handleDuplicatePrivateAuctionsErrors(error);
					} else {
						toaster.errorMessage('MESSAGE.PARTNERSHIP_SAVE_ERROR');
						InfoService.endRequest();
					}
				});
		}
	};

	vm.cancel = () => {
		triggerClose([], 200);
	};

	const unregisterSessionTimeoutListener = $rootScope.$on('event:auth-loginRequired', vm.cancel);

	$scope.$on('$destroy', unregisterSessionTimeoutListener);
}

export default PartnershipAdslotsAddController;
