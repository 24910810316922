function PartnershipsListController(
	$scope,
	$state,
	Account,
	AuthService,
	DemandPartners,
	Partnerships,
	InfoService,
	ModalFactory,
	natvalue,
	resolvedDcAccountSettings,
) {
	'ngInject';

	$scope.natvalue = natvalue;

	let isDC = Account.isDirectConnect();
	let enableDT = resolvedDcAccountSettings.enableDealTargeting;
	$scope.showUploadButton = !isDC || Account.isAllowedToCreateAdslots() || (AuthService.isAdmin() && enableDT);
	$scope.showIncomingDownloadButton = isDC && enableDT;

	$scope.uploadPartnerships = () => ModalFactory.uploadPartnershipCSV({});

	$scope.hasOptions = function hasOptions(partnershipId) {
		return !DemandPartners.getById(Partnerships.getById(partnershipId).getDemandId()).isInternal();
	};

	$scope.newPartnership = function newPartnership() {
		// try to refresh demand partners list first, then show modal anyway
		DemandPartners.loadAll().finally(function demandPartnersLoaded() {
			ModalFactory.newPartnership({}, function partnershipCreated(partnership) {
				if (angular.isDefined(partnership)) {
					$state.go('partnerships.list.view.details', {
						demandId: partnership.demandId,
						id: partnership.id,
					});
				}
			});
		});
	};

	/**
	 * @param {int} partnershipId
	 */
	$scope.duplicate = function duplicate(partnershipId) {
		ModalFactory.duplicatePartnership(
			{
				partnership: Partnerships.getById(partnershipId),
			},
			function modalClosed(id) {
				if (id) {
					$scope.methods.rebuildPartnerships();
					$state.go('partnerships.list.view.details', { id: id });
				}
			},
		);
	};

	$scope.downloadPartnerships = function downloadPartnerships() {
		InfoService.startRequest();
		Partnerships.downloadPartnerships().finally(() => InfoService.endRequest());
	};

	$scope.downloadIncoming = function downloadIncoming() {
		InfoService.startRequest();
		Partnerships.downloadIncoming().finally(() => InfoService.endRequest());
	};

	$scope.getPriorityDisplay = function getPriorityDisplay(entry) {
		if (entry.isPreferredDeal()) {
			return 'PD';
		} else if (entry.isProgrammaticGuaranteed()) {
			return 'PG';
		}
		return entry.priority;
	};
}

export default PartnershipsListController;
