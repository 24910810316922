function GenericCsvService() {
	/**
	 * Parse a multiline string containing CSV data into an array of lines.
	 * Each line consists of an array of strings.
	 * Comments are not allowed.
	 * Blank lines are NOT removed, so information about line numbers is not lost.
	 * If an array of expected header names is provided, the first line of the CSV has to match the header names exactly.
	 */
	function parseCSVString(csvString, expectedHeader = null) {
		const trimmedLines = splitLines(csvString).map((line) => line.trim());
		const tokenizedLines = trimmedLines.map(csvTokenize);

		if (expectedHeader && (tokenizedLines.length === 0 || !arraysMatch(tokenizedLines[0], expectedHeader))) {
			return Promise.reject(Error('Missing header or wrong header fields'));
		}
		return Promise.resolve(tokenizedLines.slice(1)); // remove header line
	}

	function splitLines(lines) {
		return lines.split(/\r?\n/);
	}

	function arraysMatch(array1, array2) {
		return array1.length === array2.length && array1.every((value, index) => value === array2[index]);
	}

	/**
	 * Parse a CSV line into a list of tokens. Tokens are delimited by semicolon (';').
	 * Strings can be quoted using '"'.
	 * Quote char inside a quoted string has to be escaped using duplication ("").
	 * Tokens can be null (e.g. foo;;;;bar)
	 * Tokens can be empty (e.g. foo;;"";;bar)
	 */
	function csvTokenize(line) {
		const DELIMITER = ';';
		const ESCAPE_CHAR = '"';

		let insideQuotedString = false;
		let currentTokenValue = null;
		let lastCharWasEscapeChar = false;

		let tokens = [];
		[...line].forEach((char) => {
			if (char === ESCAPE_CHAR) {
				insideQuotedString = !insideQuotedString;

				// at least one quote -> cannot be null anymore
				currentTokenValue = ensureString(currentTokenValue);

				// '"foo""bar"' => 'foo"bar', '""' -> ''
				if (insideQuotedString && lastCharWasEscapeChar) {
					currentTokenValue += ESCAPE_CHAR;
					lastCharWasEscapeChar = false;
				} else {
					lastCharWasEscapeChar = true;
				}
				return;
			}
			lastCharWasEscapeChar = false;

			if (insideQuotedString) {
				currentTokenValue += char;
				return;
			}
			if (char === DELIMITER) {
				tokens.push(currentTokenValue);
				currentTokenValue = null;
				return;
			}
			currentTokenValue = ensureString(currentTokenValue) + char;
		});
		tokens.push(currentTokenValue);

		return tokens.map((token) => {
			return token != null ? token.trim() : null;
		});
	}

	function ensureString(str) {
		return str === null ? '' : str;
	}

	return {
		parseCSVString: parseCSVString,
	};
}

export default GenericCsvService;
