import angular from 'angular';

import PartnershipAdslotsController from './partnerships-view-adslots.controller.js';
import PartnershipAdslotsAddController from './partnerships-view-adslots-add.controller.js';
import PartnershipBidOptionsController from './partnerships-view-bid-options.controller.js';
import PartnershipDetailsController from './partnerships-view-details.controller.js';
import PartnershipFilterController from './partnerships-view-filter.controller.js';
import PartnershipCategoryFilterController from './partnerships-view-category-filter.controller.js';
import PartnershipTargetingController from './partnerships-view-targeting.controller.js';
import PartnershipSizesController from './partnerships-view-sizes.controller.js';
import PartnershipsViewController from './partnerships-view.controller.js';

const PartnershipsViewModule = angular
	.module('partnerships.view', [])
	.controller('PartnershipAdslotsController', PartnershipAdslotsController)
	.controller('PartnershipAdslotsAddController', PartnershipAdslotsAddController)
	.controller('PartnershipBidOptionsController', PartnershipBidOptionsController)
	.controller('PartnershipDetailsController', PartnershipDetailsController)
	.controller('PartnershipFilterController', PartnershipFilterController)
	.controller('PartnershipCategoryFilterController', PartnershipCategoryFilterController)
	.controller('PartnershipTargetingController', PartnershipTargetingController)
	.controller('PartnershipSizesController', PartnershipSizesController)
	.controller('PartnershipsViewController', PartnershipsViewController).name;

export default PartnershipsViewModule;
