import { Set } from 'immutable';

import InventoryType from './InventoryType.js';

class DemandTechnology {
	/**
	 * @type {number}
	 */
	id;

	/**
	 * @type {string}
	 */
	name;

	/**
	 * @type {Set.<InventoryType>}
	 */
	allowedInventoryTypes;

	constructor({ id = -1, name = '', allowedInventoryTypes = [] } = {}) {
		this.id = id;
		this.name = name;
		this.allowedInventoryTypes = Set(allowedInventoryTypes.map(({ adType = '', platformType = '' }) => InventoryType.createFromNames(adType, platformType)));
	}

	/**
	 * @return {number}
	 */
	getId() {
		return this.id;
	}

	/**
	 * @return {string}
	 */
	getName() {
		return this.name;
	}

	/**
	 * @return {Set.<InventoryType>}
	 */
	getAllowedInventoryTypes() {
		return this.allowedInventoryTypes;
	}
}

export default DemandTechnology;
