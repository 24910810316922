function PartnershipReferrerGroups(Api) {
	'ngInject';

	/**
	 * @param {int} partnershipId
	 * @returns {promise}
	 */
	this.get = function getFn(partnershipId) {
		return Api.get(`/api/supply/v1/partnerships/${partnershipId}/referergroups`);
	};

	/**
	 * @param {int} partnershipId
	 * @param {int|Array.<int>} groupIds
	 * @returns {promise}
	 */
	this.add = (partnershipId, groupIds) =>
		Api.post(`/api/supply/v1/partnerships/${partnershipId}/referergroups/add`, !angular.isArray(groupIds) ? [groupIds] : groupIds);

	/**
	 * @param {int} partnershipId
	 * @param {int|Array.<int>} groupIds
	 * @returns {promise}
	 */
	this.remove = (partnershipId, groupIds) =>
		Api.post(`/api/supply/v1/partnerships/${partnershipId}/referergroups/remove`, !angular.isArray(groupIds) ? [groupIds] : groupIds);
}

export default PartnershipReferrerGroups;
