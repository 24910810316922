import angular from 'angular';

import AdslotListComponent from './adslot-list.component.js';
import AdslotListController from './adslot-list.controller.js';

const AdslotListModule = angular
	.module('inventory.adslot-list', [])
	.component('adslotsList', AdslotListComponent)
	.controller('AdslotListController', AdslotListController).name;

export default AdslotListModule;
