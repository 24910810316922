import AD_TYPE from '../../../../models/AdslotAdType.js';

import AdslotDimension, { PREDEFINED_DIMENSIONS } from '../../../../models/AdslotDimension.js';
import AdslotFormat from '../../../../models/AdslotFormat.js';

function AdslotDetailsController(
	$rootScope,
	$scope,
	$timeout,
	AdslotGroups,
	AdslotTranslations,
	Adslots,
	AuthService,
	changed,
	StructureBreadcrumb,
	toaster,
	InfoService,
	Sites,
	StringTools,
) {
	'ngInject';

	const vm = this;

	const platformTypeTextMap = AdslotTranslations.getPlatformTypes();
	const adTypeTextMap = AdslotTranslations.getAdTypes();

	vm.adslot = angular.copy($scope.localModels.adslot);

	const MULTISIZE_ADSLOT_SIZE_DUPLICATED = 50015;
	const MIN_FORMAT_COUNT = 1;
	const MAX_FORMAT_COUNT = 10;

	vm.HAS_MULTISIZE = vm.adslot.isMultisizeEnabled();

	vm.groups = Sites.getListAll().reduce(function groupBySite(result, site) {
		return result.concat(
			AdslotGroups.getListBySiteId(site.id).map(function joinSiteAndGroupName(group) {
				return {
					id: group.id,
					name: site.name + ' - ' + group.name,
				};
			}),
		);
	}, []);
	vm.mobileOsOptions = AdslotTranslations.getMobileOsTypes();
	vm.videoPositions = AdslotTranslations.getStartDelayTypes();

	vm.playbackMethods = [
		{ id: 1, name: 'CAPTION.PLAYBACK_METHOD.1' },
		{ id: 2, name: 'CAPTION.PLAYBACK_METHOD.2' },
		{ id: 3, name: 'CAPTION.PLAYBACK_METHOD.3' },
		{ id: 4, name: 'CAPTION.PLAYBACK_METHOD.4' },
		{ id: 5, name: 'CAPTION.PLAYBACK_METHOD.5' },
		{ id: 6, name: 'CAPTION.PLAYBACK_METHOD.6' },
		{ id: 7, name: 'CAPTION.PLAYBACK_METHOD.7' },
	];

	vm.videoPlacementTypes = AdslotTranslations.getVideoPlacementTypes();

	vm.showAppSettings = () => vm.adslot.isMobileApp() || vm.adslot.isConnectedTv();
	vm.showReferrerSettings = () => !vm.adslot.isMobileApp() && !vm.adslot.isConnectedTv();
	vm.showVideoSettings = () => vm.adslot.isVideo() && !vm.adslot.isDooh() && !vm.adslot.isAddressableTv();

	vm.mapPlatformTypeText = (typeId) => platformTypeTextMap[typeId];

	vm.mapAdTypeText = (typeId) => adTypeTextMap[typeId];

	vm.sanitizeUrl = function sanitizeUrl() {
		vm.adslot.url = StringTools.stripProtocol(vm.adslot.url);
	};

	vm.sortableFormatsConfig = {
		animation: 350,
		handle: '.format-draggable-handle',
	};

	vm.getDimensionOptions = () => {
		const { adType, platformType } = vm.adslot;

		let formatOptions = [];

		// Predefined formats only for banner ad types
		if (adType === AD_TYPE.BANNER && AdslotDimension.hasPredefinedDimensions(platformType)) {
			formatOptions = formatOptions.concat(Array.from(PREDEFINED_DIMENSIONS[platformType]));
		}

		return formatOptions.concat([AdslotDimension.CUSTOM_DIMENSION_STRING]);
	};

	const hasOnlyCustomOption = () => vm.getDimensionOptions().length === 1;

	/**
	 * @param {AdslotDimension} dimension
	 * @returns {boolean}
	 */
	vm.isCustomDimension = (dimension) =>
		hasOnlyCustomOption() || !(vm.adslot.platformType in PREDEFINED_DIMENSIONS) || !PREDEFINED_DIMENSIONS[vm.adslot.platformType].has(dimension.toString());

	vm.onCustomDimensionChange = (format) => {
		if (!vm.isCustomDimension(format.dimension)) {
			// if custom dimension input is shown and a predefined dimension is entered
			// re-set the dimension to the model in order to re-trigger the input formatter
			// and show the predefined entry as selected
			format.dimension = AdslotDimension.fromString(format.dimension.toString(), false);
		}
	};

	vm.canAddFormats = () => vm.HAS_MULTISIZE && vm.adslot.formats.length < MAX_FORMAT_COUNT;

	vm.addFormat = () => {
		if (vm.adslot.formats.length < MAX_FORMAT_COUNT) {
			const dimension = AdslotDimension.fromString(vm.getDimensionOptions()[0], false);
			vm.adslot.formats = vm.adslot.formats.concat(AdslotFormat.fromDimension(dimension));

			$timeout(() => $rootScope.$emit('autofocusId::trigger', `dimension-selection-${vm.adslot.formats.length - 1}`));
		}
	};

	vm.canRemoveFormats = () => vm.HAS_MULTISIZE && vm.adslot.formats.length > MIN_FORMAT_COUNT;

	vm.removeFormat = (idx) => vm.adslot.formats.splice(idx, 1);

	vm.save = () => {
		const adslotData = changed($scope.localModels.adslot, vm.adslot);

		if (!angular.equals({}, adslotData)) {
			InfoService.startRequest();

			if (adslotData.formats) {
				adslotData.newFormats = adslotData.formats.map((format) => format.toObject());
				delete adslotData.formats;
			}

			Adslots.update(vm.adslot.id, adslotData)
				.then((adslot) => {
					if (adslot.groupId !== $scope.localModels.adslot.groupId) {
						StructureBreadcrumb.viewAdslot(adslot.id);
						AdslotGroups.getById($scope.localModels.adslot.groupId).removeAdslot(adslot.id);
						AdslotGroups.getById(adslot.groupId).addAdslot(adslot.id);
					}

					// update scope with updated adslot (to display correct name and stuff)
					$scope.methods.setScopeAdslot(adslot);
					$scope.methods.rebuildAdslots();

					vm.adslot = angular.copy(adslot);
					vm.form.$setPristine();

					toaster.successMessage('MESSAGE.ADSLOT_SAVE_SUCCESS');
				})
				.catch((error) => {
					if (error && error.code === MULTISIZE_ADSLOT_SIZE_DUPLICATED) {
						toaster.errorMessage('MESSAGE.ADSLOT_SAVE_ERROR_DIMENSIONS_NOT_UNIQUE');
					} else {
						toaster.errorMessage('MESSAGE.ADSLOT_SAVE_ERROR');
					}
				})
				.finally(InfoService.endRequest.bind(InfoService));
		}
	};
}

export default AdslotDetailsController;
