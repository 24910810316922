import angular from 'angular';

import changed from './changed.js';

import natvalue from './natvalue.js';
import Seasons from './Seasons.js';
import StringTools from './StringTools.js';

const UtilsModule = angular // eslint-disable-line angular/no-service-method
	.module('app.modules.utils', [])

	.service('natvalue', natvalue)
	.service('Seasons', Seasons)
	.service('StringTools', StringTools)

	.value('changed', changed).name;

export default UtilsModule;
