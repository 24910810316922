import OpenAuctionConfig from '../../models/OpenAuctionConfig.js';

const RESOURCE_NOT_FOUND = 6; // middleware 404 error response body code

function OpenAuctionSettings($q, Api) {
	'ngInject';

	/**
	 * @return {Promise.<Boolean>}
	 */
	this.isOpenAuctionEnabled = () => Api.get('/api/supply/v1/open-auction/settings').then(({ openAuctionEnabled }) => openAuctionEnabled === true);

	/**
	 * @param {Boolean} isEnabled
	 * @return {Promise}
	 */
	this.setOpenAuctionEnabled = (isEnabled) =>
		Api.put('/api/supply/v1/open-auction/settings', { openAuctionEnabled: isEnabled }).then(({ openAuctionEnabled }) => openAuctionEnabled === true);

	/**
	 * @return {Promise.<OpenAuctionConfig>}
	 */
	this.getGlobalConfig = () =>
		Api.get('/api/supply/v1/open-auction/config')
			.then((config) => new OpenAuctionConfig(config))
			.catch((error) => (error.code === RESOURCE_NOT_FOUND ? new OpenAuctionConfig() : $q.reject(error)));

	/**
	 * @param {OpenAuctionConfig} config
	 * @return {Promise.<OpenAuctionConfig>}
	 */
	this.setGlobalConfig = (config) => Api.put('/api/supply/v1/open-auction/config', config.toObject()).then(() => new OpenAuctionConfig(config.toObject()));

	/**
	 * @return {Promise.<Array.<{adslot: Number, floorPrice: Number}>>}
	 */
	this.getGlobalAdslotConfigs = () => Api.get('/api/supply/v1/open-auction/config/adslots').then(({ items = [] }) => items);

	/**
	 * @param {Array.<{adslot: Number, floorPrice: Number}>} adslots
	 * @return {Promise}
	 */
	this.setGlobalAdslotConfigs = (adslots) =>
		Api.put('/api/supply/v1/open-auction/config/adslots/_bulk', {
			configurations: adslots,
		});

	/**
	 * @param {Array.<Number>} adslots
	 * @return {Promise}
	 */
	this.removeGlobalAdslotConfigs = (adslots) => Api.delete(`/api/supply/v1/open-auction/config/adslots/${encodeURIComponent(adslots.join(','))}`);
}

export default OpenAuctionSettings;
