import TYPE from '../../models/PartnershipType.js';

function PartnershipsDuplicateController(
	$rootScope,
	$scope,
	Account,
	Adslots,
	AdslotTranslations,
	DemandPartners,
	DemandTechnologies,
	InfoService,
	ModalCloser,
	Partnerships,
	PartnershipAdslots,
	toaster,
	close,
	partnership,
) {
	'ngInject';

	const vm = this;

	let unregisterSessionTimeoutListener;

	const programmaticTypes = [
		{
			id: 2,
			text: 'LABEL.PROGRAMMATIC_GUARANTEED',
		},
	];

	let partnershipTypes = [
		{
			id: 0,
			text: 'CAPTION.PRIVATE_AUCTION',
		},
		{
			id: 1,
			text: 'PLACEHOLDER.DIRECT_DEAL',
		},
		{
			id: 3,
			text: 'PLACEHOLDER.PREFERRED_DEAL',
		},
	];

	function triggerClose(result, delay) {
		ModalCloser.close(close, result, delay);
	}

	vm.success = false;
	vm.partnership = partnership;

	vm.demandSelectOptions = DemandPartners.getAsSelectOptionsList();

	const platformTypeTextMap = AdslotTranslations.getPlatformTypes();
	const adTypeTextMap = AdslotTranslations.getAdTypes();

	vm.mapPlatformTypeText = (typeId) => platformTypeTextMap[typeId];

	vm.mapAdTypeText = (typeId) => adTypeTextMap[typeId];

	if (Account.mustOnlyCreateDealPartnerships()) {
		// auto-configured direct connect accounts should only have deals so type private auction can be removed
		partnershipTypes.splice(0, 1);
		// all demand partners without deal support can be removed as well
		vm.demandSelectOptions = vm.demandSelectOptions.filter(({ id }) => DemandPartners.getById(id).hasDeals());
	}

	vm.partnerId = partnership.getDemandId();
	vm.typeId = partnership.getType();
	vm.typeSelectOptions = partnership.isProgrammaticGuaranteed() ? programmaticTypes : partnershipTypes;
	vm.adslots = [];
	vm.invalidAdlotCount = 0;
	vm.adslotCount = 0;

	vm.seatId = DemandPartners.getById(vm.partnerId).seatId || 0;

	PartnershipAdslots.loadDetailed(vm.partnership.getId()).then((adslots) => {
		vm.adslots = adslots.filter(({ id }) => Adslots.getById(id).visible);
	});

	// option defaults
	vm.options = {
		copyAssociatedAdslots: true,
		copyPricingData: true,
		copyAdvertiserFilters: true,
		copyTargetingData: true,
		copySizes: true,
	};

	vm.showCopyWhitelistSizes = () => !Account.mustCopyWhitelistSizesOnDuplicateDeals();

	vm.alwaysCopyAdslots = () => Account.mustCopyAdslotsOnDuplicateDeals();

	vm.alwaysCopyTargeting = () => Account.mustCopyTargetingOnDuplicateDeals() || vm.partnership.isProgrammaticGuaranteed();

	vm.isFormValid = () => !vm.invalidPartnerAndTypeSelection;

	vm.verifyPartnerAndTypeSelection = () => {
		const demandPartner = DemandPartners.getById(vm.partnerId);

		const partnerDoesNotHaveDeals = !demandPartner.hasDeals();
		const partnerMustHaveDeals = demandPartner.isRequireDeals();
		const typeIsDeal = vm.typeId !== TYPE.PRIVATE_AUCTION;

		vm.invalidPrivateAuctionConfigured = partnerDoesNotHaveDeals && typeIsDeal;
		vm.invalidDealConfigured = partnerMustHaveDeals && !typeIsDeal;
		vm.invalidPartnerAndTypeSelection = vm.invalidPrivateAuctionConfigured || vm.invalidDealConfigured;
		vm.seatId = demandPartner.seatId || 0;

		validateInventoryTypeRestriction();
	};

	vm.submit = () => {
		InfoService.startRequest();

		Partnerships.duplicate(partnership.getId(), vm.partnerId, vm.typeId, vm.options)
			.then(function success(duplicatedPartnership) {
				vm.success = true;
				toaster.successMessage('MESSAGE.PARTNERSHIP_DUPLICATED', {
					onHideCallback: function onHideCallback() {
						triggerClose(duplicatedPartnership.getId(), 200);
					},
					timeout: 2500,
				});
			})
			.catch(function error(errorObject) {
				if (errorObject.code === Partnerships.ERROR.DUPLICATE_PARTNERSHIP_NO_SEAT_FOR_DSP) {
					toaster.errorMessage('MESSAGE.DUPLICATE_PARTNERSHIP_NO_SEAT_FOR_DSP');
				} else if (errorObject.code === Partnerships.ERROR.DUPLICATE) {
					toaster.errorMessage('MESSAGE.PARTNERSHIP_DUPLICATE_ERROR_ADSLOTS');
				} else if (errorObject.code === Partnerships.ERROR.AUTO_CONFIGURED_ONLY_DEAL) {
					toaster.errorMessage('MESSAGE.PARTNERSHIP_SAVE_ERROR_AUTO_CONFIGURED_ONLY_DEAL');
				} else if (errorObject.code === Partnerships.ERROR.DUPLICATE_PARTNERSHIP_NAME_TOO_LONG) {
					toaster.errorMessage('MESSAGE.PARTNERSHIP_DUPLICATE_ERROR_NAME_TOO_LONG');
				} else if (errorObject.code === Partnerships.ERROR.ACTIVE_SIZES_OVERLAP_EXIST) {
					toaster.errorMessage('MESSAGE.PARTNERSHIP_DUPLICATE_ERROR_ALLOWLIST_SIZES');
				} else {
					toaster.errorMessage('MESSAGE.PARTNERSHIP_DUPLICATE_ERROR');
				}
			})
			.finally(InfoService.endRequest.bind(InfoService));
	};

	vm.cancel = () => triggerClose(0, 200);

	function validateInventoryTypeRestriction() {
		const demandPartner = DemandPartners.getById(vm.partnerId);
		const demandTechnology = DemandTechnologies.getById(demandPartner.technologyId);
		const allowedInventoryTypes = demandTechnology.getAllowedInventoryTypes();

		vm.adslotCount = vm.adslots.length;
		vm.invalidAdslotCount = vm.adslots.filter((adslot) => !allowedInventoryTypes.has(adslot.getInventoryType())).length;
	}

	unregisterSessionTimeoutListener = $rootScope.$on('event:auth-loginRequired', vm.cancel);

	$scope.$on('$destroy', unregisterSessionTimeoutListener);
}

export default PartnershipsDuplicateController;
