function Impersonate(Api) {
	'ngInject';

	this.ERROR = {
		SAME_AS_ORIGINAL_USER: 10,
	};

	/**
	 * Notify backend to impersonate as another user.
	 * @param {String} username
	 * @returns {Promise}
	 */
	this.as = (username) =>
		Api.post('user/impersonate', {
			username,
		});

	/**
	 * Quit impersonation and return back to original user.
	 * @returns {promise}
	 */
	this.stop = function stop() {
		return Api.get('/api/v1/user/original');
	};
}

export default Impersonate;
