function AdslotsChannelsController($scope, Channels, PGAdslots, UserInfo) {
	'ngInject';

	$scope.models.channels = Channels.getList().map(function map(channel) {
		// add websites count to channel
		channel.noAdslots = channel.websites.reduce(function reduce(sum, adslotId) {
			// skip PG adslots for count
			if (PGAdslots.getById(adslotId)) {
				return sum;
			}
			return sum + 1;
		}, 0);

		return channel;
	});
	$scope.UI = UserInfo;
}

export default AdslotsChannelsController;
