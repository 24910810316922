import AdvertiserCategoryFilter from '../models/AdvertiserCategoryFilter.js';

function SupplyCategoryFilter(Api) {
	'ngInject';

	/**
	 * @returns {Promise.<AdvertiserCategoryFilter>}
	 */
	this.get = () =>
		Api.get('/api/supply/v1/filters/advertisercategories').then(({ advertiserCategories }) => AdvertiserCategoryFilter.fromIds(advertiserCategories));

	/**
	 * @param {AdvertiserCategoryFilter} advertiserCategoryFilter
	 * @returns {Promise.<AdvertiserCategoryFilter>}
	 */
	this.save = (advertiserCategoryFilter) =>
		Api.put('/api/supply/v1/filters/advertisercategories', advertiserCategoryFilter.toObject()).then(
			() => new AdvertiserCategoryFilter(advertiserCategoryFilter.toObject()),
		);
}

export default SupplyCategoryFilter;
