function ModalClose() {
	'ngInject';

	return {
		scope: {
			ylModalClose: '&',
		},
		link: function postLink(scope, iElement) {
			iElement.one('click', function closeModal() {
				scope.ylModalClose();
			});
		},
	};
}

export default ModalClose;
