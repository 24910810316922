function PartnershipTargetingController($document, $filter, $scope, $stateParams, resolvedTargetingConfiguration, PartnershipTargeting, toaster, InfoService) {
	'ngInject';

	var vm = this,
		GROUP_LIMIT = 20,
		ENTRY_LIMIT = 5,
		EMPTY_GROUP_ENTRY = {
			/* mode does not only apply to expressions but also values */
			mode: 1,
			evaluator: 1,
			key: '',
			values: [],
		},
		EMPTY_GROUP = {
			mode: 0,
			expressions: [angular.copy(EMPTY_GROUP_ENTRY)],
		},
		ROOT_GROUP = {
			mode: 1,
			expressions: [],
		};

	vm.MATCHING_OPTIONS = PartnershipTargeting.MATCHING.OPTIONS;
	vm.MATCHING_KEYS = PartnershipTargeting.MATCHING.UI_KEYS;
	vm.GROUP_LIMIT = GROUP_LIMIT;
	vm.ENTRY_LIMIT = ENTRY_LIMIT;

	vm.form = resolvedTargetingConfiguration;
	vm.form.expressionDetails = vm.form.expressionDetails || angular.copy(ROOT_GROUP);
	vm.geoTargeting = {
		DE: false,
		AT: false,
		CH: false,
	};
	vm.geoTargetingValid = true;

	const countryCodes = vm.form.geoTargetingCountryCodes;
	for (let country in vm.geoTargeting) {
		if (countryCodes.includes(country)) {
			vm.geoTargeting[country] = true;
		}
	}

	vm.formInitial = angular.copy(vm.form);

	vm.onGeoTargetingEnabledChange = function onGeoTargetingEnabledChange() {
		if (vm.form.geoTargetingEnabled === false) {
			Object.keys(vm.geoTargeting).forEach((country) => {
				vm.geoTargeting[country] = false;
			});
			vm.form.geoTargetingCountryCodes = [];
			vm.geoTargetingValid = true;
		}
	};

	vm.onGeoTargetingChange = function onGeoTargetingChange(key) {
		vm.geoTargetingValid = true;
		if (vm.geoTargeting[key]) {
			vm.form.geoTargetingCountryCodes.push(key);
		} else {
			const index = vm.form.geoTargetingCountryCodes.indexOf(key);
			if (index !== -1) {
				vm.form.geoTargetingCountryCodes.splice(index, 1);
			}
		}
	};

	vm.hasValidExpressions = function hasValidExpressions() {
		const expressions = vm.form.expressionDetails.expressions;
		if (!angular.isArray(expressions) || expressions.length === 0) {
			return false;
		}
		const nestedExpressions = expressions[0];
		return nestedExpressions.expressions.some(function check(expression) {
			return expression.key && expression.values && expression.values.length > 0;
		});
	};

	vm.addGroup = function addGroup() {
		if (vm.form.expressionDetails.expressions.length < GROUP_LIMIT) {
			vm.form.expressionDetails.expressions.push(angular.copy(EMPTY_GROUP));
		}
	};

	vm.removeGroup = function removeGroup(index) {
		vm.form.expressionDetails.expressions.splice(index, 1);
	};

	vm.duplicateGroup = function duplicateGroup(index) {
		if (vm.form.expressionDetails.expressions.length < GROUP_LIMIT) {
			vm.form.expressionDetails.expressions.splice(index + 1, 0, angular.copy(vm.form.expressionDetails.expressions[index]));
		}
	};

	vm.addEntry = function addEntry(group) {
		if (group.expressions.length < ENTRY_LIMIT) {
			group.expressions.push(angular.copy(EMPTY_GROUP_ENTRY));
		}
	};

	vm.removeEntry = function removeEntry(group, index) {
		if (group.expressions.length > 1) {
			group.expressions.splice(index, 1);
		}
	};

	vm.getMatchingIndex = function getMatchingIndex(groupEntry) {
		return PartnershipTargeting.MATCHING.optionIndex({
			mode: groupEntry.mode,
			evaluator: groupEntry.evaluator,
		});
	};

	vm.applyMatchingOption = function applyMatchingOption(groupEntry, matchingIndex) {
		angular.extend(groupEntry, PartnershipTargeting.MATCHING.OPTIONS[matchingIndex].config);
	};

	vm.matchingOptionKey = function matchingOptionKey(groupEntry) {
		return PartnershipTargeting.MATCHING.UI_KEYS[vm.getMatchingIndex(groupEntry)];
	};

	vm.isNumericMatching = function isNumericMatching(matchingIndex) {
		// it can happen that matchingIndex is undefined because the value is stored (kinda illegally) on the ng form controller
		return (PartnershipTargeting.MATCHING.OPTIONS[matchingIndex] || {}).numeric;
	};

	vm.printValues = function printValues(values) {
		var output = angular.copy(values);
		return output.length === 1 ? output[0] : output.splice(0, output.length - 1).join(', ') + ' ' + $filter('translate')('TEXT.OR') + ' ' + output[0];
	};

	/**
	 * @returns {boolean}
	 */
	vm.formHasChanged = function formHasChanged() {
		return !angular.equals(vm.form, vm.formInitial);
	};

	/**
	 * Reset UI to initial form state.
	 */
	vm.cancel = function cancel() {
		vm.form = angular.copy(vm.formInitial);
		$scope.targetingForm.$setPristine();
	};

	vm.save = function save() {
		// do validation
		if (vm.form.geoTargetingEnabled === true && !Object.values(vm.geoTargeting).some(Boolean)) {
			$document[0].getElementById('geoTargetingActive').focus();
			vm.geoTargetingValid = false;
			return;
		}
		if (vm.formHasChanged()) {
			InfoService.startRequest();

			PartnershipTargeting.update($stateParams.id, vm.form)
				.then(function success() {
					// mark current state as initial for further operations
					vm.formInitial = angular.copy(vm.form);
					$scope.targetingForm.$setPristine();

					toaster.successMessage('MESSAGE.PARTNERSHIP_SAVE_SUCCESS');
				})
				.catch(function error(errorObject) {
					if (errorObject.code === PartnershipTargeting.ERROR.MISSING_DC_DEAL) {
						toaster.errorMessage('MESSAGE.PARTNERSHIP_SAVE_ERROR_TARGETING_MISSING_DC_DEAL');
					} else {
						toaster.errorMessage('MESSAGE.PARTNERSHIP_SAVE_ERROR');
					}
				})
				.finally(InfoService.endRequest.bind(InfoService));
		} else {
			$scope.targetingForm.$setPristine();
		}
	};
}

export default PartnershipTargetingController;
