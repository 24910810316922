const ELLIPSIS = '...';
const FILENAME_MIN_LETTERS = 2;

/**
 * Clip a filename to a given max length.
 * Max length might be exceeded in rare cases (extension is longer than max length, filename will have at least 2
 * letters).
 */
function ClipFilenameFilter() {
	return (input, maxLength) => {
		if (!input) {
			return input;
		}

		let result = input;
		if (input.length > maxLength) {
			let dotPosition = input.lastIndexOf('.');
			if (dotPosition === -1) {
				result = input.substring(0, maxLength - ELLIPSIS.length) + ELLIPSIS;
			} else {
				let extension = input.substring(dotPosition + 1);
				let lenFilename = Math.max(maxLength - extension.length - ELLIPSIS.length, FILENAME_MIN_LETTERS);
				result = input.substring(0, lenFilename) + ELLIPSIS + extension;
			}
		}
		return result;
	};
}

export default ClipFilenameFilter;
