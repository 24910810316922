import SORT from './ReportColumnSortDirection.js';
import GROUP from './ReportColumnUiGroup.js';
import TYPE from './ReportColumnUiType.js';

const ID = {
	HOUR: 'hour',
	ADSLOT: 'adslot',
	ADSLOT_ID: 'adslot_id',
	ADTYPE: 'ad_type',
	PLATFORM_TYPE: 'platform_type',
	DEAL_ID: 'deal_id',
	DEMAND_PARTNER: 'demand_partner',
	DEMAND_PARTNER_ID: 'demand_partner_id', // currently only used as data column for filtering
	PARENT_SUPPLY_PARTNER: 'parent_supply_partner',
	PARENT_DEAL_ID: 'parent_deal_id',
	PARTNERSHIP: 'partnership',
	PARTNERSHIP_ID: 'partnership_id',
	PARTNERSHIP_TYPE: 'partnership_type',
	ADVERTISER: 'advertiser',
	ADVERTISER_ID: 'advertiser_id', // currently only used as data column for filtering
	CHANNELS: 'channels',
	EXTERNAL_ID: 'external_id',
	FORMAT: 'format',
	GROUP: 'group',
	INVOICE: 'invoice',
	SITE: 'site',
	URL: 'url',
	AVERAGE_BIDS_BELOW_FLOOR: 'average_bids_below_floor',
	AVERAGE_BIDS_BY_BLOCKED_ADVERTISER: 'average_bids_by_blocked_advertiser',
	AVERAGE_BID_PRICE: 'average_bid_price',
	YIELDPROBE_REQUESTS: 'yieldprobe_requests',
	BID_REQUESTS: 'bid_requests',
	BIDS_BELOW_FLOOR: 'bids_below_floor',
	BIDS_BY_BLOCKED_ADVERTISERS: 'bids_by_blocked_advertisers',
	DELIVERIES: 'deliveries',
	LOSS_RATE: 'loss_rate',
	LOST_BIDS: 'lost_bids',
	NO_BIDS: 'no_bids',
	TOTAL_BIDS: 'total_bids',
	WON_BIDS: 'won_bids',
	YIELDPROBE_IGNORED_RECOMMENDATIONS: 'yieldprobe_ignored_recommendations',
	ECPM: 'ecpm',
	FALLBACKS: 'fallbacks',
	IMPRESSIONS: 'impressions',
	IMPRESSIONS_RTB: 'impressions_rtb',
	NET_ECPM: 'net_ecpm',
	NET_REVENUE: 'net_revenue',
	PARENT_REVENUE: 'parent_revenue',
	REVENUE: 'revenue',
	VIDEO_CLICK: 'video_click',
	VIDEO_COMPLETE: 'video_complete',
	VIDEO_FIRST_QUARTILE: 'video_first_quartile',
	VIDEO_MIDPOINT: 'video_midpoint',
	VIDEO_STARTS: 'video_starts',
	VIDEO_THIRD_QUARTILE: 'video_third_quartile',
};

const DATA = {
	[ID.ADSLOT]: {
		id: ID.ADSLOT,
		name: `TITLE.REPORTS.COLUMN.${ID.ADSLOT.toUpperCase()}`,
		filter: {},
		visible: true,
		search: '',
		sort: SORT.NONE,
		type: TYPE.TEXT,
		group: GROUP.ADSLOT,
	},
	[ID.ADSLOT_ID]: {
		id: ID.ADSLOT_ID,
		name: `TITLE.REPORTS.COLUMN.${ID.ADSLOT_ID.toUpperCase()}`,
		filter: {},
		visible: false,
		search: '',
		sort: SORT.NONE,
		type: TYPE.NUMBER,
		group: GROUP.ADSLOT,
	},
	[ID.ADTYPE]: {
		id: ID.ADTYPE,
		name: `TITLE.REPORTS.COLUMN.${ID.ADTYPE.toUpperCase()}`,
		filter: {},
		visible: false,
		search: '',
		sort: SORT.NONE,
		type: TYPE.SELECT,
		group: GROUP.ADSLOT,
	},
	[ID.PLATFORM_TYPE]: {
		id: ID.PLATFORM_TYPE,
		name: `TITLE.REPORTS.COLUMN.${ID.PLATFORM_TYPE.toUpperCase()}`,
		filter: {},
		visible: false,
		search: '',
		sort: SORT.NONE,
		type: TYPE.SELECT,
		group: GROUP.ADSLOT,
	},
	[ID.DEAL_ID]: {
		id: ID.DEAL_ID,
		name: `TITLE.REPORTS.COLUMN.${ID.DEAL_ID.toUpperCase()}`,
		filter: {},
		visible: true,
		search: '',
		sort: SORT.NONE,
		type: TYPE.TEXT,
		group: GROUP.PARTNER,
	},
	[ID.DEMAND_PARTNER]: {
		id: ID.DEMAND_PARTNER,
		name: `TITLE.REPORTS.COLUMN.${ID.DEMAND_PARTNER.toUpperCase()}`,
		filter: {},
		visible: true,
		search: '',
		sort: SORT.NONE,
		type: TYPE.TEXT,
		group: GROUP.PARTNER,
	},
	[ID.DEMAND_PARTNER_ID]: {
		id: ID.DEMAND_PARTNER_ID,
		name: `TITLE.REPORTS.COLUMN.${ID.DEMAND_PARTNER_ID.toUpperCase()}`,
		filter: {},
		visible: true,
		search: '',
		sort: SORT.NONE,
		type: TYPE.TEXT,
		group: GROUP.PARTNER,
	},
	[ID.PARENT_SUPPLY_PARTNER]: {
		id: ID.PARENT_SUPPLY_PARTNER,
		name: `TITLE.REPORTS.COLUMN.${ID.PARENT_SUPPLY_PARTNER.toUpperCase()}`,
		filter: {},
		visible: false,
		search: '',
		sort: SORT.NONE,
		type: TYPE.TEXT,
		group: GROUP.YDC,
	},
	[ID.PARENT_DEAL_ID]: {
		id: ID.PARENT_DEAL_ID,
		name: `TITLE.REPORTS.COLUMN.${ID.PARENT_DEAL_ID.toUpperCase()}`,
		filter: {},
		visible: false,
		search: '',
		sort: SORT.NONE,
		type: TYPE.TEXT,
		group: GROUP.YDC,
	},
	[ID.PARTNERSHIP]: {
		id: ID.PARTNERSHIP,
		name: `TITLE.REPORTS.COLUMN.${ID.PARTNERSHIP.toUpperCase()}`,
		filter: {},
		visible: true,
		search: '',
		sort: SORT.NONE,
		type: TYPE.TEXT,
		group: GROUP.PARTNER,
	},
	[ID.PARTNERSHIP_ID]: {
		id: ID.PARTNERSHIP_ID,
		name: `TITLE.REPORTS.COLUMN.${ID.PARTNERSHIP_ID.toUpperCase()}`,
		filter: {},
		visible: false,
		search: '',
		sort: SORT.NONE,
		type: TYPE.TEXT,
		group: GROUP.PARTNER,
	},
	[ID.PARTNERSHIP_TYPE]: {
		id: ID.PARTNERSHIP_TYPE,
		name: `TITLE.REPORTS.COLUMN.${ID.PARTNERSHIP_TYPE.toUpperCase()}`,
		filter: {},
		visible: false,
		search: '',
		sort: SORT.NONE,
		type: TYPE.SELECT,
		group: GROUP.PARTNER,
	},
	[ID.ADVERTISER]: {
		id: ID.ADVERTISER,
		name: `TITLE.REPORTS.COLUMN.${ID.ADVERTISER.toUpperCase()}`,
		filter: {},
		visible: false,
		search: '',
		sort: SORT.NONE,
		type: TYPE.TEXT,
		group: GROUP.PARTNER,
	},
	[ID.ADVERTISER_ID]: {
		id: ID.ADVERTISER_ID,
		name: `TITLE.REPORTS.COLUMN.${ID.ADVERTISER_ID.toUpperCase()}`,
		filter: {},
		visible: false,
		search: '',
		sort: SORT.NONE,
		type: TYPE.TEXT,
		group: GROUP.PARTNER,
	},
	[ID.CHANNELS]: {
		id: ID.CHANNELS,
		name: `TITLE.REPORTS.COLUMN.${ID.CHANNELS.toUpperCase()}`,
		filter: {},
		visible: false,
		search: '',
		sort: SORT.NONE,
		type: TYPE.SELECT,
		group: GROUP.ADSLOT,
	},
	[ID.EXTERNAL_ID]: {
		id: ID.EXTERNAL_ID,
		name: `TITLE.REPORTS.COLUMN.${ID.EXTERNAL_ID.toUpperCase()}`,
		filter: {},
		visible: false,
		search: '',
		sort: SORT.NONE,
		type: TYPE.TEXT,
		group: GROUP.ADSLOT,
	},
	[ID.FORMAT]: {
		id: ID.FORMAT,
		name: `TITLE.REPORTS.COLUMN.${ID.FORMAT.toUpperCase()}`,
		filter: {},
		visible: false,
		search: '',
		sort: SORT.NONE,
		type: TYPE.TEXT,
		group: GROUP.ADSLOT,
	},
	[ID.GROUP]: {
		id: ID.GROUP,
		name: `TITLE.REPORTS.COLUMN.${ID.GROUP.toUpperCase()}`,
		filter: {},
		visible: false,
		search: '',
		sort: SORT.NONE,
		type: TYPE.TEXT,
		group: GROUP.ADSLOT,
	},
	[ID.INVOICE]: {
		id: ID.INVOICE,
		name: `TITLE.REPORTS.COLUMN.${ID.INVOICE.toUpperCase()}`,
		filter: {},
		visible: false,
		search: '',
		sort: SORT.NONE,
		type: TYPE.SELECT,
		group: GROUP.BILLING,
	},
	[ID.SITE]: {
		id: ID.SITE,
		name: `TITLE.REPORTS.COLUMN.${ID.SITE.toUpperCase()}`,
		filter: {},
		visible: false,
		search: '',
		sort: SORT.NONE,
		type: TYPE.TEXT,
		group: GROUP.ADSLOT,
	},
	[ID.URL]: {
		id: ID.URL,
		name: `TITLE.REPORTS.COLUMN.${ID.URL.toUpperCase()}`,
		filter: {},
		visible: false,
		search: '',
		sort: SORT.NONE,
		type: TYPE.TEXT,
		group: GROUP.ADSLOT,
	},
	[ID.AVERAGE_BIDS_BELOW_FLOOR]: {
		id: ID.AVERAGE_BIDS_BELOW_FLOOR,
		name: `TITLE.REPORTS.COLUMN.${ID.AVERAGE_BIDS_BELOW_FLOOR.toUpperCase()}`,
		filter: {},
		visible: false,
		search: '',
		sort: SORT.NONE,
		type: TYPE.NUMBER,
		group: GROUP.BID_PRICES,
	},
	[ID.AVERAGE_BIDS_BY_BLOCKED_ADVERTISER]: {
		id: ID.AVERAGE_BIDS_BY_BLOCKED_ADVERTISER,
		name: `TITLE.REPORTS.COLUMN.${ID.AVERAGE_BIDS_BY_BLOCKED_ADVERTISER.toUpperCase()}`,
		filter: {},
		visible: false,
		search: '',
		sort: SORT.NONE,
		type: TYPE.NUMBER,
		group: GROUP.BID_PRICES,
	},
	[ID.AVERAGE_BID_PRICE]: {
		id: ID.AVERAGE_BID_PRICE,
		name: `TITLE.REPORTS.COLUMN.${ID.AVERAGE_BID_PRICE.toUpperCase()}`,
		filter: {},
		visible: false,
		search: '',
		sort: SORT.NONE,
		type: TYPE.CURRENCY,
		group: GROUP.BID_PRICES,
	},
	[ID.YIELDPROBE_REQUESTS]: {
		id: ID.YIELDPROBE_REQUESTS,
		name: `TITLE.REPORTS.COLUMN.${ID.YIELDPROBE_REQUESTS.toUpperCase()}`,
		filter: {},
		visible: false,
		search: '',
		sort: SORT.NONE,
		type: TYPE.NUMBER,
		group: GROUP.KEY_FIGURES_GENERAL,
	},
	[ID.BID_REQUESTS]: {
		id: ID.BID_REQUESTS,
		name: `TITLE.REPORTS.COLUMN.${ID.BID_REQUESTS.toUpperCase()}`,
		filter: {},
		visible: true,
		search: '',
		sort: SORT.NONE,
		type: TYPE.NUMBER,
		group: GROUP.KEY_FIGURES_GENERAL,
	},
	[ID.BIDS_BELOW_FLOOR]: {
		id: ID.BIDS_BELOW_FLOOR,
		name: `TITLE.REPORTS.COLUMN.${ID.BIDS_BELOW_FLOOR.toUpperCase()}`,
		filter: {},
		visible: true,
		search: '',
		sort: SORT.NONE,
		type: TYPE.NUMBER,
		group: GROUP.INVALID_BIDS,
	},
	[ID.BIDS_BY_BLOCKED_ADVERTISERS]: {
		id: ID.BIDS_BY_BLOCKED_ADVERTISERS,
		name: `TITLE.REPORTS.COLUMN.${ID.BIDS_BY_BLOCKED_ADVERTISERS.toUpperCase()}`,
		filter: {},
		visible: true,
		search: '',
		sort: SORT.NONE,
		type: TYPE.NUMBER,
		group: GROUP.INVALID_BIDS,
	},
	[ID.DELIVERIES]: {
		id: ID.DELIVERIES,
		name: `TITLE.REPORTS.COLUMN.${ID.DELIVERIES.toUpperCase()}`,
		filter: {},
		visible: false,
		search: '',
		sort: SORT.NONE,
		type: TYPE.NUMBER,
		group: GROUP.AD_SERVING,
	},
	[ID.LOSS_RATE]: {
		id: ID.LOSS_RATE,
		name: `TITLE.REPORTS.COLUMN.${ID.LOSS_RATE.toUpperCase()}`,
		filter: {},
		visible: false,
		search: '',
		sort: SORT.NONE,
		type: TYPE.NUMBER,
		group: GROUP.AD_SERVING,
	},
	[ID.LOST_BIDS]: {
		id: ID.LOST_BIDS,
		name: `TITLE.REPORTS.COLUMN.${ID.LOST_BIDS.toUpperCase()}`,
		filter: {},
		visible: true,
		search: '',
		sort: SORT.NONE,
		type: TYPE.NUMBER,
		group: GROUP.KEY_FIGURES_GENERAL,
	},
	[ID.NO_BIDS]: {
		id: ID.NO_BIDS,
		name: `TITLE.REPORTS.COLUMN.${ID.NO_BIDS.toUpperCase()}`,
		filter: {},
		visible: false,
		search: '',
		sort: SORT.NONE,
		type: TYPE.NUMBER,
		group: GROUP.KEY_FIGURES_GENERAL,
	},
	[ID.TOTAL_BIDS]: {
		id: ID.TOTAL_BIDS,
		name: `TITLE.REPORTS.COLUMN.${ID.TOTAL_BIDS.toUpperCase()}`,
		filter: {},
		visible: true,
		search: '',
		sort: SORT.NONE,
		type: TYPE.NUMBER,
		group: GROUP.KEY_FIGURES_GENERAL,
	},
	[ID.WON_BIDS]: {
		id: ID.WON_BIDS,
		name: `TITLE.REPORTS.COLUMN.${ID.WON_BIDS.toUpperCase()}`,
		filter: {},
		visible: true,
		search: '',
		sort: SORT.NONE,
		type: TYPE.NUMBER,
		group: GROUP.KEY_FIGURES_GENERAL,
	},
	[ID.YIELDPROBE_IGNORED_RECOMMENDATIONS]: {
		id: ID.YIELDPROBE_IGNORED_RECOMMENDATIONS,
		name: `TITLE.REPORTS.COLUMN.${ID.YIELDPROBE_IGNORED_RECOMMENDATIONS.toUpperCase()}`,
		filter: {},
		visible: false,
		search: '',
		sort: SORT.NONE,
		type: TYPE.NUMBER,
		group: GROUP.KEY_FIGURES_GENERAL,
	},
	[ID.ECPM]: {
		id: ID.ECPM,
		name: `TITLE.REPORTS.COLUMN.${ID.ECPM.toUpperCase()}`,
		filter: {},
		visible: false,
		search: '',
		sort: SORT.NONE,
		type: TYPE.CURRENCY,
		group: GROUP.KEY_FIGURES_GENERAL,
	},
	[ID.FALLBACKS]: {
		id: ID.FALLBACKS,
		name: `TITLE.REPORTS.COLUMN.${ID.FALLBACKS.toUpperCase()}`,
		filter: {},
		visible: false,
		search: '',
		sort: SORT.NONE,
		type: TYPE.NUMBER,
		group: GROUP.KEY_FIGURES_GENERAL,
	},
	[ID.IMPRESSIONS]: {
		id: ID.IMPRESSIONS,
		name: `TITLE.REPORTS.COLUMN.${ID.IMPRESSIONS.toUpperCase()}`,
		filter: {},
		visible: true,
		search: '',
		sort: SORT.NONE,
		type: TYPE.NUMBER,
		group: GROUP.KEY_FIGURES_GENERAL,
	},
	[ID.IMPRESSIONS_RTB]: {
		id: ID.IMPRESSIONS,
		name: `TITLE.REPORTS.COLUMN.${ID.IMPRESSIONS_RTB.toUpperCase()}`,
		filter: {},
		visible: true,
		search: '',
		sort: SORT.NONE,
		type: TYPE.NUMBER,
		group: GROUP.AD_SERVING,
	},
	[ID.NET_ECPM]: {
		id: ID.NET_ECPM,
		name: `TITLE.REPORTS.COLUMN.${ID.NET_ECPM.toUpperCase()}`,
		filter: {},
		visible: false,
		search: '',
		sort: SORT.NONE,
		type: TYPE.CURRENCY,
		group: GROUP.KEY_FIGURES_GENERAL,
	},
	[ID.NET_REVENUE]: {
		id: ID.NET_REVENUE,
		name: `TITLE.REPORTS.COLUMN.${ID.NET_REVENUE.toUpperCase()}`,
		filter: {},
		visible: true,
		search: '',
		sort: SORT.NONE,
		type: TYPE.CURRENCY,
		group: GROUP.KEY_FIGURES_GENERAL,
	},
	[ID.PARENT_REVENUE]: {
		id: ID.PARENT_REVENUE,
		name: `TITLE.REPORTS.COLUMN.${ID.PARENT_REVENUE.toUpperCase()}`,
		filter: {},
		visible: false,
		search: '',
		sort: SORT.NONE,
		type: TYPE.CURRENCY,
		group: GROUP.KEY_FIGURES_GENERAL,
	},
	[ID.REVENUE]: {
		id: ID.REVENUE,
		name: `TITLE.REPORTS.COLUMN.${ID.REVENUE.toUpperCase()}`,
		filter: {},
		visible: true,
		search: '',
		sort: SORT.NONE,
		type: TYPE.CURRENCY,
		group: GROUP.KEY_FIGURES_GENERAL,
	},
	[ID.VIDEO_CLICK]: {
		id: ID.VIDEO_CLICK,
		name: `TITLE.REPORTS.COLUMN.${ID.VIDEO_CLICK.toUpperCase()}`,
		filter: {},
		visible: false,
		search: '',
		sort: SORT.NONE,
		type: TYPE.NUMBER,
		group: GROUP.VIDEO,
	},
	[ID.VIDEO_COMPLETE]: {
		id: ID.VIDEO_COMPLETE,
		name: `TITLE.REPORTS.COLUMN.${ID.VIDEO_COMPLETE.toUpperCase()}`,
		filter: {},
		visible: false,
		search: '',
		sort: SORT.NONE,
		type: TYPE.NUMBER,
		group: GROUP.VIDEO,
	},
	[ID.VIDEO_FIRST_QUARTILE]: {
		id: ID.VIDEO_FIRST_QUARTILE,
		name: `TITLE.REPORTS.COLUMN.${ID.VIDEO_FIRST_QUARTILE.toUpperCase()}`,
		filter: {},
		visible: false,
		search: '',
		sort: SORT.NONE,
		type: TYPE.NUMBER,
		group: GROUP.VIDEO,
	},
	[ID.VIDEO_MIDPOINT]: {
		id: ID.VIDEO_MIDPOINT,
		name: `TITLE.REPORTS.COLUMN.${ID.VIDEO_MIDPOINT.toUpperCase()}`,
		filter: {},
		visible: false,
		search: '',
		sort: SORT.NONE,
		type: TYPE.NUMBER,
		group: GROUP.VIDEO,
	},
	[ID.VIDEO_STARTS]: {
		id: ID.VIDEO_STARTS,
		name: `TITLE.REPORTS.COLUMN.${ID.VIDEO_STARTS.toUpperCase()}`,
		filter: {},
		visible: false,
		search: '',
		sort: SORT.NONE,
		type: TYPE.NUMBER,
		group: GROUP.VIDEO,
	},
	[ID.VIDEO_THIRD_QUARTILE]: {
		id: ID.VIDEO_THIRD_QUARTILE,
		name: `TITLE.REPORTS.COLUMN.${ID.VIDEO_THIRD_QUARTILE.toUpperCase()}`,
		filter: {},
		visible: false,
		search: '',
		sort: SORT.NONE,
		type: TYPE.NUMBER,
		group: GROUP.VIDEO,
	},
};

const TIME_INFO_COLUMNS = {
	[ID.HOUR]: {
		id: ID.HOUR,
		name: `TITLE.REPORTS.COLUMN.${ID.HOUR.toUpperCase()}`,
		filter: {},
		visible: true,
		search: '',
		sort: SORT.NONE,
		type: TYPE.TEXT,
		group: GROUP.KEY_FIGURES_GENERAL,
	},
};

export default {
	ID,
	DATA,
	TIME_INFO_COLUMNS,
};
