export default {
	ADSLOT: 0,
	PARTNER: 1,
	KEY_FIGURES_GENERAL: 2,
	BILLING: 4,
	VIDEO: 5,
	INVALID_BIDS: 6,
	BID_PRICES: 7,
	AD_SERVING: 8,
	YDC: 9,
};
