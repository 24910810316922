function ImageModalMessageController(close, file) {
	'ngInject';

	var vm = this;

	vm.file = file;

	vm.close = function closeFn() {
		close(undefined, 300);
	};
}

export default ImageModalMessageController;
