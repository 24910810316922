function AdslotsChannelsNewController($scope, $translate, $filter, $state, Adslots, Channels, InfoService, toaster) {
	'ngInject';

	$scope.models = {
		isNewChannel: true,
		assignedNameFilter: '',
		availableNameFilter: '',
		assignedList: [], // list of assigned adslots to channel
		availableList: [], // list of available unassigned adslots to channel
		adslotsList: Adslots.getList().map(function map(channel) {
			channel.checked = false;
			return channel;
		}), // list of all adslots
	};

	$scope.form = {
		name: $translate.instant('TITLE.NEW_CHANNEL'),
		websites: [],
	};
	$scope.initialForm = angular.copy($scope.form);

	$scope.toggleSelection = function toggleSelection(list) {
		var i;

		for (i = 0; i < list.length; i++) {
			list[i].checked = !list[i].checked;
		}
	};

	$scope.save = function save() {
		// get adslot ids out of the list of assigned adslots
		$scope.form.websites = $filter('filter')($scope.models.adslotsList, {
			checked: true,
		}).reduce(function reduce(adslotIds, adslot) {
			adslotIds.push(adslot.id);
			return adslotIds;
		}, []);

		InfoService.startRequest();

		Channels.add($scope.form.name, $scope.form.websites)
			.then(function success() {
				InfoService.endRequest();
				toaster.successMessage('MESSAGE.SETTINGS_SAVE_SUCCESS');

				$state.go('inventory.channels');
			})
			.catch(function error(errorObject) {
				if (errorObject.code === Channels.ERROR.NAME_EXISTS) {
					$scope.channelEditForm.name.$setValidity('required', false);
					toaster.errorMessage('MESSAGE.CHANNEL_NAME_EXISTS');
				} else if (errorObject.code === Channels.ERROR.NAME_INVALID) {
					$scope.channelEditForm.name.$setValidity('required', false);
					toaster.errorMessage('MESSAGE.CHANNEL_NAME_INVALID');
				} else {
					toaster.errorMessage('MESSAGE.SETTINGS_SAVE_ERROR');
				}
				InfoService.endRequest();
			});
	};
}

export default AdslotsChannelsNewController;
