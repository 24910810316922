import ListFilterGroup from './ListFilterGroup.js';

import listFilterGroupDataHtml from '../../views/list-filter-group-data.html';

const DATA_ENTRY_KEY = 'data-entry';

/**
 * @constructor
 * @param {string} key
 */
function ListFilterGroupData(key) {
	ListFilterGroup.call(this, key);

	this.template = listFilterGroupDataHtml;

	/**
	 * Indicates whether the groups data entries are searchable.
	 * Used in template.
	 * @type {boolean}
	 */
	this.hasEntrySearch = false;
}

ListFilterGroupData.prototype = Object.create(ListFilterGroup.prototype);
ListFilterGroupData.prototype.constructor = ListFilterGroupData;

/**
 * @param {string} key
 * @param {ListFilterEntry} entry
 * @returns {ListFilterGroup}
 */
ListFilterGroupData.prototype.addEntry = function addEntry(key, entry) {
	this.entries[DATA_ENTRY_KEY] = entry;
	return this;
};

/**
 * @param {string} key
 * @returns {ListFilterEntry}
 */
ListFilterGroupData.prototype.getEntry = function getEntry(key) {
	// eslint-disable-line no-unused-vars
	return this.entries[DATA_ENTRY_KEY];
};

/**
 * @returns {ListFilterGroupData}
 */
ListFilterGroupData.prototype.setHasEntrySearch = function setHasEntrySearch() {
	this.hasEntrySearch = true;
	return this;
};

export default ListFilterGroupData;
