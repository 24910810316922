function PartnershipBidOptions(Api, $q) {
	'ngInject';

	/**
	 * @param {int} partnershipId
	 * @returns {promise}
	 */
	this.get = function getFn(partnershipId) {
		return Api.get(`/api/supply/v1/partnerships/${partnershipId}/bidoptions`).then(
			function success(bidOptions) {
				return bidOptions;
			},
			function error() {
				return $q.reject({});
			},
		);
	};

	/**
	 * @param {int} partnershipId
	 * @param {Object} bidOptions
	 * @returns {promise}
	 */
	this.update = (partnershipId, bidOptions) => Api.post(`/api/supply/v1/partnerships/${partnershipId}/bidoptions`, bidOptions);
}

export default PartnershipBidOptions;
