function AbstractPricingController($scope) {
	'ngInject';

	$scope.models = {
		onlyChanged: false,

		filteredListItems: [],
		changedPricesCount: 0,

		form: {}, // {id: {floorPrice, fixedPrice}}
		invalidPrices: {},
	};

	$scope.methods = {};

	$scope.methods.onlyChangedFilter = function onlyChangedFilter(listItem) {
		return (
			!$scope.models.onlyChanged || // not checked? -> show all
			($scope.models.form[listItem.id] && $scope.models.form[listItem.id].$changed)
		);
	};

	$scope.methods.addPricesToList = function addPricesToList(list, priceMap) {
		var priceKey;
		// add prices to list to enable sorting by it
		list.map(function map(entry) {
			for (priceKey in priceMap[entry.id]) {
				if (Object.prototype.hasOwnProperty.call(priceMap[entry.id], priceKey)) {
					entry[priceKey] = priceMap[entry.id][priceKey];
				}
			}
			return entry;
		});
	};

	/**
	 * @param {int} id
	 * @returns {boolean} true, if price has changed state has changed
	 */
	$scope.methods.detectChangeAndValidate = function detectChangeAndValidate(id) {
		var changedStateBefore = $scope.models.form[id].$changed;

		// mark changes in the form model itself
		$scope.models.form[id].$changed =
			$scope.models.form[id].floorPrice !== $scope.models.form[id].$original.floorPrice ||
			$scope.models.form[id].fixedPrice !== $scope.models.form[id].$original.fixedPrice;

		// check for only fixed OR floor price is allowed at the same time
		if (
			(angular.isDefined($scope.models.form[id].$original.floorPrice) && angular.isUndefined($scope.models.form[id].floorPrice)) ||
			(angular.isDefined($scope.models.form[id].$original.fixedPrice) && angular.isUndefined($scope.models.form[id].fixedPrice)) ||
			($scope.models.form[id].floorPrice > 0 && $scope.models.form[id].fixedPrice > 0)
		) {
			$scope.models.invalidPrices[id] = 1;
		} else {
			delete $scope.models.invalidPrices[id];
		}

		return changedStateBefore !== $scope.models.form[id].$changed;
	};

	$scope.methods.calculateChangedCount = function calculateChangedCount(itemList) {
		// and keep track of the number of changed items
		$scope.models.changedPricesCount = itemList.filter(function changedPricesCountFilter(listItem) {
			return $scope.models.form[listItem.id] && $scope.models.form[listItem.id].$changed;
		}).length;
	};

	$scope.methods.checkChanged = function checkChanged(itemList, id) {
		if ($scope.methods.detectChangeAndValidate(id)) {
			$scope.methods.calculateChangedCount(itemList);
		}
	};

	$scope.methods.isFormValid = function isFormValid() {
		return angular.equals($scope.models.invalidPrices, {});
	};

	/**
	 * @param {Array.<Object>} itemList
	 * @param {{floorPrice: <int>, fixedPrice: <int>}} priceData
	 * @param {'floorPrice'|'fixedPrice'} key
	 */
	$scope.methods.applyToAll = function applyToAll(itemList, priceData, key) {
		const filteredList = $scope.models.filteredListItems;

		// apply prices to whole, currently visible list
		for (let i = 0, l = filteredList.length; i < l; i += 1) {
			const { id } = filteredList[i];

			$scope.models.form[id][key] = priceData[key];

			$scope.methods.detectChangeAndValidate(id);
		}

		// update changed info
		$scope.methods.calculateChangedCount(itemList);
	};

	/**
	 * @param {int} id
	 * @returns {boolean} true, if a change was discarded
	 */
	$scope.methods.discard = function discard(id) {
		if (!angular.equals($scope.models.form[id], $scope.models.form[id].$original)) {
			$scope.models.form[id].floorPrice = $scope.models.form[id].$original.floorPrice;
			$scope.models.form[id].fixedPrice = $scope.models.form[id].$original.fixedPrice;

			$scope.methods.detectChangeAndValidate(id);
			return true;
		}
		return false;
	};

	$scope.methods.discardChange = function discardChange(itemList, id) {
		if ($scope.methods.discard(id)) {
			$scope.methods.calculateChangedCount(itemList);
		}
	};

	$scope.methods.discardChanges = function discardChanges(itemList) {
		Object.keys($scope.models.form).forEach($scope.methods.discard);

		$scope.methods.calculateChangedCount(itemList);
	};

	/**
	 * Initialize form model in a way to keep track of changes.
	 *
	 * @param {Array.<Object>} itemList
	 * @param {Object.<int, {floorPrice: <int>, fixedPrice: <int>}>} priceMap
	 * @returns {Object}
	 */
	$scope.methods.initializeForm = function initializeForm(itemList, priceMap) {
		var i,
			length,
			form = {};

		for (i = 0, length = itemList.length; i < length; i++) {
			form[itemList[i].id] = {
				floorPrice: priceMap[itemList[i].id] ? priceMap[itemList[i].id].floorPrice : 0,
				fixedPrice: priceMap[itemList[i].id] ? priceMap[itemList[i].id].fixedPrice : 0,
			};

			// store original values
			form[itemList[i].id].$original = angular.copy(form[itemList[i].id]);

			// initialize change markers
			form[itemList[i].id].$changed = false;
		}

		return form;
	};

	$scope.methods.initializeModels = function initializeModels() {
		$scope.models.pageNo = 1;

		$scope.models.filteredListItems = [];
		$scope.models.changedPricesCount = 0;

		$scope.models.onlyChanged = false;
	};

	$scope.methods.postSaveActions = function postSaveActions() {
		$scope.models.changedPricesCount = 0;
		$scope.models.onlyChanged = false;
	};

	$scope.methods.getChangedPrices = function getChangedPrices() {
		var id,
			prices = [];

		for (id in $scope.models.form) {
			if (Object.prototype.hasOwnProperty.call($scope.models.form, id) && $scope.models.form[id].$changed) {
				prices.push({
					id: parseInt(id, 10),
					floorPrice: $scope.models.form[id].floorPrice,
					fixedPrice: $scope.models.form[id].fixedPrice,
				});
			}
		}

		return prices;
	};
}

export default AbstractPricingController;
