import { hash } from 'immutable';

import AD_TYPE from './AdslotAdType.js';
import PLATFORM_TYPE from './AdslotPlatformType.js';

class InventoryType {
	constructor(adType, platformType) {
		this.adType = adType;
		this.platformType = platformType;
	}

	static createFromNames(adTypeName, platformTypeName) {
		return new InventoryType(AD_TYPE[`${adTypeName.toUpperCase()}`], PLATFORM_TYPE[`${platformTypeName.toUpperCase()}`]);
	}

	getAdType() {
		return this.adType;
	}

	getPlatformType() {
		return this.platformType;
	}

	/**
	 * @param other {InventoryType}
	 * @return {boolean}
	 */
	equals(other) {
		return other.getAdType() === this.getAdType() && other.getPlatformType() === this.getPlatformType();
	}

	/**
	 * @returns {Number}
	 */
	hashCode() {
		return hash(
			this.getAdType() + Object.prototype.toString.apply(this.getAdType()) + this.getPlatformType() + Object.prototype.toString.apply(this.getPlatformType()),
		);
	}
}

export default InventoryType;
