import isUndefined from 'lodash/isUndefined.js';

import { ascByEntryName } from '../../utils/sort-utils.js';

function ReportsPreFilterController($scope, uiGridConstants, ReportsFilterSharedService) {
	'ngInject';

	const SELECTION_LIMIT = 350;
	// used in view html
	this.LIMIT = SELECTION_LIMIT;

	// used for initialization
	this.availableList = [];
	this.selectedList = [];

	ReportsFilterSharedService.init($scope, ReportsPreFilterController.name);
	ReportsFilterSharedService.registerMethod(ReportsPreFilterController.name, ReportsFilterSharedService.getAvailableNames().METHOD_UPDATE, () =>
		this.availableFilterChanged(),
	);

	let handleSelectionLimit = () => {};

	const initSelectionLimitHandler = (gridApi, gridOptions, limit) => () => {
		const previousSelectionEnabledState = gridOptions.enableRowSelection;

		gridOptions.enableRowSelection = this.selectedList.length < limit;

		if (gridOptions.enableRowSelection !== previousSelectionEnabledState) {
			gridApi.core.notifyDataChange(uiGridConstants.dataChange.OPTIONS);
		}
	};

	this.isSelectionEnabled = () => this.selectedList && this.selectedList.length < SELECTION_LIMIT;

	const commonGridOptions = {
		showHeader: false,

		enableHorizontalScrollbar: uiGridConstants.scrollbars.NEVER,

		enableRowSelection: true,
		enableRowHeaderSelection: false,
		multiSelect: false,

		columnDefs: [
			{
				field: 'entry',
				cellTemplate: '<div class="ui-grid-cell-contents">{{ COL_FIELD.name }} <small style="color: #777">{{ COL_FIELD.info }}</small></div>',
			},
		],
		rowHeight: 42,
	};

	this.availableGridOptions = {
		...commonGridOptions,
		enableRowSelection: this.selectedList.length < SELECTION_LIMIT,
		data: [...this.availableList],
		onRegisterApi: (gridApi) => {
			handleSelectionLimit = initSelectionLimitHandler(gridApi, this.availableGridOptions, SELECTION_LIMIT);
			this.availabeGridApi = gridApi;
			gridApi.selection.on.rowSelectionChanged($scope, (row) => {
				const item = this.availableList.splice(
					this.availableList.findIndex((entry) => entry.id === row.entity.id),
					1,
				)[0];
				this.selectedList.push(item);
				this.selectedList.sort(ascByEntryName);

				this.availableFilterChanged();
				this.selectedFilterChanged();

				handleSelectionLimit();
			});
		},
	};

	this.selectedGridOptions = {
		...commonGridOptions,
		data: [...this.selectedList],
		onRegisterApi: (gridApi) => {
			this.selectedGridApi = gridApi;
			gridApi.selection.on.rowSelectionChanged($scope, (row) => {
				const item = this.selectedList.splice(
					this.selectedList.findIndex((entry) => entry.id === row.entity.id),
					1,
				)[0];
				this.availableList.push(item);
				this.availableList.sort(ascByEntryName);

				this.availableFilterChanged();
				this.selectedFilterChanged();

				handleSelectionLimit();
			});
		},
	};

	this.availableNameFilter = '';
	this.selectedNameFilter = '';
	this.listFilter = false;

	this.availableFilterChanged = () => {
		let availableAdslots = this.availableList;
		if (ReportsFilterSharedService.hasMethod(ReportsFilterSharedService.getAvailableNames().METHOD_GET_FILTERED_DATE)) {
			availableAdslots = ReportsFilterSharedService.callMethod(ReportsFilterSharedService.getAvailableNames().METHOD_GET_FILTERED_DATE, availableAdslots);
			this.listFilter = availableAdslots.length !== this.availableList.length;
		}

		this.availableGridOptions.data = availableAdslots.filter(
			(item) =>
				!this.availableNameFilter ||
				item.entry.name.toLowerCase().includes(this.availableNameFilter.toLowerCase()) ||
				item.entry.info.toString().includes(this.availableNameFilter.toLowerCase()),
		);
	};

	this.selectedFilterChanged = () => {
		this.selectedGridOptions.data = this.selectedList.filter(
			(item) =>
				!this.selectedNameFilter ||
				item.entry.name.toLowerCase().includes(this.selectedNameFilter.toLowerCase()) ||
				item.entry.info.toString().includes(this.selectedNameFilter.toLowerCase()),
		);
	};

	this.toggleAvailableSelection = () => {
		const itemsLeft = Math.max(SELECTION_LIMIT - this.selectedList.length, 0);

		if (itemsLeft > 0) {
			if (this.availableNameFilter || this.listFilter) {
				this.availableGridOptions.data.slice(0, itemsLeft).forEach((item) => {
					const removed = this.availableList.splice(
						this.availableList.findIndex((entry) => entry.id === item.id),
						1,
					)[0];
					this.selectedList.push(removed);
				});
				this.availableFilterChanged();
			} else {
				this.selectedList = this.selectedList.concat(this.availableList.splice(0, itemsLeft));
				this.availableGridOptions.data = this.availableList;
			}

			this.selectedList.sort(ascByEntryName);
			this.selectedFilterChanged();
			handleSelectionLimit();
		}
	};

	this.toggleSelectedSelection = () => {
		if (this.selectedNameFilter) {
			this.selectedGridOptions.data.forEach((item) => {
				const removed = this.selectedList.splice(
					this.selectedList.findIndex((entry) => entry.id === item.id),
					1,
				)[0];
				this.availableList.push(removed);
			});
			this.selectedFilterChanged();
		} else {
			this.availableList = this.availableList.concat(this.selectedList.splice(0));
			this.selectedGridOptions.data = [];
		}

		this.availableList.sort(ascByEntryName);
		this.availableFilterChanged();
		handleSelectionLimit();
	};

	this.$doCheck = () => {
		this.onSelectionChange({
			$event: {
				selection: this.selectedList,
			},
		});
	};

	this.$onInit = () => {
		this.elements.forEach((element) => {
			const { id, name } = element;

			let info;

			if (!isUndefined(element.seatId)) {
				info = element.seatId;
			} else {
				info = element.id;
			}

			const entry = {
				id,
				entry: {
					info,
					name,
				},
				element,
			};

			if (this.selected.length > 0 && this.selected.includes(id)) {
				this.selectedList.push(entry);
			} else {
				this.availableList.push(entry);
			}
		});

		this.availableList.sort(ascByEntryName);
		this.selectedList.sort(ascByEntryName);

		// update grid option definitions
		this.availableGridOptions.data = this.availableList;
		this.selectedGridOptions.data = this.selectedList;
	};
}

export default ReportsPreFilterController;
