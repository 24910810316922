import angular from 'angular';

import MATOMO_CONFIG from './matomo-config.constant.js';
import MatomoProvider from './matomo.provider.js';

const MatomoModule = angular
	.module('app.modules.matomo', [])

	.constant('trackingConfig', MATOMO_CONFIG)
	.provider('Matomo', ['trackingConfig', MatomoProvider])

	.run((trackingConfig, Matomo) => {
		'ngInject';

		Matomo.validateTrackingConfig(trackingConfig);
	}).name;

export default MatomoModule;
