function ModalCloserModel() {
	function ModalCloser() {
		this.canClose = true;
	}

	/**
	 * @param {function} fn
	 * @param {*} [value]
	 * @param {number} [delay]
	 */
	ModalCloser.prototype.close = function close(fn, value, delay) {
		if (this.canClose) {
			this.canClose = false;
			fn(value, delay);
		}
	};

	return ModalCloser;
}

export default ModalCloserModel;
