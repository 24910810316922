import angular from 'angular';

import PagerFilter from './pager.filter.js';
import PagerService from './pager.service.js';

import PagerControlsComponent from './pager-controls.component.js';
import PagerInfoComponent from './pager-info.component.js';
import PagerControlsController from './pager-controls.controller.js';
import PagerInfoController from './pager-info.controller.js';

const PagerModule = angular // eslint-disable-line angular/no-service-method
	.module('PagerModule', [])

	.filter('pager', PagerFilter)
	.service('PagerService', PagerService)

	.component('pagerControls', PagerControlsComponent)
	.component('pagerInfo', PagerInfoComponent)
	.controller('PagerControlsController', PagerControlsController)
	.controller('PagerInfoController', PagerInfoController).name;

export default PagerModule;
