function PartnershipYieldprobeParameters(Api, $q) {
	'ngInject';

	/**
	 * @param {int} partnershipId
	 * @returns {promise}
	 */
	this.get = function getFn(partnershipId) {
		return Api.get(`/api/supply/v1/partnerships/${partnershipId}/options/yp`).then(
			function success(yieldprobeParameters) {
				return yieldprobeParameters;
			},
			function error() {
				return $q.reject({});
			},
		);
	};

	/**
	 * @param {int} partnershipId
	 * @param {Object} yieldprobeParameters
	 * @returns {promise}
	 */
	this.update = (partnershipId, yieldprobeParameters) => Api.post(`/api/supply/v1/partnerships/${partnershipId}/options/yp`, yieldprobeParameters);

	/**
	 * @param {Object} parameters
	 * @returns {Array.<Object>}
	 */
	this.asArray = function asArry(parameters) {
		var paramName,
			i,
			l,
			result = [],
			MAX_ALLOWED = 3;

		for (paramName in parameters) {
			if (Object.prototype.hasOwnProperty.call(parameters, paramName)) {
				result.push({ name: paramName, value: parameters[paramName] });
			}
		}

		// fill with empty object until MAX_ALLOWED
		l = MAX_ALLOWED - result.length;
		for (i = 0; i < l; i++) {
			result.push({ name: '', value: '' });
		}

		return result;
	};

	/**
	 * @param {Array.<Object>} parameters
	 * @returns {Object}
	 */
	this.asObject = function asObject(parameters) {
		var i,
			l = parameters.length,
			res = {};

		for (i = 0; i < l; i++) {
			res[parameters[i].name] = parameters[i].value;
		}

		return res;
	};
}

export default PartnershipYieldprobeParameters;
