import AdvertiserCategoryFilter from '../../../models/AdvertiserCategoryFilter.js';

function PartnershipCategoryFilterController(
	$scope,
	$stateParams,
	resolvedPartnershipCategoryFilter,
	AdvertiserCategories,
	PartnershipCategoryFilter,
	toaster,
	InfoService,
) {
	'ngInject';

	let initialFilter = resolvedPartnershipCategoryFilter;

	$scope.filter = resolvedPartnershipCategoryFilter;

	$scope.assignedData = Array.from(initialFilter.items)
		.map((advertiserCategoryId) => AdvertiserCategories.getById(advertiserCategoryId))
		.filter((entry) => entry !== undefined); // eslint-disable-line angular/definedundefined
	$scope.availableData = Object.values(AdvertiserCategories.getMap());

	$scope.saveAdvertiserCategoryFilter = () => {
		const currentFilter = AdvertiserCategoryFilter.fromIds($scope.assignedData.map(({ id }) => id));
		const currentFilterDataObject = currentFilter.toObject();

		if (!initialFilter.equals(currentFilter)) {
			InfoService.startRequest();

			PartnershipCategoryFilter.save($stateParams.id, currentFilter)
				.then(() => {
					initialFilter = new AdvertiserCategoryFilter(currentFilterDataObject);

					toaster.successMessage('MESSAGE.PARTNERSHIP_SAVE_SUCCESS');
				})
				.catch(() => toaster.errorMessage('MESSAGE.PARTNERSHIP_SAVE_ERROR'))
				.finally(() => InfoService.endRequest());
		}
	};
}

export default PartnershipCategoryFilterController;
