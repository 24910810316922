import isEqual from 'lodash/isEqual.js';

import OpenAuctionConfig from '../../../models/OpenAuctionConfig.js';
import REFERRER_TYPE from '../../../models/ReferrerType.js';

function PartnershipsOpenAuctionsViewDetailsController(
	isOpenAuctionEnabled,
	resolvedConfiguration,
	AdslotTranslations,
	InfoService,
	OpenAuctionSettings,
	StringTools,
	toaster,
) {
	'ngInject';

	const vm = this;

	vm.isEnabled = isOpenAuctionEnabled;

	vm.initialConfig = new OpenAuctionConfig(resolvedConfiguration.toObject());
	vm.config = resolvedConfiguration;

	vm.auctionTypes = AdslotTranslations.getAuctionTypes();
	vm.auctionTypeDisabled = false;

	vm.referrerTypes = {
		[REFERRER_TYPE.TRANSPARENT]: 'CAPTION.REFERRER_VISIBLE',
		[REFERRER_TYPE.SEMI_TRANSPARENT]: 'CAPTION.REFERRER_OVERWRITTEN',
		[REFERRER_TYPE.BLIND]: 'CAPTION.REFERRER_HIDDEN',
	};

	vm.showReferrerUrl = () => vm.config.referrerType === REFERRER_TYPE.SEMI_TRANSPARENT;

	vm.isFormValid = () => {
		return true;
	};

	vm.cleanUrl = () => {
		vm.config.referrerUrl = StringTools.stripProtocol(vm.config.referrerUrl);
	};

	vm.toggle = () => {
		if (!InfoService.isRequestInProgress()) {
			InfoService.startRequest();
			OpenAuctionSettings.setOpenAuctionEnabled(vm.isEnabled)
				.then(() => toaster.successMessage('MESSAGE.SETTINGS_SAVE_SUCCESS'))
				.catch(() => {
					// reset toggle to previous state
					vm.isEnabled = !vm.isEnabled;
					toaster.errorMessage('MESSAGE.SETTINGS_SAVE_ERROR');
				})
				.finally(() => InfoService.endRequest());
		}
	};

	vm.save = () => {
		if (!isEqual(vm.initialConfig, vm.config)) {
			InfoService.startRequest();
			OpenAuctionSettings.setGlobalConfig(vm.config)
				.then((updatedConfig) => {
					vm.initialConfig = updatedConfig;

					// clear referrerUrl after save when obsolete -> make isEqual check function properly
					if (!vm.showReferrerUrl()) {
						vm.config.referrerUrl = '';
					}

					toaster.successMessage('MESSAGE.SETTINGS_SAVE_SUCCESS');
				})
				.catch((errorObject) => {
					toaster.errorMessage('MESSAGE.SETTINGS_SAVE_ERROR' + JSON.stringify(errorObject)); // eslint-disable-line angular/json-functions
				})
				.finally(() => InfoService.endRequest());
		}
	};
}

export default PartnershipsOpenAuctionsViewDetailsController;
