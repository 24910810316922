import isNumber from 'lodash/isNumber.js';

class DemandPartner {
	/**
	 * @param {Object} [data]
	 */
	constructor({
		id = 0,
		name = '',
		enabled = false,
		visible = false,
		requireDeals = false,
		type = 0,
		seatId = '',
		technologyId = -1,
		inheritParentAuction = false,
	}) {
		/**
		 * @type {Number}
		 */
		this.id = id;

		/**
		 * @type {String}
		 */
		this.name = name;

		/**
		 * @type {Boolean}
		 */
		this.enabled = enabled;

		/**
		 * @type {Boolean}
		 */
		this.visible = visible;

		/**
		 * @type {Boolean}
		 */
		this.requireDeals = requireDeals;

		/**
		 * @type {Number}
		 */
		this.type = isNumber(type) && type >= 0 && type < 3 ? type : -1;

		/**
		 * @type {String}
		 */
		this.seatId = seatId;

		/**
		 * @type {Number}
		 */
		this.technologyId = technologyId;

		/**
		 * @type {Boolean}
		 */
		this.inheritParentAuction = inheritParentAuction;
	}

	TYPE = {
		RTB: 0,
		INTERNAL: 1,
		DC: 2,
	};

	TYPES = ['RTB', 'INTERNAL', 'DC'];

	/**
	 * @return {Number}
	 */
	getId() {
		return this.id;
	}

	/**
	 * @return {String}
	 */
	getName() {
		return this.name;
	}

	/**
	 * @return {Boolean}
	 */
	isEnabled() {
		return this.enabled;
	}

	/**
	 * @return {Boolean}
	 */
	isVisible() {
		return this.visible;
	}

	/**
	 * @return {String}
	 */
	getSeatId() {
		return this.seatId;
	}

	/**
	 * @returns {Boolean}
	 */
	isRtb() {
		return this.type === this.TYPE.RTB;
	}

	/**
	 * @returns {Boolean}
	 */
	isDC() {
		return this.type === this.TYPE.DC;
	}

	/**
	 * @returns {Boolean}
	 */
	isInternal() {
		return this.type === this.TYPE.INTERNAL;
	}

	/**
	 * @returns {Boolean}
	 */
	hasDeals() {
		return !!this.getSeatId();
	}

	/**
	 * @returns {Boolean}
	 */
	isRequireDeals() {
		return this.requireDeals;
	}

	/**
	 * @returns {Number}
	 */
	getTechnologyId() {
		return this.technologyId;
	}

	/**
	 * @returns {Boolean}
	 */
	isInheritParentAuction() {
		return this.inheritParentAuction;
	}
}

export default DemandPartner;
