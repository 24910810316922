function OnlyAdminNotBeta(AuthService) {
	'ngInject';

	return {
		priority: 1000,
		compile: function compile(tElement) {
			if (!AuthService.isAdmin() || AuthService.hasBetaAccess()) {
				tElement.remove();
			} else {
				tElement.removeAttr('only-admin-not-beta');
				tElement.removeAttr('data-only-admin-not-beta');
			}
		},
	};
}

export default OnlyAdminNotBeta;
