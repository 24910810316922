function NumberIfNumberFilter($filter) {
	'ngInject';

	return function numberIfNumber(value) {
		if (angular.isNumber(value)) {
			return $filter('number')(value);
		}

		return value;
	};
}

export default NumberIfNumberFilter;
