const FIRST_PRICE_ID = 1;
const SECOND_PRICE_ID = 0;

const FIRST_PRICE_STRING = 'first_price';
const SECOND_PRICE_STRING = 'second_price';

const ID_TO_STRING = new Map([
	[FIRST_PRICE_ID, FIRST_PRICE_STRING],
	[SECOND_PRICE_ID, SECOND_PRICE_STRING],
]);

const STRING_TO_ID = new Map([
	[FIRST_PRICE_STRING, FIRST_PRICE_ID],
	[SECOND_PRICE_STRING, SECOND_PRICE_ID],
]);

class AuctionType {
	/**
	 * @param {number} id
	 */
	constructor(id) {
		if (!ID_TO_STRING.has(id)) {
			throw new Error(`invalid auctionType id ${id}`);
		}
		this.id = id;
	}

	/**
	 * @param {string} auctionTypeString
	 * @return {AuctionType}
	 */
	static fromString(auctionTypeString) {
		if (!STRING_TO_ID.has(auctionTypeString)) {
			throw new Error(`invalid auctionTypeString ${auctionTypeString}`);
		}

		return new AuctionType(STRING_TO_ID.get(auctionTypeString));
	}

	toString() {
		return ID_TO_STRING.get(this.id);
	}
}

export const SECOND_PRICE = AuctionType.fromString(SECOND_PRICE_STRING);

export default AuctionType;
