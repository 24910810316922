import MatomoService from './matomo.service.js';

function MatomoProvider(trackingConfig) {
	'ngInject';

	this.setSource = (source) => {
		trackingConfig.source = source;
		return this;
	};

	this.$get = MatomoService;
}

export default MatomoProvider;
