class Site {
	/**
	 * @type {int}
	 */
	id = 0;

	/**
	 * @type {string}
	 */
	name = '';

	/**
	 * @type {Array.<int>}
	 */
	adslotGroupIds = [];

	/**
	 * @type {boolean}
	 */
	readOnly = false;

	/**
	 * @param {Object} [data]
	 */
	constructor(data = {}) {
		// init
		Object.keys(data).forEach((key) => {
			if (key in this) {
				this[key] = data[key];
			}
		});

		/**
		 * mark default site as read only
		 * @type {boolean}
		 */
		this.readOnly = this.name === 'Default';
	}

	/**
	 * @returns {int}
	 */
	getGroupCount() {
		return this.adslotGroupIds.length;
	}

	/**
	 * @param {int} groupId
	 */
	addGroup(groupId) {
		this.adslotGroupIds.push(groupId);
	}
}

export default Site;
