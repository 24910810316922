import angular from 'angular';
import toaster from 'angularjs-toaster';

import Location from './Location.js';
import FilterBypass from './FilterBypass.js';
import ngBlurDecoration from './ngBlurred.js';
import ngFocusDecoration from './ngFocussed.js';
import RootScope from './RootScope.js';
import ToasterDecoration from './toaster.js';

const AngularDecorationsModule = angular // eslint-disable-line angular/no-service-method
	.module(
		'app.modules.decorations',
		[toaster],
		[
			'$provide',
			($provide) => {
				'ngInject';

				$provide.decorator('filterFilter', FilterBypass);
				$provide.decorator('$location', Location);
				// Suffix is "Directive" for decorating directives.
				$provide.decorator('ngBlurDirective', ngBlurDecoration);
				$provide.decorator('ngFocusDirective', ngFocusDecoration);
				$provide.decorator('$rootScope', RootScope);
				$provide.decorator('toaster', ToasterDecoration);
			},
		],
	).name;

export default AngularDecorationsModule;
