import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

import InventoryAdslotsModule from './inventory-adslots/inventory-adslots.module.js';
import InventoryAdtagsModule from './inventory-adtags/inventory-adtags.module.js';
import InventoryChannelsModule from './inventory-channels/inventory-channels.module.js';
import InventoryStructureModule from './inventory-structure/inventory-structure.module.js';

import InventoryController from './inventory.controller.js';

import InventoryConfig from './inventory.config.js';

import viewNavigationHtml from '../../views/adslots/includes/view_navigation.html';

const InventoryModule = angular
	.module('inventory', [uiRouter, InventoryAdslotsModule, InventoryAdtagsModule, InventoryChannelsModule, InventoryStructureModule])
	.controller('AbstractInventoryController', InventoryController)
	.directive('adslotsViewNavigation', () => ({
		scope: false,
		template: viewNavigationHtml,
	}))
	.config(['$stateProvider', InventoryConfig]).name;

export default InventoryModule;
