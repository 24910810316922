import isEqual from 'lodash/isEqual.js';

import NativeTemplate from '../../../../models/NativeTemplate.js';
import NativeAssetFactory from '../../../../models/NativeAssetFactory.js';
import { assetTypeSelectOptions } from '../../../../models/NativeAsset.js';
import { imageFormatSelectOptions } from '../../../../models/NativeAssetImage.js';

function AdslotNativeTemplateController(assignedTemplate, resolvedAdslot, $scope, $timeout, $q, Adslots, InfoService, NativeTemplates, toaster, $translate) {
	'ngInject';

	const vm = this;

	this.$onInit = () => {
		if (assignedTemplate) {
			updateTemplateUsageInfoAsync(assignedTemplate.id);
		}
	};

	vm.sortableAssetsConfig = {
		animation: 350,
		handle: '.asset-draggable-handle',
	};

	vm.templates = NativeTemplates.getAsSelectOptionsList();

	vm.assetTypes = assetTypeSelectOptions;

	vm.imageFormatOptions = imageFormatSelectOptions;

	// Might be undefined in case of no template assigned to the Adslot yet.
	vm.initialTemplate = assignedTemplate ? assignedTemplate.clone() : assignedTemplate;
	vm.template = assignedTemplate;

	// Sorting (dragging) is disabled for now. Will be enabled when more asset types are available in the UI.
	vm.hasMultipleAssetsEnabled = () => false;

	vm.canAddAssets = () => vm.template.assets.length < 20;

	vm.onSelectTemplate = (templateId) => {
		vm.template = templateId ? NativeTemplates.getById(templateId).clone() : getEmptyTemplate();

		updateTemplateUsageInfoAsync(templateId);

		vm.form.$setPristine();
	};

	vm.onClickAssignTemplate = () => {
		vm.template = getEmptyTemplate();
	};

	vm.onSelectAssetType = (uiType, idx) => {
		vm.template.setAsset(idx, NativeAssetFactory.createFromUiType(uiType));

		// needed when asset forms can have different "sub types" as e.g. main image and icon image
		if (vm.imageAssetDimensionForm[idx]) {
			vm.imageAssetDimensionForm[idx].$setPristine();
		}
	};

	vm.addAsset = () => vm.template.addAsset(NativeAssetFactory.iconImage());
	vm.removeAsset = (idx) => vm.template.removeAsset(idx);

	vm.save = () => {
		const { template, initialTemplate } = vm;

		const nativeTemplateIdSet = template && !initialTemplate;
		const nativeTemplateIdChanged = template && initialTemplate && template.id !== initialTemplate.id;
		const nativeTemplateConfigChanged = template.id && !isEqual(template.toObject(), NativeTemplates.getById(template.id).toObject());

		if (!(nativeTemplateIdSet || nativeTemplateIdChanged || nativeTemplateConfigChanged)) {
			vm.form.$setPristine();
			return;
		}

		InfoService.startSaveRequest();
		persistTemplateConfiguration(template)
			.then(persistAdslotTemplateConfiguration)
			.finally(() => InfoService.endRequest());
	};

	vm.delete = () => {
		deleteAdslotTemplateConfiguration(vm.template);
	};

	vm.templateIsOnlyUsedForCurrentAdslot = (templateId) => {
		return vm.templateUsedCount === 1 && vm.initialTemplate && templateId === vm.initialTemplate.id;
	};

	function getEmptyTemplate() {
		return new NativeTemplate().addAsset(NativeAssetFactory.iconImage());
	}

	/**
	 * @param {NativeTemplate} template
	 * @returns {Promise<NativeTemplate>}
	 */
	function persistTemplateConfiguration(template) {
		let apiOperation;

		// No id set? -> new template
		if (!template.id) {
			apiOperation = NativeTemplates.create(template);
			// Existing template - was it modified?
		} else if (!isEqual(template.toObject(), NativeTemplates.getById(template.id).toObject())) {
			apiOperation = NativeTemplates.update(template);

			// Template unchanged -> pass it on, it might be a different one than the one previously assigned to that Adslot
		} else {
			return $q.when(template);
		}

		return apiOperation
			.then((returnedTemplate) => {
				// Template changes are persisted, now update the list in the UI
				vm.templates = NativeTemplates.getAsSelectOptionsList();

				toaster.successMessage('MESSAGE.NATIVE_TEMPLATE.SUCCESS_TEMPLATE_SAVED');

				return returnedTemplate;
			})
			.catch(({ code }) => {
				let toastMsg = 'MESSAGE.NATIVE_TEMPLATE.ERROR_DURING_SAVE';

				if (code === NativeTemplates.ERROR.DUPLICATE_NAME) {
					vm.form.templateName.$invalid = true;
					toastMsg = 'MESSAGE.NATIVE_TEMPLATE.ERROR_NAME_EXISTS';
				}

				toaster.errorMessage(toastMsg);
			});
	}

	function deleteAdslotTemplateConfiguration(template) {
		$translate('MESSAGE.DELETE_PRESET_CONFIRMATION').then((confirmationText) => {
			// eslint-disable-next-line no-alert, no-restricted-globals
			if (confirm(confirmationText + '\n' + template.name)) {
				NativeTemplates.delete(template)
					.then(() => {
						toaster.successMessage('MESSAGE.ADSLOT_SAVE_SUCCESS');
						toaster.successMessage('MESSAGE.NATIVE_TEMPLATE.SUCCESS_TEMPLATE_DELETED');
						resetTemplates();
					})
					.catch(() => {
						toaster.successMessage('MESSAGE.ADSLOT_SAVE_ERROR');
						toaster.errorMessage('MESSAGE.NATIVE_TEMPLATE.ERROR_DURING_DELETION');
					});
			}
		});
	}

	/**
	 * @param {NativeTemplate} template
	 * @returns {Promise<NativeTemplate|undefined>}
	 */
	function persistAdslotTemplateConfiguration(template) {
		const { id: nativeTemplateId } = template;
		if (!vm.initialTemplate || nativeTemplateId !== vm.initialTemplate.id) {
			return Adslots.update(resolvedAdslot.id, { nativeTemplateId })
				.then((adslot) => {
					$scope.methods.setScopeAdslot(adslot);

					// Adslot template connection persisted -> show new template as connected
					vm.template = template.clone();
					vm.initialTemplate = template.clone();

					updateTemplateUsageInfoAsync(nativeTemplateId);

					toaster.successMessage('MESSAGE.ADSLOT_SAVE_SUCCESS');
				})
				.catch(() => toaster.errorMessage('MESSAGE.ADSLOT_SAVE_ERROR'));
		}
		return $q.when();
	}

	/**
	 * @param {Number} templateId
	 * @returns {void}
	 */
	function updateTemplateUsageInfoAsync(templateId) {
		// Use $timeout to (potentially) unblock the UI flow (or prevent it from lagging)
		$timeout(() => {
			if (templateId) {
				vm.templateUsedCount = Adslots.getList().reduce((usedCount, { nativeTemplateId }) => usedCount + (nativeTemplateId === templateId ? 1 : 0), 0);

				if (vm.templateUsedCount >= 1) {
					vm.templateUsedText = 'TEXT.NATIVE_TEMPLATE.USAGE_X_TIMES';
				} else {
					vm.templateUsedText = 'TEXT.NATIVE_TEMPLATE.USAGE_NONE';
				}

				if (vm.templateIsOnlyUsedForCurrentAdslot(templateId)) {
					vm.templateUsedText = 'TEXT.NATIVE_TEMPLATE.USAGE_HERE';
				}
			} else {
				vm.templateUsedCount = 0;
				vm.templateUsedText = '';
			}
		});
	}

	function resetTemplates() {
		vm.templates = NativeTemplates.getAsSelectOptionsList();
		vm.templateUsedCount = 0;
		vm.templateUsedText = '';
		vm.template = undefined;
		vm.initialTemplate = undefined;
		vm.form.$setPristine();
	}
}

export default AdslotNativeTemplateController;
