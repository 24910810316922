function PartnershipNewController($rootScope, close, Account, DemandPartners, ModalCloser, Partnerships, toaster, InfoService, AuthService) {
	'ngInject';

	const vm = this;

	let defaultTypes = [
		{
			id: 0,
			text: 'CAPTION.PRIVATE_AUCTION',
		},
	];

	const dealTypes = [
		{
			id: 1,
			text: 'PLACEHOLDER.DIRECT_DEAL',
		},
		{
			id: 3,
			text: 'PLACEHOLDER.PREFERRED_DEAL',
		},
		{
			id: 2,
			text: 'LABEL.PROGRAMMATIC_GUARANTEED',
		},
	];

	if (Account.mustOnlyCreateDealPartnerships()) {
		// auto-configured direct connect accounts should only have deals so type private auction can be removed
		defaultTypes = [];
	}

	function triggerClose(result, delay) {
		ModalCloser.close(close, result, delay);
	}

	vm.demandSelectOptions = DemandPartners.getAsSelectOptionsList();
	vm.typeSelectOptions = defaultTypes.concat(dealTypes);

	if (Account.mustOnlyCreateDealPartnerships()) {
		// auto-configured direct connect accounts should only have deals so all demand partners without deal support can be removed
		vm.demandSelectOptions = vm.demandSelectOptions.filter(function onlyWithDealSupport(demandPartner) {
			return DemandPartners.getById(demandPartner.id).hasDeals();
		});
	}

	vm.success = false;

	vm.partnerId = '';
	vm.partnershipType = vm.typeSelectOptions[0].id;
	vm.partnershipName = '';
	vm.seatId = 0;

	vm.selectDemandPartner = function selectDemandPartner(partnerId) {
		const demandPartner = DemandPartners.getById(partnerId) || {};

		if (partnerId && demandPartner.isRequireDeals()) {
			vm.typeSelectOptions = dealTypes;
			vm.partnershipType = vm.partnershipType === 0 ? 1 : vm.partnershipType; // if was private auction before set to deal
		} else if (!partnerId || demandPartner.hasDeals()) {
			vm.typeSelectOptions = defaultTypes.concat(dealTypes);
		} else {
			vm.typeSelectOptions = defaultTypes;
			vm.partnershipType = vm.partnershipType !== 0 ? 0 : vm.partnershipType; // if was deal type before set to private auction
		}

		vm.seatId = demandPartner.seatId || 0;
	};

	vm.createPartnership = function createPartnership() {
		if (AuthService.isAdmin() && !InfoService.isRequestInProgress()) {
			InfoService.startRequest();
			Partnerships.add(vm.partnershipName, vm.partnerId, vm.partnershipType)
				.then(function success(createdPartnership) {
					$rootScope.$emit(Partnerships.EVENTS.PARTNERSHIP_UPDATED);
					vm.success = true;
					toaster.successMessage('MESSAGE.PARTNERSHIP_CREATED', {
						onHideCallback: function onHideCallback() {
							triggerClose(createdPartnership, 200);
						},
						timeout: 2500,
					});
				})
				.catch(function error(errorObject) {
					if (errorObject.code === Partnerships.ERROR.AUTO_CONFIGURED_ONLY_DEAL) {
						toaster.errorMessage('MESSAGE.PARTNERSHIP_SAVE_ERROR_AUTO_CONFIGURED_ONLY_DEAL');
					} else {
						toaster.errorMessage('MESSAGE.PARTNERSHIP_CREATE_ERROR');
					}
				})
				.finally(InfoService.endRequest.bind(InfoService));
		}
	};

	vm.cancel = function cancel() {
		triggerClose(undefined, 200);
	};
}

export default PartnershipNewController;
