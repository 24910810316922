import { format, isEqual as isEqualDate, isValid, startOfDay, parse } from 'date-fns';
import de from 'date-fns/locale/de';
import enGB from 'date-fns/locale/en-GB';
import Flatpickr from 'flatpickr';
import { German } from 'flatpickr/dist/l10n/de.js';
import English from 'flatpickr/dist/l10n/default.js';
import isEqual from 'lodash/isEqual.js';
import isUndefined from 'lodash/isUndefined.js';

const dateFnsLocales = { de, en: enGB };
const flatpickrLocales = { de: German, en: { ...English, firstDayOfWeek: 1 } }; // always start on Monday
const dateFnsLocaleFormatStrings = { de: 'dd.MM.yyyy', en: 'dd/MM/yyyy' };
const localeReplacementRegExps = { de: /[^\d.]/g, en: /[^\d/]/g };
const localeSeparators = { de: '.', en: '/' };

function FlatpickrDirective($parse, $rootScope, $translate) {
	'ngInject';

	return {
		require: 'ngModel',
		restrict: 'A',
		scope: {
			ngModel: '=',
			ylDatepicker: '&',
			onLoad: '&',
		},
		link(scope, element, attrs, ngModel) {
			const getFormatFn = (localeStr) => (date, formatString) => format(date, formatString, { locale: dateFnsLocales[localeStr] });

			const parseDate = (dateStr) => {
				const baseDate = startOfDay(new Date());
				return parse(dateStr, dateFnsLocaleFormatStrings[$translate.use()], baseDate);
			};

			const getDefaultOptions = (localeString) => ({
				disableMobile: true,
				allowInput: true,
				ariaDateFormat: 'P',
				dateFormat: 'P',
				formatDate: getFormatFn(localeString),
				locale: flatpickrLocales[localeString],
				onChange: (selectedDates) => {
					/*
					 * In case of pressing DEL or BACKSPACE on the input element the selected date gets cleared.
					 * We don't want that so we work around that issue by setting the currently set model value to
					 * the pickr again.
					 */
					if (selectedDates.length === 0 && isValid(ngModel.$modelValue)) {
						ngModel.$setViewValue(pickr.formatDate(ngModel.$modelValue, pickr.config.dateFormat));
						ngModel.$render();
					}
				},
				defaultDate: $parse(attrs.ngModel)(scope.$parent),
			});

			// Define this specific listener right up here to register it _before_ the blur handler from flatpickr
			// that is attached during initialization.
			const preventFlatpickrBlurHandlingHandler = (event) => {
				event.stopImmediatePropagation(); // do not trigger flatpickrs parseDate functionality
			};

			element.on('blur', preventFlatpickrBlurHandlingHandler);

			const initFlatpickr = (locale) =>
				new Flatpickr(element[0], {
					...getDefaultOptions(locale),
					...scope.ylDatepicker(),
				});

			let pickr = initFlatpickr($translate.use());

			if (scope.onLoad) {
				scope.onLoad({
					fpItem: pickr,
				});
			}

			ngModel.$validators.date = (modelValue) => !attrs.required || isValid(modelValue);

			ngModel.$parsers.push((dateStr) => {
				let dateStrToProcess = dateStr.replace(localeReplacementRegExps[$translate.use()], '').trim();

				let caretPosition = element[0].selectionStart;

				// in case of replaced/updated input reset the view and keep the cursor where it was
				if (dateStr !== dateStrToProcess) {
					ngModel.$setViewValue(dateStrToProcess);
					ngModel.$render();

					element[0].setSelectionRange(caretPosition, caretPosition - 1); // do not move the cursor
				}

				// automatically add separators
				if (
					// when we enter a number at their positions
					(dateStrToProcess.length === 3 || dateStrToProcess.length === 6) &&
					// and no separator is entered
					dateStrToProcess.lastIndexOf(localeSeparators[$translate.use()]) !== dateStrToProcess.length - 1
				) {
					dateStrToProcess =
						dateStrToProcess.slice(0, dateStrToProcess.length - 1) + localeSeparators[$translate.use()] + dateStrToProcess.slice(dateStrToProcess.length - 1);
					caretPosition += 1;

					ngModel.$setViewValue(dateStrToProcess);
					ngModel.$render();

					element[0].setSelectionRange(caretPosition, caretPosition); // move the cursor
				}

				// restrict input length to 10 characters
				if (dateStrToProcess.length > 10) {
					dateStrToProcess = dateStrToProcess.slice(0, 10);
					ngModel.$setViewValue(dateStrToProcess);
					ngModel.$render();

					// when not at the end of the input reposition the cursor
					if (caretPosition <= 10) {
						element[0].setSelectionRange(caretPosition, caretPosition);
					}
				}

				// only parse complete dates
				if (dateStrToProcess.length === 10) {
					const parsedDate = parseDate(dateStrToProcess);
					const minDate = pickr.config.minDate;
					const maxDate = pickr.config.maxDate;

					if (isValid(parsedDate) && (isUndefined(minDate) || parsedDate >= minDate) && (isUndefined(maxDate) || parsedDate <= maxDate)) {
						if (!isEqualDate(parsedDate, ngModel.$modelValue)) {
							pickr.setDate(parsedDate);
							element[0].setSelectionRange(caretPosition, caretPosition); // move the cursor
						}
						return parsedDate;
					}
				}

				// reset pickr to today view but don't clear the input
				pickr.selectedDates = [];
				pickr.jumpToDate(new Date());

				// model value erroneous
				return null;
			});

			ngModel.$formatters.push((date) => {
				if (isValid(date)) {
					pickr.setDate(date);
					return pickr.formatDate(date, pickr.config.dateFormat);
				}
				return date;
			});

			scope.$watch(
				() => scope.ylDatepicker(),
				(newConfig, oldConfig) => {
					if (!isEqual(newConfig, oldConfig)) {
						const currentlySetDate = pickr.selectedDates[0];

						pickr.set('minDate', newConfig.minDate);
						pickr.set('maxDate', newConfig.maxDate);

						/**
						 * Hack!
						 * The problem here is, that the $watch() and update of the min/max date settings comes
						 * _after_ the model has been updated by angular and therefore also been processed by flatpickr
						 * which does not allow the date to be set properly due to the fact that the maxDate value is
						 * still not changed yet. In this case we assume that the $modelValue is correctly set and we
						 * just tell that to flatpickr once again.
						 * Looking forward to the day when this bites us again...
						 */
						if (isUndefined(currentlySetDate) && isValid(ngModel.$modelValue)) {
							pickr.setDate(ngModel.$modelValue, true);
						}
					}
				},
				true,
			);

			const keydownEscapeEnterHandler = (event) => {
				// on Enter close and stop bubbling
				if (event.keyCode === 13) {
					pickr.close();
					event.stopImmediatePropagation(); // do not trigger flatpickrs parseDate functionality
					event.preventDefault(); // do not trigger browser form submit
					element.blur();
				}

				// on Escape just close
				if (event.keyCode === 27) {
					pickr.close();
				}
			};

			element.on('keydown', keydownEscapeEnterHandler);

			// destroy the flatpickr instance when the dom element is removed
			element.on('$destroy', () => {
				pickr.destroy();
			});

			const unregisterTranslateChangeListener = $rootScope.$on('$translateChangeSuccess', (event, params) => {
				pickr.set('formatDate', getFormatFn(params.language));
				pickr.set('locale', flatpickrLocales[params.language]);
			});

			// clean rootScope listener
			scope.$on('$destroy', () => {
				unregisterTranslateChangeListener();
				pickr.destroy();
				element.off('keydown', keydownEscapeEnterHandler);
				element.off('blur', preventFlatpickrBlurHandlingHandler);
			});
		},
	};
}

export default FlatpickrDirective;
