function NoDrag() {
	return {
		restrict: 'A',
		scope: false,
		link: function postLink(scope, iElement) {
			var unbindDestroyListener;

			iElement.on('dragstart', function dragStart(event) {
				event.preventDefault();
				event.stopImmediatePropagation();
			});

			unbindDestroyListener = scope.$on('$destroy', function $destroyListener() {
				iElement.off('dragstart');
				unbindDestroyListener();
			});
		},
	};
}

export default NoDrag;
