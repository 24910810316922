function ChannelsService(Api, $rootScope, Channel) {
	'ngInject';

	/**
	 * @type {{[String]: Channel}}
	 */
	this.channels = {};

	/**
	 * @type {Object.<string, int>}
	 */
	this.ERROR = {
		NAME_EXISTS: 10007,
		NAME_INVALID: 10008,
	};

	this.WITHOUT_CHANNELS_ID = 'no-channels';

	this.WITOUT_CHANNELS_FILTER_ENTRY = {
		id: this.WITHOUT_CHANNELS_ID,
		name: 'CAPTION.NO_CHANNELS',
	};

	/**
	 * loads all channels data from api
	 *
	 * @return {promise}
	 */
	this.loadAll = () =>
		Api.get('/api/supply/v1/channels').then((data) => {
			this.channels = data.channels.reduce((channelMap, channelData) => {
				channelMap[channelData.id] = new Channel(channelData);
				return channelMap;
			}, {});
		});

	/**
	 * @param {int} channelId
	 * @param {string} name
	 * @param {Array.<int>} adslots
	 * @returns {promise}
	 */
	this.update = (channelId, name, adslots) =>
		Api.post(`api/supply/v1/channels/${channelId}`, { name: name, websites: adslots }, true).then((channel) => {
			this.channels[channel.id] = new Channel(channel);

			$rootScope.$emit('Channels::updated');
		});

	/**
	 * @param {string} name
	 * @param {Array.<int>} adslots
	 * @returns {promise}
	 */
	this.add = (name, adslots) =>
		Api.post('/api/supply/v1/channels/add', { name: name, websites: adslots }).then((channel) => {
			this.channels[channel.id] = new Channel(channel);

			$rootScope.$emit('Channels::updated');
		});

	/**
	 * @returns {Object.<int, Channel>}
	 */
	this.getMap = () => this.channels;

	/**
	 * @param {int} id
	 * @returns {Channel}
	 */
	this.getById = (id) => this.channels[id];

	/**
	 * @returns {Array.<Channel>}
	 */
	this.getList = () => Object.values(this.channels);

	/**
	 * @returns {Array.<{name: <string>, value: <number|string>}>}
	 */
	this.getIdNameSelectOptions = () =>
		Object.entries(this.channels).map(([id, channel]) => ({
			name: channel.name,
			value: parseInt(id, 10),
		}));

	/**
	 * @param {Array.<int>} channelIds
	 * @returns {string}
	 */
	this.getNamesForIds = (channelIds) =>
		channelIds
			.reduce((channelNames, channelId) => {
				if (this.channels[channelId]) {
					channelNames.push(this.channels[channelId].name);
				}
				return channelNames;
			}, [])
			.join(', ');
}

export default ChannelsService;
