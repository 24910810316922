import { ascByNameField } from '../../utils/sort-utils.js';

function PricingPartnershipAdslotsController(
	$scope,
	Adslots,
	AdslotGroups,
	AdslotTranslations,
	Channels,
	PartnershipAdslots,
	InfoService,
	ListFilterFactory,
	ListFilterSettings,
	ListSearchService,
	Sites,
	toaster,
	resolvedAdslots,
	resolvedPrices,
	currentPartnership,
) {
	'ngInject';

	$scope.models.partnershipAdslots = resolvedAdslots;

	const listFilterId = `pricing-partnership-adslots-filter-${currentPartnership.id}`;

	$scope.models.formatFilterOptions = Adslots.getFormatList().map(function formatStringToObject(format) {
		return {
			id: format,
			name: format,
		};
	});

	$scope.models.partnershipAdslotsFilters = ListFilterFactory.get(ListFilterFactory.LAYOUTS.ADSLOTS.LIST).load(ListFilterSettings.get(listFilterId));

	// update potentially changed entry data
	$scope.models.partnershipAdslotsFilters.getGroup('LABEL.SITE').getEntry('siteId').setEntryData(Sites.getAsIdNameObjectsList());
	$scope.models.partnershipAdslotsFilters.getGroup('LABEL.GROUP').getEntry('groupId').setEntryData(AdslotGroups.getAsIdNameObjectsList());
	$scope.models.partnershipAdslotsFilters
		.getGroup('LABEL.ADSLOT_CHANNEL')
		.getEntry('channelId')
		.setEntryData([Channels.WITOUT_CHANNELS_FILTER_ENTRY].concat(Channels.getList().sort(ascByNameField)));
	$scope.models.partnershipAdslotsFilters.getGroup('LABEL.ADSLOT_FORMAT').getEntry('formatId').setEntryData($scope.models.formatFilterOptions);

	$scope.models.matchesFilter = $scope.models.partnershipAdslotsFilters.matchesFilter.bind($scope.models.partnershipAdslotsFilters);

	$scope.models.partnershipAdslotsSearch = ListSearchService.get('pricing-partnership-adslots-search-' + currentPartnership.id);

	$scope.models.subListSortKey = 'name';
	$scope.currentPartnership = currentPartnership;

	$scope.models.form = $scope.methods.initializeForm($scope.models.partnershipAdslots, resolvedPrices);
	$scope.models.invalidPrices = {};

	$scope.methods.addPricesToList($scope.models.partnershipAdslots, $scope.models.form);

	$scope.save = function save() {
		var prices = $scope.methods.getChangedPrices();

		if (prices.length) {
			InfoService.startRequest();

			PartnershipAdslots.updatePrices(currentPartnership.id, prices)
				// handle invisible Adslots first
				.then((updatedAdslotPricesList) => updatedAdslotPricesList.filter(({ id: adslotId }) => Adslots.getById(adslotId).visible))
				// then proceed easily
				.then((updatedAdslotPricesList) => {
					// set current form as initial state
					// extend initially loaded prices with the currently updated ones to have all prices correct
					$scope.models.form = $scope.methods.initializeForm($scope.models.partnershipAdslots, PartnershipAdslots.getPriceMap(updatedAdslotPricesList));
					$scope.methods.addPricesToList($scope.models.partnershipAdslots, $scope.models.form);

					$scope.methods.postSaveActions();

					InfoService.endRequest();
					toaster.successMessage('MESSAGE.SETTINGS_SAVE_SUCCESS');
				})
				.catch(function error() {
					InfoService.endRequest();
					toaster.errorMessage('MESSAGE.SETTINGS_SAVE_ERROR');
				});
		}
	};

	$scope.methods.initializeModels();

	const removeDestroyListener = $scope.$on('$destroy', function $destroyListener() {
		ListFilterSettings.set(listFilterId, $scope.models.partnershipAdslotsFilters.getActiveFilterData());

		removeDestroyListener();
	});
}

export default PricingPartnershipAdslotsController;
