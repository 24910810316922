function StructureBreadcrumb(AdslotGroups, Adslots, Sites, $translate) {
	'ngInject';

	/**
	 * @type {{name: string, opts: {}, text: string}}
	 */
	this.site = {};

	/**
	 * @type {{name: string, opts: {}, text: string}}
	 */
	this.group = {};

	this.getCrumbs = function getCrumbs() {
		var crumbs = [];

		if (!angular.equals(this.site, {})) {
			crumbs.push(this.site);
		}

		if (!angular.equals(this.group, {})) {
			crumbs.push(this.group);
		}

		return crumbs;
	};

	this.viewSite = function viewSite(id) {
		this.group = {};
		this.site = {
			name: 'structure.sites.groups',
			opts: {
				siteId: id,
			},
			text: Sites.getById(id).name + ' (' + $translate.instant('LABEL.SITE_ID') + ' ' + id + ')',
		};
	};

	this.viewGroup = function viewGroup(id) {
		var siteId = AdslotGroups.getById(id).siteId;
		this.viewSite(siteId);
		this.group = {
			name: 'structure.sites.groups.adslots',
			opts: {
				groupId: id,
				siteId: siteId,
			},
			text: AdslotGroups.getById(id).name + ' (' + $translate.instant('LABEL.GROUP_ID') + ' ' + id + ')',
		};
	};

	this.viewAdslot = function viewAdslot(id) {
		this.viewGroup(Adslots.getById(id).groupId);
	};

	this.reset = function reset() {
		this.site = {};
		this.group = {};
	};
}

export default StructureBreadcrumb;
