function PricingAdvertisersController($scope, Advertisers, AdvertisersPrices, InfoService, ListSearchService, toaster) {
	'ngInject';

	$scope.models.advertisers = Advertisers.getList();
	$scope.models.advertisersFilter = ListSearchService.get('pricing-advertisers-search');
	$scope.models.listSortKey = 'name';

	$scope.models.partnershipsSortKey = undefined;
	$scope.models.adslotsSortKey = undefined;

	$scope.models.form = $scope.methods.initializeForm($scope.models.advertisers, AdvertisersPrices.getMap());
	$scope.models.invalidPrices = {};

	$scope.methods.addPricesToList($scope.models.advertisers, $scope.models.form);

	$scope.save = function save() {
		var prices = $scope.methods.getChangedPrices();

		if (prices.length) {
			InfoService.startRequest();

			AdvertisersPrices.update(prices)
				.then(function success() {
					// set current form as initial state
					// extend initially loaded prices with the currently updated ones to have all prices correct
					$scope.models.form = $scope.methods.initializeForm(
						$scope.models.advertisers,
						prices.reduce(function reduce(priceMap, priceObject) {
							priceMap[priceObject.id] = {
								floorPrice: priceObject.floorPrice,
								fixedPrice: priceObject.fixedPrice,
							};
							return priceMap;
						}, AdvertisersPrices.getMap()),
					);
					$scope.methods.addPricesToList($scope.models.advertisers, $scope.models.form);

					$scope.methods.postSaveActions();

					InfoService.endRequest();
					toaster.successMessage('MESSAGE.SETTINGS_SAVE_SUCCESS');
				})
				.catch(function error() {
					InfoService.endRequest();
					toaster.errorMessage('MESSAGE.SETTINGS_SAVE_ERROR');
				});
		}
	};

	$scope.methods.initializeModels();
}

export default PricingAdvertisersController;
