import angular from 'angular';

import InventoryChannelsController from './inventory-channels.controller.js';
import InventoryChannelsEditController from './inventory-channels-edit.controller.js';
import InventoryChannelsNewController from './inventory-channels-new.controller.js';

import InventoryChannelsService from './inventory-channels.service.js';

const InventoryChannelsModule = angular // eslint-disable-line angular/no-service-method
	.module('inventory.channels', [])
	.controller('AdslotsChannelsController', InventoryChannelsController)
	.controller('AdslotsChannelsEditController', InventoryChannelsEditController)
	.controller('AdslotsChannelsNewController', InventoryChannelsNewController)
	.service('Channels', InventoryChannelsService).name;

export default InventoryChannelsModule;
