function ClearInput($animate, $compile, $parse) {
	'ngInject';

	var CLEAR_INPUT_WRAPPER_CLASS = 'clear-input',
		CLEAR_INPUT_BUTTON_CLASS = 'clear-input-button',
		CLEAR_INPUT_BUTTON_HIDDEN_CLASS = 'clear-input-button-hidden',
		CLEAR_INPUT_BUTTON_ICON_DEFAULT_CLASS = 'icon-erase',
		CLEAR_INPUT_HAS_SORT_CLASS = 'has-sort';

	return {
		restrict: 'A',
		require: 'ngModel',
		scope: false,
		link: function postLink(scope, iElement, iAttrs, ngModelCtrl) {
			var unbindDestroyListener,
				unbindModelWatch,
				sortElement,
				initialModelValue = $parse(iAttrs.ngModel)(scope),
				wrapper = angular.element('<div></div>'),
				clearBtn = angular.element('<i class="yl-animate"></i>'),
				sortKey = iAttrs.sortKey,
				sortModel = iAttrs.sortModel;

			if (!(initialModelValue && initialModelValue.length > 0)) {
				clearBtn.addClass(CLEAR_INPUT_BUTTON_HIDDEN_CLASS);
			}

			wrapper.addClass(CLEAR_INPUT_WRAPPER_CLASS);
			iElement.wrap(wrapper);

			clearBtn.addClass(CLEAR_INPUT_BUTTON_CLASS);
			clearBtn.addClass(iAttrs.icon || CLEAR_INPUT_BUTTON_ICON_DEFAULT_CLASS);
			iElement.after(clearBtn);

			if (angular.isDefined(iAttrs.hasSort)) {
				iElement.parent().addClass(CLEAR_INPUT_HAS_SORT_CLASS);

				if (!sortKey || !sortModel) {
					throw new Error('if has-sort is set sort key and model have to be provided.');
				}

				sortElement = angular.element('<span></span>');
				sortElement.attr('data-yl-sort-buttons', sortKey);
				sortElement.attr('data-ng-model', sortModel);
				clearBtn.after(sortElement);
				$compile(clearBtn.next())(scope);
			}

			clearBtn.on('click', function click() {
				ngModelCtrl.$setViewValue('');
				ngModelCtrl.$render();
				scope.$apply();
			});

			unbindModelWatch = scope.$watch(iAttrs.ngModel, function watched(val) {
				if (val && val.length > 0 && clearBtn.hasClass(CLEAR_INPUT_BUTTON_HIDDEN_CLASS)) {
					$animate.removeClass(clearBtn, CLEAR_INPUT_BUTTON_HIDDEN_CLASS);
				} else if (!(val && val.length > 0) && !clearBtn.hasClass(CLEAR_INPUT_BUTTON_HIDDEN_CLASS)) {
					$animate.addClass(clearBtn, CLEAR_INPUT_BUTTON_HIDDEN_CLASS);
				}
			});

			unbindDestroyListener = scope.$on('$destroy', function cleaUp() {
				clearBtn.off('click');
				unbindModelWatch();
				unbindDestroyListener();
			});
		},
	};
}

export default ClearInput;
