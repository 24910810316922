function TotalRowModel(ReportRow) {
	'ngInject';

	/**
	 * TotalRow
	 */
	function TotalRow() {
		this.type = 'total';
	}

	TotalRow.prototype = new ReportRow();
	TotalRow.prototype.constructor = TotalRow;

	return TotalRow;
}

export default TotalRowModel;
