import sizesCellTemplate from '../../../views/partnerships/templates/sizes-cell-template.html';

function PartnershipSizesController(
	$scope,
	$rootScope,
	$filter,
	uiGridConstants,
	toaster,
	Partnerships,
	Adslots,
	Account,
	InfoService,
	selectedPartnership,
	supplyPartnerDimensions,
	resolvedAdslots,
) {
	'ngInject';

	const MAX_ENABLED_SIZES = 1000;

	this.selectedPartnership = selectedPartnership;
	this.supplyPartnerDimensions = supplyPartnerDimensions;
	this.resolvedAdslots = resolvedAdslots;

	this.availableSizes = [];
	this.enabledSizes = [];
	this.availableSizesFilter = '';
	this.enabledSizesFilter = '';

	this.rowHeight = 44;
	this.minRowsToShow = 10;
	this.showOnlyConnectedSizes = false;

	let updateIfAvailableSizesAreSelectable = () => {}; // initialized during onRegisterApi callback

	this.availableGridOptions = {
		showHeader: false,
		enableHorizontalScrollbar: uiGridConstants.scrollbars.NEVER,
		enableRowHeaderSelection: false,
		multiSelect: false,

		columnDefs: [
			{
				field: 'entry',
				cellTemplate: sizesCellTemplate,
			},
		],
		rowHeight: this.rowHeight,
		minRowsToShow: this.minRowsToShow,

		enableRowSelection: this.enabledSizes.length < MAX_ENABLED_SIZES,
		data: [...this.availableSizes],
		onRegisterApi: (gridApi) => {
			updateIfAvailableSizesAreSelectable = updateIfAvailableSizesAreSelectableFn(gridApi, this.availableGridOptions, MAX_ENABLED_SIZES);
			gridApi.selection.on.rowSelectionChanged($scope, onRowSelectionChanged);

			updateIfAvailableSizesAreSelectable();
		},
	};

	const updateIfAvailableSizesAreSelectableFn = (gridApi, gridOptions, limit) => () => {
		const rowSelectionWasEnabledBefore = gridOptions.enableRowSelection;

		gridOptions.enableRowSelection = this.enabledSizes.length < limit;
		if (gridOptions.enableRowSelection !== rowSelectionWasEnabledBefore) {
			gridApi.core.notifyDataChange(uiGridConstants.dataChange.OPTIONS);
		}
	};

	const onRowSelectionChanged = (row) => this.enableSize(row.entity);

	this.canEnableMoreSizes = () =>
		this.enabledSizes && this.enabledSizes.length < MAX_ENABLED_SIZES && this.availableGridOptions.data && this.availableGridOptions.data.length > 0;

	this.canDisableMoreSizes = () => this.filteredEnabledSizes && this.filteredEnabledSizes.length > 0;

	this.noEnabledSizesSaved = () => !this.selectedPartnership.getActiveSizes().length;

	this.noSizesEnabled = () => !this.enabledSizes.length;

	this.allSizesEnabled = () => this.enabledSizes.length === this.supplyPartnerDimensions.length;

	this.updateFilteredAvailableSizes = () => {
		this.availableGridOptions.data = this.availableSizes
			.filter((element) => !this.availableSizesFilter || element.entry.name.toLowerCase().includes(this.availableSizesFilter.toLowerCase()))
			.filter((element) => !this.showOnlyConnectedSizes || element.entry.isConnected);
	};

	this.updateFilteredEnabledSizes = () => {
		this.filteredEnabledSizes = this.enabledSizes.filter(
			(element) => !this.enabledSizesFilter || element.entry.name.toLowerCase().includes(this.enabledSizesFilter.toLowerCase()),
		);
	};

	this.enableSize = (size) => moveSize(size, this.availableSizes, this.enabledSizes);

	this.disableSize = (size) => moveSize(size, this.enabledSizes, this.availableSizes);

	this.enableFilteredSizes = () => {
		const maxEnableableSizes = Math.max(MAX_ENABLED_SIZES - this.enabledSizes.length, 0);
		if (maxEnableableSizes > 0) {
			const sizesToMove = this.availableGridOptions.data.slice(0, maxEnableableSizes);
			moveSizes(sizesToMove, this.availableSizes, this.enabledSizes);
		}
	};

	this.disableFilteredSizes = () => {
		const sizesToMove = this.filteredEnabledSizes;
		moveSizes(sizesToMove, this.enabledSizes, this.availableSizes);
	};

	const moveSize = (size, sourceList, destinationList) => moveSizes([size], sourceList, destinationList);

	const moveSizes = (sizesToMove, sourceList, destinationList) => {
		sizesToMove.forEach((size) => {
			const sizeIndexToBeRemoved = sourceList.findIndex((element) => element.entry.name === size.entry.name);
			const removedSize = sourceList.splice(sizeIndexToBeRemoved, 1)[0];
			destinationList.push(removedSize);
		});

		sortByWidthAndHeight(destinationList);
		this.updateFilteredAvailableSizes();
		this.updateFilteredEnabledSizes();
		updateIfAvailableSizesAreSelectable();
	};

	const sortByWidthAndHeight = (list) => Adslots.sortByWidthAndHeight(list, extractDimensionFn);
	const extractDimensionFn = (element) => element.entry.name.split('x').map(Number);

	this.saveEnabledSizes = () => {
		if (enabledSizesHaveChanged()) {
			InfoService.startRequest();

			const partnershipToUpdate = {
				activeSizes: this.enabledSizes.map((element) => element.entry.name),
			};
			Partnerships.update(this.selectedPartnership.getId(), partnershipToUpdate)
				.then((partnership) => {
					this.selectedPartnership.setActiveSizes(partnership.getActiveSizes());
					$rootScope.$emit(Partnerships.EVENTS.PARTNERSHIP_UPDATED, partnership);
					toaster.successMessage('MESSAGE.PARTNERSHIP_SAVE_SUCCESS');
					InfoService.endRequest();
				})
				.catch((error) => {
					if (Partnerships.ERROR.ACTIVE_SIZES_OVERLAP_EXIST === error.code) {
						handleDuplicatePrivateAuctionsErrors(error);
					} else if (Partnerships.ERROR.DIMENSION_NOT_ALLOWED_FOR_SUPPLY_PARTNER === error.code) {
						const invalidDimension = error.attributes.invalidDimension;
						toaster.errorMessage($filter('translate')('MESSAGE.PARTNERSHIP_SAVE_ERROR_DIMENSION_NOT_ALLOWED', { value: invalidDimension }));
						InfoService.endRequest();
					} else {
						toaster.errorMessage('MESSAGE.PARTNERSHIP_SAVE_ERROR');
						InfoService.endRequest();
					}
				});
		}
	};

	const enabledSizesHaveChanged = () => {
		return (
			this.enabledSizes.length !== this.selectedPartnership.getActiveSizes().length ||
			!this.enabledSizes.every((element) => this.selectedPartnership.getActiveSizes().includes(element.entry.name))
		);
	};

	const handleDuplicatePrivateAuctionsErrors = (error) => {
		const rejectedSizes = [];
		const initialNumberOfSizes = this.enabledSizes.length;

		// filter out rejected sizes
		Object.keys(error.attributes).forEach((key) =>
			error.attributes[key].forEach((value) => {
				const elementIndexToBeRemoved = this.enabledSizes.findIndex((element) => element.entry.name === value);
				if (elementIndexToBeRemoved > -1) {
					rejectedSizes.push(this.enabledSizes.splice(elementIndexToBeRemoved, 1)[0]);
				}
			}),
		);

		// all sizes invalid
		if (this.enabledSizes.length === 0) {
			rejectedSizes.forEach((e) => this.availableSizes.push(e));

			this.availableGridOptions.data = this.availableSizes;
			this.filteredEnabledSizes = this.enabledSizes;

			sortByWidthAndHeight(this.availableSizes);

			toaster.errorMessage('MESSAGE.PARTNERSHIP_SAVE_ERROR_ACTIVE_SIZES_OVERLAP_EXIST');
			InfoService.endRequest();
		} else {
			const partnershipToUpdate = {
				activeSizes: this.enabledSizes.map((element) => element.entry.name),
			};
			Partnerships.update(this.selectedPartnership.getId(), partnershipToUpdate)
				.then((partnership) => {
					this.selectedPartnership.setActiveSizes(partnership.getActiveSizes());
					$rootScope.$emit(Partnerships.EVENTS.PARTNERSHIP_UPDATED, partnership);

					rejectedSizes.forEach((e) => this.availableSizes.push(e));

					this.availableGridOptions.data = this.availableSizes;
					this.filteredEnabledSizes = this.enabledSizes;

					sortByWidthAndHeight(this.availableSizes);

					const numberOfUnsavedDimensions = initialNumberOfSizes - this.enabledSizes.length;
					if (numberOfUnsavedDimensions === 1) {
						toaster.warningMessage('MESSAGE.PARTNERSHIP_SAVE_PARTIAL_SUCCESS_ONE_ACTIVE_SIZE_OVERLAP_EXIST');
					} else {
						toaster.warningMessage(
							$filter('translate')('MESSAGE.PARTNERSHIP_SAVE_PARTIAL_SUCCESS_SOME_ACTIVE_SIZES_OVERLAP_EXIST', { value: numberOfUnsavedDimensions }),
						);
					}
				})
				.catch(() => toaster.errorMessage('MESSAGE.PARTNERSHIP_SAVE_ERROR'))
				.finally(() => InfoService.endRequest());
		}
	};

	this.$onInit = () => {
		setupData();
	};

	const setupData = () => {
		const connectedSizeNames = calculateConnectedSizeNames();

		this.enabledSizes =
			selectedPartnership
				.getActiveSizes()
				.map((size) => createSize(size, connectedSizeNames.has(size)))
				.filter((size) => this.supplyPartnerDimensions.some((availableSize) => availableSize.name === size.entry.name)) || [];

		this.availableSizes =
			this.supplyPartnerDimensions
				.map((availableSize) => availableSize.name)
				.filter((sizeName) => !isSizeEnabled(sizeName))
				.map((sizeName) => createSize(sizeName, connectedSizeNames.has(sizeName))) || [];

		sortByWidthAndHeight(this.availableSizes);
		sortByWidthAndHeight(this.enabledSizes);
		this.updateFilteredAvailableSizes();
		this.updateFilteredEnabledSizes();
	};

	const isSizeEnabled = (sizeName) => this.enabledSizes.find((enabledSize) => enabledSize.entry.name === sizeName);

	const calculateConnectedSizeNames = () => new Set(this.resolvedAdslots.flatMap((adslot) => adslot.formats).map((format) => format.dimension.toString()));

	const createSize = (size, isConnected) => {
		return {
			entry: {
				name: size,
				isConnected: isConnected,
			},
		};
	};
}

export default PartnershipSizesController;
