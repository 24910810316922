function SearchableSelect($compile, $document, $filter, $rootScope, $timeout, $window) {
	'ngInject';

	var SELECT_OPENED_CLASS = 'is-active',
		ELEMENT_ACTIVE_CLASS = 'focus';

	return {
		restrict: 'A',
		require: 'ngModel',
		scope: {},
		bindToController: {
			options: '=searchableSelect',
		},
		controller: function SearchableSelectController() {
			var vm = this;

			vm.selected = '';

			vm.select = function select(option) {
				vm.selected = option.name;
				vm.updateSelected();
				vm.clearSearch();
				vm.closeDropdown();

				if (!vm.modelCtrl.$dirty) {
					vm.modelCtrl.$setDirty();
				}

				vm.modelCtrl.$setViewValue(option.id);
				vm.modelCtrl.$render();
			};

			vm.clearSearch = function clearSearch() {
				vm.search = '';
			};
		},
		controllerAs: '$ctrl',
		link: function postLink(scope, iElement, iAttrs, ngModelCtrl) {
			var activeOption,
				currentList,
				parent,
				unbindDestroyListener,
				unbindTranslateChangeListener,
				wasOpen = false,
				placeholder = $filter('translate')(iAttrs.placeholder || ''),
				container = angular.element('<div class="searchable-select dropdown"></div>'),
				search = angular.element(
					'<input class="search" type="text" data-ng-model="$ctrl.search" data-ng-change="::$ctrl.onSearchChange()" autocomplete="off" data-noclick>',
				),
				selected = angular.element('<a href class="selected" tabindex="0" data-noclick data-nosubmit></a>'),
				dropdown = angular.element('<ul class="vertical menu" data-noclick></ul>'),
				dropdownContainer = angular.element('<div class="dropdown-content"></div>'),
				listElement = angular.element(
					'<li data-ng-repeat="option in $ctrl.filtered = ($ctrl.options | filter: {name: $ctrl.search} | orderBy: \'name\') track by option.id"><a href data-no-drag data-ng-bind="::option.name" data-ng-click="::$ctrl.select(option)" title="{{::option.name}}" tabindex="-1"></a></li>',
				),
				listElementWithAdditional = angular.element(
					'<li data-ng-repeat="option in $ctrl.filtered = ($ctrl.options | filter: $ctrl.searchFn | orderBy: \'name\') track by option.id"><a href data-no-drag data-ng-click="::$ctrl.select(option)" title="{{::option.name}} {{::option[$ctrl.additionalKey]}}" tabindex="-1">{{::option.name}} <small data-ng-bind="::option[$ctrl.additionalKey]"></small></a></li>',
				),
				emptyListElement = angular.element(
					'<li class="hint-text" data-ng-if="!$ctrl.filtered.length && $ctrl.search" data-translate="TEXT.FILTER_FILTERED_NO_RESULTS"></li>',
				);

			function open() {
				var dropdownLeft = dropdownContainer[0].getBoundingClientRect().left,
					windowWidth = $window.innerWidth;

				wasOpen = true;

				angular.element(currentList[scope.$ctrl.active]).removeClass(ELEMENT_ACTIVE_CLASS);
				resetActiveElement();

				parent.addClass(SELECT_OPENED_CLASS);
				search.attr('required', ''); // to make css selectors :valid/:invalid work
				$timeout(function focusSearch() {
					search.focus();
				});

				currentList = dropdown.find('li');

				// take care of auto width to not exceed window
				if (dropdownLeft + dropdownContainer.outerWidth() > windowWidth) {
					dropdownContainer[0].style.maxWidth = windowWidth - dropdownLeft + 'px';
				}

				$document.on('click', documentClickClose);
			}

			function close() {
				wasOpen = false;

				dropdownScrollTop();

				parent.removeClass(SELECT_OPENED_CLASS);
				search.removeAttr('required'); // to make outer form validation not care about the search input
				selected.focus();

				if (!ngModelCtrl.$touched) {
					ngModelCtrl.$setTouched();
				}

				// reset possibly set size restrictions to defaults defined in css
				dropdownContainer[0].style.maxWidth = '';

				$document.off('click', documentClickClose);
			}

			function clearSearch() {
				if (scope.$ctrl.search) {
					scope.$ctrl.clearSearch();
					scope.$apply();
				}
			}

			function resetActiveElement() {
				scope.$ctrl.active = 0;
				activeOption = scope.$ctrl.filtered[scope.$ctrl.active];
				angular.element(currentList[scope.$ctrl.active]).addClass(ELEMENT_ACTIVE_CLASS);
			}

			/**
			 * @param {int} activeIndex
			 */
			function setActiveElementAndScroll(activeIndex) {
				angular.element(currentList[scope.$ctrl.active]).removeClass(ELEMENT_ACTIVE_CLASS);
				scope.$ctrl.active = activeIndex;

				activeOption = scope.$ctrl.filtered[scope.$ctrl.active];
				dropdownScrollToActive(angular.element(currentList[scope.$ctrl.active]).addClass(ELEMENT_ACTIVE_CLASS));
			}

			function dropdownScrollTop() {
				dropdownContainer[0].scrollTop = 0;
			}

			function dropdownScrollToActive(element) {
				var VISIBLE_ELEMENTS = 10,
					elementHeight = element.height(),
					scrollDown = elementHeight * Math.max(-VISIBLE_ELEMENTS + scope.$ctrl.active + 1, 0),
					scrollUp = elementHeight * scope.$ctrl.active;

				if (scrollDown > dropdownContainer[0].scrollTop) {
					dropdownContainer[0].scrollTop = scrollDown;
				} else if (scrollUp < dropdownContainer[0].scrollTop) {
					dropdownContainer[0].scrollTop = scrollUp;
				}
			}

			function documentClickClose() {
				clearSearch();
				close();
			}

			// the original input (when "selected" by label)
			iElement.on('focus', function onOriginalInputFocus() {
				selected.focus();
			});

			// the a tag

			selected.on('mousedown', function mousedownOpen(event) {
				if (event.which === 1) {
					open();
				}
			});

			selected.on('keydown', function arrowKeydownOpen(event) {
				if (event.which === 40) {
					open();
				}
			});

			selected.on('blur', function onSelectedBlur() {
				if (!wasOpen && !ngModelCtrl.$blurred) {
					ngModelCtrl.$blurred = true;
					scope.$apply();
				}
			});

			// the text input

			search.on('keydown', function onKeydown(event) {
				switch (event.which) {
					// tab
					case 9:
						event.preventDefault(); // no tabbing when dropdown is open
						break;
					// enter
					case 13:
						if (scope.$ctrl.filtered.length) {
							scope.$ctrl.select(activeOption);
							event.preventDefault();
							search.blur();
						}
						break;
					// esc
					case 27:
						clearSearch();
						search.blur();
						close();
						break;
					// page up
					case 33:
						setActiveElementAndScroll(Math.max(scope.$ctrl.active - 10, 0));
						break;
					// page down
					case 34:
						setActiveElementAndScroll(Math.min(scope.$ctrl.active + 10, Math.max(scope.$ctrl.filtered.length - 1, 0)));
						break;
					// end
					case 35:
						event.preventDefault(); // to not move caret to the end
						setActiveElementAndScroll(Math.max(scope.$ctrl.filtered.length - 1, 0));
						break;
					// home/pos1
					case 36:
						event.preventDefault(); // to not move caret to the beginning
						setActiveElementAndScroll(0);
						break;
					// up
					case 38:
						event.preventDefault(); // to not move caret to the beginning
						setActiveElementAndScroll(Math.max(scope.$ctrl.active - 1, 0));
						break;
					// down
					case 40:
						event.preventDefault(); // to not move caret to the end
						setActiveElementAndScroll(Math.min(scope.$ctrl.active + 1, Math.max(scope.$ctrl.filtered.length - 1, 0)));
						break;
					default:
					// nothing
				}
			});

			scope.$ctrl.modelCtrl = ngModelCtrl;

			// "watch" for model changes to update the selected option
			scope.$ctrl.modelCtrl.$formatters.push(function modelHasChanged(modelValue) {
				var selectedOption;

				if (modelValue) {
					selectedOption = scope.$ctrl.options.find(function searchForId(option) {
						return option.id === modelValue;
					});

					if (selectedOption) {
						scope.$ctrl.selected = selectedOption.name;
						scope.$ctrl.updateSelected();
					}
				}
				return modelValue;
			});

			scope.$ctrl.closeDropdown = close;

			scope.$ctrl.updateSelected = function updateSelected() {
				selected.html(scope.$ctrl.selected || placeholder);
			};

			scope.$ctrl.searchFn = function searchFn(entry) {
				if (angular.isString(scope.$ctrl.search) && scope.$ctrl.search.length) {
					return (
						entry.name.toLowerCase().indexOf(scope.$ctrl.search.toLowerCase()) > -1 ||
						entry[scope.$ctrl.additionalKey].toLowerCase().indexOf(scope.$ctrl.search.toLowerCase()) > -1
					);
				}

				return true;
			};

			scope.$ctrl.onSearchChange = function onSearchChange() {
				dropdownScrollTop();

				angular.element(currentList[scope.$ctrl.active]).removeClass(ELEMENT_ACTIVE_CLASS);

				$timeout(function deferToHaveAngularRenderTheRepeat() {
					currentList = scope.$ctrl.filtered.length ? dropdown.find('li') : [];
					resetActiveElement();
				});
			};

			// init

			iElement.addClass('model');
			iElement.attr('tabindex', '-1');
			iElement.wrap(container.addClass(iAttrs.class));

			parent = iElement.parent();
			parent.append($compile(search)(scope));
			parent.append($compile(selected)(scope));

			if (iAttrs.additionalSearchKey) {
				dropdown.append(listElementWithAdditional);
				scope.$ctrl.additionalKey = iAttrs.additionalSearchKey;
			} else {
				dropdown.append(listElement);
			}
			parent.append(dropdownContainer.append($compile(dropdown.append(emptyListElement))(scope)));

			scope.$ctrl.updateSelected();

			$timeout(function waitForRepeatToRender() {
				currentList = dropdown.find('li');
			});

			unbindTranslateChangeListener = $rootScope.$on('$translateChangeSuccess', function $translateChangeSuccess() {
				placeholder = $filter('translate')(iAttrs.placeholder || '');
				scope.$ctrl.updateSelected();
			});

			unbindDestroyListener = scope.$on('$destroy', function $destroyListener() {
				iElement.off('focus');
				selected.off('mousedown keydown blur');
				search.off('keydown');
				unbindTranslateChangeListener();
				unbindDestroyListener();
			});
		},
	};
}

export default SearchableSelect;
