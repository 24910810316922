import AdslotGroup from '../../../models/AdslotGroup.js';
import { ascByNameField } from '../../../utils/sort-utils.js';

function AdslotGroups(Api) {
	'ngInject';

	this.ERROR = {
		DUPLICATE_NAME: 10007,
	};

	this.EVENT = {
		UPDATED: 'AdslotGroups::updated',
	};

	/**
	 * @type {Object.<int, AdslotGroup>}
	 */
	this.groups = {};

	/**
	 * @return {promise}
	 */
	this.loadAll = function loadAll() {
		var self = this;
		return Api.get('/api/supply/v1/adslotgroups/').then(function success(groups) {
			self.groups = {};

			groups.forEach(function storeGroups(group) {
				self.groups[group.id] = new AdslotGroup(group);
			});
		});
	};

	/**
	 * @param {int|Array.<int>} groupIds
	 * @return {promise}
	 */
	this.load = function load(groupIds) {
		var self = this;
		const ids = angular.isArray(groupIds) ? groupIds : [groupIds];
		return Api.get(`api/supply/v1/adslotgroups/${ids.join(',')}`).then(function success(groups) {
			return (groups || []).map(function convert(group) {
				self.groups[group.id] = new AdslotGroup(group);
				return self.groups[group.id];
			});
		});
	};

	/**
	 * @param {int} groupId
	 * @param {{}} groupData
	 * @returns {promise}
	 */
	this.update = (groupId, groupData) =>
		Api.post(`api/supply/v1/adslotgroups/${groupId}`, groupData).then(() => {
			angular.extend(this.groups[groupId], groupData);
			return this.groups[groupId];
		});

	/**
	 * @param {int} siteId
	 * @param {Array.<{name: <string>}>} groups
	 * @returns {Array.<AdslotGroup>}
	 */
	this.add = (siteId, groups) =>
		Api.post(`api/supply/v1/sites/${siteId}/adslotgroups/add`, groups).then((addedGroups) =>
			addedGroups.map((group) => {
				this.groups[group.id] = new AdslotGroup(group);
				return this.groups[group.id];
			}),
		);

	/**
	 * @returns {Object.<int, AdslotGroup>}
	 */
	this.getMap = function getMap() {
		return this.groups;
	};

	/**
	 * @param {int} id
	 * @returns {AdslotGroup}
	 */
	this.getById = function getById(id) {
		return this.groups[id];
	};

	/**
	 * @returns {{id: number, name: string}[]}
	 */
	this.getAsIdNameObjectsList = () =>
		Object.values(this.getMap())
			.map(({ id, name, isDefault }) => ({ id, name, isDefault }))
			.sort(ascByNameField);

	/**
	 * @param {function} [include] filter function to decide which elements to include in result list.
	 * @returns {Array.<AdslotGroup>}
	 */
	this.getListAll = function getListAll(include) {
		var self = this,
			list = Object.keys(this.groups).map(function asList(groupId) {
				return self.groups[groupId];
			});
		return angular.isFunction(include) ? list.filter(include) : list;
	};

	/**
	 * @param {int} siteId
	 * @returns {Array.<AdslotGroup>}
	 */
	this.getListBySiteId = function getListBySiteId(siteId) {
		return this.getListAll(function bySiteId(group) {
			return group.siteId === siteId * 1;
		});
	};

	/**
	 * @param {int} siteId
	 * @returns {AdslotGroup}
	 */
	this.getDefaultForSite = (siteId) => this.getListBySiteId(siteId).filter((group) => group.readOnly)[0];

	/**
	 * @param {string} groupName
	 * @returns {boolean}
	 */
	this.exists = function exists(groupName) {
		var i,
			groupIds = Object.keys(this.groups),
			l = groupIds.length,
			groupNameLowerCase = groupName.toLowerCase();

		for (i = 0; i < l; i++) {
			if (this.groups[groupIds[i]].name.toLowerCase() === groupNameLowerCase) {
				return true;
			}
		}

		return false;
	};

	/**
	 * @param {int} siteId
	 * @param {string} groupName
	 * @returns {boolean}
	 */
	this.existsInSite = function existsInSite(siteId, groupName) {
		var i,
			siteGroups = this.getListBySiteId(siteId),
			l = siteGroups.length,
			groupNameLowerCase = groupName.toLowerCase();

		for (i = 0; i < l; i++) {
			if (siteGroups[i].name.toLowerCase() === groupNameLowerCase) {
				return true;
			}
		}

		return false;
	};
}

export default AdslotGroups;
