function FocusOn($rootScope) {
	'ngInject';

	return {
		scope: false,
		link: function postLink(scope, iElement, iAttrs) {
			var unregisterFocusOnListener;

			unregisterFocusOnListener = $rootScope.$on(iAttrs.focusOn, function focusOn() {
				iElement.eq(0).focus();
			});

			scope.$on('$destroy', unregisterFocusOnListener);
		},
	};
}

export default FocusOn;
