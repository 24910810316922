import { format, isValid, parseISO } from 'date-fns';
import isString from 'lodash/isString.js';

import TYPE from './PartnershipType.js';

import PartnershipState from './PartnershipState.js';

class Partnership {
	static #API_REPORT_DATE_ISO_FORMAT = "yyyy-MM-dd'T'HH:mmXX";

	/**
	 * @param {Partnership} partnership
	 * @returns {Partnership}
	 */
	static clone(partnership) {
		return new Partnership(partnership.toObject());
	}

	/**
	 * @param {Object} [data]
	 */
	constructor(data = {}) {
		/**
		 * @type {Number}
		 */
		this.id = 0;

		/**
		 * @type {String}
		 */
		this.name = '';

		/**
		 * @type {PartnershipState}
		 */
		this.state = new PartnershipState();

		/**
		 * 0 - Billing over Yieldlab, 1 - Direct Billing (Demand Partner)
		 * @type {Number}
		 */
		this.billing = 0;

		/**
		 * @type {Number}
		 */
		this.demandId = 0;

		/**
		 * @type {String}
		 */
		this.demandName = '';

		/**
		 * @type {String}
		 */
		this.dealId = '';

		/**
		 * @type {{dealStart: Date, dealEnd: Date}}
		 */
		this.dealDates = {};

		/**
		 * @type {Number}
		 */
		this.type = 0;

		/**
		 * @type {Number}
		 */
		this.priority = 0;

		/**
		 * in cents
		 * @type {Number}
		 */
		this.floorPrice = 0;

		/**
		 * in cents
		 * @type {Number}
		 */
		this.fixedPrice = 0;

		/**
		 * @type {Number}
		 */
		this.auctionType = 0;

		/**
		 * @type {Number}
		 */
		this.biasType = 0;

		/**
		 * @type {Number}
		 */
		this.bias = 0;

		/**
		 * @type {Array<String>}
		 */
		this.activeSizes = [];

		/**
		 * @type {Number}
		 */
		this.refererType = 0;

		/**
		 * @type {String}
		 */
		this.overrideReferer = '';

		/**
		 * @type {Boolean}
		 */
		this.geoLocationAllowed = true;

		/**
		 * @type {Boolean}
		 */
		this.categoryInfoAllowed = true;

		/**
		 * @type {String}
		 */
		this.dealTargeting = '';

		/**
		 * in percentage
		 * @type {Number}
		 */
		this.margin = 0;

		if (data) {
			this.id = parseInt(data.id, 10) || 0;
			this.name = data.name || '';
			this.state = new PartnershipState(data.state);
			this.billing = data.billing || 0;
			this.demandId = parseInt(data.demandId, 10) || 0;
			this.demandName = data.demandName || '';
			this.dealId = data.dealId || '';
			this.dealDates = Partnership.convertDealDatesStringsToObjects(data.dealDates);
			this.type = data.type || 0;
			this.priority = data.priority || 0;
			this.floorPrice = parseInt(data.floorPrice, 10) || 0;
			this.fixedPrice = parseInt(data.fixedPrice, 10) || 0;
			this.auctionType = parseInt(data.auctionType, 10) || 0;
			this.biasType = parseInt(data.biasType, 10) || 0;
			this.bias = parseInt(data.bias, 10) || 0;
			this.activeSizes = data.activeSizes || [];
			this.refererType = parseInt(data.refererType, 10) || 0;
			this.overrideReferer = data.overrideReferer || '';
			this.geoLocationAllowed = data.geoLocationAllowed;
			this.categoryInfoAllowed = data.categoryInfoAllowed;
			this.dealTargetingIds = Partnership.convertDealTargetingStringToArray(data.dealTargeting);
			this.margin = parseInt(data.margin, 10) || 0;
		}
	}

	/**
	 * @return {Number}
	 */
	getId() {
		return this.id;
	}

	/**
	 * @return {String}
	 */
	getName() {
		return this.name;
	}

	/**
	 * @return {PartnershipState}
	 */
	getState() {
		return this.state;
	}

	/**
	 * @return {Number}
	 */
	getBilling() {
		return this.billing;
	}

	/**
	 * @param {String} name
	 *
	 * @return {Partnership}
	 */
	setName(name) {
		this.name = name;
		return this;
	}

	/**
	 * @returns {boolean}
	 */
	isEnabled() {
		return this.state.isEnabled();
	}

	/**
	 * @returns {boolean}
	 */
	isDisabled() {
		return this.state.isDisabled();
	}

	/**
	 * @returns {boolean}
	 */
	isDisabledAutomatically() {
		return this.state.isDisabledAutomatically();
	}

	/**
	 * @param {boolean} [enabled]
	 *
	 * @returns {Partnership}
	 */
	toggleEnabled(enabled = undefined) {
		this.state.toggle(enabled);
		return this;
	}

	/**
	 * checks if invoice is from yieldlab
	 *
	 * @return {boolean}
	 */
	isDirectBilling() {
		return this.billing === 1;
	}

	/**
	 * returns demand id
	 *
	 * @return {Number}
	 */
	getDemandId() {
		return this.demandId;
	}

	/**
	 * returns deal id
	 *
	 * @return {String}
	 */
	getDealId() {
		return this.dealId;
	}

	/**
	 * returns demand name
	 *
	 * @return {String}
	 */
	getDemandName() {
		return this.demandName;
	}

	/**
	 * @return {boolean}
	 */
	isPrivateAuction() {
		return this.type === TYPE.PRIVATE_AUCTION;
	}

	/**
	 * @return {boolean}
	 */
	isDirectDeal() {
		return this.type === TYPE.DIRECT_DEAL;
	}

	/**
	 * @return {boolean}
	 */
	isPreferredDeal() {
		return this.type === TYPE.PREFERRED_DEAL;
	}

	/**
	 * @return {boolean}
	 */
	isProgrammaticGuaranteed() {
		return this.type === TYPE.PROGRAMMATIC_GUARANTEED;
	}

	/**
	 * @return {Number}
	 */
	getType() {
		return this.type;
	}

	/**
	 * @return {Number}
	 */
	getPriority() {
		return this.priority;
	}

	/**
	 * @return {Number}
	 */
	getFloorPrice() {
		return this.floorPrice;
	}

	/**
	 * @param {Number} floorPrice
	 * @return {Partnership}
	 */
	setFloorPrice(floorPrice) {
		this.floorPrice = floorPrice;
		return this;
	}

	/**
	 * @return {Number}
	 */
	getFixedPrice() {
		return this.fixedPrice;
	}

	/**
	 * @param {Number} fixedPrice
	 * @return {Partnership}
	 */
	setFixedPrice(fixedPrice) {
		this.fixedPrice = fixedPrice;
		return this;
	}

	/**
	 *
	 * @returns {Array<String>}
	 */
	getActiveSizes() {
		return this.activeSizes;
	}

	setActiveSizes(activeSizes) {
		this.activeSizes = activeSizes;
		return this;
	}

	/**
	 *
	 * @returns {Array<String>}
	 */
	getDealTargetingIds() {
		return this.dealTargetingIds;
	}

	setDealTargetingIds(dealTargetingIds) {
		this.dealTargetingIds = dealTargetingIds;
		return this;
	}

	/**
	 * @return {Number}
	 */
	getMargin() {
		return this.margin;
	}

	/**
	 * @param {Number} margin
	 * @return {Partnership}
	 */
	setMargin(margin) {
		this.margin = margin;
		return this;
	}

	toObject(serialize = false) {
		let { dealStart, dealEnd } = this.dealDates;

		// either serialize as string
		if (serialize) {
			dealStart = format(dealStart, Partnership.#API_REPORT_DATE_ISO_FORMAT);
			dealEnd = format(dealEnd, Partnership.#API_REPORT_DATE_ISO_FORMAT);
		} else {
			// or copy values
			if (isValid(dealStart)) {
				dealStart = new Date(dealStart.valueOf());
			}
			if (isValid(dealEnd)) {
				dealEnd = new Date(dealEnd.valueOf());
			}
		}

		return {
			id: this.id,
			name: this.name,
			state: this.state.getStateId(),
			billing: this.billing,
			demandId: this.demandId,
			demandName: this.demandName,
			dealId: this.dealId,
			dealDates: { dealStart, dealEnd },
			type: this.type,
			priority: this.priority,
			floorPrice: this.floorPrice,
			fixedPrice: this.fixedPrice,
			auctionType: this.auctionType,
			biasType: this.biasType,
			bias: this.bias,
			activeSizes: this.activeSizes,
			refererType: this.refererType,
			overrideReferer: this.overrideReferer,
			geoLocationAllowed: this.geoLocationAllowed,
			categoryInfoAllowed: this.categoryInfoAllowed,
			dealTargetingIds: this.dealTargetingIds,
			margin: this.margin,
		};
	}

	/**
	 * @param {{dealStart: String|Date, dealEnd: undefined|String|Date}}[dealDates]
	 *
	 * @returns {{dealStart: Date, dealEnd: Date}}
	 */
	static convertDealDatesStringsToObjects = (dealDates = {}) => {
		const { dealStart, dealEnd } = dealDates;
		const convertedDealDates = {
			dealStart: null,
			dealEnd: null,
		};

		if (isString(dealStart)) {
			const parsed = parseISO(dealStart);
			if (isValid(parsed)) {
				convertedDealDates.dealStart = parsed;
			}
		} else if (isValid(dealStart)) {
			convertedDealDates.dealStart = new Date(dealStart.valueOf());
		}

		if (isString(dealEnd)) {
			const parsed = parseISO(dealEnd);
			if (isValid(parsed)) {
				convertedDealDates.dealEnd = parsed;
			}
		} else if (isValid(dealEnd)) {
			convertedDealDates.dealEnd = new Date(dealEnd.valueOf());
		}

		return convertedDealDates;
	};

	static convertDealTargetingStringToArray(dealTargeting) {
		if (dealTargeting) {
			if (dealTargeting === null) {
				return null;
			}
			if (dealTargeting === '') {
				return [];
			}
			return dealTargeting.split('|');
		}
		return [];
	}
}

export default Partnership;
