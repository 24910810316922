import SelectionService from '../../../../modules/utils/SelectionService.js';

function ProviderListController(ListSearchService, AuthService) {
	'ngInject';

	let filteredProviders = [];

	const vm = this;

	vm.filterSelected = (rowEntry) => {
		const selected = !!vm.selection.elements[rowEntry.id];

		return (vm.showSelected && selected) || (vm.showUnselected && !selected) || (!vm.showSelected && !vm.showUnselected);
	};

	vm.AS = AuthService;

	vm.sortKey = 'name';

	vm.filteredProviders = [];

	vm.getFilteredProviders = () => vm.filteredProviders;

	vm.toggleOne = (entryId) => vm.selection.toggleOne(entryId);

	this.$onInit = function $onInit() {
		vm.search = ListSearchService.get(vm.searchId);

		vm.showSelected = !!vm.showSelected;
		vm.showUnselected = !!vm.showUnselected;

		vm.selection = vm.selection || new SelectionService();
	};

	this.$doCheck = () => {
		if (vm.filteredProviders && !angular.equals(vm.filteredProviders, filteredProviders)) {
			filteredProviders = angular.copy(vm.filteredProviders);
		}
	};
}

export default ProviderListController;
